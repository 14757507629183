import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles } from "../../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import DeleteModal from "../../Modal/Masters/DeleteModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "800px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

function ProformaList(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const history = useHistory();
  const isw_user_role = localStorage.getItem("isw_user_role");
  const jwtToken = "Bearer " + user.isw_user_token;
  const [backdropopen, setbackdropopen] = useState(false);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");
  const dispatch = useDispatch();
  const [datefilter, setdatefilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [paginationpageno, setpaginationpageno] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allTemp, setAllTemp] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allTemp]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1558, 1563, permission_obj, resource_obj)) {
      //if (available_permission) {
      dispatch(setdashboardcheck(28));

      if (localStorage.getItem("prformaPageNo")) {
        let currentPage = localStorage.getItem("prformaPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getAllProforma(currentPage);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getAllProforma(0);
      }
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const createproforma = () => {
    history.push("/create-proforma");
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllProforma(value - 1);
    setpaginationpageno(value);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getAllProforma = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllProformaWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setAllTemp(response.data.data.content);
          setsearch(false);
          setbackdropopen(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response));
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getAllProformaWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setAllTemp(response.data.data.content);
          setbackdropopen(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response));
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const deleteCurrentUser = (id, name) => {
    axios
      .delete(`${base_url.api2}/deleteProformaById`, {
        headers: {
          Authorization: jwtToken,
          proformaId: id,
        },
      })
      .then(
        (response) => {
          toast.success("Deleted Successfully");
          let logs = isw_user_name + " Deleted Proforma " + name + ".";
          callsaveauditapi(logs);
          getAllProforma(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const editProforma = (elem) => {
    localStorage.setItem("prformaPageNo", pageno);
    var proformaId = elem.target.id;
    history.push("/edit-proforma/" + proformaId);
    // console.log(elem.target.id);
  };

  const [datesrange, setdatesrange] = useState();
  const handleApply = (event, picker) => {
    setpageno(0);
    setpageno(0);
    console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    console.log(data);
    axios
      .post(`${base_url.api2}/searchUserByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          var client_admin = response.data.data.content;
          setClientDetails(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          setpageno(0);
          setdatefilter(true);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpaginationpageno(value);
    // setdisplay("display");
    setpageno(value - 1);
    console.log(value);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchUserByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setClientDetails(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            setdatefilter(true);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            toast.error(JSON.stringify(error.response));
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setClientDetails(response.data.data.content);
            setbackdropopen(false);
          },
          (error) => {
            toast.error(JSON.stringify(error.response));
            setbackdropopen(false);
            handle403error(error);
            // console.log(error);
          }
        );
    }
  };
  const [client_admin_users, setClientDetails] = useState(null);
  const submit = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchProforma`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setAllTemp(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(true);
          setbackdropopen(false);
        },
        (error) => {
          setAllTemp();
          toast.error(JSON.stringify(error.response));
          setbackdropopen(false);
          handle403error(error);
          // console.log(error);
        }
      );
  };
  const [modalIsOpen2, setisopen2] = useState(false);

  const closeModal = () => {
    setisopen2(false);
  };

  const submitrows = (e) => {
    // if (searchinput != null) {
    //   axios
    //     .get(`${base_url.api2}/searchUser`, {
    //       headers: {
    //         Authorization: jwtToken,
    //         searchQuery: searchinput,
    //         columnName: sortState,
    //         direction: direction,
    //         page: Number(pageno),
    //         size: Number(rows),
    //       },
    //     })
    //     .then(
    //       (response) => {
    //         console.log(response.data.data);
    //         var client_admin = response.data.data.content;
    //         setClientDetails(response.data.data.content);
    //         setgetpageno(response.data.data.totalPages);
    //       },
    //       (error) => {
    //         toast.error(JSON.stringify(error.response.data.message));
    //       }
    //     );
    // } else {
    getAllProforma(0);
    // }
  };

  const [templatedesign, settemplatedesign] = useState();
  const viewTemplate = (elem) => {
    axios
      .get(`${base_url.api2}/getProformaById`, {
        headers: {
          Authorization: jwtToken,
          proformaId: Number(elem.target.id),
        },
      })
      .then((res) => {
        setisopen2(true);
        settemplatedesign(res.data.data.template);
      });
  };

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        id: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api2}/updateProformaById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllProforma(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        id: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api2}/updateProformaById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllProforma(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem",marginBottom:"-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Proforma</h3>
          <a href="#/" class="b-link">Home</a>
          -
          <a class="b-link">Settings</a>
          -
          <a class="b-link active">Manage Proforma</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1559, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={createproforma}
            style={{ float: "right", height: "40px" }}
          >
            {/* <AddIcon className="listing_addbutton_i" /> */}
            Add
          </a>
        ) : null}
      </div>
      <div style={{ marginTop: "3px" }}>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px", marginLeft: "10px" }}
              >
                <strong>Manage Proforma</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
              {permissionData?.includes(138) ? (
                <button
                  className="btn listing_addbutton"
                  onClick={createproforma}
                >
                  <AddIcon className="listing_addbutton_i" /> Add
                </button>
              ) : null}
            </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={4}>
              <form
                className="master"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitrows(e);
                }}
              >
                 <div style={{ marginTop: "-10px",marginRight:"20px" }}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                    <select
                      className="s_client_limit"
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                </div>
              </form>
              {/* <br />
                  <label>
                    <strong>Date:</strong>
                  </label>
                  <DateRangePicker
                    onEvent={handleEvent}
                    onCallback={handleCallback}
                    onApply={handleApply}
                    initialSettings={{
                      startDate: { datepast },
                      endDate: { datenow },
                    }}
                  >
                    <input style={{ width: "50%" }} type="text" />
                  </DateRangePicker>
                  <br />
                  {datefilter ? (
                    <div style={{ float: "left" }}>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdirection("DESC");
                          setdirection("DESC");
                          getAllProforma(0);
                        }}
                      >
                        Clear Search
                      </a>
                    </div>
                  ) : null} */}
            </Col>
            <Col md={5}></Col>
            <Col md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setdirection("DESC");
                    setpageno(0);
                    getAllProforma(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allTemp?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {/* {permissionData?.includes(138) ? (
                <button
                  className="btn btn-success"
                  onClick={createproforma}
                  style={{ float: "right", height: "40px" }}
                >
                   <AddIcon className="listing_addbutton_i" /> 
                  Add
                </button>
              ) : null} */}
            </Col>
          </Row>
          {/* <div className="above-scroller" onScroll={scrolldiv}>
              <div className="scroller"></div>
            </div> */}
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  {/* <th>Id</th> */}
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th style={{ width: "60%" }}>
                    NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      // className={display}
                      id="name"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ width: "125px" }}>
                    STATUS
                    <img
                      src={sort}
                      className="sorting"
                      // className={display}
                      id="status"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {check4pointpermission(
                    1560,
                    1562,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ width: "175px" }}>ACTION</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allTemp != null ? (
                  allTemp &&
                  allTemp.map((p, index) => (
                    <tr key={p.id}>
                      {/* <td>{p.id}</td> */}
                      <td style={{ textAlign: "center", width: "50px" }}>
                        {index + 1}
                      </td>
                      <td>{p.name}</td>
                      <td style={{ width: "125px" }}>
                      {check1pointpermission(
                          1563,
                          permissionData,
                          rolepermission
                        ) ? (
                          <Link id={p.id} onClick={(e) => changeStatus(e, p)}>
                            {p.status === 1 ? "Active" : "InActive"}
                          </Link>
                        ) : (
                          <>{p.status === 1 ? "Active" : "InActive"}</>
                        )}
                      </td>
                      {check4pointpermission(
                        1560,
                        1562,
                        permissionData,
                        rolepermission
                      ) ? (
                        <td id={p.id} style={{ width: "175px" }}>
                         {check1pointpermission(
                          1560,
                          permissionData,
                          rolepermission
                        ) ? (
                            <>
                              <a
                                id={p.id}
                                onClick={editProforma}
                                href={`#/edit-proforma/` + p.id}
                              >
                                Edit
                              </a>
                              &nbsp;|&nbsp;
                            </>
                          ) : null}
                      {check1pointpermission(
                          1561,
                          permissionData,
                          rolepermission
                        ) ? (
                            <>
                              <Link id={p.id} onClick={viewTemplate}>
                                View
                              </Link>
                              <Modal
                                isOpen={modalIsOpen2}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <div
                                  id="modal"
                                  dangerouslySetInnerHTML={{
                                    __html: templatedesign,
                                  }}
                                />
                                {/* <div>{`${!!templatedesign!!}`}</div> */}
                              </Modal>
                              &nbsp;|&nbsp;
                            </>
                          ) : null}
                      {check1pointpermission(
                          1562,
                          permissionData,
                          rolepermission
                        ) ? (
                            <DeleteModal
                              deleteCurrentUser={deleteCurrentUser}
                              name={p.name}
                              id={p.id}
                            />
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <Row>
              <Col md={6}>
                <div>
                  <form className="master">
                    <div style={{ marginTop: "-10px" }}>
                      <label className="area_rowslable"></label>
                      <br />
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      {allTemp?.length ? (
                        <div
                          className="s_spanPage"
                        >
                          {countnumber * rows + 1} to{" "}
                          {countnumber * rows + numberofElements} of {totalUser} records
                        </div>
                      ) : (
                        <span className="s_spanPage">No Record Found
                        </span>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  {searchdata || datefilter ? (
                    <Pagination
                      page={paginationpageno}
                      count={getpageno}
                      onChange={handlesearch}
                      shape="rounded"
                    />
                  ) : (
                    <Pagination
                      page={paginationpageno}
                      count={getpageno}
                      onChange={handlepage}
                      shape="rounded"
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(ProformaList);