import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import Modal from "react-modal";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { checkreportpermission, check1pointpermission, check4pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";
import LeadsReportViewMoreModal from "../../Modal/Masters/LeadsReportViewMoreModal";
import LeadViewMoreModal from "./../../Modal/Masters/LeadViewMoreModal";



function LeadsReportsNew(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [sortState, setSortState] = useState("id");
  const [direction, setDirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [leadTypeState, setleadTypeState] = useState("Archive");
  const [selectedClientSource, setSelectedClientSource] = useState(null);
  const [totalLead, setTotalLead] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const datepast = moment().subtract(100, "Y").format("YYYY-MM-DDT00:00:00");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];

  const [backdropopen, setbackdropopen] = useState(true);


  const [leadData, setLeadData] = useState();
  const [leadPageNo, setLeadPageNo] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const [commentModelOpen, setCommentModelOpen] = useState(false);
  const [commentContent, setCommentContent] = useState();

  const [searchStatus, setSearchStatus] = useState();
  const [display1, setDisplay] = useState("inline");
  const [searchData, setSearchData] = useState(null);
  const [pdfRow, setPdfRow] = useState([]);


  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      console.log(element);
      office_obj.push(element);
    }
    let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
    setUserOfficeId(new_id);
    getPermissions();
    getConsltant();
    getClientSource();
    getOffice();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [leadData]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: leadTypeState,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: searchData,
      clientSource: selectedClientSource,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    if (actionType === 'DOWNLOAD') {
      setbackdropopen(true);
      axios
        .get(`${base_url.api4}/getLeadReportByLeadType`, {
          headers: headData,
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  }

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  let headData;
  let office_id;

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(1007, permission_obj, resource_obj)) {
      //if(available_permission) {

      if (user.isw_user_role === "1" || user.isw_user_role === "6") {
        let datenow1 = isoDateString.split(".")[0];

        headData = {
          Authorization: jwtToken,
          leadType: leadTypeState,
          assigneeId: assigneeData,
          officeId: userOfficeId,
          query: searchData,
          clientSource: selectedClientSource,
          createdFromDate: datepast,
          createdToDate: datenow1,
          columnName: sortState,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
        console.log("firsthead", headData);
        getAllDataDateFilter(headData);
      }
      else {
        let datenow1 = isoDateString.split(".")[0];
        const office_obj = [];
        for (let i = 0; i < user?.isw_available_Office?.length; i++) {
          const element = user?.isw_available_Office?.[i];
          console.log(element);
          office_obj.push(element);
        }
        let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
        office_id = new_id;
        setSelectedOfficeId(new_id);

        headData = {
          Authorization: jwtToken,
          leadType: leadTypeState,
          assigneeId: assigneeData,
          officeId: new_id,
          query: searchData,
          clientSource: selectedClientSource,
          createdFromDate: datepast,
          createdToDate: datenow1,
          columnName: sortState,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        };
      }
      console.log("Secondhead", headData);
      getAllDataDateFilter(headData);
      dispatch(setdashboardcheck(57));

    } else {
      history.push("/errorPage");
    }
  };

  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setClientSource(response.data.data);
        },
        (error) => {
          // toast.error(JSON.stringify(error.response.data.message));
          setClientSource();
          handle403error(error);
        }
      );
  };

  const getallLeads = (headData) => {
    setbackdropopen(true);
    // if (isw_user_role === "1" || isw_user_role === "6") {
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: headData,
      })
      .then(
        (response) => {
          console.log(response.data.data.content);
          setbackdropopen(false);
          console.log(response.data.data.content);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [assigneeAPIData, setAssigneeAPIData] = useState();
  const getConsltant = (p) => {
    axios
      .get(`${base_url.api2}/getUsersByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
        },
      })
      .then(
        (res) => {
          let apiData = res.data.data;
          const consultant_obj = [];
          for (let i = 0; i < apiData.length; i++) {
            let data = {
              value: apiData[i].id,
              label: apiData[i].first_name + " " + apiData[i].last_name,
            };
            consultant_obj.push(data);
          }
          setAssigneeAPIData(consultant_obj);
        },
        (error) => {
          setAssigneeAPIData();
          handle403error(error);
        }
      );
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        setAvailableOffice();
        handle403error(error);
      });
  };

  const handlePageLead = (event, value) => {
    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: leadTypeState,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: searchData,
      clientSource: selectedClientSource,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortState,
      direction: direction,
      page: Number(value - 1),
      size: Number(rows),
    };
    getAllDataDateFilter(headData);
  };

  const [userOfficeId, setUserOfficeId] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? null
      : user?.isw_available_Office[0].officeId
  );

  const [selectedOfficeId, setSelectedOfficeId] = useState(null);

  const handleOffice = (event) => {
    setbackdropopen(true);
    // setSelectedOfficeId(event.target.value);
    let datenow1 = isoDateString.split(".")[0];
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");

    // if (event.target.value === "null") {
    if (optionElementId === "all") {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(new_id);
      setUserOfficeId(new_id);
      setSelectedOfficeId(new_id);
      let headData = {
        Authorization: jwtToken,
        leadType: leadTypeState,
        assigneeId: assigneeData,
        officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
        query: searchData,
        clientSource: selectedClientSource,
        createdFromDate: datepast,
        createdToDate: datenow1,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: Number(rows),
      };
      getAllDataDateFilter(headData);
    } else {
      console.log(optionElementId);
      setUserOfficeId(optionElementId);
      let headData = {
        Authorization: jwtToken,
        leadType: leadTypeState,
        assigneeId: assigneeData,
        officeId: optionElementId,
        query: searchData,
        clientSource: selectedClientSource,
        createdFromDate: datepast,
        createdToDate: datenow1,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: Number(rows),
      };
      getAllDataDateFilter(headData);
    }
  };

  const handleLeadType = (event) => {
    setbackdropopen(true);
    setleadTypeState(event.target.value);
    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: event.target.value,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: searchData,
      clientSource: selectedClientSource,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
    };
    getAllDataDateFilter(headData);
  };

  const handleClientSource = (event) => {
    console.log(userOfficeId);
    setbackdropopen(true);
    setSelectedClientSource(event.target.value);
    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: leadTypeState,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: searchData,
      clientSource: event.target.value,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
    };
    getAllDataDateFilter(headData);
  };

  const exportPdfLead = () => {
    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: leadTypeState,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: searchData,
      clientSource: selectedClientSource,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getLeadReportByLeadType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Lead Details",
          "Address",
          "Assignee",
          "Service",
          "Office",
          "Lead Type",
          "Session",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.firstName +
            " " +
            element.lastName +
            "\n" +
            element.email +
            "\n" +
            "Phone: " +
            element.phone,
            "Country: " + element.country + "\n" + "City: " + element.city,
            element.assigneeName,
            element.interestedServices,
            element.officeName,
            element.leadType,
            "SST: " +
            moment(element.startTime).format("DD/MM/YYYY hh:mm:ss") +
            "\n" +
            "SET: " +
            moment(element.endTime).format("DD/MM/YYYY hh:mm:ss") +
            "\n" +
            "Total: " +
            element.totalTime,
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("Leads_Reports.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const [assigneeData, setAssigneeData] = useState(null);
  const onSelectAssignee = (selectedList, selectedItem) => {
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    setAssigneeData(consultant_obj);
    let datenow1 = isoDateString.split(".")[0];
    if (consultant_obj.length > 0) {
      let headData = {
        Authorization: jwtToken,
        leadType: leadTypeState,
        assigneeId: consultant_obj.toString(),
        officeId: userOfficeId,
        query: searchData,
        clientSource: selectedClientSource,
        createdFromDate: datepast,
        createdToDate: datenow1,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: Number(rows),
      };
      getAllDataDateFilter(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        leadType: leadTypeState,
        assigneeId: null,
        officeId: userOfficeId,
        query: searchData,
        clientSource: selectedClientSource,
        createdFromDate: datepast,
        createdToDate: datenow1,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: Number(rows),
      };
      getAllDataDateFilter(headData);
    }
  };

  const searchLeadReport = (e) => {
    setbackdropopen(true);
    setDisplay("none");
    setSearchStatus(true);
    let srchInput = e.target[0].value.trim();
    setSearchData(srchInput);
    setpageno(0);

    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: leadTypeState,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: srchInput,
      clientSource: selectedClientSource,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
    };
    getAllDataDateFilter(headData);
  };

  const sortLeadData = (e) => {
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }
    let sortFilter = e.target.id.trim();
    setSortState(sortFilter);

    let datenow1 = isoDateString.split(".")[0];
    let headData = {
      Authorization: jwtToken,
      leadType: leadTypeState,
      assigneeId: assigneeData,
      officeId: userOfficeId,
      query: searchData,
      clientSource: selectedClientSource,
      createdFromDate: datepast,
      createdToDate: datenow1,
      columnName: sortFilter,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
    };
    getAllDataDateFilter(headData);
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();

  const getAllDataDateFilter = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getLeadReportByLeadType`, {
        headers: headData,
      })
      .then((response) => {
        headData.size = response.data.data.totalElements;
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          setpdflength(50);
        } else {
          // setpdflength(response.data.data.totalElements);
          setpdflength(50);
        }
        getPdfData(headData.size = Number(50));
        setPdfHeader(headData);
        setLeadData(response.data.data.content);
        setLeadPageNo(response.data.data.totalPages);
        setTotalLead(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        // setbackdropopen(false);
      })
      .catch((error) => {
        setLeadData();
        setLeadPageNo(0);
        setTotalLead(0);
        setCountNumber(0);
        setNumberOfElements(0);
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getLeadReportByLeadType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
          }, 1000);
        }
      }
    }
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Leads Report</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Reports</a><span className="s_span ">-</span>
          <a className="b-link">General</a><span className="s_span ">-</span>
          <a href="#/leads-report" className="b-link active">
            Leads Report
          </a>
        </div>
        {/* <a onClick={goback} className="back-btn">Back</a> */}
      </div>
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          borderRight: "1.5rem solid #e9ebf2",
          borderLeft: "1.5rem solid #e9ebf2",
          borderTop: "1.5rem solid #e9ebf2",
          marginBottom: "0",
        }}
      >
        <Row>
          <Col md={4}>

            <div>
              <label className="area_rowslable_my">Limit :</label>
              <select
                className="form-control"
                name="branch"
                value={rows}
                onChange={handleRows}
                style={{
                  width: "95%",
                  backgroundColor: "#f5f5f9"
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

          </Col>
          <Col md={4}>
            <div>
              {/* <h6 variant="h6" style={{ fontWeight: "bold" }}>
                Branch
              </h6> */}
              {/* <FormControl
                // className={classes.formControl}
                className="office_dropdown"
              > */}
              <label className="area_rowslable_my">Branch:</label>

              {user.isw_user_role === "1" || user.isw_user_role === "6" ? (
                <select
                  className="form-control"
                  onChange={(e) => handleOffice(e)}
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="" id="all">
                    - Show All -
                  </option>
                  {availableoffice?.map((cuntitem) => (
                    <option
                      key={cuntitem.officeId}
                      value={cuntitem.officeName}
                      id={cuntitem.officeId}
                    >
                      {cuntitem.officeName}
                      {" - "}
                      {cuntitem.officeCode}
                    </option>
                  ))}
                </select>
              ) : (
                <>
                  <select
                    // className={classes.selectdrop}
                    onChange={(e) => handleOffice(e)}
                    name="branch"
                    style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                    // ref={register}
                    required
                  >
                    <option value="" id="all">
                      - Show All -
                    </option>
                    {user.isw_available_Office?.map((cuntitem) => (
                      <option
                        key={cuntitem.officeId}
                        value={cuntitem.officeName}
                        id={cuntitem.officeId}
                      >
                        {cuntitem.officeName}
                        {" - "}
                        {cuntitem.officeCode}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {/* </FormControl> */}
            </div>
          </Col>
          <Col md={4}>
            <div>
              {/* <h6 variant="h6" style={{ fontWeight: "bold" }}>
                Consultant
              </h6>
              <FormControl
                // className={classes.formControl}
                className="office_dropdown"
                className="header_dropdown"
                style={{ width: "100%" }}
              >
                <br /> */}
              <label className="area_rowslable_my">Consultant:</label>
              <Select
                isMulti
                name="assigneeId"
                options={assigneeAPIData}
                // styles={styles}
                // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                className="report_dropdown"
                classNamePrefix="select"
                onChange={onSelectAssignee}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                required
              />
              {/* </FormControl> */}
            </div>
          </Col>

        </Row>
        <div className="form-group">
          <Row>
            <Col md={4}>
              <div>
                {/* <h6 variant="h6" style={{ fontWeight: "bold" }}>
                Client Source
              </h6>
              <FormControl
                // className={classes.formControl}
                className="office_dropdown"
              >
                <br /> */}
                <label className="area_rowslable_my">Client Source:</label>
                <select
                  className="form-control"
                  native
                  id="demo-simple-select3"
                  onChange={(e) => handleClientSource(e)}
                  // style={{
                  //   height: "38px",
                  //   borderRadius: "5px",
                  //   borderColor: "#cccaca",
                  //   width: "100%",
                  //   backgroundColor: "#f5f5f9",
                  // }}
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">- Select Source -</option>
                  {allClientSource?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.name}
                      id={cuntitem.id}
                    >
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
                {/* </FormControl> */}
              </div>
            </Col>
            <Col md={4}>
              <div>
                {/* <h6 variant="h6" style={{ fontWeight: "bold" }}>
                Lead Type
              </h6>
              <FormControl
                // className={classes.formControl}
                className="office_dropdown"
              >
                <br /> */}
                <label className="area_rowslable_my">Lead Type:</label>
                <select
                  className="form-control"
                  native
                  id="demo-simple-select3"
                  onChange={(e) => handleLeadType(e)}
                  style={{
                    // height: "38px",
                    // borderRadius: "5px",
                    // borderColor: "#cccaca",
                    width: "95%",
                    backgroundColor: "#f5f5f9",
                  }}
                >
                  <option value="Archive">Archive</option>
                  <option value="Achieved">Achieved</option>
                  <option value="HotLead">Hot Lead</option>
                  <option value="Converted">Converted Lead</option>
                  <option value="ColdLead">Cold Lead</option>
                  <option value="WarmLead">Warm Lead</option>
                </select>
                {/* </FormControl> */}
              </div>
            </Col>

            <Col md={4}>
              <div>
                <label className="area_rowslable_my">Search:</label>
                <div className="clearsearchclass">
                  <form
                    id="myForm"
                    className="d-flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchLeadReport(e);
                    }}
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search.."
                      name="search"
                      style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                    />
                    <button className="btn listpagesearch_button" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                {searchStatus ? (
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setSearchStatus(false);
                      setDisplay("inline");
                      setpageno(0);
                      document.getElementById("myForm").reset();
                      let datenow1 = isoDateString.split(".")[0];
                      let headData = {
                        Authorization: jwtToken,
                        leadType: leadTypeState,
                        assigneeId: assigneeData,
                        officeId: userOfficeId,
                        query: searchData,
                        clientSource: selectedClientSource,
                        createdFromDate: datepast,
                        createdToDate: datenow1,
                        columnName: sortState,
                        direction: direction,
                        page: Number(0),
                        size: Number(rows),
                      };
                      getAllDataDateFilter(headData);
                    }}
                  >
                    Clear Search
                  </a>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </Card> */}
      {/* <Card className="responsive_client_application"> */}
      {/* <CardBody> */}
      {/* <CardSubtitle className="font-weight-bold"></CardSubtitle> */}
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          border: "1.5rem solid #e9ebf2",
          marginTop: "0",
          marginBottom: "0",
        }}
      >

        <Row className="s_pagi" marginBottom="40px" style={{ marginTop: "5px" }}>
          <Col md={6}>
            {leadData?.length ? (
              <p className="s_para" style={{ marginTop: "5px" }}>
                {countNumber * rows + 1} to{" "}
                {countNumber * rows + numberOfElements} of {totalLead} records
              </p>
            ) : (
              <p className="s_para" style={{ marginTop: "5px" }}>No Record Found</p>
            )}
          </Col>
          <Col md={6}>
            <div style={{ display: "flex", justifyContent: "right" }}>
              {check1pointpermission(
                1008,
                permissionData,
                rolepermission
              ) ? (
                <button
                  className="btn listing_addbutton"
                  onClick={exportPdfLead}
                  style={{ marginLeft: "45px" }}
                >
                  Export PDF
                </button>
              ) : null}
              <>
                {/* {pdfRow != null && pdfRow.length != 0 ? (
                  <>
                    {permissionData?.includes(160) ? (
                      <CSVLink
                        filename="Leads-Reports.csv"
                        data={pdfRow ? pdfRow : null}
                        className="btn listing_addbutton"
                      // target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    ) : null}
                  </>
                ) : null} */}
                {dataForDownload != null ? (
                  <>
                    {check1pointpermission(
                      1008,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')}>Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="Leads-Reports.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            </div>
          </Col>
        </Row>
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          {/* <Table responsive striped onScroll={scrolltable} style={{ width: '200%' }}> */}
          <Table
            className="workload card-table table-borderless"
            responsive
            striped
            onScroll={scrolltable}
          >
            <thead className="s_sticky">
              <tr style={{ color: "#6d7290" }}>
                <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                <th style={{ minWidth: "110px" }}>DATE</th>
                <th style={{ minWidth: "80px" }}>QUEUE</th>
                <th style={{ minWidth: "110px" }}>CLIENT
                  <img
                    src={sort}
                    className="sorting"
                    id="firstName"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "140px" }}>
                  CONTACT {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="phone"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "150px" }}>
                  COUNTRY {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="country"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "140px" }}>
                  SERVICES {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="interestedServices"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "125px" }}>
                  SOURCE {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="clientSource"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "140px" }}>
                  CONSULTANT{/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="assigneeName"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                {/* <th style={{ minWidth: "130px" }}>
                    CITY 
                    <img
                      src={sort}
                      className="sorting"
                      id="city"
                      onClick={sortLeadData}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                <th style={{ minWidth: "100px" }}>
                  BRANCH {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="officeName"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>

                {/* <th style={{ minWidth: "150px" }}>
                    TIME 
                    <img
                      src={sort}
                      className="sorting"
                      id="time"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                <th style={{ minWidth: "120px" }}>
                  FOLLOW-UP{/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="followUpOn"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>
                  DEPOSIT{/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="deposit"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                {/* <th style={{ minWidth: "150px" }}>
                    DEADLINE
                    <img
                      src={sort}
                      className="sorting"
                      id="deadline"
                      onClick={sortLeadData}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                <th style={{ minWidth: "150px" }}>
                  CREATED BY
                  <img
                    src={sort}
                    className="sorting"
                    id="createdBy"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "80px" }}>
                  REMARKS {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="remarks"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                {/* <th style={{ minWidth: "125px" }}>ACTION</th> */}
              </tr>
            </thead>
            <tbody>
              {leadData != null
                ? leadData &&
                leadData.map((p, index) => (
                  <tr key={p.id}>
                    {/* <td>{p.id}</td> */}
                    <td style={{ width: "50px", textAlign: "center" }}>
                      {countNumber * rows + 1 + index}
                    </td>
                    <td>
                      {p.addedOn
                        ? moment(p.addedOn.split("T")[0]).format(
                          "DD-MMM-YY"
                        )
                        : null}
                    </td>
                    <td>
                      {p.days_in_queue}

                    </td>
                    <td>
                      {p.startTime == null ? (
                        <a
                          className="greeneffect"
                          // onClick={(e) => viewWorkflow(p, e)}
                          href={`#/start-client-session/` + p.id}
                          title="Start-Session"
                          style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}
                        >
                          {p.firstName +
                            " " +
                            p.lastName}
                          <br />
                          <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                        </a>
                      ) : (
                        <a
                          // onClick={(e) => viewWorkflow(p, e)}
                          href={`#/start-client-session/` + p.id}
                          title="Resume-Session"
                        >
                          {p.firstName +
                            " " +
                            p.lastName}
                          <br />
                          <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                        </a>
                      )}
                    </td>
                    <td>
                      {p.phone == 0 ? " " : p.phone}
                    </td>
                    <td>
                      {p.city}
                      <br />
                      {p.country}
                    </td>
                    <td>{p.interestedServices}</td>
                    <td>{p.clientSource}</td>
                    <td>
                      <>
                        <div class="s_tooltip">
                          {p.assigneeName != null ? p.assigneeName.split(" ")[0] : ""}
                          <span class="s_tooltiptext">
                            {p.assigneeName}
                          </span>
                        </div>
                      </>
                    </td>
                    <td>{p.officeCode}</td>

                    <td>
                      {p.followUpOn
                        ? moment(p.followUpOn.split(".")[0]).format(
                          "DD-MMM-YY"
                        )
                        : null}
                    </td>
                    <td>{p.deposit === 1 ? "Yes" : "No"}</td>

                    <td><div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.createdBy}</div></td>
                    <td className="leadellipsis" style={{ position: "relative" }}>
                      {p.clientDetail?.remarks}
                      {p.clientDetail?.remarks != "" ? (

                        <LeadViewMoreModal
                          p={p}
                          toast={toast}
                          id={p.id}
                          getallLeads={getallLeads}
                          handle403error={handle403error}
                          sortState={sortState}
                          direction={direction}
                          pageno={pageno}
                          rows={rows}
                        />
                      ) : null}
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {leadData?.length ? (
                      <p className="s_spanPage">
                        {countNumber * rows + 1} to{" "}
                        {countNumber * rows + numberOfElements} of {totalLead} records
                      </p>
                    ) : (
                      <p className="s_spanPage">No Record Found</p>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  count={leadPageNo}
                  onChange={handlePageLead}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* </CardBody> */}
      {/* </Card> */}
    </div>
  );
}

export default withRouter(LeadsReportsNew);