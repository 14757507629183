import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Row, Col, Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";

const EditModal = ({
  header,
  editLevel,
  editname,
  setname,
  editstatus,
  setstatus,
  classes,
  name,
  id,
  status,
  isclosemodal,
}) => {
  const [editmodal, seteditmodal] = useState(false);

  useEffect(() => {
    if (isclosemodal) {
      closeeditmodal();
    }
  }, [isclosemodal]);

  const openeditmodal = () => {
    setname(name);
    setstatus(status);
    seteditmodal(true);
  };
  const closeeditmodal = () => seteditmodal(false);

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
        Edit
      </span>
      <Modal
        isOpen={editmodal}
        onRequestClose={openeditmodal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>
          <strong>{header}</strong>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editLevel(id, name);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={editname}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>Status:</label>
                  <select
                    className={classes.selectdrop}
                    name="status"
                    value={editstatus}
                    onChange={(e) => {
                      setstatus(e.target.value);
                    }}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
              </Row>
            </div>
            <Row className="justify-content-end mt-2">
              <Button color="secondary" onClick={closeeditmodal}>
                Cancel
              </Button>
              <button type="submit" className="btn listing_addbutton ml-1">
                Submit
              </button>
            </Row>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditModal;
