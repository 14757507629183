import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import "../css/ViewEnquiryWorkFlow.css";
import PropTypes from "prop-types";
import { deepOrange } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectdashboard } from "../features/dashboardSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CourseReport from "./CourseReport";
import CourseDetailPage from "./CourseDetailPage";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  logout,
} from "../features/userSlice";
import { getcoursepermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  viewheadtext: {
    fontSize: "10",
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  createBtn: {
    float: "right",
  },
  modalcss: {
    marginTop: "10%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dropdownbtn: {
    background: "blue",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  dropdownbtn2: {
    background: "white",
    color: "black",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  agentroot: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseView = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dashboardvalue = useSelector(selectdashboard);
  const [stateCust, setstateCust] = useState({});
  const [backdrop, setbackdrop] = useState(false);
  const history = useHistory();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [changestatusmodal, setchangestatusmodal] = useState(false);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(905, permission_obj, resource_obj)) {
      //if (available_permission ){
      dispatch(setdashboardcheck(23));
      setbackdrop(true);
      getCourseDetails();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const [courseStatusText, setcourseStatusText] = useState();
  const [coursestatus, setcoursestatus] = useState();
  const [coursestatususername, setcoursestatususername] = useState();

  const getCourseDetails = () => {
    axios
      .get(`${base_url.api2}/getCourseById`, {
        headers: {
          Authorization: jwtToken,
          courseId: params.course_id,
        },
      })
      .then((res) => {
        console.log(res);
        setstateCust(res.data.data);
        setcoursestatus(res.data.data.status);
        if (res.data.data.status === 1) setcourseStatusText("Unarchive");
        else setcourseStatusText("Archive");
        setbackdrop(false);
      })
      .catch((err) => {
        setbackdrop(false);
      });
  };

  const deleteClient = (clientDetail1) => {
    setcoursestatus(clientDetail1.status);
    setcoursestatususername(clientDetail1.courseId);
    setchangestatusmodal(true);
  };

  const deleteCourse = () => {
    const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    let status1;
    // console.log("Course status is : "+coursestatus)
    if (coursestatus === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      courseId: coursestatususername,
      status: status1,
    };
    axios
      .post(`${base_url.api2}/updateCourseById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success("Status Changed");
          setcoursestatus(response.data.data.status);
          if (response.data.data.status === 1) setcourseStatusText("Unarchive");
          else setcourseStatusText("Archive");
          setchangestatusmodal(false);
          let logs =
            isw_user_name +
            " Updated status of Courset " +
            response.data.data.email +
            " to " +
            response.data.data.status +
            ".";
          // callsaveauditapi(logs);
          getCourseDetails();
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          // console.log(error);
        }
      );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const editCourse = (elem) => {
    var course_id = elem.target.id;
    history.push("/edit-course/" + course_id);
  };
  return (
    <>
      <div class="sp lead-page" style={{ marginTop: "50px" }}>
        <div class="page-head d-flex justify-content-between p-4" style={{ marginTop: "5rem" }}>
          <ul class="breadcrums">
            <h3 class="page-title bold">Manage Course</h3>
            <a href="#/" class="b-link">Home</a>
            -
            <a class="b-link">Other</a>
            -
            <a class="b-link active">Course Dashboard</a>
          </ul>
          {/* <a
          className="sent-button"
          onClick={goback}
          style={{ float: "right" }}
        >
          List
        </a> */}
        </div>
        <div class="page-card_container p-4">
          <div class="content-wrapper">
            <div class="card client-profile" id="cardClient">
              <div class="minimize-profile">
                <span
                  onClick={() => {
                    let card = document.getElementById("cardClient");
                    card.style.display = "none";
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "0px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "visible";
                  }}
                >
                  {" "}
                  &#171;{" "}
                </span>
              </div>
              <div class="options">
                <input type="checkbox" id="option-checkbox" class="checkbox" />
                <label class="icon" for="option-checkbox">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="6" fill="white" />
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <div class="options-content">
                  <ul>
                    {/* {client_users != null ? (
                      client_users &&
                      client_users?.map((p, index) => ( */}
                    <>
                      {check1pointpermission(
                        704,
                        permissionData,
                        rolepermission
                      ) ? (
                        <li>
                          <a
                            onClick={editCourse}
                          >
                            Edit
                          </a>
                        </li>
                      ) : null}
                    </>
                    {/* )))} */}
                    <li>
                      {coursestatus === 1 ? (
                        <a
                          onClick={() => {
                            // deleteCourse(p.status, p.username)
                            setcoursestatus(stateCust.status);
                            setcoursestatususername(stateCust.courseId);
                            setchangestatusmodal(true);
                          }}
                        >
                          Archive
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // deleteCourse(p.status, p.username)
                            setcoursestatus(stateCust.status);
                            setcoursestatususername(stateCust.courseId);
                            setchangestatusmodal(true);
                          }}
                        >
                          Unarchive
                        </a>
                      )}
                    </li>
                    <li>
                      <a href="#">Option 3</a>
                    </li>
                  </ul>
                </div>
              </div>
              <Modal
                isOpen={changestatusmodal}
                onRequestClose={() => setchangestatusmodal(false)}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <h4 style={{ marginTop: "10px" }}>
                  Are you sure you want to{" "}
                  {coursestatus === 1 ? "Archive" : "Unarchive"} current
                  course ?{" "}
                </h4>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "flex-end",
                    gap: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    className="btn listing_addbutton ml-1"
                    onClick={deleteCourse}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-secondary cancel_margin"
                    onClick={() => setchangestatusmodal(false)}
                  >
                    No
                  </button>
                </div>
              </Modal>
              <br />
              <div class="client-img">
                <h2 class="mt-2">  {stateCust?.name}</h2>
                {/* <hr />
                {check1pointpermission(
                  903,
                  permissionData,
                  rolepermission
                ) ? (
                  <Link
                    id={stateCust?.courseId}
                    onClick={editCourse}
                    style={{ textAlign: "center" }}
                  >
                    Edit
                  </Link>
                ) : null} */}
              </div>
              <div class="personal-details">
                <div>
                  <Typography color="primary" variant="body1">
                    Cricos
                  </Typography>
                  <p>{stateCust?.cricos}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Category
                  </Typography>
                  <p>{stateCust?.categoryName}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Duration
                  </Typography>
                  <p>{stateCust?.duration}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Area
                  </Typography>
                  <p>{stateCust?.areaName}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Provider
                  </Typography>
                  <p>{stateCust?.institutionName}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Intake
                  </Typography>
                  <p>{stateCust?.intake}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Atar
                  </Typography>
                  <p>{stateCust?.atar}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    UAC
                  </Typography>
                  <p>{stateCust?.uac}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Onshore Price
                  </Typography>
                  <p>{stateCust?.fees}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Offshore Price
                  </Typography>
                  <p>{stateCust?.offShoreFees}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    App Fees
                  </Typography>
                  <p>{stateCust?.appFees}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Material Fees
                  </Typography>
                  <p>{stateCust?.materialFees}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Other Fees
                  </Typography>
                  <p>{stateCust?.otherFees}</p>
                </div>
              </div>
            </div>
            <div class="card client-details">
              <div class="maximize-profile">
                <span onClick={() => {
                  let card = document.getElementById("cardClient");
                  card.style.display = "block";
                }}> &#187; </span>
              </div>
              {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab className="tab-bold" label="Dashboard" {...a11yProps(0)} />
                <Tab className="tab-bold" label="Overview" {...a11yProps(1)} />
                <Tab className="tab-bold" label="Requirement" {...a11yProps(2)} />
                <Tab className="tab-bold" label="What will Study" {...a11yProps(3)} />
                <Tab className="tab-bold" label="Payment Summary" {...a11yProps(4)} />
                <Tab className="tab-bold" label="Objective" {...a11yProps(5)} />
                <Tab className="tab-bold" label="Contact" {...a11yProps(6)} />
              </Tabs>
              {/* </AppBar> */}
              <br />
              <TabPanel
                className="tabpanel"
                value={value}
                index={0}
                component={<CourseReport />}
              />

              <TabPanel
                value={value}
                index={1}
                component={
                  <CourseDetailPage
                    coursedetail={stateCust}
                    courseType="Overview"
                  />
                }
              />
              <TabPanel
                value={value}
                index={2}
                component={
                  <CourseDetailPage
                    coursedetail={stateCust}
                    courseType="Requirement"
                  />
                }
              />
              <TabPanel
                value={value}
                index={3}
                component={
                  <CourseDetailPage
                    coursedetail={stateCust}
                    courseType="Whatwillstudy"
                  />
                }
              />
              <TabPanel
                value={value}
                index={4}
                component={
                  <CourseDetailPage
                    coursedetail={stateCust}
                    courseType="Payment"
                  />
                }
              />
              <TabPanel
                value={value}
                index={5}
                component={
                  <CourseDetailPage
                    coursedetail={stateCust}
                    courseType="Objective"
                  />
                }
              />
              <TabPanel
                value={value}
                index={6}
                component={
                  <CourseDetailPage
                    coursedetail={stateCust}
                    courseType="Contact"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseView;
