import { Typography } from "@material-ui/core";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import PersonIcon from "@material-ui/icons/Person";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import "./../css/MainDashboard.css";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import Chart from "react-google-charts";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout } from "../features/userSlice";
import { useHistory } from "react-router-dom";

const MainDashboardBodytop = (props) => {

  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    // getclientoncity();
    getclientoncountry();
    getclientoncbranch();
  }, []);

  // const [data, setdata] = useState([["City", "Client_Count"]]);
  // const getclientoncity = () => {
  //   axios
  //     .get(`${base_url.api1}/getAllClientCountBasedOnCity`, {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       for (let i = 0; i < res.data.data.length; i++) {
  //         let newarr = [];
  //         newarr[0] = res.data.data[i].city;
  //         newarr[1] = Number(res.data.data[i].clientCount);
  //         // console.log(newarr);
  //         setdata((prev) => [...prev, newarr]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const [data2, setdata2] = useState([["Country", "Client_Count"]]);
  const getclientoncountry = () => {
    axios
      .get(`${base_url.api1}/getAllClientCountBasedOnCountries`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        // console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = res.data.data[i].country;
          newarr[1] = Number(res.data.data[i].clientCount);
          setdata2((prev) => [...prev, newarr]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [data3, setdata3] = useState([["Office", "Client_Count"]]);
  const getclientoncbranch = () => {
    axios
      .get(`${base_url.api1}/getAllClientCountBasedOnOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = res.data.data[i].officeName;
          newarr[1] = Number(res.data.data[i].clientCount);
          setdata3((prev) => [...prev, newarr]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //console.log(props.enquiryCount);
  return (
    <>
      <ul class="short-info-tabs">
        {/* <Row>
          <div className="d-flex justify-content-between">
            <h3 class="page-title" style={{ color: "#6d7290", fontWeight: "200" }}>
              Welcome, Good Morning
            </h3>
          </div>
        </Row> */}
        <br />
        <li class="tab-magenta">
          <p class="count">{props.leadtotal}</p>
          <p class="label">Enquiry</p>
        </li>
        <li class="tab-blue">
          <p class="count">{props.enquiryCount?.["Enquiry"] || 0}</p>
          <p class="label">Application Processing</p>
        </li>
        <li class="tab-yellow">
          <p class="count">{props.enquiryCount?.["Processing"] || 0}</p>
          <p class="label">Processing</p>
        </li>
        <li class="tab-cyan">
          <p class="count">{props.enquiryCount?.["OnGoing"] || 0}</p>
          <p class="label">On going</p>
        </li>
        <li class="tab-purple">
          <p class="count">{props.enquiryCount?.["DiscontinueComplete"] || 0}</p>
          <p class="label">Discontinued Complete</p>
        </li>
        <li class="tab-pink">
          <p class="count">{props.enquiryCount?.["RefundProcessing"] || 0}</p>
          <p class="label">Refund Processing</p>
        </li>
        <li class="tab-red">
          <p class="count">{props.enquiryCount?.["Completed"] || 0}</p>
          <p class="label">completed</p>
        </li>
        <li class="tab-green">
          <p class="count">{props.totalclient}</p>
          <p class="label">total clients</p>
        </li>


      </ul>
      {/* {user.isw_user_role === 1 || user.isw_user_role === 6 ?
        <div className="bodytop">
          <div className="row">
            <div className="col-md-6 ">
              <div className="body_top">
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={data2}
                  options={{
                    title: "Client Based on Country",
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="body_topright">
                <Chart
                  width={"100%"}
                  height={"300px"}
                  // chartType="PieChart"
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={data3}
                  options={{
                    title: "Client Based on Branch",
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </div>
          </div>
        </div>
      :null} */}
    </>


  );
};

export default MainDashboardBodytop;
