import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Button from '@material-ui/core/Button';
import "./../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { addtoAdmin } from "./../features/adminSlice";
import "./../css/Responsive.css";
import { logout, setdashboardcheck, selectUser } from "../features/userSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { useStyles } from "../../Utilities/CSSUtilities";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "./../Permission/permission";

function CreateAdmin(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const [officeset1, setofficeset1] = useState(true);
  const user = useSelector(selectUser);
  const isw_user_role_name = localStorage.getItem("isw_user_role_name");
  const isw_user_role = localStorage.getItem("isw_user_role");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [inputtype, setinputtype] = useState("password");
  const [setComment, setsetComment] = useState();
  const [selecteddate, setdate] = useState(null);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(601, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(19));
      getRoles();
      getallSpecificRoles();
      getOffice();
    } else {
      history.push("/errorPage");
    }
  };

  const [availableroles, setAvailableroles] = useState(null);
  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [specificroles, setSpecificRoles] = useState();
  const getallSpecificRoles = () => {
    axios
      .get(`${base_url.api3}/getActiveSpecificRoles`)
      .then((res) => {
        setSpecificRoles(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const [availableoffice, setAvailableOffice] = useState([]);
  const getOffice = () => {
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .get(`${base_url.api1}/findAllOffice`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let ofc_data = response.data.data;
          const office_obj = [];
          for (let i = 0; i < ofc_data.length; i++) {
            let data = {
              value: ofc_data[i].officeId,
              label: ofc_data[i].officeName,
              country: ofc_data[i].country,
              city: ofc_data[i].city,
              officeCode: ofc_data[i].officeCode,
            };
            console.log(data);
            office_obj.push(data);
          }
          setAvailableOffice(office_obj);
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      let ofc_data = user?.isw_available_Office;
      const office_obj = [];
      for (let i = 0; i < ofc_data.length; i++) {
        let data = {
          value: ofc_data[i].officeId,
          label: ofc_data[i].officeName,
          country: ofc_data[i].country,
          city: ofc_data[i].city,
          officeCode: ofc_data[i].officeCode,
        };
        console.log(data);
        office_obj.push(data);
      }
      setAvailableOffice(office_obj);
    }
  };

  const selectCountry = (val) => {
    console.log(val);
    setcountry(val);
  };

  const selectRegion = (val) => {
    console.log(val);
    setregion(val);
  };

  const [selectedrole, setSelectedrole] = useState(4);
  const [defaultRole, setdefaultRole] = useState("Consultant");
  const [rolecode, setrolecode] = useState("Consultant");
  const handleChangeRole = (event) => {
    setdefaultRole(event.target.value);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    const optionElementValue2 = optionElement.getAttribute("roleCode");

    setSelectedrole(optionElementId);
    setdefaultRole(optionElementValue);
    setrolecode(optionElementValue2);
  };

  const onSubmit = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let office = officeState;
    console.log(officeState, "hiii");
    console.log(office);
    console.log(office.length);
    let officeIdarray = [];
    for (let i = 0; i < office.length; i++) {
      officeIdarray.push(office[i].officeId);
    }
    let officeId = officeIdarray.join(",");
    console.log(officeIdarray);
    console.log(officeId);
    // let officeName = selectedOfficeName;
    let firstName = data2.firstName.trim();
    let lastName = data2.lastName.trim();
    let gender = data2.gender;
    let username = data2.email.trim();
    let email = data2.email.trim();
    // let password = data2.password.trim();
    let password;
    if (chkPass == false) {
      password = chkPassState.trim();
    }
    let phone = data2.phone;
    let address = data2.address;
    let street = data2.address;
    let country = country1;
    let nationality = country1;
    let passport = country1;
    let state = region;
    let city = data2.city;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    let dob;
    if (selecteddate) {
      dob = moment(selecteddate).format("YYYY-MM-DDThh:mm:ss");
    }
    // if (data2.dob == ""){
    //   dob = "";
    // } else {
    //   dob = moment(data2.dob).format("YYYY-MM-DDThh:mm:ss");
    // }
    let roleId = Number(selectedrole);
    let roleCode = rolecode;
    let roleName = defaultRole; //e.target[11].value;
    let pincode = Number(data2.pincode);
    let status = Number(data2.status);
    let ownerUser = 0;
    let addedOn = datenow;
    let updatedOn = datenow;
    let id = 0;
    let photo = "string";
    let comment = setComment;
    let data = {
      id,
      firstName,
      lastName,
      gender,
      username,
      password,
      phone,
      dob,
      roleId,
      office,
      officeId,
      // officeName,
      roleCode,
      roleName,
      status,
      country,
      email,
      ownerUser,
      nationality,
      passport,
      addedOn,
      updatedOn,
      photo,
      street,
      city,
      address,
      pincode,
      state,
      createdBy,
      comment,
    };
    // console.log(data);
    console.log(officeset1);
    console.log(officeState);
    if (chkPass == false) {
      if (officeset1 === false) {
        console.log("came to if");
        console.log(data);
        postUser(data);
      } else {
        console.log("came to else");
        // setOffice(null);
        console.log(data);
        // setofficeset1(true);
        postUser(data);
      }
    } else {
      return false;
    }
  };

  const postUser = (data) => {
    console.log(data);
    axios
      .post(`${base_url.api2}/createUserWithOffice`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          // console.log(response);
          toast.success("Success");
          dispatch(addtoAdmin(response.data.data));
          let logs =
            isw_user_name +
            " Created User " +
            response.data.data.firstName +
            " " +
            response.data.data.lastName +
            " of with Role " +
            response.data.data.roleName +
            ".";
          callsaveauditapi(logs);

          history.push("/users");
        },
        (error) => {
          toast.success(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
    // .catch((error) => {
    //   toast.error(JSON.stringify(error.response.data.message));
    // });
  };

  const [officeState, setOffice] = useState([{ officeId: 12, officeName: "Sydney-HO ", country: "Australia", city: "Sydney", officeCode: "AHO" }]);
  const onSelect = (selectedList, selectedItem) => {
    console.log(selectedList);

    const ofc_obj = [];
    if (
      selectedrole == "1" ||
      selectedrole == "2" ||
      selectedrole == "3" ||
      selectedrole == "5"
    ) {
      console.log("coming in if");
      for (let i = 0; i < selectedList.length; i++) {
        let data = {
          officeId: Number(selectedList[i].value),
          officeName: selectedList[i].label,
          country: selectedList[i].country,
          city: selectedList[i].city,
          officeCode: selectedList[i].officeCode,
        };
        ofc_obj.push(data);
        console.log(data);
      }
      if (ofc_obj.length == 0) {
        setofficeset1(true);
      } else {
        setofficeset1(false);
        setOffice(ofc_obj);
      }
    } else {
      console.log("coming in else");
      // let data = {
      //   officeId: Number(selectedList.value),
      //   officeName: selectedList.label,
      //   country: selectedList.country,
      //   city: selectedList.city,
      //   officeCode: selectedList.officeCode,
      // };
      // ofc_obj.push(data);



      for (let i = 0; i < selectedList.length; i++) {
        let data = {
          officeId: Number(selectedList[i].value),
          officeName: selectedList[i].label,
          country: selectedList[i].country,
          city: selectedList[i].city,
          officeCode: selectedList[i].officeCode,
        };
        ofc_obj.push(data);
        console.log(data);
      }
      if (ofc_obj.length == 0) {
        setofficeset1(true);
      } else {
        setofficeset1(false);
        setOffice(ofc_obj);
      }
    }
  };

  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    const optionElementCountry = optionElement.getAttribute("country");
    const optionElementCity = optionElement.getAttribute("city");
    const optionElementOfficeCode = optionElement.getAttribute("officeCode");

    const ofc_obj = [];
    let data = {
      officeId: Number(optionElementId),
      officeName: optionElementName,
      country: optionElementCountry,
      city: optionElementCity,
      officeCode: optionElementOfficeCode,
    };
    ofc_obj.push(data);
    console.log(ofc_obj);
    if (optionElementName == "") {
      setofficeset1(true);
    } else {
      setofficeset1(false);
      setOffice(ofc_obj);
    }
  };

  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();
  // const handlePassword = (e) => {
  //   var decimal =
  //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  //   console.log(e.target.value);
  //   if (e.target.value.match(decimal)) {
  //     setchkPass(false);
  //     setchkPassState(e.target.value);
  //     return true;
  //   } else {
  //     setchkPass(true);
  //     // setchkPassState(e.target.value);
  //     return false;
  //   }
  // };

  const goback = () => {
    history.push("/users");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      {/* <Dashboard/> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.6rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Create User</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Access Level</a><span className="s_span ">-</span>
          <a className="b-link active">User Add</a>
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <Row>
          {/* <Col md={6}>
              <CardText className={classes.headtext}>
                <strong>Create User</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col> */}
        </Row>
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
          <div style={{ padding: "0 20px " }}>
            <div className="form-groupone">
              <Row>
                <Col md={3}>
                  <label>
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="firstName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.firstName && (
                    <p className="errormessage">FirstName is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="lastName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.lastName && (
                    <p className="errormessage">LastName is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Gender<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.gender && (
                    <p className="errormessage">Gender is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Email/Username<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control underline-input"
                    name="email"
                    style={{ backgroundColor: "#f5f5f9" }}
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  {errors.email && (
                    <p className="errormessage">Email is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-groupone creatadminone">
              <Row>
                <Col md={3}>
                  <label>
                    Password<span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <input
                        type="password"
                        className="form-control underline-input"
                        onChange={handlePassword}
                        // ref={register({
                        //   required: true,
                        //   minLength: {
                        //     value: 8,
                        //     message: "Password must have at least 8 characters",
                        //   },
                        // })}
                        name="password"
                        required
                      />
                      {chkPass ? (
                        <p className="errormessage">
                          Password must have at least 8 characters with at least
                          one lowercase letter, one uppercase letter, one
                          numeric digit, and one special character
                        </p>
                      ) : null} */}
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      className="form-control underline-input"
                      value={chkPassState}
                      style={{ backgroundColor: "#f5f5f9" }}
                      autoComplete="off"
                      onChange={(e) => {
                        setchkPassState(e.target.value);
                        setchkPass(false);
                      }}
                      required
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} />

                  {/* {errors.password && (
                        <p className="errormessage">
                          Password must have at least 8 characters
                        </p>
                      )} */}
                </Col>
                <Col md={3}>
                  <label>Contact No.</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="phone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Address<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="address"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.address && (
                    <p className="errormessage">Address is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Country<span style={{ color: "red" }}>*</span>
                  </label>
                  <CountryDropdown
                    className="form-control underline-input countrydropdown2"
                    defaultOptionLabel="- Select Country -"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-groupone creatadminone">
              <Row>
                <Col md={3}>
                  <label>
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">
                        Bagmati Province
                      </option>
                      <option value="Gandaki Province">
                        Gandaki Province
                      </option>
                      <option value="Lumbini Province">
                        Lumbini Province
                      </option>
                      <option value="Karnali Province">
                        Karnali Province
                      </option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      className="form-control underline-input countrydropdown2"
                      defaultOptionLabel="- Select State -"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    City<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>
                <Col md={3} style={{ position: "relative" }}>
                  <label>Date Of Birth</label>
                  <DatePicker
                    selected={selecteddate}
                    onChange={(date) => setdate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Role<span style={{ color: "red" }}>*</span>
                  </label>
                  {isw_user_role == 1 || isw_user_role == 6 ? (
                    <select
                      defaultValue={defaultRole}
                      value={defaultRole}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control"
                      onChange={(e) => {
                        // console.log(e.target.value);
                        handleChangeRole(e);
                      }}
                      name="role"
                      ref={register({ required: true })}
                    >
                      <option value="">- Select Role -</option>
                      {availableroles == null ? (
                        <Backdrop className={classes.backdrop} open>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      ) : (
                        availableroles.map((cuntitem) => (
                          <option
                            key={cuntitem.roleId}
                            id={cuntitem.id}
                            value={cuntitem.roleName}
                            roleCode={cuntitem.roleCode}
                          >
                            {cuntitem.roleName}
                          </option>
                        ))
                      )}
                    </select>
                  ) : (
                    <select
                      defaultValue={defaultRole}
                      value={defaultRole}
                      style={{ backgroundColor: "#f5f5f9" }}
                      className={classes.selectdrop}
                      onChange={(e) => {
                        // console.log(e.target.value);
                        handleChangeRole(e);
                      }}
                      name="role"
                      ref={register({ required: true })}
                    >
                      <option value="">- Select Role -</option>
                      {specificroles == null ? (
                        <Backdrop className={classes.backdrop} open>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      ) : (
                        specificroles.map((cuntitem) => (
                          <option
                            key={cuntitem.roleId}
                            id={cuntitem.id}
                            value={cuntitem.roleName}
                            roleCode={cuntitem.roleCode}
                          >
                            {cuntitem.roleName}
                          </option>
                        ))
                      )}
                    </select>
                  )}
                  {errors.role && (
                    <p className="errormessage">Role is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-groupone creatadminone" style={{ marginBottom: "-25px", marginLeft: "5px" }}>
              <div div className="workflowcol" style={{ display: "flex", gap: "10px" }}>
                <div style={{ width: "331px" }}>
                  <label>PostCode</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="pincode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                </div>
                <div style={{ width: "331px" }}>
                  <label>
                    Status<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-control"
                    name="status"
                    ref={register({ required: true })}
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {errors.status && (
                    <p className="errormessage">Status is Required</p>
                  )}
                </div>
                <div style={{ width: "670px" }}>
                  <label>
                    Select Branch<span style={{ color: "red" }}>*</span>
                  </label>
                  {/* {selectedrole == "1" ||
                    selectedrole == "2" ||
                    selectedrole == "3" ||
                    selectedrole == "4" ||
                    selectedrole == "5" ||
                    selectedrole == "6" ? ( */}
                  <Select
                    // defaultValue={officeState}
                    isMulti
                    name="officeName"
                    options={availableoffice}
                    className="brach_dropdown"
                    classNamePrefix="select"
                    //defaultValue={{ label: "Sydney-HO", value: 12 }}
                    defaultValue={{ value: 12, label: "Sydney-HO ", country: "Australia", city: "Sydney", officeCode: "AHO" }}
                    onChange={onSelect}
                    required
                  />
                  {/* ) : (
                      <Select
                        // defaultValue={officeState}
                        isMulti={false}
                        name="officeName"
                        options={availableoffice}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelect}
                        required
                      />
                    )} */}
                  <br />
                  {officeset1 ? (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      Select Atleast One Branch
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-groupone creatadminone">
              <Row>
                <Col md={12}>
                  <label>Remarks</label>
                  <TextareaAutosize
                    rowsMax={6}
                    style={{ height: "100px", backgroundColor: "#f5f5f9" }}
                    placeholder="Write Remarks"
                    // defaultValue={clientuser?.comments}
                    className="form-control underline-input"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setsetComment(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end mt-2">
                <button
                  className="outline-button"
                  color="secondary"
                  onClick={() => {
                    document.getElementById("myForm").reset();
                  }}
                  style={{ marginRight: "10px" }}
                >
                  RESET
                </button>
                <button type="submit" className="sent-button">
                  SUBMIT
                </button>
              </Col>
            </Row>
            {/* <Button
                  color="primary"
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </Button> */}
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
}

export default withRouter(CreateAdmin);
