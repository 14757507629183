import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";

const GroupPermission = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  // console.log(alladmin);
  const history = useHistory();
  const [backdropopen, setbackdropopen] = useState(false);
  const jwtToken = "Bearer " + user.isw_user_token;
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [rows, setrows] = React.useState(50);
  const { register, handleSubmit, errors } = useForm();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [sortState, setsortState] = useState("groupName");
  const [direction, setdirection] = useState("ASC");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allGroupPermission, setAllGroupPermission] = useState();
  const [display1, setdisplay] = useState("inline");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allGroupPermission]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1519, 1522, permission_obj, resource_obj)) {
      //if (available_permission) {
      getAllGroupPermissions(pageno);
      dispatch(setdashboardcheck(46));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllGroupPermissions(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const [searchdata, setsearch] = useState();

  const getAllGroupPermissions = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getAllGroups`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setAllGroupPermission(response.data.data.content);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
          setsearch(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deletestate, setdeletestate] = useState();
  const openModal = (elem, p) => {
    setdeletestate(p.groupName);
    setDeleteGroup(elem.target.id);
    setIsOpen(true);
  };
  const deleteCurrentUser = (elem) => {
    console.log(elem.target);
    axios
      .delete(`${base_url.api1}/deleteGroupById`, {
        headers: {
          Authorization: jwtToken,
          Id: deleteGroup,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);

          let log =
            isw_user_name + " Deleted Group Permission " + deletestate + ".";
          callsaveauditapi(log);
          setIsOpen(false);
          getAllGroupPermissions(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = () => {
    history.push("/create-group-permissions");
  };

  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [editproId, seteditproId] = useState();
  const [editproName, seteditproName] = useState();
  const [editSourceStatus, seteditSourceStatus] = useState();
  const editGroup = (elem, p) => {
    localStorage.setItem("groupPageNo", pageno);
    history.push("/edit-group-permissions/" + elem.target.id);
  };

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        id: Number(elem.target.id),
        status: Number(0),
      };
      axios
        .post(`${base_url.api1}/updateGroupById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllGroupPermissions(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        id: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api1}/updateGroupById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllGroupPermissions(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const submitrows = (e) => {
    getAllGroupPermissions(0);
  };

  // Search Data by name

  const [searchinput, setsearchinput] = useState();
  const submitsearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api1}/searchGroup`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: "groupName",
          direction: "DESC",
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setsearch(true);
        setAllGroupPermission(res.data.data.content);
        // document.getElementById("myForm").reset();
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropopen(false);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        setbackdropopen(false);
        setAllGroupPermission();
        handle403error(err);
      });
  };

  // const searchbystatus = (event) => {
  //   setbackdropopen(true);
  //   const index = event.target.selectedIndex;
  //   const optionElement = event.target.childNodes[index];
  //   const value = optionElement.getAttribute("value");
  //   if (value === "all") {
  //     getAllGroupPermissions(pageno);
  //   } else {
  //     axios
  //       .get(`${base_url.api1}/getClientSourceByStatus`, {
  //         headers: {
  //           Authorization: jwtToken,
  //           status: Number(value),
  //           page: Number(pageno),
  //           size: Number(rows),
  //         },
  //       })
  //       .then((response) => {
  //         setgetpageno(response.data.data.totalPages);
  //         setAllGroupPermission(response.data.data.content);
  //         settotalUser(response.data.data.totalElements);
  //         setcountnumber(response.data.data.number);
  //         setnumberofElements(response.data.data.numberOfElements);
  //         setsearch(false);
  //         setbackdropopen(false);
  //         document.getElementById("myForm").reset();
  //       })
  //       .catch((err) => {
  //         setbackdropopen(false);
  //         console.log(err);
  //       });
  //   }
  // };

  const sortGroup = (e) => {
    console.log(e.target.id);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallInsurance(pageno);
    axios
      .get(`${base_url.api1}/getAllGroups`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setAllGroupPermission(response.data.data.content);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
          setsearch(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Group Permissions</h3>
          <a href="#/" class="b-link">Home</a>
          <span className="s_span ">-</span>
          <a class="b-link">Settings</a>
          <span className="s_span ">-</span>
          <a class="b-link active">Group Permission</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1520, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={openModal2}
            style={{ float: "right", height: "40px" }}
          >
            Add
          </a>
        ) : null}
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Manage Group Permissions</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
            {permissionData?.includes(99) ? (
              <button className="btn listing_addbutton" onClick={openModal2}>
                <AddIcon className="listing_addbutton_i" /> Add
              </button>
            ) : null}
          </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              <Row>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <div style={{ marginTop: "-10px", marginRight: "20px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className="s_client_setting"
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
                {/* <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
                    <label className="area_rowslable">Status</label>

                    <select className="form-control" onChange={searchbystatus}>
                      <option value="all">All</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div> */}
              </Row>
            </Col>
            <Col md={3}>{null}</Col>
            <Col md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitsearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getAllGroupPermissions(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allGroupPermission?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {/* {permissionData?.includes(99) ? (
                <a className="btn btn-success" onClick={openModal2} style={{ float: "right",height:"40px" }}>
                  Add
                </a>
              ) : null} */}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "left", width: "100px" }}>SN</th>
                  <th style={{ textAlign: "center", width: "300px" }}>
                    NAME
                    <img
                      src={sort}
                      className="sorting"
                      id="groupName"
                      onClick={sortGroup}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ textAlign: "center", width: "300px" }}>
                    STATUS
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortGroup}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  {check4pointpermission(
                    1521,
                    1522,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ textAlign: "center", width: "400px" }}>
                      ACTION
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allGroupPermission != null ? (
                  allGroupPermission &&
                  allGroupPermission?.map((p, index) => (
                    <tr key={p.id}>
                      <td style={{ textAlign: "left" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{p.groupName}</td>
                      <td style={{ textAlign: "center", width: "175px" }}>
                        {/* {permissionData?.includes(359) ? ( */}
                        <Link id={p.id} onClick={(e) => changeStatus(e, p)}>
                          {p.status == 1 ? "Active" : "Inactive"}
                        </Link>
                        {/* ) : (
                          <>{p.status == 1 ? "Active" : "Inactive"}</>
                        )} */}
                      </td>
                      {check4pointpermission(
                        1521,
                        1522,
                        permissionData,
                        rolepermission
                      ) ? (
                        <td
                          id={p.id}
                          style={{ textAlign: "center", width: "175px" }}
                        >
                          {check1pointpermission(
                            1521,
                            permissionData,
                            rolepermission
                          ) ? (
                            <a
                              id={p.id}
                              onClick={(e) => {
                                e.preventDefault();
                                editGroup(e, p);
                              }}
                              href={`#/edit-group-permissions/` + p.id}
                            >
                              Edit
                            </a>
                          ) : null}
                          &nbsp;|&nbsp;
                          {check1pointpermission(
                            1522,
                            permissionData,
                            rolepermission
                          ) ? (
                            <Link
                              id={p.id}
                              onClick={(e) => {
                                e.preventDefault();
                                openModal(e, p);
                              }}
                            >
                              Delete
                            </Link>
                          ) : null}
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h4 style={{ marginTop: "10px" }}>
                              Are you sure you want to delete current record ?
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "flex-end",
                                gap: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <button
                                className="btn listing_addbutton ml-1"
                                onClick={deleteCurrentUser}
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn-secondary cancel_margin"
                                onClick={closeModal}
                              // style={{ marginLeft:"50px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </Modal>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allGroupPermission?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser} records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  count={getpageno}
                  onChange={handlepage}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default GroupPermission;
