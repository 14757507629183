import axios from "axios";
import base_url from "./../api/bootapi";
import moment from "moment";

const callsaveauditapi = (log) => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  let createdByName = isw_user_name + " (" + isw_user_email + ")";

  let todayDate = new Date()
  let fmt = 'YYYY-MM-DD HH:mm:ss';
  let m = moment.utc(todayDate, fmt);
  let createdAt = m.local().format(fmt);

  let auditdata = {
    adminIp: ipaddress,
    createdById: isw_adminId,
    createdByName: createdByName,
    id: 0,
    log,
    createdAt,
  };
  axios.post(`${base_url.api4}/saveAudit`, auditdata, {
    headers: { Authorization: jwtToken },
  });
};

export default callsaveauditapi;
