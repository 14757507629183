import React, { useEffect, useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./../css/LoginForm.css";
import { selectAdmin } from "./../features/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import "./../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { check4pointpermission,  check1pointpermission } from "../Permission/permission";

function EditProfile(props) {
  let params = useParams();
  const admin = useSelector(selectAdmin);
  const history = useHistory();
  const username2 = params.admin_username;
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const user = useSelector(selectUser);
  const [dateofbirthdate, setdateofbirthdate] = useState(null);
  const particularUser = [];
  admin.map((item) => {
    if (item.username === username2) {
      particularUser.push(item);
    }
  });
  // console.log("Particular User", particularUser);
  const dispatch = useDispatch();
  // console.log(params.admin_username);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const classes = useStyles();
  const datepast16 = moment().subtract(16, "Y").format("YYYY-MM-DD");
  const [stateCust, setstateCust] = useState({});
  const [availableroles, setAvailableroles] = useState(null);
  const [officeState, setOffice] = useState();
  const [officeState2, setOffice2] = useState(null);
  const [officeset1, setofficeset1] = useState(true);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1,11, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(19));
      getUser();
      getRoles();
      getOffice();
    } else {
      history.push("/errorPage");
    }
  };



  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.data.content);
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  // console.log(availableroles);

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let ofc_data = response.data.data;
        const office_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          const ofc_data2 = ofc_data[i];
          // console.log(ofc_data2);
          let data = {
            value: ofc_data[i].officeId,
            label: ofc_data[i].officeName,
            country: ofc_data[i].country,
            city: ofc_data[i].city,
          };
          office_obj.push(data);
        }
        // console.log(response.data.data);
        setAvailableOffice(office_obj);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  // console.log(availableoffice);

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const getUser = () => {
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: username2,
        },
      })
      .then((response) => {
        let userData = response.data.data;
        let ofc_data = response.data.userOfficeData;
        let ofcData_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          const element = ofc_data[i];
          let data = {
            value: ofc_data[i].officeId,
            label: ofc_data[i].officeName,
            country: ofc_data[i].country,
            city: ofc_data[i].city,
          };
          ofcData_obj.push(data);
        }
        if (ofcData_obj.length == 0) {
          setofficeset1(true);
        } else {
          setofficeset1(false);
        }
        setOffice(ofcData_obj);
        setOffice2(ofcData_obj);
        setcountry(userData.country);
        setregion(userData.state);
        setdateofbirthdate(userData.dob);
        setstateCust({
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          gender: userData.gender,
          email: userData.email,
          phone: userData.phone,
          address: userData.address,
          street: userData.street,
          pincode: userData.pincode,
          city: userData.city,
          country: userData.country,
          state: userData.state,
          nationality: userData.nationality,
          passport: userData.passport,
          roleId: userData.roleId,
          photo: userData.photo,
          dob: userData.dob,
          status: userData.status,
          ownerUser: userData.ownerUser,
          addedOn: userData.addedOn,
          updatedOn: userData.updatedOn,
          lastLogin: userData.lastLogin,
          roleName: userData.roleName,
          id: userData.id,
        });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const putCustomer = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.nationality = country1;
    stateCust.passport = country1;
    stateCust.state = region;
    stateCust.roleId = Number(selectedrole);
    stateCust.roleName = defaultRole;
    stateCust.dob = moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss");

    const ofc_obj = [];
    // console.log(officeState2.length);
    if (officeset1 == false) {
      setofficeset1(false);
      for (let i = 0; i < officeState2.length; i++) {
        let data = {
          officeId: Number(officeState[i].value),
          officeName: officeState[i].label,
          country: officeState[i].country,
          city: officeState[i].city,
        };
        ofc_obj.push(data);
      }
      console.log(ofc_obj);
      stateCust.office = officeState2;

      console.log("From Statecust is", stateCust);
      if (ofc_obj != 0) {
        axios
          .post(`${base_url.api2}/updateUserByEmail`, stateCust, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              console.log(response.data.data.data);
              toast.success("Success");
              // dispatch(
              //   updateAdmin({
              //     id: stateCust.username,
              //     data: response.data.data.data,
              //   })
              // );

              let log =
                isw_user_name +
                " Updated User " +
                response.data.data.firstName +
                " " +
                response.data.data.lastName +
                " of with Role " +
                response.data.data.roleName +
                ".";
              callsaveauditapi(log);
              history.push("/profile");
            },
            (error) => {
              handle403error(error);
              toast.error(JSON.stringify(error.response.data.message));
            }
          );
      } else {
        setofficeset1(true);
      }
    } else {
      setofficeset1(true);
    }
    // for (let i = 0; i < officeState2.length; i++) {
    //   let data ={
    //     officeId: Number(officeState[i].value),
    //     officeName: officeState[i].label,
    //     country: officeState[i].country,
    //     city: officeState[i].city,
    //   }
    //   ofc_obj.push(data);
    // }
    // console.log(ofc_obj);

    // console.log(officeset1);
    // console.log(officeState2);
    // console.log(officeState);
    // console.log(stateCust.office);
  };

  const onSelect = (selectedList, selectedItem) => {
    // console.log(selectedList);
    const client_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        officeId: Number(selectedList[i].value),
        officeName: selectedList[i].label,
        country: availableoffice[i].country,
        city: availableoffice[i].city,
      };
      client_obj.push(data);
    }
    // setAvailableOffice()
    // console.log(availableoffice);
    console.log(selectedList);
    // setOffice(selectedList)
    // setOffice2(client_obj);
    // setofficeset1(false);
    console.log(client_obj);
    if (client_obj.length == 0) {
      setofficeset1(true);
      setOffice(null);
      setOffice2(null);
    } else {
      setofficeset1(false);
      setOffice2(client_obj);
      setOffice(selectedList);
    }
  };

  const [selectedrole, setSelectedrole] = useState(4);
  const [defaultRole, setdefaultRole] = useState("Consultant");
  const handleChangeRole = (event) => {
    setdefaultRole(event.target.value);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    setstateCust.roleId = optionElementId;
    setstateCust.roleName = optionElementValue;
    setSelectedrole(optionElementId);
    setdefaultRole(optionElementValue);
    // stateCust.roleId
    console.log(optionElementId);
    console.log(optionElementValue);
  };

  const goback = () => {
    history.push("/profile");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      {/* <Dashboard/> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "5rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Edit Profile</h3>
          {/* <a href="index.html" className="b-link">Home</a>
          -
          <a href="client-dashboard.html" className="b-link">Client</a>
          -
          <a href="#" className="b-link active">Client</a> */}
        </div>
        <a onClick={goback} className="back-btn">
          Back
        </a>
      </div>
      {availableroles == null ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putCustomer(e);
          }}
          style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}
        >
          <div className="form-inner-admin">
            <Row>
              {/* <Col md={6}>
                  <CardText className={classes.headtext}>
                    <strong>Edit Profile</strong>
                  </CardText>
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                  <button className="btn listing_addbutton" onClick={goback}>
                    &#x2630; List
                  </button>
                </Col> */}
            </Row>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    First Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.firstName}
                    name="firstName"
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Last Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.lastName}
                    name="lastName"
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Gender<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    defaultValue={stateCust.gender}
                    className={classes.selectdrop}
                    name="gender"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.gender}
                    onChange={inputEvent}
                    // id={stateCust.gender}
                    required
                  >
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Others">Others</option>
                  </select>
                </Col>
                <Col md={3}>
                  <label>
                    Email / Username<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="email"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.email}
                    name="username"
                    onChange={(e) => {
                      const value = e.target.value;
                      stateCust.email = value;
                      inputEvent(e);
                    }}
                    className="form-control form-control-sm"
                    readOnly
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    Contact No<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.phone}
                    name="phone"
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Address<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.address}
                    name="address"
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    className="form-control underline-input countrydropdown2"
                    defaultOptionLabel="- Select Country -"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      value={region}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      className="form-control underline-input countrydropdown2"
                      defaultOptionLabel="- Select State -"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    City<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust.city}
                    name="city"
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust.pincode}
                    name="pincode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={inputEvent}
                    className="form-control form-control-sm"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>Date of Birth:</label>

                  <DatePicker
                    selected={
                      dateofbirthdate ? new Date(dateofbirthdate) : null
                    }
                    onChange={(date) => setdateofbirthdate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    style={{ backgroundColor: "#f5f5f9" }}
                    dropdownMode="select"
                    placeholderText="Select Date"
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Role<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    defaultValue={stateCust.roleName}
                    value={stateCust.roleName}
                    className={classes.selectdrop}
                    id={stateCust.roleId}
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="roleName"
                    onChange={(e) => {
                      handleChangeRole(e);
                      const index = e.target.selectedIndex;
                      const optionElement = e.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      const optionElementvalue =
                        optionElement.getAttribute("value");
                      setstateCust.roleId = optionElementId;
                      setstateCust.roleName = optionElementvalue;
                      inputEvent(e);
                    }}
                    required
                  >
                    {availableroles.map((cuntitem) => (
                      <option
                        key={cuntitem.id}
                        id={cuntitem.id}
                        value={cuntitem.roleName}
                      >
                        {cuntitem.roleName}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    Status<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    defaultValue={stateCust.status == 1 ? "Active" : "InActive"}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className={classes.selectdrop}
                    name="status"
                    value={stateCust.status}
                    onChange={inputEvent}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                  </select>
                </Col>
                <Col md={9}>
                  <label>
                    Select Branch<span style={{ color: "red" }}>*</span>:
                  </label>
                  <Select
                    value={officeState}
                    isMulti
                    name="officeName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    options={availableoffice}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onSelect}
                  />
                </Col>
                <br />
                {officeset1 ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "25%",
                    }}
                  >
                    Select Atleast One Branch
                  </p>
                ) : null}
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn listing_addbutton ml-1">
              Update
            </button>
          </div>
        </form>
      )}
      {/* </Card> */}
    </div>
  );
}

export default withRouter(EditProfile);
