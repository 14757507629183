import React, { useCallback, useMemo, useRef } from 'react';
import { createRoot } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import GetData from './data';
// import '@ag-grid-community/styles/ag-grid.css';
// import '@ag-grid-community/styles/ag-theme-alpine.css';
// import './style.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { Button, Typography } from "@material-ui/core";
import "./../css/MainDashboard.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Chart from "react-google-charts";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getworkflowType, setdashboardcheck, gettabvalue, selectUser, logout } from "../features/userSlice";
import { useState } from "react";
import base_url from "../../api/bootapi";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-modal";
// import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MainDashboardBodybottom from "./MainDashboardBodybottom";
import MainDashboardBodybottomskillassessment from "./MainDashboardBodybottomskillassessment";
import MainDashboardBodybottominsurance from "./MainDashboardBodybottominsurance";
import MainDashboardBodybottommigration from "./MainDashboardBodybottommigration";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import graph1 from "./../images/graph.png";
import piegraph from "./../images/pie-graph.png";
import bargraph1 from "./../images/bargraph.png";
import chevron from "./../images/chevron-down-small.svg";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { check4pointpermission, check1pointpermission } from "../Permission/permission";
import { Table } from "reactstrap";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  GridChartsModule,
  RowGroupingModule,
]);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxWidth: "800px",
    maxHeight: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
const MainDashboardBodymiddle = (props) => {
  // console.log(props);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [filterresult, setFilterResult] = useState("Intake");
  const [filterresult, setFilterResult] = useState("UserCount");
  const [filterClientResult, setFilterClientResult] = useState([]);
  var subtitle;
  const history = useHistory();
  const dispatch = useDispatch();

  const [clientcountby, setclientcountby] = useState();

  const getclientcountbyuser = (val) => {
    if (val === "all") {
      axios
        .get(`${base_url.api2}/clientConsultantCount`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          setclientcountby(res.data.data);
        });
    } else {
      axios
        .get(`${base_url.api2}/clientConsultantCount`, {
          headers: {
            Authorization: jwtToken,
            officeId: val,
          },
        })
        .then((res) => {
          setclientcountby(res.data.data);
        });
    }
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    // setFilterResult("Intake");
    console.log("papul Provider obj", props.topprovider);
    let data9 = [];
    for (let i = 0; i < props.topprovider?.length; i++) {
      let newarr = {
        institutionName: props.topprovider[i]?.institutionName,
        ProviderEnquiryCount: Number(props.topprovider[i]?.enquiryCount),
      };
      data9.push(newarr);
      console.log(newarr);
      console.log("sangam Provider obj", data9);
      if (props.topprovider.length - 1 == i) {
        setRowData(data9);
      }
    }
    getPermissions();
  }, [props.officeid, props.topprovider]);

  useEffect(() => {
    console.log("papulcourseobj", props.topcourse);
    let data10 = [];
    for (let i = 0; i < props.topcourse?.length; i++) {
      let secondnewarr = {
        courseName: props.topcourse[i]?.courseName,
        CourseEnquiryCount: Number(props.topcourse[i]?.enquiryCount),
      };
      data10.push(secondnewarr);
      console.log(secondnewarr);
      console.log("sangam Course obj", data10);
      if (props.topcourse.length - 1 == i) {
        setsecondRowData(data10);
        console.log("data10-------->", data10);
      }
    }
  }, [props.topcourse])

  let office_id;

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1, 11, permission_obj, resource_obj)) {
      setFilterResult("UserCount");
      getclientenquirycount(props.officeid);
      getclientoncountry();
      getclientoncbranch();
      getintakeresult(props.officeid);
    }
  }
  const [clientdetails, setclientdetails] = useState([]);
  const openModal = (value1, value2) => {
    console.log(props.officeid);
    setclientdetails([]);
    if (props.officeid === "all") {
      axios
        .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowName: value2,
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].workflowType === value1) {
              setclientdetails((prev) => [...prev, res.data.data[i]]);
            }
          }
          setIsOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getClientEnquiryDetailsBasedOnOfficeIdAndWorkflowType`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(props.officeid),
              workflowName: value2,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].workflowType === value1) {
              setclientdetails((prev) => [...prev, res.data.data[i]]);
            }
          }
          setIsOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const [admissionclientenquirycount, setadmissionclientenquirycount] =
    useState([]);
  const [migrationclientenquirycount, setmigrationclientenquirycount] =
    useState([]);
  const [insuranceclientenquirycount, setinsuranceclientenquirycount] =
    useState([]);
  const [
    skillassessmentclientenquirycount,
    setskillassessmentclientenquirycount,
  ] = useState([]);
  const getclientenquirycount = (value) => {
    console.log("Officeidiinmiddlepage", value);
    setadmissionclientenquirycount([]);
    setmigrationclientenquirycount([]);
    setinsuranceclientenquirycount([]);
    setskillassessmentclientenquirycount([]);
    if (value) {
      if (value === "all") {
        axios
          .get(`${base_url.api1}/getClientEnquiryCount`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            // setFilterClientResult(res.data.data);
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === "Admission") {
                setadmissionclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              } else if (res.data.data[i].workflowType === "Migration") {
                setmigrationclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              } else if (res.data.data[i].workflowType === "Insurance") {
                setinsuranceclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              } else if (res.data.data[i].workflowType === "SkillAssessment") {
                setskillassessmentclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .get(`${base_url.api1}/getClientEnquiryCountBasedOnOfficeId`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
            },
          })
          .then((res) => {
            console.log("admissionenquiryworkflowtype", res);
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === "Admission") {
                setadmissionclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              } else if (res.data.data[i].workflowType === "Migration") {
                setmigrationclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              } else if (res.data.data[i].workflowType === "Insurance") {
                setinsuranceclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              } else if (res.data.data[i].workflowType === "SkillAssessment") {
                setskillassessmentclientenquirycount((prev) => [
                  ...prev,
                  res.data.data[i],
                ]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const data = [["CurrentStage", "WorkflowCount"]];
  for (let i = 0; i < props.admissionenquiryworkflowsteps?.length; i++) {
    let newarr = [];
    newarr[0] = props.admissionenquiryworkflowsteps[i]?.currentStage;
    newarr[1] = Number(props.admissionenquiryworkflowsteps[i]?.enquiryCount);
    data.push(newarr);
  }
  // const data2 = [["WorkflowName", "WorkflowCount"]];
  // for (let i = 0; i < props.migrationenquiryworkflowsteps?.length; i++) {
  //     let newarr = [];
  //     newarr[0] = props.migrationenquiryworkflowsteps[i]?.currentStage;
  //     newarr[1] = Number(props.migrationenquiryworkflowsteps[i]?.enquiryCount);
  //     data2.push(newarr);
  // }
  // const data3 = [["WorkflowName", "WorkflowCount"]];
  // for (let i = 0; i < props.insuranceenquiryworkflowsteps?.length; i++) {
  //     let newarr = [];
  //     newarr[0] = props.insuranceenquiryworkflowsteps[i]?.currentStage;
  //     newarr[1] = Number(props.insuranceenquiryworkflowsteps[i]?.enquiryCount);
  //     data3.push(newarr);
  // }
  const data4 = [["WorkflowName", "WorkflowCount"]];
  for (let i = 0; i < props.skillassessmentenquiryworkflowsteps?.length; i++) {
    let newarr = [];
    newarr[0] = props.skillassessmentenquiryworkflowsteps[i]?.currentStage;
    newarr[1] = Number(
      props.skillassessmentenquiryworkflowsteps[i]?.enquiryCount
    );
    data4.push(newarr);
  }
  const data5 = [["WorkflowName", "ClientCount"]];
  for (let i = 0; i < admissionclientenquirycount?.length; i++) {
    let newarr = [];
    newarr[0] = admissionclientenquirycount[i]?.workflowName;
    newarr[1] = Number(admissionclientenquirycount[i]?.clientCount);
    data5.push(newarr);
  }
  const data6 = [["WorkflowName", "ClientCount"]];
  for (let i = 0; i < migrationclientenquirycount?.length; i++) {
    let newarr = [];
    newarr[0] = migrationclientenquirycount[i]?.workflowName;
    newarr[1] = Number(migrationclientenquirycount[i]?.clientCount);
    data6.push(newarr);
  }
  const data7 = [["WorkflowName", "ClientCount"]];
  for (let i = 0; i < insuranceclientenquirycount?.length; i++) {
    let newarr = [];
    newarr[0] = insuranceclientenquirycount[i]?.workflowName;
    newarr[1] = Number(insuranceclientenquirycount[i]?.clientCount);
    data7.push(newarr);
  }
  const data8 = [["WorkflowName", "ClientCount"]];
  for (let i = 0; i < skillassessmentclientenquirycount?.length; i++) {
    let newarr = [];
    newarr[0] = skillassessmentclientenquirycount[i]?.workflowName;
    newarr[1] = Number(skillassessmentclientenquirycount[i]?.clientCount);
    data8.push(newarr);
  }

  // const data10 = [["Course", "EnquiryCount"]];
  // for (let i = 0; i < props.topcourse?.length; i++) {
  //   let newarr = [];
  //   newarr[0] = props.topcourse[i]?.courseName;
  //   newarr[1] = Number(props.topcourse[i]?.enquiryCount);
  //   data10.push(newarr);
  // }
  const data11 = [["User", "ClientCount"]];
  for (let i = 0; i < props.clientcountbyuser?.length; i++) {
    let newarr = [];
    newarr[0] = props.clientcountbyuser[i]?.consultantName;
    newarr[1] = Number(props.clientcountbyuser[i]?.["count(*)"]);
    data11.push(newarr);
  }

  const [data2, setdata2] = useState([["Country", "Client_Count"]]);
  const getclientoncountry = () => {
    axios
      .get(`${base_url.api1}/getAllClientCountBasedOnCountries`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = res.data.data[i].country;
          newarr[1] = Number(res.data.data[i].clientCount);
          setdata2((prev) => [...prev, newarr]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [data3, setdata3] = useState([["Office", "Client_Count"]]);
  const getclientoncbranch = () => {

    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      console.log(element);
      office_obj.push(element);
    }
    let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
    office_id = new_id;

    axios
      .get(`${base_url.api1}/getAllClientCountBasedOnOffice`, {
        headers: {
          Authorization: jwtToken,
          officeId: new_id,
        },
      })
      .then((res) => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = res.data.data[i].officeName;
          newarr[1] = Number(res.data.data[i].clientCount);
          setdata3((prev) => [...prev, newarr]);
          console.log("newarr------------>", newarr);
        }
        console.log("ClientobjbaseonOffice", res.data.data);
        let data12 = [];
        for (let i = 0; i < res.data.data?.length; i++) {
          let thirdnewarr = {
            officeName: res.data.data[i]?.officeName,
            clientCount: Number(res.data.data[i]?.clientCount),
          };
          data12.push(thirdnewarr);
          console.log(thirdnewarr);
          console.log("ClientobjbaseonOffice", data12);
          if (res.data.data.length - 1 == i) {
            setthirdRowData(data12);
            console.log("data12-------->", data12);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setFilterClientResult([]);
    if (props.officeid === "all") {
      axios
        .get(`${base_url.api1}/getClientCountBasedOnDate`, {
          headers: {
            Authorization: jwtToken,
            createdFromDate: picker.startDate.format("YYYY-MM-DD HH:MM:ss"),
            createdToDate: picker.endDate.format("YYYY-MM-DD HH:MM:ss"),
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].workflowType === props.workflowType) {
              setFilterClientResult((prev) => [...prev, res.data.data[i]]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${base_url.api1}/getClientCountByOfficeIdBasedOnDate`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(props.officeid),
            createdFromDate: picker.startDate.format("YYYY-MM-DD HH:MM:ss"),
            createdToDate: picker.endDate.format("YYYY-MM-DD HH:MM:ss"),
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].workflowType === props.workflowType) {
              setFilterClientResult((prev) => [...prev, res.data.data[i]]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getintakeresult = (value) => {
    setFilterClientResult([]);
    if (value) {
      if (value === "all") {
        axios
          .get(`${base_url.api1}/getEnquiryClientCountBasedOnIntake`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              // if (res.data.data[i].workflowType === props.workflowType) {
              if (res.data.data[i].workflowType === 'Admission') {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('coming 447');
        axios
          .get(
            `${base_url.api1}/getClientEnquiryCountByOfficeIdBasedOnIntake`,
            {
              headers: {
                Authorization: jwtToken,
                officeId: Number(props.officeid),
              },
            }
          )
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].workflowType === props.workflowType) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
            // setpiechartdata(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const handlefilter = (e) => {
    // let filter = e.target.value;
    // setFilterResult(e.target.value);
    let filter = e;
    setFilterResult(e);
    setFilterClientResult([]);
    if (props.officeid === "all") {
      // if (filter === "Intake") {
      //     axios
      //         .get(`${base_url.api1}/getEnquiryClientCountBasedOnIntake`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 if (res.data.data[i].workflowType === props.workflowType) {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // } else if (filter === "SuperAgent") {
      //     axios
      //         .get(`${base_url.api1}/getClientCountBasedOnSuperAgent`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 // if (res.data.data[i].workflowType === props.workflowType) {
      //                 if (res.data.data[i].workflowType === "Admission") {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // } else if (filter === "SubAgent") {
      //     axios
      //         .get(`${base_url.api1}/getClientCountBasedOnSubAgent`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 // if (res.data.data[i].workflowType === props.workflowType) {
      //                 if (res.data.data[i].workflowType === "Admission") {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // } else if (filter === "Provider") {
      //     axios
      //         .get(`${base_url.api1}/getEnquiryInstitutionCount`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 // if (res.data.data[i].workflowType === props.workflowType) {
      //                 if (res.data.data[i].workflowType === "Admission") {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // }
      if (filter == "ApplicationByWorkflow") {
        axios
          .get(`${base_url.api1}/getClientEnquiryCount`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "Admission") {
        axios
          .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowName: 'Admission',
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "Migration") {
        axios
          .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowName: 'Migration',
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "SkillAssessment") {
        axios
          .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowName: 'SkillAssessment',
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "Intake") {
        axios
          .get(`${base_url.api1}/getEnquiryClientCountBasedOnIntake`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].intake != undefined) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      // if (filter === "Intake") {
      //     axios
      //         .get(
      //             `${base_url.api1}/getClientEnquiryCountByOfficeIdBasedOnIntake`,
      //             {
      //                 headers: {
      //                     Authorization: jwtToken,
      //                     officeId: Number(props.officeid),
      //                 },
      //             }
      //         )
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 if (res.data.data[i].workflowType === props.workflowType) {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // } else if (filter === "SuperAgent") {
      //     axios
      //         .get(`${base_url.api1}/getClientCountBasedOnSuperAgentByOfficeId`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //                 officeId: Number(props.officeid),
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 if (res.data.data[i].workflowType === props.workflowType) {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // } else if (filter === "SubAgent") {
      //     axios
      //         .get(`${base_url.api1}/getClientCountBasedOnSubAgentByOfficeId`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //                 officeId: Number(props.officeid),
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 if (res.data.data[i].workflowType === props.workflowType) {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // } else if (filter === "Provider") {
      //     axios
      //         .get(`${base_url.api1}/getEnquiryInstitutionCountByOfficeId`, {
      //             headers: {
      //                 Authorization: jwtToken,
      //                 officeId: Number(props.officeid),
      //             },
      //         })
      //         .then((res) => {
      //             for (let i = 0; i < res.data.data.length; i++) {
      //                 if (res.data.data[i].workflowType === props.workflowType) {
      //                     setFilterClientResult((prev) => [...prev, res.data.data[i]]);
      //                 }
      //             }
      //             // setpiechartdata(data);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // }
      if (filter == "ApplicationByWorkflow") {
        axios
          .get(`${base_url.api1}/getClientEnquiryCountBasedOnOfficeId`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "Admission") {
        axios
          .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnOfficeIdAndWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowName: 'Admission',
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "Migration") {
        axios
          .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnOfficeIdAndWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowName: 'Migration',
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "SkillAssessment") {
        axios
          .get(`${base_url.api1}/getClientEnquiryDetailsBasedOnOfficeIdAndWorkflowType`, {
            headers: {
              Authorization: jwtToken,
              workflowName: 'SkillAssessment',
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].clientCount != 0) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (filter == "Intake") {
        axios
          .get(`${base_url.api1}/getClientEnquiryCountByOfficeIdBasedOnIntake`, {
            headers: {
              Authorization: jwtToken,
              officeId: Number(props.officeid),
            },
          })
          .then((res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].intake != undefined) {
                setFilterClientResult((prev) => [...prev, res.data.data[i]]);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const data15 = [["Name", "Count"]];
  if (filterresult === "Intake") {
    for (let i = 0; i < filterClientResult?.length; i++) {
      let newarr = [];
      newarr[0] = filterClientResult[i]?.intake;
      newarr[1] = Number(filterClientResult[i]?.enquiryClientCount);
      data.push(newarr);
    }
  } else if (filterresult === "SuperAgent") {
    for (let i = 0; i < filterClientResult?.length; i++) {
      let newarr = [];
      newarr[0] = filterClientResult[i]?.superAgent;
      newarr[1] = Number(filterClientResult[i]?.clientCount);
      data.push(newarr);
    }
  } else if (filterresult === "SubAgent") {
    for (let i = 0; i < filterClientResult?.length; i++) {
      let newarr = [];
      newarr[0] = filterClientResult[i]?.subAgent;
      newarr[1] = Number(filterClientResult[i]?.clientCount);
      data.push(newarr);
    }
  } else if (filterresult === "Provider") {
    for (let i = 0; i < filterClientResult?.length; i++) {
      let newarr = [];
      newarr[0] = filterClientResult[i]?.institutionName;
      newarr[1] = Number(filterClientResult[i]?.enquiryInstitutionCount);
      data.push(newarr);
    }
  } else if (filterresult === "DateFilter") {
    for (let i = 0; i < filterClientResult?.length; i++) {
      let newarr = [];
      newarr[0] = filterClientResult[i]?.workflowName;
      newarr[1] = Number(filterClientResult[i]?.clientCount);
      data.push(newarr);
    }
  }

  // var newgrid;
  // {
  //   props.topprovider.map((toppro, index) => {
  //     return newgrid = {
  //       country: toppro.institutionName,
  //       ProviderEnquiryCount: toppro.enquiryCount,
  //     };
  //   })
  // };
  // console.log(newgrid);

  // const reformattedArray = props.topprovider.map(({ toppro, index }) => ({
  //   country: toppro.institutionName,
  //   ProviderEnquiryCount: toppro.enquiryCount,
  // }));

  // console.log(reformattedArray);

  const gridRef = useRef();
  const secondgridRef = useRef();
  const thirdgridRef = useRef();


  const [rowData, setRowData] = useState();

  const [secondrowData, setsecondRowData] = useState();

  const [thirdrowData, setthirdRowData] = useState();

  const [columnDefs, setColumnDefs] = useState([
    { field: 'institutionName', width: 150 },
    { field: 'ProviderEnquiryCount' },
  ]);

  const [secondcolumnDefs, secondsetColumnDefs] = useState([
    { field: 'courseName', width: 150 },
    { field: 'CourseEnquiryCount' },
  ]);

  const [thirdcolumnDefs, thirdsetColumnDefs] = useState([
    { field: 'officeName', width: 150 },
    { field: 'clientCount' },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  const seconddefaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  const thirddefaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const secondpopupParent = useMemo(() => {
    return document.body;
  }, []);

  const thirdpopupParent = useMemo(() => {
    return document.body;
  }, []);

  const chartThemes = useMemo(() => {
    return ['ag-pastel', 'ag-material-dark', 'ag-vivid-dark', 'ag-solar'];
  }, []);

  const secondchartThemes = useMemo(() => {
    return ['ag-pastel', 'ag-material-dark', 'ag-vivid-dark', 'ag-solar'];
  }, []);

  const thirdchartThemes = useMemo(() => {
    return ['ag-pastel', 'ag-material-dark', 'ag-vivid-dark', 'ag-solar'];
  }, []);

  const chartThemeOverrides = useMemo(() => {
    return {
      cartesian: {
        axes: {
          category: {
            label: {
              rotation: 335,
            },
          },
        },
      },
    };
  }, []);

  const secondchartThemeOverrides = useMemo(() => {
    return {
      cartesian: {
        axes: {
          category: {
            label: {
              rotation: 335,
            },
          },
        },
      },
    };
  }, []);

  const thirdchartThemeOverrides = useMemo(() => {
    return {
      cartesian: {
        axes: {
          category: {
            label: {
              rotation: 335,
            },
          },
        },
      },
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    var createRangeChartParams = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 79,
        columns: ['institutionName', 'ProviderEnquiryCount'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#myChart'),
      aggFunc: 'sum',
    };
    gridRef.current.api.createRangeChart(createRangeChartParams);
  }, []);

  const onSecondDataRendered = useCallback((params) => {
    var createRangeChartParams = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 79,
        columns: ['courseName', 'CourseEnquiryCount'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#mysecondChart'),
      aggFunc: 'sum',

    };
    secondgridRef.current.api.createRangeChart(createRangeChartParams);
  }, []);

  const onThirdDataRendered = useCallback((params) => {
    var createRangeChartParams = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 79,
        columns: ['officeName', 'clientCount'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#mythirdChart'),
      aggFunc: 'sum',

    };
    thirdgridRef.current.api.createRangeChart(createRangeChartParams);
  }, []);


  const viewWorkflow = (pan, e) => {
    localStorage.setItem("selected_client_username", pan.ClientEmail);
    localStorage.setItem("selected_client_id", pan.ClientId);
    dispatch(getworkflowType(pan.workflowType));
    dispatch(gettabvalue(1));
    if (!pan.workflowId) {
      history.push(
        "/view-enquiry-workflowList/" + pan.ClientId + "/" + pan.ClientEmail
      );
    } else {
      history.push(
        "/view-enquiry-workflow/" +
        pan.ClientId +
        "/" +
        pan.enquiryId +
        "/" +
        pan.ClientEmail
      );
    }
  };

  return (
    <>
      <div class="page-card_container p-4">
        <div class="row">
          {check1pointpermission(
            2,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Client Count</h3>

                  {/* <div class="dropdown">
                    <button class="btn tab-limegreen dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      Office
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div> */}
                  {/* <Chart
                                    width={"370px"}
                                    height={"260px"}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={data2}
                                    options={{
                                        title: "Client Based on Country",
                                    }}
                                    rootProps={{ "data-testid": "1" }}
                                /> */}
                </div>
                <div class="card-body vertical">
                  <div class="img-container">
                    {/* <img src={graph1} alt="" /> */}
                    <div class="card-body-sangam vertical" style={{ maxHeight: "210px !important" }}>
                      <div style={{ display: "none" }} >
                        <AgGridReact
                          ref={thirdgridRef}
                          rowData={thirdrowData}
                          columnDefs={thirdcolumnDefs}
                          defaultColDef={thirddefaultColDef}
                          popupParent={thirdpopupParent}
                          enableRangeSelection={true}
                          enableCharts={true}
                          chartThemes={thirdchartThemes}
                          chartThemeOverrides={thirdchartThemeOverrides}
                          onFirstDataRendered={onThirdDataRendered}
                        ></AgGridReact>
                      </div>
                      <div id="mythirdChart" className="my-chart"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* <div class="col-xl-4 col-sm-6 col-12">
                        <div class="card p-3">
                            <div class="card-head d-flex justify-content-between align-items-center " style={{ flexDirection: "column" }}>
                                <h3 class="card-title">Client Count</h3>

                                <div class="dropdown1">
                                    <button class="btn tab-limegreen dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Office
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                                <Chart
                                    width={"370px"}
                                    height={"260px"}
                                    // chartType="PieChart"
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={data3}
                                    options={{
                                        title: "Client Based on Branch",
                                    }}
                                    rootProps={{ "data-testid": "1" }}
                                />
                            </div>
                             <div class="card-body vertical">
                                <div class="img-container">
                                    <img src={graph1} alt="" />
                                </div>
                        </div>
                        </div>
                    </div> */}
          {check1pointpermission(
            3,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">My Appointment</h3>
                  <button class="btn tab-yellow" type="button">
                    Office
                    <i class="icon">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25 10.5L8.75 7L5.25 3.5" stroke="#DDA730" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </i>
                  </button>
                </div>

                <div class="card-body vertical">
                  <ul class="list p-0 m-0">
                    {props.appointment?.map((p, index) => (
                      <li class="list-item">
                        <div class="item-head">
                          <span class="item-title">
                            {p.client_name}
                          </span>
                          <span class="item-date">
                            {moment(p.appointmentDate).format("lll")}
                          </span>
                        </div>
                        <div class="item-desc">
                          {p.notes}
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* <div className="bodymiddle"> */}
                  {/* <div>
                                    {props.appointment?.map((p, index) => (
                                        <div
                                            key={index + 1}
                                            style={{
                                                display: "flex",
                                                borderBottom: "1px solid black",
                                            }}
                                        >
                                            <div className="application2">
                                                <Typography color="primary">{p.clientName}</Typography>
                                                <div style={{ display: "flex" }}>
                                                    {/* <Typography color="secondary">End</Typography>
                                                    <BootstrapTooltip title={p.notes}>
                                                        <Typography
                                                            color="secondary"
                                                            className="applicationellipsis"
                                                        >
                                                            {p.notes}
                                                        </Typography>
                                                    </BootstrapTooltip>
                                                </div>
                                                <Typography variant="caption">
                                                    {moment(p.appointmentDate).format("lll")}
                                                </Typography>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>*/}
                </div>
              </div>
            </div>
          ) : null}

          {check1pointpermission(
            4,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">User Count</h3>


                  <div class="dropdown">
                    <a href="#" class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                      <i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect width="24" height="24" rx="6" fill="white" />
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </i>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">User Count</a></li>
                      <li><a class="dropdown-item" href="#">Application by Workflow</a></li>
                      <li><a class="dropdown-item" href="#">Admission Client Enquiry</a></li>
                      <li><a class="dropdown-item" href="#">Intake Client Enquiry</a></li>
                      <li><a class="dropdown-item" href="#">Migration Client Enquiry</a></li>
                      <li><a class="dropdown-item" href="#">Skill Assessment Client Enquiry</a></li>
                    </ul>
                  </div>
                </div>

                {/* <div class="card-body vertical">
                {data11?.length > 1 ? (
                  <Chart
                    width={"230px"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={data11}
                    options={{
                      pieHole: 0.4,
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                ) : (
                  <p className="h-100 text-danger row justify-content-center align-items-center">
                    No Data Available
                  </p>
                )}
                {user?.isw_user_role === 1 || user?.isw_user_role === 6 ? <>
                  <div className=" bodytop">
                    <div className="row">
                      <div className="col-md-6">
                        {/* <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">Top 5 Providers</Typography>
                                                    <div className="h-75">
                                                        {data9?.length > 1 ? (
                                                            <Chart
                                                                width={"230px"}
                                                                height={"200px"}
                                                                chartType="Bar"
                                                                loader={<div>Loading Chart</div>}
                                                                data={data9}
                                                                options={{
                                                                    title: "Top Provider",
                                                                    pieHole: 0.4,
                                                                }}
                                                                rootProps={{ "data-testid": "1" }}
                                                            />
                                                        ) : (
                                                            <p className="h-100 row justify-content-center align-items-center">
                                                                No Data
                                                            </p>
                                                        )}
                                                    </div>
                                                </div> *
                      </div>
                      <div className="col-md-6">
                        {/* <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">Top 5 Courses</Typography>
                                                    <div>
                                                        <Chart
                                                            width={"500px"}
                                                            height={"300px"}
                                                            chartType="Bar"
                                                            loader={<div>Loading Chart</div>}
                                                            data={data10}
                                                            options={{
                                                                title: "Top Course",
                                                                pieHole: 0.4,
                                                            }}
                                                            rootProps={{ "data-testid": "1" }}
                                                        />
                                                    </div>
                                                </div> *
                      </div>
                    </div>
                  </div>
                  {/* <div className="bodytop">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        ADMISSION APPLICATION BY WORKFLOW STAGES
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            // borderBottom: "1px solid black",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Step Name
                                                        </Typography>
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Count
                                                        </Typography>
                                                    </div>
                                                    {props.admissionenquiryworkflowsteps?.length > 0 ? (
                                                        <>
                                                            {props.admissionenquiryworkflowsteps?.map((p, index) => (
                                                                <div
                                                                    key={index + 1}
                                                                    style={{
                                                                        display: "flex",
                                                                            justifyContent: "space-between",
                                                                        borderBottom: "1px solid black",
                                                                        padding: "10px",
                                                                    }}
                                                                >
                                                                    <Typography variant="body1" color="primary">
                                                                        {p.currentStage}
                                                                    </Typography>
                                                                    <Typography variant="body1" color="primary">
                                                                        {p.enquiryCount}
                                                                    </Typography>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <p className="dashboardnodataptag">No Data</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        IN PROGRESS APPLICATION BY WORKFLOW
                                                    </Typography>
                                                    <div>
                                                        <Chart
                                                            width={"500px"}
                                                            height={"300px"}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={data}
                                                            options={{
                                                                title: "Admission Count",
                                                                pieHole: 0.4,
                                                            }}
                                                            chartEvents={[
                                                                {
                                                                    eventName: "select",
                                                                    callback: ({ chartWrapper }) => {
                                                                        const chart = chartWrapper.getChart();
                                                                        const selection = chart.getSelection();
                                                                        if (selection.length === 1) {
                                                                            const [selectedItem] = selection;
                                                                            const datatable = chartWrapper.getDataTable();
                                                                            const { row, column } = selectedItem;
                                                                            console.log(selection);
                                                                            console.log(datatable.getValue(row, 0));
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                            rootProps={{ "data-testid": "1" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bodytop">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">
                                                            ADMISSION CLIENT ENQUIRY{" "}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                // borderBottom: "1px solid black",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <Typography variant="h5" color="primary">
                                                                Workflow Name
                                                            </Typography>
                                                            <Typography variant="h5" color="primary">
                                                                Client Count
                                                            </Typography>
                                                        </div>
                                                        {admissionclientenquirycount?.length > 0 ? (
                                                            <>
                                                                {admissionclientenquirycount?.map((p, index) => (
                                                                    <div
                                                                        key={index + 1}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            borderBottom: "1px solid black",
                                                                            padding: "10px",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.workflowName}
                                                                        </Typography>
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.clientCount}
                                                                        </Typography>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <p className="dashboardnodataptag">No Data</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">Admission Client Enquiry</Typography>
                                                        <div>
                                                            <Chart
                                                                width={"500px"}
                                                                height={"300px"}
                                                                chartType="PieChart"
                                                                loader={<div>Loading Chart</div>}
                                                                data={data5}
                                                                options={{
                                                                    title: "Admission",
                                                                    pieHole: 0.4,
                                                                }}
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 1) {
                                                                                const [selectedItem] = selection;
                                                                                const datatable = chartWrapper.getDataTable();
                                                                                const { row, column } = selectedItem;
                                                                                console.log(chart);
                                                                                openModal(
                                                                                    chart.cfa[0].title,
                                                                                    datatable.getValue(row, 0)
                                                                                );
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                                rootProps={{ "data-testid": "1" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MainDashboardBodybottom
                                            workflowType="Admission"
                                            officeid={props.officeid}
                                        />
                                    </div>
                                    <div className="bodytop">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        MIGRATION APPLICATION BY WORKFLOW STAGES
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            // borderBottom: "1px solid black",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Step Name
                                                        </Typography>
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Count
                                                        </Typography>
                                                    </div>
                                                    {props.migrationenquiryworkflowsteps?.length > 0 ? (
                                                        <>
                                                            {props.migrationenquiryworkflowsteps?.map((p, index) => (
                                                                <div
                                                                    key={index + 1}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        borderBottom: "1px solid black",
                                                                        padding: "10px",
                                                                    }}
                                                                >
                                                                    <Typography variant="body1" color="primary">
                                                                        {p.currentStage}
                                                                    </Typography>
                                                                    <Typography variant="body1" color="primary">
                                                                        {p.enquiryCount}
                                                                    </Typography>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <p className="dashboardnodataptag">No Data</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        IN PROGRESS APPLICATION BY WORKFLOW
                                                    </Typography>
                                                    <div>
                                                        <Chart
                                                            width={"500px"}
                                                            height={"300px"}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={data2}
                                                            options={{
                                                                title: "Migration Count",
                                                                pieHole: 0.4,
                                                            }}
                                                            rootProps={{ "data-testid": "1" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bodytop">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">
                                                            MIGRATION CLIENT ENQUIRY{" "}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                // borderBottom: "1px solid black",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <Typography variant="h5" color="primary">
                                                                Workflow Name
                                                            </Typography>
                                                            <Typography variant="h5" color="primary">
                                                                Client Count
                                                            </Typography>
                                                        </div>
                                                        {migrationclientenquirycount?.length > 0 ? (
                                                            <>
                                                                {migrationclientenquirycount?.map((p, index) => (
                                                                    <div
                                                                        key={index + 1}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            borderBottom: "1px solid black",
                                                                            padding: "10px",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.workflowName}
                                                                        </Typography>
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.clientCount}
                                                                        </Typography>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <p className="dashboardnodataptag">No Data</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">MigrationClient Enquiry</Typography>
                                                        <div>
                                                            <Chart
                                                                width={"500px"}
                                                                height={"300px"}
                                                                chartType="PieChart"
                                                                loader={<div>Loading Chart</div>}
                                                                data={data6}
                                                                options={{
                                                                    title: "Migration",
                                                                    pieHole: 0.4,
                                                                }}
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 1) {
                                                                                const [selectedItem] = selection;
                                                                                const datatable = chartWrapper.getDataTable();
                                                                                const { row, column } = selectedItem;
                                                                                openModal(
                                                                                    chart.cfa[0].title,
                                                                                    datatable.getValue(row, 0)
                                                                                );
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                                rootProps={{ "data-testid": "1" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MainDashboardBodybottommigration
                                            workflowType="Migration"
                                            officeid={props.officeid}
                                        />
                                    </div>
                                    <div className="bodytop">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        INSURANCE APPLICATION BY WORKFLOW STAGES
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            // borderBottom: "1px solid black",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Step Name
                                                        </Typography>
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Count
                                                        </Typography>
                                                    </div>
                                                    {props.insuranceenquiryworkflowsteps?.length > 0 ? (
                                                        <>
                                                            {props.insuranceenquiryworkflowsteps?.map((p, index) => (
                                                                <div
                                                                    key={index + 1}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        borderBottom: "1px solid black",
                                                                        padding: "10px",
                                                                    }}
                                                                >
                                                                    <Typography variant="body1" color="primary">
                                                                        {p.currentStage}
                                                                    </Typography>
                                                                    <Typography variant="body1" color="primary">
                                                                        {p.enquiryCount}
                                                                    </Typography>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <p className="dashboardnodataptag">No Data</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        IN PROGRESS APPLICATION BY WORKFLOW
                                                    </Typography>
                                                    <div>
                                                        <Chart
                                                            width={"500px"}
                                                            height={"300px"}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={data3}
                                                            options={{
                                                                title: "Insurance Count",
                                                                pieHole: 0.4,
                                                            }}
                                                            rootProps={{ "data-testid": "1" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bodytop">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">
                                                            INSURANCE CLIENT ENQUIRY{" "}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                // borderBottom: "1px solid black",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <Typography variant="h5" color="primary">
                                                                Workflow Name
                                                            </Typography>
                                                            <Typography variant="h5" color="primary">
                                                                Client Count
                                                            </Typography>
                                                        </div>
                                                        {insuranceclientenquirycount?.length > 0 ? (
                                                            <>
                                                                {insuranceclientenquirycount?.map((p, index) => (
                                                                    <div
                                                                        key={index + 1}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            borderBottom: "1px solid black",
                                                                            padding: "10px",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.workflowName}
                                                                        </Typography>
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.clientCount}
                                                                        </Typography>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <p className="dashboardnodataptag">No Data</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">Insurance Client Enquiry</Typography>
                                                        <div>
                                                            <Chart
                                                                width={"500px"}
                                                                height={"300px"}
                                                                chartType="PieChart"
                                                                loader={<div>Loading Chart</div>}
                                                                data={data7}
                                                                options={{
                                                                    title: "Insurance",
                                                                    pieHole: 0.4,
                                                                }}
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 1) {
                                                                                const [selectedItem] = selection;
                                                                                const datatable = chartWrapper.getDataTable();
                                                                                const { row, column } = selectedItem;
                                                                                openModal(
                                                                                    chart.cfa[0].title,
                                                                                    datatable.getValue(row, 0)
                                                                                );
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                                rootProps={{ "data-testid": "1" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MainDashboardBodybottominsurance
                                            workflowType="Insurance"
                                            officeid={props.officeid}
                                        />
                                    </div>
                                    <div className="bodytop">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        SKILL ASSESSMENT APPLICATION BY WORKFLOW STAGES
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            // borderBottom: "1px solid black",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Step Name
                                                        </Typography>
                                                        <Typography variant="h5" color="primary">
                                                            Workflow Count
                                                        </Typography>
                                                    </div>
                                                    {props.skillassessmentenquiryworkflowsteps?.length > 0 ? (
                                                        <>
                                                            {props.skillassessmentenquiryworkflowsteps?.map(
                                                                (p, index) => (
                                                                    <div
                                                                        key={index + 1}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            borderBottom: "1px solid black",
                                                                            padding: "10px",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.currentStage}
                                                                        </Typography>
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.enquiryCount}
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <p className="dashboardnodataptag">No Data</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="dashboardmiddle_belowone">
                                                    <Typography variant="h6">
                                                        IN PROGRESS APPLICATION BY WORKFLOW
                                                    </Typography>
                                                    <div>
                                                        <Chart
                                                            width={"500px"}
                                                            height={"300px"}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={data4}
                                                            options={{
                                                                title: "SkillAssessment Count",
                                                                pieHole: 0.4,
                                                            }}
                                                            rootProps={{ "data-testid": "1" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bodytop">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">
                                                            SKILL ASSESSMENT CLIENT ENQUIRY{" "}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                // borderBottom: "1px solid black",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <Typography variant="h5" color="primary">
                                                                Workflow Name
                                                            </Typography>
                                                            <Typography variant="h5" color="primary">
                                                                Client Count
                                                            </Typography>
                                                        </div>
                                                        {skillassessmentclientenquirycount?.length > 0 ? (
                                                            <>
                                                                {skillassessmentclientenquirycount?.map((p, index) => (
                                                                    <div
                                                                        key={index + 1}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            borderBottom: "1px solid black",
                                                                            padding: "10px",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.workflowName}
                                                                        </Typography>
                                                                        <Typography variant="body1" color="primary">
                                                                            {p.clientCount}
                                                                        </Typography>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <p className="dashboardnodataptag">No Data</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="dashboardmiddle_belowone">
                                                        <Typography variant="h6">
                                                            Skill Assessment Client Enquiry
                                                        </Typography>
                                                        <div>
                                                            <Chart
                                                                width={"500px"}
                                                                height={"300px"}
                                                                chartType="PieChart"
                                                                loader={<div>Loading Chart</div>}
                                                                data={data8}
                                                                options={{
                                                                    title: "SkillAssessment",
                                                                    pieHole: 0.4,
                                                                }}
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 1) {
                                                                                const [selectedItem] = selection;
                                                                                const datatable = chartWrapper.getDataTable();
                                                                                const { row, column } = selectedItem;
                                                                                openModal(
                                                                                    chart.cfa[0].title,
                                                                                    datatable.getValue(row, 0)
                                                                                );
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                                rootProps={{ "data-testid": "1" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MainDashboardBodybottomskillassessment
                                            workflowType="SkillAssessment"
                                            officeid={props.officeid}
                                        />
                                    </div> *
                </> : null}
              </div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contenLabel="Example Label"
              >
                <div>
                  <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Client Details</h2>
                  <hr />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Username</TableCell>
                          <TableCell align="right">Phone Number</TableCell>
                          <TableCell align="right">Workflow Name</TableCell>
                          <TableCell align="right">Workflow Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clientdetails?.map((p, index) => (
                          <TableRow key={index + 1}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {p.clientName}
                            </TableCell>
                            <TableCell align="right">{p.clientUsername}</TableCell>
                            <TableCell align="right">{p.clientPhoneNumber}</TableCell>
                            <TableCell align="right">{p.workflowName}</TableCell>
                            <TableCell align="right">{p.workflowType}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Modal> */}
                <div class="card-body vertical">
                  <div class="img-container">
                    <img src={piegraph} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {check1pointpermission(
            5,
            permissionData,
            rolepermission
          ) ? (

            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Top 5 Providers</h3>


                  {/* <div class="dropdown">
                    <a href="#" class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                      <i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect width="24" height="24" rx="6" fill="white" />
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </i>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">User Count</a></li>
                      <li><a class="dropdown-item" href="#">Application by Workflow</a></li>
                      <li><a class="dropdown-item" href="#">Admission Client Enquiry</a></li>
                      <li><a class="dropdown-item" href="#">Intake Client Enquiry</a></li>
                      <li><a class="dropdown-item" href="#">Migration Client Enquiry</a></li>
                      <li><a class="dropdown-item" href="#">Skill Assessment Client Enquiry</a></li>
                    </ul>
                  </div> */}
                  {/* <div className="h-75">
                                    {data9?.length > 1 ? (
                                        <Chart
                                            width={"350px"}
                                            height={"300px"}
                                            chartType="Bar"
                                            loader={<div>Loading Chart</div>}
                                            data={data9}
                                            options={{
                                                title: "Top Provider",
                                                pieHole: 0.4,
                                            }}
                                            rootProps={{ "data-testid": "1" }}
                                        />
                                    ) : (
                                        <p className="h-100 row justify-content-center align-items-center">
                                            No Data
                                        </p>
                                    )}
                                </div> */}
                  {/* {data9?.length > 1 ? (
                                    <Chart
                                        width={"370px"}
                                        height={"260px"}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={data9}
                                        options={{
                                            title: "Top Provider",
                                            pieHole: 0.4,
                                        }}
                                        rootProps={{ "data-testid": "1" }}
                                    />
                                ) : (
                                    <p className="h-100 row justify-content-center align-items-center">
                                        No Data
                                    </p>
                                )} */}
                </div>

                {/* <div class="card-body vertical">
                  <div class="img-container">
                    <img src={bargraph1} alt="" />
                  </div>
                </div> */}
                <div class="card-body-sangam vertical" style={{ maxHeight: "210px !important" }}>
                  <div style={{ display: "none" }} >
                    <AgGridReact
                      ref={gridRef}
                      rowData={rowData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      popupParent={popupParent}
                      enableRangeSelection={true}
                      enableCharts={true}
                      chartThemes={chartThemes}
                      chartThemeOverrides={chartThemeOverrides}
                      onFirstDataRendered={onFirstDataRendered}
                    ></AgGridReact>
                  </div>
                  <div id="myChart" className="my-chart"></div>
                </div>
              </div>
            </div>
          ) : null}

          <div class="col-xl-4 col-sm-6 col-12">
            <div class="card p-3">
              <div class="card-head d-flex justify-content-between align-items-center ">
                <h3 class="card-title">Top 5 Courses</h3>
              </div>

              <div class="card-body-san vertical">
                <div style={{ display: "none" }} >
                  <AgGridReact
                    ref={secondgridRef}
                    rowData={secondrowData}
                    columnDefs={secondcolumnDefs}
                    defaultColDef={seconddefaultColDef}
                    popupParent={secondpopupParent}
                    enableRangeSelection={true}
                    enableCharts={true}
                    chartThemes={secondchartThemes}
                    chartThemeOverrides={secondchartThemeOverrides}
                    onFirstDataRendered={onSecondDataRendered}
                  ></AgGridReact>
                </div>
                <div id="mysecondChart" className="my-chart"></div>
              </div>
            </div>
          </div>

          {/* <div class="col-xl-4 col-sm-6 col-12">
                        <div class="card p-3">
                            <div class="card-head d-flex justify-content-between align-items-center " style={{ flexDirection: "column" }}>
                                <h3 class="card-title">Top 5 Courses</h3>

                                {/* <div class="dropdown">
                                    <a href="#" class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                                        <i class="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect width="24" height="24" rx="6" fill="white" />
                                                <path
                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">User Count</a></li>
                                        <li><a class="dropdown-item" href="#">Application by Workflow</a></li>
                                        <li><a class="dropdown-item" href="#">Admission Client Enquiry</a></li>
                                        <li><a class="dropdown-item" href="#">Intake Client Enquiry</a></li>
                                        <li><a class="dropdown-item" href="#">Migration Client Enquiry</a></li>
                                        <li><a class="dropdown-item" href="#">Skill Assessment Client Enquiry</a></li>
                                    </ul>
                                </div>
                                 <div className="h-75">
                                    {data9?.length > 1 ? (
                                        <Chart
                                            width={"350px"}
                                            height={"300px"}
                                            chartType="Bar"
                                            loader={<div>Loading Chart</div>}
                                            data={data9}
                                            options={{
                                                title: "Top Provider",
                                                pieHole: 0.4,
                                            }}
                                            rootProps={{ "data-testid": "1" }}
                                        />
                                    ) : (
                                        <p className="h-100 row justify-content-center align-items-center">
                                            No Data
                                        </p>
                                    )}
                                </div>
                                <Chart
                                    width={"370px"}
                                    height={"260px"}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={data10}
                                    options={{
                                        title: "Top Course",
                                        pieHole: 0.4,
                                    }}
                                    rootProps={{ "data-testid": "1" }}
                                />
                            </div>

                             <div class="card-body vertical">
                                <div class="img-container">
                                    <img src={bargraph1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div> */}

          {check1pointpermission(
            6,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Admission Application by Workflow Stages</h3>

                  {/* <div class="dropdown">
                                    <a href="#" class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                                        <i class="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect width="24" height="24" rx="6" fill="white" />
                                                <path
                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </i>
                                    </a>
                                     <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">User Count</a></li>
                                        <li><a class="dropdown-item" href="#">Application by Workflow</a></li>
                                        <li><a class="dropdown-item" href="#">Admission Client Enquiry</a></li>
                                        <li><a class="dropdown-item" href="#">Intake Client Enquiry</a></li>
                                        <li><a class="dropdown-item" href="#">Migration Client Enquiry</a></li>
                                        <li><a class="dropdown-item" href="#">Skill Assessment Client Enquiry</a></li>
                                    </ul>
                                </div> */}
                </div>

                <div class="card-body vertical">
                  <div class="table-container">
                    <table class="table card-table table-borderless static-table" striped>
                      <thead>
                        <th>Workflow Step Name </th>
                        <th>Workflow Count</th>
                      </thead>
                      {props.admissionenquiryworkflowsteps?.length > 0 ? (
                        <>
                          {props.admissionenquiryworkflowsteps?.map((p, index) => (
                            <tbody >
                              {index % 2 == 0 ? (
                                <tr style={{ background: "#eee" }}>
                                  <td>{p.currentStage}</td>
                                  <td>{p.enquiryCount}</td>
                                </tr>
                              ) : (
                                <tr style={{ background: "white" }}>
                                  <td>{p.currentStage}</td>
                                  <td>{p.enquiryCount}</td>
                                </tr>
                              )}
                            </tbody>
                          ))}
                        </>
                      ) : (
                        <p className="dashboardnodataptag">No Data</p>
                      )}
                    </table>
                  </div>

                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="maindashboard_select">
                                <FormControl
                                    className={classes.formControl}
                                    className="office_dropdown"
                                >
                                    <InputLabel htmlFor="age-native-simple">Filter</InputLabel>
                                    <Select
                                        native
                                        value={filterresult}
                                        id="demo-simple-select3"
                                        onChange={(e) => handlefilter(e)}
                                    >
                                        <option value="Intake">Intake</option>
                                        <option value="SuperAgent">Super Agent</option>
                                        <option value="SubAgent">Sub Agent</option>
                                        <option value="Provider">Provider</option>
                                        <option value="DateFilter">DateFilter</option>
                                    </Select>
                                </FormControl>
                                {filterresult === "DateFilter" ? (
                                    <>
                                        <br />
                                        <label>
                                            <strong>Date:</strong>
                                        </label>
                                        <DateRangePicker
                                            onEvent={handleEvent}
                                            onCallback={handleCallback}
                                            onApply={handleApply}
                                        >
                                            <p className="datepickerrangevaluetext">{datevalue}</p>
                                        </DateRangePicker>
                                    </>
                                ) : null}
                            </div> */}
          {check1pointpermission(
            7,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">ADMISSION {filterresult.toUpperCase()} ENQUIRY</h3>


                  {/* <div class="dropdown">
                                    <a
                                        class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                                        <i class="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect width="24" height="24" rx="6" fill="white" />
                                                <path
                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </i>
                                    </a>
                                    <ul class="dropdown-menu" value={filterresult}
                                        onChange={(e) => handlefilter(e)}>
                                        <li><a class="dropdown-item" value="Intake">Intake</a></li>
                                        <li><a class="dropdown-item" value="SuperAgent">Super Agent</a></li>
                                        <li><a class="dropdown-item" value="SubAgent">Sub Agent</a></li>
                                        <li><a class="dropdown-item" value="Provider">Provider</a></li>
                                        <li><a class="dropdown-item" value="DateFilter">DateFilter</a></li>
                                        <li><a class="dropdown-item" href="#">Skill Assessment Client Enquiry</a></li>
                                    </ul>
                                </div> */}
                  <div class="dropdown">
                    <a href="#" class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                      <i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect width="24" height="24" rx="6" fill="white" />
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                            stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </i>
                    </a>
                    <ul class="dropdown-menu">
                      {/* <li><a class="dropdown-item" onClick={() => handlefilter('Intake')} value="Intake">Intake</a></li>
                                        <li><a class="dropdown-item" onClick={() => handlefilter('SuperAgent')} value="SuperAgent">Super Agent</a></li>
                                        <li><a class="dropdown-item" onClick={() => handlefilter('SubAgent')} value="SubAgent">Sub Agent</a></li>
                                        <li><a class="dropdown-item" onClick={() => handlefilter('Provider')} value="Provider">Provider</a></li>
                                        <li><a class="dropdown-item" onClick={() => handlefilter('DateFilter')} value="DateFilter">DateFilter</a></li> */}
                      <li><a class="dropdown-item" onClick={() => handlefilter('UserCount')}>User Count</a></li>
                      <li><a class="dropdown-item" onClick={() => handlefilter('ApplicationByWorkflow')}>Application by Workflow</a></li>
                      <li><a class="dropdown-item" onClick={() => handlefilter('Admission')}>Admission Client Enquiry</a></li>
                      <li><a class="dropdown-item" onClick={() => handlefilter('Intake')}>Intake Client Enquiry</a></li>
                      <li><a class="dropdown-item" onClick={() => handlefilter('Migration')}>Migration Client Enquiry</a></li>
                      <li><a class="dropdown-item" onClick={() => handlefilter('SkillAssessment')}>Skill Assessment Client Enquiry</a></li>
                      {/* <li><a class="dropdown-item" onClick={()=>handlefilter('Intake')} href="#">Skill Assessment Client Enquiry</a></li> */}
                    </ul>
                  </div>
                </div>


                <div class="card-body vertical">
                  <div class="table-container">
                    <table class="table card-table table-borderless static-table">
                      <thead>
                        <th>Workflow Name</th>
                        <th>{filterresult} Count</th>
                      </thead>
                      {filterClientResult?.length > 0 ? (
                        <>
                          {filterClientResult?.map((p, index) => (
                            <tbody>

                              {/* {filterresult === "Intake" ? (
                                                            <><tr>
                                                                <td> {p.intake}</td>
                                                                <td> {p.enquiryClientCount}</td>
                                                            </tr></>
                                                        ) : (
                                                            <>
                                                                {filterresult === "SuperAgent" ? (
                                                                    <>
                                                                        <tr>
                                                                            <td> {p.superAgent}</td>
                                                                            <td> {p.clientCount}</td>
                                                                        </tr> </>
                                                                ) : (
                                                                    <>
                                                                        {filterresult === "SubAgent" ? (
                                                                            <>  <tr>
                                                                                <td> {p.subAgent}</td>
                                                                                <td> {p.clientCount}</td>
                                                                            </tr></>
                                                                        ) : (
                                                                            <>
                                                                                {filterresult === "Provider" ? (
                                                                                    <>   <tr>
                                                                                        <td>  {p.institutionName}</td>
                                                                                        <td>{p.enquiryInstitutionCount}</td>
                                                                                    </tr></>
                                                                                ) : (
                                                                                    <>
                                                                                        {filterresult === "DateFilter" ? (
                                                                                            <>   <tr>
                                                                                                <td> {p.workflowName}</td>
                                                                                                <td> {p.clientCount}</td>
                                                                                            </tr></>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )} */}
                              {filterresult === "UserCount" ? (
                                <>
                                  {index % 2 == 0 ? (
                                    <tr style={{ background: "#eee" }}>
                                      <td> {p.intake}</td>
                                      <td> {p.enquiryClientCount}</td>
                                    </tr>
                                  ) : (
                                    <tr style={{ background: "white" }}>
                                      <td> {p.intake}</td>
                                      <td> {p.enquiryClientCount}</td>
                                    </tr>
                                  )}
                                </>
                              ) : (
                                <>
                                  {filterresult === "ApplicationByWorkflow" ? (
                                    <>
                                      {index % 2 == 0 ? (
                                        <tr style={{ background: "#eee" }}>
                                          <td> {p.workflowName}</td>
                                          <td> {p.clientCount}</td>
                                        </tr>
                                      ) : (
                                        <tr style={{ background: "white" }}>
                                          <td> {p.workflowName}</td>
                                          <td> {p.clientCount}</td>
                                        </tr>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {filterresult === "Admission" ? (
                                        <>
                                          {index % 2 == 0 ? (
                                            <tr style={{ background: "#eee" }}>
                                              <td> {p.subAgent}</td>
                                              <td> {p.clientCount}</td>
                                            </tr>
                                          ) : (
                                            <tr style={{ background: "white" }}>
                                              <td> {p.subAgent}</td>
                                              <td> {p.clientCount}</td>
                                            </tr>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {filterresult === "Intake" ? (
                                            <>
                                              {index % 2 == 0 ? (
                                                <tr style={{ background: "#eee" }}>
                                                  <td>  {p.intake}</td>
                                                  <td>{p.enquiryClientCount}</td>
                                                </tr>
                                              ) : (
                                                <tr style={{ background: "white" }}>
                                                  <td>  {p.intake}</td>
                                                  <td>{p.enquiryClientCount}</td>
                                                </tr>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {filterresult === "Migration" ? (
                                                <>
                                                  {index % 2 == 0 ? (
                                                    <tr style={{ background: "#eee" }}>
                                                      <td> {p.workflowName}</td>
                                                      <td> {p.clientCount}</td>
                                                    </tr>
                                                  ) : (
                                                    <tr style={{ background: "white" }}>
                                                      <td> {p.workflowName}</td>
                                                      <td> {p.clientCount}</td>
                                                    </tr>
                                                  )}
                                                </>
                                              ) :
                                                <tr>
                                                  <td> {p.workflowName}</td>
                                                  <td> {p.clientCount}</td>
                                                </tr>}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                            </tbody>
                          ))}
                        </>
                      ) : (
                        <p className="dashboardnodataptag">No Data</p>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div >
          ) : null}

          {check1pointpermission(
            8,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Follow up</h3>
                  <div class="dropdown1">
                    {/* <button class="btn tab-yellow" type="button">
                                        See All
                                        <i class="icon">
                                            <img src={chevron} alt="" />
                                        </i>
                                    </button> */}
                  </div>
                </div>

                <div class="card-body vertical">
                  <ul class="list p-0 m-0">
                    {props.enquirybyfollowdate?.map((p, index) => (
                      <li class="list-item">
                        <div class="item-head">

                          <span class="item-title">
                            {p.workflowName}
                          </span>
                          {/* <span class="item-date">
                                                Aug 18, 2021 5:45PM
                                            </span> */}
                        </div>
                        <div class="item-desc">
                          {p.workflowType}
                        </div>

                      </li>
                    ))}

                    {/* <li class="list-item">
                                        <div class="item-head">
                                            <span class="item-title">
                                                prajwal@nepcoms.io
                                            </span>
                                            <span class="item-date">
                                                Aug 18, 2021 5:45PM
                                            </span>
                                        </div>
                                        <div class="item-desc">
                                            Lorem ipsum dolor sit amet.
                                        </div>
                                    </li>

                                    <li class="list-item">
                                        <div class="item-head">
                                            <span class="item-title">
                                                prajwal@nepcoms.io
                                            </span>
                                            <span class="item-date">
                                                Aug 18, 2021 5:45PM
                                            </span>
                                        </div>
                                        <div class="item-desc">
                                            Lorem ipsum dolor sit amet.
                                        </div>
                                    </li>

                                    <li class="list-item">
                                        <div class="item-head">
                                            <span class="item-title">
                                                prajwal@nepcoms.io
                                            </span>
                                            <span class="item-date">
                                                Aug 18, 2021 5:45PM
                                            </span>
                                        </div>
                                        <div class="item-desc">
                                            Lorem ipsum dolor sit amet.
                                        </div>
                                    </li>

                                    <li class="list-item">
                                        <div class="item-head">
                                            <span class="item-title">
                                                prajwal@nepcoms.io
                                            </span>
                                            <span class="item-date">
                                                Aug 18, 2021 5:45PM
                                            </span>
                                        </div>
                                        <div class="item-desc">
                                            Lorem ipsum dolor sit amet.
                                        </div>
                                    </li>

                                    <li class="list-item">
                                        <div class="item-head">
                                            <span class="item-title">
                                                prajwal@nepcoms.io
                                            </span>
                                            <span class="item-date">
                                                Aug 18, 2021 5:45PM
                                            </span>
                                        </div>
                                        <div class="item-desc">
                                            Lorem ipsum dolor sit amet.
                                        </div>
                                    </li> */}

                  </ul>
                </div>

              </div>
            </div>
          ) : null}

          {check1pointpermission(
            9,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Application Reminders</h3>
                </div>

                <div class="card-body vertical">
                  <ul class="list p-0 m-0">
                    {props.applicationreminder?.map((p, index) => (
                      <li>
                        {/* <a href="#" class="list-item d-flex reminder-item"> */}
                        <a class="list-item d-flex reminder-item">
                          <div class="item-day">
                            <p class="date">{moment(p.CourseStartDate).format("DD")}</p>
                            <p class="day">{moment(p.CourseStartDate).format("ddd")}</p>
                          </div>

                          <div class="reminder">
                            <div class="rem-item">
                              <span class="rem-name gree">
                                <a
                                  class="rem-name gree"
                                  href={
                                    `#/view-enquiry-workflow/` +
                                    p.ClientId +
                                    `/` +
                                    p.enquiryId +
                                    `/` +
                                    p.ClientEmail
                                  }
                                >
                                  {p.ClientName}
                                </a>
                              </span>
                              <p class="mb-1">
                                <span class="text-danger">End</span> -{p.CourseName}
                              </p>
                            </div>
                            <p class="item-date">{moment(p.CourseEndDate).format("MMM-YYYY")}</p>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}

          {check1pointpermission(
            10,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">{filterresult} Client Enquiry</h3>

                  {/* <div class="dropdown">
                                    <a href="#" class="dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                                        <i class="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect width="24" height="24" rx="6" fill="white" />
                                                <path
                                                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                                                    stroke="#B1B1CA" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </i>
                                    </a>
                                    <ul class="dropdown-menu" value={filterresult} onChange={(e) => handlefilter(e)}>
                                        <li><a class="dropdown-item" value="Intake">Intake Client Enquiry</a></li>
                                        <li><a class="dropdown-item" value="SuperAgent">Super Agent</a></li>
                                        <li><a class="dropdown-item" value="SubAgent">Sub Agent</a></li>
                                        <li><a class="dropdown-item" value="Proiver">Provider</a></li>
                                        <li><a class="dropdown-item" value="DateFilter">DateFilter</a></li>
                                        <li><a class="dropdown-item" href="#">Skill Assessment Client Enquiry</a></li>
                                    </ul>
                                </div> */}
                </div>

                {/* <div class="card-body vertical">
                                <div class="img-container">
                                    <img src={bargraph1} alt="" />
                                </div>
                            </div> */}
                <Chart
                  width={"300px"}
                  // height={"300px"}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={data}

                  options={{
                    title: "",
                    pieHole: 0.4,
                  }}
                  // chartEvents={[
                  //     {
                  //         eventName: "select",
                  //         callback: ({ chartWrapper }) => {
                  //             const chart = chartWrapper.getChart();
                  //             const selection = chart.getSelection();
                  //             if (selection.length === 1) {
                  //                 const [selectedItem] = selection;
                  //                 const datatable = chartWrapper.getDataTable();
                  //                 const { row, column } = selectedItem;
                  //                 console.log(chart);
                  //                 openModal(
                  //                     chart.cfa[0].title,
                  //                     datatable.getValue(row, 0)
                  //                 );
                  //             }
                  //         },
                  //     },
                  // ]}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </div>
          ) : null}

          {check1pointpermission(
            8,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Deadline</h3>
                  <div class="dropdown1">
                  </div>
                </div>

                <div class="card-body vertical">
                  <ul class="list p-0 m-0">
                    {props.deadlinereminder?.map((pan, index) => (
                      <li>
                        {/* <a href="#" class="list-item d-flex reminder-item"> */}
                        <a class="list-item d-flex reminder-item">
                          <div class="item-day">
                            <p class="date">{moment(pan.deadLineDate).format("DD")}</p>
                            <p class="day">{moment(pan.deadLineDate).format("ddd")}</p>
                          </div>
                          {/* 
                          <div class="reminder">
                            <div class="item-head">
                              <span class="item-date">
                                <a
                                  class="rem-name gree"
                                  href={
                                    `#/view-enquiry-workflow/` +
                                    p.ClientId +
                                    `/` +
                                    p.enquiryId +
                                    `/` +
                                    p.ClientEmail
                                  }
                                >
                                  {p.ClientName}
                                </a>
                              </span>
                              <span class="item-date" >{p.workflowType}</span>
                            </div>
                            <p class="item-date">{moment(p.deadLineDate).format("MMM-YYYY")}</p>
                          </div> */}
                          <div class="card-body vertical">

                            <div class="item-head">
                              <span class="item-title">
                                <a
                                  class="rem-name gree"
                                  onClick={(e) => viewWorkflow(pan, e)}
                                  href={
                                    `#/view-enquiry-workflow/` +
                                    pan.ClientId +
                                    `/` +
                                    pan.enquiryId +
                                    `/` +
                                    pan.ClientEmail
                                  }
                                >
                                  {pan.ClientName}
                                </a>
                              </span>
                              <span class="item-date" style={{ color: "red", fontWeight: "bold" }} >
                                {pan.workflowType}
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}

          {check1pointpermission(
            11,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Overdue Invoice</h3>
                </div>

                <div class="card-body vertical">
                  <ul class="list p-0 m-0">
                    {props.overdueInvoice?.map((p, index) => (
                      <li>
                        <a class="list-item d-flex reminder-item">
                          {/* <a href="#" class="list-item d-flex reminder-item"> */}
                          <div class="item-day">
                            <p class="date" >
                              <a
                                id={p}
                                style={{ textDecorationColor: "black !important" }}
                                //onClick={(e) => openInvoice(p, e)}
                                href={
                                  `#/view-invoice-details/` +
                                  p.clientId +
                                  `/` +
                                  p.Id
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >

                                {p.Id}
                              </a>
                            </p>
                          </div>
                          <div class="reminder">
                            <div class="rem-item">
                              <span class="rem-name gree">
                                <a
                                  class="rem-name gree"
                                  href={
                                    `#/view-invoice-details/` +
                                    p.clientId +
                                    `/` +
                                    p.Id
                                  }
                                >
                                  {p.clientUsername}
                                </a>
                              </span>
                              <p class="mb-1">
                                <span class="text-warning">Date:</span>  {moment(p.invoiceDueDate).format("DD-MMM-YY")}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}

          {check1pointpermission(
            9,
            permissionData,
            rolepermission
          ) ? (
            <div class="col-xl-4 col-sm-6 col-12">
              <div class="card p-3">
                <div class="card-head d-flex justify-content-between align-items-center ">
                  <h3 class="card-title">Visa Reminders</h3>
                </div>

                <div class="card-body vertical">
                  <ul class="list p-0 m-0">
                    {props.visreminder?.map((p, index) => (
                      <li>
                        {/* <a href="#" class="list-item d-flex reminder-item"> */}
                        <a class="list-item d-flex reminder-item">
                          <div class="item-day">
                            <p class="date">{moment(p.visaExpiry).format("DD")}</p>
                            <p class="day">{moment(p.visaExpiry).format("ddd")}</p>
                          </div>

                          <div class="reminder">
                            <div class="rem-item">
                              <span class="rem-name gree">
                                <a
                                  class="rem-name gree"
                                  href={
                                    `#/view-enquiry-workflow/` +
                                    p.ClientId +
                                    `/` +
                                    p.enquiryId +
                                    `/` +
                                    p.ClientEmail
                                  }
                                >
                                  {p.ClientName}
                                </a>
                              </span>
                              <p class="mb-1">
                                <span class="text-danger">End</span> -{p.CourseName}
                              </p>
                            </div>
                            <p class="item-date">{moment(p.visaExpiry).format("MMM-YYYY")}</p>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </div >


      </div >

    </>
  );
};

export default MainDashboardBodymiddle;