import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import "../css/ViewEnquiryWorkFlow.css";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import BranchesDetail from "./BranchesDetail";
import ApplicationDetail from "./ApplicationDetail";
import HeadInstitutionCourse from "./HeadInstitutionCourse";
import AgreementDetail from "./AgreementDetail";
import ProviderReport from "./ProviderReport";
import { useStyles } from "../../Utilities/CSSUtilities";
import { logout, selectUser, setdashboardcheck } from "../features/userSlice";
import EditIcon from "@material-ui/icons/Edit";
import { getproviderpermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewCollege = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [applicationformlist, setapplicationformlist] = useState();
  const [headInstitutionCourses, setheadInstitutionCourses] = useState();
  const [stateCust, setstateCust] = useState({});
  const [institutionbranches, setinstitutionBranches] = useState();
  const [backdrop, setbackdrop] = useState(false);
  const [transactionalarrangementlist, settransactionalarrangementlist] =
    useState();
  const user = useSelector(selectUser);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(706, permission_obj, resource_obj)) {
      //if (available_permission){
      dispatch(setdashboardcheck(22));
      setbackdrop(true);
      getinstitutionbranches();
      getCollegeById();
      getCourse();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const getinstitutionbranches = () => {
    axios
      .get(`${base_url.api2}/getBranchesByInstitutionId`, {
        headers: {
          Authorization: jwtToken,
          institutionId: params.provider_id,
        },
      })
      .then((res) => {
        console.log("Branches", res);
        setbackdrop(false);
        setinstitutionBranches(res.data.data);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };

  const getCourse = () => {
    axios
      .get(`${base_url.api2}/getAllCourseByInstitution`, {
        headers: {
          Authorization: jwtToken,
          institutionId: params.provider_id,
          columnName: "name",
          direction: "ASC",
          page: 0,
          size: 3,
        },
      })
      .then((res) => {
        console.log("Branche Courses", res.data.data.content);
        setbackdrop(false);
        setheadInstitutionCourses(res.data.data.content);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };


  const getCollegeById = () => {
    setbackdrop(true);
    axios
      .get(`${base_url.api2}/getInstitutionById`, {
        headers: {
          Authorization: jwtToken,
          institutionId: params.provider_id,
        },
      })
      .then((res) => {
        let college = res.data.data;
        setapplicationformlist(college.applicationForms);
        settransactionalarrangementlist(college.transactionalArrangements);
        setstateCust({
          abn: college.abn,
          acn: college.acn,
          addedOn: college.addedOn,
          address: college.address,
          applicationFormLink: college.applicationFormLink,
          contactPerson: college.contactPerson,
          can: college.can,
          city: college.city,
          country: college.country,
          cricosProviderCode: college.cricosProviderCode,
          currency: college.currency,
          designation: college.designation,
          email: college.email,
          fax: college.fax,
          institutionCode: college.institutionCode,
          institutionId: college.institutionId,
          institutionType: college.institutionType,
          latitude: college.latitude,
          logo: college.logo,
          longitude: college.longitude,
          mobile: college.mobile,
          name: college.name,
          overview: college.overview,
          pan: college.pan,
          phoneNumber: college.phoneNumber,
          rtoNumber: college.rtoNumber,
          remarks: college.remarks,
          state: college.state,
          status: college.status,
          street: college.street,
          transactionalArrangementName: college.transactionalArrangementName,
          university: college.university,
          updatedOn: college.updatedOn,
          website: college.website,
          zipCode: college.zipCode,
          providerCreatedBy: college.providerCreatedBy,
          providerCreatedDate: college.providerCreatedDate,
        });
        setbackdrop(false);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  const editCollege = (elem) => {
    var provider_id = elem;
    history.push("/edit-provider/" + provider_id);
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <>
      <div class="sp lead-page" style={{ marginTop: "50px" }}>
        <div class="page-head d-flex justify-content-between p-4" style={{ marginTop: "5rem", marginBottom: "10px" }}>
          <ul class="breadcrums">
            <h3 class="page-title bold">Partner Provider</h3>
            <a href="#/" class="b-link">Home</a>
            <span className="s_span ">-</span>
            <a class="b-link">Partner</a>
            <span className="s_span ">-</span>
            <a class="b-link active">Provider Dashboard</a>
          </ul>
          {/* <a
            className="sent-button"
            onClick={goback}
            style={{ float: "right" }}
          >
            List
          </a> */}
        </div>
        <div class="page-card_container p-4">
          <div class="content-wrapper">
            <div class="card client-profile" id="cardClient">
              <div class="minimize-profile">
                <span
                  onClick={() => {
                    let card = document.getElementById("cardClient");
                    card.style.display = "none";
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "0px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "visible";
                  }}
                >
                  {" "}
                  &#171;{" "}
                </span>
              </div>
              <div class="options">
                <input type="checkbox" id="option-checkbox" class="checkbox" />
                <label class="icon" for="option-checkbox">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="6" fill="white" />
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <div class="options-content">
                  <ul>
                    {/* {client_users != null ? (
                      client_users &&
                      client_users?.map((p, index) => ( */}
                    <>
                      {check1pointpermission(
                        704,
                        permissionData,
                        rolepermission
                      ) ? (
                        <li>
                          <a
                            onClick={() => editCollege(stateCust.institutionId)}
                          >
                            Edit
                          </a>
                        </li>
                      ) : null}
                    </>
                    {/* )))} */}
                    <li>
                      <a href="#">Option 2</a>
                    </li>
                    <li>
                      <a href="#">Option 3</a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />

              {stateCust.logo ? (
                <div class="provider-img">
                  <img src={stateCust.logo} alt="providerlist_logo"
                    style={{ width: "130px", height: "130px" }} />
                  <h5 style={{ width: "240px", wordWrap: "break-word", display: "inline-block" }} class="mt-2">  {stateCust.name}</h5>
                  <hr />

                </div>
              ) : (
                <div class="client-img">
                  <Avatar className="no_logo" />
                  <h5 style={{ width: "240px", wordWrap: "break-word", display: "inline-block" }} class="mt-2">  {stateCust.name}</h5>
                  <hr />
                </div>
              )}

              {/* {permissionData?.includes(168) ? (
                  <Link onClick={() => editCollege(stateCust.institutionId)}>
                    {/* <EditIcon
                      className="icon-margin dummy_contentlefticon"
                      fontSize="small"
                      color="primary"
                    /> *Edit
                  </Link>
                ) : null} */}

              <div class="personal-details">
                {/* <h3>Personal Details</h3> */}
                <div class="email">
                  <p> Cricos</p>
                  <p><strong> {stateCust?.cricosProviderCode
                    ? stateCust?.cricosProviderCode
                    : "-"}</strong></p>
                </div>
                <div class="dob">
                  <p>  Provider Code</p>
                  <p><strong> {stateCust?.institutionCode
                    ? stateCust?.institutionCode
                    : "-"}</strong></p>
                </div>
                <div class="contact">
                  <p>  Phone</p>
                  <p><strong>{stateCust?.phoneNumber ? stateCust?.phoneNumber : "-"}</strong></p>
                </div>
                <div class="address">
                  <p> Email</p>
                  <p>
                    <strong>{stateCust?.email ? stateCust?.email : "-"}</strong>
                  </p>
                </div>
                <div class="postal">
                  <p> Website</p>
                  <p><strong>{stateCust?.website ? stateCust?.website : "-"}</strong></p>
                </div>
                <div class="visa-expiry">
                  <p>  Provider Type</p>
                  {stateCust?.institutionType?.split(",").map((item, index) => (
                    <p className="viewproviderptag" key={index + 1}>
                      {item}
                    </p>
                  ))}
                </div>
                <div class="visa-type">
                  <p>  Address</p>
                  <p>
                    {stateCust?.city},
                    <br />
                    {stateCust?.state}
                    <br />
                    {stateCust?.postCode}
                    <br />
                    {stateCust?.country}
                  </p>
                </div>
                <p style={{ color: "#071A52", fontSize: "15px" }}>
                  <b>Contact Detail</b>
                </p>
                <div class="postal">
                  <p> Contact Person</p>
                  <p><strong> {stateCust?.contactPerson ? stateCust?.contactPerson : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p>  Designation</p>
                  <p><strong>{stateCust?.designation ? stateCust?.designation : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p>  Mobile</p>
                  <p><strong>{stateCust?.mobile ? stateCust?.mobile : "-"}</strong></p>
                </div>
                <p style={{ color: "#071A52", fontSize: "15px" }}>
                  <b>Provider Details</b>
                </p>
                <div class="postal">
                  <p>   ABN</p>
                  <p><strong>{stateCust?.abn ? stateCust?.abn : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p>  ACN</p>
                  <p><strong>{stateCust?.acn ? stateCust?.acn : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p> PAN</p>
                  <p><strong>{stateCust?.pan ? stateCust?.pan : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p> CAN</p>
                  <p><strong>{stateCust?.can ? stateCust?.can : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p> Currency</p>
                  <p><strong>{stateCust?.currency ? stateCust?.currency : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p>providerCreatedBy</p>
                  <p><strong>{stateCust?.providerCreatedBy ? stateCust?.providerCreatedBy : "-"}</strong></p>
                </div>
                <div class="postal">
                  <p>providerCreatedDate</p>
                  <p><strong>{stateCust?.providerCreatedDate ? moment(stateCust?.providerCreatedDate).format("DD-MMM-YY") : "-"}</strong></p>
                </div>
              </div>
            </div>
            <div class="card client-details" id="cardNumber" style={{ overflow: "hidden" }}>
              <div class="maximize-profile">
                <span
                  onClick={() => {
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "20px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "hidden";
                    let card = document.getElementById("cardClient");
                    card.style.display = "block";
                  }}
                  id="right_span"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  &#187;{" "}
                </span>
              </div>
              {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab className="tab-bold" label="Dashboard" {...a11yProps(0)} />

                <Tab className="tab-bold" label="Branches" {...a11yProps(1)} />
                <Tab className="tab-bold" label="Application" {...a11yProps(2)} />
                <Tab className="tab-bold" label="Agreement" {...a11yProps(3)} />
                <Tab className="tab-bold" label="Course" {...a11yProps(4)} />
              </Tabs>
              {/* </AppBar> */}
              <br />
              <TabPanel
                value={value}
                index={0}
                component={<ProviderReport value={value} handleChange={handleChange} />}
              />
              <TabPanel
                className="tabpanel"
                value={value}
                index={1}
                component={
                  <BranchesDetail
                    institutionBranches={institutionbranches}
                    institutionId={stateCust.institutionId}
                  />
                }
              />

              <TabPanel
                value={value}
                index={2}
                component={
                  <ApplicationDetail
                    applicationformlist={applicationformlist}
                    institutionId={stateCust.institutionId}
                    getCollegeById={getCollegeById}
                  />
                }
              />

              <TabPanel
                value={value}
                index={3}
                component={
                  <AgreementDetail
                    transactionalarrangementlist={transactionalarrangementlist}
                    institutionId={stateCust.institutionId}
                    getCollegeById={getCollegeById}
                  />
                }
              />

              <TabPanel
                value={value}
                index={4}
                component={
                  <HeadInstitutionCourse
                    headInstitutionCourses={headInstitutionCourses}
                    institutionId={stateCust.institutionId}
                  />
                }
              />

              {/* <TabPanel
              value={value}
              index={5}
              component={
                <AgreementDetail
                  transactionalarrangementlist={transactionalarrangementlist}
                />
              }
            /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCollege;
