import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	Col,
	Row,
	Button,
	Table,
} from 'reactstrap';
import './../css/Responsive.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import base_url from '../../api/bootapi';
import attachmenturl from '../../api/attachmenturl';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setdashboardcheck, logout } from '../features/userSlice';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useForm } from 'react-hook-form';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { selectdashboard } from '../features/dashboardSlice';
import { customStyles, useStyles } from '../../Utilities/CSSUtilities';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import callsaveauditapi from '../../services/auditservice';
import sort from './../images/sort.svg';
import {
	check4pointpermission,
	check1pointpermission,
} from './../Permission/permission';
import ReceivedReplyModal from './../Modal/Masters/ReceivedReplyModal';
import ReceivedViewModal from './../Modal/Masters/ReceivedViewModal';

const ReceivedInbox = () => {
	const classes = useStyles();
	const user = useSelector(selectUser);
	const history = useHistory();
	const jwtToken = 'Bearer ' + localStorage.getItem('isw_user_token');
	const isw_user_role = localStorage.getItem('isw_user_role');
	const isw_user_email = localStorage.getItem('isw_user_email');
	const [backdropopen, setbackdropopen] = useState(false);

	const isw_user_name = localStorage.getItem('isw_user_name');
	const isw_adminId = localStorage.getItem('isw_adminId');

	const [pageno, setpageno] = useState(0);
	const [getpageno, setgetpageno] = useState();
	const [rows, setrows] = React.useState(50);
	const [inbox, setInbox] = useState();
	const dispatch = useDispatch();
	const [searchinput, setsearchinput] = useState();
	const [sortState, setsortState] = useState('date');
	const [direction, setdirection] = useState('DESC');
	const [display1, setdisplay] = useState('inline');
	const [setComment, setsetComment] = useState();
	const [setSubject, setsetSubject] = useState();
	const ipaddress = localStorage.getItem('AdminIpAddress');
	const { register, handleSubmit } = useForm();
	const [attachmentState, setattachmentState] = useState();
	const [totalUser, settotalUser] = useState();
	const [countnumber, setcountnumber] = useState();
	const [numberofElements, setnumberofElements] = useState();
	const [filesizemore, setfilesizemore] = useState(false);
	const [permissionData, setPermissionData] = useState([]);
	const [rolepermission, setrolepermission] = useState([]);

	const handlepage = (event, value) => {
		setpageno(value - 1);
		getInbox(value - 1);
	};

	useEffect(() => {
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				history.push('/');
			}
		});
		getPermissions();
	}, [rows]);

	useEffect(() => {
		let width = document.querySelector('table')?.offsetWidth;
		if (document.querySelector('.scroller')) {
			document.querySelector('.scroller').style.width = `${width}px`;
		}
	}, [inbox]);

	let scrolling = false;
	const scrolldiv = () => {
		if (scrolling) {
			scrolling = false;
			return true;
		}
		scrolling = true;
		let scroller = document.querySelector('.above-scroller');
		let table = document.querySelector('.displaytablerow .table-responsive');
		table.scrollTo(scroller.scrollLeft, 0);
	};

	const scrolltable = () => {
		if (scrolling) {
			scrolling = false;
			return true;
		}
		scrolling = true;
		let scroller = document.querySelector('.above-scroller');
		let table = document.querySelector('.displaytablerow  .table-responsive');
		scroller.scrollTo(table.scrollLeft, 0);
	};

	const getPermissions = () => {
		// console.log(user.isw_role_permissions);
		const resource_obj = [];
		const permission_obj = [];
		for (let i = 0; i < user.isw_role_permissions.length; i++) {
			resource_obj.push(user.isw_role_permissions[i].resourceId);
		}
		for (let i = 0; i < user.isw_user_permissions.length; i++) {
			permission_obj.push(user.isw_user_permissions[i].permissionId);
		}
		setPermissionData(permission_obj);
		setrolepermission(resource_obj);

		if (check1pointpermission(101, permission_obj, resource_obj)) {
			getInbox(pageno);
			dispatch(setdashboardcheck(2));
		} else {
			history.push('/errorPage');
		}
	};

	const getInbox = (value1) => {
		setbackdropopen(true);
		if (isw_user_role === '1' || isw_user_role === '6') {
			axios
				.get(`${base_url.api4}/getAllByMailType`, {
					headers: {
						Authorization: jwtToken,
						columnName: sortState,
						mailType: 'received',
						page: Number(value1),
						size: Number(rows),
						direction: direction,
						fromEmailId: isw_user_email,
					},
				})
				.then(
					(res) => {
						console.log(res);
						setgetpageno(res.data.data.totalPages);
						settotalUser(res.data.data.totalElements);
						setcountnumber(res.data.data.number);
						setnumberofElements(res.data.data.numberOfElements);
						setInbox(res.data.data.content);
						setsearch(false);
						document.getElementById('myForm').reset();
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						toast.error(JSON.stringify(error.response.data.message));
						handle403error(error);
					}
				);
		} else {
			axios
				.get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
					headers: {
						Authorization: jwtToken,
						fromEmailId: isw_user_email,
						columnName: sortState,
						mailType: 'received',
						direction: direction,
						page: Number(value1),
						size: Number(rows),
					},
				})
				.then(
					(res) => {
						console.log(res);
						setgetpageno(res.data.data.totalPages);
						settotalUser(res.data.data.totalElements);
						setInbox(res.data.data.content);
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						toast.error(JSON.stringify(error.response.data.message));
						handle403error(error);
					}
				);
		}
	};
	const handleRows = (event) => {
		setrows(event.target.value);
	};
	const submitrows = (e) => {
		setbackdropopen(true);
		if (searchinput != null) {
			axios
				.get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
					headers: {
						Authorization: jwtToken,
						fromEmailId: isw_user_email,
						columnName: sortState,
						mailType: 'received',
						direction: direction,
						page: Number(pageno),
						size: Number(rows),
					},
				})
				.then(
					(response) => {
						console.log(response.data.data);
						setgetpageno(response.data.data.totalPages);
						setInbox(response.data.data.content);
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						toast.error(JSON.stringify(error.response.data.message));
						handle403error(error);
					}
				);
		} else {
			getInbox(0);
		}
	};

	const [searchdata, setsearch] = useState();
	const handlesearch = (e, value) => {
		setbackdropopen(true);
		// setdisplay("display");
		setpageno(value - 1);
		console.log(value);
		if (isw_user_role === '1' || isw_user_role === '6') {
			axios
				.get(`${base_url.api4}/searchEmail`, {
					headers: {
						Authorization: jwtToken,
						searchQuery: searchinput,
						columnName: sortState,
						direction: direction,
						page: Number(pageno),
						size: Number(rows),
					},
				})
				.then(
					(res) => {
						// console.log(res);
						setgetpageno(res.data.data.totalPages);
						setInbox(res.data.data.content);
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						toast.error(JSON.stringify(error.response.data.message));
						handle403error(error);
					}
				);
		} else {
			axios
				.get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
					headers: {
						Authorization: jwtToken,
						fromEmailId: isw_user_email,
						columnName: sortState,
						mailType: 'received',
						direction: direction,
						page: Number(pageno),
						size: Number(rows),
					},
				})
				.then(
					(res) => {
						setgetpageno(res.data.data.totalPages);
						setInbox(res.data.data.content);
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						toast.error(JSON.stringify(error.response.data.message));
						handle403error(error);
					}
				);
		}
	};
	const [client_admin_users, setClientDetails] = useState(null);
	const submit = (e) => {
		setbackdropopen(true);
		let srchdata = e.target[0].value;
		setsearchinput(srchdata);
		setpageno(0);
		console.log(srchdata);
		if (isw_user_role === '1' || isw_user_role === '6') {
			axios
				.get(`${base_url.api4}/searchEmailByMailType`, {
					headers: {
						Authorization: jwtToken,
						searchQuery: srchdata,
						columnName: sortState,
						direction: direction,
						mailType: 'received',
						page: Number(0),
						size: Number(rows),
					},
				})
				.then(
					(res) => {
						console.log(res);
						setgetpageno(res.data.data.totalPages);
						setInbox(res.data.data.content);
						settotalUser(res.data.data.totalElements);
						setcountnumber(res.data.data.number);
						setnumberofElements(res.data.data.numberOfElements);
						setsearch(true);
						// document.getElementById("myForm").reset();
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						handle403error(error);
						toast.error(JSON.stringify(error.response.data.message));
					}
				);
		} else {
			axios
				.get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
					headers: {
						Authorization: jwtToken,
						fromEmailId: isw_user_email,
						page: Number(pageno),
						size: Number(rows),
						mailType: 'received',
						columnName: sortState,
						direction: direction,
					},
				})
				.then(
					(res) => {
						setgetpageno(res.data.data.totalPages);
						setInbox(res.data.data.content);
						settotalUser(res.data.data.totalElements);
						setcountnumber(res.data.data.number);
						setnumberofElements(res.data.data.numberOfElements);
						setbackdropopen(false);
					},
					(error) => {
						setbackdropopen(false);
						toast.error(JSON.stringify(error.response.data.message));
						handle403error(error);
					}
				);
		}
	};

	const sortClient = (e) => {
		setbackdropopen(true);
		setsortState(e.target.id);
		if (direction == 'DESC') {
			setdirection('ASC');
		} else if (direction == 'ASC') {
			setdirection('DESC');
		}
		axios
			.get(`${base_url.api4}/getByMailTypeAndFromEmailId`, {
				headers: {
					Authorization: jwtToken,
					fromEmailId: isw_user_email,
					columnName: sortState,
					mailType: 'received',
					direction: direction,
					page: Number(pageno),
					size: Number(rows),
				},
			})
			.then((res) => {
				setgetpageno(res.data.data.totalPages);
				setInbox(res.data.data.content);
				setbackdropopen(false);
			})
			.catch((error) => {
				setbackdropopen(false);
				handle403error(error);
			});
	};

	const [selectemail, setselectedmail] = useState({});
	const [modalIsOpen3, setIsOpen3] = React.useState(false);
	const openView = (p) => {
		setselectedmail(p);
		setIsOpen3(true);
		let data = {
			id: Number(p.id),
			status: 0,
		};
		axios
			.post(`${base_url.api4}/updateEmailById`, data, {
				headers: {
					Authorization: jwtToken,
				},
			})
			.then(
				(response) => {
					getInbox(pageno);
				},
				(error) => {
					handle403error(error);
				}
			);
	};

	const [modalIsOpen4, setIsOpen4] = React.useState(false);
	const openReply = (p) => {
		console.log(p);
		setselectedmail(p);
		setIsOpen4(true);
	};

	const closeModal = () => {
		setIsOpen3(false);
		setIsOpen4(false);
	};

	const submitReply = (data) => {
		setbackdropopen(true);
		let data2 = {
			description: setComment,
			fromEmailId: isw_user_email,
			mailType: 'received',
			status: Number(1),
			subject: setSubject,
			toEmailId: selectemail.toEmailId,
		};
		console.log(data2);
		let formData = new FormData();
		let fileToUpload = data.picture.item(0);
		if (fileToUpload != null) {
			if (filesizemore === true) {
				setbackdropopen(false);
				return false;
			} else {
				formData.append('emailAttachmentFile', fileToUpload, fileToUpload.name);
				// console.log("Formdata", formData);
				setattachmentState(true);
				axios
					.post(`${base_url.api4}/uploadEmailAttachment`, formData, {
						headers: {
							Authorization: jwtToken,
							fromEmailId: isw_user_email,
							toEmailId: selectemail.toEmailId,
							mailType: 'received',
							subject: setSubject,
							description: setComment,
							status: Number(1),
						},
					})
					.then(
						(response) => {
							setbackdropopen(false);
							toast.success(response.data.message);
							let logs =
								isw_user_name +
								' Uploaded Attachment & Sent Reply to ' +
								selectemail.toEmailId;
							callsaveauditapi(logs);
							setIsOpen4(false);
							getInbox(pageno);
						},
						(error) => {
							setbackdropopen(false);
							toast.error(JSON.stringify(error.response.data.message));
							handle403error(error);
						}
					);
			}
		} else {
			setattachmentState(false);
			axios.post(`${base_url.api4}/createEmail`, data2).then(
				(response) => {
					setbackdropopen(false);
					toast.success(response.data.message);
					let logs = isw_user_name + ' Send Reply to ' + selectemail.toEmailId;
					callsaveauditapi(logs);
					setIsOpen4(false);
					getInbox(pageno);
				},
				(error) => {
					setbackdropopen(false);
					toast.error(JSON.stringify(error.response.data.message));
					handle403error(error);
				}
			);
		}
	};

	const chkfilesize = (e) => {
		if (e.target.files[0].size / 1048576 > 4) {
			setfilesizemore(true);
		} else {
			setfilesizemore(false);
		}
	};

	// const sortTypes = {
	//   up: {
	//     class: 'sort-up',
	//     fn: (a, b) => a.net_worth - b.net_worth
	//   },
	//   down: {
	//     class: 'sort-down',
	//     fn: (a, b) => b.net_worth - a.net_worth
	//   },
	//   // default: {
	//   //   class: 'sort',
	//   //   fn: (a, b) => a
	//   // }
	// };
	const dashboardvalue = useSelector(selectdashboard);

	const downloadAll = (emailAttachmentPath, toEmailId, fromEmailId) => {
		setbackdropopen(true);
		let file_name =
			emailAttachmentPath.split('amazonaws.com/')[
				emailAttachmentPath.split('amazonaws.com/').length - 1
			];
		const header = {
			method: 'GET',
			headers: {
				Authorization: jwtToken,
				toEmailId: toEmailId,
				fromEmailId: fromEmailId,
				fileName: file_name,
			},
		};

		var url = `${base_url.api4}/downloadEmailAttachment`;
		fetch(url, header)
			.then((response) => readAllChunks(response))
			.then(async function (response) {
				console.log(response);
				const downloadFileType = localStorage.getItem(
					'email_attachment_file_content_type'
				);
				console.log(downloadFileType);
				var blob = new Blob(response, { type: downloadFileType });
				var link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = file_name;
				link.click();
				setbackdropopen(false);
			})
			.catch((error) => {
				setbackdropopen(false);
				console.log(error);
			});
	};

	const readAllChunks = (readableStream) => {
		localStorage.setItem(
			'email_attachment_file_content_type',
			readableStream.headers.get('content-type')
		);
		const reader = readableStream.body.getReader();
		const chunks = [];

		function pump() {
			return reader.read().then(({ value, done }) => {
				if (done) {
					return chunks;
				}
				chunks.push(value);
				return pump();
			});
		}
		return pump();
	};

	const handle403error = (err) => {
		if (err) {
			if (err.response) {
				if (Number(err?.response?.status) === 403) {
					setTimeout(() => {
						dispatch(logout());
						localStorage.clear();
						localStorage.setItem('from_403_status', 'call_toast');
						history.push('/');
						toast.warn('Session Expired');
					}, 1000);
				}
			}
		}
	};
	return (
		<div>
			<ToastContainer />
			<Backdrop className={classes.backdrop} open={backdropopen}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <div className="commoncomponent_cardcss"> */}
			<div
				class="page-head d-flex justify-content-between p-4"
				style={{ marginTop: '4.3rem', marginBottom: '-20px' }}
			>
				<ul class="breadcrums">
					<h3 class="page-title bold">Mail Receive</h3>
					<a href="#/" class="b-link">
						Home
					</a>
					<span className="s_span ">-</span>
					<a class="b-link">Mail</a>
					<span className="s_span ">-</span>
					<a href="#/received-mails" class="b-link active">
						Received
					</a>
				</ul>
				{check1pointpermission(102, permissionData, rolepermission) ? (
					<a
						href="#/sent-mails"
						class="sent-button"
						style={{ fontWeight: 'revert', textDecoration: 'none' }}
					>
						Mail Sent
					</a>
				) : null}
			</div>
			<div>
				<Row>
					{/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Received</strong>
              </CardText>
            </Col> */}
				</Row>
				<div
					className="newlisting_headertabdesign"
					style={{
						padding: '1.5rem',
						borderRight: '1.5rem solid #e9ebf2',
						borderLeft: '1.5rem solid #e9ebf2',
						borderTop: '1.5rem solid #e9ebf2',
						marginBottom: '0',
					}}
				>
					<Row style={{ marginTop: '10px' }}>
						<Col md={3}>
							<form
								className="master"
								onSubmit={(e) => {
									e.preventDefault();
									submitrows(e);
								}}
							>
								<div style={{ marginTop: '-10px', width: 'fit-content' }}>
									{/* <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#f5f5f9",
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl> */}
									<form className="master">
										<div style={{ marginTop: '-10px', marginRight: '24px' }}>
											<label
												className="area_rowslable"
												style={{ marginBottom: '0.5rem' }}
											>
												Limit
											</label>
											<br />
											<FormControl
												variant="standard"
												sx={{
													m: 1,
													minWidth: 180,
													marginTop: 0,
													maxHeight: 180,
												}}
											>
												<select
													className={classes.s_mail_limit}
													labelId="simple-select-label"
													id="simple-select"
													value={rows}
													onChange={handleRows}
												>
													<option value={10}>10</option>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>
											</FormControl>
										</div>
									</form>
								</div>
							</form>
						</Col>
						<Col md={5}>{null}</Col>
						<Col md={4}>
							<div className="clearsearchclass" style={{ marginTop: '20px' }}>
								<form
									id="myForm"
									className="d-flex"
									onSubmit={(e) => {
										e.preventDefault();
										submit(e);
									}}
								>
									<input
										className="form-control"
										placeholder="Search.."
										name="search"
										style={{ marginLeft: '0px', width: '100%' }}
									/>
									<button className="btn listpagesearch_button" type="submit">
										<i
											class="fa fa-search"
											type="submit"
											style={{ color: '#6d7290' }}
										></i>
									</button>
								</form>
							</div>
							{searchdata ? (
								<a
									className={classes.clrsrch}
									onClick={() => {
										setpageno(0);
										getInbox(0);
									}}
								>
									Clear Search
								</a>
							) : null}
						</Col>
					</Row>
				</div>
				<div
					className="newlisting_headertabdesign"
					style={{
						padding: '1.5rem',
						border: '1.5rem solid #e9ebf2',
						marginTop: '0',
						marginBottom: '0',
					}}
				>
					<Row className="s_pagi">
						<Col md={6}>
							{inbox?.length ? (
								<p className="s_para">
									{countnumber * rows + 1} to{' '}
									{countnumber * rows + numberofElements} of {totalUser} records
								</p>
							) : (
								<p className="s_para">No Record Found</p>
							)}
						</Col>
					</Row>
					<div className="above-scroller" onScroll={scrolldiv}>
						<div className="scroller"></div>
					</div>
					<div className="displaytablerow">
						<Table
							className="workload card-table table-borderless"
							responsive
							striped
							onScroll={scrolltable}
						>
							{/* <table
              id="example"
              className="table workload card-table table-borderless table-responsive"
              style={{ width: "100%" }}
            > */}
							<thead className="s_sticky">
								<tr>
									<th style={{ width: '50px', textAlign: 'center' }}>SN</th>
									{/* <th>Sender</th> */}
									<th style={{ minWidth: '280px' }}>
										SENDER {/* <UnfoldMoreIcon */}
										<img
											src={sort}
											className="sorting"
											id="toEmailId"
											onClick={sortClient}
											style={{
												cursor: 'pointer',
												display: `${display1}`,
												height: '10px',
											}}
										/>
									</th>
									<th style={{ minWidth: '90px' }}>
										LEAD FROM {/* <UnfoldMoreIcon */}
										<img
											src={sort}
											className="sorting"
											id="isAgent"
											onClick={sortClient}
											style={{
												cursor: 'pointer',
												display: `${display1}`,
												height: '10px',
											}}
										/>
									</th>
									<th style={{ minWidth: '90px' }}>
										SUBJECT {/* <UnfoldMoreIcon */}
										<img
											src={sort}
											className="sorting"
											id="subject"
											onClick={sortClient}
											style={{
												cursor: 'pointer',
												display: `${display1}`,
												height: '10px',
											}}
										/>
									</th>
									<th style={{ minWidth: '90px' }}>
										SENT TIME {/* <UnfoldMoreIcon */}
										<img
											src={sort}
											className="sorting"
											id="date"
											onClick={sortClient}
											style={{
												cursor: 'pointer',
												display: `${display1}`,
												height: '10px',
											}}
										/>
									</th>
									<th style={{ minWidth: '90px' }}>
										ATTACHMENTS{' '}
										{/* <UnfoldMoreIcon
                        id="emailAttachmentPath"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      /> */}
									</th>
									<th>ACTION</th>
								</tr>
							</thead>
							{inbox !== null ? (
								<tbody>
									{inbox?.map((p, index) => (
										<tr key={index}>
											<td style={{ textAlign: 'center' }}>
												{p.status === 1 ? (
													<>
														{index + 1}
														<FiberNewIcon style={{ color: 'red' }} />
														{'  '}
													</>
												) : (
													<>{index + 1}</>
												)}
											</td>
											{/* <th><FiberNewIcon style={{color:"red"}}/>{"  "}{index + 1}</th> */}
											{/* <th>{p.fromEmailId}</th> */}
											<td>{p.fromEmailId}</td>
											<td>{p.isAgent == 1 ? 'Agent' : 'Client'}</td>
											<td>{p.subject}</td>
											<td>
												{p.date
													? moment(p.date.split('.')[0]).format(
															'DD-MMM-YY hh.mm a'
													  )
													: null}
											</td>
											<th>
												{p.emailAttachmentPath != null ? (
													<a
														onClick={() => {
															downloadAll(
																p.emailAttachmentPath,
																p.toEmailId,
																p.fromEmailId
															);
														}}
														style={{ cursor: 'pointer' }}
													>
														View
													</a>
												) : (
													''
												)}
											</th>
											<th>
												{/* <a
                          className="sent-button"
                          id={p.id}
                          onClick={() => openReply(p)}
                        >
                          Reply
                        </a> */}
												<ReceivedReplyModal
													register={register}
													setbackdropopen={setbackdropopen}
													getInbox={getInbox}
													handle403error={handle403error}
													pageno={pageno}
													toast={toast}
													p={p}
													id={p.id}
													jwtToken={jwtToken}
												/>
												&nbsp;&nbsp;&nbsp;
												{/* <a
                          className="sent-button"
                          id={p.id}
                          onClick={() => openView(p)}
                        >
                          View
                        </a> */}
												<ReceivedViewModal selectemail={selectemail} p={p} />
												<Modal
													isOpen={modalIsOpen3}
													// onAfterOpen={afterOpenModal}
													onRequestClose={closeModal}
													style={customStyles}
													contentLabel="Example Modal"
												>
													<div>
														<h4>
															<strong>View Message</strong>
														</h4>
														<div className="form-group creatadmin">
															<Row>
																<Col>
																	<h4>
																		<strong>Receiver:</strong>
																	</h4>
																	<p>{selectemail.toEmailId}</p>
																</Col>
																<Col>
																	<h4>
																		<strong>Subject:</strong>
																	</h4>
																	<p>{selectemail.subject}</p>
																</Col>
															</Row>
														</div>
														<div className="form-group creatadmin">
															<Row>
																<Col>
																	<h4>
																		<strong>Description:</strong>
																	</h4>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: selectemail.description,
																		}}
																	/>
																</Col>
															</Row>
														</div>
													</div>
													<div className="form-group creatadmin">
														<div className="d-flex justify-content-end">
															<Button color="secondary" onClick={closeModal}>
																Close
															</Button>
														</div>
													</div>
												</Modal>
												<Modal
													isOpen={modalIsOpen4}
													// onAfterOpen={afterOpenModal}
													onRequestClose={closeModal}
													style={customStyles}
													contentLabel="Example Modal"
												>
													<form onSubmit={handleSubmit(submitReply)}>
														<div>
															<h3 style={{ color: 'black' }}>Send Reply</h3>
															<div className="form-group creatadmin">
																<Row>
																	<Col>
																		<label>Receiver:</label>
																		<input
																			type="text"
																			ref={register}
																			className="form-control underline-input"
																			value={selectemail.toEmailId}
																			readOnly
																			required
																		/>
																	</Col>
																</Row>
															</div>
															<div className="form-group creatadmin">
																<Row>
																	<Col>
																		<label>Subject:</label>
																		<input
																			type="text"
																			ref={register}
																			className="form-control underline-input"
																			onChange={(e) => {
																				setsetSubject(e.target.value);
																			}}
																			required
																		/>
																	</Col>
																</Row>
															</div>
															<div className="form-group creatadmin">
																<Row>
																	<Col>
																		<label>Description:</label>
																		<TextareaAutosize
																			rowsMax={6}
																			ref={register}
																			style={{ height: '100px' }}
																			className="form-control underline-input"
																			onChange={(e) => {
																				setsetComment(e.target.value);
																			}}
																			required
																		/>
																	</Col>
																</Row>
															</div>
															<div className="form-group creatadmin">
																<Row>
																	<Col>
																		<label>Attachment:</label>
																		<input
																			ref={register}
																			type="file"
																			name="picture"
																			onChange={(e) => chkfilesize(e)}
																			accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf, .doc, .docx"
																		/>
																		{filesizemore && (
																			<p className="errormessage">
																				File Size cannot be more than 4 mb
																			</p>
																		)}
																	</Col>
																</Row>
															</div>
															<div className="form-group creatadmin">
																<Row className="justify-content-end mt-2">
																	<div>
																		<Button
																			color="secondary"
																			onClick={closeModal}
																		>
																			Cancel
																		</Button>
																	</div>
																	<div>
																		<button
																			type="submit"
																			className="btn listing_addbutton ml-1"
																		>
																			Send
																		</button>
																	</div>
																</Row>
															</div>
														</div>
													</form>
												</Modal>
											</th>
										</tr>
									))}
								</tbody>
							) : (
								<Typography>{null}</Typography>
							)}
						</Table>
					</div>
					<Row>
						<Col md={6}>
							<div>
								<form className="master">
									<div style={{ marginTop: '-10px' }}>
										<label
											className="area_rowslable"
											style={{ fontWeight: 'bold' }}
										></label>
										<br />
										<FormControl
											variant="standard"
											sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}
										>
											<select
												className={classes.selectlimit}
												labelId="simple-select-label"
												id="simple-select"
												value={rows}
												onChange={handleRows}
											>
												<option value={10}>10</option>
												<option value={20}>20</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>
										</FormControl>
										{inbox?.length ? (
											<div className="s_spanPage">
												{countnumber * rows + 1} to{' '}
												{countnumber * rows + numberofElements} of {totalUser}{' '}
												records
											</div>
										) : (
											<div className="s_spanPage">No Record Found</div>
										)}
									</div>
								</form>
							</div>
						</Col>
						<Col md={6}>
							<div className={classes.root}>
								{searchdata ? (
									<Pagination
										count={getpageno}
										onChange={handlesearch}
										shape="rounded"
									/>
								) : (
									<Pagination
										count={getpageno}
										onChange={handlepage}
										shape="rounded"
									/>
								)}
							</div>
						</Col>
					</Row>
				</div>
			</div>
			{/* </div> */}
		</div>
	);
};

export default ReceivedInbox;
