import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Modal from "react-modal";
import "./../../css/Responsive.css";
import { useForm } from "react-hook-form";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
// import { checksettingpermission } from "../../Permission/permission";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import EditAdminConfig from "../../Modal/Masters/EditAdminConfig";
import FormControl from "@material-ui/core/FormControl";
import Pagination from "@material-ui/lab/Pagination";

function AdminConfig(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const isw_user_role = localStorage.getItem("isw_user_role");
  const jwtToken = "Bearer " + user.isw_user_token;
  const { register, handleSubmit, errors } = useForm();
  const [backdropopen, setbackdropopen] = useState(false);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("configKey");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [setConfig, setConfigValue] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [totalUser, settotalUser] = useState();
  const [display1, setdisplay] = useState("inline");

  const isw_user_name = localStorage.getItem("isw_user_name");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allConfig, setallConfig] = useState();
  const [searchdata, setsearch] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allConfig]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    // if (checksettingpermission(permission_obj, resource_obj)) {
      if (check4pointpermission(1501, 1503, permission_obj, resource_obj)) {
      //if (available_permission){
      getallConfig(pageno);
      dispatch(setdashboardcheck(52));
      getISWOffice();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const [onlineUsers, setonlineUsers] = useState();
  const getISWOffice = () => {
    axios
      .get(`${base_url.api2}/findAllUsersByOfficeId`, {
        headers: {
          Authorization: jwtToken,
          officeId: 13,
          // officeId: 20,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        let consultant_data = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let data = {
            value: res.data.data[i].email,
            label:
              res.data.data[i].firstName +
              " " +
              res.data.data[i].lastName +
              " (" +
              res.data.data[i].email +
              ")",
            id: res.data.data[i].id,
          };
          consultant_data.push(data);
        }
        setSelectedIswOnlineConsultantData(consultant_data);
        setonlineUsers(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const getallConfig = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getAllConfig`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setnumberofElements(response.data.data.numberOfElements);
          setcountnumber(response.data.data.number);
          setallConfig(response.data.data.content);
          setbackdropopen(false);
          // document.getElementById("myForm").reset();
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
          setallConfig(null);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api3}/getAllConfig`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setbackdropopen(false);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setnumberofElements(response.data.data.numberOfElements);
          setcountnumber(response.data.data.number);
          setallConfig(response.data.data.content);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          setallConfig(null);
          handle403error(error);
          setbackdropopen(false);
        }
      );
  };

  const [selectedIswOnlineConsultantData, setSelectedIswOnlineConsultantData] =
    useState();

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = () => {
    setIsOpen2(true);
  };

  // Create location

  const createConfig = (data2) => {
    // if (setConfigStatus) {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let configKey = data2.configKey;
    let configName = data2.configName;
    // let configValue = data2.configValue;
    let configValue = setConfig;
    let id = 0;
    let data = {
      configKey,
      configName,
      configValue,
      id,
    };
    console.log(data);
    axios
      .post(`${base_url.api3}/createAdminConfig`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        getallConfig(pageno);

        let log =
          isw_user_name + " Created Config " + response.data.data.name + ".";
        callsaveauditapi(log);

        setIsOpen2(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        handle403error(error);
      });
    // }
  };

  const closeModal = () => {
    setIsOpen2(false);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem",marginBottom:"0px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Admin Config</h3>
          <a href="#/" class="b-link">Home</a>
          <span className="s_span ">-</span>
          <a class="b-link">Settings</a>
          <span className="s_span ">-</span>
          <a class="b-link active">Admin config</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {isw_user_role == "1" || isw_user_role == "6" ? (
          <>
            {check1pointpermission(1502, permissionData, rolepermission) ? (
              <a className="sent-button" onClick={openModal2}>
                Add
              </a>
            ) : null}
          </>
        ) : null}
        <Modal
          isOpen={modalIsOpen2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>
            <strong>Config</strong>
          </h2>
          <form onSubmit={handleSubmit(createConfig)}>
            <div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label>
                      Config Key<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="configKey"
                      ref={register({ required: true })}
                    />
                    {errors.configKey && (
                      <p className="errormessage">Config Key is Required</p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>
                      Key Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="configName"
                      ref={register({ required: true })}
                    />
                    {errors.configName && (
                      <p className="errormessage">Key Name is Required</p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>
                      Key Value<span style={{ color: "red" }}>*</span>:
                    </label>
                    <TextareaAutosize
                      rowsMax={6}
                      ref={register}
                      style={{ height: "100px",backgroundColor:"#f5f5f9" }}
                      className="form-control underline-input"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setConfigValue(e.target.value);
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="justify-content-end mt-2">
                      <Button
                        color="secondary"
                        onClick={() => setIsOpen2(false)}
                      >
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        className="btn listing_addbutton ml-1"
                      >
                        Submit
                      </button>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </Modal>
      </div>
      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allConfig?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th>
                    CONFIG{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="configKey"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "160px" }}>
                    CONFIG NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="configName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ width: "175px" }}>
                    CONFIG VALUE{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="configValue"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  {check1pointpermission(
                    1503,
                    permissionData,
                    rolepermission
                  ) ? (
                  <th style={{ width: "175px" }}>ACTION</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allConfig != null ? (
                  allConfig &&
                  allConfig?.map((p, index) => (
                    <tr key={p.id}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td>{p.configKey}</td>
                      <td>{p.configName}</td>
                      <td>{p.configValue}</td>
                      <td
                        id={p.id}
                        style={{ textAlign: "center", width: "175px" }}
                      >
                        {/* <Link id={p.configKey} onClick={editConfig}>
                            Edit
                          </Link> */}
                        {check1pointpermission(1503, permissionData, rolepermission) ? (
                          <EditAdminConfig
                            configkey={p.configKey}
                            toast={toast}
                            handle403error={handle403error}
                            getallConfig={getallConfig}
                            pageno={pageno}
                            selectedIswOnlineConsultantData={
                              selectedIswOnlineConsultantData
                            }
                            onlineUsers={onlineUsers}
                            jwtToken={jwtToken}
                            isw_user_name={isw_user_name}
                          />
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>No User Available</p>
                )}
              </tbody>
            </Table>
            {/* <div className={classes.root}>
              <Pagination count={getpageno} onChange={handlepage} shape="rounded" />
            </div> */}
          </div>
          <Row>
            <Col md={6}>
            <div>
            <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{fontWeight:"bold"}}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180 ,marginTop: 0, maxHeight: 180}}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                      </select>
                   </FormControl>
              {allConfig?.length ? (
                <div className="s_spanPage">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </div>
              ) : (
                <div className="s_spanPage">No Record Found</div>
              )}
                 </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    count={getpageno}
                    //onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    //onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(AdminConfig);
