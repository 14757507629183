import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const DashboardDrop_new = ({
	showDashTitle,
	setShowDash,
	check1pointpermission,
	check4pointpermission,
}) => {
	return (
		<div style={{ marginTop: '100px' }}>
			<ul class="nav flex-column main-nav">
				<li class="nav-item">
					<a class="nav-link extended" onClick={() => setShowDash(false)}>
						<ArrowBackIcon onClick={() => setShowDash(false)} />
						{showDashTitle}
					</a>
				</li>
				{showDashTitle === 'AC Receivable' && (
					<>
						{check1pointpermission(510) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-admission">
									Admission
								</a>
							</li>
						)}
						{check1pointpermission(511) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-migration">
									Migration
								</a>
							</li>
						)}
						{check1pointpermission(512) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-insurance">
									Insurance
								</a>
							</li>
						)}
						{check1pointpermission(513) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-general">
									Skill Assessment
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'AC Payable' && (
					<>
						{check1pointpermission(515) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-payable-admission">
									Admission
								</a>
							</li>
						)}
						{check1pointpermission(516) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-payable-migration">
									Migration
								</a>
							</li>
						)}
						{check1pointpermission(517) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-payable-insurance">
									Insurance
								</a>
							</li>
						)}
						{check1pointpermission(518) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/acc-payable-general">
									Skill Assessment
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'General' && (
					<>
						{check1pointpermission(1003) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/today-lead-report">
									Todays Lead
								</a>
							</li>
						)}
						{check1pointpermission(1007) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/leads-report">
									Lead
								</a>
							</li>
						)}
						{check1pointpermission(1011) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/client-report">
									Clients
								</a>
							</li>
						)}
						{check1pointpermission(1015) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/branch-wise-general-summery-report"
								>
									Branchwise Summary
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'Admission' && (
					<>
						{check1pointpermission(1020) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/admisison-report">
									Admission Report
								</a>
							</li>
						)}
						{check1pointpermission(1024) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/superAgent-receivable-admission-summery-report"
								>
									SuperAgent Receivable
								</a>
							</li>
						)}
						{check1pointpermission(1028) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/subAgent-payable-admission-summery-report"
								>
									SubAgent Summery
								</a>
							</li>
						)}
						{check1pointpermission(1032) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/admission-provider-summery-report"
								>
									Provider Summery
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'Migration' && (
					<>
						{check1pointpermission(1037) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/migration-report">
									Migration Report
								</a>
							</li>
						)}
						{check1pointpermission(1041) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/subAgent-payable-migration-summery-report"
								>
									SubAgent Summery
								</a>
							</li>
						)}
						{check1pointpermission(1045) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/migration-provider-summery-report"
								>
									Migration Provider Summery
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'Insurance' && (
					<>
						{check1pointpermission(1050) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/insurance-report">
									Insurance Report
								</a>
							</li>
						)}
						{check1pointpermission(1054) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/subAgent-payable-insurence-summery-report"
								>
									SubAgent Summery
								</a>
							</li>
						)}
						{check1pointpermission(1058) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/insurence-provider-summery-report"
								>
									Insurance Provider Summery
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'Skill Assessment' && (
					<>
						{check1pointpermission(1067) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/subAgent-payable-skill-assessment-summery-report"
								>
									SubAgent Summery
								</a>
							</li>
						)}
						{check1pointpermission(1071) && (
							<li class="nav-item">
								<a
									className="nav-link extended"
									href="#/skill-assessment-provider-summery-report"
								>
									SK Provider Summery
								</a>
							</li>
						)}
					</>
				)}
				{showDashTitle === 'Setting' && (
					<>
						{check4pointpermission(1501, 1503) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/admin-config">
									Admin Config
								</a>
							</li>
						)}
						{check4pointpermission(1504, 1508) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/areas">
									Area
								</a>
							</li>
						)}
						{check4pointpermission(1509, 1513) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/category">
									Category
								</a>
							</li>
						)}
						{check4pointpermission(1514, 1518) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/client-source">
									Client Source
								</a>
							</li>
						)}
						{check4pointpermission(1519, 1522) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/group-permissions">
									Group Permissions
								</a>
							</li>
						)}
						{check4pointpermission(1523, 1527) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/healthcovertype">
									HealthCoverType
								</a>
							</li>
						)}
						{check4pointpermission(1528, 1532) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/insurance">
									Insurance
								</a>
							</li>
						)}
						{check4pointpermission(1533, 1537) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/intake">
									Intake
								</a>
							</li>
						)}
						{check4pointpermission(1538, 1542) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/interestedservices">
									Interested Services
								</a>
							</li>
						)}
						{check4pointpermission(1543, 1547) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/locations">
									Location
								</a>
							</li>
						)}
						{check4pointpermission(1548, 1552) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/levels">
									Level
								</a>
							</li>
						)}
						{check4pointpermission(1553, 1557) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/migrationprovider">
									Migration Provider
								</a>
							</li>
						)}
						{check4pointpermission(1558, 1563) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/proforma">
									Proforma
								</a>
							</li>
						)}
						{check4pointpermission(1564, 1568) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/visatype">
									Visa Type
								</a>
							</li>
						)}
						{check4pointpermission(1569, 1574) && (
							<li class="nav-item">
								<a className="nav-link extended" href="#/workflow">
									Workflow
								</a>
							</li>
						)}
					</>
				)}
			</ul>
		</div>
	);
};

export default DashboardDrop_new;
