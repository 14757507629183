import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";
import base_url from "../../../api/bootapi";
import axios from "axios";
import callsaveauditapi from "../../../services/auditservice";
import { ToastContainer, toast } from "react-toastify";


const LeadArchiveModal = ({ id, status, jwtToken, handle403error, getallLeads, pageno, headData, sortState, direction, rows, statusstate }) => {
    const [changestatusmodal, setchangestatusmodal] = useState(false);
    const openmodal = () => setchangestatusmodal(true);
    const isw_user_name = localStorage.getItem("isw_user_name");

    const updateUser = () => {
        let status1;
        if (status === 0) {
            status1 = 1;
        } else {
            status1 = 0;
        }
        let data = {
            id: id,
            status: status1,
        };
        axios
            .post(`${base_url.api3}/updateClientTempById`, data, {
                headers: {
                    Authorization: jwtToken,
                },
            })
            .then(
                (response) => {
                    toast.success("Status Changed");
                    setchangestatusmodal(false);
                    let logs =
                        isw_user_name +
                        " Updated status of Lead " +
                        response.data.data.email +
                        " to " +
                        response.data.data.status +
                        ".";
                    callsaveauditapi(logs);
                    getallLeads(headData = {
                        Authorization: jwtToken,
                        columnName: sortState,
                        direction: direction,
                        page: Number(pageno),
                        size: Number(rows),
                        status: statusstate,
                    });
                },
                (error) => {
                    toast.error(JSON.stringify(error.response.data.message));
                    handle403error(error);
                }
            );
    };
    return (
        <>
            <span style={{ cursor: "pointer" }} id={id} onClick={() => openmodal(id)}>
                {/* <i class="icon">
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="#17B978"
                    >
                        <path
                            d="M5.69629 9.6289V18.126C5.69629 18.4173 5.93906 18.6439 6.21421 18.6439H17.7703C18.0616 18.6439 18.2882 18.4012 18.2882 18.126V9.6289H5.69629ZM14.6142 12.8821C14.6142 13.1734 14.3714 13.4 14.0963 13.4H9.8882C9.59687 13.4 9.37028 13.1572 9.37028 12.8821V11.8301C9.37028 11.5387 9.61305 11.3121 9.8882 11.3121H14.0963C14.3876 11.3121 14.6142 11.5549 14.6142 11.8301V12.8821Z"
                            fill="#17B978"
                        />
                        <path
                            d="M18.4659 5.35607H5.51792C5.22659 5.35607 5 5.59885 5 5.87399V8.93295H19V5.89018C18.9838 5.59885 18.7572 5.35607 18.4659 5.35607Z"
                            fill="#17B978"
                        />
                    </svg>
                </i> */}

                {status === 1 ? "Archive" : "Unarchive"}

            </span>
            <Modal
                isOpen={changestatusmodal}
                onRequestClose={() => setchangestatusmodal(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h4 style={{ marginTop: "10px" }}>
                    Are you sure you want to{" "}
                    {status === 1 ? "Archive" : "Unarchive"}{" "}
                    current record ?{" "}
                </h4>
                <div
                    style={{
                        display: "flex",
                        // marginTop: "20px",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <button
                        className="btn listing_addbutton ml-1"
                        onClick={updateUser}
                    >
                        Yes
                    </button>
                    <button
                        className="btn btn-secondary cancel_margin"
                        onClick={() => setchangestatusmodal(false)}
                    >
                        No
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default LeadArchiveModal;