import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./../css/Responsive.css";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import Select2 from "@material-ui/core/Select";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Modal from "react-modal";
import axios from "axios";
import base_url from "../../api/bootapi";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Pagination from "@material-ui/lab/Pagination";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import { getquotationpermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import sort from "./../images/sort.svg";

const QuotationList = () => {
  const classes = useStyles();
  const history = useHistory();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [backdropopen, setbackdropopen] = useState(false);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [checkclient, setcheckclient] = useState(true);
  const user = useSelector(selectUser);
  const [radioattachmentvalue, setradioattachmentvalue] = React.useState();
  const [datefilter, setdatefilter] = useState();
  const [deletemodal, setdeletemodal] = useState(false);
  const [deletequotation, setdeletequotation] = useState();
  let canceltoken;
  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const dispatch = useDispatch();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allQuotation, setQuotation] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allQuotation]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check4pointpermission(1200, 1204, permission_obj, resource_obj)) {
      //if (available_permission) {
      localStorage.setItem("quotation", null);
      dispatch(setdashboardcheck(29));

      if (localStorage.getItem("quatationPageNo")) {
        let currentPage = localStorage.getItem("quatationPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getQuotation(currentPage);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getQuotation(0);
      }
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      // console.log('No access to the page');
      history.push("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getQuotation(value - 1);
    setpaginationpageno(value);
  };
  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const getQuotation = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllQuotationWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setQuotation(res.data.data.content);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setsearch(false);
        setdatefilter(false);
        document.getElementById("myForm").reset();
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getAllQuotationWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setQuotation(response.data.data.content);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const [loadoptions, setloadoptions] = useState();
  const [defaultloadoptions, setdefaultloadoptions] = useState();
  const [loadoptions2, setloadoptions2] = useState();
  const [defaultloadoptions2, setdefaultloadoptions2] = useState();

  const openModal = () => {
    setIsOpen(true);
  };
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setradioattachmentvalue(event.target.value);
    if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
      if (event.target.value === "client") {
        getallclient("all");
      } else if (event.target.value === "lead") {
        getallclienttemp("all");
      }
    } else {
      if (event.target.value === "client") {
        getallclient(user?.isw_available_Office[0].officeId);
      } else if (event.target.value === "lead") {
        getallclienttemp(user?.isw_available_Office[0].officeId);
      }
    }
  };
  const [officechange, setofficechange] = useState("all");
  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    setofficechange(optionElementName);
    if (optionElementName === "all") {
      if (radioattachmentvalue === "client") {
        getallclient("all");
      } else if (radioattachmentvalue === "lead") {
        getallclienttemp("all");
      }
    } else {
      if (radioattachmentvalue === "client") {
        getallclient(optionElementName);
      } else if (radioattachmentvalue === "lead") {
        getallclienttemp(optionElementName);
      }
    }
  };
  const getallclient = (value) => {
    setbackdropopen(true);
    if (value === "all") {
      axios
        .get(`${base_url.api3}/getAllClients`, {
          headers: {
            Authorization: jwtToken,
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          let data = res.data.data.content;
          const office_obj = [];
          for (let i = 0; i < data.length; i++) {
            let data2 = {
              value: data[i].id,
              label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
              email: data[i].email,
            };
            office_obj.push(data2);
          }
          setloadoptions(office_obj);
          setbackdropopen(false);
          setdefaultloadoptions(office_obj);
        })
        .catch((err) => {
          handle403error(err);
          setbackdropopen(false);
        });
    } else {
      axios
        .get(`${base_url.api3}/getClientByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(value),
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          let data = res.data.data.content;
          const office_obj = [];
          for (let i = 0; i < data.length; i++) {
            let data2 = {
              value: data[i].id,
              label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
              email: data[i].email,
            };
            office_obj.push(data2);
          }
          setbackdropopen(false);
          setloadoptions(office_obj);
          setdefaultloadoptions(office_obj);
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };
  const getallclienttemp = (value) => {
    setbackdropopen(true);
    if (value === "all") {
      axios
        .get(`${base_url.api3}/getAllClientTempWithPage`, {
          headers: {
            Authorization: jwtToken,
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 20,
            status: 1,
          },
        })
        .then((res) => {
          let data = res.data.data.content;
          const office_obj = [];
          for (let i = 0; i < data.length; i++) {
            let data2 = {
              value: data[i].id,
              label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
              email: data[i].email,
            };
            office_obj.push(data2);
          }
          setbackdropopen(false);
          setloadoptions2(office_obj);
          setdefaultloadoptions2(office_obj);
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    } else {
      axios
        .get(`${base_url.api3}/getClientTempByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(value),
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          let data = res.data.data.content;
          const office_obj = [];
          for (let i = 0; i < data.length; i++) {
            let data2 = {
              value: data[i].id,
              label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
              email: data[i].email,
            };
            office_obj.push(data2);
          }
          setloadoptions2(office_obj);
          setbackdropopen(false);
          setdefaultloadoptions2(office_obj);
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const submitrows = (e) => {
    getQuotation(0);
  };

  const handleInputChange = (newvalue) => {
    if (newvalue) {
      if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
        if (officechange === "all") {
          if (typeof canceltoken !== typeof undefined) {
            canceltoken.cancel("Cancelling previous request");
          }
          canceltoken = axios.CancelToken.source();
          axios
            .get(`${base_url.api3}/searchClientSelect`, {
              headers: {
                Authorization: jwtToken,
                searchQuery: newvalue,
              },
              cancelToken: canceltoken.token,
            })
            .then((res) => {
              let data = res.data.data;
              const office_obj = [];
              for (let i = 0; i < data.length; i++) {
                let data2 = {
                  value: data[i].id,
                  label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
                  email: data[i].email,
                };
                office_obj.push(data2);
              }
              setloadoptions(office_obj);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (typeof canceltoken !== typeof undefined) {
            canceltoken.cancel("Cancelling previous request");
          }
          canceltoken = axios.CancelToken.source();
          axios
            .get(`${base_url.api3}/searchClientSelectByOfficeId`, {
              headers: {
                Authorization: jwtToken,
                searchQuery: newvalue,
                officeId: officechange,
              },
              cancelToken: canceltoken.token,
            })
            .then((res) => {
              let data = res.data.data;
              const office_obj = [];
              for (let i = 0; i < data.length; i++) {
                let data2 = {
                  value: data[i].id,
                  label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
                  email: data[i].email,
                };
                office_obj.push(data2);
              }
              setloadoptions(office_obj);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (typeof canceltoken !== typeof undefined) {
          canceltoken.cancel("Cancelling previous request");
        }
        canceltoken = axios.CancelToken.source();
        axios
          .get(`${base_url.api3}/searchClientSelectByOfficeId`, {
            headers: {
              Authorization: jwtToken,
              searchQuery: newvalue,
              officeId: user?.isw_available_Office[0].officeId,
            },
            cancelToken: canceltoken.token,
          })
          .then((res) => {
            let data = res.data.data;
            const office_obj = [];
            for (let i = 0; i < data.length; i++) {
              let data2 = {
                value: data[i].id,
                label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
                email: data[i].email,
              };
              office_obj.push(data2);
            }
            setloadoptions(office_obj);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setloadoptions(defaultloadoptions);
    }
  };
  const onselect = (selectedList, selectedItem) => {
    console.log(selectedList);
    localStorage.setItem("quotation", JSON.stringify(selectedList));
  };

  const handleInputChange2 = (newvalue) => {
    if (newvalue) {
      if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
        if (officechange === "all") {
          if (typeof canceltoken !== typeof undefined) {
            canceltoken.cancel("Cancelling previous request");
          }
          canceltoken = axios.CancelToken.source();
          axios
            .get(`${base_url.api3}/searchLeadSelect`, {
              headers: {
                Authorization: jwtToken,
                searchQuery: newvalue,
              },
              cancelToken: canceltoken.token,
            })
            .then((res) => {
              let data = res.data.data;
              const office_obj = [];
              for (let i = 0; i < data.length; i++) {
                let data2 = {
                  value: data[i].id,
                  label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
                  email: data[i].email,
                };
                office_obj.push(data2);
              }
              setloadoptions2(office_obj);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (typeof canceltoken !== typeof undefined) {
            canceltoken.cancel("Cancelling previous request");
          }
          canceltoken = axios.CancelToken.source();
          axios
            .get(`${base_url.api3}/searchLeadSelectByOfficeId`, {
              headers: {
                Authorization: jwtToken,
                searchQuery: newvalue,
                officeId: officechange,
              },
              cancelToken: canceltoken.token,
            })
            .then((res) => {
              let data = res.data.data;
              const office_obj = [];
              for (let i = 0; i < data.length; i++) {
                let data2 = {
                  value: data[i].id,
                  label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
                  email: data[i].email,
                };
                office_obj.push(data2);
              }
              setloadoptions2(office_obj);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (typeof canceltoken !== typeof undefined) {
          canceltoken.cancel("Cancelling previous request");
        }
        canceltoken = axios.CancelToken.source();
        axios
          .get(`${base_url.api3}/searchLeadSelectByOfficeId`, {
            headers: {
              Authorization: jwtToken,
              searchQuery: newvalue,
              officeId: user?.isw_available_Office[0].officeId,
            },
            cancelToken: canceltoken.token,
          })
          .then((res) => {
            let data = res.data.data;
            const office_obj = [];
            for (let i = 0; i < data.length; i++) {
              let data2 = {
                value: data[i].id,
                label: `${data[i].firstName} ${data[i].lastName}(${data[i].email})`,
                email: data[i].email,
              };
              office_obj.push(data2);
            }
            setloadoptions2(office_obj);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setloadoptions2(defaultloadoptions2);
    }
  };
  const onselect2 = (selectedList, selectedItem) => {
    console.log(selectedList);
    localStorage.setItem("quotation", JSON.stringify(selectedList));
  };
  const createQuotation = () => {
    var item = JSON.parse(localStorage.getItem("quotation"));
    if (item === null) {
      setcheckclient(false);
    } else {
      history.push("/create-quotation");
    }
  };
  const editQuotation = (elem) => {
    localStorage.setItem("quatationPageNo", pageno);
    var quotation_id = elem.target.id;
    history.push("/edit-quotation/" + quotation_id);
  };
  const submit = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchQuotation`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setQuotation(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(true);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };
  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    let data = {
      quotationFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      quotationToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    axios
      .post(`${base_url.api2}/searchQuotationByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setQuotation(response.data.data.content);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpageno(value - 1);
    setpaginationpageno(value);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchQuotationByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setQuotation(response.data.data.content);
            setsearch(false);
            setgetpageno(response.data.data.totalPages);
            document.getElementById("myForm").reset();
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchQuotation`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setbackdropopen(false);
            setQuotation(response.data.data.content);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const deleteQuotation = () => {
    axios
      .delete(`${base_url.api2}/deleteQuotationById`, {
        headers: {
          Authorization: jwtToken,
          quotationId: deletequotation,
        },
      })
      .then((res) => {
        toast.success("Quotation Deleted");
        setdeletemodal(false);
        getQuotation(pageno);
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <>
      <div>
        <ToastContainer />
        <Backdrop className={classes.backdrop} open={backdropopen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <div className="commoncomponent_cardcss"> */}
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
        >
          <ul class="breadcrums">
            <h3 class="page-title bold">Quotation</h3>
            <a href="#/" class="b-link">
              Home
            </a>
            <span className="s_span ">-</span><a class="b-link">Other</a><span className="s_span ">-</span>
            <a href="#/quotations" class="b-link active">
              Quotation
            </a>
          </ul>
          {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
          {check1pointpermission(1202, permissionData, rolepermission) ? (
            <a className="sent-button" onClick={openModal}>
              Add
            </a>
          ) : null}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div style={{ marginTop: "-30px" }}>
              {user?.isw_user_role === 1 || user?.isw_user_role === 6 ? (
                <div className="form-group">
                  <label>
                    <strong>Branch:</strong>
                  </label>
                  <select
                    className={classes.selectdrop}
                    onChange={handleChangeOffice}
                    name="branch"
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    {user?.isw_user_role === 1 || user?.isw_user_role === 6 ? (
                      <option value="all">Show All</option>
                    ) : null}
                    {user?.isw_available_Office?.map((p, index) => (
                      <option key={index + 1} value={p.officeId}>
                        {p.officeName}
                        {" - "}
                        {p.officeCode}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="client_type"
                  value={radioattachmentvalue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="client"
                    control={<Radio color="primary" />}
                    label="Client"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="lead"
                    control={<Radio color="primary" />}
                    label="Lead"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
              {radioattachmentvalue === "client" ? (
                <>
                  <h6 style={{ fontWeight: "bolder" }}>Client</h6>
                  <Select
                    // cacheOptions
                    name="clientName"
                    options={loadoptions}
                    defaultOptions={defaultloadoptions}
                    onInputChange={handleInputChange}
                    onChange={onselect}
                  />
                </>
              ) : (
                <>
                  {radioattachmentvalue === "lead" ? (
                    <>
                      <h6 style={{ fontWeight: "bolder" }}>Lead</h6>
                      <Select
                        // cacheOptions
                        name="clientName"
                        options={loadoptions2}
                        onInputChange={handleInputChange2}
                        onChange={onselect2}
                      />
                    </>
                  ) : null}
                </>
              )}

              <Row
                style={{
                  marginLeft: "10px",
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                {!checkclient && (
                  <p className="errormessage">
                    Client or Temp client is required
                  </p>
                )}
              </Row>
              <Row>
                <Col md={6} style={{ marginTop: "30px" }}>
                  {null}
                </Col>
                <Col md={3} className="d-flex justify-content-end mt-1">
                  <Button color="secondary" onClick={() => closeModal()}>
                    Cancel
                  </Button>
                </Col>
                <Col md={3} className="d-flex justify-content-end mt-1">
                  <button
                    className="btn listing_addbutton"
                    onClick={createQuotation}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
        <div>
          <Row>
            {/* <Col md={6}>
                <CardText
                  className={classes.headtext}
                  style={{ marginTop: "-10px" }}
                >
                  <strong>Quotation</strong>
                </CardText>
              </Col> */}
            {/* <Col md={6} className="d-flex justify-content-end">
              {permissionData?.includes(550) ? (
                <button className="btn listing_addbutton" onClick={openModal}>
                  <AddIcon className="listing_addbutton_i" /> Add
                </button>
              ) : null}
            </Col> */}
          </Row>
          <div
            className="newlisting_headertabdesign"
            style={{
              padding: "1.5rem",
              borderRight: "1.5rem solid #e9ebf2",
              borderLeft: "1.5rem solid #e9ebf2",
              borderTop: "1.5rem solid #e9ebf2",
              marginBottom: "0",
            }}
          >
            <Row style={{ marginTop: "20px", marginBottom: "-15px" }}>
              <Col md={6} style={{ marginTop: "-20px" }}>
                <Row>
                  <form
                    className="master"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitrows(e);
                    }}
                  >
                    <div style={{ marginTop: "-10px" }}>
                      <label className="area_rowslable_skill">Limit</label>
                      <br />
                      {/* <FormControl className={classes.formControl}>
                        <Select2
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={rows}
                          onChange={handleRows}
                          style={{
                            border: "2px solid #ced4da ",
                            paddingTop: "5px",
                            backgroundColor: "#f5f5f9",
                          }}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select2>
                      </FormControl> */}
                      <select
                        className="form-control"
                        name="branch"
                        value={rows}
                        onChange={handleRows}
                        style={{ backgroundColor: "#f5f5f9", width: "100px", marginRight: "10px" }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </form>
                  <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
                    <label className="area_rowslable_skill">Date</label>
                    <div className="clearsearchclass" style={{ width: "200px", marginTop: "0px" }}>
                      <form
                        id="myForm"
                        className="d-flex"
                        style={{ position: "relative" }}
                      >
                        <DateRangePicker
                          onEvent={handleEvent}
                          onCallback={handleCallback}
                          onApply={handleApply}
                        >
                          <p
                            className="datepickerrangevaluetext"
                            style={{ backgroundColor: "#f5f5f9", fontSize: "14px", color: "#6d7290", padding: "10px", width: "100%" }}
                          >
                            {datevalue}
                          </p>
                        </DateRangePicker>
                        {/* {datefilter ? (
                      <div style={{ float: "left" }}>
                        <a
                          className={classes.clrsrch}
                          onClick={() => {
                            setdirection("DESC");
                            setdatevalue("Click to open");
                            setpageno(0);
                            getQuotation(0);
                          }}
                        >
                          Clear Filter
                        </a>
                      </div>
                    ) : null} */}
                        <span
                          className="listpagesearch_button"
                          style={{
                            top: "10px",
                            position: "absolute",
                            right: "10px",
                            pointerEvents: "none",
                          }}
                        >
                          <i className="fa fa-calendar"></i>
                        </span>
                      </form>
                    </div>
                  </div>
                </Row>
              </Col>
              <Col md={3}>{null}</Col>
              <Col md={3}>
                <div className="clearsearchclass">
                  <form
                    id="myForm"
                    className="d-flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submit(e);
                    }}
                  >
                    <input
                      className="form-control"
                      placeholder="Search.."
                      name="search"
                    />
                    <button className="btn listpagesearch_button" type="submit">
                      <i className="fa fa-search" style={{ color: "rgb(109, 114, 144)" }}></i>
                    </button>
                  </form>
                </div>
                <p className={classes.side}>
                  {searchdata ? (
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setpageno(0);
                        getQuotation(0);
                      }}
                    >
                      Clear Search
                    </a>
                  ) : null}
                </p>
              </Col>
            </Row>
          </div>
          <div
            className="newlisting_headertabdesign"
            style={{
              padding: "1.5rem",
              border: "1.5rem solid #e9ebf2",
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            <Row className="s_pagi">
              <Col md={6}>
                {allQuotation?.length ? (
                  <p className="s_para">
                    {countnumber * rows + 1} to{" "}
                    {countnumber * rows + numberofElements} of {totalUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="s_para">No Record Found</p>
                )}
              </Col>
              {/* <Col md={6}> */}
              {/* {permissionData?.includes(218) ? (
                  <a
                    className="btn btn-success"
                    onClick={openModal}
                    style={{ float: "right" }}
                  >
                    Add
                  </a>
                ) : null}
              </Col>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div style={{ marginTop: "-30px" }}>
                  {user?.isw_user_role === 1 || user?.isw_user_role === 6 ? (
                    <div className="form-group">
                      <label>
                        <strong>Branch:</strong>
                      </label>
                      <select
                        className={classes.selectdrop}
                        onChange={handleChangeOffice}
                        name="branch"
                      >
                        {user?.isw_user_role === 1 ||
                          user?.isw_user_role === 6 ? (
                          <option value="all">Show All</option>
                        ) : null}
                        {user?.isw_available_Office?.map((p, index) => (
                          <option key={index + 1} value={p.officeId}>
                            {p.officeName}
                            {" - "}
                            {p.officeCode}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="client_type"
                      value={radioattachmentvalue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="client"
                        control={<Radio color="primary" />}
                        label="Client"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="lead"
                        control={<Radio color="primary" />}
                        label="Lead"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                  {radioattachmentvalue === "client" ? (
                    <>
                      <h6 style={{ fontWeight: "bolder" }}>Client</h6>
                      <Select
                        // cacheOptions
                        name="clientName"
                        options={loadoptions}
                        defaultOptions={defaultloadoptions}
                        onInputChange={handleInputChange}
                        onChange={onselect}
                      />
                    </>
                  ) : (
                    <>
                      {radioattachmentvalue === "lead" ? (
                        <>
                          <h6 style={{ fontWeight: "bolder" }}>Lead</h6>
                          <Select
                            // cacheOptions
                            name="clientName"
                            options={loadoptions2}
                            onInputChange={handleInputChange2}
                            onChange={onselect2}
                          />
                        </>
                      ) : null}
                    </>
                  )}

                  <Row
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {!checkclient && (
                      <p className="errormessage">
                        Client or Temp client is required
                      </p>
                    )}
                  </Row>
                  <Row>
                    <Col md={6} style={{ marginTop: "30px" }}>
                      {null}
                    </Col>
                    <Col md={3} className="d-flex justify-content-end mt-1">
                      <Button color="secondary" onClick={() => closeModal()}>
                        Cancel
                      </Button>
                    </Col>
                    <Col md={3} className="d-flex justify-content-end mt-1">
                      <button
                        className="btn listing_addbutton"
                        onClick={createQuotation}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </div>
              </Modal> */}
            </Row>
            <div className="above-scroller" onScroll={scrolldiv}>
              <div className="scroller"></div>
            </div>
            <div className="displaytablerow">
              {/* <Table responsive striped onScroll={scrolltable}> */}
              <Table
                className="workload card-table table-borderless"
                responsive
                striped
                onScroll={scrolltable}
              >
                <thead className="s_sticky">
                  <tr>
                    <th style={{ minWidth: "80px", textAlign: "left" }}>
                      SN
                    </th>
                    <th style={{ minWidth: "100px" }}>
                      QN
                      <img
                        src={sort}
                        className="sorting"
                        // className={display}
                        id="clientName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", height: "10px" }}
                      />
                    </th>
                    <th style={{ minWidth: "130px" }}>
                      CLIENT NAME{" "}
                      <img
                        src={sort}
                        className="sorting"
                        // className={display}
                        id="clientName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", height: "10px" }}
                      />
                    </th>
                    <th style={{ minWidth: "130px" }}>
                      EMAIL{" "}
                      <img
                        src={sort}
                        className="sorting"
                        // className={display}
                        id="clientUsername"
                        onClick={sortClient}
                        style={{ cursor: "pointer", height: "10px" }}
                      />
                    </th>
                    <th style={{ minWidth: "130px" }}>
                      DUE DATE{" "}
                      <img
                        src={sort}
                        className="sorting"
                        // className={display}
                        id="quotationDueDate"
                        onClick={sortClient}
                        style={{ cursor: "pointer", height: "10px" }}
                      />
                    </th>
                    <th style={{ textAlign: "left", minWidth: "100px" }}>
                      TOTAL AMOUNT{" "}
                      <img
                        src={sort}
                        className="sorting"
                        // className={display}
                        id="totalAmount"
                        onClick={sortClient}
                        style={{ cursor: "pointer", height: "10px" }}
                      />
                    </th>
                    {check4pointpermission(
                      1203,
                      1204,
                      permissionData,
                      rolepermission
                    ) ? (
                      <th style={{ minWidth: "400px" }}>ACTION</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {allQuotation?.map((p, index) => (
                    <tr key={index}>
                      <td style={{ minWidth: "80px", textAlign: "left" }}>
                        {index + 1}
                      </td>
                      <td style={{ minWidth: "80px" }}>
                        {p.quotationNumber}
                      </td>
                      <td>{p.clientName}</td>
                      <td>{p.clientUsername}</td>
                      <td>
                        {moment(p.quotationDueDate).format("DD-MMM-YY")}
                        {/* <br />
                        {moment(p.quotationDueDate).format("hh.mm a")} */}
                      </td>
                      <td style={{ textAlign: "left", paddingRight: "36px" }}>
                        {p.totalAmount}
                      </td>
                      {check4pointpermission(
                        1203,
                        1204,
                        permissionData,
                        rolepermission
                      ) ? (
                        <td style={{ display: "flex" }}>
                          {/* <Link id={`receipt(${p.id})`} onClick={printIframe}>
                            Print
                          </Link> */}
                          {check1pointpermission(
                            1203,
                            permissionData,
                            rolepermission
                          ) ? (
                            <>
                              <button
                                className="role-deletebtn"
                                style={{ marginRight: "10px" }}
                                id={p.id}
                                onClick={editQuotation}
                                href={`#/edit-quotation/` + p.id}
                              >
                                Edit
                              </button>
                              {/* &nbsp;|&nbsp; */}
                            </>
                          ) : null}
                          {check1pointpermission(
                            1204,
                            permissionData,
                            rolepermission
                          ) ? (
                            <>
                              <button
                                className="role-deletebtn"
                                id={p.id}
                                onClick={() => {
                                  setdeletequotation(p.id);
                                  setdeletemodal(true);
                                }}
                              >
                                Delete
                              </button>
                              <Modal
                                isOpen={deletemodal}
                                onRequestClose={() => setdeletemodal(false)}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <h4 style={{ marginTop: "10px" }}>
                                  Are you sure you want to delete current record?
                                </h4>
                                <div style={{
                                  display: "flex",
                                  marginTop: "20px",
                                  justifyContent: "flex-end",
                                  gap: "10px",
                                  marginBottom: "10px",
                                }}
                                >
                                  <button
                                    className="btn listing_addbutton ml-1"
                                    onClick={deleteQuotation}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="btn btn-secondary cancel_margin"
                                    onClick={() => setdeletemodal(false)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Modal>
                            </>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col md={6}>
                <div>
                  <form className="master">
                    <div style={{ marginTop: "-10px" }}>
                      <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                      <br />
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      {allQuotation?.length ? (
                        <div className="s_spanPage">
                          {countnumber * rows + 1} to{" "}
                          {countnumber * rows + numberofElements} of {totalUser}{" "}
                          records
                        </div>
                      ) : (
                        <div className="s_spanPage">No Record Found</div>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  {searchdata || datefilter ? (
                    <Pagination
                      page={paginationpageno}
                      count={getpageno}
                      onChange={handlesearch}
                      shape="rounded"
                    />
                  ) : (
                    <Pagination
                      page={paginationpageno}
                      count={getpageno}
                      onChange={handlepage}
                      shape="rounded"
                    />
                  )}{" "}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default QuotationList;
