import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, CardBody, Table } from "reactstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import Modal from "react-modal";
import PasswordStrengthBar from "react-password-strength-bar";
import Pagination from "@material-ui/lab/Pagination";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { selectUser, logout } from "../features/userSlice";
import { check4pointpermission, check1pointpermission } from "../Permission/permission";

const OfficeUserDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rows, setrows] = React.useState(20);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [user_lists, setUserList] = useState(null);
  const [selecteduser, setselecteduser] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deleteusername, setdeleteusername] = useState();
  const [inputtype, setinputtype] = useState("password");
  const [chkPassState, setchkPassState] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [backdrop, setbackdrop] = useState(false);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [paginationpageno, setpaginationpageno] = useState(1);
  const user = useSelector(selectUser);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdrop(true);
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [user_lists]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(608, permission_obj, resource_obj)) {
      getUser();
    } else {
      history.push("/errorPage");
    }
  };

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getUser = () => {

    if (localStorage.getItem("officeUserDetailsPageNo")) {
      let currentPage = localStorage.getItem("officeUserDetailsPageNo");
      setpageno(currentPage);
      setpaginationpageno(Number(currentPage) + 1);
      getallUserlist(currentPage);
    } else {
      setpageno(0);
      setpaginationpageno(1);
      getallUserlist(0);
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getallUserlist(value - 1);
    setpaginationpageno(value);
  };
  const getallUserlist = (value1) => {
    axios
      .get(`${base_url.api2}/getUsersByOfficeIdPagination`, {
        headers: {
          Authorization: jwtToken,
          officeId: params.branch_id,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setUserList(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdrop(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          setbackdrop(false);
          handle403error(error);
        }
      );
  };
  const createofficeUsers = () => {
    history.push("/create-office-users/" + params.branch_id);
  };
  const goback = () => {
    history.push("/office");
  };
  const editUser = (elem, p) => {
    localStorage.setItem("officeUserDetailsPageNo", pageno);
    var user_email = elem.target.id;
    history.push("/edit-office-user/" + params.branch_id + "/" + user_email);
  };

  const openModal2 = (elem) => {
    setselecteduser(elem.target.id);
    setIsOpen2(true);
  };

  const openModal = (elem, p) => {
    setdeleteusername(p.email);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
  };
  const changePassword = (e) => {
    let email = e.target[0].value;
    let password;
    password = chkPassState.trim();
    let data = {
      email,
      password,
    };
    axios
      .post(`${base_url.api2}/updatePasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);

          setIsOpen2(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const deleteUser = (elem) => {
    axios
      .delete(`${base_url.api2}/deleteUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: deleteusername,
        },
      })
      .then(
        (response) => {
          getallUserlist(pageno);
          setIsOpen(!modalIsOpen);
          toast.success(response.data.message);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <hr />
      <div>
        <Row>
          <Col md={6}>
            <h5>
              <strong>Branch:- {props.officeName}</strong>
            </h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            {check1pointpermission(
              609,
              permissionData,
              rolepermission
            ) ? (
              <button
                className="outline-button"
                onClick={createofficeUsers}
                style={{ marginRight: "10px" }}
              >
                ADD USER
              </button>
            ) : null}
            <button className="sent-button" onClick={goback}>
              List
            </button>
          </Col>
        </Row>
        <br />
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          <Table
            className=" workload card-table table-borderless"
            responsive striped onScroll={scrolltable}>
            <thead className="s_sticky">
              <tr>
                <th style={{ minWidth: "50px", textAlign: "center" }}>
                  SN
                </th>
                <th style={{ minWidth: "100px" }}>NAME</th>
                <th style={{ minWidth: "120px" }}>CONTACT</th>
                <th style={{ minWidth: "120px" }}>COUNTRY</th>
                <th style={{ minWidth: "50px" }}>ROLE</th>
                <th style={{ minWidth: "120px" }}>CREATED</th>
                <th style={{ minWidth: "135px" }}>LAST LOGIN</th>
                <th style={{ minWidth: "80px" }}>STATUS</th>
                <th style={{ minWidth: "250px" }}>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {user_lists?.map((p, index) => (
                <tr key={index + 1}>
                  <td style={{ minWidth: "50px", textAlign: "center" }}>
                    {index + 1}
                  </td>
                  <td>
                    {p.firstName + " " + p.lastName}
                    <br />
                    {p.email}
                  </td>
                  <td>{p.phone}</td>
                  <td>{p.country}</td>
                  <td>{p.roleCode}</td>
                  <td>
                    {p.addedOn
                      ? moment(p.addedOn.split(".")[0]).format(
                        "DD-MMM-YY"
                      )
                      : null}
                    <br />
                    {p.addedOn
                      ? moment(p.addedOn.split(".")[0]).format("hh.mm a")
                      : null}
                  </td>
                  <td>
                    {p.lastLogin ? (
                      moment(p.lastLogin.split(".")[0]).format(
                        "DD-MMM-YY"
                      )
                    ) : (
                      <span style={{ color: "brown" }}>
                        Never Logged In
                      </span>
                    )}
                    <br />
                    {p.lastLogin
                      ? moment(p.lastLogin.split(".")[0]).format("hh.mm a")
                      : null}
                  </td>
                  <td>{p.status == "1" ? "Active" : "Inactive"}</td>
                  <td>
                    {check1pointpermission(
                      610,
                      permissionData,
                      rolepermission
                    ) ? (
                      <a
                        id={p.username}
                        onClick={(e) => {
                          editUser(e, p);
                        }}
                        href={
                          `#/edit-office-user/` +
                          params.branch_id +
                          `/` +
                          p.username
                        }
                      >
                        Edit
                      </a>
                    ) : null}
                    &nbsp;&nbsp;
                    {check1pointpermission(
                      611,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        {p.roleId != "1" ? (
                          <>
                            |&nbsp;&nbsp;
                            <Link id={p.username} onClick={openModal2}>
                              Chg-Pwd
                            </Link>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {check1pointpermission(
                      612,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        &nbsp;&nbsp;
                        {p.roleId != "1" ? (
                          <>
                            |&nbsp;&nbsp;
                            <Link
                              id={p.id}
                              onClick={(e) => {
                                openModal(e, p);
                              }}
                            >
                              Delete
                            </Link>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    <Modal
                      isOpen={modalIsOpen2}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          changePassword(e);
                        }}
                      >
                        <div>
                          <h3 style={{ color: "black" }}>
                            Update Password
                          </h3>
                          <div className="form-group creatadmin">
                            <Row>
                              <Col>
                                <label>User Name:</label>
                                <input
                                  type="text"
                                  className="form-control underline-input"
                                  value={selecteduser}
                                  readOnly
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="form-group creatadmin">
                            <Row>
                              <Col>
                                <label>Password:</label>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type={inputtype}
                                    className="form-control underline-input"
                                    value={chkPassState}
                                    onChange={(e) =>
                                      setchkPassState(e.target.value)
                                    }
                                    required
                                  />
                                  {inputtype === "password" ? (
                                    <i
                                      className="fa fa-eye-slash fonticonpositioning"
                                      onClick={() => setinputtype("text")}
                                      aria-hidden="true"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-eye fonticonpositioning"
                                      onClick={() =>
                                        setinputtype("password")
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div>
                                <PasswordStrengthBar
                                  password={chkPassState}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="form-group creatadmin">
                            <Row style={{ float: "right" }}>
                              <Button
                                style={{
                                  marginTop: "20px",
                                  marginRight: "10px",
                                }}
                                type="submit"
                                color="primary"
                              >
                                Update
                              </Button>
                              <Button
                                style={{
                                  marginTop: "20px",
                                  marginRight: "10px",
                                }}
                                color="secondary"
                                onClick={closeModal}
                              >
                                Cancel
                              </Button>
                            </Row>
                          </div>
                        </div>
                      </form>
                    </Modal>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <h4 style={{ marginTop: "10px" }}>
                        Are you sure you want to delete current record ?
                      </h4>
                      <Row
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          justifyContent: "flex-end",
                          gap: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <button
                          className="btn listing_addbutton ml-1"
                          onClick={deleteUser}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-secondary cancel_margin"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </Row>
                    </Modal>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <hr />
      <Row>
        <Col md={6}>
          {user_lists?.length ? (
            <p style={{ marginTop: "15px" }}>
              {countnumber * rows + 1} to{" "}
              {countnumber * rows + numberofElements} of {totalUser} records
            </p>
          ) : (
            <p className="formfooterrecordstyle">No Record Found</p>
          )}
        </Col>
        <Col md={6}>
          <div className={classes.root}>
            <Pagination
              page={paginationpageno}
              count={getpageno}
              onChange={handlepage}
              shape="rounded"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OfficeUserDetail;
