import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboard: "230px",
  },
  reducers: {
    addtodashboard: (state, action) => {
      state.dashboard = action.payload;
    },
  },
});

export const { addtodashboard } = dashboardSlice.actions;

export const selectdashboard = (state) => state.dashboard.dashboard;

export default dashboardSlice.reducer;
