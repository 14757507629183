import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer } from "react-toastify";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import MenuItem from "@material-ui/core/MenuItem";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { checkreportpermission, check4pointpermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";

function InsuranceProviderReport(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageNo, setPageNo] = useState(0);
  const [sortState, setSortState] = useState("name");
  const [direction, setDirection] = useState("DESC");
  const [rows, setRows] = React.useState(50);
  const [totalUser, setTotalUser] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberofElements, setNumberOfElements] = useState();
  // const datepast = moment().subtract(3, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const [backdropopen, setbackdropopen] = useState(true);
  const [pdfRow, setPdfRow] = useState([]);
  const [subAgentData, setSubAgentData] = useState();
  const [subAgentPageNo, setSubAgentPageNo] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const [display1, setDisplay] = useState("inline");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [subAgentData]);


  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();
  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    let headData = {
      Authorization: jwtToken,
      workflowType: "Insurance",
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    if (actionType === 'DOWNLOAD') {
      setbackdropopen(true);
      axios
        .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
          headers: headData,
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  }

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    let headData = {
      Authorization: jwtToken,
      workflowType: "Insurance",
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(rows),
    };
    if (check1pointpermission(1058, permission_obj, resource_obj)) {
      //if(available_permission) {
      getProviderWiseSummery(headData);
      dispatch(setdashboardcheck(73));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const handlePageLead = (event, value) => {
    setPageNo(value - 1);
    if (searchStatus) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortState,
        direction: direction,
        query: searchData,
        page: Number(value - 1),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortState,
        direction: direction,
        page: Number(value - 1),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    }
  };

  const handleRows = (event) => {
    setRows(event.target.value);
    if (searchStatus) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortState,
        direction: direction,
        query: searchData,
        page: Number(0),
        size: Number(event.target.value),
      };
      getProviderWiseSummery(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(event.target.value),
      };
      getProviderWiseSummery(headData);
    }
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();
  const getProviderWiseSummery = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        headData.size = response.data.data.totalElements;
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          setpdflength(50);
        } else {
          setpdflength(50);
        }
        getPdfData(headData.size = Number(50));
        setPdfHeader(headData);
        setSubAgentData(response.data.data.content);
        setSubAgentPageNo(response.data.data.totalPages);
        setTotalUser(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        // setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        setSubAgentData();
        setSubAgentPageNo(0);
        setTotalUser(0);
        setCountNumber(0);
        setNumberOfElements(0);
        if (err) {
          if (err.response) {
            if (Number(err?.response?.status) === 403) {
              setTimeout(() => {
                dispatch(logout());
                localStorage.clear();
                localStorage.setItem("from_403_status", "call_toast");
                history.push("/");
              }, 1000);
            }
          }
        }
      });
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        setPdfRow();
        if (err) {
          if (err.response) {
            if (Number(err?.response?.status) === 403) {
              setTimeout(() => {
                dispatch(logout());
                localStorage.clear();
                localStorage.setItem("from_403_status", "call_toast");
                history.push("/");
              }, 1000);
            }
          }
        }
      });
  };

  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState();
  const searchSubAgentWiseSummery = (e) => {
    setDisplay("none");
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setPageNo(0);
    if (srchdata != "") {
      setSearchStatus(true);
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortState,
        direction: direction,
        query: srchdata,
        page: Number(0),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    } else {
      setSearchStatus(false);
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    }
  };

  const exportPdfLead = () => {
    let headData = {
      Authorization: jwtToken,
      workflowType: "Insurance",
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getProviderWorkflowSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = ["Name", "Percentage", "Count"];
        var rows = [];
        pdfRow?.forEach((element) => {
          var temp = [element.name, element.percentage, element.totalSearchCount];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("InsuranceProviderReport.pdf");
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        setPdfRow();
        if (err) {
          if (err.response) {
            if (Number(err?.response?.status) === 403) {
              setTimeout(() => {
                dispatch(logout());
                localStorage.clear();
                localStorage.setItem("from_403_status", "call_toast");
                history.push("/");
              }, 1000);
            }
          }
        }
      });
  };

  const sortLeadData = (e) => {
    let sortFilter = e.target.id.trim();
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }
    if (searchStatus) {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortFilter,
        direction: direction,
        query: searchData,
        page: Number(pageNo),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    } else {
      let headData = {
        Authorization: jwtToken,
        workflowType: "Insurance",
        columnName: sortFilter,
        direction: direction,
        page: Number(pageNo),
        size: Number(rows),
      };
      getProviderWiseSummery(headData);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      {/* <CardBody> */}
      {/* <CardSubtitle className="font-weight-bold"></CardSubtitle> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Insurance Provider Summary</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Reports</a><span className="s_span ">-</span>
          <a class="b-link">Insurance</a><span className="s_span ">-</span>
          <a href="#/insurence-provider-summery-report" class="b-link active">
            Insurance provider summary
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
      </div>
      {/* <div>
        <Row> */}
      {/* <Col md={6}>
            <CardText className={classes.headtext}>
              <strong>Insurance Provider Summery Report</strong>
            </CardText>
          </Col> */}
      {/* <Col md={6}>
            <Row>
              <Col md={3}></Col>
              <Col md={3}></Col>
              <Col md={3}>
                {permissionData?.includes(648) ?
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfLead}
                  >
                    Export PDF
                  </button>
                  : null}
              </Col>
              <Col md={3}>
                {subAgentData != null ?
                  <>
                    {permissionData?.includes(649) ?
                      <CSVLink
                        filename="InsuranceProviderReport.csv"
                        data={subAgentData}
                        className="btn listing_addbutton"
                      // target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                      : null}
                  </>
                  : null}
              </Col>
            </Row>
          </Col> */}
      {/* </Row> */}
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          borderRight: "1.5rem solid #e9ebf2",
          borderLeft: "1.5rem solid #e9ebf2",
          borderTop: "1.5rem solid #e9ebf2",
          marginBottom: "0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <form className="master">
              <div style={{ marginTop: "-10px" }}>
                <label className="area_rowslable_my" style={{ fontSize: "14px" }}>Limit:</label>
                <br />
                <FormControl className={classes.formControl}>
                  <select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rows}
                    onChange={handleRows}
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9", width: "100px" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </FormControl>
              </div>
            </form>
          </div>
          <div style={{ width: "329px" }}>
            <div className="clearsearchclass" style={{ marginTop: "20px" }}>
              <form
                className="d-flex"
                id="myForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  searchSubAgentWiseSummery(e);
                }}
              >
                <input
                  className="form-control"
                  placeholder="Search.."
                  name="search"
                />
                <button className="btn listpagesearch_button" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
            {searchStatus ? (
              <a
                className={classes.clrsrch2}
                onClick={() => {
                  setDisplay("inline");
                  document.getElementById("myForm").reset();
                  setSearchStatus(false);
                  setPageNo(0);
                  let headData = {
                    Authorization: jwtToken,
                    workflowType: "Insurance",
                    columnName: sortState,
                    direction: direction,
                    page: Number(0),
                    size: Number(rows),
                  };
                  getProviderWiseSummery(headData);
                }}
              >
                Clear Search
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          border: "1.5rem solid #e9ebf2",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <Row className="s_pagi" style={{ marginTop: "5px" }}>
          <Col md={6}>
            {subAgentData?.length ? (
              <p className="s_para" style={{ marginTop: "5px" }}>
                {countNumber * rows + 1} to{" "}
                {countNumber * rows + numberofElements} of {totalUser} records
              </p>
            ) : (
              <p className="s_para" style={{ marginTop: "5px" }}>No Record Found</p>
            )}
          </Col>
          <Col md={6}>
            <div style={{ display: "flex", justifyContent: "right" }}>
              {check1pointpermission(
                1059,
                permissionData,
                rolepermission
              ) ? (
                <button
                  className="btn listing_addbutton"
                  onClick={exportPdfLead}
                >
                  Export PDF
                </button>
              ) : null}
              <>
                {/* {pdfRow != null && pdfRow.length != 0 ? (
                  <>
                    {permissionData?.includes(199) ? (
                      <CSVLink
                        filename="InsuranceProviderReport.csv"
                        data={pdfRow ? pdfRow : null}
                        className="btn listing_addbutton"
                        // target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    ) : null}
                  </>
                ) : null} */}
                {dataForDownload != null ? (
                  <>
                    {check1pointpermission(
                      1059,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')}>Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="InsuranceProviderReport.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            </div>
          </Col>
        </Row>
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          {/* <Table responsive striped onScroll={scrolltable}> */}
          <Table
            className="workload card-table table-borderless"
            responsive
            striped
            onScroll={scrolltable}
          >
            <thead className="s_sticky">
              <tr>
                {/* <th>Id</th> */}
                <th style={{ width: "50px", textAlign: "center" }}>SN </th>
                <th style={{ width: "250px", textAlign: "center" }}>
                  NAME {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="name"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ width: "250px", textAlign: "center" }}>
                  PERCENTAGE {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="percentage"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ width: "250px", textAlign: "center" }}>
                  COUNT {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="totalSearchCount"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {subAgentData != null
                ? subAgentData &&
                subAgentData.map((p, index) => (
                  <tr key={p.id}>
                    {/* <td>{p.id}</td> */}
                    <td style={{ width: "50px", textAlign: "center" }}>
                      {countNumber * rows + 1 + index}
                    </td>
                    <td style={{ textAlign: "center" }}>{p.name}</td>
                    <td style={{ textAlign: "center" }}>{p.percentage}</td>
                    <td style={{ textAlign: "center" }}>{p.totalSearchCount}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {subAgentData?.length ? (
                      <div className="s_spanPage">
                        {countNumber * rows + 1} to{" "}
                        {countNumber * rows + numberofElements} of {totalUser} records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  count={subAgentPageNo}
                  onChange={handlePageLead}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </CardBody> */}
      {/* </Card> */}
    </div>
    // </div>
  );
}

export default withRouter(InsuranceProviderReport);