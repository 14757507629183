import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useForm } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import DeleteModal from "../../Modal/Masters/DeleteModal";
import EditLocationModal from "../../Modal/Masters/EditLocationModal";

function LocationList(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  // console.log(alladmin);
  const history = useHistory();
  const [country1, setcountry] = useState();
  const [backdropopen, setbackdropopen] = useState(false);
  const jwtToken = "Bearer " + user.isw_user_token;
  const username = user.isw_user_email;
  const { register, handleSubmit, errors } = useForm();
  const [deleteuser, setDeleteuser] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("locationId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allLocations, setAllLocations] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allLocations]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
  

    if (check4pointpermission(1543, 1547, permission_obj, resource_obj)) {
      //if (available_permission) {
      getalllocation(pageno);
      dispatch(setdashboardcheck(34));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const selectCountry = (val) => {
    setcountry(val);
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getalllocation(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const [searchdata, setsearch] = useState();
  const getalllocation = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getAllLocations`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setAllLocations(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          setAllLocations(null);
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api1}/getAllLocations`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setAllLocations(response.data.data.content);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          setAllLocations(null);
          handle403error(error);
        }
      );
  };

  const deleteCurrentUser = (id, name) => {
    axios
      .delete(`${base_url.api1}/deleteLocationById`, {
        headers: {
          Authorization: jwtToken,
          id,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);

          let log = isw_user_name + " Deleted Location " + name + ".";
          callsaveauditapi(log);
          getalllocation(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [stateCust, setstateCust] = useState();
  const editUser = (elem) => {
    var location = elem.target.id;
    axios
      .get(`${base_url.api1}/getLocationById`, {
        headers: {
          Authorization: jwtToken,
          locationId: location,
        },
      })
      .then(
        (response) => {
          setstateCust(response.data.data);
          setcountry(response.data.data.country);
          toast.success(response.data.message);
        },
        (error) => {
          handle403error(error);
        }
      );
    setIsOpen3(true);
    // var locationId = elem.target.id;
    // history.push("/edit-location/" + locationId);
  };
  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value.trim(),
      };
    });
  };

  const putCustomer = (e) => {
    stateCust.country = country1;
    console.log(stateCust);
    axios
      .post(`${base_url.api1}/updateLocationById`, stateCust, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          toast.success("Success");

          let log =
            isw_user_name +
            " Updated Location " +
            response.data.data.name +
            ".";
          callsaveauditapi(log);
          setIsOpen3(false);
          getalllocation(pageno);
        },
        (error) => {
          handle403error(error);
        }
      );
  };
  const [selecteduser, setselecteduser] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = () => {
    setcountry();
    setIsOpen2(true);
  };

  // Create location

  const createLocation = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let name = data2.name;
    let latitude = data2.latitude;
    let longitude = data2.longitude;
    let status = Number(data2.status);
    let country = country1;
    let locationId = 0;
    let data = {
      name,
      latitude,
      longitude,
      status,
      country,
      locationId,
    };
    console.log(data);
    axios
      .post(`${base_url.api1}/saveLocation`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        getalllocation(pageno);
        console.log(response.data.data);

        let log =
          isw_user_name + " Created Location " + response.data.data.name + ".";
        callsaveauditapi(log);
        setIsOpen2(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const closeModal = () => {
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const submitrows = (e) => {
    getalllocation(0);
  };

  // Change Status of location

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        locationId: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api1}/updateLocationById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getalllocation(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        locationId: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api1}/updateLocationById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getalllocation(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };

  // Search Data by name

  const [searchinput, setsearchinput] = useState();
  const submitsearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api1}/searchLocationByName`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: "name",
          direction: "DESC",
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setsearch(true);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setAllLocations(res.data.data.content);
        setbackdropopen(false);
        // document.getElementById("myForm").reset();
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
        setAllLocations();
      });
  };

  const searchbystatus = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const value = optionElement.getAttribute("value");
    if (value === "all") {
      getalllocation(pageno);
    } else {
      axios
        .get(`${base_url.api1}/getLocationByStatus`, {
          headers: {
            Authorization: jwtToken,
            status: Number(value),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setAllLocations(response.data.data.content);
          setsearch(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Location</h3>
          <a href="#/" class="b-link">Home</a>
          -
          <a class="b-link">Settings</a>
          -
          <a class="b-link active">Manage Location</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1544, permissionData, rolepermission) ? (
          <a className="sent-button" onClick={openModal2}>
            {/* <AddIcon className="listing_addbutton_i" /> */}
            Add
          </a>
        ) : null}
        <Modal
          isOpen={modalIsOpen2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>
            <strong>Location</strong>
          </h2>
          <form onSubmit={handleSubmit(createLocation)}>
            <div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={6}>
                    <label>
                      Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="name"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <p className="errormessage">Name is Required</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <label>
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      value={country1}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <label>
                      Latitude<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="number"
                      className="form-control underline-input"
                      name="latitude"
                      ref={register({ required: true })}
                    />
                    {errors.latitude && (
                      <p className="errormessage">Latitude is Required</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <label>
                      Longitude<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="number"
                      className="form-control underline-input"
                      name="longitude"
                      ref={register({ required: true })}
                    />
                    {errors.longitude && (
                      <p className="errormessage">Longitude is Required</p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <label>
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      name="status"
                      ref={register({ required: true })}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Status is Required</p>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex justify-content-end align-items-end"
                  >
                    <Row className="justify-content-end mt-2">
                      <Button
                        color="secondary"
                        onClick={() => setIsOpen2(false)}
                      >
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        className="btn listing_addbutton ml-1"
                      >
                        Submit
                      </button>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </Modal>
      </div>

      <div>
        <Row></Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              <Row>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <div style={{ marginTop: "-10px",marginRight:"20px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className="s_client_limit"
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
                <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
                  <label className="area_rowslable">Status</label>

                  <select
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={searchbystatus}
                  >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </Row>
            </Col>
            <Col md={3}>{null}</Col>
            <Col md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitsearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getalllocation(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              {allLocations?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
          </Row>
          {/* <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div> */}
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
             <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th>
                    LOCATION NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ textAlign: "center", width: "175px" }}>
                    STATUS
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                    />
                  </th>
                  {check4pointpermission(
                    1545,
                    1546,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ textAlign: "center", width: "175px" }}>
                      ACTION
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allLocations != null ? (
                  allLocations &&
                  allLocations?.map((p, index) => (
                    <tr key={p.id}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td>{p.name}</td>
                      <td style={{ textAlign: "center", width: "175px" }}>
                      {check1pointpermission(
                          1547,
                          permissionData,
                          rolepermission
                        ) ? (
                          <Link
                            id={p.locationId}
                            onClick={(e) => changeStatus(e, p)}
                          >
                            {p.status == 1 ? "Active" : "Inactive"}
                          </Link>
                        ) : (
                          <>{p.status == 1 ? "Active" : "Inactive"}</>
                        )}
                      </td>
                      {check4pointpermission(
                          1545,
                          1546,
                          permissionData,
                          rolepermission
                        ) ? (
                        <td
                          id={p.locationId}
                          style={{ textAlign: "center", width: "175px" }}
                        >
                        {check1pointpermission(
                            1545,
                            permissionData,
                            rolepermission
                          ) ? (
                            // <Link id={p.locationId} onClick={editUser}>
                            //   Edit
                            // </Link>
                            <EditLocationModal
                            inputEvent={inputEvent}
                            toast={toast}
                            handle403error={handle403error}
                            pageno={pageno}
                            jwtToken={jwtToken}
                            isw_user_name={isw_user_name}
                            stateCust={stateCust}
                            setstateCust={setstateCust}
                            country1={country1}
                            p={p}
                            getalllocation={getalllocation}
                            selectCountry={selectCountry}
                           />
                          ) : null}



                          &nbsp;|&nbsp;
                          {check1pointpermission(
                            1546,
                            permissionData,
                            rolepermission
                          ) ? (
                            <DeleteModal
                              deleteCurrentUser={deleteCurrentUser}
                              id={p.locationId}
                              name={p.name}
                            />
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <Row>
              <Col md={6}>
                 <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable"></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allLocations?.length ? (
                      <div
                        className="s_spanPage"
                      >
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser} records
                      </div>
                    ) : (
                      <span className="s_spanPage">No Record Found
                      </span>
                    )}
                  </div>
                </form>
              </div>
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  <Pagination
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(LocationList);