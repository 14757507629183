import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";

const EditAdminConfig = ({
  configkey,
  toast,
  handle403error,
  getallConfig,
  pageno,
  selectedIswOnlineConsultantData,
  onlineUsers,
  jwtToken,
  isw_user_name,
}) => {
  const [editmodal, seteditmodal] = useState(false);
  const [consultantmodal, setconsultantmodal] = useState(false);
  const [selectedIswOnlineConsultant, setSelectedIswOnlineConsultant] =
    useState(false);
  const [stateCust, setstateCust] = useState();
  const [
    selectedIswOnlineConsultantData2,
    setSelectedIswOnlineConsultantData2,
  ] = useState();
  const [setConfig, setConfigValue] = useState();
  const [consultantState, setConsultantState] = useState();
  const [consultantLabel, setConsultantLabel] = useState();
  const [selectConfiguser, setselectConfiguser] = useState();

  const selectuserbybranch = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    stateCust.configValue = optionElementValue;

    setselectConfiguser(optionElementValue);
  };
  const onSelect = (selectedList, selectedItem) => {
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i].value;
      consultant_obj.push(data);
    }

    const consultant_Label = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i].label;
      consultant_Label.push(data);
    }

    const consultant_All = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i];
      consultant_All.push({
        value: data.value,
        label: data.label,
      });
    }

    setConsultantLabel(consultant_Label);
    setConsultantState(consultant_obj);
    setselectConfiguser(consultant_obj);
    setSelectedIswOnlineConsultantData2(consultant_All);
  };

  const closeeditModal = () => {
    seteditmodal(false);
  };
  const closeconsultantModal = () => {
    setconsultantmodal(false);
  };
  const openeditmodal = () => {
    if (configkey == "iswonline_consultant") {
      setSelectedIswOnlineConsultant(true);
      axios
        .get(`${base_url.api3}/getByConfigKey`, {
          headers: {
            configKey: configkey,
          },
        })
        .then(
          (response) => {
            let data = response.data.data.configFullValue?.split(",");
            let data2 = response.data.data.configValue?.split(",");
            let data3 = [];

            for (let i = 0; i < data.length; i++) {
              data3.push({
                value: data2[i],
                label: data[i],
              });
            }
            setSelectedIswOnlineConsultantData2(data3);
            setstateCust(response.data.data);
          },
          (error) => {
            handle403error(error);
          }
        );
      setconsultantmodal(true);
    } else {
      axios
        .get(`${base_url.api3}/getByConfigKey`, {
          headers: {
            configKey: configkey,
          },
        })
        .then(
          (response) => {
            setstateCust(response.data.data);
          },
          (error) => {
            handle403error(error);
          }
        );
      seteditmodal(true);
    }
  };

  const putConfig = (e) => {
    stateCust.configValue = setConfig;
    if (configkey == "iswonline_consultant") {
      if (selectConfiguser != undefined) {
        stateCust.configValue = consultantState.toString();
        stateCust.configFullValue = consultantLabel.toString();
        axios
          .put(`${base_url.api3}/updateConfigById`, stateCust, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              toast.success("Success");

              let log =
                isw_user_name +
                " Updated Admin Config key iswonline consultant" +
                response.data.data.configKey +
                ".";
              callsaveauditapi(log);
              closeconsultantModal();
              getallConfig(pageno);
            },
            (error) => {
              handle403error(error);
            }
          );
      }
    } else {
      axios
        .put(`${base_url.api3}/updateConfigById`, stateCust, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            toast.success("Success");

            let log =
              isw_user_name +
              " Updated Admin Config key " +
              response.data.data.configKey +
              ".";
            callsaveauditapi(log);
            closeeditModal();
            getallConfig(pageno);
          },
          (error) => {
            handle403error(error);
          }
        );
    }
  };
  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
        Edit
      </span>
      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>
          <strong>Config</strong>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putConfig(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Config Key
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configKey}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configName}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Value
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <TextareaAutosize
                    rowsMax={6}
                    style={{ height: "100px",backgroundColor:"#f5f5f9" }}
                    className="form-control underline-input"
                    defaultValue={stateCust?.configValue}
                    onChange={(e) => {
                      setConfigValue(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Row className="justify-content-end">
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={consultantmodal}
        onRequestClose={closeconsultantModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>
          <strong>Config</strong>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putConfig(e);
          }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>
                    Config Key
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configKey}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    value={stateCust?.configName}
                    className="form-control underline-input"
                    readOnly
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <label>
                    Key Value
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />
                  {selectedIswOnlineConsultant ? (
                    <Select
                      isMulti
                      name="iswonline_consultant"
                      value={
                        selectedIswOnlineConsultantData2
                          ? selectedIswOnlineConsultantData2
                          : null
                      }
                      options={selectedIswOnlineConsultantData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={onSelect}
                      required
                    />
                  ) : (
                    <select
                      style={{
                        width: "100%",
                        height: "35px",
                        // paddingLeft: "13px",
                      }}
                      value={stateCust?.configValue}
                      onChange={(e) => {
                        selectuserbybranch(e);
                      }}
                      required
                    >
                      <option value="">-- Select Config User --</option>
                      {onlineUsers?.map((p, index) => (
                        <option
                          key={index}
                          id={p.id}
                          value={p.email}
                          username={p.firstName + " " + p.lastName}
                        >
                          {p.firstName +
                            " " +
                            p.lastName +
                            " (" +
                            p.email +
                            ")"}
                        </option>
                      ))}
                    </select>
                  )}
                </Col>
              </Row>
              <Row className="justify-content-end mt-2" style={{marginLeft:"180px",marginTop:"20px"}}>
                
                  <Button color="secondary" onClick={closeconsultantModal} >
                    Cancel
                  </Button>
                  <button
                    className="btn listing_addbutton ml-1"
                    type="submit"
                    style={{ float: "left" }}
                  >
                    Submit
                  </button>
                
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditAdminConfig;
