import { createSlice } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    client:[],
  },
  reducers: {
    getClient:(state,action)=>{
      state.client=action.payload
    },
    
    addtoClient:(state,action)=>{
      state.client=[...state.client,action.payload]
    },
    removeClient:(state,action)=>{
      const index=state.client.findIndex(
        (clientUser)=>clientUser.username===action.payload.id 
      )
      let newClient=[...state.client]
      if(index>=0){
        newClient.splice(index,1)
      }
      else{
        console.warn('Cant remove')
      }
      return{
        ...state,
        client:newClient
      }
    },
    updateClient:(state,action)=>{
      const index=state.client.findIndex(
        (clientUser)=>clientUser.username===action.payload.id 
      )
      let newClient=[...state.client]
      if(index>=0){
        newClient.splice(index,1)
        newClient.splice(index,0,action.payload.data)
      }
      else{
        console.warn('Cant remove')
      }
      // newAdmin.push(action.payload.data)
      return{
        ...state,
        client:newClient
      }

      
  
    }
    

    
    
  },
});

export const { getClient,addtoClient,removeClient,updateClient } = clientSlice.actions;

export const selectClient = state => state.client.client;

export default clientSlice.reducer;
