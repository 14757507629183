import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;
const dashboarddrawerWidth = 220;

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  commentBox: {
    background: "#f2f2f2",
    padding: "20px",
  },
  dropdownbtn2: {
    background: "white",
    color: "black",
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  selectlimit: {
    minWidth: "65px",
    marginRight: "15px",
    textAlign: "center",
  },
  s_mail_limit: {
    minWidth: "113px",
    marginRight: "15px",
    textAlign: "start",
  },
  headtext: {
    fontSize: headtxt,
    color: "#2a4184",
  },
  viewheadtext: {
    fontSize: "10",
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  enquirycreateBtn: {
    color: "white",
    textDecoration: "none",
    marginLeft: 20,
    float: "left",
  },
  createBtn: {
    float: "right",
  },
  agentcreateBtn: {
    color: "white",
    textDecoration: "none",
    marginLeft: 20,
  },
  modalcss: {
    marginTop: "10%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  srch: {
    float: "right",
  },
  workloadsrch: {
    float: "right",
    marginTop: "22px",
  },
  srchbtn: {
    padding: "1px 10px",
    marginTop: "8px",
    background: "#ddd",
    fontSize: "17px",
    cursor: "pointer",
  },
  clickhere: {
    color: "blue",
    cursor: "pointer",
  },
  clrsrch: {
    float: "right",
    marginTop: "5px",
    marginRight: "15px",
    cursor: "pointer",
  },
  root: {
    float: "right",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: "100px",
    height: "84px",
    borderRadius: "0px",
  },
  agentroot: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "120px !important",
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "80px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  side: {
    float: "right",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: dashboarddrawerWidth,
    width: `calc(100% - ${dashboarddrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: dashboarddrawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: dashboarddrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7.5) + 1,
    },
  },
  toolbar3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbar2: {
    // padding: theme.spacing(0, 1),
    minHeight: "35px",
    // ...theme.mixins.toolbar,
  },
  searchbox: {
    padding: "2px",
    borderTop: "2px solid black",
    borderRight: "2px solid black",
    borderRadius: "5px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  root1: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: "54px",
  },
  nested2: {
    paddingLeft: theme.spacing(7),
  },
  table: {
    minWidth: 650,
  },
}));

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};



export const mycustomStyles = {
  content: {
    top: "55%",
    left: "70%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "580px",
    maxHeight: "750px",
    transform: "translate(-50%, -50%)",
    padding: "25px 35px"
  },
};

export const addcustomStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "750px",
    maxHeight: "650px",
    transform: "translate(-50%, -50%)",
  },
};

export const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "500px",
    maxWidth: "900px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

// export const customFileStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     minWidth: "auto",
//     minHeight: "70%",
//     // maxHeight: "600px",
//     transform: "translate(-50%, -50%)",
//   },
// };
export const customFileStyles = {
  content: {
    margin: "auto",
    maxWidth: "60%",
    maxHeight: "70%",
  },
};

export const customStylesProviderBranch = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "450px",
    transform: "translate(-50%, -50%)",
  },
};

export const customStylesAttachment = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "800px",
    maxWidth: "800px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      'heading1',
      'heading2',
      'heading3',
      'heading4',
      'heading5',
      'heading6',
      "|",
      "bold",
      "italic",
      "horizontalLine",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "alignment",
      "|",
      "imageUpload",
      "imageInsert",
      "blockQuote",
      "fontBackgroundColor",
      "fontColor",
      "insertTable",
      "fontSize",
      "fontFamily",
      "code",
      "undo",
      "redo",
    ],
  },
  fontFamily: {
    options: [
      "default",
      "Ubuntu, Arial, sans-serif",
      "Ubuntu Mono, Courier New, Courier, monospace",
    ],
  },
  fontSize: {
    options: [9, 11, 13, "default", 17, 19, 21],
  },
  autoCorrect: false, // Add this line to disable auto-correction
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:full",
      "imageStyle:side",
      "linkImage",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  licenseKey: "",
  contentStyles: {
    // Set default styles for the editor content.
    'body': {
      'font-size': '6px', // Set your desired font size.
      'font-weight': 'normal', // Set your desired font weight.
    },
  },
  // heading: {
  //   options: [
  //     { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
  //     { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
  //     { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
  //     { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
  //     { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
  //     { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
  //     { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
  //   ]
  // },
  typing: {
    transformations: {
      remove: [
        // Do not use the transformations from the
        // 'symbols' and 'quotes' groups.
        'symbols',
        'quotes',

        // As well as the following transformations.
        'arrowLeft',
        'arrowRight',

        'ellipsis',    // Remove only 'ellipsis' from the 'typography' group.
        'mathematical', // Remove all transformations from the 'mathematical' group.
      ],

      extra: [
        // Add some custom transformations � e.g. for emojis.
        { from: ':)', to: '??' },
        { from: ':+1:', to: '??' },
        { from: ':tada:', to: '??' },

        // You can also define patterns using regular expressions.
        // Note: The pattern must end with `$` and all its fragments must be wrapped
        // with capturing groups.
        // The following rule replaces ` "foo"` with ` �foo�`.
        {
          from: /(^|\s)(")([^"]*)(")$/,
          to: [null, '�', null, '�']
        },

        // Finally, you can define `to` as a callback.
        // This (naive) rule will auto-capitalize the first word after a period, question mark, or an exclamation mark.
        {
          from: /([.?!] )([a-z])$/,
          to: matches => [null, matches[1].toUpperCase()]
        }
      ],
    }

  },
  removePlugins: [
    'Title',
  ]

};

export const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
