import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import "../css/ViewEnquiryWorkFlow.css";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AgentAgreementDetail from "./AgentAgreementDetail";
import { useStyles } from "../../Utilities/CSSUtilities";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import AgentPayableDetail from "./AgentPayableDetail";
import AgentAppliedDetail from "./AgentAppliedDetail";
import AgentRefferedDetail from "./AgentRefferedDetail";

import AgentPaidInvoiceDetail from "./AgentPaidInvoiceDetail";
import { getagentpermission, check1pointpermission } from "../Permission/permission";
import moment from "moment";
function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ViewAgent = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [stateCust, setstateCust] = useState({});
  const [backdrop, setbackdrop] = useState(false);
  const [transactionalarrangementlist, settransactionalarrangementlist] =
    useState();
  const [agentinvoice, setagentinvoice] = useState();
  const [agentpaidinvoice, setagentpaidinvoice] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [pagepaidno, setpaidpageno] = useState(0);
  const [pageagentno, setagentpageno] = useState(0);
  const [pagereferredno, setrefferedpageno] = useState(0);
  const [getpaidpageno, setgetpaidpageno] = useState();
  const user = useSelector(selectUser);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  let selected_AgId = localStorage.getItem('selected_agentId');

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    console.log("comming=---------->");
    if (check1pointpermission(729, permission_obj, resource_obj)) {

      setbackdrop(true);
      getAgentByUsername();
      getInvoicebyUsername(pageno);
      getInvoicepaidbyUsername(pagepaidno);
      getApplied(pageagentno);
      getReffered(pagereferredno);
    } else {
      history.push("/errorPage");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getAgentByUsername = () => {
    axios
      .get(`${base_url.api3}/getAgentByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.agent_email,
        },
      })
      .then((res) => {
        setstateCust(res.data.data);
        settransactionalarrangementlist(res.data.data.transactionalAgreement);
        setbackdrop(false);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const getInvoicebyUsername = (value) => {
    axios
      .get(`${base_url.api2}/getInvoiceByAgentUsername`, {
        headers: {
          Authorization: jwtToken,
          agentUsername: params.agent_email,
          columnName: "invoiceCreatedDate",
          direction: "DESC",
          page: value,
          size: 20,
        },
      })
      .then((res) => {
        setagentinvoice(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const getInvoicepaidbyUsername = (value) => {
    axios
      .get(`${base_url.api2}/getPaidInvoiceByAgentUsername`, {
        headers: {
          Authorization: jwtToken,
          agentUsername: params.agent_email,
          columnName: "invoiceCreatedDate",
          invoiceStatus: "Paid",
          direction: "DESC",
          page: value,
          size: 20,
        },
      })
      .then((res) => {
        setagentpaidinvoice(res.data.data.content);
        setgetpaidpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getInvoicebyUsername(value - 1);
  };
  const handlepaidpage = (event, value) => {
    setpaidpageno(value - 1);
    getInvoicepaidbyUsername(value - 1);
  };

  const handleappliedpage = (event, value) => {
    setagentpageno(value - 1);
    getApplied(value - 1);
  };

  const handlerefferedpage = (event, value) => {
    setrefferedpageno(value - 1);
    getReffered(value - 1);
  };

  const editAgent = () => {
    history.push("/edit-agent/" + params.agent_id + "/" + params.agent_email);

  };

  const [agentApplied, setagentApplied] = useState();
  const [getagentpageno, setgetagentpageno] = useState();
  const getApplied = (value) => {
    axios
      .get(`${base_url.api2}/getInvoiceByAgentUsernameForRA`, {
        headers: {
          agentId: params.agent_id,
          Authorization: jwtToken,
          clientStatus: 1,
          columnName: "id",
          direction: "DESC",
          enquiryType: "applied",
          page: value,
          size: 20,
        },
      })
      .then((res) => {
        setagentApplied(res.data.data.content);
        setgetagentpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };

  const [agentReffered, setagentReffered] = useState();
  const [getrefferedpageno, setgetrefferedpageno] = useState();

  const getReffered = (value) => {
    axios
      .get(`${base_url.api2}/getInvoiceByAgentUsernameForRA`, {
        headers: {
          agentId: params.agent_id,
          Authorization: jwtToken,
          clientStatus: 1,
          columnName: "id",
          direction: "DESC",
          enquiryType: "referred",
          page: value,
          size: 20,
        },
      })
      .then((res) => {
        setagentReffered(res.data.data.content);
        setgetrefferedpageno(res.data.data.totalPages);
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            // toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <>
      <div class="sp lead-page" style={{ marginTop: "50px" }}>
        <div class="page-head d-flex justify-content-between p-4" style={{ marginTop: "5rem" }}>
          <ul class="breadcrums">
            <h3 class="page-title bold">Agent</h3>
            <a href="#/" class="b-link">Home</a>
            -
            <a class="b-link">Partner</a>
            -
            <a class="b-link active">Agent Dashboard</a>
          </ul>
          {/* <a
          className="sent-button"
          onClick={goback}
          style={{ float: "right" }}
        >
          List
        </a> */}
        </div>
        <div class="page-card_container p-4">
          <div class="content-wrapper">
            <div class="card client-profile" id="cardClient">
              <div class="minimize-profile">
                <span onClick={() => {
                  let card = document.getElementById("cardClient");
                  card.style.display = "none";
                }}> &#171; </span>
              </div>
              <div class="options">
                <input type="checkbox" id="option-checkbox" class="checkbox" />
                <label class="icon" for="option-checkbox">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="6" fill="white" />
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <div class="options-content">
                  <ul>
                    <>
                      {check1pointpermission(
                        720,
                        permissionData,
                        rolepermission
                      ) ? (
                        <li>
                          <a
                            onClick={editAgent}
                          // href={`#/client-view/` + params.client_name}
                          >
                            Edit
                          </a>
                        </li>
                      ) : null}
                    </>
                    {/* <li>
                    <a href="#">Option 2</a>
                  </li>
                  <li>
                    <a href="#">Option 3</a>
                  </li> */}
                  </ul>
                </div>
              </div>
              <div class="client-img">
                <h2 class="mt-2">  {stateCust?.name}</h2>
                <hr />

              </div>
              <div class="personal-details">
                {/* <h3>Personal Details</h3> */}
                <div class="email">
                  <p> Phone</p>
                  <p><strong> {stateCust?.phone || "-"}</strong></p>
                </div>
                <div class="dob">
                  <p>  Email</p>
                  <p><strong>{stateCust?.email || "-"}</strong></p>
                </div>
                <div class="contact">
                  <p>  Website</p>
                  <p><strong>{stateCust?.website || "-"}</strong></p>
                </div>
                <div class="address">
                  <p> Address</p>
                  <p>
                    {stateCust?.city || "-"}
                    <br />
                    {stateCust?.state}
                    <br />
                    {stateCust.zipCode}
                  </p>
                </div>
                <div class="postal">
                  <p>  Contact Person</p>
                  <p><strong> <p>{stateCust?.contactPerson || "-"}</p></strong></p>
                </div>
                <div class="visa-expiry">
                  <p>  Designation</p>
                  <p>
                    {stateCust?.designation || "-"}
                  </p>
                </div>
                <div class="visa-type">
                  <p>  Mobile</p>
                  <p>
                    {stateCust?.mobile || "-"}
                  </p>
                </div>
                <p style={{ color: "#071A52", fontSize: "15px" }}>
                  <b>Agent Details</b>
                </p>
                <div class="postal">
                  <p>   ABN</p>
                  <p><strong>{stateCust?.abn || "-"}</strong></p>
                </div>
                <div class="postal">
                  <p>  ACN</p>
                  <p><strong>{stateCust?.acn || "-"}</strong></p>
                </div>
                <div class="postal">
                  <p> PAN</p>
                  <p><strong>{stateCust?.pan || "-"}</strong></p>
                </div>
                <div class="postal">
                  <p> CAN</p>
                  <p><strong>{stateCust?.can || "-"}</strong></p>
                </div>
                <div class="postal">
                  <p> Currency</p>
                  <p><strong>{stateCust?.currency || "-"}</strong></p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Agent Type
                  </Typography>
                  {stateCust?.subAgent === 1 ? <p>SubAgent</p> : null}
                  {stateCust?.superAgent === 1 ? <p>SuperAgent</p> : null}
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Latitude
                  </Typography>
                  <p>{stateCust?.lattitude || "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Longitude
                  </Typography>
                  <p>{stateCust?.longitude || "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Remarks
                  </Typography>
                  <p>{stateCust?.remark || "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Created By
                  </Typography>
                  <p>{stateCust?.createdBy || "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Created ON
                  </Typography>
                  <p>{moment(stateCust?.addedOn).format("DD-MMM-YY") || "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Updated By
                  </Typography>
                  <p>{stateCust?.updatedBy || "-"}</p>
                </div>
                <div>
                  <Typography color="primary" variant="body1">
                    Updated ON
                  </Typography>
                  <p>{moment(stateCust?.updated).format("DD-MMM-YY") || "-"}</p>
                </div>
              </div>
            </div>
            <div class="card client-details">
              <div class="maximize-profile">
                <span onClick={() => {
                  let card = document.getElementById("cardClient");
                  card.style.display = "block";
                }}> &#187; </span>
              </div>
              {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab className="tab-bold" label="Reffered" {...a11yProps(0)} />
                <Tab className="tab-bold" label="Applied" {...a11yProps(1)} />
                <Tab className="tab-bold" label="Agreement" {...a11yProps(2)} />

                <Tab className="tab-bold" label="Payable" {...a11yProps(3)} />
                <Tab className="tab-bold" label="Paid" {...a11yProps(4)} />

              </Tabs>
              {/* </AppBar> */}
              <br />
              <TabPanel
                value={value}
                index={0}
                component={
                  <AgentRefferedDetail
                    agentReffered={agentReffered}
                    agentEmail={params.agent_email}
                    agentId={params.agent_id}
                    handlerefferedpage={handlerefferedpage}
                    getrefferedpageno={getrefferedpageno}
                  />
                }
              />
              <TabPanel
                value={value}
                index={1}
                component={
                  <AgentAppliedDetail
                    agentApplied={agentApplied}
                    agentEmail={params.agent_email}
                    agentId={params.agent_id}
                    handleappliedpage={handleappliedpage}
                    getagentpageno={getagentpageno}
                  />
                }
              />
              <TabPanel
                value={value}
                index={2}
                component={
                  <AgentAgreementDetail
                    transactionalarrangementlist={transactionalarrangementlist}
                    agentId={stateCust.agentId}
                    agentEmail={params.agent_email}
                  />
                }
              />
              <TabPanel
                value={value}
                index={3}
                component={
                  <AgentPayableDetail
                    agentinvoice={agentinvoice}
                    agentEmail={params.agent_email}
                    agentId={params.agent_id}
                    handlepage={handlepage}
                    getpageno={getpageno}
                  />
                }
              />
              <TabPanel
                value={value}
                index={4}
                component={
                  <AgentPaidInvoiceDetail
                    agentpaidinvoice={agentpaidinvoice}
                    agentEmail={params.agent_email}
                    agentId={params.agent_id}
                    handlepaidpage={handlepaidpage}
                    getpaidpageno={getpaidpageno}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAgent;
