import React, { useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../../Utilities/CSSUtilities";
import { CardText, Col, Row, Button, Table } from "reactstrap";

const InboxViewModal = ({ selectemail, p }) => {
    const [remarkmodelopen, setremarkmodelopen] = useState(false);
    const setremarkcontent = () => setremarkmodelopen(true);
    const closeremarkmodal = () => setremarkmodelopen(false);

    return (
        <>
            <a
                // className="leadellipsisspan"
                className="sent-button"
                id={p.id}
                onClick={() => {
                    setremarkmodelopen(true);
                }}
            >
                View
            </a>
            <Modal
                isOpen={remarkmodelopen}
                onRequestClose={() => setremarkmodelopen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    <h4>
                        <strong>View Message</strong>
                    </h4>
                    <div className="form-group creatadmin">
                        <Row>
                            <Col>
                                <h4>
                                    <strong>Receiver:</strong>
                                </h4>
                                <p>{p.toEmailId}</p>
                            </Col>
                            <Col>
                                <h4>
                                    <strong>Subject:</strong>
                                </h4>
                                <p>{p.subject}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group creatadmin">
                        <Row>
                            <Col>
                                <h4>
                                    <strong>Description:</strong>
                                </h4>
                                <p>{p.description}</p>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="form-group creatadmin">
                    <div className="d-flex justify-content-end">
                        <Button color="secondary" onClick={closeremarkmodal}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default InboxViewModal;
