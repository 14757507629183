import React, { useState, useEffect } from "react";
import { jssPreset, makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
} from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectcourse } from "../features/courseSlice";
import { selectcollege } from "../features/collegeSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../css/Responsive.css";
import { selectAdmin } from "../features/adminSlice";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "react-modal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useStopwatch } from "react-timer-hook";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import { selectdashboard } from "../features/dashboardSlice";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "./../Permission/permission";
import DatePicker from "react-datepicker";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  editorConfiguration,
} from "../../Utilities/CSSUtilities";

function AddBasicClient(props) {
  const user = useSelector(selectAdmin);
  const user2 = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const allcourse = useSelector(selectcourse);
  // const allcollege = useSelector(selectcollege);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [country1, setCountry] = useState();
  const { register, handleSubmit, errors } = useForm();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [followupdate, setfollowupdate] = useState(null);
  const [deadlinedate, setdeadlinedate] = useState(null);
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });
  const [backdropopen, setbackdropopen] = useState(false);
  const [workloaddescriptionvalue2, setworkloaddescriptionvalue2] =
    useState(null);
  const [workloaddescriptionvalue, setworkloaddescriptionvalue] = useState(null);
  // const [checkboxstate, setState] = React.useState({
  //   deposit: false,
  // });
  // const checkboxchange = (event) => {
  //   setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  // };

  const classes = useStyles();
  // console.log(props);
  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user2.isw_role_permissions.length; i++) {
      resource_obj.push(user2.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user2.isw_user_permissions.length; i++) {
      permission_obj.push(user2.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check1pointpermission(201, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(4));
      getServices();
      getOffice();
      getClientSource();
      getproforma();
    } else {
      history.push("/errorPage");
    }
  };

  // Proforma templates
  const [template, settemplate] = useState(null);
  const [loadermodal, setloadermodal] = useState(true);
  const [proformadetails, setproformadetails] = useState();
  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log("Proforma res", res);
        setproformadetails(res.data.data);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        handle403error(err);
      });
  };

  const selectCountry = (val) => {
    setCountry(val);
  };
  const [allcollege, setAllCollege] = useState();
  const getallCollege = () => {
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: {
          Authorization: jwtToken,
          username: user2.isw_user_email,
        },
      })
      .then(
        (response) => {
          console.log("Dispatch College response", response);
          var client_college = response.data.data.content;
          setAllCollege(client_college);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allOffice, setAllOffice] = useState();
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          // console.log("Dispatch College response", response);
          var client_college = response.data.data;
          setAllOffice(client_college);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allconsultant, setConsultant] = useState();
  const getConsultant = (officeId) => {
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeId`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(officeId),
        },
      })
      .then(
        (res) => {
          setConsultant(res.data.data);
        },
        (error) => {
          setConsultant(null);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [allServices, setServices] = useState();
  const getServices = () => {
    axios
      .get(`${base_url.api1}/findAllInterestedServices`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (res) => {
          setServices(res.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allclientuser, setAllClientUser] = useState(null);
  const getClient = () => {
    axios
      .get(`${base_url.api3}/getAllClients`, {
        headers: {
          Authorization: jwtToken,
          // 'username':username,
        },
      })
      .then(
        (response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i].clientDetail;
            client_obj.push(element);
          }
          setAllClientUser(client_obj);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [selectedOffice, setSelectedOfficeId] = useState(null);
  const [selectedOfficeN, setSelectedOfficeName] = useState(null);
  const [branchselected, setBranchSelected] = useState(true);
  const [selectedOfficecode, setSelectedOfficecode] = useState(null);

  const handleOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedOfficeId = optionElement.getAttribute("id");
    const selectedOfficeName = optionElement.getAttribute("value");
    const optionElementofficecode = optionElement.getAttribute("officeCode");

    if (selectedOfficeName) {
      setBranchSelected(false);
      getConsultant(selectedOfficeId);
    } else {
      setBranchSelected(true);
    }
    // console.log(selectedOfficeId);
    // console.log(selectedOfficeName);
    // console.log(optionElementofficecode);
    setSelectedOfficeId(selectedOfficeId);
    setSelectedOfficeName(selectedOfficeName);
    setSelectedOfficecode(optionElementofficecode);
  };

  const [selectedAssigne, setSelectedAssigne] = useState(null);
  const [selectedAssigneN, setSelectedAssigneN] = useState(null);
  const [selectedAssigneconsultantN, setSelectedAssigneConsultantN] =
    useState(null);
  const handleAssigne = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedAssigneId = optionElement.getAttribute("id");
    const selectedAssignename = optionElement.getAttribute("consultantname");
    const selectedAssigneconsultantname = optionElement.getAttribute("value");
    setSelectedAssigne(selectedAssigneId);
    setSelectedAssigneN(selectedAssignename);
    setSelectedAssigneConsultantN(selectedAssigneconsultantname);
  };

  const [selectedservice, setSelectedservice] = useState(null);
  const [selectedserviceN, setSelectedserviceN] = useState("Admission");
  const handleService = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceId = optionElement.getAttribute("id");
    const selectedServiceName = optionElement.getAttribute("value");
    setSelectedservice(selectedServiceId);
    setSelectedserviceN(selectedServiceName);
  };

  const [selectedEnquiryStatus, setEnquiryStatus] = useState(null);
  const handleEnquiryStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceName = optionElement.getAttribute("value");
    setEnquiryStatus(selectedServiceName);
  };

  const [selectedLeadType, setLeadType] = useState("HotLead");
  const handleLeadType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceName = optionElement.getAttribute("value");
    setLeadType(selectedServiceName);
    console.log(selectedServiceName);
  };

  const isw_user_role_name = localStorage.getItem("isw_user_role_name");
  const isw_user_id = localStorage.getItem("isw_adminId");
  const [AssignBtn, setAssignedBtn] = useState();

  const [checkBtn, setcheckBtn] = useState();

  // function getCheckboxStatus(event) {
  //   var status = document.getElementById("flexCheckDefault").checked;
  //   if (status) {
  //     setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  //     console.log(event.target.checked, status);
  //     setcheckBtn(1);
  //   } else {
  //     setState({ ...checkboxstate, [event.target.name]: event.target.unchecked });
  //     console.log(event.target.checked, status);
  //     setcheckBtn(0);
  //   }
  // }


  const onSubmit = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let remarks;
    if (workloaddescriptionvalue) {
      remarks = workloaddescriptionvalue;
    } else {
      remarks = workloaddescriptionvalue2;
    }
    let data = {
      addedOn: datenow,
      assigneeId: Number(selectedAssigne),
      assigneeName: selectedAssigneN,
      assigneeEmail: selectedAssigneconsultantN,
      address: data2.address,
      city: data2.city,
      country: country1,
      state: region,
      pincode: data2.pincode,
      email: data2.email.trim(),
      username: data2.email.trim(),
      firstName: data2.firstName.trim(),
      id: 0,
      lastName: data2.lastName.trim(),
      leadType: selectedLeadType,
      remarks: remarks,
      phone: Number(data2.phone),
      officeCode: selectedOfficecode,
      officeId: Number(selectedOffice),
      officeName: selectedOfficeN,
      interestedServices: selectedserviceN,
      //enquiryStatus: data2.enquiryStatus,
      updatedOn: datenow,
      status: 1,
      password: "iswuser",
      emailVerificationStatus: "1",
      clientSource: selectedClientSource,
      createdBy: isw_user_name + " (" + isw_user_email + ")",
      // deposit: checkBtn,
      followUpOn: followupdate
        ? moment(followupdate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      // deadline : deadlinedate
      // ? moment(deadlinedate).format("YYYY-MM-DDThh:mm:ss")
      // : null,
    };
    console.log(data);
    if (AssignBtn == 2) {
      postEnquiry(data);
    } else {
      let data = {
        id: teampClient.id,
        assigneeId: Number(selectedAssigne),
        assigneeName: selectedAssigneN,
        assigneeEmail: selectedAssigneconsultantN,
      };
      console.log(data);
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          toast.success("Success");
          setNewState(true);
          setTeampClient(response.data.data);
          history.push("/leads-list");
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };
  const [respAssigneeId2, setRespAssigneeId2] = useState(false);
  const [respAssigneeId3, setRespAssigneeId3] = useState(false);
  const [teampClient, setTeampClient] = useState({});
  const postEnquiry = (data) => {
    // console.log(data);
    setbackdropopen(true);
    axios
      .post(`${base_url.api3}/addNewClientTemp`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let temp_client = response.data.data;
        setbackdropopen(false);
        toast.success("Success");
        setRespAssigneeId2(true);
        setRespAssigneeId3(true);
        setTeampClient(response.data.data);

        let log =
          isw_user_name +
          " Assigned Lead " +
          temp_client.firstName +
          " " +
          temp_client.lastName +
          " (" +
          temp_client.email +
          ") to " +
          temp_client.assigneeName +
          "(" +
          temp_client.officeName +
          ") " +
          "for " +
          temp_client.interestedServices;
        callsaveauditapi(log);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const [newState, setNewState] = useState();
  const [setSession2, setSetSession2] = useState(false);
  const sessionStart = () => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let todayDate = new Date();
    let fmt = "YYYY-MM-DDTHH:mm:ss";
    let m = moment.utc(todayDate, fmt);
    let createdAt = m.local().format(fmt);

    let data = {
      id: teampClient.id,
      startTime: createdAt,
    };

    axios
      .post(`${base_url.api3}/updateClientTempById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let temp_client = response.data.data;
        toast.success("Success");
        setTeampClient(response.data.data);
        setSetSession2(true);
        setRespAssigneeId2(true);
        setRespAssigneeId3(false);
        start();

        let log =
          isw_user_name +
          " Started Session for " +
          temp_client.firstName +
          " " +
          temp_client.lastName +
          " (" +
          temp_client.email +
          ").";
        callsaveauditapi(log);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [region, setregion] = useState();

  const selectRegion = (val) => {
    setregion(val);
  };

  var subtitle;
  const [clickedBtn, setClickedBtn] = useState();
  const [setComment, setSetComment] = useState();
  const updateSession = (e) => {
    // const isoDateString = new Date().toISOString();
    // const datenow = isoDateString.split(".")[0];
    var tempstartTime1 = teampClient.startTime;
    var tempstartTime =
      tempstartTime1.split(".")[0].split("T")[0] +
      " " +
      tempstartTime1.split(".")[0].split("T")[1];
    // var endTime = datenow;

    let todayDate = new Date();
    let fmt = "YYYY-MM-DDTHH:mm:ss";
    let m = moment.utc(todayDate, fmt);
    let datenow = m.local().format(fmt);
    var endTime = datenow;

    var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
      moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
    );

    var d = moment.duration(duration);
    var totalTime =
      Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");

    // console.log('Start Time1-->',tempstartTime);
    // console.log('Start Time-->',moment(tempstartTime).format('YYYY-MM-DD HH:mm:ss'));
    // console.log('EndTime-->',moment(endTime).format('YYYY-MM-DD HH:mm:ss'));
    // console.log('diff-->',duration);
    // console.log('Duration-->',d);
    // console.log(totalTime);
    // // console.log(clickedBtn);

    if (clickedBtn == 1) {
      // console.log(setComment);
      if (setComment != null) {
        let data = {
          id: teampClient.id,
          comments: setComment,
          endTime: datenow,
          totalTime: totalTime,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            toast.success("Success");

            let log =
              isw_user_name +
              " added Comment & Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      } else {
        let data = {
          id: teampClient.id,
          endTime: datenow,
          totalTime: totalTime,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            toast.success("Success");

            let log =
              isw_user_name +
              " Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      }
    } else if (clickedBtn == 2) {
      var tempstartTime = teampClient.startTime;
      var endTime = datenow;
      var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
        moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
      );
      var d = moment.duration(duration);
      var totalTime =
        Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
      if (setComment != null) {
        let data = {
          id: teampClient.id,
          comments: setComment,
          endTime: datenow,
          totalTime: totalTime,
          // status: 0,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            localStorage.setItem("client_prev_path", "/add-basic-client");
            toast.success("Success");

            let log =
              isw_user_name +
              " added Comment & Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
            history.push("/create-client-lead/" + teampClient.id + "/" + teampClient.username);
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      } else {
        let data = {
          id: teampClient.id,
          endTime: datenow,
          totalTime: totalTime,
          // status: 0,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            localStorage.setItem("client_prev_path", "/add-basic-client");
            toast.success("Success");

            let log =
              isw_user_name +
              " Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
            history.push("/create-client-lead/" + teampClient.id + "/" + teampClient.username);
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      }
    } else if (clickedBtn == 3) {
      setIsOpen(true);
    }
  };

  const openClient = (e) => {
    localStorage.setItem("client_prev_path", "/add-basic-client");
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    var tempstartTime = teampClient.startTime;
    var endTime = datenow;
    var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
      moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
    );
    var d = moment.duration(duration);
    var totalTime =
      Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
    if (setComment != null) {
      let data = {
        id: teampClient?.id,
        comments: setComment,
        endTime: datenow,
        totalTime: totalTime,
        // status: 0,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");

          let log =
            isw_user_name +
            " added Comment & Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          // history.push("/create-client-lead/" + e);
          console.log(e);
          console.log(teampClient.id);
          console.log(teampClient.username);
          history.push("/create-client-lead/" + teampClient.id + "/" + teampClient.username);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      let data = {
        id: teampClient?.id,
        endTime: datenow,
        totalTime: totalTime,
        // status: 0,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");

          let log =
            isw_user_name +
            " Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          // history.push("/create-client-lead/" + e);
          history.push("/create-client-lead/" + teampClient.id + "/" + teampClient.username);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };

  const goback = () => {
    history.push("/leads-list");
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#0000ff";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeClient = () => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    var tempstartTime = teampClient.startTime;
    var endTime = datenow;
    var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
      moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
    );
    var d = moment.duration(duration);
    var totalTime =
      Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
    if (setComment != null) {
      let data = {
        id: teampClient?.id,
        comments: setComment,
        endTime: datenow,
        totalTime: totalTime,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");

          let log =
            isw_user_name +
            " added Comment & Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          history.push("/leads-list");
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    } else {
      let data = {
        id: teampClient?.id,
        endTime: datenow,
        totalTime: totalTime,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");

          let log =
            isw_user_name +
            " Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          history.push("/leads-list");
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };
  const dashboardvalue = useSelector(selectdashboard);

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setClientSource(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [selectedClientSource, setselectedClientSource] = useState(null);
  const handleClientSource = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    if (optionElementName === "Sub Agent") {
      console.log("hello Sangam1");
    } else if (optionElementName === "Referral") {
      console.log("hello Sangam2");
    } else {
      console.log("hello Sangam3");
    }
    setselectedClientSource(optionElementName);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      {/* <CardSubtitle className="font-weight-bold"></CardSubtitle> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.2rem", marginBottom: "0px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Leads</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Client</a><span className="s_span ">-</span>
          <a href="#/leads-list" class="b-link">
            Lead
          </a>
          <span className="s_span ">-</span>
          <a href="#/" class="b-link active">
            Form
          </a>
        </ul>
        <a onClick={goback} class="back-btn">
          Back
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2", }}>
        <Row>
          <Col md={4}>
            <h4 className="page-title bold"
              style={{
                marginTop: "-10px",
                fontSize: "20px",
                fontWeight: "600"
              }}>
              Send Information
            </h4>
          </Col>
          <Col md={4}>{null}</Col>
          {/* <Col md={4} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col> */}
        </Row>
        {/* <form
              id="myForm"
              onSubmit={(e) => {
                e.preventDefault();
                submitLead(e);
              }}
            > */}
        <form id="myForm" onSubmit={handleSubmit(onSubmit)} className="s_ckeditor">
          <div style={{ padding: "0 20px " }}>
            <div className="form-group creatadmin2">
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      // required
                      name="firstName"
                      placeholder="First Name"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    />
                    {errors.firstName && (
                      <p className="errormessage">FirstName is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      // required
                      name="lastName"
                      placeholder="Last Name"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    />
                    {errors.lastName && (
                      <p className="errormessage">LastName is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Email<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control underline-input"
                      // required
                      name="email"
                      placeholder="Email"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    />
                    {errors.email && (
                      <p className="errormessage">Email is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Phone<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      // required
                      name="phone"
                      placeholder="Phone Number"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    />
                    {errors.phone && (
                      <p className="errormessage">Phone is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              {/* )} */}
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label>
                      Address <span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      // required
                      name="address"
                      placeholder="Address"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    />
                    {errors.address && (
                      <p className="errormessage">Address is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      City/Suburb<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      // required
                      name="city"
                      placeholder="City"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    />
                    {errors.city && (
                      <p className="errormessage">City is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      value={country1}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectCountry(val)}
                      required
                    // ref={register({ required: true })}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_skill">
                      State<span style={{ color: "red" }}>*</span>
                    </label>
                    {country1 == "Nepal" ? (
                      <select
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control"
                        onChange={(e) => selectRegion(e.target.value)}
                      >
                        <option value="">- Select Province -</option>
                        <option value="Province No. 1">Province No. 1</option>
                        <option value="Madhesh Province">Madhesh Province</option>
                        <option value="Bagmati Province">Bagmati Province</option>
                        <option value="Gandaki Province">Gandaki Province</option>
                        <option value="Lumbini Province">Lumbini Province</option>
                        <option value="Karnali Province">Karnali Province</option>
                        <option value="Sudurpashchim Province">
                          Sudurpashchim Province
                        </option>
                      </select>
                    ) : (
                      <RegionDropdown
                        defaultOptionLabel="- Select State -"
                        className="form-control underline-input countrydropdown2"
                        country={country1}
                        value={region}
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(val) => selectRegion(val)}
                        required
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_skill">
                      PostCode<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control underline-input"
                      name="pincode"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    // required
                    />
                    {errors.pincode && (
                      <p className="errormessage">PostCode is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Lead Type<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleLeadType}
                      // required
                      name="leadType"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    >
                      <option value=""> Lead Type </option>
                      <option value="ColdLead">Cold Lead</option>
                      <option value="WarmLead">Warm Lead</option>
                      <option value="HotLead" selected>
                        Hot Lead
                      </option>
                      <option value="Converted">Converted Lead</option>
                    </select>
                    {errors.leadType && (
                      <p className="errormessage">Lead Type is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Services
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleService}
                      // required
                      name="interestedServices"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    >
                      {/* <option value="">- Interested Service -</option> */}
                      {allServices?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          id={cuntitem.id}
                          value={cuntitem.name}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                    {errors.interestedServices && (
                      <p className="errormessage">
                        Interested Services is Required
                      </p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label >Source
                      <span style={{ color: "red" }}></span>:
                    </label>
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      name="clientsource"
                      className="form-control"
                      onChange={handleClientSource}
                      ref={register({ required: false })}
                    // required
                    >
                      <option value="">- Select Source -</option>
                      {allClientSource?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  {/* <Col md={3}>
                    <label>
                      Enquiry Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleEnquiryStatus}
                      // required
                      name="enquiryStatus"
                      placeholder="Enquiry Status"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    >
                      <option value="Enquiry">Application Processing</option>
                      <option value="Processing">Processing</option>
                      <option value="OnGoing">OnGoing</option>
                      <option value="Completed">Completed</option>
                      <option value="RefundProcessing">
                        Refund Processing
                      </option>
                      <option value="Discontinued">Discontinued</option>
                    </select>
                    {errors.enquiryStatus && (
                      <p className="errormessage">Enquiry Status is Required</p>
                    )}
                  </Col> */}
                  <Col md={3}>
                    <label>
                      Branch<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleOffice}
                      // required
                      name="officeName"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    >
                      <option value=""> Branch </option>
                      {/* {allOffice?.map((cuntitem) => (
                            <option
                              key={cuntitem.officeId}
                              id={cuntitem.officeId}
                              value={cuntitem.officeName}
                            >
                              {cuntitem.officeName}
                            </option>
                          ))} */}
                      {user2?.isw_available_Office?.map((p, index) => (
                        <option
                          key={p.officeId}
                          id={p.officeId}
                          value={p.officeName}
                          officeCode={p.officeCode}
                        >
                          {p.officeName}
                          {" - "}
                          {p.officeCode}
                        </option>
                      ))}
                    </select>
                    {errors.officeName && (
                      <p className="errormessage">Branch is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Consultant<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleAssigne}
                      // required
                      disabled={branchselected}
                      name="assigneeName"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register({ required: true })}
                    >
                      <option value="">- Select Consultant -</option>
                      {allconsultant != null ? (
                        allconsultant.map((cuntitem) => (
                          <option
                            key={cuntitem.id}
                            id={cuntitem.id}
                            value={cuntitem.email}
                            consultantname={`${cuntitem.firstName} ${cuntitem.lastName}`}
                          >
                            {cuntitem.firstName} {cuntitem.lastName} (
                            {cuntitem.email})
                          </option>
                        ))
                      ) : (
                        <option value="">No Consultant Available</option>
                      )}
                    </select>
                    {errors.assigneeName && (
                      <p className="errormessage">Consultant is Required</p>
                    )}
                  </Col>


                  <Col md={3}>
                    <label >Follow-up
                      <span style={{ color: "red" }}></span>:
                    </label>
                    <DatePicker
                      style={{ backgroundColor: "#f5f5f9" }}
                      selected={followupdate ? new Date(followupdate) : null}
                      onChange={(date) => setfollowupdate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                      minDate={new Date()}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Templates</label>
                    <select
                      onChange={(e) => settemplate(e.target.value)}
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "f5f5f9",
                      }}
                    >
                      <option value="">- Select Template -</option>
                      {proformadetails?.map((item, index) => (
                        <option key={index} value={item.template}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              {/* <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <div class="container" style={{ marginTop: "20px" }}>
                      <input
                        type="checkbox"
                        checked={checkboxstate.deposit}
                        onChange={getCheckboxStatus}
                        id="flexCheckDefault"
                        name="deposit"
                      />
                      <label class="checkmark" for="flexCheckDefault" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefault"
                        style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Deposit</label>
                    </div>
                    <br />
                  </Col>
                  {/* <Col md={3}>
              <label >Deadline
              <span style={{ color: "red" }}></span>:
              </label>
              <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={deadlinedate ? new Date(deadlinedate) : null}
                    onChange={(date) => setdeadlinedate(date)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
              </Col> */}
              {/* </Row>
          </div>  */}
              {/* <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label>Remarks:</label>
                    <textarea
                      type="text"
                      className="form-control underline-input"
                      name="remarks"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register}
                      placeholder="Write Remarks"
                      rows={7}
                    />
                  </Col>
                </Row>
              </div> */}
              <label>Remark</label>
              {template === null ? (
                <CKEditor
                  editor={Editor}
                  config={editorConfiguration}
                  data={workloaddescriptionvalue2}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setworkloaddescriptionvalue2(data);
                  }}
                />
              ) : (
                <CKEditor
                  editor={Editor}
                  config={editorConfiguration}
                  data={template}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                    // setloadermodal(true);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setworkloaddescriptionvalue(data);
                  }}
                />
              )}
              <div className="form-group creatadmin">
                <Row>
                  <Col md={6}>{null}</Col>
                  <Col
                    md={6}
                    className="form-group button-container d-flex justify-content-end"
                  >
                    {respAssigneeId2 ? null : (
                      <button
                        className="outline-button"
                        style={{
                          border: "1px solid #17b978",
                          color: "#17b978",
                          fontWeight: "600",
                          float: "left",
                          backgroundColor: "white",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          document.getElementById("myForm").reset();
                        }}
                      >
                        RESET
                      </button>
                    )}
                    {respAssigneeId2 ? (
                      <button
                        type="submit"
                        className="sent-button"
                        style={{
                          backgroundColor: "#17b978",
                          color: "#fff",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          setAssignedBtn(1);
                        }}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="sent-button"
                        style={{
                          backgroundColor: "#17b978",
                          color: "#fff",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          setAssignedBtn(2);
                        }}
                      >
                        SUBMIT
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </form >
        {
          respAssigneeId3 ? (
            <div className="form-inner-admin" >
              <button
                type="submit"
                className="sent-button"
                style={{
                  backgroundColor: "#17b978",
                  color: "#fff",
                  fontWeight: "600",
                }}
                onClick={sessionStart}
              >
                Attend Session
              </button>
            </div>
          ) : null
        }
        {
          setSession2 ? (
            <div className="form-inner-admin">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  updateSession(e);
                }}
              >
                <h4>
                  <strong>Check-In Details</strong>
                </h4>
                <br />
                <h6>
                  <strong>Session Time: </strong>
                  <span>{hours}</span>:<span>{minutes}</span>:
                  <span>{seconds} sec</span>
                </h6>
                <br />
                <FiberManualRecordIcon fontSize="small" color="primary" />
                {teampClient.enquiryStatus}
                <div className="form-group creatadmin2">
                  <Row>
                    <Col md={6}>
                      {/* <Row>
                        <Col md={6}>
                          <p><strong>Contact</strong></p>
                          <AccountCircleIcon/>
                          <strong>{teampClient.firstName} {teampClient.lastName}</strong>
                          <br/>
                          {teampClient.email}
                        </Col>
                      </Row> */}
                      <Row style={{ marginLeft: "0px" }}>
                        <Col md={0.5}>
                          <QueryBuilderIcon />
                        </Col>
                        <Col md={3}>
                          <span>
                            <strong>Check-In Date</strong>
                          </span>
                          <br />
                          <p>
                            {moment(teampClient.startTime.split(".")[0]).format(
                              "DD-MMM-YY hh.mm a"
                            )}
                          </p>
                        </Col>
                        <Col md={3}>
                          <span>
                            <strong>Session Start</strong>
                          </span>
                          <br />
                          <p>
                            {moment(teampClient.startTime.split(".")[0]).format(
                              "DD-MMM-YY hh.mm a"
                            )}
                          </p>
                        </Col>
                        <Col md={3}>
                          <span>
                            <strong>Session End</strong>
                          </span>
                          <br />
                          {teampClient.endTime ? (
                            <p>
                              {moment(teampClient.endTime).format(
                                "DD-MMM-YY hh.mm a"
                              )}
                            </p>
                          ) : null}
                        </Col>
                        <Col md={2.5}>
                          <span>
                            <strong>Total Time</strong>
                          </span>
                          <br />
                          <span>{hours}</span>:<span>{minutes}</span>:
                          <span>{seconds} sec</span>
                        </Col>
                      </Row>
                      {/* <Row style={{marginLeft:"0px"}}>
                        <AccountCircleIcon/>
                        <strong>{teampClient.assigneeName}</strong>
                      </Row><br/><br/> */}
                      <Row className={classes.commentBox}>
                        <label>
                          <strong>Comment</strong>
                        </label>
                        <br />
                        {/* <input
                          type="textarea"
                          style={{height:"100px"}}
                          className="form-control underline-input"
                          onChange={(e)=>{setSetComment(e.target.value)}}
                        /> */}
                        <TextareaAutosize
                          rowsMax={6}
                          style={{ height: "100px" }}
                          className="form-control underline-input"
                          onChange={(e) => {
                            setSetComment(e.target.value);
                          }}
                        />
                      </Row>
                      <Row>
                        <div className="form-group creatadmin d-flex">
                          <button
                            type="submit"
                            name="btn1"
                            className="btn listing_addbutton"
                            onClick={() => {
                              setClickedBtn(1);
                            }}
                          >
                            Save
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            name="btn2"
                            className="btn listing_addbutton ml-1"
                            onClick={() => {
                              setClickedBtn(2);
                            }}
                          >
                            Convert To Client
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            name="btn3"
                            id={teampClient.id}
                            className="btn listing_addbutton ml-1"
                            onClick={() => {
                              setClickedBtn(3);
                            }}
                          >
                            Complete Session
                          </button>
                          <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h4 style={{ color: "blue" }}>
                              Would You Like to fill the client
                              <br />
                              details and create application ?
                            </h4>
                            <div
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <Button
                                className="btn listing_addbutton"
                                onClick={() => openClient(teampClient.id)}
                              >
                                Yes
                              </Button>
                              <Button
                                color="secondary"
                                className="ml-2"
                                onClick={closeClient}
                              >
                                No
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
          ) : null
        }
      </div >
      {/* </Card> */}
    </div >
  );
}

export default withRouter(AddBasicClient);