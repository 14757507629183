import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Pagination from "@material-ui/lab/Pagination";
import "../../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { selectdashboard } from "../../features/dashboardSlice";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import { checkFinancepermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import FormControl from "@material-ui/core/FormControl";
import AdmissionOnGoing from "./AdmissionOnGoing";
import MigrationOnGoing from "./MigrationOnGoing";
import InsuranceOnGoing from "./InsuranceOnGoing";
import SkillOnGoing from "./SkillOnGoing";

function ApplicationOnGoing(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [backdropopen, setbackdropopen] = useState(false);
  const [deleteEnquiry, setDeleteEnquiry] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const history = useHistory();
  const [sortState, setSortState] = useState("created");
  const [direction, setDirection] = useState("DESC");
  const [pageNo, setPageNo] = useState(0);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [enquiry, setEnquiry] = useState(null);
  const [display1, setDisplay] = useState("inline");
  const [value, setValue] = React.useState(0);

  const isw_user_name = localStorage.getItem("isw_user_name");
  const [deletedUser, setDeletedUser] = useState();
  const [totalApplication, setTotalApplication] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberofElements] = useState();
  const [rows, setRows] = React.useState(50);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const [enquiryStatusData, setEnquiryStatusData] = useState("OnGoing");
  const [enquirySourceType, setEnquirySourceType] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [selectedCource, setSelectedCource] = useState(null);
  const [selectedCurrentStage, setSelectedCurrentStage] = useState(null);
  const [selectedIntake, setSelectedIntake] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [WorkFlowStatusData, setWorkFlowStatusData] = useState("");
  const [selectedOffice, setSelectedOffice] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? null
      : user?.isw_available_Office[0].officeId
  );
  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState(null);
  let headData;

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);





  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(508, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(51));
    } else {
      history.push("/errorPage");
    }
  };

  const classes = useStyles();

  function TabPanel(props) {
    return (
      <div
        role="tabpanel"
        hidden={props.value !== props.index}
        id={`simple-tabpanel-${props.index}`}
        aria-labelledby={`simple-tab-${props.index}`}
      >
        {props.value === props.index && props.component}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <div>

      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "5rem", marginBottom: "-13px" }}
      >
        <ul class="breadcrums">
          {/* <h3 class="page-title bold">Application OnGoing Follow-Up Sheet</h3> */}
          <h3 class="page-title bold">Create Invoice</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span>
          <a class="b-link">
            Finance
          </a>
          <span className="s_span ">-</span>
          <a href="#/application-ongoing" class="b-link active">
            Create Invoice
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab className="tab-bold" label="Admission" {...a11yProps(0)} />
        <Tab className="tab-bold" label="Migration" {...a11yProps(1)} />
        <Tab className="tab-bold" label="Insurance" {...a11yProps(2)} />
        <Tab
          className="tab-bold"
          label="Skill Assessment"
          {...a11yProps(3)}
        />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel
        value={value}
        index={0}
        component={<AdmissionOnGoing />}
      />
      <TabPanel
        value={value}
        index={1}
        component={
          <MigrationOnGoing />
        }
      />
      <TabPanel
        value={value}
        index={2}
        component={
          <InsuranceOnGoing />
        }
      />
      <TabPanel
        value={value}
        index={3}
        component={
          <SkillOnGoing />
        }
      />

      {/* </div> */}
    </div>
  );
}

export default ApplicationOnGoing;