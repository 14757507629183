import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import Modal from "react-modal";
import moment from "moment";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { getcourse, removecourse, selectcourse } from "../features/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles } from "../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../services/auditservice";
import { getcoursepermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import sort from "./../images/sort.svg";
import CourseViewMoreModal from "./../Modal/Masters/CourseViewMoreModal";
import CourseArchiveModal from "./../Modal/Masters/CourseArchiveModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    transform: "translate(-50%, -50%)",
    // background:'rgba(0,0,0,0.5)'
  },
  Overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

function Courses(props) {
  const user = useSelector(selectUser);
  const allcourses = useSelector(selectcourse);
  const dispatch = useDispatch();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [modal, setModal] = useState(false);
  const [deleteCourse, setDeletecourse] = useState(null);
  const history = useHistory();
  const [remarkcontent, setremarkcontent] = useState();
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [datefilter, setdatefilter] = useState();
  const [providerfilter, setproviderfilter] = useState();
  const [categoryfilter, setcategoryfilter] = useState();
  const [areafilter, setareafilter] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [statusstate, setstatusstate] = useState(1);
  const [deleteCourseName, setdeleteCourseName] = useState();
  const [deleteCourseCollege, setdeleteCourseCollege] = useState();
  const [deleteCourseStatus, setdeleteCourseStatus] = useState();
  const toggle = (elem, p) => {
    console.log(elem.target.id);
    setdeleteCourseName(p.name);
    setdeleteCourseCollege(p.institutionName);
    setDeletecourse(elem.target.id);
    if (p.status == 1) {
      setdeleteCourseStatus(0);
    } else {
      setdeleteCourseStatus(1);
    }
    setModal(!modal);
  };
  var subtitle;
  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setModal(false);
  };
  const [pageno, setpageno] = useState();
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [sortState, setsortState] = useState("courseId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");
  const [totalCourse, settotalCourse] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [backdropopen, setbackdropopen] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allcourses]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check1pointpermission(901, permission_obj, resource_obj)) {

      //if (available_permission) {
      dispatch(setdashboardcheck(23));
      // currentPage = localStorage.getItem("pageNo");
      if (localStorage.getItem("coursePageNo")) {
        let currentPage = localStorage.getItem("coursePageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getAllCourses(currentPage, statusstate);
      } else {
        setpaginationpageno(1);
        setpageno(0);
        getAllCourses(0, statusstate);
      }
      getBranches();
      getCategory();
      getArea();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const [availableareaId, setAvailableareaId] = useState(null);
  const getArea = () => {
    axios
      .get(`${base_url.api1}/findAllArea`)
      .then((response) => {
        setAvailableareaId(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allBranchProvider, setAllBranchProvider] = useState();
  const [selectedBranches, setselectedBranches] = useState();
  const getBranches = () => {
    axios
      .get(`${base_url.api2}/findAllInstitutionBranches`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAllBranchProvider(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [availableCategory, setAvailableCategory] = useState(null);
  const getCategory = () => {
    axios
      .get(`${base_url.api1}/findAllCategory`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setAvailableCategory(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const createcourse = () => {
    history.push("/create-course");
  };
  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const getAllCourses = (value1, statusstate) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllCourses`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("Dispatch course response", response);
          var course_list = response.data.data.content;
          settotalCourse(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          dispatch(getcourse(course_list));
          setsearch(false);
          setdatefilter(false);
          setgetpageno(response.data.data.totalPages);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    getAllCourses(value - 1, statusstate);
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpageno(value - 1);
    setpaginationpageno(value);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchCourseByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Dispatch course response", response);
            setcountnumber(response.data.data.number);
            var course_list = response.data.data.content;
            dispatch(getcourse(course_list));
            setsearch(false);
            setgetpageno(response.data.data.totalPages);
            setbackdropopen(false);
          },
          (error) => {
            if (error.response) {
              toast.error(JSON.stringify(error.response.data.message));
            } else {
              toast.error(JSON.stringify("Something Went Wrong"));
            }
            handle403error(error);
            setbackdropopen(false);
          }
        );
    } else if (providerfilter) {
      axios
        .get(`${base_url.api2}/searchCourseByFilter`, {
          headers: {
            Authorization: jwtToken,
            page: Number(pageno),
            size: Number(rows),
            columnName: sortState,
            direction: direction,
            institutionId: Number(selectedProvider),
          },
        })
        .then(
          (response) => {
            var course_list = response.data.data.content;
            settotalCourse(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            dispatch(getcourse(course_list));
            setsearch(false);
            setproviderfilter(true);
            setgetpageno(response.data.data.totalPages);
            setbackdropopen(false);
          },
          (error) => {
            dispatch(getcourse(null));
            settotalCourse(null);
            setcountnumber(null);
            setnumberofElements(null);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else if (categoryfilter) {
      axios
        .get(`${base_url.api2}/searchCourseByFilter`, {
          headers: {
            Authorization: jwtToken,
            page: Number(pageno),
            size: Number(rows),
            columnName: sortState,
            direction: direction,
            categoryId: Number(selectedCategory),
          },
        })
        .then(
          (response) => {
            var course_list = response.data.data.content;
            settotalCourse(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            dispatch(getcourse(course_list));
            setsearch(false);
            setcategoryfilter(true);
            setgetpageno(response.data.data.totalPages);
            setbackdropopen(false);
          },
          (error) => {
            dispatch(getcourse(null));
            settotalCourse(null);
            setcountnumber(null);
            setnumberofElements(null);
            handle403error(error);
          }
        );
    } else if (areafilter) {
      axios
        .get(`${base_url.api2}/searchCourseByFilter`, {
          headers: {
            Authorization: jwtToken,
            page: Number(0),
            size: Number(rows),
            columnName: sortState,
            direction: direction,
            areaId: Number(selectedArea),
          },
        })
        .then(
          (response) => {
            var course_list = response.data.data.content;
            settotalCourse(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            dispatch(getcourse(course_list));
            setsearch(false);
            setareafilter(true);
            setgetpageno(response.data.data.totalPages);
            setbackdropopen(false);
          },
          (error) => {
            dispatch(getcourse(null));
            settotalCourse(null);
            setcountnumber(null);
            setnumberofElements(null);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchCourse`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            searchQuery: searchinput,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            dispatch(getcourse(client_admin));
            setbackdropopen(false);
          },
          (error) => {
            if (error.response) {
              toast.error(JSON.stringify(error.response.data.message));
            } else {
              toast.error(JSON.stringify("Something Went Wrong"));
            }
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };
  const submit = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchCourse`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          var client_admin = response.data.data.content;
          dispatch(getcourse(client_admin));
          setgetpageno(response.data.data.totalPages);
          settotalCourse(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(true);
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          handle403error(error);
          setbackdropopen(false);
        }
      );
  };

  const deleteCurrentCourse = (elem) => {
    let data = {
      courseId: deleteCourse,
      status: deleteCourseStatus,
    };
    setbackdropopen(true);
    axios
      .post(`${base_url.api2}/updateCourseById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let logs =
            isw_user_name +
            " Updated Course Status " +
            deleteCourseName +
            " from " +
            deleteCourseCollege +
            ".";
          callsaveauditapi(logs);
          dispatch(
            removecourse({
              id: deleteCourse,
            })
          );
          setbackdropopen(false);
          setModal(!modal);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const editCourse = (elem) => {
    localStorage.setItem("coursePageNo", pageno);
    var course_id = elem.target.id;
    history.push("/edit-course/" + course_id);
  };

  const viewReportpage = (elem) => {
    localStorage.setItem("coursePageNo", pageno);
    var course_id = elem.target.id;
    history.push("/course-report/" + course_id);
  };
  const classes = useStyles();

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    axios
      .get(`${base_url.api2}/getAllCourses`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("Dispatch course response", response);
          setcountnumber(response.data.data.number);
          var course_list = response.data.data.content;
          dispatch(getcourse(course_list));
          setsearch(false);
          setgetpageno(response.data.data.totalPages);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setpaginationpageno(1);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    axios
      .post(`${base_url.api2}/searchCourseByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("Dispatch course response", response);
          var course_list = response.data.data.content;
          dispatch(getcourse(course_list));
          setsearch(false);
          settotalCourse(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setdatefilter(true);
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
        }
      );
  };

  const [selectedProvider, setselectedProvider] = useState();
  const handleChangeProvider = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    setselectedProvider(optionElementId);
    const optionElementName = optionElement.getAttribute("value");
    axios
      .get(`${base_url.api2}/searchCourseByFilter`, {
        headers: {
          Authorization: jwtToken,
          page: Number(0),
          size: Number(rows),
          columnName: sortState,
          direction: direction,
          institutionId: Number(optionElementId),
        },
      })
      .then(
        (response) => {
          var course_list = response.data.data.content;
          settotalCourse(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          dispatch(getcourse(course_list));
          setsearch(false);
          setproviderfilter(true);
          setgetpageno(response.data.data.totalPages);
          setbackdropopen(false);
        },
        (error) => {
          dispatch(getcourse(null));
          settotalCourse(null);
          setcountnumber(null);
          setnumberofElements(null);
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [selectedCategory, setselectedCategory] = useState();
  const handleChangeCategory = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    setselectedCategory(optionElementId);
    const optionElementName = optionElement.getAttribute("value");
    axios
      .get(`${base_url.api2}/searchCourseByFilter`, {
        headers: {
          Authorization: jwtToken,
          page: Number(0),
          size: Number(rows),
          columnName: sortState,
          direction: direction,
          categoryId: Number(optionElementId),
        },
      })
      .then(
        (response) => {
          var course_list = response.data.data.content;
          settotalCourse(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          dispatch(getcourse(course_list));
          setsearch(false);
          setcategoryfilter(true);
          setgetpageno(response.data.data.totalPages);
          setbackdropopen(false);
        },
        (error) => {
          dispatch(getcourse(null));
          settotalCourse(null);
          setcountnumber(null);
          setnumberofElements(null);
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [selectedArea, setselectedArea] = useState();
  const handleChangeArea = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    setselectedArea(optionElementId);
    const optionElementName = optionElement.getAttribute("value");
    axios
      .get(`${base_url.api2}/searchCourseByFilter`, {
        headers: {
          Authorization: jwtToken,
          page: Number(0),
          size: Number(rows),
          columnName: sortState,
          direction: direction,
          areaId: Number(optionElementId),
        },
      })
      .then(
        (response) => {
          var course_list = response.data.data.content;
          settotalCourse(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          dispatch(getcourse(course_list));
          setsearch(false);
          setareafilter(true);
          setgetpageno(response.data.data.totalPages);
          setbackdropopen(false);
        },
        (error) => {
          dispatch(getcourse(null));
          settotalCourse(null);
          setcountnumber(null);
          setnumberofElements(null);
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const filterCourseStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setstatusstate(Number(optionElementName));
    // console.log(optionElementName);
    getAllCourses(pageno, optionElementName);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div id="root">
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Course</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Other</a><span className="s_span ">-</span>
          <a href="#/course" class="b-link active">
            Course
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(902, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={createcourse}
            style={{ float: "right" }}
          >
            Add
          </a>
        ) : null}
      </div>
      <div>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Manage Course</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionData?.includes(232) ? (
                <button
                  className="btn listing_addbutton"
                  onClick={createcourse}
                >
                  <AddIcon className="listing_addbutton_i" /> Add
                </button>
              ) : null}
            </Col>
          </Row> */}
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={2} style={{ marginRight: "-5px" }}>
              <label class="area_rowslable_skill">Limit</label>
              {/* <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#f5f5f9",
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form> */}
              <select
                className="form-control"
                name="branch"
                value={rows}
                onChange={handleRows}
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </Col>
            <Col md={2} style={{ marginRight: "-5px" }}>
              <label className="area_rowslable_skill">Date</label>
              <div className="clearsearchclass" style={{ width: "100%", marginTop: "0px" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  style={{ position: "relative" }}
                >
                  <DateRangePicker
                    onEvent={handleEvent}
                    onCallback={handleCallback}
                    onApply={handleApply}
                  >
                    <p
                      className="datepickerrangevaluetext"
                      style={{ backgroundColor: "#f5f5f9", width: "95%" }}
                    >
                      {datevalue}
                    </p>
                  </DateRangePicker>
                  {/* {datefilter ? (
                  <div style={{ float: "left" }}>
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setdisplay("inline");
                        setdatevalue("Click to open");
                        setdirection("DESC");
                        setpageno(0);
                        getAllCourses(0, statusstate);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null} */}
                  <span
                    className="listpagesearch_button"
                    style={{
                      top: "7px",
                      position: "absolute",
                      right: "20px",
                      pointerEvents: "none",
                    }}
                  >
                    <i className="fa fa-calendar"></i>
                  </span>
                </form>
              </div>
            </Col>
            <Col md={2} style={{ marginRight: "-5px" }}>
              <label className="area_rowslable_skill">Provider</label>
              <select
                className="form-control"
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
                onChange={handleChangeProvider}
              >
                <option value="">- Select College -</option>
                {allBranchProvider?.map((cuntitem, index) => (
                  <option
                    key={index}
                    value={cuntitem.branchName}
                    id={cuntitem.branchId}
                  >
                    {cuntitem.branchName}
                  </option>
                ))}
              </select>
              {providerfilter ? (
                <div style={{ float: "left" }}>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setproviderfilter(false);
                      setdisplay("inline");
                      setdirection("DESC");
                      getAllCourses(0, statusstate);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}

            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col md={2}>
              <label className="area_rowslable_skill">Category</label>
              <select
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  backgroundColor: "#f5f5f9",
                  width: "85%"
                }}
                className="form-control"
                onChange={handleChangeCategory}
                name="category"
              >
                <option value="">- Select Category -</option>
                {availableCategory?.map((cuntitem) => (
                  <option
                    key={cuntitem.category_id}
                    value={cuntitem.name}
                    id={cuntitem.category_id}
                  >
                    {cuntitem.name}
                  </option>
                ))}
              </select>
              {categoryfilter ? (
                <div style={{ float: "left" }}>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setcategoryfilter(false);
                      setdisplay("inline");
                      setdirection("DESC");
                      getAllCourses(0, statusstate);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}
            </Col>
            <Col md={2}>
              <label className="area_rowslable_skill">Area</label>
              <select
                className="form-control"
                style={{ backgroundColor: "#f5f5f9", width: "85%" }}
                onChange={handleChangeArea}
                name="area"
              >
                <option value="">- Select Area -</option>
                {availableareaId?.map((cuntitem) => (
                  <option
                    key={cuntitem.area_id}
                    value={cuntitem.name}
                    id={cuntitem.area_id}
                  >
                    {cuntitem.name}
                  </option>
                ))}
              </select>
              {areafilter ? (
                <div style={{ float: "left" }}>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setareafilter(false);
                      setdisplay("inline");
                      setdirection("DESC");
                      getAllCourses(0, statusstate);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}
            </Col>
            <Col md={2}>
              <label className="area_rowslable_skill">Status</label>
              <select
                className="form-control"
                style={{ backgroundColor: "#f5f5f9", width: "85%" }}
                onChange={filterCourseStatus}
              >
                <option value="1">Unarchive</option>
                <option value="0">Archive</option>
              </select>
            </Col>
            <Col md={2} className="mt-3">
              <div className="clearsearchclass" style={{ marginTop: "13px", width: "85%" }}>
                <form
                  className="d-flex"
                  id="myForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch2}
                  onClick={() => {
                    setdisplay("inline");
                    setpageno(0);
                    getAllCourses(0, statusstate);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allcourses?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalCourse}{" "}
                  records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {/* <Col md={6}>
              {permissionData?.includes(76) ? (
                <a
                  className="btn btn-success"
                  onClick={createcourse}
                  style={{ float: "right" }}
                >
                  Add
                </a>
              ) : null}
            </Col> */}
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  {/* <th>Id</th> */}
                  <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                  <th>
                    NAME {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th>
                    PROVIDER {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="institutionName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CATEGORY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="categoryName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th>
                    AREA {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="areaName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    DURATION {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="duration"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    REMARKS
                    {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="comment"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  {check4pointpermission(
                    903,
                    904,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ minWidth: "125px" }}>ACTION</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allcourses != null ? (
                  allcourses &&
                  allcourses.map((p, index) => (
                    <tr key={p.courseId}>
                      <td style={{ width: "50px", textAlign: "center" }}>
                        {countnumber * rows + 1 + index}
                      </td>
                      <td>
                        {check1pointpermission(
                          905,
                          permissionData,
                          rolepermission
                        ) ? (
                          <>
                            <a
                              className="greeneffect"
                              id={p.courseId}
                              onClick={viewReportpage}
                              href={`#/course-report/` + p.courseId}
                            >
                              {p.name}
                            </a>
                            <br />
                            {p.cricos}
                          </>
                        ) : (
                          <>
                            {p.name}
                            <br />
                            {p.cricos}
                          </>
                        )}
                      </td>
                      <td>{p.institutionName}</td>
                      <td>{p.categoryName}</td>
                      <td>{p.areaName}</td>
                      <td>{p.status == 1 ? "Active" : "Inactive"}</td>
                      <td>{p.duration}</td>
                      <td className="leadellipsis">
                        {p.comment}
                        {p.comment != null ? (
                          //   <span
                          //   className="leadellipsisspan"
                          //   onClick={() => {
                          //     setremarkmodelopen(true);
                          //     setremarkcontent(p.comment);
                          //   }}
                          // >
                          //   View More
                          // </span>
                          // <button
                          //   // className="leadellipsisspan"
                          //   className="role-deletebtn"
                          //   onClick={() => {
                          //     setremarkmodelopen(true);
                          //     setremarkcontent(p.comment);
                          //   }}
                          // >
                          //   View More
                          // </button>
                          <CourseViewMoreModal
                            p={p}
                          />
                        ) : null}
                      </td>
                      <Modal
                        isOpen={remarkmodelopen}
                        onRequestClose={() => setremarkmodelopen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h4>Remark</h4>
                        {remarkcontent ? (
                          <textarea
                            style={{ minWidth: "600px" }}
                            className="form-control"
                            value={remarkcontent}
                            rows={10}
                            readonly
                          />
                        ) : (
                          <p> No Record Available</p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            margin: "10px 0",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="secondary"
                            onClick={() => setremarkmodelopen(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal>
                      <td id={p.username}>
                        {check4pointpermission(
                          903,
                          904,
                          permissionData,
                          rolepermission
                        ) ? (
                          <div
                            style={{
                              position: "relative",
                              textAlign: "left",
                            }}
                          >
                            <div
                              className="workflowdashboard_fa-ellipsis"
                              data-toggle="dropdown"
                            >
                              <i
                                className="fa fa-ellipsis-v "
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div className="dropdown-menu workflowdashboarddropdown">
                              <ul style={{ margin: "0px" }} className="ulclass">
                                {check1pointpermission(
                                  903,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <li>
                                    <p>
                                      <a
                                        id={p.courseId}
                                        onClick={editCourse}
                                        href={`#/edit-course/` + p.courseId}
                                        className="aclass"
                                      >
                                        Edit
                                      </a>
                                    </p>
                                  </li>
                                ) : null}
                                {check1pointpermission(
                                  904,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  // <p>
                                  //   <Link
                                  //     id={p.courseId}
                                  //     onClick={(e) => {
                                  //       e.preventDefault();
                                  //       toggle(e, p);
                                  //     }}
                                  //   >
                                  //     {p.status == 1 ? "Archive" : "Unarchive"}
                                  //   </Link>
                                  // </p>
                                  <li>
                                    <p>
                                      <CourseArchiveModal
                                        header={p.status == 1 ? "Archive" : "Unarchive"}
                                        status={p.status}
                                        username={p.username}
                                        jwtToken={jwtToken}
                                        handle403error={handle403error}
                                        statusstate={statusstate}
                                        pageno={pageno}
                                        p={p}
                                        courseId={p.courseId}
                                        deleteCourseName={p.name}
                                        deleteCourse={p.id}
                                        deleteCourseCollege={p.institutionName}
                                      />
                                    </p>
                                  </li>
                                ) : null}

                              </ul>
                            </div>
                          </div>
                        ) : null}
                        {/* <Modal
                          isOpen={modal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                          // overlayClassName="Overlay"
                        >
                          <h5>
                            Are you sure you want to{" "}
                            {p.status == 1 ? "Archive" : "Unarchive"} current
                            record?
                          </h5>
                          <div style={{ float: "right" }}>
                            <Button
                              color="danger"
                              onClick={deleteCurrentCourse}
                            >
                              {p.status == 1 ? "Archive" : "Unarchive"}
                            </Button>{" "}
                            <Button color="secondary" onClick={closeModal}>
                              Cancel
                            </Button>
                          </div>
                        </Modal> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allcourses?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalCourse}{" "}
                        records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata || datefilter ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Courses;
