import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { Card, CardSubtitle, CardText, Col, Row } from 'reactstrap';
import axios from 'axios';
import base_url from '../../api/bootapi';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setdashboardcheck, selectUser, logout } from '../features/userSlice';
import { selectcourse, updatecourse } from '../features/courseSlice';
import '../css/Responsive.css';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
// import { Multiselect } from "multiselect-react-dropdown";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useStyles } from '../../Utilities/CSSUtilities';
import callsaveauditapi from '../../services/auditservice';
import { Button } from 'reactstrap';
import {
	getcoursepermission,
	check4pointpermission,
	check1pointpermission,
} from '../Permission/permission';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function EditCourses(props) {
	const [backdropopen, setbackdropopen] = useState();
	const user = useSelector(selectUser);
	let params = useParams();
	const history = useHistory();
	const course = useSelector(selectcourse);
	const { register, handleSubmit, errors } = useForm();
	const dispatch = useDispatch();
	const course_id = params.course_id;
	const particularCourse = [];
	course.map((item) => {
		if (item.courseId == course_id) {
			particularCourse.push(item);
		}
	});
	const jwtToken = 'Bearer ' + user.isw_user_token;
	const classes = useStyles();
	const editorRef = useRef();
	const ipaddress = localStorage.getItem('AdminIpAddress');
	const isw_user_name = localStorage.getItem('isw_user_name');
	const isw_adminId = localStorage.getItem('isw_adminId');
	const isw_user_email = localStorage.getItem('isw_user_email');

	const [stateCust, setstateCust] = useState({});
	const [courseBranchState, setcourseBranchState] = useState();
	const [selectedBranches, setselectedBranches] = useState();
	const [statusstate, setstatusstate] = useState(1);
	const [sortState, setsortState] = useState('institutionId');
	const [direction, setdirection] = useState('DESC');
	const [rows, setrows] = React.useState(50);

	useEffect(() => {
		// window.addEventListener("storage", function (e) {
		//   if (e.key === null) {
		//     dispatch(logout());
		//     history.push("/");
		//   }
		// });
		getPermissions();
	}, []);

	const [permissionData, setPermissionData] = useState([]);
	const [rolepermission, setrolepermission] = useState([]);

	const getPermissions = () => {
		const resource_obj = [];
		const permission_obj = [];
		for (let i = 0; i < user.isw_role_permissions.length; i++) {
			resource_obj.push(user.isw_role_permissions[i].resourceId);
		}
		for (let i = 0; i < user.isw_user_permissions.length; i++) {
			permission_obj.push(user.isw_user_permissions[i].permissionId);
		}
		setPermissionData(permission_obj);
		setrolepermission(resource_obj);

		if (check1pointpermission(903, permission_obj, resource_obj)) {
			let id = params.course_id;
			// if (available_permission){
			dispatch(setdashboardcheck(23));
			getCourseById();
			getArea();
			getCategory();
			getLevel();
			getIntake();
			getBranches();
			getvatfromadmin();
			getAllCollege(0, statusstate);
			// } else {
			//   history.push("/errorPage");
			// }
		} else {
			history.push('/errorPage');
		}
	};

	const [allcollege, setAllCollege] = useState();

	const getAllCollege = (value1, statusstate) => {
		setbackdropopen(true);
		axios
			.get(`${base_url.api2}/findAllInstitution`, {
				headers: {
					Authorization: jwtToken,
				},
			})
			.then(
				(response) => {
					console.log(response.data);
					var collegeList = response.data.data;
					setAllCollege(response.data.data);
					setbackdropopen(false);
				},
				(error) => {
					// handle403error(error);
					setbackdropopen(false);
				}
			);
	};

	const [vatvalue, setvatvalue] = useState([]);

	const getvatfromadmin = () => {
		let value = [];
		axios
			.get(`${base_url.api3}/getByConfigKey`, {
				headers: {
					configKey: 'vet',
				},
			})
			.then((res) => {
				value.push({
					name: 'VET',
					value: res.data.data.configValue,
				});
				axios
					.get(`${base_url.api3}/getByConfigKey`, {
						headers: {
							configKey: 'trimester/vet',
						},
					})
					.then((res) => {
						value.push({
							name: 'Trimester/VET',
							value: res.data.data.configValue,
						});
						axios
							.get(`${base_url.api3}/getByConfigKey`, {
								headers: {
									configKey: 'master/vet',
								},
							})
							.then((res) => {
								value.push({
									name: 'Master/VET',
									value: res.data.data.configValue,
								});
								axios
									.get(`${base_url.api3}/getByConfigKey`, {
										headers: {
											configKey: 'bachelor/vet',
										},
									})
									.then((res) => {
										value.push({
											name: 'Bachelor/VET',
											value: res.data.data.configValue,
										});
										setvatvalue(value);
									});
							});
					});
			})
			.catch((err) => handle403error(err));
	};

	const [allBranchProvider, setAllBranchProvider] = useState();
	const getBranches = () => {
		axios
			.get(`${base_url.api2}/findAllInstitutionBranches`, {
				headers: {
					Authorization: jwtToken,
				},
			})
			.then((response) => {
				console.log('Branches:', response.data);
				setAllBranchProvider(response.data.data);
			})
			.catch((error) => {
				handle403error(error);
			});
	};

	const [allIntake, setAllIntake] = useState(null);
	const getIntake = () => {
		axios
			.get(`${base_url.api1}/getAllIntake`, {
				headers: {
					Authorization: jwtToken,
				},
			})
			.then((response) => {
				const office_obj = [];

				for (let i = 0; i < response.data.data.length; i++) {
					let data = {
						value: response.data.data[i].name,
						label: response.data.data[i].name,
					};
					office_obj.push(data);
				}
				setAllIntake(office_obj);
			})
			.catch((error) => {
				handle403error(error);
			});
	};

	// const [selectedcollegeid, setselectedcollegeid] = useState(null);
	// const [selectedcollegename, setselectedcollegename] = useState(null);
	const [availableareaId, setAvailableareaId] = useState(null);
	const getArea = () => {
		axios
			.get(`${base_url.api1}/findAllArea`)
			.then((response) => {
				setAvailableareaId(response.data.data);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	const [availableCategory, setAvailableCategory] = useState(null);
	const getCategory = () => {
		axios
			.get(`${base_url.api1}/findAllCategory`, {
				headers: {
					Authorization: jwtToken,
				},
			})
			.then((response) => {
				setAvailableCategory(response.data.data);
			})
			.catch((error) => {
				console.log(JSON.stringify(error.response));
			});
	};

	const [availableLevel, setAvailableLevel] = useState(null);
	const getLevel = () => {
		axios
			.get(`${base_url.api1}/findAllLevel`)
			.then((response) => {
				setAvailableLevel(response.data.data);
			})
			.catch((error) => {
				console.log(JSON.stringify(error.response));
			});
	};
	const [intakeoptions, setintakeoptions] = useState();
	const getCourseById = () => {
		axios
			.get(`${base_url.api2}/getCourseById`, {
				headers: {
					Authorization: jwtToken,
					courseId: Number(params.course_id),
				},
			})
			.then((response) => {
				let coursedata = response.data.data;
				setcourseBranchState({
					branchId: coursedata.institutionId,
					branchName: coursedata.institutionName,
					courseId: coursedata.courseId,
					id: 0,
					state: coursedata.state,
					city: coursedata.city,
					country: coursedata.country,
				});
				setselectedBranches();
				setstateCust(response.data.data);
				var arr = coursedata.intake.split(',');
				let ofcData_obj = [];
				for (let i = 0; i < arr.length; i++) {
					let data = {
						value: arr[i],
						label: arr[i],
					};
					ofcData_obj.push(data);
				}
				setintakeoptions(ofcData_obj);
				setintakeState(arr);
			})
			.catch((error) => {
				handle403error(error);
			});
	};

	const inputEvent = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setstateCust((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const [overviewState, setoverviewState] = useState(null);
	const overviewChange = (event) => {
		setoverviewState(event);
	};
	const [objectiveChangeState, setobjectiveChangeState] = useState(null);
	const objectiveChange = (event) => {
		// console.log(event);
		setobjectiveChangeState(event);
	};
	const [courseStudyChangeState, setcourseStudyChangeState] = useState(null);
	const courseStudyChange = (event) => {
		// console.log(event);
		setcourseStudyChangeState(event);
	};
	const [requirementChangeState, setrequirementChangeState] = useState(null);
	const requirementChange = (event) => {
		// console.log(event);
		setrequirementChangeState(event);
	};
	const [paymentChangeState, setpaymentChangeState] = useState(null);
	const paymentChange = (event) => {
		// console.log(event);
		setpaymentChangeState(event);
	};
	const [contactChangeState, setcontactChangeState] = useState(null);
	const contactChange = (event) => {
		// console.log(event);
		setcontactChangeState(event);
	};

	const [intakeState, setintakeState] = useState();
	const onSelect = (selectedList, selectedItem) => {
		let ofcData_obj = [];
		for (let i = 0; i < selectedList.length; i++) {
			let data = {
				value: selectedList[i].value,
				label: selectedList[i].value,
			};
			ofcData_obj.push(data);
		}
		setintakeoptions(ofcData_obj);
		const client_obj = [];
		for (let i = 0; i < selectedList.length; i++) {
			let data = selectedList[i].value;
			client_obj.push(data);
		}
		setintakeState(client_obj);
	};
	const [intakeerror, setintakeerror] = useState(false);
	const putCourse = (e) => {
		if (!intakeState || intakeState.length === 0) {
			setintakeerror(true);
			return;
		}
		setintakeerror(false);
		if (stateCust.commission == '') {
			stateCust.commission = 0;
		}
		const isoDateString = new Date().toISOString();
		const datenow = isoDateString.split('.')[0];
		stateCust.updatedOn = datenow;
		stateCust.courseOverview = overviewState;
		stateCust.courseObjective = objectiveChangeState;
		stateCust.courseWhatWillYouStudy = courseStudyChangeState;
		stateCust.requirement = requirementChangeState;
		stateCust.paymentSummary = paymentChangeState;
		stateCust.contactDetail = contactChangeState;
		stateCust.createdBy = stateCust.createdBy;
		stateCust.updatedBy = isw_user_name + ' (' + isw_user_email + ')';
		if (selectedBranches) {
			stateCust.institutionId = Number(selectedBranches[0].institutionId);
			stateCust.institutionName = selectedBranches[0].name;
			stateCust.city = selectedBranches[0].city;
			stateCust.state = selectedBranches[0].state;
			stateCust.country = selectedBranches[0].country;
		}
		if (intakeState) {
			stateCust.intake = intakeState.join(',');
		}
		axios
			.post(`${base_url.api2}/updateCourseById`, stateCust, {
				headers: {
					Authorization: jwtToken,
				},
			})
			.then(
				(response) => {
					// console.log(response);
					dispatch(
						updatecourse({
							id: course_id,
							data: response.data.data,
						})
					);
					let log =
						isw_user_name +
						' Updated Course ' +
						stateCust.name +
						' of Provider ' +
						stateCust.institutionName +
						'.';
					callsaveauditapi(log);

					toast.success('Success');
					history.push('/course');
				},
				(error) => {
					toast.error(JSON.stringify(error.response.data.message));
					handle403error(error);
				}
			);
	};

	const goback = () => {
		history.push('/course');
		// history.goBack();
	};

	const handle403error = (err) => {
		if (err) {
			if (err.response) {
				if (Number(err?.response?.status) === 403) {
					setTimeout(() => {
						dispatch(logout());
						localStorage.clear();
						localStorage.setItem('from_403_status', 'call_toast');
						history.push('/');
						toast.warn('Session Expired');
					}, 1000);
				}
			}
		}
	};

	return (
		<div>
			<ToastContainer />
			<Backdrop className={classes.backdrop} open={backdropopen}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <Card className="commoncomponent_cardcss"> */}
			<div
				class="page-head d-flex justify-content-between p-4"
				style={{ marginTop: '4.3rem', marginBottom: '7px' }}
			>
				<ul class="breadcrums">
					<h3 class="page-title bold">Manage Course</h3>
					{/* <a href="index.html" class="b-link">Home</a>
          -
          <a href="workload-dashboard.html" class="b-link">Workload</a>
          -
          <a href="#" class="b-link active">Skill Assessment</a> */}
				</ul>
				<a onClick={goback} class="back-btn">
					Back
				</a>
			</div>
			<CardSubtitle className="font-weight-bold"></CardSubtitle>
			<form
				onSubmit={handleSubmit(putCourse)}
				style={{
					padding: '1.5rem',
					border: '1.5rem solid #e9ebf2',
					marginBottom: '0',
				}}
			>
				<div className="form-inner-admin">
					<Row>
						<Col md={6}>
							<CardText className={classes.headtext} style={{ float: 'left' }}>
								<strong>Course</strong>
							</CardText>
						</Col>
						{/* <Col md={6} className="d-flex justify-content-end">
                <Button className="btn listing_addbutton" onClick={goback}>
                  &#x2630; List
                </Button>
              </Col> */}
					</Row>
					<div className="form-group creatadmin">
						<Row>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Course Code:</label>
								<input
									type="text"
									className="form-control underline-input"
									onChange={inputEvent}
									name="courseCode"
									value={stateCust.courseCode}
									style={{ backgroundColor: '#f5f5f9' }}
								// readOnly
								/>
								{/* {errors.courseCode && (
                    <p className="errormessage">CourseCode is Required</p>
                  )} */}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Name<span style={{ color: 'red' }}>*</span>:
								</label>
								<input
									type="text"
									className="form-control underline-input"
									onChange={inputEvent}
									name="name"
									value={stateCust.name}
									style={{ backgroundColor: '#f5f5f9' }}
									ref={register({ required: true })}
								/>
								{errors.name && (
									<p className="errormessage">Name is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Cricos<span style={{ color: 'red' }}>*</span>:
								</label>
								<input
									type="text"
									className="form-control underline-input"
									onChange={inputEvent}
									name="cricos"
									value={stateCust.cricos}
									style={{ backgroundColor: '#f5f5f9' }}
									ref={register({ required: true })}
								/>
								{errors.cricos && (
									<p className="errormessage">Cricos is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Total No. of Units:
								</label>
								<input
									ref={register}
									type="number"
									style={{ textAlign: 'right', backgroundColor: '#f5f5f9' }}
									onWheel={(event) => event.currentTarget.blur()}
									className="form-control underline-input"
									placeholder="0"
									value={stateCust.numberOfUnit}
									name="numberOfUnit"
									onChange={inputEvent}
								/>
							</Col>
						</Row>
					</div>
					<div className="form-group creatadmin">
						<Row>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Onshore Fee (Domestic)
									<span style={{ color: 'red' }}>*</span>:
								</label>
								<input
									type="number"
									style={{ textAlign: 'right', backgroundColor: '#f5f5f9' }}
									onWheel={(event) => event.currentTarget.blur()}
									className="form-control underline-input"
									onChange={inputEvent}
									name="fees"
									value={stateCust.fees}
									ref={register({ required: true })}
								/>
								{errors.fees && (
									<p className="errormessage">Fees is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Offshore Fee (International)
									<span style={{ color: 'red' }}>*</span>:
								</label>
								<input
									type="number"
									style={{ textAlign: 'right', backgroundColor: '#f5f5f9' }}
									onWheel={(event) => event.currentTarget.blur()}
									className="form-control underline-input"
									onChange={inputEvent}
									name="offShoreFees"
									value={stateCust.offShoreFees}
									ref={register({ required: true })}
								/>
								{errors.offShoreFees && (
									<p className="errormessage">Offshore Fees is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>App Fees:</label>
								<input
									type="number"
									style={{ textAlign: 'right', backgroundColor: '#f5f5f9' }}
									onWheel={(event) => event.currentTarget.blur()}
									className="form-control underline-input"
									onChange={inputEvent}
									name="appFees"
									value={stateCust.appFees}
									ref={register}
								/>
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Material Fees:</label>
								<input
									type="number"
									style={{ textAlign: 'right', backgroundColor: '#f5f5f9' }}
									onWheel={(event) => event.currentTarget.blur()}
									className="form-control underline-input"
									onChange={inputEvent}
									placeholder="10000"
									name="materialFees"
									ref={register}
									value={stateCust.materialFees}
								/>
							</Col>
						</Row>
					</div>
					<div className="form-group creatadmin">
						<Row>
							<Col md={6}>
								<label style={{ fontWeight: 'bold' }}>
									Provider<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									value={courseBranchState?.branchName}
									className={classes.selectdrop}
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={(event) => {
										const index = event.target.selectedIndex;
										const optionElement = event.target.childNodes[index];
										const optionElementId = optionElement.getAttribute('id');
										const optionElementName =
											optionElement.getAttribute('value');
										const optionElementstate =
											optionElement.getAttribute('state');
										const optionElementcity =
											optionElement.getAttribute('city');
										const optionElementcountry =
											optionElement.getAttribute('country');
										let data = {
											institutionId: Number(optionElementId),
											name: optionElementName,
											courseId: stateCust?.courseId,
											id: 0,
											state: optionElementstate,
											city: optionElementcity,
											country: optionElementcountry,
										};
										setcourseBranchState([data]);
										setselectedBranches([data]);
									}}
									required
								>
									<option value="">- Select College -</option>
									{allcollege?.map((cuntitem, index) => (
										<option
											key={index}
											value={cuntitem.institutionName}
											id={cuntitem.institutionId}
											state={cuntitem.state}
										>
											{cuntitem.institutionName}
										</option>
									))}
								</select>
								{/* <Multiselect
                      options={allBranchProvider}
                      selectedValues={courseBranchState} // Preselected value to persist in dropdown
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="branchName"
                      required
                    /> */}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Other Fees:</label>
								<input
									type="number"
									style={{ textAlign: 'right', backgroundColor: '#f5f5f9' }}
									onWheel={(event) => event.currentTarget.blur()}
									className="form-control underline-input"
									onChange={inputEvent}
									placeholder="10000"
									ref={register}
									name="otherFees"
									value={stateCust.otherFees}
								/>
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Category<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									className={classes.selectdrop}
									style={{
										border: '1px solid #ced4da',
										borderRadius: '.25rem',
										backgroundColor: '#f5f5f9',
									}}
									defaultValue={stateCust.categoryName}
									value={stateCust.categoryName}
									onChange={(e) => {
										const index = e.target.selectedIndex;
										const optionElement = e.target.childNodes[index];
										const optionElementId = optionElement.getAttribute('id');
										const optionElementValue =
											optionElement.getAttribute('value');
										stateCust.categoryId = optionElementId;
										stateCust.categoryName = optionElementValue;
										inputEvent(e);
									}}
									name="category"
									ref={register({ required: true })}
								>
									{availableCategory?.map((e) => (
										<option
											key={e.category_id}
											value={e.name}
											id={e.category_id}
										>
											{e.name}
										</option>
									))}
								</select>
								{errors.category && (
									<p className="errormessage">Category is Required</p>
								)}
							</Col>
						</Row>
					</div>
					<div className="form-group creatadmin">
						<Row>
							<Col md={6}>
								<label style={{ fontWeight: 'bold' }}>
									Intake<span style={{ color: 'red' }}>*</span>:
								</label>
								<br />
								<Select
									value={intakeoptions}
									isMulti
									name="intake"
									options={allIntake}
									className="basic-multi-select"
									classNamePrefix="select"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={onSelect}
								/>
								{intakeerror && (
									<p className="errormessage">Intake is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Level<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									className={classes.selectdrop}
									style={{
										border: '1px solid #ced4da',
										borderRadius: '.25rem',
										backgroundColor: '#f5f5f9',
									}}
									defaultValue={stateCust.levelName}
									value={stateCust.levelName}
									onChange={(e) => {
										const index = e.target.selectedIndex;
										const optionElement = e.target.childNodes[index];
										const optionElementId = optionElement.getAttribute('id');
										const optionElementValue =
											optionElement.getAttribute('value');
										stateCust.levelId = optionElementId;
										stateCust.levelName = optionElementValue;
										inputEvent(e);
									}}
									name="level"
									ref={register({ required: true })}
								>
									{availableLevel?.map((e) => (
										<option key={e.level_id} value={e.name} id={e.level_id}>
											{e.name}
										</option>
									))}
								</select>
								{errors.level && (
									<p className="errormessage">Level is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Area<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									defaultValue={stateCust.areaName}
									value={stateCust.areaName}
									className={classes.selectdrop}
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={(e) => {
										const index = e.target.selectedIndex;
										const optionElement = e.target.childNodes[index];
										const optionElementId = optionElement.getAttribute('id');
										const optionElementValue =
											optionElement.getAttribute('value');
										stateCust.areaId = optionElementId;
										stateCust.areaName = optionElementValue;
										inputEvent(e);
									}}
									name="area"
									ref={register({ required: true })}
								>
									{availableareaId?.map((e) => (
										<option key={e.area_id} value={e.name} id={e.area_id}>
											{e.name}
										</option>
									))}
								</select>
								{errors.area && (
									<p className="errormessage">Area is Required</p>
								)}
							</Col>
						</Row>
					</div>
					<div className="form-group creatadmin">
						<Row>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Duration<span style={{ color: 'red' }}>*</span>:
								</label>
								<input
									type="text"
									className="form-control underline-input"
									onChange={inputEvent}
									name="duration"
									value={stateCust.duration}
									style={{ backgroundColor: '#f5f5f9' }}
									ref={register({ required: true })}
								/>
								{errors.duration && (
									<p className="errormessage">Duration is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Atar:</label>
								<input
									type="text"
									className="form-control underline-input"
									onChange={inputEvent}
									name="atar"
									value={stateCust.atar}
									style={{ backgroundColor: '#f5f5f9' }}
									ref={register}
								/>
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>UAC:</label>
								<input
									type="text"
									className="form-control underline-input"
									onChange={inputEvent}
									name="uac"
									style={{ backgroundColor: '#f5f5f9' }}
									value={stateCust.uac}
									ref={register}
								/>
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Type:</label>
								<select
									defaultValue={stateCust.type}
									value={stateCust.type}
									className={classes.selectdrop}
									name="type"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register}
								>
									<option value="flexible">Flexible</option>
									<option value="scholarship">Scholarship</option>
								</select>
							</Col>
						</Row>
					</div>
					<div className="form-group creatadmin">
						<Row>
							<Col md={6}>
								<label style={{ fontWeight: 'bold' }}>Remark:</label>
								<textarea
									ref={register}
									value={stateCust.comment}
									onChange={inputEvent}
									style={{ backgroundColor: '#f5f5f9' }}
									className="form-control underline-input"
									name="comment"
								/>
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Status<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									defaultValue={stateCust.status == 1 ? 'Active' : 'InActive'}
									value={stateCust.status}
									className={classes.selectdrop}
									name="status"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register({ required: true })}
								>
									<option value="1">Active</option>
									<option value="0">Inactive</option>
								</select>
								{errors.status && (
									<p className="errormessage">Status is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Semister:</label>
								<select
									value={stateCust.semester}
									className={classes.selectdrop}
									name="semester"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register}
								>
									<option value="">-Select Semister-</option>
									{vatvalue.map((p, index) => (
										<option key={index + 1} value={p.value}>
											{p.value}-{p.name}
										</option>
									))}
								</select>
							</Col>
						</Row>
					</div>
					<div className="form-group creatadmin">
						<Row>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Commission(%):</label>
								<input
									type="number"
									step="0.001"
									className="form-control underline-input"
									name="commission"
									value={stateCust.commission}
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register}
								/>
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Published<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									defaultValue={
										stateCust.published == 1 ? 'Active' : 'InActive'
									}
									value={stateCust.published}
									className="form-control"
									name="published"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register({ required: true })}
								>
									<option value="1">Active</option>
									<option value="0">Inactive</option>
								</select>
								{errors.published && (
									<p className="errormessage">Published is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>
									Popular<span style={{ color: 'red' }}>*</span>:
								</label>
								<select
									defaultValue={stateCust.popular == 1 ? 'Active' : 'InActive'}
									value={stateCust.popular}
									className="form-control"
									name="popular"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register({ required: true })}
								>
									<option value="1">Active</option>
									<option value="0">Inactive</option>
								</select>
								{errors.popular && (
									<p className="errormessage">Popular is Required</p>
								)}
							</Col>
							<Col md={3}>
								<label style={{ fontWeight: 'bold' }}>Rating:</label>
								<select
									className="form-control"
									value={stateCust?.courseRating}
									name="courseRating"
									style={{ backgroundColor: '#f5f5f9' }}
									onChange={inputEvent}
									ref={register}
								>
									<option value=""> - Select Rating - </option>
									<option value="0.5">0.5</option>
									<option value="1">01</option>
									<option value="1.5">1.5</option>
									<option value="2">02</option>
									<option value="2.5">2.5</option>
									<option value="3">03</option>
									<option value="3.5">3.5</option>
									<option value="4">04</option>
									<option value="4.5">4.5</option>
									<option value="5">05</option>
								</select>
							</Col>
						</Row>
					</div>
					<div style={{ margin: '40px 0' }}>
						<ul className="nav nav-tabs ">
							<li className="nav-item ">
								<a
									className="nav-link active"
									data-toggle="tab"
									href="#courseoverview"
									style={{ backgroundColor: '#f5f5f9' }}
								>
									Course Overview
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-toggle="tab" href="#coursestudy">
									Course What Will Study
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-toggle="tab" href="#payment">
									Payment Summary
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									data-toggle="tab"
									href="#courseobjective"
									style={{ backgroundColor: '#f5f5f9' }}
								>
									Course Objective
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-toggle="tab" href="#contact">
									Contact Detail
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-toggle="tab" href="#requirement">
									Requirement
								</a>
							</li>
						</ul>
						<div
							className="tab-content"
							style={{ border: '1px solid  #dee2e6' }}
						>
							<div id="courseoverview" className="container tab-pane active">
								<br />
								<Col md={12}>
									<SunEditor
										ref={editorRef}
										setContents={stateCust.courseOverview}
										height="200"
										onChange={overviewChange}
										style={{ backgroundColor: '#f5f5f9' }}
									/>
								</Col>
							</div>
							<div id="coursestudy" className="container tab-pane fade">
								<br />
								<Col md={12}>
									<SunEditor
										ref={editorRef}
										setContents={stateCust.courseWhatWillYouStudy}
										height="200"
										placeholder="Please type here..."
										onChange={courseStudyChange}
										style={{ backgroundColor: '#f5f5f9' }}
										// onImageUploadBefore={handleImageUploadBefore}
										required
									/>
								</Col>
							</div>
							<div id="payment" className="container tab-pane fade">
								<br />
								<Col md={12}>
									<SunEditor
										ref={editorRef}
										setContents={stateCust.paymentSummary}
										height="200"
										placeholder="Please type here..."
										onChange={paymentChange}
										style={{ backgroundColor: '#f5f5f9' }}
										// onImageUploadBefore={handleImageUploadBefore}
										required
									/>
								</Col>
							</div>
							<div id="courseobjective" className="container tab-pane fade">
								<br />
								<Col md={12}>
									<SunEditor
										ref={editorRef}
										setContents={stateCust.courseObjective}
										height="200"
										placeholder="Please type here..."
										onChange={objectiveChange}
										style={{ backgroundColor: '#f5f5f9' }}
									/>
								</Col>
							</div>
							<div id="contact" className="container tab-pane fade">
								<br />
								<Col md={12}>
									<SunEditor
										ref={editorRef}
										setContents={stateCust.contactDetail}
										height="200"
										placeholder="Please type here..."
										onChange={contactChange}
										style={{ backgroundColor: '#f5f5f9' }}
										// onImageUploadBefore={handleImageUploadBefore}
										required
									/>
								</Col>
							</div>
							<div id="requirement" className="container tab-pane fade">
								<br />
								<Col md={12}>
									<SunEditor
										ref={editorRef}
										setContents={stateCust.requirement}
										height="200"
										placeholder="Please type here..."
										onChange={requirementChange}
										// onImageUploadBefore={handleImageUploadBefore}
										required
									/>
								</Col>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end">
					<button type="submit" className="btn listing_addbutton">
						Update
					</button>
				</div>
			</form>
			{/* </Card> */}
		</div>
	);
}

export default withRouter(EditCourses);
