import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast, ToastContainer } from "react-toastify";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import { useStyles } from "../../Utilities/CSSUtilities";

function LeadsReports(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  //   console.log(alladmin);
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [leadTypeState, setleadTypeState] = useState("Archive");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const datepast = moment().subtract(3, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [backdropopen, setbackdropopen] = useState(true);
  const [officeid, setofficeid] = useState();

  const [admissionEnquiry, setadmissionEnquiry] = useState();
  const [admissionEnquiryPageNo, setadmissionEnquiryPageNo] = useState();
  const [permissionData, setPermissionData] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [admissionEnquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    var available_resource = resource_obj.includes(50);
    var available_permission = permission_obj.includes(417);
    if (available_resource || available_permission) {
      getWorkflowReports(pageno);
      getOffice();
      dispatch(setdashboardcheck(50));
      getConsltant();
    } else {
      history.push("/errorPage");
    }
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = (p) => {
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then(
        (res) => {
          let apiData = res.data.data;
          const consultant_obj = [];
          for (let i = 0; i < apiData.length; i++) {
            let data = {
              value: apiData[i].id,
              label: apiData[i].firstName + " " + apiData[i].lastName,
            };
            consultant_obj.push(data);
          }
          setAssigneeData(consultant_obj);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const handlePageLead = (event, value) => {
    // setpageno(value - 1);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "assigneeName") {
      sorting = "assignee_name";
    } else if (sortState == "interestedServices") {
      sorting = "interested_services";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "country") {
      sorting = "country";
    } else {
      sorting = "id";
    }
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      if (leadState) {
        axios
          .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              leadType: leadTypeState,
              columnName: sorting,
              assigneeId: leadData,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
          })
          .catch((error) => {
            handle403error(error);
          });
      } else {
        axios
          .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              leadType: leadTypeState,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    } else {
      if (leadState) {
        axios
          .post(
            `${base_url.api4}/getLeadReportByLeadTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                leadType: leadTypeState,
                officeId: Number(officeid),
                columnName: sorting,
                assigneeId: leadData,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
          })
          .catch((error) => {
            handle403error(error);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getLeadReportByLeadTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                leadType: leadTypeState,
                officeId: Number(officeid),
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    }
  };

  const getWorkflowReports = (value1) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      axios
        .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            leadType: leadTypeState,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      axios
        .post(`${base_url.api4}/getLeadReportByLeadTypeAndOfficeId`, dateMap, {
          headers: {
            Authorization: jwtToken,
            leadType: leadTypeState,
            officeId: Number(user.isw_available_Office[0].officeId),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const handleOffice = (event) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    setofficeid(event.target.value);
    if (event.target.value === "all") {
      axios
        .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            leadType: leadTypeState,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      axios
        .post(`${base_url.api4}/getLeadReportByLeadTypeAndOfficeId`, dateMap, {
          headers: {
            Authorization: jwtToken,
            leadType: leadTypeState,
            officeId: Number(event.target.value),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const handleLeadType = (event) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    if (officeid === undefined) {
      axios
        .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            leadType: event.target.value,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      axios
        .post(`${base_url.api4}/getLeadReportByLeadTypeAndOfficeId`, dateMap, {
          headers: {
            Authorization: jwtToken,
            leadType: event.target.value,
            officeId: Number(officeid),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const exportPdfLead = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Lead Details",
      "Address",
      "Assignee",
      "Service",
      "Office",
      "Lead Type",
      "Session",
    ];
    var rows = [];
    admissionEnquiry?.forEach((element) => {
      var temp = [
        element.firstName +
        " " +
        element.lastName +
        "\n" +
        element.email +
        "\n" +
        "Phone: " +
        element.phone,
        "Country: " + element.country + "\n" + "City: " + element.city,
        element.assigneeName,
        element.interestedServices,
        element.officeName,
        element.leadType,
        "SST: " +
        moment(element.startTime).format("DD/MM/YYYY hh:mm:ss") +
        "\n" +
        "SET: " +
        moment(element.endTime).format("DD/MM/YYYY hh:mm:ss") +
        "\n" +
        "Total: " +
        element.totalTime,
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    // doc.autoTable(col, rows, { pageBreak: 'auto' });
    doc.save("Leads_Reports.pdf");
  };

  const [leadState, setLeadState] = useState();
  const [leadData, setLeadData] = useState();
  const onSelectLead = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "assigneeName") {
      sorting = "assignee_name";
    } else if (sortState == "interestedServices") {
      sorting = "interested_services";
    } else if (sortState == "enquiryStatus") {
      sorting = "enquiry_status";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "country") {
      sorting = "country";
    } else {
      sorting = "id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    setLeadData(consultant_obj);
    if (consultant_obj.length > 0) {
      setLeadState(true);
      if (officeid === "all" || officeid == undefined) {
        axios
          .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              leadType: leadTypeState,
              columnName: sorting,
              assigneeId: consultant_obj.toString(),
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          })
          .catch((error) => {
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        axios
          .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              leadType: leadTypeState,
              columnName: sorting,
              assigneeId: consultant_obj.toString(),
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          })
          .catch((error) => {
            setbackdropopen(false);
            handle403error(error);
          });
      }
    } else {
      setLeadState(false);
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        axios
          .post(`${base_url.api4}/getLeadReportByLeadType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              leadType: leadTypeState,
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          })
          .catch((error) => {
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getLeadReportByLeadTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                leadType: leadTypeState,
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          })
          .catch((error) => {
            setbackdropopen(false);
            handle403error(error);
          });
      }
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={4}>
              <div style={{ float: "left" }} className="maindashboard_select">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Select Branch
                </Typography>
                <FormControl
                  // className={classes.formControl}
                  className="office_dropdown"
                >
                  <br />
                  <select
                    native
                    value={officeid}
                    id="demo-simple-select3"
                    style={{
                      height: "38px",
                      borderRadius: "5px",
                      borderColor: "#cccaca",
                    }}
                    onChange={(e) => handleOffice(e)}
                  >
                    {/* <option aria-label="None" value="" /> */}
                    {user.isw_user_role === 1 || user.isw_user_role === 6 ? (
                      <>
                        <option className="option_text" value="all">
                          Show All
                        </option>
                        {availableoffice?.map((p, index) => (
                          <option
                            className="option_text"
                            key={index}
                            value={p.officeId}
                          >{`${p.officeName}-${p.city}-${p.country}`}</option>
                        ))}
                      </>
                    ) : (
                      <>
                        {user.isw_available_Office?.map((p, index) => (
                          <option
                            key={index}
                            value={p.officeId}
                          >{`${p.country}-${p.city}-${p.officeName}`}</option>
                        ))}
                      </>
                    )}
                  </select>
                </FormControl>
              </div>
            </Col>
            <Col md={6}>
              <div
                style={{ float: "left", width: "100%" }}
                className="maindashboard_select"
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Select Consultant
                </Typography>
                <FormControl
                  // className={classes.formControl}
                  className="office_dropdown"
                  style={{ width: "100%" }}
                >
                  <br />
                  <Select
                    isMulti
                    name="assigneeId"
                    options={assigneeData}
                    // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onSelectLead}
                    required
                  />
                </FormControl>
              </div>
            </Col>
            <Col md={2}>
              <div style={{ float: "right" }} className="maindashboard_select">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Select Lead Type
                </Typography>
                <FormControl
                  // className={classes.formControl}
                  className="office_dropdown"
                >
                  <br />
                  <select
                    native
                    id="demo-simple-select3"
                    onChange={(e) => handleLeadType(e)}
                  >
                    <option value="Archive">Archive</option>
                    <option value="HotLead">Hot Lead</option>
                    <option value="Converted">Converted Lead</option>
                    <option value="ColdLead">Cold Lead</option>
                    <option value="WarmLead">Warm Lead</option>
                  </select>
                </FormControl>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      <br />
      <Card className="responsive_client_application">
        <CardBody>
          <CardSubtitle className="font-weight-bold"></CardSubtitle>
          <div>
            <Row>
              <Col md={6}>
                <CardText className={classes.headtext}>
                  <strong>Leads Reports</strong>
                </CardText>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                  <Col md={3}>
                    {permissionData?.includes(418) ? (
                      <button
                        className="btn listing_addbutton"
                        onClick={exportPdfLead}
                      >
                        Export PDF
                      </button>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    {admissionEnquiry != null ? (
                      <>
                        {permissionData?.includes(419) ? (
                          <CSVLink
                            filename="Leads-Reports.csv"
                            data={admissionEnquiry}
                            className="btn listing_addbutton"
                          // target="_blank"
                          >
                            Export CSV
                          </CSVLink>
                        ) : null}
                      </>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="form-group">
              <Row>
                <Col md={4}>
                  {/* <form
                    style={{ display: "flex" }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitrows(e);
                    }}
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        No. of Rows
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                        style={{border:"2px solid black", paddingTop:"5px"}}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      style={{
                        height: "40px",
                        position: "absolute",
                        left: "150px",
                        bottom: "10px",
                      }}
                      color="primary"
                      type="submit"
                    >
                      Filter
                    </Button>
                  </form>
                  <br /> */}
                  {/* -------------------------------------------------------- */}
                  {/* <label>
                    <strong>Date:</strong>
                  </label>
                  <DateRangePicker
                    onEvent={handleEvent}
                    onCallback={handleCallback}
                    onApply={handleApply}
                    initialSettings={{
                      startDate: { datepast },
                      endDate: { datenow },
                    }}
                  >
                    <input style={{ width: "50%" }} type="text" />
                  </DateRangePicker>
                  <br />
                  {datefilter ? (
                    <div style={{ float: "left" }}>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdirection("DESC");
                          setdirection("DESC");
                          getWorkflowReports(0);
                        }}
                      >
                        Clear Search
                      </a>
                    </div>
                  ) : null} */}
                  {/* -------------------------------------------------------- */}
                </Col>
                <Col md={4}></Col>
                {/* <Col md={4}>
                  <form
                    id="myForm"
                    className={classes.srch}
                    onSubmit={(e) => {
                      e.preventDefault();
                      submit(e);
                    }}
                  >
                    <input
                      style={{ padding: "2px" }}
                      type="text"
                      placeholder="Search.."
                      name="search"
                    />
                    <button className={classes.srchbtn} type="submit">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                  <br/><br/>
                  {searchdata ? (
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setdisplay("inline");
                        setdirection("DESC");
                        setdirection("DESC");
                        getWorkflowReports(0);
                      }}
                    >
                      Clear Search
                    </a>
                  ) : null}
                </Col> */}
              </Row>
            </div>
            <Row>
              <Col md={6}>
                {admissionEnquiry?.length ? (
                  <p style={{ marginTop: "15px" }}>
                    {countnumber * rows + 1} to{" "}
                    {countnumber * rows + numberofElements} of {totalUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
            </Row>
            <div className="above-scroller" onScroll={scrolldiv}>
              <div className="scroller"></div>
            </div>
            <div className="displaytablerow">
              <Table responsive striped onScroll={scrolltable}>
                <thead>
                  <tr>
                    {/* <th>Id</th> */}
                    <th style={{ width: "50px", textAlign: "center" }}>SN </th>
                    <th style={{ width: "50px", textAlign: "center" }}>LID </th>
                    <th style={{ minWidth: "160px" }}>Lead Name </th>
                    <th style={{ minWidth: "160px" }}>Consultant </th>
                    <th style={{ minWidth: "160px" }}>Service </th>
                    <th style={{ minWidth: "140px" }}>Enquiry Status </th>
                    <th style={{ minWidth: "100px" }}>Office </th>
                    <th style={{ minWidth: "160px" }}>Country </th>
                    <th style={{ minWidth: "100px" }}>Lead Type </th>
                    <th style={{ minWidth: "165px" }}>Session Start Time </th>
                    <th style={{ minWidth: "160px" }}>Session End Time </th>
                    <th style={{ minWidth: "140px" }}>Created </th>
                    <th style={{ minWidth: "140px" }}>Updated </th>
                  </tr>
                </thead>
                <tbody>
                  {admissionEnquiry != null
                    ? admissionEnquiry &&
                    admissionEnquiry.map((p, index) => (
                      <tr key={p.id}>
                        {/* <td>{p.id}</td> */}
                        <td style={{ width: "50px", textAlign: "center" }}>
                          {countnumber * rows + 1 + index}
                        </td>
                        <td style={{ width: "50px", textAlign: "center" }}>
                          {p.id}
                        </td>
                        <td>
                          {p.firstName} {p.lastName}
                          <br />
                          {p.email}
                        </td>
                        <td>{p.assigneeName}</td>
                        <td>{p.interestedServices}</td>
                        <td>{p.enquiryStatus}</td>
                        <td>{p.officeName}</td>
                        <td>
                          {p.country}
                          <br />
                          City: {p.city}
                        </td>
                        <td>{p.leadType}</td>
                        <td>
                          {p.startTime
                            ? moment(p.startTime.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                            : null}
                        </td>
                        <td>
                          {p.endTime
                            ? moment(p.endTime.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                            : null}
                        </td>
                        <td>
                          {p.addedOn
                            ? moment(p.addedOn.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                            : null}
                        </td>
                        <td>
                          {p.updatedOn
                            ? moment(p.updatedOn.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                            : null}
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </Table>
            </div>
          </div>
          <Row>
            <Col md={6}>
              {admissionEnquiry?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  count={admissionEnquiryPageNo}
                  onChange={handlePageLead}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default withRouter(LeadsReports);
