





// *****************************************************
// Define configurations for different environments
const configurations = {
  frontend: {
    api1: "https://api.edupilot.com.au/module1",
    api2: "https://api.edupilot.com.au/module2",
    api3: "https://api.edupilot.com.au/module3",
    api4: "https://api.edupilot.com.au/module4",
  },
  production: {
    api1: "https://api.nepcoms.com/module1",
    api2: "https://api.nepcoms.com/module2",
    api3: "https://api.nepcoms.com/module3",
    api4: "https://api.nepcoms.com/module4",
  },
  test: {
    api1: "https://testcrm.nepcoms.com/module1",
    api2: "https://testcrm.nepcoms.com/module2",
    api3: "https://testcrm.nepcoms.com/module3",
    api4: "https://testcrm.nepcoms.com/module4",
  },
  branch: {
    api1: "https://branch.nepcoms.com/mongolia-module1",
    api2: "https://branch.nepcoms.com/mongolia-module2",
    api3: "https://branch.nepcoms.com/mongolia-module3",
    api4: "https://branch.nepcoms.com/mongolia-module4",
  },
};

// Set the environment mode
const environment = process.env.REACT_APP_ENVIRONMENT || "frontend";

// Select the configuration based on the environment
const base_url = configurations[environment];

export default base_url;
