import React, { useEffect, useState } from "react";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useHistory, useParams } from "react-router-dom";
import "../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import "../css/Responsive.css";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { logout, selectUser } from "../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

const nodes = [
  {
    value: 0,
    label: "Dashboard",
    children: [
      { value: 1, label: "Welcome Page" },
      { value: 2, label: "Client Count" },
      { value: 3, label: "My Appointment" },
      { value: 4, label: "User Count" },
      { value: 5, label: "Top 5 Providers and Courses" },
      { value: 6, label: "Admission Application by Workflow Stages" },
      { value: 7, label: "Asmission User Count Enquiry" },
      { value: 8, label: "Follow up" },
      { value: 9, label: "Application Reminders" },
      { value: 10, label: "UserCount Client Enquiry" },
      { value: 11, label: "Overdue Invoice" },
    ]
  },
  {
    value: 100,
    label: "Mail",
    children: [
      { value: 101, label: "Received" },
      { value: 102, label: "Sent" },
    ],
  },
  {
    value: 200,
    label: "Lead",
    children: [
      { value: 201, label: "View Lead List" },
      { value: 202, label: "Export CSV Lead." },
    ]
  },
  {
    value: 300,
    label: "Client",
    children: [
      { value: 301, label: "View Client List" },
      { value: 341, label: "Export CSV Clients." },
      { value: 302, label: "Create New Client." },
      { value: 303, label: "Edit Client." },
      { value: 304, label: "Change Password of Client." },
      { value: 305, label: "Archive/Unarchive Client." },
      { value: 306, label: "View Client Application Details." },
      { value: 307, label: "Add Note for Client." },
      { value: 308, label: "Edit Client Notes." },
      { value: 309, label: "Edit Workload Notes." },
      { value: 310, label: "Upload Document of Client." },
      { value: 311, label: "View/Download Client Documents." },
      { value: 312, label: "Delete Client Documents." },
      {
        value: 313,
        label: "Manage Client Application.",
        children: [
          { value: 314, label: "Add Application for Client." },
          { value: 315, label: "View Client Application." },
          { value: 342, label: "Change Client Email." },
          { value: 316, label: "Update Steps of Client Application." },
          { value: 317, label: "Send Email in Client Application." },
          { value: 318, label: "Add Notes in Client Application." },
          { value: 319, label: "View Notes in Client Application." },
          { value: 320, label: "Edit Notes in Client Application." },
          { value: 321, label: "Upload Attachment in Client Application." },
          { value: 322, label: "Rename Attachment in Client Application." },
          {
            value: 323,
            label: "View/Download Attachment in Client Application.",
          },
          { value: 324, label: "Delete Attachment in Client Application." },
          { value: 325, label: "Add Appointment in Client Application." },
          { value: 326, label: "Update Client Application Status." },
          {
            value: 327,
            label: "Update Client Application Student ID or Policy No.",
          },
          {
            value: 328,
            label: "Update Client Application Course Start Date.",
          },
          {
            value: 329,
            label: "Update Client Application Course End Date.",
          },
          {
            value: 330,
            label: "Update Client Application Policy Start Date.",
          },
          {
            value: 331,
            label: "Update Client Application Policy End Date.",
          },
          {
            value: 332,
            label: "Update Unit Exemption in Client Application.",
          },
          { value: 333, label: "Update SubAgent in Client Application." },
          { value: 334, label: "Update SuperAgent in Client Application." },
          {
            value: 335,
            label: "Update FollowUp Date in Client Application.",
          },
          { value: 336, label: "Add Remarks in Client Application." },
          {
            value: 337,
            label: "Update Applied Intake in Client Application.",
          },
          {
            value: 338,
            label: "View Payment Schedule in Client Application.",
          },
        ],
      },
      { value: 339, label: "Create Invoice for Client." },
      { value: 340, label: "View Invoice of Client." },
      { value: 343, label: "View EnquiryWorkFlow Admission." },
      { value: 344, label: "View EnquiryWorkFlow Migration." },
      { value: 345, label: "View EnquiryWorkFlow Insurance." },
      { value: 346, label: "View EnquiryWorkFlow SkillAssessment." },
      { value: 347, label: "View EnquiryWorkFlow Accounts." },
      { value: 348, label: "View EnquiryWorkFlow Eoi." },
      { value: 349, label: "View EnquiryWorkFlow PTE/IELTS." },
    ],
  },
  {
    value: 400,
    label: "Workload",
    children: [
      { value: 401, label: "Admissions" },
      { value: 405, label: "Export CSV Admission." },
      { value: 402, label: "Migration" },
      { value: 406, label: "Export CSV Migration." },
      { value: 403, label: "Insurance" },
      { value: 407, label: "Export CSV Insurence." },
      { value: 404, label: "Skill Assessment" },
      { value: 408, label: "Export CSV Skill Assessment." },
    ],
  },

  {
    value: 500,
    label: "Finance",
    children: [
      {
        value: 501,
        label: "Create Invoice",
        children: [
          { value: 502, label: "View OnGoing Workflow." },
          { value: 503, label: "Delete OnGoing Workflow." },
          { value: 519, label: "Export CSV Create Invoice." },
        ],
      },
      { value: 504, label: "View Invoice." },
      { value: 505, label: "Change Status of Invoice." },
      { value: 506, label: "Edit Invoice." },
      { value: 507, label: "Refund/Roleback Invoice." },
      { value: 508, label: "Application OnGoing" },
      {
        value: 509,
        label: "A/C Receivable",
        children: [
          { value: 510, label: "Admission" },
          { value: 520, label: "Export CSV Ac Receivable Admission." },
          { value: 511, label: "Migration" },
          { value: 521, label: "Export CSV Ac Receivable Migration." },
          { value: 512, label: "Insurance" },
          { value: 522, label: "Export CSV Ac Receivable Insurence." },
          { value: 513, label: "Skill Assessment" },
          { value: 523, label: "Export CSV Ac Receivable Skill Assessment." },
        ],
      },
      {
        value: 514,
        label: "A/C Payable",
        children: [
          { value: 515, label: "Admission" },
          { value: 524, label: "Export CSV Ac Payable Admission." },
          { value: 516, label: "Migration" },
          { value: 525, label: "Export CSV Ac Payable Migration." },
          { value: 517, label: "Insurance" },
          { value: 526, label: "Export CSV Ac Payable Insurence." },
          { value: 518, label: "Skill Assessment" },
          { value: 527, label: "Export CSV Ac Payable Skill Assessment." },
          { value: 528, label: "Edit Paid Date" },
        ],
      },
    ],
  },

  {
    value: 1000,
    label: "Report",
    children: [
      {
        value: 1001,
        label: "General",
        children: [
          {
            value: 1002,
            label: "Todays Lead Report",
            children: [
              { value: 1003, label: "View Todays Lead Report." },
              { value: 1004, label: "Export CSV Todays Lead Reports." },
            ],
          },
          {
            value: 1006,
            label: "Lead Report",
            children: [
              { value: 1007, label: "View Lead Report" },
              { value: 1008, label: "Export CSV Lead Reports." },
            ],
          },
          {
            value: 1010,
            label: "Client Report",
            children: [
              { value: 1011, label: "View Client Report." },
              { value: 1012, label: "Export CSV Client Reports." },
            ],
          },
          {
            value: 1014,
            label: "Branch Wise Summery Report",
            children: [
              {
                value: 1015,
                label: "View Branch Wise Summery Report.",
              },
              {
                value: 1016,
                label: "Export CSV Branch Wise Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1018,
        label: "Admission",
        children: [
          {
            value: 1019,
            label: "Admission Report",
            children: [
              { value: 1020, label: "View Admission Report." },
              { value: 1021, label: "Export CSV Admission Reports." },
            ],
          },
          {
            value: 1023,
            label: "SuperAgent Receviable Admission Summery Report",
            children: [
              {
                value: 1024,
                label: "View SuperAgent Receviable Admission Summery Report.",
              },
              {
                value: 1025,
                label:
                  "Export CSV SuperAgent Receviable Admission Summery Reports.",
              },
            ],
          },
          {
            value: 1027,
            label: "SubAgent Payable Admission Summery Report",
            children: [
              {
                value: 1028,
                label: "View SubAgent Payable Admission Summery Report.",
              },
              {
                value: 1029,
                label:
                  "Export CSV SubAgent Payable Admission Summery Reports.",
              },
            ],
          },
          {
            value: 1031,
            label: "Admission Provider Summery Report",
            children: [
              {
                value: 1032,
                label: "View Admission Provider Summery Report.",
              },
              {
                value: 1033,
                label: "Export CSV Admission Provider Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1035,
        label: "Migration",
        children: [
          {
            value: 1036,
            label: "Migration Report",
            children: [
              { value: 1037, label: "View Migration Report." },
              { value: 1038, label: "Export CSV Migration Reports." },
            ],
          },
          {
            value: 1040,
            label: "SubAgent Payable Migration Summery Report",
            children: [
              {
                value: 1041,
                label: "View SubAgent Payable Migration Summery Report.",
              },
              {
                value: 1042,
                label:
                  "Export CSV SubAgent Payable Migration Summery Reports.",
              },
            ],
          },
          {
            value: 1044,
            label: "Migration Provider Summery Report",
            children: [
              {
                value: 1045,
                label: "View Migration Provider Summery Report.",
              },
              {
                value: 1046,
                label: "Export CSV Migration Provider Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1048,
        label: "Insurence",
        children: [
          {
            value: 1049,
            label: "Insurence Report",
            children: [
              { value: 1050, label: "View Insurence Report." },
              { value: 1051, label: "Export CSV Insurence Reports." },
            ],
          },
          {
            value: 1053,
            label: "SubAgent Payable Insurence Summery Report",
            children: [
              {
                value: 1054,
                label: "View SubAgent Payable Insurence Summery Report.",
              },
              {
                value: 1055,
                label:
                  "Export CSV SubAgent Payable Insurence Summery Reports.",
              },
            ],
          },
          {
            value: 1057,
            label: "Insurence Provider Summery Report",
            children: [
              {
                value: 1058,
                label: "View Insurence Provider Summery Report.",
              },
              {
                value: 1059,
                label: "Export CSV Insurence Provider Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1061,
        label: "Skill Assessment",
        children: [
          {
            value: 1062,
            label: "Skill Assessment Report",
            children: [
              { value: 1063, label: "View Skill Assessment Report." },
              {
                value: 1064,
                label: "Export CSV Skill Assessment Reports.",
              },
            ],
          },
          {
            value: 1066,
            label: "SubAgent Payable Skill Assessment Summery Report",
            children: [
              {
                value: 1067,
                label: "View SubAgent Payable Skill Assessment Summery Report.",
              },
              {
                value: 1068,
                label:
                  "Export CSV SubAgent Payable Skill Assessment Summery Reports.",
              },
            ],
          },
          {
            value: 1070,
            label: "Skill Assessment Provider Summery Report",
            children: [
              {
                value: 1071,
                label: "View Skill Assessment Provider Summery Report.",
              },
              {
                value: 1072,
                label:
                  "Export CSV Skill Assessment Provider Summery Reports.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 600,
    label: "Access Level",
    children: [
      { value: 601, label: "User" },
      { value: 602, label: "Role Menu" },
      {
        value: 603,
        label: "Branch",
        children: [
          { value: 604, label: "View Branch List" },
          { value: 605, label: "Create Branch." },
          { value: 606, label: "Edit Branch." },
          { value: 607, label: "Delete Branch." },
          { value: 608, label: "View Branch Details." },
          { value: 609, label: "Add User to Branch." },
          { value: 610, label: "Edit User in Branch." },
          { value: 611, label: "Change Password of User in Branch." },
          { value: 612, label: "Delete User in Branch." },
        ],
      },
    ],
  },
  {
    value: 700,
    label: "Partner",
    children: [
      {
        value: 701,
        label: "Provider",
        children: [
          { value: 702, label: "View Provider List" },
          { value: 730, label: "Export CSV Provider." },
          { value: 703, label: "Create Provider." },
          { value: 704, label: "Edit Provider." },
          { value: 705, label: "Delete Provider." },
          { value: 706, label: "View Provider Details." },
          { value: 707, label: "Download Provider Branch Attachments." },
          { value: 708, label: "View Provider Branch Courses." },
          { value: 709, label: "View Provider Branch Details." },
          { value: 710, label: "View Provider Application Form." },
          { value: 711, label: "Download Provider Application Form." },
          { value: 712, label: "Delete Provider Application Form." },
          { value: 713, label: "View Provider Transactional Arrangement." },
          {
            value: 714,
            label: "Download Provider Transactional Arrangement.",
          },
          {
            value: 715,
            label: "Delete Provider Transactional Arrangement.",
          },
          { value: 716, label: "Edit Provider Branch." },
        ],
      },
      {
        value: 717,
        label: "Agent",
        children: [
          { value: 718, label: "View Agent List" },
          { value: 731, label: "Export CSV Agent." },
          { value: 719, label: "Create Agent." },
          { value: 720, label: "Edit Agent." },
          { value: 732, label: "Change Agent Email." },
          { value: 721, label: "Verify Agent." },
          { value: 722, label: "Update Agent Password." },
          { value: 723, label: "Delete Agent." },
          {
            value: 724,
            label: "View Agent Details.",
            children: [
              { value: 725, label: "View/Download Agent Agreements." },
              { value: 726, label: "Delete Agent Agreements." },
              { value: 727, label: "View/Download Agent Payable Invoice." },
              { value: 728, label: "View/Download Agent Paid Invoice." },
              { value: 729, label: "View Agent Details" },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 900,
    label: "Course",
    children: [
      { value: 901, label: "View Course List" },
      { value: 902, label: "Create Course." },
      { value: 903, label: "Edit Course." },
      { value: 904, label: "Delete Course." },
      { value: 905, label: "View Course Details." },
    ],
  },
  {
    value: 1200,
    label: "Quotation",
    children: [
      { value: 1201, label: "View Quotation List" },
      { value: 1202, label: "Create Quotation" },
      { value: 1203, label: "Edit Quotation" },
      { value: 1204, label: "Delete Quotation" },
    ],
  },
  {
    value: 1300,
    label: "Ambassador",
  },
  {
    value: 1400,
    label: "Brand Ambassador",
  },
  {
    value: 1500,
    label: "Setting",
    children: [
      {
        value: 1501,
        label: "Admin Config",
        children: [
          { value: 1502, label: "Create Config." },
          { value: 1503, label: "Edit Config." },
        ],
      },
      {
        value: 1504,
        label: "Area",
        children: [
          { value: 1505, label: "Create Area." },
          { value: 1506, label: "Edit Area." },
          { value: 1507, label: "Delete Area." },
          { value: 1508, label: "Update Status of Area." },
        ],
      },
      {
        value: 1509,
        label: "Category",
        children: [
          { value: 1510, label: "Create Category." },
          { value: 1511, label: "Edit Category." },
          { value: 1512, label: "Delete Category." },
          { value: 1513, label: "Update Status of Category." },
        ],
      },
      {
        value: 1514,
        label: "Client Source",
        children: [
          { value: 1515, label: "Create Client Source." },
          { value: 1516, label: "Edit Client Source." },
          { value: 1517, label: "Delete Client Source." },
          { value: 1518, label: "Update Status of Client Source." },
        ],
      },
      {
        value: 1519,
        label: "Group Permissions",
        children: [
          { value: 1520, label: "Create Group Permissions." },
          { value: 1521, label: "Edit Group Permissions." },
          { value: 1522, label: "Delete Group Permissions." },
        ],
      },
      {
        value: 1523,
        label: "Health Cover Type",
        children: [
          { value: 1524, label: "Create Health Cover Type." },
          { value: 1525, label: "Edit Health Cover Type." },
          { value: 1526, label: "Delete Health Cover Type." },
          { value: 1527, label: "Update Status of Health Cover Type." },
        ],
      },
      {
        value: 1528,
        label: "Insurance",
        children: [
          { value: 1529, label: "Create Insurance." },
          { value: 1530, label: "Edit Insurance." },
          { value: 1531, label: "Delete Insurance." },
          { value: 1532, label: "Update Status of Insurance." },
        ],
      },
      {
        value: 1533,
        label: "Intake",
        children: [
          { value: 1534, label: "Create Intake." },
          { value: 1535, label: "Edit Intake." },
          { value: 1536, label: "Delete Intake." },
          { value: 1537, label: "Update Status of Intake." },
        ],
      },
      {
        value: 1538,
        label: "Interested Services",
        children: [
          { value: 1539, label: "Create Interested Services." },
          { value: 1540, label: "Edit Interested Services." },
          { value: 1541, label: "Delete Interested Services." },
          { value: 1542, label: "Update Status of Interested Services." },
        ],
      },
      {
        value: 1543,
        label: "Location",
        children: [
          { value: 1544, label: "Create Location." },
          { value: 1545, label: "Edit Location." },
          { value: 1546, label: "Delete Location." },
          { value: 1547, label: "Update Status of Location." },
        ],
      },
      {
        value: 1548,
        label: "Level",
        children: [
          { value: 1549, label: "Create Level." },
          { value: 1550, label: "Edit Level." },
          { value: 1551, label: "Delete Level." },
          { value: 1552, label: "Update Status of Level." },
        ],
      },
      {
        value: 1553,
        label: "Migration Provider",
        children: [
          { value: 1554, label: "Create Migration Provider." },
          { value: 1555, label: "Edit Migration Provider." },
          { value: 1556, label: "Delete Migration Provider." },
          { value: 1557, label: "Update Status of Migration Provider." },
        ],
      },
      {
        value: 1558,
        label: "Proforma",
        children: [
          { value: 1559, label: "Create Proforma." },
          { value: 1560, label: "Edit Proforma." },
          { value: 1561, label: "View Proforma." },
          { value: 1562, label: "Delete Proforma." },
          { value: 1563, label: "Update Status of Proforma." },
        ],
      },
      {
        value: 1564,
        label: "Visa Type",
        children: [
          { value: 1565, label: "Create Visa Type." },
          { value: 1566, label: "Edit Visa Type." },
          { value: 1567, label: "Delete Visa Type." },
          { value: 1568, label: "Update Status of Visa Type." },
        ],
      },
      {
        value: 1569,
        label: "WorkFlow",
        children: [
          { value: 1570, label: "Create WorkFlow." },
          { value: 1571, label: "View WorkFlow Steps." },
          { value: 1572, label: "Edit WorkFlow." },
          { value: 1573, label: "Delete WorkFlow." },
          { value: 1574, label: "Update Status of WorkFlow." },
        ],
      },
    ],
  },

  {
    value: 1700,
    label: "Audit",
  },
];

const UserPermissionsTab_new = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [backdropopen, setbackdropopen] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getUserPermissions();
    getActiveGroups();
  }, []);

  const [availableGroups, setAvailableGroups] = useState(null);
  const getActiveGroups = () => {
    axios
      .get(`${base_url.api1}/getAllActiveGroups`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let group_data = response.data.data;
        const group_obj = [];
        for (let i = 0; i < group_data.length; i++) {
          const group_data2 = group_data[i];
          let data = {
            value: group_data[i].id,
            label: group_data[i].group_name,
          };
          group_obj.push(data);
        }
        setAvailableGroups(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const getUserPermissions = () => {
    axios
      .get(`${base_url.api3}/getUserPermissionByUserId`, {
        headers: {
          Authorization: jwtToken,
          userId: Number(params.admin_id),
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        let permissionId = [];
        for (let i = 0; i < res.data.data.length; i++) {
          permissionId.push(res.data.data[i].permissionId);
        }
        const unique = getUnique(permissionId);
        let ofc = [];
        for (let j = 0; j < unique.length; j++) {
          ofc.push(unique[j]);
        }
        setChecked(ofc);
      })
      .catch((err) => {
        handle403error(err);
        let permissionId = [0];
        let ofc = [];
        setChecked(ofc);
        // console.log(err);
      });
  };

  const getGroupPermissions = (e) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getGroupPermissionsByGroupId`, {
        headers: {
          Authorization: jwtToken,
          groupId: e.target.value,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        let permissionId = [0];
        for (let i = 0; i < res.data.data.length; i++) {
          permissionId.push(res.data.data[i].permissionId);
        }
        const unique = getUnique(permissionId);
        let ofc = [];
        for (let j = 0; j < unique.length; j++) {
          ofc.push(unique[j]);
        }
        setChecked(ofc);

        setbackdropopen(false);
      })
      .catch((err) => {
        let permissionId = [0];
        let ofc = [];
        setChecked(ofc);

        setbackdropopen(false);
      });
  };

  function getUnique(array) {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  // Office Permissions------------------------------------------
  const [Expanded, setExpanded] = useState([]);
  const [Checked, setChecked] = useState([]);
  const [Checked2, setChecked2] = React.useState(false);
  const onCheck = (Checkedall) => {
    setChecked(Checkedall);
  };
  // console.log(officeChecked);
  const onExpand = (allExpanded) => {
    setExpanded(allExpanded);
  };
  const handleChange = (event) => {
    if (event.target.checked) {
      checkAllPermission();
    } else {
      unCheckAllPermission();
    }
    setChecked2(event.target.checked);
  };
  const checkAllPermission = () => {
    // let checkAlldata = [];
    // for (let i = 0; i <= 1700; i++) {
    //   checkAlldata.push(i);
    // }
    let arr = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 101, 102, 201, 202, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311,
      312, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327,
      328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 348, 401, 402,
      403, 404, 405, 406, 407, 408, 502, 503, 504, 505, 506, 507, 508, 510, 511, 512, 513, 515, 516,
      517, 518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 601, 602, 604, 605, 606, 607, 608, 609, 610, 611, 612, 702, 703,
      704, 705, 706, 707, 708, 709, 710, 711, 712, 713, 714, 715, 716, 718, 719,
      720, 721, 722, 723, 725, 726, 727, 728, 729, 730, 731, 732, 901, 902, 903, 904, 905, 1003,
      1004, 1007, 1008, 1011, 1012, 1015, 1016, 1020,
      1021, 1024, 1025, 1028, 1029, 1032, 1033, 1037,
      1038, 1041, 1042, 1045, 1046, 1050, 1051, 1054,
      1055, 1058, 1059, 1063, 1064, 1067, 1068, 1071,
      1072, 1201, 1202, 1203, 1204, 1300, 1400, 1502, 1503, 1505, 1506,
      1507, 1508, 1510, 1511, 1512, 1513, 1515, 1516, 1517, 1518, 1520, 1521,
      1522, 1524, 1525, 1526, 1527, 1529, 1530, 1531, 1532, 1534, 1535, 1536,
      1537, 1539, 1540, 1541, 1542, 1544, 1545, 1546, 1547, 1549, 1550, 1551,
      1552, 1554, 1555, 1556, 1557, 1559, 1560, 1561, 1562, 1563, 1565, 1566,
      1567, 1568, 1570, 1571, 1572, 1573, 1574, 1700,
    ];
    onCheck(arr);
  };
  const unCheckAllPermission = () => {
    onCheck([]);
  };
  // Office Permissions------------------------------------------

  // Quotation Permissions------------------------------------------

  const assignPermission = () => {
    let data = [];
    let data2 = [];
    data = Checked;
    // this arrays for truncating parent id
    let chkentry = [
      0,
      100, 200, 300, 313, 400, 500, 501, 509, 514, 600, 603, 700, 701, 717, 724, 900,
      1000, 1001, 1002, 1006, 1010, 1014, 1018, 1019, 1023, 1027, 1031, 1035,
      1036, 1040, 1044, 1048, 1049, 1053, 1057, 1061, 1062, 1066, 1070, 1200,
      1500, 1501, 1504, 1509, 1514, 1519, 1523, 1528, 1533, 1538, 1543, 1548,
      1553, 1558, 1564, 1569,
    ];

    for (let j = 0; j < data.length; j++) {
      let id = Number(data[j]);
      if (!chkentry.includes(id)) {
        data2.push({
          id: Number(0),
          userId: Number(params.admin_id),
          permissionId: Number(data[j]),
        });
      }
    }
    // console.log(data2);
    setbackdropopen(true);
    axios
      .post(`${base_url.api3}/addUserPermission`, data2, {
        headers: {
          Authorization: jwtToken,
          userId: Number(params.admin_id),
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          toast.success("Success");
          setbackdropopen(false);
          let logs =
            isw_user_name +
            " Update Permissions for " +
            params.admin_email +
            ".";
          callsaveauditapi(logs);
          // history.push("/users");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [createGroupModalOpen, setCreateModalOpen] = React.useState(false);
  const createNewGroup = () => {
    setCreateModalOpen(true);
  };

  const closeModal = () => {
    setCreateModalOpen(false);
  };

  const createGroupPermission = (data3) => {
    // if ((groupName == "") || (groupName == null)) {
    // if (groupName) {
    //   setGroupNameStatus(true);
    //   return
    // } else {
    setbackdropopen(true);
    let data = [];
    let data2 = [];
    let chkedData = Checked;

    for (let j = 0; j < chkedData.length; j++) {
      data2.push({
        id: Number(0),
        userId: Number(params.admin_id),
        permissionId: Number(chkedData[j]),
      });
    }
    // console.log(data2);
    setbackdropopen(true);
    axios
      .post(`${base_url.api3}/addUserPermission`, data2, {
        headers: {
          Authorization: jwtToken,
          userId: Number(params.admin_id),
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          toast.success("Success");
          setbackdropopen(false);
          let logs =
            isw_user_name +
            " Update Permissions for " +
            params.admin_email +
            ".";
          callsaveauditapi(logs);
          // history.push("/users");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });

    let groupName = data3.groupName;
    let status = 1;
    let id = 0;

    let submitData = {
      groupName,
      status,
      id,
    };
    axios
      .post(`${base_url.api1}/createGroup`, submitData, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.status_code);
        if (response.data.status_code == 200) {
          for (let j = 0; j < chkedData.length; j++) {
            data2.push({
              id: Number(0),
              groupId: response.data.data.id,
              permissionId: Number(chkedData[j]),
            });
          }
          axios
            .post(`${base_url.api1}/saveGroupPermissions`, data2, {
              headers: {
                Authorization: jwtToken,
                groupId: response.data.data.id,
              },
            })
            .then((response) => {
              if (response.data.status_code == 200) {
                let logs =
                  isw_user_name + " Added Permissions for " + groupName + ".";
                callsaveauditapi(logs);
                setCreateModalOpen(false);
                setbackdropopen(false);
                // getUserPermissions();
                // history.push("/group-permissions");
              } else {
                toast.error(response.message);
              }
            })
            .catch((error) => {
              setCreateModalOpen(false);
              setbackdropopen(false);
              toast.error(JSON.stringify(error.response.data.message));
            });

          toast.success("Success");
          let logs = isw_user_name + " Created Group " + groupName + ".";
          callsaveauditapi(logs);
          // history.push("/users");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
    // }
  };

  const goback = () => {
    history.push("/users");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div style={{ padding: "15px" }}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <div>
        <Row>
          <Col md={6}>
            <CardText className={classes.headtext}>
              <strong>User Permission</strong>
            </CardText>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="sent-button" onClick={goback}>
              List
            </button>
          </Col>
        </Row>

        {/* Office Permissions------------------ */}
        <div className="form-inner-admin">
          <div className="form-group creatadmin">
            <Row>
              <Col md={2}>
                <label>
                  <strong>Role Permissions</strong>
                  {/* <b style={{ color: "black" }}>{params.role_name}</b> */}
                </label>
              </Col>
              <Col md={3}>
                <select
                  className="form-control"
                  // value={branchvalue}
                  style={{ marginTop: "5px" }}
                  onChange={(e) => {
                    getGroupPermissions(e);
                  }}
                >
                  <option id="">--Select Group--</option>
                  {availableGroups?.map((p, index) => (
                    <option key={index + 1} value={p.id}>
                      {p.group_name}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={3} style={{ marginLeft: "30px", float: "left" }}>
                <button
                  onClick={createNewGroup}
                  className="sent-button"
                  style={{
                    padding: "10px 15px",
                    float: "left",
                    marginTop: "5px",
                  }}
                >
                  Update & Save As New Group
                </button>
                <Modal
                  isOpen={createGroupModalOpen}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h2>
                    <strong>Group Permission</strong>
                  </h2>
                  <form onSubmit={handleSubmit(createGroupPermission)}>
                    <div>
                      <div className="form-group creatadmin">
                        <Row>
                          <Col md={12}>
                            <label>
                              Group Name<span style={{ color: "red" }}>*</span>:
                            </label>
                            <input
                              type="text"
                              name="groupName"
                              className="form-control underline-input"
                              ref={register({ required: true })}
                            />
                            {errors.groupName && (
                              <p className="errormessage">
                                Group Name is Required
                              </p>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <Row className="justify-content-end mt-2">
                        <Button
                          color="secondary"
                          onClick={() => setCreateModalOpen(false)}
                        >
                          Cancel
                        </Button>
                        <button
                          type="submit"
                          className="btn listing_addbutton ml-1"
                        >
                          Submit
                        </button>
                      </Row>
                    </div>
                  </form>
                </Modal>
              </Col>
              <Col md={2}>{null}</Col>
              <Col md={1} style={{ marginLeft: "30px", float: "right" }}>
                <button
                  onClick={assignPermission}
                  className="btn listing_addbutton"
                  style={{
                    padding: "10px 15px",
                    float: "left",
                    marginTop: "5px",
                  }}
                >
                  Update
                </button>
              </Col>
              {/* <Col md={4}>
                    <label>
                      <strong>Check All: </strong>
                    </label>
                    <br />
                    <Checkbox
                      style={{ marginTop: "-10px" }}
                      checked={checked2}
                      onChange={handleChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Col> */}
            </Row>
            {/* <Row>
                  <Col md={4}>
                    <Row>
                      <Col md={4}>
                        <button
                          onClick={checkAllOfficePermission}
                          className="btn btn-primary btn-sm editprofilebtn2"
                          style={{ padding: "10px 15px" }}
                        >
                          Check All
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          onClick={unCheckAllOfficePermission}
                          className="btn btn-primary btn-sm editprofilebtn2"
                          style={{ padding: "10px 15px" }}
                        >
                          UnCheck All
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
          </div>
          <Row style={{ position: "relative" }} className="rolesrowalignment">
            <Col md={12}>
              <CheckboxTree
                style={{ position: "relative" }}
                nodes={nodes}
                checked={Checked}
                expanded={Expanded}
                onExpand={onExpand}
                onCheck={onCheck}
                showExpandAll={true}
                icons={{
                  check: <span className="rct-icon rct-icon-check" />,
                  uncheck: <span className="rct-icon rct-icon-uncheck" />,
                  halfCheck: <span className="rct-icon rct-icon-half-check" />,
                  expandClose: (
                    <span className="rct-icon rct-icon-expand-all" />
                  ),
                  expandOpen: (
                    <span className="rct-icon rct-icon-collapse-all" />
                  ),
                  expandAll: <span className="rct-icon rct-icon-expand-all" />,
                  collapseAll: (
                    <span className="rct-icon rct-icon-collapse-all" />
                  ),
                  parentClose: (
                    <span className="rct-icon rct-icon-parent-close" />
                  ),
                  parentOpen: (
                    <span className="rct-icon rct-icon-parent-open" />
                  ),
                }}
              />
            </Col>
            <Col md={4} className="roleabsoluteposition">
              <Checkbox
                style={{
                  marginTop: "-10px",
                  marginLeft: "-10px",
                  height: "40px",
                }}
                checked={Checked2}
                onChange={handleChange}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Col>
          </Row>
          <div style={{ marginBottom: "30px", float: "right" }}>
            <button
              onClick={assignPermission}
              className="btn listing_addbutton"
              style={{ padding: "10px 15px", float: "left", marginTop: "5px" }}
            >
              Update
            </button>
          </div>
        </div>
        {/* Office Permissions------------------ */}
      </div>
      {/* </Card> */}
    </div>
  );
};

export default UserPermissionsTab_new;
