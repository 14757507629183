import React, { useState, useEffect, Component } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import { getAdmin, selectAdmin } from "./../features/adminSlice";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { CountryDropdown } from "react-country-region-selector";
import UserArchiveModal from "./../Modal/Masters/UserArchiveModal";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PasswordStrengthBar from "react-password-strength-bar";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../services/auditservice";
import sort from "./../images/sort.svg";
import UserModal from "../Modal/UserModal";
import { check1pointpermission } from "./../Permission/permission";

function AdminUsers(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const alladmin = useSelector(selectAdmin);
  const dispatch = useDispatch();
  const history = useHistory();
  const isw_user_role = localStorage.getItem("isw_user_role");
  const jwtToken = "Bearer " + user.isw_user_token;
  const [deleteuser, setDeleteuser] = useState();
  const [deleteuserStatus, setDeleteuserStatus] = useState();
  const [pageno, setpageno] = useState(0);
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("addedOn");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");
  const [backdropopen, setbackdropopen] = useState(false);
  const [country1, setcountry] = useState();
  const [rolevalue, setrolevalue] = useState("all");
  const datepast = moment().subtract(1, "M").format("YYYY-MM-DDThh:mm:ss");
  const [datefilter, setdatefilter] = useState();
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [rolefilter, setrolefilter] = useState(false);
  const [countryfilter, setcountryfilter] = useState(false);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [inputtype, setinputtype] = useState("password");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [branchvalue, setbranchvalue] = useState();
  const [remarkcontent, setremarkcontent] = useState();
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [userStatus, setUserStatus] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [alladmin]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(601, permission_obj, resource_obj)) {
      if (isw_user_role === "1" || isw_user_role === "6") {
        if (localStorage.getItem("adminPageNo")) {
          let currentPage = localStorage.getItem("adminPageNo");
          setpageno(currentPage);
          setpaginationpageno(Number(currentPage) + 1);
          getalladminlist(currentPage);
        } else {
          setpageno(0);
          setpaginationpageno(1);
          getalladminlist(0);
        }
        setbranchvalue("all");
      } else {
        if (localStorage.getItem("adminPageNo")) {
          let currentPage = localStorage.getItem("adminPageNo");
          setpageno(currentPage);
          setpaginationpageno(Number(currentPage) + 1);
          getadminbyspecificrole(
            currentPage,
            user?.isw_available_Office[0]?.officeId
          );
          setbranchvalue(user?.isw_available_Office[0]?.officeId);
        } else {
          setpageno(0);
          setpaginationpageno(1);
          getadminbyspecificrole(0, user?.isw_available_Office[0]?.officeId);
          setbranchvalue(user?.isw_available_Office[0]?.officeId);
        }
      }
      getallRoles();
      getallSpecificRoles();
      dispatch(setdashboardcheck(19));
    } else {
      history.push("/errorPage");
    }
  };

  const createadminpage = () => {
    history.push("/create-users");
  };

  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    getalladminlist(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getalladminlist = (value1) => {
    axios
      .get(`${base_url.api2}/getAllUsers`, {
        headers: {
          Authorization: jwtToken,
          status: Number(userStatus),
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          var all_admin_users = response.data.data.content;
          dispatch(getAdmin(all_admin_users));
          setsearch(false);
          setdatefilter(false);
          setbackdropopen(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          dispatch(getAdmin(null));
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const getadminbyspecificrole = (value1, value2) => {
    axios
      .get(`${base_url.api2}/getUsersOfSpecificRolesByOfficeId`, {
        headers: {
          Authorization: jwtToken,
          officeId: value2,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          var all_admin_users = response.data.data.content;
          dispatch(getAdmin(all_admin_users));
          setsearch(false);
          setdatefilter(false);

          setbackdropopen(false);

          document.getElementById("myForm").reset();
        },
        (error) => {
          dispatch(getAdmin(null));
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [rolesdropdown, setrolesdropdown] = useState();
  const getallRoles = () => {
    axios
      .get(`${base_url.api3}/getAllRoles`, {
        headers: {
          page: 0,
          size: 100,
        },
      })
      .then((res) => {
        setrolesdropdown(res.data.data.content);
      })
      .catch((err) => handle403error(err));
  };

  const [specificroles, setSpecificRoles] = useState();
  const getallSpecificRoles = () => {
    axios
      .get(`${base_url.api3}/getAllSpecificRoles`)
      .then((res) => {
        setSpecificRoles(res.data.data);
      })
      .catch((err) => handle403error(err));
  };

  const sortClient = (e) => {
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getAllUsers`, {
        headers: {
          Authorization: jwtToken,
          status: Number(userStatus),
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setcountnumber(response.data.data.number);
          var all_admin_users = response.data.data.content;
          dispatch(getAdmin(all_admin_users));
          setsearch(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const selectCountry = (val) => {
    setbackdropopen(true);
    setrolevalue("all");
    setcountryfilter(true);
    setrolefilter(false);
    setcountry(val);
    if (val) {
      if (isw_user_role === "1" || isw_user_role === "6") {
        axios
          .get(`${base_url.api2}/getUserByCountry`, {
            headers: {
              Authorization: jwtToken,
              country: val,
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            var all_admin_users = response.data.data.content;
            dispatch(getAdmin(all_admin_users));
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setsearch(false);
            setdatefilter(false);
            setbackdropopen(false);

            document.getElementById("myForm").reset();
          })
          .catch((err) => {
            setbackdropopen(false);
            dispatch(getAdmin(null));
            handle403error(err);
          });
      } else {
        axios
          .get(`${base_url.api2}/getUserOfSpecificRoleByCountry`, {
            headers: {
              Authorization: jwtToken,
              country: val,
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setgetpageno(response.data.data.totalPages);
            var all_admin_users = response.data.data.content;
            dispatch(getAdmin(all_admin_users));
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setsearch(false);
            setdatefilter(false);
            setbackdropopen(false);
            document.getElementById("myForm").reset();
          })
          .catch((err) => {
            setbackdropopen(false);
            dispatch(getAdmin(null));
            handle403error(err);
          });
      }
    } else {
      getalladminlist(pageno);
    }
  };

  const searchbyrole = (e) => {
    setbackdropopen(true);

    setcountry();
    setcountryfilter(false);
    setrolefilter(true);
    setrolevalue(e.target.value);
    if (e.target.value === "all") {
      getalladminlist(pageno);
    } else {
      axios
        .get(`${base_url.api2}/getUserByRoleIdWithPage`, {
          headers: {
            Authorization: jwtToken,
            roleId: Number(e.target.value),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          var all_admin_users = response.data.data.content;
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          dispatch(getAdmin(all_admin_users));
          setsearch(false);
          setbackdropopen(false);

          setdatefilter(false);
          document.getElementById("myForm").reset();
        })
        .catch((err) => {
          setbackdropopen(false);
          dispatch(getAdmin(null));
          handle403error(err);
        });
    }
  };
  const updateCurrentUserStatus = (elem) => {
    let statusNo;
    if (Number(deleteuserStatus) === 1) {
      statusNo = 0;
    } else {
      statusNo = 1;
    }
    let data = {
      email: deleteuser,
      status: Number(statusNo),
    };
    axios
      .post(`${base_url.api2}/updateUserDataByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let logs =
            isw_user_name + " updated status of the user " + deleteuser + " .";
          callsaveauditapi(logs);
          getalladminlist(pageno);
          setDeleteOpen(false);
          // dispatch(
          //   removeAdmin({
          //     id: deleteuser,
          //   })
          // );
          // getalladminlist();
        },
        (error) => {
          toast.error(JSON.stringify(error?.response?.data?.message));
          let logs =
            isw_user_name +
            " failed to Update the status of the user " +
            deleteuser +
            " .";
          callsaveauditapi(logs);
          handle403error(error);
        }
      );
  };
  const editUser = (elem) => {
    localStorage.setItem("adminPage", "/users");
    localStorage.setItem("adminPageNo", pageno);
    var admin_username = elem.target.id;
    // props.history.replace({ pathname: '/edit-admin/'+admin_username })
    history.push("/edit-users/" + admin_username);
    // console.log(elem.target.id);
  };

  var subtitle;
  const [modalDeleteOpen, setDeleteOpen] = React.useState(false);
  // const openDeleteModal = (elem) => {
  //   console.log(elem.target.id);
  //   setDeleteuser(elem.target.id);
  //   setDeleteOpen(true);
  // };

  const [selecteduser, setselecteduser] = useState();
  const [modalPasswordOpen, setPasswordOpen] = React.useState(false);
  const openPasswordModal = (elem) => {
    console.log(elem.target.id);
    setselecteduser(elem.target.id);
    setPasswordOpen(true);
  };

  const [modalEditOpen, setEditOpen] = React.useState(false);
  const openStatusModal = (elem, p) => {
    if (p.status === 1) {
      console.log("called inactive");
      let data = {
        email: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            toast.success(
              "User " + p.firstName + " " + p.lastName + " Status: Inactive"
            );
            getalladminlist(pageno);
            let logs =
              isw_user_name +
              " Made Status of " +
              p.firstName +
              " " +
              p.lastName +
              " Inactive.";
            callsaveauditapi(logs);
          },
          (error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    } else {
      let data = {
        email: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail  `, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            toast.success(
              "User " + p.firstName + " " + p.lastName + " Status: Active"
            );
            getalladminlist(pageno);
            let logs =
              isw_user_name +
              " Made Status of " +
              p.firstName +
              " " +
              p.lastName +
              " Active.";
            callsaveauditapi(logs);
          },
          (error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    }
    setselecteduser(elem.target.id);
    // setEditOpen(true);
  };
  // -------------------------- Force logout -----------------------------------------------------------------
  const openForceLogoutModal = (elem, p) => {
    //console.log("called inactive");
    let data = {
      userName: elem.target.id,
    };
    axios
      .get(`${base_url.api3}/forceUserLogout`, {
        headers: {
          Authorization: jwtToken,
          userName: elem.target.id,
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          toast.success("User has been forcely logged out");
          let logs = isw_user_name + " Made user forcely logged out.";
          callsaveauditapi(logs);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );

    setselecteduser(elem.target.id);
    // setEditOpen(true);
  };
  //-------------------------- end force logout --------------------------------------------------------------
  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();
  const handlePassword = (e) => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    console.log(e.target.value);
    if (e.target.value.match(decimal)) {
      setchkPass(false);
      setchkPassState(e.target.value);
      return true;
    } else {
      setchkPass(true);
      // setchkPassState(e.target.value);
      return false;
    }
  };

  const changePassword = (e) => {
    let email = e.target[0].value;
    let password;
    // if (chkPass == false) {
    password = chkPassState.trim();
    let data = {
      email,
      password,
    };
    axios
      .post(`${base_url.api2}/updatePasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);
          let logs =
            isw_user_name +
            " Updated Password for the user " +
            e.target[0].value +
            ".";
          callsaveauditapi(logs);

          setPasswordOpen(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
    // } else {
    //   return false;
    // }
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    console.log(value);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchUserByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            setcountnumber(response.data.data.number);
            var all_admin_users = response.data.data.content;
            setClientDetails(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            setdatefilter(true);
            dispatch(getAdmin(all_admin_users));
          },
          (error) => {
            // toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var all_admin_users = response.data.data.content;
            setcountnumber(response.data.data.number);
            setClientDetails(response.data.data.content);
            dispatch(getAdmin(all_admin_users));
          },
          (error) => {
            handle403error(error);
          }
        );
    }
  };
  const [client_admin_users, setClientDetails] = useState(null);
  const submitSearch = (e) => {
    setcountry();
    setrolevalue("all");
    setrolefilter(false);
    setcountryfilter(false);
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            // Authorization: "Bearer efadfasd",
            Authorization: jwtToken,
            searchQuery: srchdata,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var all_admin_users = response.data.data.content;
            dispatch(getAdmin(all_admin_users));
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(true);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchUserOfSpecificRole`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: srchdata,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            // console.log(response.data.data);
            var all_admin_users = response.data.data.content;
            dispatch(getAdmin(all_admin_users));
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
            setsearch(true);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const afterOpenModal = () => {
    // subtitle.style.color = "#f00";
    console.log("open modal");
  };

  const closeModal = () => {
    setDeleteOpen(false);
    setPasswordOpen(false);
    setEditOpen(false);
  };
  const handleSelect = (event) => {
    console.log(event.target.value);
  };

  const submitstatus = (e) => {
    let email = e.target[0].value;
    let status = Number(e.target[1].value);
    let data = {
      email,
      status,
    };
    console.log(data);
    axios
      .post(`${base_url.api2}/updateUserDataByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          toast.success(response.data.message);
          setEditOpen(false);
          getalladminlist(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setcountry();
    setrolevalue("all");
    setpageno(0);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .post(`${base_url.api2}/searchUserByDate`, data, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var all_admin_users = response.data.data.content;
            setClientDetails(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setpageno(0);
            setdatefilter(true);
            dispatch(getAdmin(all_admin_users));
            setbackdropopen(false);
          },
          (error) => {
            // toast.error(JSON.stringify(error.response.data.message));
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .post(`${base_url.api2}/searchUserOfSpecificRoleByDate`, data, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var all_admin_users = response.data.data.content;
            setClientDetails(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setpageno(0);
            setdatefilter(true);
            dispatch(getAdmin(all_admin_users));
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };
  // console.log(datesrange);

  const selectuserbybranch = (e) => {
    setbranchvalue(e.target.value);
    setbackdropopen(true);

    if (e.target.value === "all") {
      getalladminlist(pageno);
    } else {
      if (isw_user_role === "1" || isw_user_role === "6") {
        axios
          .get(`${base_url.api2}/getUsersByOfficeIdPagination`, {
            headers: {
              Authorization: jwtToken,
              officeId: e.target.value,
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              console.log(response.data.data);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setgetpageno(response.data.data.totalPages);
              var all_admin_users = response.data.data.content;
              dispatch(getAdmin(all_admin_users));
              setsearch(false);
              setdatefilter(false);

              setbackdropopen(false);

              document.getElementById("myForm").reset();
            },
            (error) => {
              dispatch(getAdmin(null));
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        getadminbyspecificrole(pageno, e.target.value);
      }
    }
  };

  const viewPermissions = (p, e) => {
    localStorage.setItem("adminPageNo", pageno);
    localStorage.setItem("selected_user_id", p.id);
    localStorage.setItem("selected_user_username", p.email);

    history.push("/user-permissions/" + p.id + "/" + p.email);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      {/* <div style={{ margin: "5px" }}> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Users</h3>
          <a href="index.html" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Access Level</a><span className="s_span ">-</span>
          <a href="#/users" class="b-link active">
            User
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {isw_user_role == 1 || isw_user_role == 5 || isw_user_role == 6 ? (
          <a
            className="sent-button"
            onClick={createadminpage}
            style={{ float: "right", height: "40px" }}
          >
            Add
          </a>
        ) : null}
      </div>
      <Row>
        {/* <Col md={6}>
          <CardText
            className={classes.headtext}
            style={{ marginTop: "-10px" }}
          >
            <strong>Manage Users</strong>
          </CardText>
        </Col> */}
        {/* <Col md={6} className="d-flex justify-content-end">
          {isw_user_role == 1 ||
            isw_user_role == 5 ||
            isw_user_role == 6 ? (
            <button
              className="btn listing_addbutton"
              onClick={createadminpage}
            >
              <AddIcon className="listing_addbutton_i" /> Add
            </button>
          ) : null}
        </Col> */}
      </Row>
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          borderRight: "1.5rem solid #e9ebf2",
          borderLeft: "1.5rem solid #e9ebf2",
          borderTop: "1.5rem solid #e9ebf2",
          marginBottom: "0",
        }}
      >
        <Row>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable">RoleLimit</label>
            {/* <div>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#f5f5f9",
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form>
              <br />
            </div> */}
            <select
              className="form-control"
              name="branch"
              value={rows}
              onChange={handleRows}
              style={{ backgroundColor: "#f5f5f9", width: "95%" }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </Col>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable">RoleBranch</label>
            <select
              className="form-control"
              value={branchvalue}
              style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              onChange={selectuserbybranch}
            >
              {isw_user_role === "1" || isw_user_role === "6" ? (
                <option value="all">Show All</option>
              ) : null}
              {user?.isw_available_Office?.map((p, index) => (
                <option key={index + 1} value={p.officeId}>
                  {p.officeName}
                  {" - "}
                  {p.officeCode}
                </option>
              ))}
            </select>
          </Col>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable">RoleDate</label>
            <div className="clearsearchclass" style={{ width: "100%", marginTop: "0px" }}>
              <form
                id="myForm"
                className="d-flex"
                style={{ position: "relative" }}
              >
                <DateRangePicker
                  onEvent={handleEvent}
                  onCallback={handleCallback}
                  onApply={handleApply}
                >
                  <p
                    className="datepickerrangevaluetext"
                    style={{ backgroundColor: "#f5f5f9", width: "95%", padding: "10px" }}
                  >
                    {datevalue}
                  </p>
                </DateRangePicker>
                {/* {datefilter ? (
                <div>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setdatevalue("Click to open");
                      setdisplay("inline");
                      setdirection("DESC");
                      setrolefilter(false);
                      setcountryfilter(false);
                      setbackdropopen(true);
                      if (isw_user_role === "1" || isw_user_role === "6") {
                        getalladminlist(0);
                      } else {
                        getadminbyspecificrole(0, branchvalue);
                      }
                    }}
                  >
                    Clear Search
                  </a>
                </div>
              ) : null} */}
                <span
                  className="listpagesearch_button"
                  style={{
                    top: "7px",
                    position: "absolute",
                    right: "20px",
                    pointerEvents: "none",
                  }}
                >
                  <i className="fa fa-calendar"></i>
                </span>
              </form>
            </div>
          </Col>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable">Role</label>
            {isw_user_role === "1" || isw_user_role === "6" ? (
              <select
                className="form-control"
                value={rolevalue}
                onChange={searchbyrole}
                style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              >
                <option value="all">- Select Role -</option>
                {rolesdropdown?.map((item, index) => (
                  <option key={index + 1} value={item.id}>
                    {item.roleName}
                  </option>
                ))}
              </select>
            ) : (
              <select
                style={{
                  width: "95%",
                  height: "40px",
                  paddingLeft: "13px",
                  backgroundColor: "#f5f5f9",
                }}
                value={rolevalue}
                onChange={searchbyrole}
              >
                <option value="all">- Select Role -</option>
                {specificroles?.map((item, index) => (
                  <option key={index + 1} value={item.id}>
                    {item.roleName}
                  </option>
                ))}
              </select>
            )}
            {rolefilter ? (
              <div>
                <a
                  style={{
                    textDecoration: "none",
                    margin: "10px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setdisplay("inline");
                    setrolevalue("all");
                    setcountryfilter(false);
                    setbackdropopen(true);

                    setcountry("all");
                    setrolefilter(false);
                    setdirection("DESC");
                    getalladminlist(0);
                  }}
                >
                  Clear Search
                </a>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginTop: "8px" }}>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable">Country</label>
            <CountryDropdown
              style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              defaultOptionLabel="- Select Country -"
              className="form-control underline-input countrydropdown2"
              value={country1}
              onChange={(val) => selectCountry(val)}
            />
            {countryfilter ? (
              <div>
                <a
                  style={{
                    textDecoration: "none",
                    margin: "10px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setcountry("all");
                    setrolevalue("all");
                    setcountryfilter(false);
                    setbackdropopen(true);
                    if (isw_user_role === "1" || isw_user_role === "6") {
                      getalladminlist(0);
                    } else {
                      getadminbyspecificrole(0, branchvalue);
                    }
                    setrolefilter(false);
                    setdisplay("inline");
                    setdirection("DESC");
                  }}
                >
                  Clear Search
                </a>
              </div>
            ) : null}
          </Col>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable">Status</label>
            <select
              className="form-control"
              style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              onChange={(e) => {
                setUserStatus(e.target.value);
                axios
                  .get(`${base_url.api2}/getAllUsers`, {
                    headers: {
                      Authorization: jwtToken,
                      status: Number(e.target.value),
                      columnName: sortState,
                      direction: direction,
                      page: Number(pageno),
                      size: Number(rows),
                    },
                  })
                  .then(
                    (response) => {
                      settotalUser(response.data.data.totalElements);
                      setcountnumber(response.data.data.number);
                      setnumberofElements(response.data.data.numberOfElements);
                      setgetpageno(response.data.data.totalPages);
                      var all_admin_users = response.data.data.content;
                      dispatch(getAdmin(all_admin_users));
                      setsearch(false);
                      setdatefilter(false);
                      setbackdropopen(false);
                      document.getElementById("myForm").reset();
                    },
                    (error) => {
                      dispatch(getAdmin(null));
                      setbackdropopen(false);
                      handle403error(error);
                    }
                  );
              }}
            >
              <option value="1">Unarchive</option>
              <option value="0">Archive</option>
            </select>
          </Col>
          <Col md={2} style={{ marginRight: "5px" }}>
            <label className="area_rowslable"></label>
            <div className="clearsearchclass" style={{ width: "95%" }}>
              <form
                id="myForm"
                className="d-flex"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitSearch(e);
                }}
              >
                <input
                  className="form-control"
                  placeholder="Search.."
                  name="search"
                />
                <button className="btn listpagesearch_button" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
            {searchdata ? (
              <a
                className={classes.clrsrch}
                onClick={() => {
                  setdisplay("inline");
                  setdirection("DESC");
                  setbackdropopen(true);
                  setpageno(0);
                  if (isw_user_role === "1" || isw_user_role === "6") {
                    getalladminlist(0);
                  } else {
                    getadminbyspecificrole(0, branchvalue);
                  }
                }}
              >
                Clear Search
              </a>
            ) : null}
          </Col>
          {/* </div> */}
        </Row>
      </div>
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          border: "1.5rem solid #e9ebf2",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <Row className="s_pagi">
          <Col md={6}>
            {alladmin?.length ? (
              <p className="s_para">
                {countnumber * rows + 1} to{" "}
                {countnumber * rows + numberofElements} of {totalUser} records
              </p>
            ) : (
              <p className="s_para">No Record Found</p>
            )}
          </Col>
          {/* <Col md={6} className="d-flex justify-content-end">
            {isw_user_role == 1 ||
              isw_user_role == 5 ||
              isw_user_role == 6 ? (
              <a
                className="btn btn-success"
                onClick={createadminpage}
                style={{ float: "right", height: "40px" }}
              >
                Add
              </a>
            ) : null}
          </Col> */}
        </Row>
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          <Table
            className=" workload card-table table-borderless"
            // style={{ width: "100%" }}
            responsive
            onScroll={scrolltable}
          >
            <thead className="s_sticky">
              <tr>
                <th>SN</th>
                <th style={{ minWidth: "180px" }}>
                  NAME {/* <UnfoldMoreIcon */}
                  {/* // className={display} */}
                  <img
                    src={sort}
                    className="sorting"
                    id="firstName"
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "120px" }}>
                  EMAIL {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="email"
                    // className={display}
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>
                  ROLE {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="roleCode"
                    // className={display}
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "150px" }}>
                  CREATED {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="addedOn"
                    // className={display}
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "150px" }}>
                  LAST LOGIN {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="lastLogin"
                    // className={display}
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "110px" }}>
                  STATUS {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="status"
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "130px" }}>
                  REMARKS {/* <UnfoldMoreIcon */}
                  <img
                    src={sort}
                    className="sorting"
                    id="comment"
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>Created By</th>
                <th style={{ minWidth: "130px" }}>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {alladmin != null ? (
                alladmin &&
                alladmin.map((p, index) => (
                  <>
                    {p.status === 1 ?
                      <tr key={index + 1}>
                        <td style={{ textAlign: "center", width: "50px" }}>
                          {countnumber * rows + 1 + index}
                        </td>
                        {isw_user_role == "6" || isw_user_role === "1" ? (
                          <td>
                            {isw_user_role === "6" || isw_user_role === "1" ? (
                              <a
                                className="effects"
                                id={p.username}
                                onClick={(e) => viewPermissions(p, e)}
                                href={`#/user-permissions/` + p.id + `/` + p.email}
                              >
                                {p.firstName} {p.lastName}
                              </a>
                            ) : (
                              <>
                                {p.firstName} {p.lastName}
                              </>
                            )}
                          </td>
                        ) : (
                          <td>
                            {p.firstName} {p.lastName}
                          </td>
                        )}
                        <td className="effects">
                          {p.email}
                          {/* <br />
                          {p.phone} */}
                        </td>
                        <td>{p.roleName}</td>
                        <td>
                          {p.addedOn
                            ? moment(p.addedOn.split(".")[0]).format("DD-MMM-YY")
                            : null}
                          {/* <br />
                          {p.addedOn
                            ? moment(p.addedOn.split(".")[0]).format("hh.mm a")
                            : null} */}
                        </td>
                        <td>
                          {p.lastLogin
                            ? moment(p.lastLogin.split(".")[0]).format(
                              "DD-MMM-YY"
                            )
                            : "Never logged in"}
                          <br />
                          {p.lastLogin
                            ? moment(p.lastLogin.split(".")[0]).format("hh.mm a")
                            : null}
                        </td>
                        {isw_user_role != "6" ? (
                          <td>
                            {p.roleId != "1" && p.roleId != "6" ? (
                              <Link
                                id={p.username}
                                onClick={(e) => {
                                  openStatusModal(e, p);
                                }}
                              >
                                {p.status == "1" ? "Active" : "Inactive"}
                              </Link>
                            ) : (
                              <>{p.status == "1" ? "Active" : "Inactive"}</>
                            )}
                          </td>
                        ) : (
                          <td>
                            <Link
                              id={p.username}
                              onClick={(e) => {
                                openStatusModal(e, p);
                              }}
                            >
                              {p.status == "1" ? "Active" : "Inactive"}
                            </Link>
                          </td>
                        )}
                        <td className="leadellipsis">
                          {p.comment}
                          {p.comment != null ? (
                            // <span
                            //   className="leadellipsisspan"
                            //   onClick={() => {
                            //     setremarkmodelopen(true);
                            //     setremarkcontent(p.comment);
                            //   }}
                            // >
                            //   View More
                            // </span>
                            <button
                              // className="leadellipsisspan"
                              className="role-deletebtn"
                              onClick={() => {
                                setremarkmodelopen(true);
                                setremarkcontent(p.comment);
                              }}
                            >
                              View More
                            </button>
                          ) : null}
                        </td>
                        <td>{p.createdBy}</td>
                        <Modal
                          isOpen={remarkmodelopen}
                          onRequestClose={() => setremarkmodelopen(false)}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h4>Remark</h4>
                          {remarkcontent ? (
                            <textarea
                              style={{ minWidth: "600px" }}
                              className="form-control"
                              value={remarkcontent}
                              rows={10}
                              readonly
                            />
                          ) : (
                            <p> No Record Available</p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              margin: "10px 0",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="secondary"
                              onClick={() => setremarkmodelopen(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Modal>
                        {isw_user_role == "6" ? (
                          <td>
                            <div
                              style={{
                                position: "relative",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="workflowdashboard_fa-ellipsis"
                                data-toggle="dropdown"
                                style={{ width: "50px" }}
                              >
                                <i
                                  className="fa fa-ellipsis-v "
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="dropdown-menu sangam_workflowdashboarddropdown">
                                <a
                                  id={p.username}
                                  onClick={editUser}
                                  href={`#/edit-users/` + p.username}
                                >
                                  Edit
                                </a>
                                &nbsp;&nbsp;
                                <UserModal
                                  username={p.username}
                                  inputtype={inputtype}
                                  chkPassState={chkPassState}
                                  setchkPassState={setchkPassState}
                                  setinputtype={setinputtype}
                                  jwtToken={jwtToken}
                                  toast={toast}
                                  isw_user_name={isw_user_name}
                                />
                                &nbsp;&nbsp;|&nbsp;&nbsp;

                                {p.roleId != "6" ? (
                                  <Link
                                    id={p.username}
                                    onClick={(e) => {
                                      openForceLogoutModal(e, p);
                                    }}
                                  >
                                    Force logout
                                  </Link>
                                ) : null}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {p.roleId != "6" ? (
                                  <Link
                                    id={p.username}
                                    onClick={() => {
                                      setDeleteuserStatus(p.status);
                                      setDeleteuser(p.username);
                                      setDeleteOpen(true);
                                    }}
                                  >
                                    {p.status == "1" ? "Unarchive" : "Archive"}
                                  </Link>
                                ) : null}
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td id={p.username}>
                            <div
                              style={{
                                position: "relative",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="workflowdashboard_fa-ellipsis"
                                data-toggle="dropdown"
                                style={{ width: "50px" }}
                              >
                                <i
                                  className="fa fa-ellipsis-v "
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div
                                // class="s_dropname"
                                className="dropdown-menu workflowdashboarddropdown"
                              >
                                <ul style={{ margin: "0px" }} className="ulclass">
                                  {p.roleId != "6" ? (
                                    <>
                                      <li>
                                        <p>
                                          <a
                                            id={p.username}
                                            onClick={editUser}
                                            href={`#/edit-users/` + p.username}
                                            className="aclass"
                                          >
                                            Edit
                                          </a>
                                        </p>
                                      </li>
                                    </>
                                  ) : null}
                                  {p.roleId != "1" && p.roleId != "6" ? (
                                    <li>
                                      <p>
                                        <UserModal
                                          username={p.username}
                                          inputtype={inputtype}
                                          chkPassState={chkPassState}
                                          setchkPassState={setchkPassState}
                                          setinputtype={setinputtype}
                                          jwtToken={jwtToken}
                                          toast={toast}
                                          isw_user_name={isw_user_name}
                                        />
                                      </p>
                                    </li>
                                  ) : null}
                                  {/* {p.roleId != "1" && p.roleId != "6" ? (
                        <>
                          |&nbsp;&nbsp;
                          <span id={p.username} onClick={openPasswordModal}>
                            Chg-Pwd
                          </span>
                        </>
                      ) : null} */}
                                  {p.roleId != "6" ? (
                                    <li>
                                      <p>
                                        <a
                                          id={p.username}
                                          onClick={(e) => {
                                            openForceLogoutModal(e, p);
                                          }}
                                        >
                                          Force logout
                                        </a>
                                      </p>
                                    </li>
                                  ) : null}
                                  {p.roleId != "1" && p.roleId != "6" ? (
                                    <>
                                      <li>
                                        {/* <a
                            id={p.username}
                            onClick={() => {
                              setDeleteuserStatus(p.status);
                              setDeleteuser(p.username);
                              setDeleteOpen(true);
                            }}
                          >
                            {p.status == "1" ? "Unarchive" : "Archive"}
                          </a> */}<p>
                                          <UserArchiveModal
                                            header={p.status == 1 ? "Archive" : "Unarchive"}
                                            status={p.status}
                                            username={p.username}
                                            jwtToken={jwtToken}
                                            handle403error={handle403error}
                                            pageno={pageno}
                                            p={p}
                                            getalladminlist={getalladminlist}
                                            email={p.username}
                                          />
                                        </p>
                                      </li>

                                    </>
                                  ) : null}

                                </ul>
                              </div>
                            </div>
                          </td>
                        )}
                        <Modal
                          isOpen={modalPasswordOpen}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              changePassword(e);
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black" }}>Update Password</h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>User Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={selecteduser}
                                      readOnly
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Password:</label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type={inputtype}
                                        className="form-control underline-input"
                                        value={chkPassState}
                                        onChange={(e) =>
                                          setchkPassState(e.target.value)
                                        }
                                        required
                                      />
                                      {inputtype === "password" ? (
                                        <i
                                          className="fa fa-eye-slash fonticonpositioning"
                                          onClick={() => setinputtype("text")}
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-eye fonticonpositioning"
                                          onClick={() => setinputtype("password")}
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div>
                                    <PasswordStrengthBar password={chkPassState} />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <div className="d-flex justify-content-end mt-2">
                                  <Button color="secondary" onClick={closeModal}>
                                    Cancel
                                  </Button>
                                  <button
                                    className="btn listing_addbutton ml-1"
                                    type="submit"
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </Modal>
                        <Modal
                          isOpen={modalDeleteOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h4 style={{ marginTop: "10px" }}>
                            Are you sure you want to{" "}
                            {deleteuserStatus === 1 ? "Archive" : "Unarchive"}{" "}
                            current record ?{" "}
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              justifyContent: "flex-end",
                              gap: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <button
                              className="btn listing_addbutton ml-1"
                              onClick={updateCurrentUserStatus}
                            >
                              {deleteuserStatus === 1 ? "Archive" : "Unarchive"}{" "}
                            </button>
                            <button
                              className="btn btn-secondary cancel_margin"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </Modal>
                      </tr>
                      :
                      <>
                        {alladmin != null ?
                          <tr key={index + 1} className="s_inactive">
                            <td style={{ textAlign: "center", width: "50px" }}>
                              {countnumber * rows + 1 + index}
                            </td>
                            {isw_user_role == "6" || isw_user_role === "1" ? (
                              <td>
                                {isw_user_role === "6" || isw_user_role === "1" ? (
                                  <a
                                    className="effects"
                                    id={p.username}
                                    onClick={(e) => viewPermissions(p, e)}
                                    href={`#/user-permissions/` + p.id + `/` + p.email}
                                  >
                                    {p.firstName} {p.lastName}
                                  </a>
                                ) : (
                                  <>
                                    {p.firstName} {p.lastName}
                                  </>
                                )}
                              </td>
                            ) : (
                              <td>
                                {p.firstName} {p.lastName}
                              </td>
                            )}
                            <td className="effects">
                              {p.email}
                              {/* <br />
                          {p.phone} */}
                            </td>
                            <td>{p.roleName}</td>
                            <td>
                              {p.addedOn
                                ? moment(p.addedOn.split(".")[0]).format("DD-MMM-YY")
                                : null}
                              {/* <br />
                              {p.addedOn
                                ? moment(p.addedOn.split(".")[0]).format("hh.mm a")
                                : null} */}
                            </td>
                            <td>
                              {p.lastLogin
                                ? moment(p.lastLogin.split(".")[0]).format(
                                  "DD-MMM-YY"
                                )
                                : "Never logged in"}
                              <br />
                              {p.lastLogin
                                ? moment(p.lastLogin.split(".")[0]).format("hh.mm a")
                                : null}
                            </td>
                            {isw_user_role != "6" ? (
                              <td>
                                {p.roleId != "1" && p.roleId != "6" ? (
                                  <Link
                                    id={p.username}
                                    onClick={(e) => {
                                      openStatusModal(e, p);
                                    }}
                                  >
                                    {p.status == "1" ? "Active" : "Inactive"}
                                  </Link>
                                ) : (
                                  <>{p.status == "1" ? "Active" : "Inactive"}</>
                                )}
                              </td>
                            ) : (
                              <td>
                                <Link
                                  id={p.username}
                                  onClick={(e) => {
                                    openStatusModal(e, p);
                                  }}
                                >
                                  {p.status == "1" ? "Active" : "Inactive"}
                                </Link>
                              </td>
                            )}
                            <td className="leadellipsis">
                              {p.comment}
                              {p.comment != null ? (
                                // <span
                                //   className="leadellipsisspan"
                                //   onClick={() => {
                                //     setremarkmodelopen(true);
                                //     setremarkcontent(p.comment);
                                //   }}
                                // >
                                //   View More
                                // </span>
                                <button
                                  // className="leadellipsisspan"
                                  className="role-deletebtn"
                                  onClick={() => {
                                    setremarkmodelopen(true);
                                    setremarkcontent(p.comment);
                                  }}
                                >
                                  View More
                                </button>
                              ) : null}
                            </td>
                            <td>{p.createdBy}</td>
                            <Modal
                              isOpen={remarkmodelopen}
                              onRequestClose={() => setremarkmodelopen(false)}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >
                              <h4>Remark</h4>
                              {remarkcontent ? (
                                <textarea
                                  style={{ minWidth: "600px" }}
                                  className="form-control"
                                  value={remarkcontent}
                                  rows={10}
                                  readonly
                                />
                              ) : (
                                <p> No Record Available</p>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  color="secondary"
                                  onClick={() => setremarkmodelopen(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal>
                            {isw_user_role == "6" ? (
                              <td>
                                <div
                                  style={{
                                    position: "relative",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    className="workflowdashboard_fa-ellipsis"
                                    data-toggle="dropdown"
                                    style={{ width: "50px" }}
                                  >
                                    <i
                                      className="fa fa-ellipsis-v "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="dropdown-menu sangam_workflowdashboarddropdown">
                                    <a
                                      id={p.username}
                                      onClick={editUser}
                                      href={`#/edit-users/` + p.username}
                                    >
                                      Edit
                                    </a>
                                    &nbsp;&nbsp;
                                    <UserModal
                                      username={p.username}
                                      inputtype={inputtype}
                                      chkPassState={chkPassState}
                                      setchkPassState={setchkPassState}
                                      setinputtype={setinputtype}
                                      jwtToken={jwtToken}
                                      toast={toast}
                                      isw_user_name={isw_user_name}
                                    />
                                    &nbsp;&nbsp;|&nbsp;&nbsp;


                                    {p.roleId != "6" ? (
                                      <Link
                                        id={p.username}
                                        onClick={(e) => {
                                          openForceLogoutModal(e, p);
                                        }}
                                      >
                                        Force logout
                                      </Link>
                                    ) : null}
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    {p.roleId != "6" ? (
                                      <Link
                                        id={p.username}
                                        onClick={() => {
                                          setDeleteuserStatus(p.status);
                                          setDeleteuser(p.username);
                                          setDeleteOpen(true);
                                        }}
                                      >
                                        {p.status == "1" ? "Unarchive" : "Archive"}
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td id={p.username}>
                                <div
                                  style={{
                                    position: "relative",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    className="workflowdashboard_fa-ellipsis"
                                    data-toggle="dropdown"
                                    style={{ width: "50px" }}
                                  >
                                    <i
                                      className="fa fa-ellipsis-v "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div
                                    // class="s_dropname"
                                    className="dropdown-menu workflowdashboarddropdown"
                                  >
                                    <ul style={{ margin: "0px" }} className="ulclass">
                                      {p.roleId != "6" ? (
                                        <>
                                          <li>
                                            <p>
                                              <a
                                                id={p.username}
                                                onClick={editUser}
                                                href={`#/edit-users/` + p.username}
                                                className="aclass"
                                              >
                                                Edit
                                              </a>
                                            </p>
                                          </li>
                                        </>
                                      ) : null}
                                      {p.roleId != "1" && p.roleId != "6" ? (
                                        <li>
                                          <p>
                                            <UserModal
                                              username={p.username}
                                              inputtype={inputtype}
                                              chkPassState={chkPassState}
                                              setchkPassState={setchkPassState}
                                              setinputtype={setinputtype}
                                              jwtToken={jwtToken}
                                              toast={toast}
                                              isw_user_name={isw_user_name}
                                            />
                                          </p>
                                        </li>
                                      ) : null}

                                      {p.roleId != "6" ? (
                                        <li>
                                          <p>
                                            <a
                                              id={p.username}
                                              onClick={(e) => {
                                                openForceLogoutModal(e, p);
                                              }}
                                            >
                                              Force logout &nbsp;&nbsp; &nbsp;&nbsp;
                                            </a>
                                          </p>
                                        </li>
                                      ) : null}
                                      {/* {p.roleId != "1" && p.roleId != "6" ? (
                        <>
                          |&nbsp;&nbsp;
                          <span id={p.username} onClick={openPasswordModal}>
                            Chg-Pwd
                          </span>
                        </>
                      ) : null} */}
                                      {p.roleId != "1" && p.roleId != "6" ? (
                                        <>
                                          <li>
                                            <p>
                                              {/* <a
                            id={p.username}
                            onClick={() => {
                              setDeleteuserStatus(p.status);
                              setDeleteuser(p.username);
                              setDeleteOpen(true);
                            }}
                          >
                            {p.status == "1" ? "Unarchive" : "Archive"}
                          </a> */}
                                              <UserArchiveModal
                                                header={p.status == 1 ? "Archive" : "Unarchive"}
                                                status={p.status}
                                                username={p.username}
                                                jwtToken={jwtToken}
                                                handle403error={handle403error}
                                                pageno={pageno}
                                                p={p}
                                                getalladminlist={getalladminlist}
                                                email={p.username}
                                              />
                                            </p>
                                          </li>
                                        </>
                                      ) : null}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            )}
                            <Modal
                              isOpen={modalPasswordOpen}
                              // onAfterOpen={afterOpenModal}
                              onRequestClose={closeModal}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  changePassword(e);
                                }}
                              >
                                <div>
                                  <h3 style={{ color: "black" }}>Update Password</h3>
                                  <div className="form-group creatadmin">
                                    <Row>
                                      <Col>
                                        <label>User Name:</label>
                                        <input
                                          type="text"
                                          className="form-control underline-input"
                                          value={selecteduser}
                                          readOnly
                                          required
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group creatadmin">
                                    <Row>
                                      <Col>
                                        <label>Password:</label>
                                        <div style={{ position: "relative" }}>
                                          <input
                                            type={inputtype}
                                            className="form-control underline-input"
                                            value={chkPassState}
                                            onChange={(e) =>
                                              setchkPassState(e.target.value)
                                            }
                                            required
                                          />
                                          {inputtype === "password" ? (
                                            <i
                                              className="fa fa-eye-slash fonticonpositioning"
                                              onClick={() => setinputtype("text")}
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-eye fonticonpositioning"
                                              onClick={() => setinputtype("password")}
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </div>
                                        <PasswordStrengthBar password={chkPassState} />
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group creatadmin">
                                    <div className="d-flex justify-content-end mt-2">
                                      <Button color="secondary" onClick={closeModal}>
                                        Cancel
                                      </Button>
                                      <button
                                        className="btn listing_addbutton ml-1"
                                        type="submit"
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </Modal>
                            <Modal
                              isOpen={modalDeleteOpen}
                              onRequestClose={closeModal}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >
                              <h4 style={{ marginTop: "10px" }}>
                                Are you sure you want to{" "}
                                {deleteuserStatus === 1 ? "Archive" : "Unarchive"}{" "}
                                current record ?{" "}
                              </h4>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "20px",
                                  justifyContent: "flex-end",
                                  gap: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <button
                                  className="btn listing_addbutton ml-1"
                                  onClick={updateCurrentUserStatus}
                                >
                                  {deleteuserStatus === 1 ? "Archive" : "Unarchive"}{" "}
                                </button>
                                <button
                                  className="btn btn-secondary cancel_margin"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                              </div>
                            </Modal>
                          </tr>
                          :
                          <p className={classes.headertekst}>{null}</p>}
                      </>
                    }
                  </>
                ))
              ) : (
                <p className={classes.headertekst}>{null}</p>
              )}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col md={6}>
            <div>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                  <br />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                    <select
                      className={classes.selectlimit}
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                  {alladmin?.length ? (
                    <div className="s_spanPage">
                      {countnumber * rows + 1} to{" "}
                      {countnumber * rows + numberofElements} of {totalUser} records
                    </div>
                  ) : (
                    <div className="s_spanPage">No Record Found</div>
                  )}
                </div>
              </form>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.root}>
              {searchdata || datefilter ? (
                <Pagination
                  page={paginationpageno}
                  count={getpageno}
                  onChange={handlesearch}
                  shape="rounded"
                />
              ) : (
                <Pagination
                  page={paginationpageno}
                  count={getpageno}
                  onChange={handlepage}
                  shape="rounded"
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default withRouter(AdminUsers);
