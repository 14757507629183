import { createSlice } from '@reduxjs/toolkit';

export const courseSlice = createSlice({
  name: 'course',
  initialState: {
    course:[],
  },
  reducers: {
    getcourse:(state,action)=>{
      state.course=action.payload
    },
    
    addtocourse:(state,action)=>{
      state.course=[...state.course,action.payload]
    },
    removecourse:(state,action)=>{
      const index=state.course.findIndex(
        (courseUser)=>courseUser.courseId===Number(action.payload.id) 
      )
      let newcourse=[...state.course]
      if(index>=0){
        newcourse.splice(index,1)
      }
      else{
        console.warn('Cant remove')
      }
      return{
        ...state,
        course:newcourse
      }
    },
    updatecourse:(state,action)=>{
      const index=state.course.findIndex(
        (courseUser)=>courseUser.courseId===Number(action.payload.id)  
      )
      let newcourse=[...state.course]
      if(index>=0){
        newcourse.splice(index,1)
        newcourse.splice(index,0,action.payload.data)
      }
      else{
        console.warn('Cant remove')
      }
    //   newcourse.push(action.payload.data)
      return{
        ...state,
        course:newcourse
      }
    }
  },
});

export const { getcourse,addtocourse,removecourse,updatecourse } = courseSlice.actions;

export const selectcourse = state => state.course.course;

export default courseSlice.reducer;
