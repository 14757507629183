import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer } from "react-toastify";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import MenuItem from "@material-ui/core/MenuItem";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { checkreportpermission, check4pointpermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";


function SubAgentPayableAdmissionSummeryReport(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageNo, setPageNo] = useState(0);
  const [sortState, setSortState] = useState("agent_name");
  const [direction, setDirection] = useState("DESC");
  const [rows, setRows] = React.useState(50);
  const [totalUser, setTotalUser] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberofElements, setNumberOfElements] = useState();
  // const datepast = moment().subtract(3, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const [backdropopen, setbackdropopen] = useState(true);

  const [subAgentData, setSubAgentData] = useState();
  const [subAgentPageNo, setSubAgentPageNo] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [pdfRow, setPdfRow] = useState([]);

  const [display1, setDisplay] = useState("inline");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [subAgentData]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();
  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    let headData = {
      Authorization: jwtToken,
      invoiceType: 'Payable',
      workflowType: "Admission",
      agentType: 'sub_agent',
      columnName: 'agent_name',
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    }
    if (actionType === 'DOWNLOAD') {
      setbackdropopen(true);
      axios
        .get(`${base_url.api4}/getAgentSummeryReport`, {
          headers: headData,
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  }


  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  let headData;
  let office_id;

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (user.isw_user_role === "1" || user.isw_user_role === "6") {
      headData = {
        Authorization: jwtToken,
        invoiceType: 'Payable',
        workflowType: "Admission",
        agentType: 'sub_agent',
        columnName: 'agent_name',
        officeId: userOfficeId,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      }
    } else {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      office_id = new_id;
      setSelectedOfficeId(new_id);
      headData = {
        Authorization: jwtToken,
        invoiceType: 'Payable',
        workflowType: "Admission",
        agentType: 'sub_agent',
        columnName: 'agent_name',
        officeId: new_id,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      }
    }
    if (check1pointpermission(1028, permission_obj, resource_obj)) {
      //if(available_permission) {
      getSubAgentWiseSummery(headData);
      getOffice()
      dispatch(setdashboardcheck(64));
      getIntake();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        setAvailableOffice();
      });
  };

  const [allIntake, setAllIntake] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
          columnName: 'createdIntake',
          direction: 'DESC',
        },
      })
      .then(
        (response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              createdIntake: moment(intakeData[i].createdIntake).format("YYYY-MM"),
              id: intakeData[i].id,
              name: intakeData[i].name,
              status: intakeData[i].status,
            }
            intakeObj.push(data);
          }
          setAllIntake(intakeObj);
        },
        (error) => {
          setAllIntake();
          console.log(JSON.stringify(error.response));
        }
      );
  };

  const [intakeStatus, setIntakeStatus] = useState();
  const [intakeData, setIntakeData] = useState();
  const handleChangeIntake = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    let headData;
    if (optionElementName == '') {
      setIntakeData();
      setIntakeStatus(false);
      if (dateStatus) {
        if (officeStatus) {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (officeStatus) {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              officeId: userOfficeId,
              query: searchData,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              officeId: userOfficeId,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    } else {
      setIntakeData(optionElementName);
      setIntakeStatus(true);
      if (dateStatus) {
        if (officeStatus) {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              intake: optionElementName,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              intake: optionElementName,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              intake: optionElementName,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: optionElementName,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (officeStatus) {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              officeId: userOfficeId,
              query: searchData,
              intake: optionElementName,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              intake: optionElementName,
              officeId: userOfficeId,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              intake: optionElementName,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: optionElementName,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    }
  }

  const [userOfficeId, setUserOfficeId] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? null
      : user?.isw_available_Office[0].officeId
  );

  const [selectedOfficeId, setSelectedOfficeId] = useState(null);

  const [officeStatus, setOfficeStatus] = useState(false);
  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementNameId = optionElement.getAttribute("id");
    const optionElementId = optionElement.getAttribute("value");
    if (optionElementNameId === "all") {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(new_id);
      setUserOfficeId(new_id);
      setSelectedOfficeId(new_id);
      setOfficeStatus(false);
      // setSelectedOfficeId('');
      if (dateStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              query: searchData,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              query: searchData,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    } else {
      setOfficeStatus(true);
      // setSelectedOfficeId(optionElementId);
      setUserOfficeId(optionElementId);

      if (dateStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              query: searchData,
              columnName: sortState,
              officeId: optionElementId,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              query: searchData,
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: optionElementId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    }
  }

  const handleRows = (event) => {
    setRows(event.target.value);
    if (dateStatus) {
      if (officeStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              query: searchData,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              query: searchData,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    } else {
      if (officeStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              query: searchData,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              query: searchData,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              direction: direction,
              page: Number(0),
              size: Number(event.target.value),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    }
  };

  const [datesRange, setDatesRange] = useState();
  const [dateStatus, setDatesStatus] = useState();
  const [dateValue, setDateValue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let data = {
      from: picker.startDate.format("YYYY-MM-DD 00:00:00"),
      to: picker.endDate.format("YYYY-MM-DD 00:00:00"),
    };

    setDatesRange(data);
    setDatesStatus(true);
    if (officeStatus) {
      if (intakeStatus) {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            officeId: Number(userOfficeId),
            intake: intakeData,
            query: searchData,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            officeId: Number(userOfficeId),
            intake: intakeData,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      } else {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            officeId: Number(userOfficeId),
            query: searchData,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            officeId: Number(userOfficeId),
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      }
    } else {
      if (intakeStatus) {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            intake: intakeData,
            query: searchData,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            intake: intakeData,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      } else {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            query: searchData,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            page: Number(0),
            invoicePaidStartDate: data.from,
            invoicePaidEndDate: data.to,
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      }
    }
  };

  const handlePageLead = (event, value) => {
    setPageNo(value - 1);
    if (dateStatus) {
      if (officeStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              query: searchData,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              query: searchData,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    } else {
      if (officeStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              query: searchData,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              intake: intakeData,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              query: searchData,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    }
  };

  const clearDateField = (value) => {
    if (officeStatus) {
      if (intakeStatus) {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            officeId: userOfficeId,
            columnName: sortState,
            direction: direction,
            intake: intakeData,
            query: searchData,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            officeId: userOfficeId,
            columnName: sortState,
            direction: direction,
            intake: intakeData,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      } else {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            officeId: userOfficeId,
            columnName: sortState,
            direction: direction,
            query: searchData,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            officeId: userOfficeId,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      }
    } else {
      if (intakeStatus) {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            intake: intakeData,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            intake: intakeData,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      } else {
        if (searchStatus) {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        } else {
          let headData = {
            Authorization: jwtToken,
            invoiceType: 'Payable',
            workflowType: "Admission",
            agentType: 'sub_agent',
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          }
          getSubAgentWiseSummery(headData);
        }
      }
    }
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();

  const getSubAgentWiseSummery = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAgentSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        headData.size = response.data.data.totalElements;
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          setpdflength(50);
        } else {
          setpdflength(50);
        }
        getPdfData(headData.size = Number(50));
        setPdfHeader(headData);
        setSubAgentData(response.data.data.content);
        setSubAgentPageNo(response.data.data.totalPages);
        setTotalUser(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        // setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setSubAgentData();
        setSubAgentPageNo(0);
        setTotalUser(0);
        setCountNumber(0);
        setNumberOfElements(0);
      });
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAgentSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setPdfRow();
      });
  };

  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState();
  const searchSubAgentWiseSummery = (e) => {
    setDisplay("none");
    console.log(e.target.value);
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setPageNo(0);
    if (srchdata != '') {
      setSearchStatus(true);
      let headData = {
        Authorization: jwtToken,
        invoiceType: 'Payable',
        workflowType: "Admission",
        agentType: 'sub_agent',
        columnName: sortState,
        direction: direction,
        query: srchdata,
        officeId: userOfficeId,
        page: Number(0),
        size: Number(rows),
      }
      getSubAgentWiseSummery(headData);
    } else {
      setSearchStatus(false);
      let headData = {
        Authorization: jwtToken,
        invoiceType: 'Payable',
        workflowType: "Admission",
        agentType: 'sub_agent',
        columnName: sortState,
        direction: direction,
        page: Number(0),
        size: Number(rows),
      }
      getSubAgentWiseSummery(headData);
    }
  };

  const exportPdfLead = () => {
    let headData = {
      Authorization: jwtToken,
      invoiceType: 'Payable',
      workflowType: "Admission",
      agentType: 'sub_agent',
      columnName: 'agent_name',
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    }
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAgentSummeryReport`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Sub Agent Name",
          "Sub Agent Email",
          "Total Due",
          "Total Paid",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.agent_name,
            element.agent_username,
            element.totalDue,
            element.totalPaid,
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("SubAgentPayableAdmissionSummeryReports.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setPdfRow();
      });
  };

  const sortLeadData = (e) => {
    let sortFilter = e.target.id.trim();
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }
    if (dateStatus) {
      if (officeStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              intake: intakeData,
              query: searchData,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              intake: intakeData,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              query: searchData,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              query: searchData,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              intake: intakeData,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              query: searchData,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              invoicePaidStartDate: datesRange.from,
              invoicePaidEndDate: datesRange.to,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    } else {
      if (officeStatus) {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              intake: intakeData,
              query: searchData,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              intake: intakeData,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              query: searchData,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              officeId: userOfficeId,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              query: searchData,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              intake: intakeData,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        } else {
          if (searchStatus) {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              query: searchData,
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          } else {
            let headData = {
              Authorization: jwtToken,
              invoiceType: 'Payable',
              workflowType: "Admission",
              agentType: 'sub_agent',
              columnName: sortFilter,
              direction: direction,
              page: Number(pageNo),
              size: Number(rows),
            }
            getSubAgentWiseSummery(headData);
          }
        }
      }
    }
  }

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <br />
      {/* <Card className="commoncomponent_cardcss"> */}
      {/* <CardBody> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "3rem", marginBottom: "-20px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Subagent Summery</h3>
          <a href="#/" className="b-link">Home</a>
          <span className="s_span ">-</span>
          <a className="b-link">Reports</a>
          <span className="s_span ">-</span>
          <a className="b-link">General</a>
          <span className="s_span ">-</span>
          <a href="#/subAgent-payable-admission-summery-report" className="b-link active">Subagent Summery</a>
        </div>
        {/* <a onClick={goback} className="back-btn">Back</a> */}
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
            <CardText className={classes.headtext}>
              <strong>Sub Agent Payable Admission Report</strong>
            </CardText>
          </Col> */}
          {/* <Col md={6}>
            <Row>
              <Col md={3}></Col>
              <Col md={3}></Col>
              <Col md={3}>
                {permissionData?.includes(625) ?
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfLead}
                  >
                    Export PDF
                  </button>
                  : null}
              </Col>
              <Col md={3}>
                {subAgentData != null ?
                  <>
                    {permissionData?.includes(626) ?
                      <CSVLink
                        filename="SubAgentPayableAdmissionSummeryReports.csv"
                        data={subAgentData}
                        className="btn listing_addbutton"
                      // target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                      : null}
                  </>
                  : null}
              </Col>
            </Row>
          </Col> */}
        </Row>
        <div className="newlisting_headertabdesign" style={{ padding: "1.5rem", borderRight: "1.5rem solid #e9ebf2", borderLeft: "1.5rem solid #e9ebf2", borderTop: "1.5rem solid #e9ebf2", marginBottom: "0" }}>
          <Row>
            <Col md={1.5}>
              <form className="master">
                <div>
                  <label className="area_rowslable_my">Limit:</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      className="form-control"
                      style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </FormControl>
                </div>
              </form>
            </Col>
            <Col md={1.5}>{null}</Col>
            <Col md={3}>
              <div >
                <label className="area_rowslable_my">Paid Date:</label>
                <div className="clearsearchclass" style={{ width: "99%", marginTop: "0px" }}>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onApply={handleApply}
                      initialSettings={{ showDropdowns: true }}
                    >
                      <p className="datepickerrangevaluetext"
                        style={{ backgroundColor: "#f5f5f9", fontSize: "14px", color: "#6d7290", padding: "10px", width: "99%" }}
                      >
                        {dateValue}</p>
                    </DateRangePicker>
                    {/* {dateStatus ? (
                  <div style={{ float: "left" }}>
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setDisplay("inline");
                        setDateValue("Click to open");
                        setDirection("DESC");
                        setPageNo(0);
                        clearDateField(1);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "7px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </Col>
            <Col md={2.5}>
              <div >
                <label className="area_rowslable_my">Branch:</label>
                {user.isw_user_role === "1" || user.isw_user_role === "6" ? (
                  <select
                    className="form-control"
                    onChange={(e) => handleChangeOffice(e)}
                    style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                  >
                    <option value="" id="all">
                      - Show All -
                    </option>
                    {availableoffice?.map((cuntitem) => (
                      <option
                        key={cuntitem.officeId}
                        value={cuntitem.officeId}
                        id={cuntitem.officeName}
                      >
                        {cuntitem.officeName}
                        {" - "}
                        {cuntitem.officeCode}
                      </option>
                    ))}
                  </select>
                ) : (
                  <>
                    <select
                      // className={classes.selectdrop}
                      onChange={(e) => handleChangeOffice(e)}
                      name="branch"
                      style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                      // ref={register}
                      required
                    >
                      <option value="" id="all">
                        - Show All -
                      </option>
                      {user.isw_available_Office?.map((cuntitem) => (
                        <option
                          key={cuntitem.officeId}
                          value={cuntitem.officeId}
                          id={cuntitem.officeName}
                        >
                          {cuntitem.officeName}
                          {" - "}
                          {cuntitem.officeCode}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Intake:</label>
                <select
                  className="form-control"
                  onChange={handleChangeIntake}
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="">- Select Intake -</option>
                  {allIntake?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.name}
                      id={cuntitem.id}
                    >
                      {cuntitem.createdIntake}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            {/* <Col md={1.5}>{null}</Col> */}
            <Col md={2}>
              <label className="area_rowslable_my">Search:</label>
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchSubAgentWiseSummery(e);
                  }}
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchStatus ? (
                <a
                  className={classes.clrsrch2}
                  onClick={() => {
                    setDisplay("inline");
                    document.getElementById("myForm").reset();
                    setSearchStatus(false);
                    setPageNo(0);
                    let headData = {
                      Authorization: jwtToken,
                      workflowType: "Admission",
                      invoiceType: 'Payable',
                      agentType: 'sub_agent',
                      columnName: sortState,
                      officeId: userOfficeId,
                      direction: direction,
                      page: Number(0),
                      size: Number(rows),
                    }
                    getSubAgentWiseSummery(headData);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div className="newlisting_headertabdesign" style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2", marginTop: "0", marginBottom: "0" }}>
          <Row className="s_pagi" marginBottom="40px" style={{ marginTop: "5px" }}>
            <Col md={6}>
              {subAgentData?.length ? (
                <p className="s_para" style={{ marginTop: "5px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para" style={{ marginTop: "5px" }}>No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(
                  1029,
                  permissionData,
                  rolepermission
                ) ? (
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfLead}
                  >
                    Export PDF
                  </button>
                ) : null}
                <>
                  {/* {pdfRow != null && pdfRow.length != 0 ? (
                    <>
                      {permissionData?.includes(176) ?
                        <CSVLink
                          filename="SubAgentPayableAdmissionSummeryReports.csv"
                          data={pdfRow ? pdfRow : null}
                          className="btn listing_addbutton"
                        // target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                        : null}
                    </>
                   ) : null} */}
                  {dataForDownload != null ? (
                    <>
                      {check1pointpermission(
                        1029,
                        permissionData,
                        rolepermission
                      ) ? (
                        <>
                          <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')}>Export CSV</button>
                          <CSVLink
                            data={dataForDownload}
                            filename="SubAgentPayableAdmissionSummeryReports.csv"
                            className="hidden"
                            ref={csvLink}
                            target="_blank" />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>

          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive striped onScroll={scrolltable}>
              <thead className="s_sticky">
                <tr>
                  {/* <th>Id</th> */}
                  <th style={{ width: "50px", textAlign: "center" }}>SN </th>
                  <th style={{ width: "340px" }}>
                    AGENT NAME{" "}
                    {/* <UnfoldMoreIcon */}
                    <img src={sort}
                      className="sorting"
                      id="agent_name"
                      onClick={sortLeadData}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "340px" }}>
                    EMAIL{" "}
                    {/* <UnfoldMoreIcon */}
                    <img src={sort}
                      className="sorting"
                      id="agent_username"
                      onClick={sortLeadData}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    TOTAL DUE{" "}
                    {/* <UnfoldMoreIcon */}
                    <img src={sort}
                      className="sorting"
                      id="totalDue"
                      onClick={sortLeadData}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    TOTAL PAID{" "}
                    {/* <UnfoldMoreIcon */}
                    <img src={sort}
                      className="sorting"
                      id="totalPaid"
                      onClick={sortLeadData}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {subAgentData != null ?
                  subAgentData &&
                  subAgentData.map((p, index) => (
                    <tr key={p.id}>
                      {/* <td>{p.id}</td> */}
                      <td style={{ width: "50px", textAlign: "center" }}>
                        {countNumber * rows + 1 + index}
                      </td>
                      <td style={{ width: "50px" }}>
                        {p.agent_name}
                      </td>
                      <td>{p.agent_username}</td>
                      <td>{p.totalDue != null ? Number(p.totalDue).toFixed(2) : null}</td>
                      <td>{p.totalPaid != null ? Number(p.totalPaid).toFixed(2) : null}</td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </Table>
            <Row>
              <Col md={6}>
                <div>
                  <form className="master">
                    <div style={{ marginTop: "-10px" }}>
                      <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                      <br />
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      {subAgentData?.length ? (
                        <div className="s_spanPage">
                          {countNumber * rows + 1} to{" "}
                          {countNumber * rows + numberofElements} of {totalUser}{" "}
                          records
                        </div>
                      ) : (
                        <div className="s_spanPage">No Record Found</div>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  <Pagination
                    count={subAgentPageNo}
                    onChange={handlePageLead}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* </CardBody> */}
        {/* </Card> */}
      </div>
    </div>
  );
}

export default withRouter(SubAgentPayableAdmissionSummeryReport);