import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import "../css/Notes.css";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useHistory } from "react-router-dom";
const Appointments = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const [appointment, setappointment] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [documenttotalUser, setdocumenttotalUser] = useState();
  const [documentcountnumber, setdocumentcountnumber] = useState();
  const [documentnumberofElements, setdocumentnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [documentgetpageno, setdocumentgetpageno] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getAppointment();
  }, []);

  const handlepage = (event, value) => {
    let data = appointment.slice((value - 1) * 10, value * 10);
    setdocumentcountnumber(Number(value - 1));
    setdocumentdetails(data);
    setdocumentnumberofElements(data.length);
  };

  const getAppointment = () => {
    axios
      .get(`${base_url.api4}/getAppointmentsByEnquiryId`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: params.enquiry_id,
        },
      })
      .then((res) => {
        setappointment(res.data.data);
        const data2 = res.data.data.slice(0, 10) || [];
        setdocumentcountnumber(Number(0));
        setdocumentdetails(
          res.data.data.length > 0 ? res.data.data.slice(0, 10) : []
        );
        setdocumentnumberofElements(data2.length);
        setdocumentgetpageno(Math.ceil(Number(res.data.data.length) / 10));
        setdocumenttotalUser(res.data.data.length);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err.response.data.message));
      });
  };
  return (
    <div>
      <Table responsive striped>
        <thead>
          <tr>
            <th style={{ width: "50px" }}>SN</th>
            <th>Title</th>
            <th>Step</th>
            <th style={{ width: "105px" }}>Added</th>
          </tr>
        </thead>
        <tbody>
          {documentdetails?.map((item, index) => (
            <tr key={index + 1}>
              <td style={{ textAlign: "center" }}>{index + 1}</td>
              <td>{item.notes}</td>
              <td>{item.enquiryWorkflowStepsName}</td>
              <td>
                {item.appointmentDate
                  ? moment(item.appointmentDate.split(".")[0]).format(
                    "DD-MMM-YY"
                  )
                  : null}
                <br />
                {item.appointmentDate
                  ? moment(item.appointmentDate.split(".")[0]).format(
                    " hh.mm a"
                  )
                  : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {documentdetails?.length ? (
            <p>
              {documentcountnumber * 10 + 1} to{" "}
              {documentcountnumber * 10 + documentnumberofElements} of{" "}
              {documenttotalUser} records
            </p>
          ) : (
            <p>No Record Found </p>
          )}
        </div>
        <Pagination count={documentgetpageno} onChange={handlepage} shape="rounded" />
      </div>
    </div>
  );
};

export default Appointments;
