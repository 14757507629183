import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles,useStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import { CountryDropdown } from "react-country-region-selector";

const EditLocationModal = ({
    toast,
    handle403error,
    pageno,
    jwtToken,
    isw_user_name,
    inputEvent,
    stateCust,
    getalllocation,
    selectCountry,
    p,
}) => {
  const [editmodal, seteditmodal] = useState(false);
  const [editLocationName, seteditLocationName] = useState(p.name);
//   const [editCountry, seteditCountry] = useState(p.country);
  const [editLatitude, seteditLatitude] = useState(p.latitude);
  const [editLongitude, seteditLongitude] = useState(p.longitude);
  const [editstatus, seteditstatus] = useState(p.status);
  const [country1, setcountry] = useState(p.country);

  console.log(p);

  const classes = useStyles();
  const closeeditModal = () => {
    seteditmodal(false);
  };

  const openeditmodal = () => {
      seteditmodal(true);
  };


  const putCustomer = (e) => {
    p.country = country1;
    console.log(p);
    axios
      .post(`${base_url.api1}/updateLocationById`, p, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          toast.success("Success");

          let log =
            isw_user_name +
            " Updated Location " +
            response.data.data.name +
            ".";
          callsaveauditapi(log);
          seteditmodal(false);
          getalllocation(pageno);
        },
        (error) => {
          handle403error(error);
          seteditmodal(false);
        }
      );
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={openeditmodal}>
        Edit
      </span>
      <Modal
        isOpen={editmodal}
        onRequestClose={closeeditModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 style={{ color: "black" }}>
            Location
        </h3>
        <form
            onSubmit={(e) => {
                e.preventDefault();
                putCustomer(e);
            }}
        >
          <div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={6}>
                  <label>
                     Name
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={editLocationName}
                    onChange={(e) => {
                        seteditLocationName(
                        e.target.value
                        );
                    }}
                    className="form-control underline-input"
                    required
                  />
                </Col>
                <Col md={6}>
                  <label>
                   Country
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={country1}
                    // onChange={(val) => selectCountry(val)}
                    onChange={(val) => {
                        setcountry(val);
                    }}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <label>
                    Latitude
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    type="number"
                    name="latitude"
                    value={editLatitude}
                    onChange={(e) => {
                        seteditLatitude(
                        e.target.value
                        );
                    }}
                    className="form-control underline-input"
                    step="any"
                    required
                  />
                </Col>
                <Col md={6}>
                    <label>
                    Longitude
                    <span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                    type="number"
                    name="longitude"
                    value={editLongitude}
                    onChange={(e) => {
                        seteditLongitude(
                        e.target.value
                        );
                    }}
                    className="form-control underline-input"
                    step="any"
                    required
                    />
                </Col>
              </Row>
              <br/>
              <Row style={{marginTop:"20px"}}>
              <Col md={6}>
                <label>
                Status
                <span style={{ color: "red" }}>*</span>:
                </label>
                <select
                className="form-control"
                value={editstatus}
                name="status"
                onChange={(e) => {
                    seteditstatus(
                    e.target.value
                    );
                }}
                required
                >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
                </select>
              </Col>
                <Col md={6} className="d-flex justify-content-end align-items-end">
                  <Row className="justify-content-end mt-2">
                    <Button color="secondary" onClick={closeeditModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditLocationModal;