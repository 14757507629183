import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import attachmenturl from "../../api/attachmenturl";
import { withRouter } from "react-router-dom";
import SelectCurrency from "react-select-currency";
import "../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import { addtocollege } from "../features/collegeSlice";
import "../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Modal from "react-modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useForm } from "react-hook-form";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getproviderpermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import {
  useStyles,
  customStyles,
  customStylesProviderBranch,
} from "../../Utilities/CSSUtilities";
const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "horizontalLine",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "alignment",
      "|",
      // "imageUpload",
      // "imageInsert",
      "blockQuote",
      "fontBackgroundColor",
      "fontColor",
      "insertTable",
      "fontSize",
      "fontFamily",
      "htmlEmbed",
      // "mediaEmbed",
      "codeBlock",
      "code",
      "undo",
      "redo",
    ],
  },
  language: "en",
  // image: {
  //   toolbar: [
  //     "imageTextAlternative",
  //     "imageStyle:full",
  //     "imageStyle:side",
  //     "linkImage",
  //   ],
  // },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  licenseKey: "",
};
function CreateCollege(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const classes = useStyles();
  const [country1, setcountry] = useState();
  const [currency1, setcurrency] = useState("");
  const [region, setregion] = useState();
  const [country2, setcountry2] = useState();
  const [region2, setregion2] = useState();
  const [institutionBranches, setinstitutionBranches] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [descriptionState, setdescriptionState] = useState(null);
  const [providerName, setproviderName] = useState();
  const [institutionerror, setinstitutionerror] = useState(false);
  const [branchagreementopen, setbranchagreementopen] = useState(false);
  const [branchagreementfiles, setbranchagreementfiles] = useState();
  const [fileinstitutionId, setfileinstitutionid] = useState();
  const [filebranchId, setfilebranchId] = useState();
  const [appformfilesize, setappformfilesize] = useState(false);
  const [logofilesize, setlogofilesize] = useState(false);
  const [bannerfilesize, setbannerfilesize] = useState(false);
  const [transacrionalfilesize, settransacrionalfilesize] = useState(false);
  const [agreementfilesize, setagreementfilesize] = useState(false);
  const [checkboxstate, setState] = React.useState({
    university: false,
    higherEducation: false,
    vet: false,
    elicos: false,
    shortCourse: false,
    pyCourse: false,
  });

  const handleChange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(703, permission_obj, resource_obj)) {

      dispatch(setdashboardcheck(22));
    } else {
      history.push("/errorPage");
    }
  };
  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const selectCountry2 = (val) => {
    setcountry2(val);
  };
  const selectRegion2 = (val) => {
    setregion2(val);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [modalisopen, setisopen] = useState(false);
  const [modalisopen2, setisopen2] = useState(false);
  const openModal = () => {
    console.log("Clicked");
    setcountry2();
    setregion2();
    setisopen(true);
  };
  const [baseimage, setbaseimage] = useState("");
  const uploadLogo = async (data) => {
    console.log(data.target.files);
    const file = data.target.files[0];
    const base64 = await convertBase64(file);
    setbaseimage(base64);
  };

  const [baseimagetwo, setbaseimagetwo] = useState("");
  const uploadBanner = async (data) => {
    console.log(data.target.files);
    const file = data.target.files[0];
    const base64 = await convertBase64(file);
    setbaseimagetwo(base64);
  };
  const [providerid, setproviderid] = useState();

  //Create Branch
  const submitBranch = (data2) => {
    if (agreementfilesize) {
      return;
    }
    let formData = new FormData();
    let multiplefiles = data2.attachment;
    if (multiplefiles.length) {
      for (let i = 0; i < multiplefiles.length; i++) {
        formData.append(
          "transactionalAgreement",
          multiplefiles[i],
          multiplefiles[i].name
        );
      }
    }
    let data = {
      branchId: 0,
      institutionId: Number(providerid),
      branchName: providerName + "-" + data2.branchname,
      branchAddress: data2.branchaddress,
      branchContactDetails: descriptionState,
      city: data2.city,
      state: region2,
      country: country2,
      commissionPercentage: Number(data2.commissionpercentage),
      lattitude: data2.latitude,
      logo: baseimage,
      banner: baseimagetwo,
      longitude: data2.longitude,
    };
    axios
      .post(`${base_url.api2}/createInstitutionBranch`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Branch Created");

        if (multiplefiles.length) {
          axios
            .post(
              `${base_url.api2}/uploadBranchTransactionalAgreement`,
              formData,
              {
                headers: {
                  Authorization: jwtToken,
                  institutionId: Number(res.data.data.institutionId),
                  branchId: res.data.data.branchId,
                },
              }
            )
            .then((res) => {
              setinstitutionBranches((prev) => [...prev, res.data.data]);
              setisopen(false);
            })
            .catch((err) => {
              setisopen(false);
              toast.error("Error Uploading Agreement");
            });
        }
        if (!multiplefiles.length) {
          setinstitutionBranches((prev) => [...prev, res.data.data]);
          setisopen(false);
        }
      })
      .catch((err) => {
        setisopen(false);
        handle403error(err);
        toast.error(JSON.stringify(err.response.data.message));
      });
  };

  const selectcurrency = (e) => {
    setcurrency(e.target.value);
  };

  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const createProvider = (data2) => {
    if (appformfilesize || logofilesize || bannerfilesize || transacrionalfilesize) {
      return;
    }
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let abn = data2.abn;
    let acn = data2.acn;
    let addedOn = datenow;
    let address = data2.street;
    let status = Number(data2.status);
    let contactPerson = data2.contactPerson;
    let can = data2.can;
    let city = data2.city;
    let country = country1;
    let cricosProviderCode = data2.cricosProviderCode;
    let currency = currency1;
    let designation = data2.designation;
    let email = data2.email;
    let fax = data2.fax;
    let institutionCode;
    // if (data2.institutionCode == null){
    //   institutionCode = data2.institutionCode;
    // } else {
    //   institutionCode = 0;
    // }
    if (data2.institutionCode == 0) {
      institutionCode = null;
    } else {
      institutionCode = data2.institutionCode;
    }

    let institutionId = 0;
    let remarks = data2.remarks;
    let applicationFormLink = data2.appFormLink;
    let institutionTypearray = [];
    let arr = Object.keys(checkboxstate);
    if (remarks == "") {
      remarks = null;
    }

    for (let i = 0; i < arr.length; i++) {
      let key = arr[i];
      if (checkboxstate[key]) {
        institutionTypearray.push(key);
      }
    }
    let institutionType;
    for (let i = 0; i < institutionTypearray.length; i++) {
      if (i === 0) {
        institutionType = institutionTypearray[i];
      } else {
        institutionType += `,${institutionTypearray[i]}`;
      }
    }
    let latitude = data2.latitude;
    let logo = baseimage;
    let banner = baseimagetwo;
    let longitude = data2.longitude;
    let mobile = data2.mobile;
    let name = `${data2.name}(Head Office)`;
    setproviderName(data2.name);
    let overview = data2.overview;
    let pan = data2.pan;
    let phoneNumber = data2.phoneNumber;
    let rtoNumber = data2.rtoNumber;
    let state = region;
    let street = data2.street;
    let formData = new FormData();
    let formData2 = new FormData();
    let multiplefiles = data2.appForm;
    let fileToUpload = data2.transactionalArrangement;
    if (multiplefiles.length) {
      for (let i = 0; i < multiplefiles.length; i++) {
        formData2.append(
          "applicationForms",
          multiplefiles[i],
          multiplefiles[i].name
        );
      }
    }
    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append(
          "transactionalArrangement",
          fileToUpload[i],
          fileToUpload[i].name
        );
      }
    }
    let university = data2.university;
    let updatedOn = datenow;
    let website = data2.website;
    let zipCode = data2.zipCode;
    let providerCreatedBy = isw_user_name + ` ` + `(` + isw_user_email + `)`;
    let providerCreatedDate = datenow;
    let data = {
      abn,
      acn,
      addedOn,
      address,
      applicationFormLink,
      // appForm,
      can,
      city,
      country,
      contactPerson,
      cricosProviderCode,
      currency,
      designation,
      email,
      fax,
      institutionCode,
      institutionId,
      institutionType,
      latitude,
      logo,
      banner,
      longitude,
      mobile,
      name,
      overview,
      pan,
      phoneNumber,
      remarks,
      rtoNumber,
      state,
      status,
      street,
      // transactionalArrangement,
      university,
      updatedOn,
      website,
      zipCode,
      providerCreatedBy,
      providerCreatedDate,
    };
    let data3 = {
      branchAddress: address,
      branchContactDetails: address,
      branchName: name,
      city: city,
      country: country,
      branchId: 0,
      institutionId: 0,
      lattitude: latitude,
      longitude: longitude,
      state: state,
    };
    if (institutionType) {
      postCustomer(
        data,
        data3,
        formData,
        formData2,
        multiplefiles,
        fileToUpload
      );
      setinstitutionerror(false);
    } else {
      setinstitutionerror(true);
    }
  };

  const postCustomer = (
    data,
    data2,
    formData,
    formData2,
    multiplefiles,
    fileToUpload
  ) => {
    axios
      .post(`${base_url.api2}/saveInstitution`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          toast.success("Success");
          if (fileToUpload.length) {
            axios
              .post(
                `${base_url.api2}/uploadTransactionalArrangements`,
                formData,
                {
                  headers: {
                    Authorization: jwtToken,
                    institutionId: response.data.data.institutionId,
                  },
                }
              )
              .then((res) => {
                toast.success("Sucess");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (multiplefiles.length) {
            axios
              .post(
                `${base_url.api2}/uploadInstitutionApplicationForm`,
                formData2,
                {
                  headers: {
                    Authorization: jwtToken,
                    institutionId: response.data.data.institutionId,
                  },
                }
              )
              .then((res) => {
                toast.success("Sucess");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          dispatch(addtocollege(response.data.data));
          data2.institutionId = Number(response.data.data.institutionId);
          data2.logo = baseimage;
          data2.banner = baseimagetwo;
          //Create Branch
          axios
            .post(`${base_url.api2}/createInstitutionBranch`, data2, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then(
              (res) => {
                console.log("Provider response", res);
                setinstitutionBranches((prev) => [...prev, res.data.data]);
                setproviderid(res.data.data.institutionId);
              },
              (error) => {
                toast.error(JSON.stringify(error.response.data.message));
              }
            );
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };
  const goback = () => {
    history.push("/provider");
  };

  const chkappfilesize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setappformfilesize(chk);
  };
  const chklogosize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setlogofilesize(chk);
  };
  const chkbannersize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setbannerfilesize(chk);
  };
  const chktransactionalsize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    settransacrionalfilesize(chk);
  };
  const chkagreementsize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 4) {
        chk = true;
      }
    }
    setagreementfilesize(chk);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      {/* <Dashboard /> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Provider Add</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Partner</a><span className="s_span ">-</span>
          <a href="#/create-provider" className="b-link active">
            Provider Add
          </a>
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <Row>
            <Col md={6}>
              <CardText className={classes.headtext}>
                <strong>Provider</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row> */}
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form id="myForm" onSubmit={handleSubmit(createProvider)}>
          <div className="form-inner-admin form_inner_admin">
            {/* <div class="client_container2">
                  <span>Provider Details</span>
                </div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Provider Details</p>
              <hr style={{ flex: "1", marginLeft: "20px" }} />
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>
                    Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    className="form-control underline-input"
                    name="name"
                    ref={register({ required: true })}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {errors.name && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Cricos Provider Code
                    <span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    className="form-control underline-input"
                    name="cricosProviderCode"
                    ref={register({ required: false })}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {/* {errors.cricosProviderCode && (
                    <p className="errormessage">
                      Cricos Provider Code is Required
                    </p>
                  )} */}
                </Col>
                <Col md={3}>
                  <label>RTO Number(VET National Code):</label>
                  <input
                    className="form-control underline-input"
                    name="rtoNumber"
                    ref={register({ required: false })}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {errors.rtoNumber && (
                    <p className="errormessage">RTO Number is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>Provider Code:</label>
                  <input
                    className="form-control underline-input"
                    name="institutionCode"
                    ref={register}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>ABN:</label>
                  <input
                    className="form-control underline-input"
                    name="abn"
                    ref={register({ required: false })}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {errors.abn && (
                    <p className="errormessage">ABN is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>ACN:</label>
                  <input
                    className="form-control underline-input"
                    name="acn"
                    ref={register({ required: false })}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {errors.acn && (
                    <p className="errormessage">ACN is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>PAN:</label>
                  <input
                    className="form-control underline-input"
                    name="pan"
                    ref={register}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {/* {errors.pan && (
                        <p className="errormessage">PAN is Required</p>
                      )} */}
                </Col>
                <Col md={3}>
                  <label>CAN:</label>
                  <input
                    className="form-control underline-input"
                    name="can"
                    ref={register}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                  {/* {errors.can && (
                        <p className="errormessage">CAN is Required</p>
                      )} */}
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>
                    Currency<span style={{ color: "red" }}>*</span>:
                  </label>
                  <SelectCurrency
                    value={currency1}
                    onChange={selectcurrency}
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    required
                  />
                </Col>
                {/* <input
                        className="form-control underline-input"
                        name="currency"
                        ref={register({ required: true })}
                        type="text"
                      /> */}
                {/* {errors.currency && (
                        <p className="errormessage">Currency is Required</p>
                      )} */}
                <Col md={9}>
                  <label>
                    Provider Type<span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />
                  <Row style={{ gap: "5px" }}>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.university}
                              onChange={handleChange}
                              color="primary"
                              name="university"
                            />
                          }
                          label="University"
                        /> */}
                    <div class="containerthree">
                      <input
                        checked={checkboxstate.university}
                        onChange={handleChange}
                        type="checkbox"
                        id="flexCheckDefault"
                        name="university"
                      />
                      <label class="form-check-label boxes" for="flexCheckDefault"
                        style={{ marginLeft: "0px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        University
                      </label>
                      <label class="checkmarkthree" for="flexCheckDefault" style={{ marginTop: "10px" }}>
                      </label>
                    </div>
                    <br />
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.higherEducation}
                              onChange={handleChange}
                              color="primary"
                              name="higherEducation"
                            />
                          }
                          label="Higher Education"
                        /> */}
                    <div class="containerfour">
                      <input
                        checked={checkboxstate.higherEducation}
                        onChange={handleChange}
                        type="checkbox"
                        name="higherEducation"
                        id="flexCheck"
                      />
                      <label class="checkmarkfour" for="flexCheck" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheck"
                        style={{ marginLeft: "0px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Higher Education
                      </label>
                    </div>
                    <br />
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.vet}
                              onChange={handleChange}
                              name="vet"
                              color="primary"
                            />
                          }
                          label="VET"
                        /> */}
                    <div class="containerfive">
                      <input
                        checked={checkboxstate.vet}
                        onChange={handleChange}
                        type="checkbox"
                        name="vet"
                        id="flexCheckChecked"
                      />
                      <label class="checkmarkfive" for="flexCheckChecked" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckChecked"
                        style={{ marginLeft: "0px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        VET
                      </label>
                    </div>
                    <br />
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.elicos}
                              onChange={handleChange}
                              color="primary"
                              name="elicos"
                            />
                          }
                          label="ELICOS"
                        /> */}
                    <div class="containersix">
                      <input
                        checked={checkboxstate.elicos}
                        onChange={handleChange}
                        type="checkbox"
                        name="elicos"
                        id="flexChecked"
                      />
                      <label class="checkmarksix" for="flexChecked" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexChecked"
                        style={{ marginLeft: "0px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        ELICOS
                      </label>
                    </div>
                    <br />
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.shortCourse}
                              onChange={handleChange}
                              color="primary"
                              name="shortCourse"
                            />
                          }
                          label="Short Course"
                        /> */}
                    <div class="containerseven">
                      <input
                        checked={checkboxstate.shortCourse}
                        onChange={handleChange}
                        type="checkbox"
                        name="shortCourse"
                        id="CheckChecked"
                      />
                      <label class="checkmarkseven" for="CheckChecked" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="CheckChecked"
                        style={{ marginLeft: "0px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Short Course
                      </label>
                    </div>
                    <br />
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.pyCourse}
                              onChange={handleChange}
                              color="primary"
                              name="pyCourse"
                            />
                          }
                          label="PY Course"
                        /> */}
                    <div class="containereight">
                      <input
                        checked={checkboxstate.pyCourse}
                        onChange={handleChange}
                        type="checkbox"
                        name="pyCourse"
                        id="fChecked"
                      />
                      <label class="checkmarkeight" for="fChecked" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="fChecked"
                        style={{ marginLeft: "0px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        PY Course
                      </label>
                    </div>
                    <br />
                  </Row>
                  {institutionerror && (
                    <p
                      className="errormessage"
                      style={{ fontWeight: "bolder" }}
                    >
                      InstitutionType is Required
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            {/* <div class="client_container2">
                  <span>Provider Address</span>
                </div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              <hr style={{ flex: "1", marginLeft: "20px" }} />
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>
                    Street<span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                  {/* {errors.street && (
                    <p className="errormessage">Street is Required</p>
                  )} */}
                </Col>

                <Col md={3}>
                  <label>
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    className="countrydropdown2"
                    defaultOptionLabel="- Select Country -"
                    // className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={country1}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      className="countrydropdown2"
                      defaultOptionLabel="- Select State -"
                      // className="form-control underline-input"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    City<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="zipCode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.zipCode && (
                    <p className="errormessage">PostCode is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Website<span style={{ color: "red" }}></span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="website"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                  {/* {errors.website && (
                    <p className="errormessage">Website is Required</p>
                  )} */}
                </Col>
                <Col md={3}>
                  <label>Latitude:</label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    name="latitude"
                    step="any"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Longitude:</label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    name="longitude"
                    step="any"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>Student App Form Link:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="appFormLink"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Student App Form :</label>
                  <input
                    type="file"
                    onChange={chkappfilesize}
                    multiple
                    className="form-control underline-input"
                    name="appForm"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                  {appformfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label>Logo:</label>
                  <input
                    type="file"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(data) => {
                      uploadLogo(data);
                      chklogosize(data);
                    }}
                    accept=".jpg, .jpeg, .bmp, .gif, .png"
                  />
                  {logofilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label>Banner:</label>
                  <input
                    type="file"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(data) => {
                      uploadBanner(data);
                      chkbannersize(data);
                    }}
                    accept=".jpg, .jpeg, .bmp, .gif, .png"
                  />
                  {bannerfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>

              </Row>
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={3}>
                  <label>Transactional Arrangement:</label>
                  <input
                    type="file"
                    multiple
                    onChange={chktransactionalsize}
                    style={{ backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="transactionalArrangement"
                    ref={register}
                  />
                  {transacrionalfilesize && (
                    <p className="errormessage">
                      File Size cannot be more than 4 mb
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <label>University:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="university"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={6}>
                  <label>Overview:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="overview"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            {/* <div class="client_container2">
                  <span>Provider Contact Details</span>
                </div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              <hr style={{ flex: "1", marginLeft: "20px" }} />
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={3}>
                  <label>Contact Person:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="contactPerson"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Designation:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="designation"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Phone:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="phoneNumber"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Mobile:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="mobile"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={3}>
                  <label>Email:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="email"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Status:</label>
                  <select
                    className="form-control"
                    name="status"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
                <Col md={6}>
                  <label>Remarks:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="remarks"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end">
                <button
                  className="outline-button"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    document.getElementById("myForm").reset();
                  }}
                >
                  Reset
                </button>
                <button className="sent-button" type="submit">
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </form>
        {institutionBranches.length !== 0 ? (
          <>
            <div className="d-flex justify-content-end my-1">
              <button
                className="btn listing_addbutton"
                onClick={() => openModal()}
              >
                Add Branches
              </button>
            </div>
            <Modal
              isOpen={modalisopen}
              onRequestClose={() => setisopen(false)}
              style={customStylesProviderBranch}
              contentLabel="Example Modal"
            >
              <div>
                <h2>Provider Branch</h2>
                <hr />
                <form id="saveForm" onSubmit={handleSubmit(submitBranch)}>
                  <Row>
                    <Col md={3}>
                      <label>
                        Branch Name<span style={{ color: "red" }}>*</span>:
                      </label>
                      <input
                        name="branchname"
                        ref={register({ required: true })}
                        type="text"
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control underline-input"
                      />
                      {errors.branchname && (
                        <p className="errormessage">BranchName is Required</p>
                      )}
                    </Col>
                    <Col md={3}>
                      <label>
                        {" "}
                        Address<span style={{ color: "red" }}>*</span>:
                      </label>
                      <input
                        name="branchaddress"
                        ref={register({ required: true })}
                        type="textarea"
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control underline-input"
                      />
                      {errors.branchaddress && (
                        <p className="errormessage">Address is Required</p>
                      )}
                    </Col>
                    <Col md={3}>
                      <label>
                        Country<span style={{ color: "red" }}>*</span>:
                      </label>
                      <CountryDropdown
                        className="countrydropdown2"
                        // className="form-control underline-input"
                        defaultOptionLabel="- Select Country -"
                        value={country2}
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(val) => selectCountry2(val)}
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <label>
                        State<span style={{ color: "red" }}>*</span>:
                      </label>
                      {country2 == "Nepal" ? (
                        <select
                          className={classes.selectdrop}
                          name="gender"
                          style={{ backgroundColor: "#f5f5f9" }}
                          onChange={(e) => selectRegion2(e.target.value)}
                        >
                          <option value="">- Select Province -</option>
                          <option value="Province No. 1">Province No. 1</option>
                          <option value="Madhesh Province">
                            Madhesh Province
                          </option>
                          <option value="Bagmati Province">
                            Bagmati Province
                          </option>
                          <option value="Gandaki Province">
                            Gandaki Province
                          </option>
                          <option value="Lumbini Province">
                            Lumbini Province
                          </option>
                          <option value="Karnali Province">
                            Karnali Province
                          </option>
                          <option value="Sudurpashchim Province">
                            Sudurpashchim Province
                          </option>
                        </select>
                      ) : (
                        <RegionDropdown
                          className="countrydropdown2"
                          defaultOptionLabel="- Select State -"
                          // className="form-control underline-input"
                          country={country2}
                          value={region2}
                          style={{ backgroundColor: "#f5f5f9" }}
                          onChange={(val) => selectRegion2(val)}
                          required
                        />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <label>
                        City<span style={{ color: "red" }}>*</span>:
                      </label>
                      <input
                        name="city"
                        ref={register({ required: true })}
                        type="textarea"
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control underline-input"
                      />
                      {errors.city && (
                        <p className="errormessage">City is Required</p>
                      )}
                    </Col>
                    <Col md={3}>
                      <label>Latitude:</label>
                      <input
                        name="latitude"
                        ref={register({ required: false })}
                        type="number"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onWheel={(event) => event.currentTarget.blur()}
                        step="any"
                        className="form-control underline-input"
                      />
                      {/* {errors.latitude && (
                            <p className="errormessage">Latitude is Required</p>
                          )} */}
                    </Col>
                    <Col md={3}>
                      <label>Longitude:</label>
                      <input
                        name="longitude"
                        ref={register({ required: false })}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        step="any"
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control underline-input"
                      />
                      {/* {errors.longitude && (
                            <p className="errormessage">
                              Longitude is Required
                            </p>
                          )} */}
                    </Col>
                    <Col md={3}>
                      <label>Commission %:</label>
                      <input
                        name="commissionpercentage"
                        style={{
                          textAlign: "right",
                          backgroundColor: "#f5f5f9",
                        }}
                        ref={register({ required: false })}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        className="form-control underline-input"
                        required
                      />
                      {errors.commissionpercentage && (
                        <p className="errormessage">Commission % is Required</p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <label>Agreement:</label>
                      <input
                        name="attachment"
                        multiple
                        onChange={chkagreementsize}
                        ref={register}
                        type="file"
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control underline-input"
                      // accept=".pdf, .doc, .docx"
                      />
                      {agreementfilesize && (
                        <p className="errormessage">
                          File Size cannot be more than 4 mb
                        </p>
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <label
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                      >
                        Contact Details
                        <span style={{ color: "red" }}>*</span>:
                      </label>
                      <CKEditor
                        editor={Editor}
                        config={editorConfiguration}
                        data="<p>Type your content</p>"
                        onReady={(editor) => {
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setdescriptionState(data);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Create Branch
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => setisopen(false)}
                      style={{ marginLeft: "20px" }}
                    >
                      Close
                    </button>
                  </Row>
                  <br />
                </form>
              </div>
            </Modal>
            {institutionBranches.length !== 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Branch Name</TableCell>
                      <TableCell align="right">Address</TableCell>
                      <TableCell align="right">Commission Percentage</TableCell>
                      <TableCell align="right">Attachments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {institutionBranches.map((p, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {p.branchName}
                        </TableCell>
                        <TableCell align="right">{p.branchAddress}</TableCell>
                        <TableCell align="right">
                          {p.commissionPercentage}
                        </TableCell>
                        <TableCell align="right">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setbranchagreementopen(true);
                              setfileinstitutionid(p.institutionId);
                              setfilebranchId(p.branchId);
                              setbranchagreementfiles(
                                p.branchTransactionalAgreement
                              );
                            }}
                          >
                            View
                          </a>
                          <Modal
                            isOpen={branchagreementopen}
                            onRequestClose={() => setbranchagreementopen(false)}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <div>
                              <h5>Application Forms</h5>
                              <hr />
                              <TableContainer
                                style={{ marginTop: "10px" }}
                                component={Paper}
                              >
                                <Table
                                  className={classes.table}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{
                                      backgroundColor: "cornflowerblue",
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell>SN</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell align="right">View</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {branchagreementfiles?.length != null ? (
                                    <TableBody>
                                      {branchagreementfiles?.map((p, index) => (
                                        <TableRow key={index}>
                                          <TableCell component="th" scope="row">
                                            {index + 1}
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {p.branchTransactionalAgreementName}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="right"
                                          >
                                            <a
                                              target="_blank"
                                              href={
                                                `${attachmenturl}/attachments/institutionBranchAgreements/` +
                                                fileinstitutionId +
                                                "/" +
                                                filebranchId +
                                                "/" +
                                                p.branchTransactionalAgreementName
                                              }
                                              download
                                            >
                                              {
                                                p.branchTransactionalAgreementName
                                              }
                                            </a>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  ) : (
                                    "No Files Available"
                                  )}
                                </Table>
                              </TableContainer>
                              <button
                                type="submit"
                                className="btn btn-secondary"
                                style={{
                                  marginRight: "20px",
                                  marginTop: "20px",
                                  float: "right",
                                }}
                                onClick={() => setbranchagreementopen(false)}
                              >
                                Close
                              </button>
                            </div>
                          </Modal>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </>
        ) : null}
      </div>
      {/* </Card> */}
    </div>
  );
}

export default withRouter(CreateCollege);
