import React from "react";
import { useStyles } from "../../Utilities/CSSUtilities";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
const CourseDetailPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const goback = () => {
    history.push("/course");
  };
  return (
    <div style={{ margin: "20px" }}>
      <Row>
        <Col md={6}>{null}</Col>
        <Col md={6} className="d-flex justify-content-end">
          <button className="btn listing_addbutton" onClick={goback}>
            &#x2630; List
          </button>
        </Col>
      </Row>
      {props.courseType === "Overview" ? (
        <div
          dangerouslySetInnerHTML={{
            __html: props.coursedetail?.courseOverview,
          }}
        />
      ) : (
        <>
          {props.courseType === "Requirement" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.coursedetail?.requirement,
              }}
            />
          ) : (
            <>
              {props.courseType === "Whatwillstudy" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.coursedetail?.courseWhatWillYouStudy,
                  }}
                />
              ) : (
                <>
                  {props.courseType === "Payment" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.coursedetail?.paymentSummary,
                      }}
                    />
                  ) : (
                    <>
                      {props.courseType === "Objective" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.coursedetail?.courseObjective,
                          }}
                        />
                      ) : (
                        <>
                          {props.courseType === "Contact" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: props.coursedetail?.contactDetail,
                              }}
                            />
                          ) : (
                            <p>No Record Present</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CourseDetailPage;
