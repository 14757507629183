import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import "./../css/LoginForm.css";
import "./../css/Responsive.css";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission ,check4pointpermission} from "../Permission/permission";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
    marginLeft: "20px",
  },
  createBtn: {
    float: "right",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function CreateOffice(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const admin_UserId = localStorage.getItem("isw_adminId");
  const user = useSelector(selectUser);
  const classes = useStyles();
  const history = useHistory();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const [setComment, setsetComment] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(605, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(21));
    } else {
      history.push("/errorPage");
    }
  };

  const onSubmit = (data2) => {
    // for (let i = 0; i < e.target.length; i++) {
    //     console.log(e.target[i].value);
    // }
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let adminUserId = admin_UserId;
    let officeName = data2.officeName;
    let street = data2.street;
    let city = data2.city;
    let contactPerson = data2.contactPerson;
    let emailId = data2.email;
    let phone = data2.phone;
    let longitude = data2.longitude;
    let latitude = data2.latitude;
    let country = country1;
    let state = region;
    let zipCode = data2.zipcode;
    let officeCode = data2.officeCode.toUpperCase();
    let officeId = 0;
    let addedOn = datenow;
    let comment = setComment;
    let status = Number(data2.status);
    let data = {
      adminUserId,
      officeName,
      city,
      contactPerson,
      emailId,
      phone,
      longitude,
      latitude,
      street,
      country,
      state,
      zipCode,
      officeCode,
      officeId,
      addedOn,
      comment,
      status,
    };
    postOffice(data);
  };

  const postOffice = (data) => {
    // console.log(data);
    axios
      .post(`${base_url.api1}/addNewOffice`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        toast.success("Success");

        let log = isw_user_name + " Added Office " + response.data.data.officeName + ".";
        callsaveauditapi(log);
        history.push("/office");
      })
      .catch((error) => {
        // console.log(JSON.stringify(error));
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const goback = () => {
    history.push("/office");
  };

  return (
    <div>
      {/* <Card className="commoncomponent_cardcss"> */}
      <div className="page-head d-flex justify-content-between p-4 "
      style={{ marginTop: "4.3rem",marginBottom:"0px" }} 
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Create Branch</h3>
          <a href="#/" className="b-link">Home</a>
          <span className="s_span ">-</span>
          <a  className="b-link">Access Level</a>
          <span className="s_span ">-</span>
          <a  className="b-link active">Branch</a>
        </div>
        <a onClick={goback} className="sent-button">List</a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <Row>
            <Col md={4}>
              <CardText
                className={classes.headtext}
                style={{ float: "left"}}
              >
                <strong>Create Branch</strong>
              </CardText>
            </Col>
            <Col md={4}>{null}</Col>
            <Col md={4} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row> */}
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form id="myForm" onSubmit={handleSubmit(onSubmit)} style={{marginTop:"-20px"}}>
          <div className="form-inner-admin">
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    Branch <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="officeName"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.officeName && (
                    <p className="errormessage">OfficeName is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Email<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="email"
                    autoComplete="off"
                    style={{ backgroundColor: "#f5f5f9" }}
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                    className="form-control underline-input"
                    name="email"
                    ref={register({ required: true })}
                  />
                  {errors.email && (
                    <p className="errormessage">Email is Required</p>
                  )}
                </Col>

                <Col md={3}>
                  <label>Contact Person:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="contactPerson"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>

                <Col md={3}>
                  <label>Contact No.:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="phone"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    Address<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.street && (
                    <p className="errormessage">Address is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    City<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.city && (
                    <p className="errormessage">City is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Country<span style={{ color: "red" }}>*</span>:
                  </label>
                  <CountryDropdown
                    className="form-control underline-input countrydropdown2"
                    defaultOptionLabel="- Select Country -"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                    required
                  />
                </Col>
                <Col md={3}>
                  <label>
                    State<span style={{ color: "red" }}>*</span>:
                  </label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">
                        Bagmati Province
                      </option>
                      <option value="Gandaki Province">
                        Gandaki Province
                      </option>
                      <option value="Lumbini Province">
                        Lumbini Province
                      </option>
                      <option value="Karnali Province">
                        Karnali Province
                      </option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      className="form-control underline-input countrydropdown2"
                      defaultOptionLabel="- Select State -"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                      required
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label>
                    Latutude<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    name="latitude"
                    style={{ backgroundColor: "#f5f5f9" }}
                    step="any"
                    ref={register({ required: true })}
                  />
                  {errors.latitude && (
                    <p className="errormessage">Latitude is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Longitude<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    name="longitude"
                    style={{ backgroundColor: "#f5f5f9" }}
                    step="any"
                    ref={register({ required: true })}
                  />
                  {errors.longitude && (
                    <p className="errormessage">Longitude is Required</p>
                  )}
                </Col>

                <Col md={3}>
                  <label>
                    Branch Code<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="officeCode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    maxLength="7"
                    ref={register({ required: true })}
                  />
                  {errors.officeCode && (
                    <p className="errormessage">Branch Code is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    PostCode<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="zipcode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.zipcode && (
                    <p className="errormessage">Zipcode is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
               <Col md={3}>
            <label>
              Status<span style={{ color: "red" }}>*</span>
            </label>
                  <select
                    className="form-control"
                    name="status"
                    ref={register({ required: true })}
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {errors.status && (
                    <p className="errormessage">Status is Required</p>
                  )}
                </Col>
              </Row>
            </div>  
            <div className="form-group creatadmin">
              <Row>
                <Col md={12}>
                  <label>Remarks:</label>
                  <TextareaAutosize
                    rowsMax={6}
                    style={{ height: "100px", backgroundColor: "#f5f5f9" }}
                    // defaultValue={clientuser?.comments}
                    className="form-control underline-input"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setsetComment(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </div>    
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end">
                <button
                  className="outline-button"
                  style={{marginRight:"5px"}}
                  onClick={() => {
                    document.getElementById("myForm").reset();
                  }}
                >
                  Reset
                </button>
                <button type="submit" className="sent-button">
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
}

export default withRouter(CreateOffice);
