import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import "../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import { addtocourse } from "../features/courseSlice";
import "../css/Responsive.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { getcoursepermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

function CreateCourses(props) {
  const [backdropopen, setbackdropopen] = useState();
  const history = useHistory();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const jwtToken = "Bearer " + user.isw_user_token;
  const classes = useStyles();
  const editorRef = useRef();
  const { register, handleSubmit, errors } = useForm();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [selectedBranches, setselectedBranches] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [sortState, setsortState] = useState("institutionId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(400);


  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check1pointpermission(902, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(23));
      getArea();
      getCategory();
      getLevel();
      getIntake();
      getBranches();
      getvatfromadmin();
      getAllCollege(0, statusstate);
    } else {
      history.push("/errorPage");
    }
  };

  const [allcollege, setAllCollege] = useState();


  const getAllCollege = (value1, statusstate) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("98", response.data.data.content);
          var collegeList = response.data.data.content;
          setAllCollege(response.data.data.content);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [vatvalue, setvatvalue] = useState([]);

  const getvatfromadmin = () => {
    let value = [];
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "vet",
        },
      })
      .then((res) => {
        value.push({
          name: "VET",
          value: res.data.data.configValue,
        });
        axios
          .get(`${base_url.api3}/getByConfigKey`, {
            headers: {
              configKey: "trimester/vet",
            },
          })
          .then((res) => {
            value.push({
              name: "Trimester/VET",
              value: res.data.data.configValue,
            });
            axios
              .get(`${base_url.api3}/getByConfigKey`, {
                headers: {
                  configKey: "master/vet",
                },
              })
              .then((res) => {
                value.push({
                  name: "Master/VET",
                  value: res.data.data.configValue,
                });
                axios
                  .get(`${base_url.api3}/getByConfigKey`, {
                    headers: {
                      configKey: "bachelor/vet",
                    },
                  })
                  .then((res) => {
                    value.push({
                      name: "Bachelor/VET",
                      value: res.data.data.configValue,
                    });
                    setvatvalue(value);
                  });
              });
          });
      });
  };
  const [allBranchProvider, setAllBranchProvider] = useState();
  const getBranches = () => {
    axios
      .get(`${base_url.api2}/findAllInstitutionBranches`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAllBranchProvider(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [availableareaId, setAvailableareaId] = useState(null);
  const getArea = () => {
    axios
      .get(`${base_url.api1}/findAllArea`)
      .then((response) => {
        console.log(response.data.data);
        setAvailableareaId(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [availableCategory, setAvailableCategory] = useState(null);
  const getCategory = () => {
    axios
      .get(`${base_url.api1}/findAllCategory`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setAvailableCategory(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [availableLevel, setAvailableLevel] = useState(null);
  const getLevel = () => {
    axios
      .get(`${base_url.api1}/findAllLevel`)
      .then((response) => {
        console.log(response.data.data);
        setAvailableLevel(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const [allIntake, setAllIntake] = useState(null);
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        const office_obj = [];

        for (let i = 0; i < response.data.data.length; i++) {
          let data = {
            value: response.data.data[i].name,
            label: response.data.data[i].name,
            id: response.data.data[i].id,
          };
          office_obj.push(data);
        }
        setAllIntake(office_obj);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response));
      });
  };

  const [selectedArea, setselectedArea] = useState(null);
  const [selectedAreaName, setselectedAreaName] = useState(null);
  const handleChangeArea = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedArea(optionElementId);
    setselectedAreaName(optionElementName);
  };

  const [selectedCategory, setselectedCategory] = useState(null);
  const [selectedCategoryName, setselectedCategoryName] = useState(null);
  const handleChangeCategory = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedCategory(optionElementId);
    setselectedCategoryName(optionElementName);
  };

  const [selectedLevel, setselectedLevel] = useState(null);
  const [selectedLevelName, setselectedLevelName] = useState(null);
  const handleChangeLevel = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedLevel(optionElementId);
    setselectedLevelName(optionElementName);
  };
  const [intakeState, setintakeState] = useState();
  const onSelect = (selectedList, selectedItem) => {
    const client_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = selectedList[i].value;
      client_obj.push(data);
    }
    setintakeState(client_obj);
  };
  const [descriptionState, setdescriptionState] = useState(null);
  const descriptionChange = (event) => {
    setdescriptionState(event);
  };

  const [objectivestate, setobjectivestate] = useState(null);
  const objectiveChange = (event) => {
    setobjectivestate(event);
  };

  const [coursestudystate, setcoursestudystate] = useState(null);
  const courseStudyChange = (event) => {
    setcoursestudystate(event);
  };

  const [requirementstate, setrequirementstate] = useState(null);
  const requirementChange = (event) => {
    setrequirementstate(event);
  };

  const [paymentstate, setpaymentstate] = useState(null);
  const paymentChange = (event) => {
    setpaymentstate(event);
  };

  const [contactstate, setcontactstate] = useState(null);
  const contactChange = (event) => {
    setcontactstate(event);
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    console.log(files, info);
  };

  const [typestate, settypestate] = useState("flexible");
  const [statusstate, setstatusstate] = useState("1");

  const [intakeerror, setintakeerror] = useState(false);
  const onSubmit = (data2) => {
    if (!intakeState || intakeState.length === 0) {
      setintakeerror(true);
      return;
    }
    setintakeerror(false);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let courseId = 0;
    let name = data2.name;
    let fees = Number(data2.fees);
    let offShoreFees = Number(data2.offshorefees);
    let appFees = data2.appfees;
    let materialFees = Number(data2.materialFees);
    let otherFees = Number(data2.otherFees);
    let areaId = Number(selectedArea);
    let areaName = selectedAreaName;
    let institutionId = Number(selectedBranches[0].institutionId);
    let institutionName = selectedBranches[0].name;
    let city = selectedBranches[0].city;
    let country = selectedBranches[0].country;
    let comment = data2.comment;
    let commission = data2.commission;
    let state = selectedBranches[0].state;
    let categoryId = Number(selectedCategory);
    let categoryName = selectedCategoryName;
    let levelId = Number(selectedLevel);
    let levelName = selectedLevelName;
    let duration = data2.duration;
    let intake = intakeState.join(",");
    let courseCode = data2.coursecode;
    let numberOfUnit = data2.numberOfUnit;
    let cricos = data2.cricos;
    let atar = data2.atar;
    let uac = data2.uac;
    let courseOverview = descriptionState;
    let courseObjective = objectivestate;
    let courseWhatWillYouStudy = coursestudystate;
    let requirement = requirementstate;
    let paymentSummary = paymentstate;
    let contactDetail = contactstate;
    let type = typestate;
    let status = Number(statusstate);
    let published = Number(data2.published);
    let courseRating = data2.courseRating;
    let semester = data2.semester;
    let addedOn = datenow;
    let updatedOn = datenow;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    let updatedBy = isw_user_name + " (" + isw_user_email + ")";
    let popular = 0;
    let data = {
      addedOn,
      appFees,
      areaId,
      areaName,
      atar,
      categoryId,
      categoryName,
      city,
      comment,
      commission,
      contactDetail,
      country,
      courseCode,
      courseId,
      courseRating,
      courseObjective,
      courseOverview,
      courseWhatWillYouStudy,
      cricos,
      duration,
      fees,
      institutionId,
      institutionName,
      intake,
      levelId,
      levelName,
      materialFees,
      name,
      numberOfUnit,
      offShoreFees,
      otherFees,
      paymentSummary,
      published,
      requirement,
      state,
      status,
      semester,
      type,
      uac,
      updatedOn,
      createdBy,
      updatedBy,
      popular,
    };
    console.log(data);
    postCustomer(data);
  };

  const postCustomer = (data) => {
    axios
      .post(`${base_url.api2}/saveCourse`, data, {
        headers: {
          Authorization: jwtToken,
          courseType: typestate,
        },
      })
      .then((response) => {
        toast.success("Success");
        dispatch(addtocourse(response.data.data));
        let log =
          isw_user_name +
          " Created Course " +
          response.data.data.name +
          " for " +
          response.data.data.institutionName +
          ".";
        callsaveauditapi(log);
        history.push("/course");
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const goback = () => {
    history.push("/course");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Dashboard /> */}
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem", marginBottom: "5px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Course</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          -<a className="b-link">Course</a>-
          <a href="#/create-course" className="b-link active">
            Add
          </a>
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ float: "left", marginLeft: "15px" }}
              >
                <strong>Course</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row> */}
        {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
        <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-inner-admin">
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Course Code:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="coursecode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                  {/* {errors.coursecode && (
                      <p className="errormessage">CourseCode is Required</p>
                    )} */}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="name"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.name && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Cricos<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="cricos"
                    style={{ backgroundColor: "#f5f5f9" }}
                    // onChange={(e)=>{
                    //   axios.get(`${base_url.api2}/getByCricos`, {
                    //     headers: {
                    //       Authorization: jwtToken,
                    //       cricos: e.target.value,
                    //     },
                    //   })
                    //   .then((response) => {
                    //     setnewCricos(response.data.data);
                    //   })
                    //   .catch((error) => {
                    //     console.log(error);
                    //   });
                    // }}
                    ref={register({ required: true })}
                  />
                  {errors.cricos && (
                    <p className="errormessage">Cricos is Required</p>
                  )}
                  {/* {newCricos==false?
                        <p className="errormessage">Cricos Already Exist</p>:null
                      } */}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Total No. of Units:
                  </label>
                  <input
                    type="number"
                    style={{ textAlign: "right", backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    placeholder="0"
                    name="numberOfUnit"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Onshore Fee (Domestic)
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    placeholder="0.00"
                    name="fees"
                    style={{ backgroundColor: "#f5f5f9", textAlign: "right" }}
                    ref={register({ required: true })}
                  />
                  {errors.fees && (
                    <p className="errormessage">Fees is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Offshore Fee (International)
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="number"
                    style={{ textAlign: "right", backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    placeholder="0.00"
                    name="offshorefees"
                    ref={register({ required: true })}
                  />
                  {errors.offshorefees && (
                    <p className="errormessage">Offshore Fees is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>App Fees:</label>
                  <input
                    type="number"
                    style={{ textAlign: "right", backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    placeholder="0.00"
                    name="appfees"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Material Fees:</label>
                  <input
                    type="number"
                    style={{ textAlign: "right", backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    placeholder="0.00"
                    name="materialFees"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={6} className="providerpadding">
                  <label style={{ fontWeight: "bold" }}>
                    Provider<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(event) => {
                      const index = event.target.selectedIndex;
                      const optionElement = event.target.childNodes[index];
                      const optionElementId = optionElement.getAttribute("id");
                      console.log("optionElementId--->", optionElementId);
                      const optionElementName =
                        optionElement.getAttribute("value");
                      const optionElementstate =
                        optionElement.getAttribute("state");
                      const optionElementcity =
                        optionElement.getAttribute("city");
                      const optionElementcountry =
                        optionElement.getAttribute("country");
                      let data = {
                        institutionId: Number(optionElementId),
                        name: optionElementName,
                        courseId: 0,
                        id: 0,
                        state: optionElementstate,
                        city: optionElementcity,
                        country: optionElementcountry,
                      };

                      setselectedBranches([data]);
                    }}
                    required
                  >
                    <option value="">- Select College -</option>
                    {allcollege?.map((cuntitem, index) => (
                      <option
                        key={index}
                        value={cuntitem.name}
                        id={cuntitem.institutionId}
                        state={cuntitem.state}
                        city={cuntitem.city}
                        country={cuntitem.country}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Other Fees:</label>
                  <input
                    type="number"
                    style={{ textAlign: "right", backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    placeholder="0.00"
                    name="otherFees"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Category<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      backgroundColor: "#f5f5f9",
                    }}
                    className={classes.selectdrop}
                    onChange={handleChangeCategory}
                    name="category"
                    ref={register({ required: true })}
                  >
                    <option value="">- Select Category -</option>
                    {availableCategory?.map((cuntitem) => (
                      <option
                        key={cuntitem.category_id}
                        value={cuntitem.name}
                        id={cuntitem.category_id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                  {errors.category && (
                    <p className="errormessage">Category is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={6}>
                  <label style={{ fontWeight: "bold" }}>
                    Intake<span style={{ color: "red" }}>*</span>:
                  </label>
                  <Select
                    isMulti
                    name="intake"
                    options={allIntake}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onSelect}
                    required
                  />
                  {intakeerror && (
                    <p className="errormessage">Intake is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Level<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      backgroundColor: "#f5f5f9",
                    }}
                    className={classes.selectdrop}
                    onChange={handleChangeLevel}
                    name="level"
                    ref={register({ required: true })}
                  >
                    <option value="">- Select Level -</option>
                    {availableLevel?.map((cuntitem) => (
                      <option
                        key={cuntitem.level_id}
                        value={cuntitem.name}
                        id={cuntitem.level_id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                  {errors.level && (
                    <p className="errormessage">Level is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Area<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    onChange={handleChangeArea}
                    name="area"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  >
                    <option value="">- Select Area -</option>
                    {availableareaId?.map((cuntitem) => (
                      <option
                        key={cuntitem.area_id}
                        value={cuntitem.name}
                        id={cuntitem.area_id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                  {errors.area && (
                    <p className="errormessage">Area is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Duration<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="duration"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.duration && (
                    <p className="errormessage">Duration is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Atar:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="atar"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>UAC:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="uac"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Type:</label>
                  <select
                    className={classes.selectdrop}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(event) => {
                      // console.log(event.target.value);
                      settypestate(event.target.value);
                    }}
                  >
                    <option value="flexible">Flexible</option>
                    <option value="scholarship">Scholarship</option>
                  </select>
                  {/* <input type="text" className="form-control underline-input" required /> */}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Status<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className={classes.selectdrop}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(event) => {
                      // console.log(event.target.value);
                      setstatusstate(event.target.value);
                    }}
                    name="status"
                    ref={register({ required: true })}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {errors.status && (
                    <p className="errormessage">Status is Required</p>
                  )}
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Semister :</label>
                  <select
                    className={classes.selectdrop}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(event) => {
                      // console.log(event.target.value);
                      setstatusstate(event.target.value);
                    }}
                    name="semester"
                    ref={register}
                  >
                    <option value="">-Select Semister-</option>
                    {vatvalue.map((p, index) => (
                      <option key={index + 1} value={p.value}>
                        {p.value}-{p.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Commission(%):</label>
                  <input
                    type="number"
                    step="0.001"
                    className="form-control underline-input"
                    name="commission"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>
                    Published<span style={{ color: "red" }}>*</span>:
                  </label>
                  <select
                    className="form-control"
                    name="published"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {errors.published && (
                    <p className="errormessage">Published is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col md={3}>
                  <label style={{ fontWeight: "bold" }}>Rating:</label>
                  <select
                    className="form-control"
                    name="courseRating"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  >
                    <option value=""> - Select Rating - </option>
                    <option value="0.5">0.5</option>
                    <option value="1">01</option>
                    <option value="1.5">1.5</option>
                    <option value="2">02</option>
                    <option value="2.5">2.5</option>
                    <option value="3">03</option>
                    <option value="3.5">3.5</option>
                    <option value="4">04</option>
                    <option value="4.5">4.5</option>
                    <option value="5">05</option>
                  </select>
                </Col>
                <Col md={9}>
                  <label style={{ fontWeight: "bold" }}>Remark:</label>
                  <textarea
                    className="form-control underline-input"
                    name="comment"
                    rows="4"
                    placeholder="Write Remarks"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ margin: "40px 0" }}>
              <ul className="nav nav-tabs ">
                <li className="nav-item ">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#courseoverview"
                  >
                    Course Overview
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#coursestudy">
                    Course What Will Study
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#payment">
                    Payment Summary
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#courseobjective"
                  >
                    Course Objective
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#contact">
                    Contact Detail
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#requirement">
                    Requirement
                  </a>
                </li>
              </ul>
              <hr />
              <div
                className="tab-content"
                style={{ border: "1px solid  #dee2e6" }}
              >
                <div id="courseoverview" className="container tab-pane active">
                  <br />
                  <Col md={12}>
                    <SunEditor
                      ref={editorRef}
                      height="200"
                      placeholder="Please type here..."
                      onChange={descriptionChange}
                      onImageUploadBefore={handleImageUploadBefore}
                      required
                    />
                  </Col>
                </div>
                <div id="coursestudy" className="container tab-pane fade">
                  <br />
                  <Col md={12}>
                    <SunEditor
                      ref={editorRef}
                      height="200"
                      placeholder="Please type here..."
                      onChange={courseStudyChange}
                      onImageUploadBefore={handleImageUploadBefore}
                      required
                    />
                  </Col>
                </div>
                <div id="payment" className="container tab-pane fade">
                  <br />
                  <Col md={12}>
                    <SunEditor
                      ref={editorRef}
                      height="200"
                      placeholder="Please type here..."
                      onChange={paymentChange}
                      onImageUploadBefore={handleImageUploadBefore}
                      required
                    />
                  </Col>
                </div>
                <div id="courseobjective" className="container tab-pane fade">
                  <br />
                  <Col md={12}>
                    <SunEditor
                      ref={editorRef}
                      height="200"
                      placeholder="Please type here..."
                      onChange={objectiveChange}
                      onImageUploadBefore={handleImageUploadBefore}
                      required
                    />
                  </Col>
                </div>
                <div id="contact" className="container tab-pane fade">
                  <br />
                  <Col md={12}>
                    <SunEditor
                      ref={editorRef}
                      height="200"
                      placeholder="Please type here..."
                      onChange={contactChange}
                      onImageUploadBefore={handleImageUploadBefore}
                      required
                    />
                  </Col>
                </div>
                <div id="requirement" className="container tab-pane fade">
                  <br />
                  <Col md={12}>
                    <SunEditor
                      ref={editorRef}
                      height="200"
                      placeholder="Please type here..."
                      onChange={requirementChange}
                      onImageUploadBefore={handleImageUploadBefore}
                      required
                    />
                  </Col>
                </div>
              </div>
            </div>
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end">
                <button
                  className="outline-button"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    document.getElementById("myForm").reset();
                  }}
                >
                  Reset
                </button>
                <button type="submit" className="sent-button">
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
}

export default withRouter(CreateCourses);