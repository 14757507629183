import React from "react";
import ReactDragListView from "react-drag-listview/lib/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DatePicker from "react-datepicker";
import { customStyles } from "../../Utilities/CSSUtilities";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { Typography } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

class PaymentSchedule extends React.PureComponent {
  constructor(props) {
    super(props);
    const data = this.props.data;
    this.state = {
      data,
      paymentmodal: false,
      setPaidPayment: "",
      deletemodal: false,
      setdeletepayment: "",
    };
  }
  componentDidMount() {
    console.log("enterinf");
    this.setState({
      data: this.props.data,
    });
  }
  render() {
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: "tr",
      handleSelector: "a",
    };
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.props.submitpaymentschedule(this.state.data);
        }}
      >
        <ReactDragListView {...this.dragProps}>
          <TableContainer component={Paper}>
            <Table style={{ minWidth: "650px" }}>
              <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                <TableRow>
                  <TableCell align="center">SN</TableCell>
                  <TableCell>Semester</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Commission(%)</TableCell>
                  <TableCell>Paid</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="clientgeneralinvoicetables">
                {this.state.data.map((p, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <input
                        placeholder="Enter Semester.."
                        value={p.semester}
                        className="form-control"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(e) => {
                          var data = [...that.state.data];
                          data[index].semester = e.target.value;
                          that.setState({ data });
                        }}
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <DatePicker
                        selected={p.dueDate ? new Date(p.dueDate) : null}
                        onChange={(date) => {
                          var data = [...that.state.data];
                          data[index].dueDate = date;
                          that.setState({ data });
                        }}
                        style={{ backgroundColor: "#f5f5f9" }}
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date"
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="number"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Enter Amount.."
                        value={p.amount}
                        onChange={(e) => {
                          var data = [...that.state.data];
                          data[index].amount = e.target.value;
                          that.setState({ data });
                        }}
                        className="form-control underline-input"
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="number"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Enter Commission.."
                        value={p.commissionPercentage}
                        onChange={(e) => {
                          var data = [...that.state.data];
                          data[index].commissionPercentage = e.target.value;
                          that.setState({ data });
                        }}
                        className="form-control underline-input"
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="checkbox"
                        style={{ width: "20px", display: "inherit", backgroundColor: "#f5f5f9" }}
                        checked={p.paidStatus}
                        onChange={(e) => {
                          var lines = [...that.state.data];
                          this.setState({
                            paymentmodal: true,
                            setPaidPayment: lines[index],
                          });
                        }}
                        className="form-control underline-input"
                      />
                      <Modal
                        isOpen={this.state.paymentmodal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h5>
                          Are you sure you want to{" "}
                          {this.state.setPaidPayment?.paidStatus === false
                            ? "Paid"
                            : "Unpaid"}{" "}
                          current payment record ?
                        </h5>
                        <div className="d-flex justify-content-end mt-2">
                          <button
                            className="btn listing_addbutton mr-1"
                            onClick={(e) => {
                              this.state.setPaidPayment.paidStatus =
                                !this.state.setPaidPayment.paidStatus;
                              this.setState({
                                paymentmodal: false,
                                setPaidPayment: this.state.setPaidPayment,
                              });
                            }}
                          >
                            Yes
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            color="secondary"
                            onClick={() =>
                              this.setState({
                                paymentmodal: false,
                              })
                            }
                          >
                            No
                          </Button>
                        </div>
                      </Modal>
                    </TableCell>
                    {p.paidStatus === true ? (
                      <p>&nbsp;</p>
                    ) : (
                      <TableCell style={{ cursor: "pointer" }} id={p.id}>
                        <div
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="workflowdashboard_fa-ellipsis"
                            data-toggle="dropdown"
                          >
                            <i
                              className="fa fa-ellipsis-v "
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown-menu workflowdashboarddropdown">
                            <p>
                              <span
                                style={{ cursor: "pointer", color: "blue" }}
                                id={p.id}
                                onClick={() => {
                                  this.setState({
                                    deletemodal: true,
                                    setdeletepayment: p.id,
                                  });
                                }}
                              >
                                Delete
                              </span>
                            </p>
                          </div>
                          <Modal
                            isOpen={this.state.deletemodal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h4 style={{ marginTop: "10px" }}>
                              Are you sure you want to delete current payment
                              record ?
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "flex-end",
                                gap: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <button
                                className="btn listing_addbutton ml-1"
                                onClick={() => {
                                  var data = [...this.state.data];
                                  data.splice(index, 1);
                                  this.setState({
                                    data,
                                    deletemodal: false,
                                  });
                                }}
                              >
                                Yes
                              </button>
                              <button
                                className="btn btn-secondary cancel_margin"
                                onClick={() =>
                                  this.setState({ deletemodal: false })
                                }
                              >
                                No
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <a href="#" style={{ cursor: "grab" }}>
                        <DragIndicatorIcon className="lefticon" />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ReactDragListView>
        <button
          // color="primary"
          className="sent-button"
          style={{
            // cursor: "pointer",
            marginTop: "20px",
            // width: "fit-content",
            // border: "1px solid",
            // padding: "5px",
            // borderRadius: "7px",
          }}
          onClick={() => {
            let data = [
              ...this.state.data,
              {
                amount: 0,
                commissionPercentage: 0,
                dueDate: this.props.datenow,
                enquiryId: Number(this.props.params.enquiry_id),
                id: 0,
                paidStatus: false,
                semester: "",
              },
            ];
            this.setState({
              data,
            });
          }}
        >
          Add Row
        </button>
        <hr />
        <div className="d-flex justify-content-end">
          <button className="sent-button" type="submit">
            Submit
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className="outline-button"
            onClick={() => this.props.setpaymentschedulemodal(false)}
          >
            Close
          </button>
        </div>
      </form>
    );
  }
}

export default PaymentSchedule;
