import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { Link, useHistory, useParams } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { selectdashboard } from "../features/dashboardSlice";
import { selectUser, gettabvalue, getworkflowType, logout } from "../features/userSlice";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-google-charts";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import { toast, ToastContainer } from "react-toastify";

const ProviderReport = (props) => {
  const dashboardvalue = useSelector(selectdashboard);
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [rows, setrows] = React.useState(20);
  const params = useParams();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [reportresult, setreportresult] = useState();
  const [reportresultcount, setreportresultcount] = useState();
  const [getpageno, setgetpageno] = useState();
  const [data, setdata] = useState([["EnquiryStatus", "Count"]]);
  const [backdropopen, setbackdropopen] = useState(false);
  const dispatch = useDispatch();

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getproviderreport(value - 1);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdropopen(true);
    getproviderreport(pageno);
    getproviderreportcount();
  }, []);

  const getproviderreport = (value) => {
    axios
      .get(`${base_url.api2}/getEnquiryByHeadInstitutionId`, {
        headers: {
          Authorization: jwtToken,
          headInstitutionId: params.provider_id,
          columnName: "created",
          direction: "DESC",
          page: value,
          size: 20,
        },
      })
      .then((res) => {
        setreportresult(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        handle403error(err);
      });
  };

  const viewEnquiry = (p, e) => {
    localStorage.setItem("clientPageNo", pageno);
    dispatch(getworkflowType("Accounts"));
    dispatch(gettabvalue(0));
    var name = p.clientName;
    // history.push("/view-client-enquiry/" + p.id + "/" + p.email);

    localStorage.setItem("selected_client_id", p.clientId);
    localStorage.setItem("selected_client_username", p.clientUsername);

    localStorage.setItem("prev_path", "/client-list");
    history.push("/view-enquiry-workflowList/" + p.clientId + "/" + p.clientUsername);
  };

  const getproviderreportcount = () => {
    axios
      .get(`${base_url.api2}/getCountBasedOnEnquiryStatusByHeadInstitutionId`, {
        headers: {
          Authorization: jwtToken,
          headInstitutionId: params.provider_id,
        },
      })
      .then((res) => {
        setreportresultcount(res.data.data);
        for (let i = 0; i < res.data.data.length; i++) {
          let newarr = [];
          newarr[0] = res.data.data[i].enquiryStatus;
          newarr[1] = Number(res.data.data[i].enquiryCount);
          setdata((prev) => [...prev, newarr]);
        }
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const goBack = () => {
    history.push("/provider");
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div style={{ margin: "0 20px" }}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <div className="row">
        <Col md={6}>{null}</Col>
        <Col md={6} className="d-flex justify-content-end my-1">
          <button className="btn listing_addbutton" onClick={goBack}>
            {/* &#x2630; */}
            List
          </button>
        </Col>
      </div>
      {/* <div className="row">
        {reportresultcount?.map((p, index) => (
          <div
            key={index + 1}
            className="col"
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 0 4px",
              margin: "0 10px",
            }}
          >
            <p>{p.enquiryStatus}</p>
            <h5>{p.enquiryCount}</h5>
          </div>
        ))}
      </div> */}
      {/* <div className="row providerreport">
        <div className="col-md-12">
          {data[1] ? (
            <Chart
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={{
                title: "Enquiry count based on Provider",
                hAxis: {
                  title: "Status",
                },
                vAxis: {
                  title: "Count",
                },
              }}
              rootProps={{ "data-testid": "1" }}
            />
          ) : null}
        </div>
      </div> */}
      <div className="quotation_table">
        <TableContainer style={{ marginTop: "10px" }} component={Paper}>
          <Table className="workload card-table table-borderless"
            responsive>
            <TableHead style={{ backgroundColor: "cornflowerblue" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>SN</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Client</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Consultant</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Course
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Workflow</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Current Stage
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>AddedOn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportresult?.map((p, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  {/* <TableCell component="th" scope="row" onClick={(e)=>{props.handleChange(e,2)}}> */}
                  <TableCell component="th" scope="row">
                    <a
                      onClick={(e) => viewEnquiry(p, e)}
                      href={
                        `#/view-enquiry-workflowList/` +
                        p.clientId +
                        `/` +
                        p.clientUsername
                      }
                      style={{ cursor: "pointer" }} >
                      {p.clientName}
                    </a>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {p.consultantName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {p.courseName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {p.workflowName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {p.currentStage}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {p.enquiryStatus === "Discontinued" ? (
                      <td style={{ color: "red" }}>{p.enquiryStatus}</td>
                    ) : (
                      <>
                        {p.enquiryStatus === "Completed" ? (
                          <td style={{ color: "green" }}>{p.enquiryStatus}</td>
                        ) : (
                          <td>{p.enquiryStatus}</td>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {p.created
                      ? moment(p.created.split(".")[0]).format("DD-MMM-YY")
                      : null}
                    {/* <br />
                    {p.created
                      ? moment(p.created.split(".")[0]).format("hh.mm a")
                      : null} */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Row>
        <Col md={6}>
          {reportresult?.length ? (
            <p style={{ marginTop: "15px" }}>
              {countnumber * rows + 1} to{" "}
              {countnumber * rows + numberofElements} of {totalUser} records
            </p>
          ) : (
            <p className="formfooterrecordstyle">No Record Found</p>
          )}
        </Col>
        <Col md={6}>
          <div className={classes.root}>
            <Pagination
              count={getpageno}
              onChange={handlepage}
              shape="rounded"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProviderReport;