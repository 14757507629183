import React, { useEffect, useState } from "react";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { useHistory, useParams } from "react-router-dom";
import "../../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import "../../css/Responsive.css";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import {
  logout,
  selectUser,
  setdashboardcheck,
} from "../../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import {
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";

const dashboardPermissions = [
  {
    value: 0,
    label: "Dashboard",
    children: [
      { value: 1, label: "Welcome Page" },
      { value: 2, label: "Client Count" },
      { value: 3, label: "My Appointment" },
      { value: 4, label: "User Count" },
      { value: 5, label: "Top 5 Providers and Courses" },
      { value: 6, label: "Admission Application by Workflow Stages" },
      { value: 7, label: "Asmission User Count Enquiry" },
      { value: 8, label: "Follow up" },
      { value: 9, label: "Application Reminders" },
      { value: 10, label: "UserCount Client Enquiry" },
      { value: 11, label: "Overdue Invoice" },
    ]
  },
];

const mailPermissions = [
  {
    value: 100,
    label: "Mail",
    children: [
      { value: 101, label: "Received" },
      { value: 102, label: "Sent" },
    ],
  },
];

const leadPermissions = [
  {
    value: 200,
    label: "Lead",
    children: [
      { value: 201, label: "View Lead List" },
      { value: 202, label: "Export CSV Lead." },
    ]
  },
];



const officePermissions = [
  {
    value: 600,
    label: "Access Level",
    children: [
      { value: 601, label: "User" },
      { value: 602, label: "Role Menu" },
      {
        value: 603,
        label: "Branch",
        children: [
          { value: 604, label: "View Branch List" },
          { value: 605, label: "Create Branch." },
          { value: 606, label: "Edit Branch." },
          { value: 607, label: "Delete Branch." },
          { value: 608, label: "View Branch Details." },
          { value: 609, label: "Add User to Branch." },
          { value: 610, label: "Edit User in Branch." },
          { value: 611, label: "Change Password of User in Branch." },
          { value: 612, label: "Delete User in Branch." },
        ],
      },
    ],
  },
];

const clientPermissions = [
  {
    value: 300,
    label: "Client",
    children: [
      { value: 301, label: "View Client List" },
      { value: 341, label: "Export CSV Clients." },
      { value: 302, label: "Create New Client." },
      { value: 303, label: "Edit Client." },
      { value: 304, label: "Change Password of Client." },
      { value: 305, label: "Archive/Unarchive Client." },
      { value: 306, label: "View Client Application Details." },
      { value: 307, label: "Add Note for Client." },
      { value: 308, label: "Edit Client Notes." },
      { value: 309, label: "Edit Workload Notes." },
      { value: 310, label: "Upload Document of Client." },
      { value: 311, label: "View/Download Client Documents." },
      { value: 312, label: "Delete Client Documents." },
      {
        value: 313,
        label: "Manage Client Application.",
        children: [
          { value: 314, label: "Add Application for Client." },
          { value: 315, label: "View Client Application." },
          { value: 342, label: "Change Client Email." },
          { value: 316, label: "Update Steps of Client Application." },
          { value: 317, label: "Send Email in Client Application." },
          { value: 318, label: "Add Notes in Client Application." },
          { value: 319, label: "View Notes in Client Application." },
          { value: 320, label: "Edit Notes in Client Application." },
          { value: 321, label: "Upload Attachment in Client Application." },
          { value: 322, label: "Rename Attachment in Client Application." },
          {
            value: 323,
            label: "View/Download Attachment in Client Application.",
          },
          { value: 324, label: "Delete Attachment in Client Application." },
          { value: 325, label: "Add Appointment in Client Application." },
          { value: 326, label: "Update Client Application Status." },
          {
            value: 327,
            label: "Update Client Application Student ID or Policy No.",
          },
          {
            value: 328,
            label: "Update Client Application Course Start Date.",
          },
          {
            value: 329,
            label: "Update Client Application Course End Date.",
          },
          {
            value: 330,
            label: "Update Client Application Policy Start Date.",
          },
          {
            value: 331,
            label: "Update Client Application Policy End Date.",
          },
          {
            value: 332,
            label: "Update Unit Exemption in Client Application.",
          },
          { value: 333, label: "Update SubAgent in Client Application." },
          { value: 334, label: "Update SuperAgent in Client Application." },
          {
            value: 335,
            label: "Update FollowUp Date in Client Application.",
          },
          { value: 336, label: "Add Remarks in Client Application." },
          {
            value: 337,
            label: "Update Applied Intake in Client Application.",
          },
          {
            value: 338,
            label: "View Payment Schedule in Client Application.",
          },
        ],
      },
      { value: 339, label: "Create Invoice for Client." },
      { value: 340, label: "View Invoice of Client." },
      { value: 343, label: "View EnquiryWorkFlow Admission." },
      { value: 344, label: "View EnquiryWorkFlow Migration." },
      { value: 345, label: "View EnquiryWorkFlow Insurance." },
      { value: 346, label: "View EnquiryWorkFlow SkillAssessment." },
      { value: 347, label: "View EnquiryWorkFlow Accounts." },
      { value: 348, label: "View EnquiryWorkFlow Eoi." },
      { value: 349, label: "View EnquiryWorkFlow PTE/IELTS." },
    ],
  },
];

const workloadPermissions = [
  {
    value: 400,
    label: "Workload",
    children: [
      { value: 401, label: "Admissions" },
      { value: 405, label: "Export CSV Admission." },
      { value: 402, label: "Migration" },
      { value: 406, label: "Export CSV Migration." },
      { value: 403, label: "Insurance" },
      { value: 407, label: "Export CSV Insurence." },
      { value: 404, label: "Skill Assessment" },
      { value: 408, label: "Export CSV Skill Assessment." },
    ],
  },
];

const agentPermissions = [
  {
    value: 700,
    label: "Partner",
    children: [
      {
        value: 701,
        label: "Provider",
        children: [
          { value: 702, label: "View Provider List" },
          { value: 730, label: "Export CSV Provider." },
          { value: 703, label: "Create Provider." },
          { value: 704, label: "Edit Provider." },
          { value: 705, label: "Delete Provider." },
          { value: 706, label: "View Provider Details." },
          { value: 707, label: "Download Provider Branch Attachments." },
          { value: 708, label: "View Provider Branch Courses." },
          { value: 709, label: "View Provider Branch Details." },
          { value: 710, label: "View Provider Application Form." },
          { value: 711, label: "Download Provider Application Form." },
          { value: 712, label: "Delete Provider Application Form." },
          { value: 713, label: "View Provider Transactional Arrangement." },
          {
            value: 714,
            label: "Download Provider Transactional Arrangement.",
          },
          {
            value: 715,
            label: "Delete Provider Transactional Arrangement.",
          },
          { value: 716, label: "Edit Provider Branch." },
        ],
      },
      {
        value: 717,
        label: "Agent",
        children: [
          { value: 718, label: "View Agent List" },
          { value: 731, label: "Export CSV Agent." },
          { value: 719, label: "Create Agent." },
          { value: 720, label: "Edit Agent." },
          { value: 732, label: "Change Agent Email." },
          { value: 721, label: "Verify Agent." },
          { value: 722, label: "Update Agent Password." },
          { value: 723, label: "Delete Agent." },
          {
            value: 724,
            label: "View Agent Details.",
            children: [
              { value: 725, label: "View/Download Agent Agreements." },
              { value: 726, label: "Delete Agent Agreements." },
              { value: 727, label: "View/Download Agent Payable Invoice." },
              { value: 728, label: "View/Download Agent Paid Invoice." },
              { value: 729, label: "View Agent Details" },
            ],
          },
        ],
      },
    ],
  },
];



const coursePermissions = [
  {
    value: 900,
    label: "Course",
    children: [
      { value: 901, label: "View Course List" },
      { value: 902, label: "Create Course." },
      { value: 903, label: "Edit Course." },
      { value: 904, label: "Delete Course." },
      { value: 905, label: "View Course Details." },
    ],
  },
];

const settingPermissions = [
  {
    value: 1500,
    label: "Setting",
    children: [
      {
        value: 1501,
        label: "Admin Config",
        children: [
          { value: 1502, label: "Create Config." },
          { value: 1503, label: "Edit Config." },
        ],
      },
      {
        value: 1504,
        label: "Area",
        children: [
          { value: 1505, label: "Create Area." },
          { value: 1506, label: "Edit Area." },
          { value: 1507, label: "Delete Area." },
          { value: 1508, label: "Update Status of Area." },
        ],
      },
      {
        value: 1509,
        label: "Category",
        children: [
          { value: 1510, label: "Create Category." },
          { value: 1511, label: "Edit Category." },
          { value: 1512, label: "Delete Category." },
          { value: 1513, label: "Update Status of Category." },
        ],
      },
      {
        value: 1514,
        label: "Client Source",
        children: [
          { value: 1515, label: "Create Client Source." },
          { value: 1516, label: "Edit Client Source." },
          { value: 1517, label: "Delete Client Source." },
          { value: 1518, label: "Update Status of Client Source." },
        ],
      },
      {
        value: 1519,
        label: "Group Permissions",
        children: [
          { value: 1520, label: "Create Group Permissions." },
          { value: 1521, label: "Edit Group Permissions." },
          { value: 1522, label: "Delete Group Permissions." },
        ],
      },
      {
        value: 1523,
        label: "Health Cover Type",
        children: [
          { value: 1524, label: "Create Health Cover Type." },
          { value: 1525, label: "Edit Health Cover Type." },
          { value: 1526, label: "Delete Health Cover Type." },
          { value: 1527, label: "Update Status of Health Cover Type." },
        ],
      },
      {
        value: 1528,
        label: "Insurance",
        children: [
          { value: 1529, label: "Create Insurance." },
          { value: 1530, label: "Edit Insurance." },
          { value: 1531, label: "Delete Insurance." },
          { value: 1532, label: "Update Status of Insurance." },
        ],
      },
      {
        value: 1533,
        label: "Intake",
        children: [
          { value: 1534, label: "Create Intake." },
          { value: 1535, label: "Edit Intake." },
          { value: 1536, label: "Delete Intake." },
          { value: 1537, label: "Update Status of Intake." },
        ],
      },
      {
        value: 1538,
        label: "Interested Services",
        children: [
          { value: 1539, label: "Create Interested Services." },
          { value: 1540, label: "Edit Interested Services." },
          { value: 1541, label: "Delete Interested Services." },
          { value: 1542, label: "Update Status of Interested Services." },
        ],
      },
      {
        value: 1543,
        label: "Location",
        children: [
          { value: 1544, label: "Create Location." },
          { value: 1545, label: "Edit Location." },
          { value: 1546, label: "Delete Location." },
          { value: 1547, label: "Update Status of Location." },
        ],
      },
      {
        value: 1548,
        label: "Level",
        children: [
          { value: 1549, label: "Create Level." },
          { value: 1550, label: "Edit Level." },
          { value: 1551, label: "Delete Level." },
          { value: 1552, label: "Update Status of Level." },
        ],
      },
      {
        value: 1553,
        label: "Migration Provider",
        children: [
          { value: 1554, label: "Create Migration Provider." },
          { value: 1555, label: "Edit Migration Provider." },
          { value: 1556, label: "Delete Migration Provider." },
          { value: 1557, label: "Update Status of Migration Provider." },
        ],
      },
      {
        value: 1558,
        label: "Proforma",
        children: [
          { value: 1559, label: "Create Proforma." },
          { value: 1560, label: "Edit Proforma." },
          { value: 1561, label: "View Proforma." },
          { value: 1562, label: "Delete Proforma." },
          { value: 1563, label: "Update Status of Proforma." },
        ],
      },
      {
        value: 1564,
        label: "Visa Type",
        children: [
          { value: 1565, label: "Create Visa Type." },
          { value: 1566, label: "Edit Visa Type." },
          { value: 1567, label: "Delete Visa Type." },
          { value: 1568, label: "Update Status of Visa Type." },
        ],
      },
      {
        value: 1569,
        label: "WorkFlow",
        children: [
          { value: 1570, label: "Create WorkFlow." },
          { value: 1571, label: "View WorkFlow Steps." },
          { value: 1572, label: "Edit WorkFlow." },
          { value: 1573, label: "Delete WorkFlow." },
          { value: 1574, label: "Update Status of WorkFlow." },
        ],
      },
    ],
  },
];


const reportNewPermissions = [
  {
    value: 1000,
    label: "Report",
    children: [
      {
        value: 1001,
        label: "General",
        children: [
          {
            value: 1002,
            label: "Todays Lead Report",
            children: [
              { value: 1003, label: "View Todays Lead Report." },
              { value: 1004, label: "Export CSV Todays Lead Reports." },
            ],
          },
          {
            value: 1006,
            label: "Lead Report",
            children: [
              { value: 1007, label: "View Lead Report" },
              { value: 1008, label: "Export CSV Lead Reports." },
            ],
          },
          {
            value: 1010,
            label: "Client Report",
            children: [
              { value: 1011, label: "View Client Report." },
              { value: 1012, label: "Export CSV Client Reports." },
            ],
          },
          {
            value: 1014,
            label: "Branch Wise Summery Report",
            children: [
              {
                value: 1015,
                label: "View Branch Wise Summery Report.",
              },
              {
                value: 1016,
                label: "Export CSV Branch Wise Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1018,
        label: "Admission",
        children: [
          {
            value: 1019,
            label: "Admission Report",
            children: [
              { value: 1020, label: "View Admission Report." },
              { value: 1021, label: "Export CSV Admission Reports." },
            ],
          },
          {
            value: 1023,
            label: "SuperAgent Receviable Admission Summery Report",
            children: [
              {
                value: 1024,
                label: "View SuperAgent Receviable Admission Summery Report.",
              },
              {
                value: 1025,
                label:
                  "Export CSV SuperAgent Receviable Admission Summery Reports.",
              },
            ],
          },
          {
            value: 1027,
            label: "SubAgent Payable Admission Summery Report",
            children: [
              {
                value: 1028,
                label: "View SubAgent Payable Admission Summery Report.",
              },
              {
                value: 1029,
                label:
                  "Export CSV SubAgent Payable Admission Summery Reports.",
              },
            ],
          },
          {
            value: 1031,
            label: "Admission Provider Summery Report",
            children: [
              {
                value: 1032,
                label: "View Admission Provider Summery Report.",
              },
              {
                value: 1033,
                label: "Export CSV Admission Provider Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1035,
        label: "Migration",
        children: [
          {
            value: 1036,
            label: "Migration Report",
            children: [
              { value: 1037, label: "View Migration Report." },
              { value: 1038, label: "Export CSV Migration Reports." },
            ],
          },
          {
            value: 1040,
            label: "SubAgent Payable Migration Summery Report",
            children: [
              {
                value: 1041,
                label: "View SubAgent Payable Migration Summery Report.",
              },
              {
                value: 1042,
                label:
                  "Export CSV SubAgent Payable Migration Summery Reports.",
              },
            ],
          },
          {
            value: 1044,
            label: "Migration Provider Summery Report",
            children: [
              {
                value: 1045,
                label: "View Migration Provider Summery Report.",
              },
              {
                value: 1046,
                label: "Export CSV Migration Provider Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1048,
        label: "Insurence",
        children: [
          {
            value: 1049,
            label: "Insurence Report",
            children: [
              { value: 1050, label: "View Insurence Report." },
              { value: 1051, label: "Export CSV Insurence Reports." },
            ],
          },
          {
            value: 1053,
            label: "SubAgent Payable Insurence Summery Report",
            children: [
              {
                value: 1054,
                label: "View SubAgent Payable Insurence Summery Report.",
              },
              {
                value: 1055,
                label:
                  "Export CSV SubAgent Payable Insurence Summery Reports.",
              },
            ],
          },
          {
            value: 1057,
            label: "Insurence Provider Summery Report",
            children: [
              {
                value: 1058,
                label: "View Insurence Provider Summery Report.",
              },
              {
                value: 1059,
                label: "Export CSV Insurence Provider Summery Reports.",
              },
            ],
          },
        ],
      },
      {
        value: 1061,
        label: "Skill Assessment",
        children: [
          {
            value: 1062,
            label: "Skill Assessment Report",
            children: [
              { value: 1063, label: "View Skill Assessment Report." },
              {
                value: 1064,
                label: "Export CSV Skill Assessment Reports.",
              },
            ],
          },
          {
            value: 1066,
            label: "SubAgent Payable Skill Assessment Summery Report",
            children: [
              {
                value: 1067,
                label: "View SubAgent Payable Skill Assessment Summery Report.",
              },
              {
                value: 1068,
                label:
                  "Export CSV SubAgent Payable Skill Assessment Summery Reports.",
              },
            ],
          },
          {
            value: 1070,
            label: "Skill Assessment Provider Summery Report",
            children: [
              {
                value: 1071,
                label: "View Skill Assessment Provider Summery Report.",
              },
              {
                value: 1072,
                label:
                  "Export CSV Skill Assessment Provider Summery Reports.",
              },
            ],
          },
        ],
      },
    ],
  },
];

const financePermissions = [
  {
    value: 500,
    label: "Finance",
    children: [
      {
        value: 501,
        label: "Create Invoice",
        children: [
          { value: 502, label: "View OnGoing Workflow." },
          { value: 503, label: "Delete OnGoing Workflow." },
          { value: 519, label: "Export CSV Create Invoice." },
        ],
      },
      { value: 504, label: "View Invoice." },
      { value: 505, label: "Change Status of Invoice." },
      { value: 506, label: "Edit Invoice." },
      { value: 507, label: "Refund/Roleback Invoice." },
      { value: 508, label: "Application OnGoing" },
      {
        value: 509,
        label: "A/C Receivable",
        children: [
          { value: 510, label: "Admission" },
          { value: 520, label: "Export CSV Ac Receivable Admission." },
          { value: 511, label: "Migration" },
          { value: 521, label: "Export CSV Ac Receivable Migration." },
          { value: 512, label: "Insurance" },
          { value: 522, label: "Export CSV Ac Receivable Insurence." },
          { value: 513, label: "Skill Assessment" },
          { value: 523, label: "Export CSV Ac Receivable Skill Assessment." },
        ],
      },
      {
        value: 514,
        label: "A/C Payable",
        children: [
          { value: 515, label: "Admission" },
          { value: 524, label: "Export CSV Ac Payable Admission." },
          { value: 516, label: "Migration" },
          { value: 525, label: "Export CSV Ac Payable Migration." },
          { value: 517, label: "Insurance" },
          { value: 526, label: "Export CSV Ac Payable Insurence." },
          { value: 518, label: "Skill Assessment" },
          { value: 527, label: "Export CSV Ac Payable Skill Assessment." },
          { value: 528, label: "Edit Paid Date" },
        ],
      },
    ],
  },
];


const quotationPermissions = [
  {
    value: 1200,
    label: "Quotation",
    children: [
      { value: 1201, label: "View Quotation List" },
      { value: 1202, label: "Create Quotation" },
      { value: 1203, label: "Edit Quotation" },
      { value: 1204, label: "Delete Quotation" },
    ],
  },
];

const ambassadorPermissions = [
  {
    value: 1300,
    label: "Ambassador",
    children: [
      { value: 1301, label: "View Ambassador List" },
    ],
  },
];

const brandambassadorPermissions = [
  {
    value: 1400,
    label: "Brand Ambassador",
    children: [
      { value: 1401, label: "View Brand Ambassador List" },
    ],
  },
];

const auditPermissions = [
  {
    value: 1700,
    label: "Audit",
    children: [
      { value: 1701, label: "View Audit List" },
    ],
  },
];

const EditGroupPermission = () => {
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [backdropopen, setbackdropopen] = useState(false);

  const [disOffice, setDisOffice] = useState(false);
  const [disClient, setDisClient] = useState(false);
  const [disAgent, setDisAgent] = useState(false);
  const [disProvider, setDisProvider] = useState(false);
  const [disCourse, setDisCourse] = useState(false);
  const [disSetting, setDisSetting] = useState(false);
  const [disReport, setDisReport] = useState(false);
  const [disFinance, setDisFinance] = useState(false);
  const [disQuotation, setDisQuotation] = useState(false);

  const [groupName, setGroupName] = useState();
  const [groupNameStatus, setGroupNameStatus] = useState(false);
  const [groupStatus, setGroupStatus] = useState(1);
  const [groupStatusDone, setGroupStatusDone] = useState(false);

  const [groupDetails, setGroupDetails] = useState();

  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setGroupDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    // var available_resource = resource_obj.includes(54);
    // var available_permission = permission_obj.includes(359);
    if (check4pointpermission(1519, 1522, permission_obj, resource_obj)) {
      // if (available_permission){
      dispatch(setdashboardcheck(54));
      getGroupPermissions();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const getGroupPermissions = () => {
    axios
      .get(`${base_url.api1}/getGroupById`, {
        headers: {
          Authorization: jwtToken,
          groupId: params.group_id,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setGroupDetails(response.data.data);
        axios
          .get(`${base_url.api1}/getGroupPermissionsByGroupId`, {
            headers: {
              Authorization: jwtToken,
              groupId: Number(params.group_id),
            },
          })
          .then((res) => {
            console.log(res.data.data);
            let permissionId = [0];
            for (let i = 0; i < res.data.data.length; i++) {
              permissionId.push(res.data.data[i].permissionId);
            }
            const unique = getUnique(permissionId);
            let Dashboard = [];
            let Mail = [];
            let Lead = [];
            let Client = [];
            let WorkLoad = [];
            let Finance = [];
            let Report = [];
            let AccessLevel = [];
            let Partner = [];
            let Course = [];
            let Quotation = [];
            let Ambasador = [];
            let BrandAmbasador = [];
            let Setting = [];
            let Audit = [];


            for (let j = 0; j < unique.length; j++) {

              if (unique[j] >= 0 && unique[j] <= 99) {
                Dashboard.push(unique[j]);
              }
              if (unique[j] >= 100 && unique[j] <= 199) {
                Mail.push(unique[j]);
              }
              if (unique[j] >= 200 && unique[j] <= 299) {
                Lead.push(unique[j]);
              }
              if (unique[j] >= 300 && unique[j] <= 399) {
                Client.push(unique[j]);
              }
              if (unique[j] >= 400 && unique[j] <= 499) {
                WorkLoad.push(unique[j]);
              }
              if (unique[j] >= 500 && unique[j] <= 599) {
                Finance.push(unique[j]);
              }
              if (unique[j] >= 600 && unique[j] <= 699) {
                AccessLevel.push(unique[j]);
              }
              if (unique[j] >= 700 && unique[j] <= 799) {
                Partner.push(unique[j]);
              }
              if (unique[j] >= 900 && unique[j] <= 999) {
                Course.push(unique[j]);
              }
              if (unique[j] >= 1000 && unique[j] <= 1099) {
                Report.push(unique[j]);
              }
              if (unique[j] >= 1200 && unique[j] <= 1299) {
                Quotation.push(unique[j]);
              }
              if (unique[j] >= 1300 && unique[j] <= 1399) {
                Ambasador.push(unique[j]);
              }
              if (unique[j] >= 1400 && unique[j] <= 1499) {
                BrandAmbasador.push(unique[j]);
              }
              if (unique[j] >= 1500 && unique[j] <= 1599) {
                Setting.push(unique[j]);
              }
              if (unique[j] >= 1700 && unique[j] <= 1799) {
                Audit.push(unique[j]);
              }

            }

            setDashboardChecked(Dashboard);
            setMailChecked(Mail);
            setLeadChecked(Lead);
            setClientChecked(Client);
            setWorkloadChecked(WorkLoad);
            setFinanceChecked(Finance);
            setReportNewChecked(Report);
            setOfficeChecked(AccessLevel);
            setAgentChecked(Partner);
            setCourseChecked(Course);
            setQuotationChecked(Quotation);
            setAmbassadorChecked(Ambasador);
            setBrandAmbassadorChecked(BrandAmbasador);
            setSettingChecked(Setting);
            setAuditChecked(Audit);
          })
          .catch((err) => {
            let permissionId = [0];
            const unique = getUnique(permissionId);

            let Dashboard = [0];
            let Mail = [100];
            let Lead = [200];
            let Client = [300];
            let WorkLoad = [400];
            let Finance = [500];
            let Report = [1000];
            let AccessLevel = [600];
            let Partner = [700];
            let Course = [900];
            let Quotation = [1200];
            let Ambasador = [1300];
            let BrandAmbasador = [1400];
            let Setting = [1500];
            let Audit = [1700];

            setDashboardChecked(Dashboard);
            setMailChecked(Mail);
            setLeadChecked(Lead);
            setClientChecked(Client);
            setWorkloadChecked(WorkLoad);
            setFinanceChecked(Finance);
            setReportNewChecked(Report);
            setOfficeChecked(AccessLevel);
            setAgentChecked(Partner);
            setCourseChecked(Course);
            setQuotationChecked(Quotation);
            setAmbassadorChecked(Ambasador);
            setBrandAmbassadorChecked(BrandAmbasador);
            setSettingChecked(Setting);
            setAuditChecked(Audit);
            console.log(err);
          });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  function getUnique(array) {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  // Dashboard Permissions------------------------------------------
  const [DashboardExpanded, setDashboardExpanded] = useState([]);
  const [DashboardChecked, setDashboardChecked] = useState([0]);
  const [DashboardChecked2, setDashboardChecked2] = React.useState(false);
  const onCheckDashboard = (DashboardChecked) => {
    setDashboardChecked(DashboardChecked);
  };

  const onExpandDashboard = (DashboardExpanded) => {
    console.log("clicked");
    setDashboardExpanded(DashboardExpanded);
  };
  const handleDashboardChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllDashboardPermission();
    } else {
      console.log("Closing");
      unCheckAllDashboardPermission();
    }
    setDashboardChecked2(event.target.checked);
  };
  const checkAllDashboardPermission = () => {
    let checkAlldata = [];
    for (let i = 0; i <= 99; i++) {
      checkAlldata.push(i);
    }
    onCheckDashboard(checkAlldata);
  };
  const unCheckAllDashboardPermission = () => {
    onCheckDashboard([0]);
  };
  // Dashboard Permissions------------------------------------------

  // Mail Permissions------------------------------------------
  const [MailExpanded, setMailExpanded] = useState([]);
  const [MailChecked, setMailChecked] = useState([100]);
  const [MailChecked2, setMailChecked2] = React.useState(false);
  const onCheckMail = (MailChecked) => {
    setMailChecked(MailChecked);
  };

  const onExpandMail = (MailExpanded) => {
    console.log("clicked");
    setMailExpanded(MailExpanded);
  };
  const handleMailChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllMailPermission();
    } else {
      console.log("Closing");
      unCheckAllMailPermission();
    }
    setMailChecked2(event.target.checked);
  };
  const checkAllMailPermission = () => {
    let checkAlldata = [];
    for (let i = 100; i <= 199; i++) {
      checkAlldata.push(i);
    }
    onCheckMail(checkAlldata);
  };
  const unCheckAllMailPermission = () => {
    onCheckMail([100]);
  };
  // Mail Permissions------------------------------------------

  // Lead Permissions------------------------------------------
  const [LeadExpanded, setLeadExpanded] = useState([]);
  const [LeadChecked, setLeadChecked] = useState([200]);
  const [LeadChecked2, setLeadChecked2] = React.useState(false);
  const onCheckLead = (LeadChecked) => {
    setLeadChecked(LeadChecked);
  };

  const onExpandLead = (LeadExpanded) => {
    console.log("clicked");
    setLeadExpanded(LeadExpanded);
  };
  const handleLeadChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllLeadPermission();
    } else {
      console.log("Closing");
      unCheckAllLeadPermission();
    }
    setLeadChecked2(event.target.checked);
  };
  const checkAllLeadPermission = () => {
    let checkAlldata = [];
    for (let i = 200; i <= 299; i++) {
      checkAlldata.push(i);
    }
    onCheckLead(checkAlldata);
  };
  const unCheckAllLeadPermission = () => {
    onCheckLead([200]);
  };
  // Lead Permissions------------------------------------------

  // Office Permissions------------------------------------------
  const [officeExpanded, setOfficeExpanded] = useState([]);
  const [officeChecked, setOfficeChecked] = useState([600]);
  const [officeChecked2, setOfficeChecked2] = React.useState(false);
  const onCheckOffice = (officeChecked) => {
    setOfficeChecked(officeChecked);
  };
  // console.log(officeChecked);
  const onExpandOffice = (officeExpanded) => {
    console.log("clicked");
    setOfficeExpanded(officeExpanded);
  };
  const handleOfficeChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllOfficePermission();
    } else {
      console.log("Closing");
      unCheckAllOfficePermission();
    }
    setOfficeChecked2(event.target.checked);
  };
  const checkAllOfficePermission = () => {
    let checkAlldata = [];
    for (let i = 600; i <= 699; i++) {
      checkAlldata.push(i);
    }
    onCheckOffice(checkAlldata);
  };
  const unCheckAllOfficePermission = () => {
    onCheckOffice([600]);
  };
  // Office Permissions------------------------------------------

  // Client Permissions------------------------------------------
  const [clientExpanded, setClientExpanded] = useState([]);
  const [clientChecked, setClientChecked] = useState([300]);
  const [clientChecked2, setClientChecked2] = React.useState(false);
  const onCheckClient = (clientChecked) => {
    setClientChecked(clientChecked);
  };
  // console.log(clientChecked);
  const onExpandClient = (clientExpanded) => {
    console.log("clicked");
    setClientExpanded(clientExpanded);
  };
  const handleClientChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllClientPermission();
    } else {
      console.log("Closing");
      unCheckAllClientPermission();
    }
    setClientChecked2(event.target.checked);
  };
  const checkAllClientPermission = () => {
    let checkAlldata = [];
    for (let i = 300; i <= 399; i++) {
      checkAlldata.push(i);
    }
    onCheckClient(checkAlldata);
  };
  const unCheckAllClientPermission = () => {
    onCheckClient([300]);
  };
  // Client Permissions------------------------------------------

  // Workload Permissions------------------------------------------
  const [WorkloadExpanded, setWorkloadExpanded] = useState([]);
  const [WorkloadChecked, setWorkloadChecked] = useState([400]);
  const [WorkloadChecked2, setWorkloadChecked2] = React.useState(false);
  const onCheckWorkload = (WorkloadChecked) => {
    setWorkloadChecked(WorkloadChecked);
  };

  const onExpandWorkload = (WorkloadExpanded) => {
    console.log("clicked");
    setWorkloadExpanded(WorkloadExpanded);
  };
  const handleWorkloadChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllWorkloadPermission();
    } else {
      console.log("Closing");
      unCheckAllWorkloadPermission();
    }
    setWorkloadChecked2(event.target.checked);
  };
  const checkAllWorkloadPermission = () => {
    let checkAlldata = [];
    for (let i = 400; i <= 499; i++) {
      checkAlldata.push(i);
    }
    onCheckWorkload(checkAlldata);
  };
  const unCheckAllWorkloadPermission = () => {
    onCheckWorkload([400]);
  };
  // Workload Permissions------------------------------------------

  // Agent Permissions------------------------------------------
  const [agentExpanded, setAgentExpanded] = useState([]);
  const [agentChecked, setAgentChecked] = useState([700]);
  const [agentChecked2, setAgentChecked2] = React.useState(false);
  const onCheckAgent = (agentChecked) => {
    setAgentChecked(agentChecked);
  };
  // console.log(agentChecked);
  const onExpandAgent = (agentExpanded) => {
    console.log("clicked");
    setAgentExpanded(agentExpanded);
  };
  const handleAgentChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllAgentPermission();
    } else {
      console.log("Closing");
      unCheckAllAgentPermission();
    }
    setAgentChecked2(event.target.checked);
  };
  const checkAllAgentPermission = () => {
    let checkAlldata = [];
    for (let i = 700; i <= 799; i++) {
      checkAlldata.push(i);
    }
    onCheckAgent(checkAlldata);
  };
  const unCheckAllAgentPermission = () => {
    onCheckAgent([700]);
  };
  // Agent Permissions------------------------------------------

  // Provider Permissions------------------------------------------
  const [providerExpanded, setProviderExpanded] = useState([]);
  const [providerChecked, setProviderChecked] = useState([166]);
  const [providerChecked2, setProviderChecked2] = React.useState(false);
  const onCheckProvider = (providerChecked) => {
    setProviderChecked(providerChecked);
  };
  // console.log(providerChecked);
  const onExpandProvider = (providerExpanded) => {
    console.log("clicked");
    setProviderExpanded(providerExpanded);
  };
  const handleProviderChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllProviderPermission();
    } else {
      console.log("Closing");
      unCheckAllProviderPermission();
    }
    setProviderChecked2(event.target.checked);
  };
  const checkAllProviderPermission = () => {
    let checkAlldata = [];
    for (let i = 166; i <= 230; i++) {
      checkAlldata.push(i);
    }
    onCheckProvider(checkAlldata);
  };
  const unCheckAllProviderPermission = () => {
    onCheckProvider([166]);
  };
  // Provider Permissions------------------------------------------

  // Course Permissions------------------------------------------
  const [courseExpanded, setCourseExpanded] = useState([]);
  const [courseChecked, setCourseChecked] = useState([900]);
  const [courseChecked2, setCourseChecked2] = React.useState(false);
  const onCheckCourse = (courseChecked) => {
    setCourseChecked(courseChecked);
  };
  // console.log(courseChecked);
  const onExpandCourse = (courseExpanded) => {
    console.log("clicked");
    setCourseExpanded(courseExpanded);
  };
  const handleCourseChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllCoursePermission();
    } else {
      console.log("Closing");
      unCheckAllCoursePermission();
    }
    setCourseChecked2(event.target.checked);
  };
  const checkAllCoursePermission = () => {
    let checkAlldata = [];
    for (let i = 900; i <= 999; i++) {
      checkAlldata.push(i);
    }
    onCheckCourse(checkAlldata);
  };
  const unCheckAllCoursePermission = () => {
    onCheckCourse([900]);
  };
  // Course Permissions------------------------------------------

  // Setting Permissions------------------------------------------
  const [settingExpanded, setSettingExpanded] = useState([]);
  const [settingChecked, setSettingChecked] = useState([1500]);
  const [settingChecked2, setSettingChecked2] = React.useState(false);
  const onCheckSetting = (settingChecked) => {
    setSettingChecked(settingChecked);
  };
  // console.log(settingChecked);
  const onExpandSetting = (settingExpanded) => {
    console.log("clicked");
    setSettingExpanded(settingExpanded);
  };
  const handleSettingChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllSettingPermission();
    } else {
      console.log("Closing");
      unCheckAllSettingPermission();
    }
    setSettingChecked2(event.target.checked);
  };
  const checkAllSettingPermission = () => {
    let checkAlldata = [];
    for (let i = 1500; i <= 1599; i++) {
      checkAlldata.push(i);
    }
    onCheckSetting(checkAlldata);
  };
  const unCheckAllSettingPermission = () => {
    onCheckSetting([1500]);
  };
  // Setting Permissions------------------------------------------



  // Report Permissions------------------------------------------
  const [reportExpanded, setReportExpanded] = useState([]);
  const [reportChecked, setReportChecked] = useState([407]);
  const [reportChecked2, setReportChecked2] = React.useState(false);
  const onCheckReport = (reportChecked) => {
    setReportChecked(reportChecked);
  };

  const onExpandReport = (reportExpanded) => {
    console.log("clicked");
    setReportExpanded(reportExpanded);
  };
  const handleReportChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllReportPermission();
    } else {
      console.log("Closing");
      unCheckAllReportPermission();
    }
    setReportChecked2(event.target.checked);
  };
  const checkAllReportPermission = () => {
    let checkAlldata = [];
    for (let i = 407; i <= 490; i++) {
      checkAlldata.push(i);
    }
    onCheckReport(checkAlldata);
  };
  const unCheckAllReportPermission = () => {
    onCheckReport([407]);
  };
  // Report Permissions------------------------------------------

  // Report New Permissions------------------------------------------
  const [reportNewExpanded, setReportNewExpanded] = useState([]);
  const [reportNewChecked, setReportNewChecked] = useState([1000]);
  const [reportNewChecked2, setReportNewChecked2] = React.useState(false);
  const onCheckNewReport = (reportNewChecked) => {
    setReportNewChecked(reportNewChecked);
  };

  const onExpandNewReport = (reportNewExpanded) => {
    console.log("clicked");
    setReportNewExpanded(reportNewExpanded);
  };
  const handleNewReportChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllNewReportPermission();
    } else {
      console.log("Closing");
      unCheckAllNewReportPermission();
    }
    setReportNewChecked2(event.target.checked);
  };
  const checkAllNewReportPermission = () => {
    let checkAlldata = [];
    for (let i = 1000; i <= 1100; i++) {
      checkAlldata.push(i);
    }
    onCheckNewReport(checkAlldata);
  };
  const unCheckAllNewReportPermission = () => {
    onCheckNewReport([1099]);
  };
  // Report New Permissions------------------------------------------

  // Finance Permissions------------------------------------------
  const [financeExpanded, setFinanceExpanded] = useState([]);
  const [financeChecked, setFinanceChecked] = useState([500]);
  const [financeChecked2, setFinanceChecked2] = React.useState(false);
  const onCheckFinance = (financeChecked) => {
    setFinanceChecked(financeChecked);
  };
  // console.log(financeChecked);
  const onExpandFinance = (financeExpanded) => {
    console.log("clicked");
    setFinanceExpanded(financeExpanded);
  };
  const handleFinanceChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllFinancePermission();
    } else {
      console.log("Closing");
      unCheckAllFinancePermission();
    }
    setFinanceChecked2(event.target.checked);
  };
  const checkAllFinancePermission = () => {
    let checkAlldata = [];
    for (let i = 500; i <= 599; i++) {
      checkAlldata.push(i);
    }
    onCheckFinance(checkAlldata);
  };
  const unCheckAllFinancePermission = () => {
    onCheckFinance([500]);
  };
  // Finance Permissions------------------------------------------

  // Quotation Permissions------------------------------------------
  const [quotationExpanded, setQuotationExpanded] = useState([]);
  const [quotationChecked, setQuotationChecked] = useState([1200]);
  const [quotationChecked2, setQuotationChecked2] = React.useState(false);
  const onCheckQuotation = (quotationChecked) => {
    setQuotationChecked(quotationChecked);
  };
  // console.log(quotationChecked);
  const onExpandQuotation = (quotationExpanded) => {
    console.log("clicked");
    setQuotationExpanded(quotationExpanded);
  };
  const handleQuotationChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllQuotationPermission();
    } else {
      console.log("Closing");
      unCheckAllQuotationPermission();
    }
    setQuotationChecked2(event.target.checked);
  };
  const checkAllQuotationPermission = () => {
    let checkAlldata = [];
    for (let i = 1200; i <= 1299; i++) {
      checkAlldata.push(i);
    }
    onCheckQuotation(checkAlldata);
  };
  const unCheckAllQuotationPermission = () => {
    onCheckQuotation([1200]);
  };
  // Quotation Permissions------------------------------------------

  // Ambassador Permissions------------------------------------------
  const [AmbassadorExpanded, setAmbassadorExpanded] = useState([]);
  const [AmbassadorChecked, setAmbassadorChecked] = useState([1300]);
  const [AmbassadorChecked2, setAmbassadorChecked2] = React.useState(false);
  const onCheckAmbassador = (AmbassadorChecked) => {
    setAmbassadorChecked(AmbassadorChecked);
  };

  const onExpandAmbassador = (AmbassadorExpanded) => {
    console.log("clicked");
    setAmbassadorExpanded(AmbassadorExpanded);
  };
  const handleAmbassadorChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllAmbassadorPermission();
    } else {
      console.log("Closing");
      unCheckAllAmbassadorPermission();
    }
    setAmbassadorChecked2(event.target.checked);
  };
  const checkAllAmbassadorPermission = () => {
    let checkAlldata = [];
    for (let i = 1300; i <= 1399; i++) {
      checkAlldata.push(i);
    }
    onCheckAmbassador(checkAlldata);
  };
  const unCheckAllAmbassadorPermission = () => {
    onCheckAmbassador([1300]);
  };
  // Ambassador Permissions------------------------------------------

  // BrandAmbassador Permissions------------------------------------------

  const [BrandAmbassadorExpanded, setBrandAmbassadorExpanded] = useState([]);
  const [BrandAmbassadorChecked, setBrandAmbassadorChecked] = useState([1400]);
  const [BrandAmbassadorChecked2, setBrandAmbassadorChecked2] = React.useState(false);
  const onCheckBrandAmbassador = (BrandAmbassadorChecked) => {
    setBrandAmbassadorChecked(BrandAmbassadorChecked);
  };

  const onExpandBrandAmbassador = (BrandAmbassadorExpanded) => {
    console.log("clicked");
    setBrandAmbassadorExpanded(BrandAmbassadorExpanded);
  };
  const handleBrandAmbassadorChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllBrandAmbassadorPermission();
    } else {
      console.log("Closing");
      unCheckAllBrandAmbassadorPermission();
    }
    setBrandAmbassadorChecked2(event.target.checked);
  };
  const checkAllBrandAmbassadorPermission = () => {
    let checkAlldata = [];
    for (let i = 1400; i <= 1499; i++) {
      checkAlldata.push(i);
    }
    onCheckBrandAmbassador(checkAlldata);
  };
  const unCheckAllBrandAmbassadorPermission = () => {
    onCheckBrandAmbassador([1400]);
  };
  // BrandAmbassador Permissions------------------------------------------

  // Audit Permissions------------------------------------------

  const [AuditExpanded, setAuditExpanded] = useState([]);
  const [AuditChecked, setAuditChecked] = useState([1700]);
  const [AuditChecked2, setAuditChecked2] = React.useState(false);
  const onCheckAudit = (AuditChecked) => {
    setAuditChecked(AuditChecked);
  };

  const onExpandAudit = (AuditExpanded) => {
    console.log("clicked");
    setAuditExpanded(AuditExpanded);
  };
  const handleAuditChange = (event) => {
    if (event.target.checked) {
      console.log("Entering");
      checkAllAuditPermission();
    } else {
      console.log("Closing");
      unCheckAllAuditPermission();
    }
    setAuditChecked2(event.target.checked);
  };
  const checkAllAuditPermission = () => {
    let checkAlldata = [];
    for (let i = 1700; i <= 1799; i++) {
      checkAlldata.push(i);
    }
    onCheckAudit(checkAlldata);
  };
  const unCheckAllAuditPermission = () => {
    onCheckAudit([1700]);
  };
  // Audit Permissions------------------------------------------

  const assignPermission = (data3) => {
    if (groupName) {
      return;
    } else {
      let groupName = data3.groupName;
      let status = data3.status;
      let id = params.group_id;

      let submitData = {
        groupName,
        status,
        id,
      };
      // console.log(submitData);
      let chkedData = [];
      let data2 = [];
      chkedData = DashboardChecked.concat(
        MailChecked,
        LeadChecked,
        clientChecked,
        WorkloadChecked,
        financeChecked,
        reportNewChecked,
        officeChecked,
        agentChecked,
        courseChecked,
        quotationChecked,
        AmbassadorChecked,
        BrandAmbassadorChecked,
        settingChecked,
        AuditChecked,
      );

      setbackdropopen(true);
      axios
        .post(`${base_url.api1}/updateGroupById`, submitData, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          console.log(response.data.status_code);
          if (response.data.status_code == 200) {
            for (let j = 0; j < chkedData.length; j++) {
              data2.push({
                id: Number(0),
                groupId: params.group_id,
                permissionId: Number(chkedData[j]),
              });
            }
            axios
              .post(`${base_url.api1}/saveGroupPermissions`, data2, {
                headers: {
                  Authorization: jwtToken,
                  groupId: response.data.data.id,
                },
              })
              .then((response) => {
                if (response.data.status_code == 200) {
                  let logs =
                    isw_user_name +
                    " Updated Permissions for " +
                    groupDetails?.groupName +
                    ".";
                  callsaveauditapi(logs);
                  setbackdropopen(false);
                  history.push("/group-permissions");
                } else {
                  toast.error(response.message);
                }
              })
              .catch((error) => {
                toast.error(JSON.stringify(error.response.data.message));
              });

            toast.success("Success");
            // let logs = isw_user_name + " Created Group "+groupName+".";
            // callsaveauditapi(logs);
            // history.push("/users");
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        });
    }
  };

  const goback = () => {
    history.push("/group-permissions");
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Card className="commoncomponent_cardcss">
        <CardSubtitle className="font-weight-bold"></CardSubtitle>
        <div style={{ padding: "15px" }}>
          <Backdrop className={classes.backdrop} open={backdropopen}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <Card className="commoncomponent_cardcss"> */}
          <div>
            <Row>
              <Col md={6}>
                <CardText className={classes.headtext}>
                  <strong>Edit Group</strong>
                </CardText>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <button className="btn listing_addbutton" onClick={goback}>
                  &#x2630; List
                </button>
              </Col>
            </Row>
            <form id="myForm" onSubmit={handleSubmit(assignPermission)}>
              <div style={{ padding: "0 20px " }}>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={3}>
                      <label>
                        Group Name<span style={{ color: "red" }}>*</span>:
                      </label>
                      <input
                        type="text"
                        className="form-control underline-input"
                        name="groupName"
                        value={groupDetails?.groupName}
                        onChange={inputEvent}
                        ref={register({ required: true })}
                      />
                      {/* {groupNameStatus?<p className="errormessage">Group Name is Required</p>:null} */}
                      {errors.groupName && (
                        <p className="errormessage">Group Name is Required</p>
                      )}
                    </Col>
                    <Col md={2}>
                      <label>
                        Status<span style={{ color: "red" }}>*</span>:
                      </label>
                      <select
                        className={classes.selectdrop}
                        name="status"
                        onChange={inputEvent}
                        value={groupDetails?.status}
                        ref={register({ required: true })}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      {errors.status && (
                        <p className="errormessage">Status is Required</p>
                      )}
                    </Col>
                  </Row>
                </div>

                {/* Dashboard Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Dashboard Permissions</strong>
                        </label>
                      </Col>
                      <Col md={3} style={{ marginLeft: "30px" }}>
                        <button
                          // onClick={assignPermission}
                          className="btn listing_addbutton"
                          style={{
                            padding: "10px 15px",
                            float: "left",
                            marginTop: "5px",
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={dashboardPermissions}
                        checked={DashboardChecked}
                        expanded={DashboardExpanded}
                        onExpand={onExpandDashboard}
                        onCheck={onCheckDashboard}
                        // disabled={disClient}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={DashboardChecked2}
                        onChange={handleDashboardChange}
                        // disabled={disClient}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Dashboard Permissions------------------ */}

                {/* Mail Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Mail Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={mailPermissions}
                        checked={MailChecked}
                        expanded={MailExpanded}
                        onExpand={onExpandMail}
                        onCheck={onCheckMail}
                        // disabled={disClient}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={MailChecked2}
                        onChange={handleMailChange}
                        // disabled={disClient}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Mail Permissions------------------ */}

                {/* Lead Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Lead Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={leadPermissions}
                        checked={LeadChecked}
                        expanded={LeadExpanded}
                        onExpand={onExpandLead}
                        onCheck={onCheckLead}
                        // disabled={disClient}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={LeadChecked2}
                        onChange={handleLeadChange}
                        // disabled={disClient}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Lead Permissions------------------ */}

                {/* Client Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Client Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={clientPermissions}
                        checked={clientChecked}
                        expanded={clientExpanded}
                        onExpand={onExpandClient}
                        onCheck={onCheckClient}
                        // disabled={disClient}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={clientChecked2}
                        onChange={handleClientChange}
                        // disabled={disClient}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Client Permissions------------------ */}

                {/* Workload Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Workload Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={workloadPermissions}
                        checked={WorkloadChecked}
                        expanded={WorkloadExpanded}
                        onExpand={onExpandWorkload}
                        onCheck={onCheckWorkload}
                        // disabled={disClient}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={WorkloadChecked2}
                        onChange={handleWorkloadChange}
                        // disabled={disClient}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Workload Permissions------------------ */}

                {/* Finance Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Finance Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={financePermissions}
                        checked={financeChecked}
                        expanded={financeExpanded}
                        onExpand={onExpandFinance}
                        onCheck={onCheckFinance}
                        // disabled={disFinance}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={financeChecked2}
                        onChange={handleFinanceChange}
                        // disabled={disFinance}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Finance Permissions------------------ */}

                {/* Report New Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Report Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={reportNewPermissions}
                        checked={reportNewChecked}
                        expanded={reportNewExpanded}
                        onExpand={onExpandNewReport}
                        onCheck={onCheckNewReport}
                        // disabled={disReport}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={reportNewChecked2}
                        onChange={handleNewReportChange}
                        // disabled={disReport}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Report New Permissions------------------ */}

                {/* Office Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Access Level Permission</strong>
                          <br /><br />
                          {/* <b style={{ color: "black" }}>{params.role_name}</b> */}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={officePermissions}
                        checked={officeChecked}
                        expanded={officeExpanded}
                        onExpand={onExpandOffice}
                        onCheck={onCheckOffice}
                        // disabled={disOffice}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={officeChecked2}
                        onChange={handleOfficeChange}
                        // disabled={disOffice}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Office Permissions------------------ */}

                {/* Agent Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Partner Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={agentPermissions}
                        checked={agentChecked}
                        expanded={agentExpanded}
                        onExpand={onExpandAgent}
                        onCheck={onCheckAgent}
                        // disabled={disAgent}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={agentChecked2}
                        onChange={handleAgentChange}
                        // disabled={disAgent}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Agent Permissions------------------ */}

                {/* Provider Permissions------------------ */}
                {/* <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Provider Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={providerPermissions}
                        checked={providerChecked}
                        expanded={providerExpanded}
                        onExpand={onExpandProvider}
                        onCheck={onCheckProvider}
                        // disabled={disProvider}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={providerChecked2}
                        onChange={handleProviderChange}
                        // disabled={disProvider}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div> */}
                {/* Provider Permissions------------------ */}

                {/* Course Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Course Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={coursePermissions}
                        checked={courseChecked}
                        expanded={courseExpanded}
                        onExpand={onExpandCourse}
                        onCheck={onCheckCourse}
                        // disabled={disCourse}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={courseChecked2}
                        onChange={handleCourseChange}
                        // disabled={disCourse}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Course Permissions------------------ */}

                {/* Quotation Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Quotation Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={quotationPermissions}
                        checked={quotationChecked}
                        expanded={quotationExpanded}
                        onExpand={onExpandQuotation}
                        onCheck={onCheckQuotation}
                        // disabled={disQuotation}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={quotationChecked2}
                        onChange={handleQuotationChange}
                        // disabled={disQuotation}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Quotation Permissions------------------ */}

                {/* Ambassador Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Ambassador Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={ambassadorPermissions}
                        checked={AmbassadorChecked}
                        expanded={AmbassadorExpanded}
                        onExpand={onExpandAmbassador}
                        onCheck={onCheckAmbassador}
                        // disabled={disCourse}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={AmbassadorChecked2}
                        onChange={handleAmbassadorChange}
                        // disabled={disCourse}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Ambassador Permissions------------------ */}

                {/* BrandAmbassador Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>BrandAmbassador Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={brandambassadorPermissions}
                        checked={BrandAmbassadorChecked}
                        expanded={BrandAmbassadorExpanded}
                        onExpand={onExpandBrandAmbassador}
                        onCheck={onCheckBrandAmbassador}
                        // disabled={disCourse}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={BrandAmbassadorChecked2}
                        onChange={handleBrandAmbassadorChange}
                        // disabled={disCourse}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* BrandAmbassador Permissions------------------ */}

                {/* Setting Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Setting Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={settingPermissions}
                        checked={settingChecked}
                        expanded={settingExpanded}
                        onExpand={onExpandSetting}
                        onCheck={onCheckSetting}
                        // disabled={disSetting}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={settingChecked2}
                        onChange={handleSettingChange}
                        // disabled={disSetting}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Setting Permissions------------------ */}

                {/* Audit Permissions------------------ */}
                <div className="form-inner-admin">
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={3}>
                        <label>
                          <strong>Audit Permissions</strong>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Row
                    style={{ position: "relative" }}
                    className="rolesrowalignment"
                  >
                    <Col md={12}>
                      <CheckboxTree
                        style={{ position: "relative" }}
                        nodes={auditPermissions}
                        checked={AuditChecked}
                        expanded={AuditExpanded}
                        onExpand={onExpandAudit}
                        onCheck={onCheckAudit}
                        // disabled={disCourse}
                        showExpandAll={true}
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: (
                            <span className="rct-icon rct-icon-uncheck" />
                          ),
                          halfCheck: (
                            <span className="rct-icon rct-icon-half-check" />
                          ),
                          expandClose: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          expandOpen: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          expandAll: (
                            <span className="rct-icon rct-icon-expand-all" />
                          ),
                          collapseAll: (
                            <span className="rct-icon rct-icon-collapse-all" />
                          ),
                          parentClose: (
                            <span className="rct-icon rct-icon-parent-close" />
                          ),
                          parentOpen: (
                            <span className="rct-icon rct-icon-parent-open" />
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="roleabsoluteposition">
                      <Checkbox
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-10px",
                          height: "40px",
                        }}
                        checked={AuditChecked2}
                        onChange={handleAuditChange}
                        // disabled={disCourse}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Col>
                    <Col md={3}>{null}</Col>
                    <Col md={3} style={{ marginLeft: "30px" }}>
                      <button
                        // onClick={assignPermission}
                        className="btn listing_addbutton"
                        style={{
                          padding: "10px 15px",
                          float: "left",
                          marginTop: "5px",
                        }}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </div>
                {/* Audit Permissions------------------ */}

                {/* Report Permissions------------------ */}
                {/* <div className="form-inner-admin">
                    <div className="form-group creatadmin">
                      <Row>
                        <Col md={3}>
                          <label>
                            <strong>Report Permissions</strong>
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <br/>
                    <Row style={{ position: "relative" }} className="rolesrowalignment">
                      <Col md={12}>
                        <CheckboxTree
                          style={{ position: "relative" }}
                          nodes={reportPermissions}
                          checked={reportChecked}
                          expanded={reportExpanded}
                          onExpand={onExpandReport}
                          onCheck={onCheckReport}
                          // disabled={disReport}
                          showExpandAll={true}
                          icons={{
                            check: <span className="rct-icon rct-icon-check" />,
                            uncheck: <span className="rct-icon rct-icon-uncheck" />,
                            halfCheck: (
                              <span className="rct-icon rct-icon-half-check" />
                            ),
                            expandClose: (
                              <span className="rct-icon rct-icon-expand-all" />
                            ),
                            expandOpen: (
                              <span className="rct-icon rct-icon-collapse-all" />
                            ),
                            expandAll: (
                              <span className="rct-icon rct-icon-expand-all" />
                            ),
                            collapseAll: (
                              <span className="rct-icon rct-icon-collapse-all" />
                            ),
                            parentClose: (
                              <span className="rct-icon rct-icon-parent-close" />
                            ),
                            parentOpen: (
                              <span className="rct-icon rct-icon-parent-open" />
                            ),
                          }}
                        />
                      </Col>
                      <Col md={4} className="roleabsoluteposition">
                        <Checkbox
                          style={{
                            marginTop: "-10px",
                            marginLeft: "-10px",
                            height: "40px",
                          }}
                          checked={reportChecked2}
                          onChange={handleReportChange}
                          // disabled={disReport}
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Col>
                    </Row>
                  </div> */}
                {/* Report Permissions------------------ */}

              </div>
            </form>
          </div>
          {/* </Card> */}
        </div>
      </Card>
    </div>
  );
};

export default EditGroupPermission;
