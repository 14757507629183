import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import { Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import { logout, setdashboardcheck, selectUser } from "../features/userSlice";
import Tooltip from "@material-ui/core/Tooltip";
import { selectdashboard } from "../features/dashboardSlice";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../services/auditservice";
import LeadViewMoreModal from "./../Modal/Masters/LeadViewMoreModal";
import { check1pointpermission } from "./../Permission/permission";
import LeadArchiveModal from "../Modal/Masters/LeadArchiveModal";
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip as ReactTooltip } from "react-tooltip";

import "../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import sort from "./../images/sort.svg";
import mail from "./../images/mail.png";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import ArchiveModal from "../Modal/Masters/ArchiveModal";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
function LeadList(props) {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [backdropopen, setbackdropopen] = useState(false);
  const isw_user_role = localStorage.getItem("isw_user_role");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [verifyleadid, setverileadid] = useState();
  const [openverifymodal, setopenverifymodal] = useState(false);
  const user = useSelector(selectUser);
  const [remarkcontent, setremarkcontent] = useState();
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [deleteEnquiry, setDeleteEnquiry] = useState(null);
  const [archiveUser, setArchiveEnquiry] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [statusstate, setstatusstate] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();

  const [pageno, setpageno] = useState(0);
  const [enquiry, setEnquiry] = useState(null);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [getstatusname, setstatusname] = useState("1");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  // console.log(user.isw_available_Office);
  const [paginationpageno, setpaginationpageno] = useState(1);

  let headData;

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);



  const handleerror = () => {
    let log = isw_user_name + " Logged out.";
    callsaveauditapi(log);
    dispatch(logout());
    //dispatch(removeworkflowType());
    localStorage.clear();
    history.push("/");
    toast.success("Log Out Successfully");
  };

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [enquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  let office_id;

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check1pointpermission(201, permission_obj, resource_obj)) {
      setbackdropopen(true);
      dispatch(setdashboardcheck(4));
      getConsultant();
      getOffice();
      if (selectedOfficeId) {
        office_id = selectedOfficeId;
      } else {
        const office_obj = [];
        for (let i = 0; i < user?.isw_available_Office?.length; i++) {
          const element = user?.isw_available_Office?.[i];
          console.log(element);
          office_obj.push(element);
        }
        let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
        office_id = new_id;
        setSelectedOfficeId(new_id);
      }
      headData = {
        Authorization: jwtToken,
        columnName: sortState,
        direction: direction,
        clientSource: sourceData,
        interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
        status: statusstate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        query: searchinput,
        officeId: office_id,
        page: Number(pageno),
        size: Number(rows),
      };

      if (localStorage.getItem("leadPageNo")) {
        let currentPage = localStorage.getItem("leadPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getallLeads(headData);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getallLeads(headData);
      }
    } else {
      // console.log('No access to the page');
      history.push("/errorPage");
    }
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow([]);
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const getsecondPdfData = (currentOffice) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          officeId: currentOffice,
          interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
          clientSource: sourceData,
          status: statusstate,
          officeId: selectedOfficeId,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow([]);
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const exportPdfLeadList = () => {
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
      clientSource: sourceData,
      status: statusstate,
      createdStartDate: createdStartDate,
      createdEndDate: createdToDate,
      query: searchinput,
      officeId: selectedOfficeId,
      page: Number(0),
      // size: Number(pdflength),
      size: Number(50),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Lead Details",
          "Address",
          "Assignee",
          "Service",
          "Office",
          "Lead Type",
          "Session",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.firstName +
            " " +
            element.middleName +
            " " +
            element.lastName +
            "\n" +
            element.email +
            "\n" +
            "Phone: " +
            element.phone,
            "Country: " + element.country + "\n" + "City: " + element.city,
            element.assigneeName,
            element.interestedServices,
            element.officeName,
            element.leadType,
            "SST: " +
            moment(element.startTime).format("DD/MM/YYYY hh:mm:ss") +
            "\n" +
            "SET: " +
            moment(element.endTime).format("DD/MM/YYYY hh:mm:ss") +
            "\n" +
            "Total: " +
            element.totalTime,
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("Leads_list.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow([]);
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const addBasicClient = () => {
    history.push("/add-basic-client");
  };

  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
      clientSource: sourceData,
      status: statusstate,
      createdStartDate: createdStartDate,
      createdEndDate: createdToDate,
      query: searchinput,
      officeId: selectedOfficeId,
      page: Number(value - 1),
      size: Number(rows),
    };
    getallLeads(headData);
    setpaginationpageno(value);
  };

  const [getofficeId, setofficeId] = useState();
  const [selectedOfficeId, setSelectedOfficeId] = useState(null);

  const handleChangeOffice = (event) => {
    setbackdropopen(true);


    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    setofficeId(optionElementId);
    const optionElementName = optionElement.getAttribute("value");
    // console.log(optionElementId);
    if (optionElementId === "all") {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(new_id);
      setSelectedOfficeId(new_id);

      let headData = {
        Authorization: jwtToken,
        columnName: sortState,
        direction: direction,
        interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
        clientSource: sourceData,
        status: statusstate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        query: searchinput,
        officeId: Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(","),
        page: Number(pageno),
        size: Number(rows),
      };

      getallLeads(headData);
    } else {
      setSelectedOfficeId(optionElementId);
      getTempClientByOfficeId(optionElementId);
    }
  };

  const [allConsultant, setallConsultant] = useState();
  const getConsultant = () => {
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then(
        (response) => {
          setallConsultant(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const handleAction = (actionType) => {
    setbackdropopen(true);
    if (actionType === 'DOWNLOAD') {
      axios
        .get(`${base_url.api3}/getAllClientTempWithPage`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
            clientSource: sourceData,
            status: statusstate,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            query: searchinput,
            officeId: selectedOfficeId,
            page: Number(0),
            // size: Number(pdflength),
            size: Number(50),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload([]);
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  };

  const [pdflength, setpdflength] = useState(50);
  const [pdfHeader, setPdfHeader] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  const getallLeads = (headData) => {
    console.log(headData);
    setbackdropopen(true);
    // if (isw_user_role === "1" || isw_user_role === "6") {
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: headData,
      })
      .then(
        (response) => {
          // console.log(response.data.data.content);
          setbackdropopen(false);
          headData.size = response.data.data.totalElements;
          if (response.data.data.totalElements == 0) {
            headData.size = Number(50);
            setpdflength(50);
            getPdfData(headData);
          } else {
            setpdflength(response.data.data.totalElements);
            getPdfData(headData);
          }

          setPdfHeader(headData);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setEnquiry(response.data.data.content);
          // let arr = [];
          // let respData = response.data.data.content;
          // for (let i = 0; i < respData.length; i++) {
          //   arr.push({
          //     diffDays: moment(datenow).format("YYYY-MM-DD").diff(moment(respData[i].addedOn).format("YYYY-MM-DD"), 'days'),
          //     addedOn: respData[i].addedOn,
          //     address: respData[i].address
          //   })
          // }
          // setEnquiry(arr);
          console.log(response.data.data.content);
          setsearch(false);
          document.getElementById("myForm").reset();

          //   if (response.data.data.content.addedOn) {
          //   let create_date = moment(
          //     response.data.data.content.addedOn
          //   ).format("YYYY-MM-DD");
          //   console.log(create_date);
          //   console.log(datenow);

          let datetoday = moment(datenow).format("YYYY-MM-DD");
          //   var sangam =(moment(create_date).diff(moment(datetoday), "day"));
          //   console.log(sangam);
          //   }
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
          setpdflength(0);
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setEnquiry([]);
          setsearch(false);
          document.getElementById("myForm").reset();
        }
      );
    // } 
    // else {
    //   let currentOffice = user.isw_available_Office[0].officeId;
    //   getTempClientByOfficeId(currentOffice);
    // }
  };

  let isoDateString = new Date().toISOString();
  let datenow = isoDateString.split(".")[0];

  const getTempClientByOfficeId = (currentOffice) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          officeId: currentOffice,
          interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
          clientSource: sourceData,
          status: statusstate,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          query: searchinput,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setbackdropopen(false);
          if (response.data.data.totalElements == 0) {
            headData.size = Number(50);
            setpdflength(50);
          } else {
            setpdflength(response.data.data.totalElements);
          }
          getsecondPdfData(currentOffice);
          setgetpageno(response.data.data.totalPages);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
        },
        (error) => {
          setbackdropopen(false);
          setEnquiry([]);
          handle403error(error);
        }
      );
  };

  const deleteenquiry = (elem) => {
    console.log("Page", pageno);
    console.log(elem);
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
      status: 1,
    };
    axios
      .delete(`${base_url.api3}/deleteClientTempById`, {
        headers: {
          Authorization: jwtToken,
          clientTempId: deleteEnquiry,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.data.message);

          let log = isw_user_name + " deleted  " + deleteUser;
          callsaveauditapi(log);

          getallLeads(headData);
          setIsOpen(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const archiveenquiry = (elem) => {
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
      status: 1,
    };
    axios
      .post(`${base_url.api3}/updateClientTempById`, {
        headers: {
          Authorization: jwtToken,
          clientTempId: Number(archiveUser),
        },
      })
      .then(
        (response) => {
          toast.success(response.data.data.message);

          let log = isw_user_name + " Updated Status of " + archiveUserData;
          callsaveauditapi(log);

          getallLeads(headData);
          setIsOpen(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [deleteUser, setdeleteUser] = useState();
  var subtitle;
  const openModal = (e, p) => {
    console.log(e.target.id);
    console.log(p);
    setdeleteUser(p.firstName + " " + p.lastName + " (" + p.email + ")");
    setDeleteEnquiry(e.target.id);
    setIsOpen(true);
  };

  const [archiveUserData, setArchiveUserData] = useState();

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen2(false);
    setIsOpen(false);
  };

  const classes = useStyles();

  const viewWorkflow = (p, e) => {
    localStorage.setItem("leadPageNo", pageno);
    localStorage.setItem("selected_temp_client_username", p.email);
    localStorage.setItem("selected_temp_client_id", p.id);
    history.push({ pathname: "/start-client-session/" + p.id });
  };

  const [ConsultantId, setConsultantId] = useState();
  const [ConsultantName, setConsultantName] = useState();
  const handleChangeworkflowName = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setConsultantId(optionElementId);
    setConsultantName(optionElementName);
  };
  const [clientId, setclientId] = useState();
  const [enquiryId, setenquiryId] = useState();

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState(null);
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpaginationpageno(value);
    setpageno(value - 1);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
          clientSource: sourceData,
          status: statusstate,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          query: searchinput,
          officeId: selectedOfficeId,
          direction: direction,
          page: Number(value - 1),
          size: 20,
        },
      })
      .then(
        (res) => {
          setbackdropopen(false);
          setcountnumber(res.data.data.number);
          setgetpageno(res.data.data.totalPages);
          setEnquiry(res.data.data.content);
          setsearch(true);
        },
        (error) => {
          setbackdropopen(false);
          setEnquiry([]);
          handle403error(error);
        }
      );
  };
  const [display1, setdisplay] = useState("inline");
  const submit4 = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    console.log(srchdata);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
          clientSource: sourceData,
          status: statusstate,
          createdStartDate: createdStartDate,
          createdEndDate: createdToDate,
          query: srchdata,
          officeId: selectedOfficeId,
          direction: direction,
          page: Number(0),
          size: 20,
        },
      })
      .then(
        (res) => {
          setbackdropopen(false);
          setgetpageno(res.data.data.totalPages);
          setEnquiry(res.data.data.content);
          settotalUser(res.data.data.totalElements);
          setcountnumber(res.data.data.number);
          setnumberofElements(res.data.data.numberOfElements);
          setsearch(true);
        },
        (error) => {
          setbackdropopen(false);
          setEnquiry([]);
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallLeads(pageno);
    if (isw_user_role === "1" || isw_user_role === "6") {
      axios
        .get(`${base_url.api3}/getAllClientTempWithPage`, {
          headers: {
            Authorization: jwtToken,
            columnName: e.target.id,
            direction: direction,
            interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
            clientSource: sourceData,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            query: searchinput,
            officeId: selectedOfficeId,
            status: statusstate,
            page: Number(pageno),
            size: 20,
          },
        })
        .then(
          (response) => {
            setcountnumber(response.data.data.number);
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
            setsearch(false);
            setbackdropopen(false);
            document.getElementById("myForm").reset();
          },
          (error) => {
            setbackdropopen(false);
            setEnquiry([]);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/getClientTempByAssigneeId`, {
          headers: {
            Authorization: jwtToken,
            assigneeId: isw_adminId,
            columnName: e.target.id,
            interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
            clientSource: sourceData,
            status: statusstate,
            createdStartDate: createdStartDate,
            createdEndDate: createdToDate,
            query: searchinput,
            officeId: selectedOfficeId,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setbackdropopen(false);
            setcountnumber(response.data.data.number);
            setgetpageno(response.data.data.totalPages);
            setEnquiry(response.data.data.content);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const [datefilter, setdatefilter] = useState();

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [createdStartDate, setCreatedStartDate] = useState(null);
  const [createdToDate, setCreatedEndDate] = useState(null);

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDThh:mm:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDThh:mm:ss"),
    };
    setCreatedStartDate(picker.startDate.format("YYYY-MM-DDT00:00:00"));
    setCreatedEndDate(picker.endDate.format("YYYY-MM-DDT23:59:59"));
    setdatesrange(data);
    setpageno(0);
    axios
      .get(`${base_url.api3}/getAllClientTempWithPage`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
          clientSource: sourceData,
          status: statusstate,
          createdStartDate: picker.startDate.format("YYYY-MM-DD"),
          createdEndDate: picker.endDate.format("YYYY-MM-DD"),
          query: searchinput,
          officeId: selectedOfficeId,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setbackdropopen(false);
          setEnquiry(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
          setEnquiry([]);
        }
      );
  };

  const dashboardvalue = useSelector(selectdashboard);

  const verifyuser = () => {
    setbackdropopen(true);
    let data = {
      id: verifyleadid,
      emailVerificationStatus: 1,
    };
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
      status: 1,
    };
    axios
      .post(`${base_url.api3}/updateClientTempById`, data)
      .then((res) => {
        toast.success("User Verified");
        setopenverifymodal(false);
        getallLeads(headData);
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const [WorkFlowStatusData, setWorkFlowStatusData] = useState("");

  const handleChangeWorkFlowStatus = (e) => {
    setbackdropopen(true);
    setWorkFlowStatusData(e.target.value);
    if (e.target.value == "") {
      headData = {
        Authorization: jwtToken,
        columnName: sortState,
        direction: direction,
        clientSource: sourceData,
        status: statusstate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        query: searchinput,
        officeId: selectedOfficeId,
        page: Number(pageno),
        size: Number(rows),
      };
      getallLeads(headData);
    } else {
      headData = {
        Authorization: jwtToken,
        columnName: sortState,
        direction: direction,
        interestedService: e.target.value,
        clientSource: sourceData,
        status: statusstate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        query: searchinput,
        officeId: selectedOfficeId,
        page: Number(pageno),
        size: Number(rows),
      };
      getallLeads(headData);
    }
  };

  const [sourceData, setsourceData] = useState("null");

  const handleChangesouce = (e) => {
    setbackdropopen(true);
    setsourceData(e.target.value);
    if (e.target.value == "") {
      headData = {
        Authorization: jwtToken,
        columnName: sortState,
        direction: direction,
        interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
        status: statusstate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        query: searchinput,
        officeId: selectedOfficeId,
        page: Number(pageno),
        size: Number(rows),
      };
      getallLeads(headData);
    } else {
      headData = {
        Authorization: jwtToken,
        columnName: sortState,
        direction: direction,
        clientSource: e.target.value,
        interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
        status: statusstate,
        createdStartDate: createdStartDate,
        createdEndDate: createdToDate,
        query: searchinput,
        officeId: selectedOfficeId,
        page: Number(pageno),
        size: Number(rows),
      };
      getallLeads(headData);
    }
  };


  // const [hover, setHover] = useState(false); // initial false

  // const onHover = (e) => {
  //   e.preventDefault();
  //   setHover(true); // turn true
  //   console.log("hovered");
  // };

  // const onHoverOver = (e) => {
  //   e.preventDefault(); // turn false
  //   setHover(false);
  // };

  const handleChangeTableStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementName = optionElement.getAttribute("value");
    console.log(optionElementName);
    setstatusstate(Number(optionElementName));
    getallLeads(headData = {
      Authorization: jwtToken,
      columnName: sortState,
      status: optionElement.getAttribute("value"),
      interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
      clientSource: sourceData,
      createdStartDate: createdStartDate,
      createdEndDate: createdToDate,
      query: searchinput,
      officeId: selectedOfficeId,
      direction: direction,
      page: Number(pageno),
      size: Number(rows),
    });;
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Leads</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Client</a><span className="s_span ">-</span>
          <a href="#/leads-list" class="b-link active">
            Lead
          </a>
        </ul>
        <a className="sent-button" onClick={addBasicClient}>
          Add
        </a>
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Manage Leads</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
              <button
                className="btn listing_addbutton"
                onClick={addBasicClient}
              >
                <AddIcon className="listing_addbutton_i" /> Add
              </button>
            </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: "15px",
              marginBottom: "-13px"
            }}
          >
            <Row>
              <div style={{ marginRight: "7px" }}>
                {/* <form className="master">
                  <div style={{ marginTop: "-10px", width: "fit-content" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                        style={{
                          border: "2px solid #ced4da ",
                          paddingTop: "5px",
                          backgroundColor: "#f5f5f9",
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form> */}
                <form className="master">
                  <div style={{ marginTop: "-10px", marginRight: "24px" }}>
                    <label className="area_rowslable_skill" style={{ fontWeight: "bold" }}>Limit</label>
                    <br />
                    <select
                      className="form-control"
                      name="branch"
                      value={rows}
                      onChange={handleRows}
                      style={{ backgroundColor: "#f5f5f9", width: "100px", marginRight: "10px" }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </form>
              </div>
              <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill" style={{ fontWeight: "bold" }}>Date</label>
                <div className="clearsearchclass" style={{ width: "200px", marginTop: "0px" }}>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetextone"
                        style={{ backgroundColor: "#f5f5f9", width: "100%", position: "relative" }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    {/* {datefilter ? (
                  <div className="s_datefilter">
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setdisplay("inline");
                        setdatevalue("Click to open");
                        setdirection("DESC");
                        getallLeads(0);
                        setdatefilter(false);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
              <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill" style={{ fontWeight: "bold" }}>Services:</label>
                <select
                  className="form-control"
                  name="interestedService"
                  value={WorkFlowStatusData}
                  style={{ backgroundColor: "#f5f5f9" }}
                  onChange={handleChangeWorkFlowStatus}
                >
                  <option value="null">- Select WorkFlow Type -</option>
                  <option value="Admission">Admission</option>
                  <option value="Migration">Migration</option>
                  <option value="Insurance">Insurance</option>
                  <option value="SkillAssessment">SkillAssessment</option>
                </select>
              </div>
              <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill" style={{ fontWeight: "bold" }}>Source:</label>
                <select
                  className="form-control"
                  name="clientSource"
                  value={sourceData}
                  style={{ backgroundColor: "#f5f5f9" }}
                  onChange={handleChangesouce}
                >
                  <option value="">- Select Source -</option>
                  <option value="In-house App">In-house App</option>
                  <option value="Referral">Referral</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Sub Agent">Sub Agent</option>
                  <option value="Website">Website</option>
                </select>
              </div>

              <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill" style={{ fontWeight: "bold" }}>Branch</label>
                {isw_user_role === "1" || isw_user_role === "6" ? (
                  <select
                    className="form-control"
                    onChange={handleChangeOffice}
                    style={{ backgroundColor: "#f5f5f9" }}
                  >
                    <option value="" id="all">
                      - Show All -
                    </option>
                    {availableoffice?.map((cuntitem) => (
                      <option
                        key={cuntitem.officeId}
                        value={cuntitem.officeName}
                        id={cuntitem.officeId}
                      >
                        {cuntitem.officeName}
                        {" - "}
                        {cuntitem.officeCode}
                      </option>
                    ))}
                  </select>
                ) : (
                  <>
                    <select
                      // className={classes.selectdrop}
                      onChange={handleChangeOffice}
                      name="branch"
                      style={{
                        width: "100%",
                        padding: "8px 15px",
                        backgroundColor: "#f5f5f9",
                        border: "none",
                      }}
                      // ref={register}
                      required
                    >
                      <option value="" id="all">
                        - Show All -
                      </option>
                      {user.isw_available_Office?.map((cuntitem) => (
                        <option
                          key={cuntitem.officeId}
                          value={cuntitem.officeName}
                          id={cuntitem.officeId}
                        >
                          {cuntitem.officeName}
                          {" - "}
                          {cuntitem.officeCode}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
              <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label className="area_rowslable_skill" style={{ fontWeight: "bold" }}>Status:</label>
                <select
                  className="form-control"
                  defaultValue={getstatusname}
                  onChange={handleChangeTableStatus}
                >
                  <option value="">All</option>
                  <option value="1">Unarchive</option>
                  <option value="0">Archive</option>
                </select>
              </div>
            </Row>
            <div>
              <div className="clearsearchclass" style={{ marginTop: "8px" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit4(e);
                  }}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Type to Search"
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setdisplay("inline");
                    setpageno(0);
                    let headData = {
                      Authorization: jwtToken,
                      columnName: sortState,
                      direction: direction,
                      interestedService: WorkFlowStatusData ? WorkFlowStatusData : null,
                      clientSource: sourceData,
                      status: statusstate,
                      createdStartDate: createdStartDate,
                      createdEndDate: createdToDate,
                      query: searchinput,
                      officeId: selectedOfficeId,
                      page: Number(0),
                      size: Number(rows),
                    };
                    getallLeads(headData);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >

          <Row className="s_pagi" marginBottom="40px">
            <Col md={6}>
              {enquiry?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {/* <Col md={6} className="d-flex justify-content-end">
              <button
                className="sent-button"
                onClick={addBasicClient}
                style={{ float: "right", height: "40px", backgroundColor: "#198754" }}
              >
                Add
              </button>
            </Col> */}
            <Col md={6} style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(
                  202,
                  permissionData,
                  rolepermission
                ) ? (
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfLeadList}
                  >
                    Export PDF
                  </button>
                ) : null}
                {check1pointpermission(
                  202,
                  permissionData,
                  rolepermission
                ) ? (
                  <>
                    {dataForDownload != null ? (
                      <>
                        <button type="button" style={{ marginLeft: "25px" }} className="btn listing_addbutton" onClick={(e) => handleAction('DOWNLOAD')} >Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="Leads_list.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            <Table
              className="workload card-table table-borderless s_table"
              responsive
              class="fixed-header"
              onScroll={scrolltable}
            >
              {/* <table
              id="example"
              className="table workload card-table table-borderless table-responsive"
              style={{ width: "100%" }}
            > */}
              <thead className="s_sticky">
                <tr style={{ color: "#6d7290" }}>
                  <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                  <th style={{ minWidth: "95px" }}>DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="addedOn"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>QUEUE
                    <img
                      src={sort}
                      className="sorting"
                      id="days_in_queue"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>CLIENT
                    <img
                      src={sort}
                      className="sorting"
                      id="firstName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    CONTACT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="phone"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    COUNTRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "115px" }}>
                    SERVICES {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="interestedServices"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    SOURCE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="clientSource"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CONSULTANT{/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="assigneeName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "130px" }}>
                    CITY 
                    <img
                      src={sort}
                      className="sorting"
                      id="city"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                  <th style={{ minWidth: "100px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="officeName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>

                  {/* <th style={{ minWidth: "150px" }}>
                    TIME 
                    <img
                      src={sort}
                      className="sorting"
                      id="time"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                  <th style={{ minWidth: "115px" }}>
                    FOLLOW-UP{/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="followUpOn"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    DEPOSIT{/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="deposit"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "150px" }}>
                    DEADLINE
                    <img
                      src={sort}
                      className="sorting"
                      id="deadline"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                  <th style={{ minWidth: "130px" }}>
                    CREATED BY
                    <img
                      src={sort}
                      className="sorting"
                      id="createdBy"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "103px" }}>
                    UPDATED DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="createdBy"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    REMARKS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="remarks"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "125px" }}>ACTION</th> */}
                  <th style={{ minWidth: "100px" }}>ACTION</th>
                </tr>
              </thead>
              {enquiry != null ? (
                <tbody>
                  {enquiry?.map((p, index) => (
                    <>
                      {p.status === 1 ?
                        <tr key={p.enquiryId} className="s_tr">
                          <td style={{ width: "50px", textAlign: "center" }}>
                            {countnumber * rows + 1 + index}
                          </td>
                          <td>
                            {p.addedOn
                              ? moment(p.addedOn.split("T")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                          </td>
                          <td>
                            {p.days_in_queue}
                            {/* {p.leadType} */}
                            {/* <i class="icon">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.7019 7.88493C5.45358 6.55701 6.55702 5.45357 7.88494 4.70188L4.25157 3.35371C3.99558 3.2587 3.70777 3.32165 3.51474 3.51468C3.32166 3.70771 3.25885 3.99552 3.35377 4.25151L4.7019 7.88493Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M3.61969 12.0001C3.61969 11.2212 3.72689 10.4668 3.92667 9.75073L0.407203 11.3652C0.159047 11.4791 0 11.7271 0 12.0001C0 12.2731 0.159047 12.5211 0.407203 12.6349L3.92672 14.2494C3.72694 13.5334 3.61969 12.779 3.61969 12.0001Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M12 3.61969C12.779 3.61969 13.5333 3.72689 14.2494 3.92667L12.6348 0.407203C12.521 0.159047 12.273 0 12 0C11.727 0 11.479 0.159047 11.3652 0.407203L9.75067 3.92672C10.4667 3.72689 11.221 3.61969 12 3.61969Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M19.2981 7.88488L20.6462 4.25146C20.7412 3.99552 20.6783 3.70766 20.4853 3.51463C20.2922 3.32155 20.0043 3.25878 19.7484 3.35366L16.115 4.70178C17.4429 5.45352 18.5464 6.55696 19.2981 7.88488Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M12 20.3803C11.221 20.3803 10.4667 20.2731 9.75067 20.0733L11.3652 23.5928C11.479 23.8409 11.727 24 12 24C12.273 24 12.521 23.8409 12.6348 23.5928L14.2494 20.0732C13.5333 20.273 12.779 20.3803 12 20.3803Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M4.7019 16.115L3.35377 19.7484C3.25885 20.0043 3.32166 20.2922 3.51474 20.4852C3.6481 20.6186 3.82669 20.6898 4.00876 20.6898C4.09022 20.6898 4.17244 20.6755 4.25157 20.6462L7.88499 19.2981C6.55707 18.5463 5.45363 17.4429 4.7019 16.115Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M23.5928 11.3651L20.0733 9.75049C20.2731 10.4666 20.3803 11.2209 20.3803 11.9998C20.3803 12.7788 20.2731 13.5331 20.0733 14.2492L23.5928 12.6347C23.8409 12.5209 24 12.2729 24 11.9999C24 11.7269 23.8409 11.4789 23.5928 11.3651Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M19.2981 16.115C18.5464 17.4429 17.443 18.5463 16.1151 19.298L19.7485 20.6462C19.8276 20.6756 19.9098 20.6898 19.9913 20.6898C20.1733 20.6898 20.352 20.6186 20.4853 20.4852C20.6784 20.2922 20.7412 20.0044 20.6463 19.7484L19.2981 16.115Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M12 18.9835C8.14932 18.9835 5.01657 15.8507 5.01657 12C5.01657 8.14935 8.14932 5.0166 12 5.0166C15.8507 5.0166 18.9834 8.14935 18.9834 12C18.9834 15.8507 15.8507 18.9835 12 18.9835Z"
                              fill="#B1B1CA"
                            />
                          </svg>
                        </i> */}

                            {/* {moment(p.addedOn).format("YYYY-MM-DD")}
                          <br />
                          {moment(datenow).format("YYYY-MM-DD")}
                          <br /> */}
                            {/* {moment(datenow).format("YYYY-MM-DD").diff(moment(p.addedOn).format("YYYY-MM-DD"), 'days')} */}

                          </td>
                          <td>
                            {p.startTime == null ? (
                              <a
                                className="greeneffect"
                                onClick={(e) => viewWorkflow(p, e)}
                                href={`#/start-client-session/` + p.id}
                                title="Start-Session"
                                style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}
                              >
                                {p.firstName +
                                  " " +
                                  p.lastName}
                                <br />
                                <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                              </a>
                            ) : (
                              <a
                                onClick={(e) => viewWorkflow(p, e)}
                                href={`#/start-client-session/` + p.id}
                                title="Resume-Session"
                              >
                                {p.firstName +
                                  " " +
                                  p.lastName}
                                <br />
                                <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                              </a>
                            )}
                          </td>
                          <td>
                            <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>
                              {p.phone == 0 ? " " : p.phone}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }}>
                              {p.city}
                            </div>
                            <br />
                            <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }}>
                              {p.country}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>
                              {p.interestedServices}
                            </div>
                          </td>
                          <td>{p.clientSource}</td>
                          <td>
                            <>
                              <div class="s_tooltip">
                                {p.assigneeName != null ? p.assigneeName.split(" ")[0] : ""}
                                <span class="s_tooltiptext">
                                  {p.assigneeName}
                                </span>
                              </div>
                            </>
                          </td>
                          {/* <td>
                      <>
                      <div class="s_tooltip">
                      {p.assigneeName != null ? (
                        <>
                        {p.assigneeName.split(" ")[0]}
                        </>
                      ):(
                        <>
                        <span class="s_tooltiptext">
                        {p.assigneeName}
                        </span>
                        </>
                      )}
                        
                      </div>
                      </> */}
                          {/* <br /> */}
                          {/* {p.assigneeEmail} */}

                          {/* <div class="s_tooltip">
                        <img
                          src={mail}
                          className="sorting"
                          style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                          />
                        <span class="s_tooltiptext">{p.assigneeEmail}</span>
                        </div> */}
                          {/* </td> */}
                          {/* <BootstrapTooltip
                        // title={
                        //   <>
                        //     <Typography>
                        //       SST-
                        //       {p.startTimeF
                        //         ? moment(p.startTime.split(".")[0]).format(
                        //           "DD MMM YYYY hh.mm a"
                        //         )
                        //         : null}
                        //     </Typography>
                        //     <Typography>
                        //       SET-
                        //       {p.endTime
                        //         ? moment(p.endTime.split(".")[0]).format(
                        //           "DD MMM YYYY hh.mm a"
                        //         )
                        //         : null}
                        //     </Typography>
                        //   </>
                        // }
                      >
                        <td>
                          {p.firstName} {p.lastName}
                          <br />
                          {p.email}
                        </td>
                      </BootstrapTooltip> */}
                          {/* <td>{p.city}</td> */}
                          <td>
                            <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>{p.officeName}</div>
                            <br />
                            <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>{p.officeCountry}</div>
                          </td>

                          {/* <td>{p.totalTime}</td> */}
                          <td>
                            {p.followUpOn
                              ? moment(p.followUpOn.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                          </td>
                          <td>{p.deposit === 1 ? "Yes" : "No"}</td>
                          {/* <td>
                      {p.deadline
                            ? moment(p.deadline.split(".")[0]).format(
                                "DD-MM-YYYY "
                              )
                       : null}
                      </td> */}

                          {/* <Modal
                        isOpen={remarkmodelopen}
                        onRequestClose={() => setremarkmodelopen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h4>Remark</h4>
                        {remarkcontent ? (
                          <textarea
                            style={{ minWidth: "600px" }}
                            className="form-control"
                            value={remarkcontent}
                            rows={10}
                            readonly
                          />
                        ) : (
                          <p> No Record Available</p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            margin: "10px 0",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="secondary"
                            onClick={() => setremarkmodelopen(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal> */}
                          {/* <td><div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.createdBy}</div></td> */}

                          <td >
                            <>
                              <div class="s_tooltip">
                                {p.createdBy != null ? p.createdBy.split(" ")[0] : ""}
                                <span class="s_tooltiptext">
                                  <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>
                                    {p.createdBy}
                                  </div>
                                </span>
                              </div>
                            </>
                            {/* <div id={p.id}>{p.createdBy != null ? p.createdBy.split(" ")[0] : ""}
                              <ReactTooltip
                                anchorId={p.id}
                                place="bottom"
                                content={p.createdBy}
                                style={{ background: "#17b978" }}
                              />
                            </div> */}
                          </td>
                          <td>
                            {p.updatedOn
                              ? moment(p.updatedOn.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                          </td>
                          <td>
                            {p.clientDetail?.remarks}
                            {p.clientDetail?.remarks != "" ? (
                              // <span
                              //   className="leadellipsisspan"
                              //   onClick={() => {
                              //     setremarkmodelopen(true);
                              //     setremarkcontent(p.remarks);
                              //   }}
                              // >
                              //   View More
                              // </span>

                              <LeadViewMoreModal
                                p={p}
                                toast={toast}
                                id={p.id}
                                getallLeads={getallLeads}
                                headData={headData}
                                handle403error={handle403error}
                                sortState={sortState}
                                direction={direction}
                                pageno={pageno}
                                rows={rows}
                              />
                            ) : null}
                          </td>
                          {/* <td
                        style={{
                          paddingLeft: "0",
                          paddingRight: "0",
                          width: "350px",
                        }}
                      >
                        {p.startTime == null ? (
                          // <a
                          //   color="primary"
                          //   style={{ padding: "5px", fontSize: "15px" }}
                          //   id={p.id}
                          //   onClick={(e) => viewWorkflow(p, e)}
                          //   href={`#/start-client-session/` + p.id}
                          // >
                          //   Start-Session
                          // </a>
                          <a
                            onClick={(e) => viewWorkflow(p, e)}
                            href={`#/start-client-session/` + p.id}
                            title="Start-Session"
                          >
                            <i class="icon">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="#17B978"
                              >
                                <path d="M18.6968 12.5171L16.3638 14.8505C16.1695 15.0447 15.9054 15.1543 15.6302 15.1543C15.3551 15.1543 15.091 15.0451 14.897 14.8505L12.5636 12.5171C12.1588 12.1123 12.1588 11.4556 12.5636 11.0508C12.9684 10.6456 13.6253 10.6456 14.0301 11.0508L14.6634 11.6841C14.5016 9.73477 12.8672 8.19753 10.8767 8.19753C8.78044 8.19753 7.07413 9.90346 7.07413 12C7.07413 14.0962 8.78044 15.8025 10.8767 15.8025C11.4495 15.8025 11.9138 16.2667 11.9138 16.8395C11.9138 17.4123 11.4495 17.8765 10.8767 17.8765C7.63656 17.8765 5 15.2404 5 12C5 8.75961 7.63656 6.12346 10.8767 6.12346C13.9658 6.12346 16.4996 8.52005 16.7313 11.5499L17.2297 11.0508C17.6349 10.6456 18.2917 10.6456 18.6961 11.0508C19.1013 11.4552 19.1013 12.112 18.6968 12.5171Z" />
                              </svg>
                            </i>
                          </a>
                        ) : (
                          // <a
                          //   color="primary"
                          //   style={{ padding: "5px", fontSize: "15px" }}
                          //   onClick={(e) => viewWorkflow(p, e)}
                          //   href={`#/start-client-session/` + p.id}
                          // >
                          //   Resume-Session
                          // </a>
                          <a
                            onClick={(e) => viewWorkflow(p, e)}
                            href={`#/start-client-session/` + p.id}
                            title="Resume-Session"
                          >
                            <i class="icon">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="#17B978"
                              >
                                <path d="M18.6968 12.5171L16.3638 14.8505C16.1695 15.0447 15.9054 15.1543 15.6302 15.1543C15.3551 15.1543 15.091 15.0451 14.897 14.8505L12.5636 12.5171C12.1588 12.1123 12.1588 11.4556 12.5636 11.0508C12.9684 10.6456 13.6253 10.6456 14.0301 11.0508L14.6634 11.6841C14.5016 9.73477 12.8672 8.19753 10.8767 8.19753C8.78044 8.19753 7.07413 9.90346 7.07413 12C7.07413 14.0962 8.78044 15.8025 10.8767 15.8025C11.4495 15.8025 11.9138 16.2667 11.9138 16.8395C11.9138 17.4123 11.4495 17.8765 10.8767 17.8765C7.63656 17.8765 5 15.2404 5 12C5 8.75961 7.63656 6.12346 10.8767 6.12346C13.9658 6.12346 16.4996 8.52005 16.7313 11.5499L17.2297 11.0508C17.6349 10.6456 18.2917 10.6456 18.6961 11.0508C19.1013 11.4552 19.1013 12.112 18.6968 12.5171Z" />
                              </svg>
                            </i>
                          </a>
                        )}
                        {Number(p.emailVerificationStatus) === 0 ? (
                          // <>
                          //   &nbsp;|&nbsp;
                          //   <Link
                          //     id={p.id}
                          //     onClick={() => {
                          //       setverileadid(p.id);
                          //       setopenverifymodal(true);
                          //     }}
                          //   >
                          //     Verify User
                          //   </Link>
                          // </>
                          <a
                            onClick={() => {
                              setverileadid(p.id);
                              setopenverifymodal(true);
                            }}
                            title="Verify-User"
                          >
                            <i class="icon">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="#17B978"
                              >
                                <path d="M10.3609 17.23C10.2272 17.3644 10.0449 17.4394 9.85549 17.4394C9.66609 17.4394 9.48376 17.3644 9.35012 17.23L5.31414 13.1933C4.89529 12.7745 4.89529 12.0953 5.31414 11.6772L5.8195 11.1717C6.23848 10.7529 6.91688 10.7529 7.33573 11.1717L9.85549 13.6916L16.6642 6.88273C17.0832 6.46388 17.7623 6.46388 18.1805 6.88273L18.6858 7.38823C19.1047 7.80708 19.1047 8.48613 18.6858 8.90432L10.3609 17.23Z" />
                              </svg>
                            </i>
                          </a>
                        ) : null}
                        <Modal
                          isOpen={openverifymodal}
                          onRequestClose={() => setopenverifymodal(false)}
                          style={customStyles}
                        >
                          <h4>Are you sure you want to verify the user</h4>
                          <div style={{ float: "right" }}>
                            <Button
                              style={{ float: "right", marginRight: "10px" }}
                              color="danger"
                              onClick={verifyuser}
                            >
                              Confirm
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ float: "right", marginRight: "10px" }}
                              color="secondary"
                              onClick={() => setopenverifymodal(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen2}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Archive Lead ?
                          </h2>
                          <Button color="danger" onClick={archiveenquiry}>
                            Archive
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button color="secondary" onClick={closeModal}>
                            Cancel
                          </Button>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Delete Lead ?
                          </h2>
                          <Button color="danger" onClick={deleteenquiry}>
                            Delete
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button color="secondary" onClick={closeModal}>
                            Cancel
                          </Button>
                        </Modal>
                      </td> */}
                          <td id={p.id}>
                            {Number(user?.isw_user_role) === 1 ||
                              Number(user?.isw_user_role) === 4 ||
                              Number(user?.isw_user_role) === 5 ||
                              Number(user?.isw_user_role) === 6 ||
                              Number(user?.isw_user_role) === 10 ? (
                              <div
                                style={{
                                  position: "relative",
                                  textAlign: "center",
                                }}
                              >
                                <div
                                  className="workflowdashboard_fa-ellipsis"
                                  data-toggle="dropdown"
                                  style={{ width: "50px" }}
                                >
                                  <i
                                    className="fa fa-ellipsis-v "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div
                                  // class="s_dropname"
                                  className="dropdown-menu workflowdashboarddropdown"
                                >
                                  <ul style={{ margin: "0px" }} className="ulclass">
                                    <li>
                                      <p>
                                        <a
                                          id={p.id}
                                          onClick={(e) => viewWorkflow(p, e)}
                                          href={`#/start-client-session/` + p.id}
                                          className="aclass"
                                        >
                                          Edit
                                        </a>
                                      </p>
                                    </li>
                                    <li>
                                      <p>
                                        {p.status === 0 ? (
                                          <LeadArchiveModal
                                            status={p.status}
                                            id={p.id}
                                            jwtToken={jwtToken}
                                            handle403error={handle403error}
                                            getallLeads={getallLeads}
                                            pageno={pageno}
                                            sortState={sortState}
                                            direction={direction}
                                            statusstate={statusstate}
                                            rows={rows}
                                          />
                                        ) : (
                                          <LeadArchiveModal
                                            status={p.status}
                                            id={p.id}
                                            jwtToken={jwtToken}
                                            handle403error={handle403error}
                                            getallLeads={getallLeads}
                                            pageno={pageno}
                                            sortState={sortState}
                                            direction={direction}
                                            statusstate={statusstate}
                                            rows={rows}
                                          />
                                        )}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        :
                        <>
                          {enquiry != null ?
                            <tr key={p.enquiryId} className="s_inactive">
                              <td style={{ width: "50px", textAlign: "center" }}>
                                {countnumber * rows + 1 + index}
                              </td>
                              <td>
                                {p.addedOn
                                  ? moment(p.addedOn.split("T")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                              <td>
                                {p.days_in_queue}
                                {/* {p.leadType} */}
                                {/* <i class="icon">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.7019 7.88493C5.45358 6.55701 6.55702 5.45357 7.88494 4.70188L4.25157 3.35371C3.99558 3.2587 3.70777 3.32165 3.51474 3.51468C3.32166 3.70771 3.25885 3.99552 3.35377 4.25151L4.7019 7.88493Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M3.61969 12.0001C3.61969 11.2212 3.72689 10.4668 3.92667 9.75073L0.407203 11.3652C0.159047 11.4791 0 11.7271 0 12.0001C0 12.2731 0.159047 12.5211 0.407203 12.6349L3.92672 14.2494C3.72694 13.5334 3.61969 12.779 3.61969 12.0001Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M12 3.61969C12.779 3.61969 13.5333 3.72689 14.2494 3.92667L12.6348 0.407203C12.521 0.159047 12.273 0 12 0C11.727 0 11.479 0.159047 11.3652 0.407203L9.75067 3.92672C10.4667 3.72689 11.221 3.61969 12 3.61969Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M19.2981 7.88488L20.6462 4.25146C20.7412 3.99552 20.6783 3.70766 20.4853 3.51463C20.2922 3.32155 20.0043 3.25878 19.7484 3.35366L16.115 4.70178C17.4429 5.45352 18.5464 6.55696 19.2981 7.88488Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M12 20.3803C11.221 20.3803 10.4667 20.2731 9.75067 20.0733L11.3652 23.5928C11.479 23.8409 11.727 24 12 24C12.273 24 12.521 23.8409 12.6348 23.5928L14.2494 20.0732C13.5333 20.273 12.779 20.3803 12 20.3803Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M4.7019 16.115L3.35377 19.7484C3.25885 20.0043 3.32166 20.2922 3.51474 20.4852C3.6481 20.6186 3.82669 20.6898 4.00876 20.6898C4.09022 20.6898 4.17244 20.6755 4.25157 20.6462L7.88499 19.2981C6.55707 18.5463 5.45363 17.4429 4.7019 16.115Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M23.5928 11.3651L20.0733 9.75049C20.2731 10.4666 20.3803 11.2209 20.3803 11.9998C20.3803 12.7788 20.2731 13.5331 20.0733 14.2492L23.5928 12.6347C23.8409 12.5209 24 12.2729 24 11.9999C24 11.7269 23.8409 11.4789 23.5928 11.3651Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M19.2981 16.115C18.5464 17.4429 17.443 18.5463 16.1151 19.298L19.7485 20.6462C19.8276 20.6756 19.9098 20.6898 19.9913 20.6898C20.1733 20.6898 20.352 20.6186 20.4853 20.4852C20.6784 20.2922 20.7412 20.0044 20.6463 19.7484L19.2981 16.115Z"
                              fill="#B1B1CA"
                            />
                            <path
                              d="M12 18.9835C8.14932 18.9835 5.01657 15.8507 5.01657 12C5.01657 8.14935 8.14932 5.0166 12 5.0166C15.8507 5.0166 18.9834 8.14935 18.9834 12C18.9834 15.8507 15.8507 18.9835 12 18.9835Z"
                              fill="#B1B1CA"
                            />
                          </svg>
                        </i> */}

                                {/* {moment(p.addedOn).format("YYYY-MM-DD")}
                          <br />
                          {moment(datenow).format("YYYY-MM-DD")}
                          <br /> */}
                                {/* {moment(datenow).format("YYYY-MM-DD").diff(moment(p.addedOn).format("YYYY-MM-DD"), 'days')} */}

                              </td>
                              <td>
                                {p.startTime == null ? (
                                  <a
                                    className="greeneffect"
                                    onClick={(e) => viewWorkflow(p, e)}
                                    href={`#/start-client-session/` + p.id}
                                    title="Start-Session"
                                    style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}
                                  >
                                    {p.firstName +
                                      " " +
                                      p.lastName}
                                    <br />
                                    <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                                  </a>
                                ) : (
                                  <a
                                    onClick={(e) => viewWorkflow(p, e)}
                                    href={`#/start-client-session/` + p.id}
                                    title="Resume-Session"
                                  >
                                    {p.firstName +
                                      " " +
                                      p.lastName}
                                    <br />
                                    <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                                  </a>
                                )}
                              </td>
                              <td>
                                <div style={{ width: "80px", wordWrap: "break-word", display: "inline-block" }}>
                                  {p.phone == 0 ? " " : p.phone}
                                </div>
                              </td>
                              <td>
                                <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>
                                  {p.city}
                                </div>
                                <br />
                                <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>
                                  {p.country}
                                </div>
                              </td>
                              <td>
                                <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>
                                  {p.interestedServices}
                                </div>
                              </td>
                              <td>
                                <div style={{ width: "80px", wordWrap: "break-word", display: "inline-block" }}>
                                  {p.clientSource}
                                </div>
                              </td>
                              <td>
                                <>
                                  <div class="s_tooltip">
                                    {p.assigneeName != null ? p.assigneeName.split(" ")[0] : ""}
                                    <span class="s_tooltiptext">
                                      {p.assigneeName}
                                    </span>
                                  </div>
                                </>
                              </td>
                              {/* <td>
                      <>
                      <div class="s_tooltip">
                      {p.assigneeName != null ? (
                        <>
                        {p.assigneeName.split(" ")[0]}
                        </>
                      ):(
                        <>
                        <span class="s_tooltiptext">
                        {p.assigneeName}
                        </span>
                        </>
                      )}
                        
                      </div>
                      </> */}
                              {/* <br /> */}
                              {/* {p.assigneeEmail} */}

                              {/* <div class="s_tooltip">
                        <img
                          src={mail}
                          className="sorting"
                          style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                          />
                        <span class="s_tooltiptext">{p.assigneeEmail}</span>
                        </div> */}
                              {/* </td> */}
                              {/* <BootstrapTooltip
                        // title={
                        //   <>
                        //     <Typography>
                        //       SST-
                        //       {p.startTimeF
                        //         ? moment(p.startTime.split(".")[0]).format(
                        //           "DD MMM YYYY hh.mm a"
                        //         )
                        //         : null}
                        //     </Typography>
                        //     <Typography>
                        //       SET-
                        //       {p.endTime
                        //         ? moment(p.endTime.split(".")[0]).format(
                        //           "DD MMM YYYY hh.mm a"
                        //         )
                        //         : null}
                        //     </Typography>
                        //   </>
                        // }
                      >
                        <td>
                          {p.firstName} {p.lastName}
                          <br />
                          {p.email}
                        </td>
                      </BootstrapTooltip> */}
                              {/* <td>{p.city}</td> */}
                              <td>
                                <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>{p.officeName}</div>
                                <br />
                                <div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>{p.officeCountry}</div>
                              </td>

                              {/* <td>{p.totalTime}</td> */}
                              <td>
                                {p.followUpOn
                                  ? moment(p.followUpOn.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                              <td>{p.deposit === 1 ? "Yes" : "No"}</td>
                              {/* <td>
                      {p.deadline
                            ? moment(p.deadline.split(".")[0]).format(
                                "DD-MM-YYYY "
                              )
                       : null}
                      </td> */}

                              {/* <Modal
                        isOpen={remarkmodelopen}
                        onRequestClose={() => setremarkmodelopen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h4>Remark</h4>
                        {remarkcontent ? (
                          <textarea
                            style={{ minWidth: "600px" }}
                            className="form-control"
                            value={remarkcontent}
                            rows={10}
                            readonly
                          />
                        ) : (
                          <p> No Record Available</p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            margin: "10px 0",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="secondary"
                            onClick={() => setremarkmodelopen(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal> */}
                              {/* <td><div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.createdBy}</div></td> */}
                              <td>
                                <>
                                  <div class="s_tooltip">
                                    {p.createdBy != null ? p.createdBy.split(" ")[0] : ""}
                                    <span class="s_tooltiptext">
                                      <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>
                                        {p.createdBy}
                                      </div>
                                    </span>
                                  </div>
                                </>
                              </td>
                              <td>
                                {p.updatedOn
                                  ? moment(p.updatedOn.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                              <td>
                                {p.clientDetail?.remarks}
                                {p.clientDetail?.remarks != "" ? (
                                  // <span
                                  //   className="leadellipsisspan"
                                  //   onClick={() => {
                                  //     setremarkmodelopen(true);
                                  //     setremarkcontent(p.remarks);
                                  //   }}
                                  // >
                                  //   View More
                                  // </span>

                                  <LeadViewMoreModal
                                    p={p}
                                    toast={toast}
                                    id={p.id}
                                    getallLeads={getallLeads}
                                    headData={headData}
                                    handle403error={handle403error}
                                    sortState={sortState}
                                    direction={direction}
                                    pageno={pageno}
                                    rows={rows}
                                  />
                                ) : null}
                              </td>
                              {/* <td
                        style={{
                          paddingLeft: "0",
                          paddingRight: "0",
                          width: "350px",
                        }}
                      >
                        {p.startTime == null ? (
                          // <a
                          //   color="primary"
                          //   style={{ padding: "5px", fontSize: "15px" }}
                          //   id={p.id}
                          //   onClick={(e) => viewWorkflow(p, e)}
                          //   href={`#/start-client-session/` + p.id}
                          // >
                          //   Start-Session
                          // </a>
                          <a
                            onClick={(e) => viewWorkflow(p, e)}
                            href={`#/start-client-session/` + p.id}
                            title="Start-Session"
                          >
                            <i class="icon">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="#17B978"
                              >
                                <path d="M18.6968 12.5171L16.3638 14.8505C16.1695 15.0447 15.9054 15.1543 15.6302 15.1543C15.3551 15.1543 15.091 15.0451 14.897 14.8505L12.5636 12.5171C12.1588 12.1123 12.1588 11.4556 12.5636 11.0508C12.9684 10.6456 13.6253 10.6456 14.0301 11.0508L14.6634 11.6841C14.5016 9.73477 12.8672 8.19753 10.8767 8.19753C8.78044 8.19753 7.07413 9.90346 7.07413 12C7.07413 14.0962 8.78044 15.8025 10.8767 15.8025C11.4495 15.8025 11.9138 16.2667 11.9138 16.8395C11.9138 17.4123 11.4495 17.8765 10.8767 17.8765C7.63656 17.8765 5 15.2404 5 12C5 8.75961 7.63656 6.12346 10.8767 6.12346C13.9658 6.12346 16.4996 8.52005 16.7313 11.5499L17.2297 11.0508C17.6349 10.6456 18.2917 10.6456 18.6961 11.0508C19.1013 11.4552 19.1013 12.112 18.6968 12.5171Z" />
                              </svg>
                            </i>
                          </a>
                        ) : (
                          // <a
                          //   color="primary"
                          //   style={{ padding: "5px", fontSize: "15px" }}
                          //   onClick={(e) => viewWorkflow(p, e)}
                          //   href={`#/start-client-session/` + p.id}
                          // >
                          //   Resume-Session
                          // </a>
                          <a
                            onClick={(e) => viewWorkflow(p, e)}
                            href={`#/start-client-session/` + p.id}
                            title="Resume-Session"
                          >
                            <i class="icon">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="#17B978"
                              >
                                <path d="M18.6968 12.5171L16.3638 14.8505C16.1695 15.0447 15.9054 15.1543 15.6302 15.1543C15.3551 15.1543 15.091 15.0451 14.897 14.8505L12.5636 12.5171C12.1588 12.1123 12.1588 11.4556 12.5636 11.0508C12.9684 10.6456 13.6253 10.6456 14.0301 11.0508L14.6634 11.6841C14.5016 9.73477 12.8672 8.19753 10.8767 8.19753C8.78044 8.19753 7.07413 9.90346 7.07413 12C7.07413 14.0962 8.78044 15.8025 10.8767 15.8025C11.4495 15.8025 11.9138 16.2667 11.9138 16.8395C11.9138 17.4123 11.4495 17.8765 10.8767 17.8765C7.63656 17.8765 5 15.2404 5 12C5 8.75961 7.63656 6.12346 10.8767 6.12346C13.9658 6.12346 16.4996 8.52005 16.7313 11.5499L17.2297 11.0508C17.6349 10.6456 18.2917 10.6456 18.6961 11.0508C19.1013 11.4552 19.1013 12.112 18.6968 12.5171Z" />
                              </svg>
                            </i>
                          </a>
                        )}
                        {Number(p.emailVerificationStatus) === 0 ? (
                          // <>
                          //   &nbsp;|&nbsp;
                          //   <Link
                          //     id={p.id}
                          //     onClick={() => {
                          //       setverileadid(p.id);
                          //       setopenverifymodal(true);
                          //     }}
                          //   >
                          //     Verify User
                          //   </Link>
                          // </>
                          <a
                            onClick={() => {
                              setverileadid(p.id);
                              setopenverifymodal(true);
                            }}
                            title="Verify-User"
                          >
                            <i class="icon">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="#17B978"
                              >
                                <path d="M10.3609 17.23C10.2272 17.3644 10.0449 17.4394 9.85549 17.4394C9.66609 17.4394 9.48376 17.3644 9.35012 17.23L5.31414 13.1933C4.89529 12.7745 4.89529 12.0953 5.31414 11.6772L5.8195 11.1717C6.23848 10.7529 6.91688 10.7529 7.33573 11.1717L9.85549 13.6916L16.6642 6.88273C17.0832 6.46388 17.7623 6.46388 18.1805 6.88273L18.6858 7.38823C19.1047 7.80708 19.1047 8.48613 18.6858 8.90432L10.3609 17.23Z" />
                              </svg>
                            </i>
                          </a>
                        ) : null}
                        <Modal
                          isOpen={openverifymodal}
                          onRequestClose={() => setopenverifymodal(false)}
                          style={customStyles}
                        >
                          <h4>Are you sure you want to verify the user</h4>
                          <div style={{ float: "right" }}>
                            <Button
                              style={{ float: "right", marginRight: "10px" }}
                              color="danger"
                              onClick={verifyuser}
                            >
                              Confirm
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              style={{ float: "right", marginRight: "10px" }}
                              color="secondary"
                              onClick={() => setopenverifymodal(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen2}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Archive Lead ?
                          </h2>
                          <Button color="danger" onClick={archiveenquiry}>
                            Archive
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button color="secondary" onClick={closeModal}>
                            Cancel
                          </Button>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Delete Lead ?
                          </h2>
                          <Button color="danger" onClick={deleteenquiry}>
                            Delete
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button color="secondary" onClick={closeModal}>
                            Cancel
                          </Button>
                        </Modal>
                      </td> */}
                              <td id={p.id}>
                                {Number(user?.isw_user_role) === 1 ||
                                  Number(user?.isw_user_role) === 4 ||
                                  Number(user?.isw_user_role) === 5 ||
                                  Number(user?.isw_user_role) === 6 ||
                                  Number(user?.isw_user_role) === 10 ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div
                                      className="workflowdashboard_fa-ellipsis"
                                      data-toggle="dropdown"
                                      style={{ width: "50px" }}
                                    >
                                      <i
                                        className="fa fa-ellipsis-v "
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div
                                      // class="s_dropname"
                                      className="dropdown-menu workflowdashboarddropdown"
                                    >
                                      <ul style={{ margin: "0px" }} className="ulclass">
                                        <li>
                                          <p>
                                            <a
                                              id={p.id}
                                              onClick={(e) => viewWorkflow(p, e)}
                                              href={`#/start-client-session/` + p.id}
                                              className="aclass"
                                            >
                                              Edit
                                            </a>
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            {p.status === 0 ? (
                                              <LeadArchiveModal
                                                status={p.status}
                                                id={p.id}
                                                jwtToken={jwtToken}
                                                handle403error={handle403error}
                                                getallLeads={getallLeads}
                                                pageno={pageno}
                                                sortState={sortState}
                                                direction={direction}
                                                statusstate={statusstate}
                                                rows={rows}
                                              />
                                            ) : (
                                              <LeadArchiveModal
                                                status={p.status}
                                                id={p.id}
                                                jwtToken={jwtToken}
                                                handle403error={handle403error}
                                                getallLeads={getallLeads}
                                                pageno={pageno}
                                                sortState={sortState}
                                                direction={direction}
                                                statusstate={statusstate}
                                                rows={rows}
                                              />
                                            )}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                            :
                            <p className={classes.headertekst}>{null}</p>}
                        </>
                      }
                    </>
                  ))}
                </tbody>
              ) : (
                <Typography>{null}</Typography>
              )}
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {enquiry?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser} records
                      </div>
                    ) : (
                      <span className="s_spanPage">No Record Found</span>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default LeadList;

// {
//   "createdFromDate": "2021-02-17T11:23:51.342Z",
//    "createdToDate": "2021-02-19T14:02:42.232Z"
//   }
