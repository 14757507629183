import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Redirect,
  useHistory,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ForgotPassword from "./components/ForgotPassword";
import Forgot from "./components/Forgot";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import AdminUsers from "./components/AllUsers/AdminUsers";
import CreateAdmin from "./components/AllUsers/CreateAdmin";
import EditAdmin from "./components/AllUsers/EditAdmin";
import UserPermissions from "./components/AllUsers/UserPermissions";
import ClientUsers from "./components/Client/ClientUsers";
import CreateClient from "./components/Client/CreateClient";
import EditClient from "./components/Client/EditClient";
import ClientView from "./components/Client/ClientView";
import ClientEnquiry from "./components/Client/ClientEnquiry";
import Office from "./components/Office/OfficeList";
import CreateOffice from "./components/Office/CreateOffice";
import OfficeAgents from "./components/Office/OfficeAgents";
import AddOfficeUsers from "./components/Office/AddOfficeUsers";
import EditOfficeUser from "./components/Office/EditOfficeUser";
import EditOffice from "./components/Office/EditOffice";
import ViewEnquiryWorkFlow from "./components/Enquiry/ViewEnquiryWorkFlow";
import LeadList from "./components/Leads/LeadList";
import AddBasicClient from "./components/Leads/AddBasicClient";
import CreateClientLead from "./components/Leads/CreateClientLead";
import StartClientSession from "./components/Leads/StartClientSession";
import AllWorkFlowLists from "./components/Masters/Workflow/AllWorkFlowLists";
import ViewWorkflow from "./components/Masters/Workflow/ViewWorkflow";
import VerticalExample from "./components/Masters/Workflow/VerticalExample";
import Colleges from "./components/Provider/Colleges";
import CreateCollege from "./components/Provider/CreateCollege";
import EditCollege from "./components/Provider/EditCollege";
import ApplicationDetail from "./components/Provider/ApplicationDetail";
import Courses from "./components/Courses/CoursesList";
import CreateCourses from "./components/Courses/CreateCourses";
import EditCourses from "./components/Courses/EditCourses";
import Agents from "./components/Agent/AgentList";
import CreateAgent from "./components/Agent/CreateAgent";
import EditAgent from "./components/Agent/EditAgent";
import Roles from "./components/Roles/RoleList";
import ViewPermission from "./components/Roles/ViewPermission";
import AdmisisonWorkFlowLists from "./components/WorkLoad/AdmisisonWorkFlowLists";
import MigrationWorkFlowLists from "./components/WorkLoad/MigrationWorkFlowLists";
import InsuranceWorkFlowLists from "./components/WorkLoad/InsuranceWorkFlowLists";
import SkillWorkFlowLists from "./components/WorkLoad/SkillWorkFlowLists";
import AreaList from "./components/Masters/Area/AreaList";
import CategoryList from "./components/Masters/Category/CategoryList";
import LevelList from "./components/Masters/Level/LevelList";
import LocationList from "./components/Masters/Location/LocationList";
import AdminConfig from "./components/Masters/AdminConfig/AdminConfig";
import IntakeList from "./components/Masters/Intake/IntakeList";
import ClientSource from "./components/Masters/ClientSource/ClientSource";
import GroupPermission from "./components/Masters/GroupPermission/GroupPermission";
import CreateGroupPermission from "./components/Masters/GroupPermission/CreateGroupPermission";
import EditGroupPermission from "./components/Masters/GroupPermission/EditGroupPermission";
import HealthList from "./components/Masters/HealthCoverType/HealthList";
import ServicesList from "./components/Masters/InterestedServices/ServiceList";
import VisaTypeList from "./components/Masters/VisaType/VisaTypeList";
// import ReceiveOthers from "./components/Finance/ReceiveOthers";

import PayableAdmission from "./components/Finance/IncomeSharing/PayableAdmission";
import PayableMigration from "./components/Finance/IncomeSharing/PayableMigration";
import PayableInsurance from "./components/Finance/IncomeSharing/PayableInsurance";
import PayableGeneral from "./components/Finance/IncomeSharing/PayableGeneral";

import ApplicationOnGoing from "./components/Finance/ApplicationOnGoing/ApplicationOnGoing";

import ReceiveGeneral from "./components/Finance/ACReceivable/ReceiveGeneral";
import ReceiveAdmission from "./components/Finance/ACReceivable/ReceiveAdmission";
import ReceiveInsurance from "./components/Finance/ACReceivable/ReceiveInsurance";
import ReceiveMigration from "./components/Finance/ACReceivable/ReceiveMigration";

import InsuranceList from "./components/Masters/Insurance/InsuranceList";
import ProformaList from "./components/Masters/Proforma/ProformaList";
import EditProforma from "./components/Masters/Proforma/EditProforma";
import CreateProforma from "./components/Masters/Proforma/CreateProforma";

import Ambasador from "./components/Ambasador/Ambasador";
import CreateAmbasador from "./components/Ambasador/CreateAmbasador";
import EditAmbasador from "./components/Ambasador/EditAmbasador";

import BrandAmbasador from "./components/Ambasador/BrandAmbasador";
import CreateBrandAmbasador from "./components/Ambasador/CreateBrandAmbasador";
import EditBrandAmbasador from "./components/Ambasador/EditBrandAmbasador";

import ApplicationReports from "./components/Reports/ApplicationReports";
import ClientReports from "./components/Reports/ClientReports";
import ReceivableInvoiceReports from "./components/Reports/ReceivableInvoiceReports";
import PayableInvoiceReports from "./components/Reports/PayableInvoiceReports";
import LeadsReports from "./components/Reports/LeadsReports";

// import ApplicationReportsNew from "./components/ReportsNew/ApplicationReports";
// import ReceivableInvoiceReportsNew from "./components/ReportsNew/ReceivableInvoiceReports";
// import PayableInvoiceReportsNew from "./components/ReportsNew/PayableInvoiceReports";
import ClientReportsNew from "./components/ReportsNew/General/ClientReportsNew";
import TodayLeadsReports from "./components/ReportsNew/General/TodayLeadsReports";
import BranchWiseGeneralReport from "./components/ReportsNew/General/BranchWiseGeneralReport";
import LeadsReportsNew from "./components/ReportsNew/General/LeadsReportsNew";

import AdmissionReport from "./components/ReportsNew/Admission/AdmissionReport";
import SuperAgentReceviableAdmissionSummeryReport from "./components/ReportsNew/Admission/SuperAgentReceviableAdmissionSummeryReport";
import SubAgentPayableAdmissionSummeryReport from "./components/ReportsNew/Admission/SubAgentPayableAdmissionSummeryReport";
import AdmissionProviderReport from "./components/ReportsNew/Admission/AdmissionProviderReport";

import MigrationReport from "./components/ReportsNew/Migration/MigrationReport";
import SubAgentPayableMigrationSummeryReport from "./components/ReportsNew/Migration/SubAgentPayableMigrationSummeryReport";
import MigrationProviderReport from "./components/ReportsNew/Migration/MigrationProviderReport";

import InsuranceReport from "./components/ReportsNew/Insurance/InsuranceReport";
import SubAgentPayableInsuranceSummeryReport from "./components/ReportsNew/Insurance/SubAgentPayableInsuranceSummeryReport";
import InsuranceProviderReport from "./components/ReportsNew/Insurance/InsuranceProviderReport";

import SkillAssessmentReport from "./components/ReportsNew/SkillAssessment/SkillAssessmentReport";
import SubAgentPayableSkillAssessmentSummeryReport from "./components/ReportsNew/SkillAssessment/SubAgentPayableSkillAssessmentSummeryReport";
import SkillAssessmentProviderReport from "./components/ReportsNew/SkillAssessment/SkillAssessmentProviderReport";

import GrossPrintInvoice from "./components/Invoice/GrossPrintInvoice";
import GeneralPrintInvoice from "./components/Invoice/GeneralPrintInvoice";
import PayablePrintInvoice from "./components/Invoice/PayablePrintInvoice";
import Audit from "./components/Audit/Audit";
import Modal from "react-modal";
import moment from "moment";
import Inbox from "./components/Inbox/Inbox";
import ReceivedInbox from "./components/Inbox/ReceivedInbox";
import "./components/css/LoginForm.css";
import axios from "axios";
import base_url from "./api/bootapi";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  selectUser,
  setdashboardcheck,
} from "./components/features/userSlice";
import { getAdmin, selectAdmin } from "./components/features/adminSlice";
import { getClient } from "./components/features/clientSlice";
import { getcollege } from "./components/features/collegeSlice";
import { getcourse } from "./components/features/courseSlice";
import AddInvoiceDetails from "./components/Invoice/AddInvoiceDetails1";
import Invoice from "./components/Invoice/Invoice";
import EditInvoice from "./components/Invoice/EditInvoice";
import ViewInvoice from "./components/Invoice/ViewInvoice";
import ViewEnquiryWorkflowList from "./components/Enquiry/ViewEnquiryWorkflowList";
import MainDashboard from "./components/MainDashboard/MainDashboard";
import MigrationProviderList from "./components/Masters/MigrationProvider/MigrationProviderList";
import PrintInvoice from "./components/Invoice/PrintInvoice";
import ErrorPage from "./components/ErrorPage";
import { useForm } from "react-hook-form";
import QuotationList from "./components/Quotation/QuotationList";
import CreateQuotation from "./components/Quotation/CreateQuotation";
import EditQuotation from "./components/Quotation/EditQuotation";
import PrintQuotation from "./components/Quotation/PrintQuotation";
import { selectdashboard } from "./components/features/dashboardSlice";

import ViewOffice from "./components/Office/ViewOffice";
import ViewCollege from "./components/Provider/ViewCollege";
import CourseReport from "./components/Courses/CourseReport";
import ViewAgent from "./components/Agent/ViewAgent";
import CourseView from "./components/Courses/CourseView";

import { useStyles, customStyles } from "./Utilities/CSSUtilities";
import { Col, Row, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import NewDashboard from "./components/DashBoard/Dashboard";
import nepcomlogo from "./components/images/nepcoms-logo 1.png";
import studentlogo from "./components/images/studentworld.png";
import logosvg from "./components/images/login-illus.svg";
// import WorkflowFiles from "./components/Enquiry/WorkflowFiles";

const customStyles2 = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

function SecuredRoute(props) {
  return (
    <Route
      exact
      path={props.path}
      render={(data) =>
        props.user ? (
          <props.component {...data}></props.component>
        ) : (
          <Redirect to={{ pathname: "/" }}></Redirect>
        )
      }
    ></Route>
  );
}
Modal.setAppElement("#root");
function LogIn(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const admin2 = useSelector(selectAdmin);
  const { register, handleSubmit, errors } = useForm();
  const [inputtype, setinputtype] = useState("password");

  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [permissionData, setPermissionData] = useState(null);
  const [loginresult, setLoginresult] = useState(null);
  const [statusstate, setstatusstate] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("from_403_status") === "call_toast") {
        toast.warn("Session Expired");
      }
      localStorage.removeItem("from_403_status");
    }, 1000);
  }, []);
  function changeLogInData(e) {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  }

  const onSubmit = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let username = data2.username.trim();
    let password = data2.password.trim();

    let authenticateData = {
      username,
      password,
    };

    fetch(base_url["api3"] + "/authenticate", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(authenticateData),
    })
      .then((r) => r.json())
      .then((result) => {
        if (result.status_code == "202") {
          console.log("Result", result);
          // console.log('At login',result.roles_resources);
          setPermissionData(result.roles_resources);
          const name = result.data.firstName + " " + result.data.lastName;
          // console.log('User name',name)
          localStorage.setItem("isw_user_role_name", result.data.roleName);
          localStorage.setItem("isw_user_role_code", result.data.roleCode);
          localStorage.setItem("isw_user_token", result.jwtToken);
          localStorage.setItem("isw_user_email", result.data.email);
          localStorage.setItem("isw_adminId", result.data.id);
          localStorage.setItem("isw_user_name", name);
          localStorage.setItem("isw_first_name", result.data.firstName);
          localStorage.setItem("isw_user_role", result.data.roleId);

          const permission_obj = [];
          for (let i = 0; i < result.userPermissionData.length; i++) {
            permission_obj.push(result.userPermissionData[i].permissionId);
          }

          // localStorage.setItem("isw_user_permissions", JSON.stringify(permission_obj));

          dispatch(setdashboardcheck(0));
          // let sorteduserdata = result.userOfficeData.sort((a, b) =>
          //   a.officeName.localeCompare(b.officeName)
          // );
          dispatch(
            login({
              isw_user_token: result.jwtToken,
              isw_user_email: result.data.email,
              isw_adminId: result.data.id,
              isw_user_name: name,
              isw_first_name: result.data.firstName,
              isw_role_permissions: result.roles_resources,
              isw_user_permissions: result.userPermissionData,
              isw_user_role: result.data.roleId,
              isw_available_Office: result.userOfficeData,
            })
          );
          let last_data = {
            email: result.data.email,
            lastLogin: datenow,
          };

          axios
            .post(`${base_url.api2}/updateUserDataByEmail`, last_data, {
              headers: {
                Authorization: "Bearer " + result.jwtToken,
              },
            })
            .then(
              (response) => {
                console.log(response.data);
              },
              (error) => {
                console.log(error);
                // toast.error(JSON.stringify(error.response.data.message));
              }
            );

          //Getting admin details
          axios
            .get(`${base_url.api2}/getAllUsers`, {
              headers: {
                Authorization: "Bearer " + result.jwtToken,
                status: 1,
                columnName: "addedOn",
                direction: "DESC",
                page: 0,
                size: 50,
              },
            })
            .then(
              (response) => {
                // console.log("Dispatch Admin response", response.data.data.content);
                var client_admin = response.data.data.content;
                // console.log(response.data.data.content);
                dispatch(getAdmin(client_admin));
                // console.log("Form login", admin2);
              },
              (error) => {
                // toast.error(JSON.stringify(error.response.data.message));
                console.log(error);
              }
            );

          // Getting College Details
          // axios
          //   .get(`${base_url.api2}/getAllInstitution`, {
          //     headers: {
          //       Authorization: "Bearer " + result.jwtToken,
          //       // username: result.data.email,
          //       status: 1,
          //     },
          //   })
          //   .then(
          //     (response) => {
          //       // console.log("Dispatch College response", response);
          //       var client_college = response.data.data.content;
          //       dispatch(getcollege(client_college));
          //     },
          //     (error) => {
          //       // toast.error(JSON.stringify(error.response.data.message));
          //       console.log(error);
          //     }
          //   );

          //Getting course details
          // axios
          //   .get(`${base_url.api2}/getAllCourses`, {
          //     headers: {
          //       Authorization: "Bearer " + result.jwtToken,
          //       // username: result.data.email,
          //       status: 1,
          //     },
          //   })
          //   .then(
          //     (response) => {
          //       // console.log("Dispatch course response", response);
          //       var client_course = response.data.data.content;
          //       dispatch(getcourse(client_course));
          //     },
          //     (error) => {
          //       // toast.error(JSON.stringify(error.response.data.message));
          //       console.log(error);
          //     }
          //   );

          // Getting all clients
          axios
            .get(`${base_url.api3}/getAllClients`, {
              headers: {
                Authorization: "Bearer " + result.jwtToken,
                username: result.data.email,
              },
            })
            .then(
              (response) => {
                // console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                dispatch(getClient(client_obj));
              },
              (error) => {
                // toast.error(JSON.stringify(error.response.data.message));
                console.log(error);
              }
            );

          toast.success("Success");
          setLoginresult(result);
          // authentication.onAuthentication();
          // props.history.push('/dashboard');
          axios
            .get("https://geolocation-db.com/json/")
            // .get("https://geolocation-db.com/jsonp/")
            .then((res) => {
              let ipaddress = res.data.IPv4;
              localStorage.setItem("AdminIpAddress", res.data.IPv4);
              let createdByName = name + " (" + result.data.email + ")";

              let todayDate = new Date();
              let fmt = "YYYY-MM-DD HH:mm:ss";
              let m = moment.utc(todayDate, fmt);
              let createdAt = m.local().format(fmt);

              let auditdata = {
                adminIp: ipaddress,
                createdById: result.data.id,
                createdByName: createdByName,
                id: 0,
                log: name + " logged in.",
                createdAt,
              };
              axios
                .post(`${base_url.api4}/saveAudit`, auditdata, {
                  headers: {
                    Authorization: "Bearer " + result.jwtToken,
                  },
                })
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                  // toast.error(JSON.stringify(error.response.data.message));
                });
            });

          setTimeout(
            () =>
              // props.history.push({ pathname: "/dashboard", state: result }),
              history.push("/"),
            1000
          );
        } else if (result.status_code == "400") {
          toast.error("Your account is deactivated");
        } else {
          toast.error("Invalid UserName or PassWord");
          // console.log(result);
        }
      });
  };

  const goBack = () => {
    history.push("/forgotpage");
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openForgotPassModal = (elem) => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [forgotUser, setForgotUser] = useState(false);
  const forgotPassword = (e) => {
    // console.log(e.target[0].value);
    axios
      .get(`${base_url.api2}/forgotAdminPassword`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          email: e.target[0].value.trim(),
        },
      })
      .then(
        (response) => {
          setIsOpen(false);
          toast.success("Reset Password Link has been sent to your Email Id");
        },
        (error) => {
          setForgotUser(true);
          // toast.error("User Does Not Exist");
          // setIsOpen(false);
        }
      );
  };

  return (
    <>
      <div className="login-page d-flex justify-content-center align-items-center">
        {/* <ToastContainer /> */}
        <div className="form-container">
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="login-image text-center">
                <div className="logo-container">
                  <a>
                    <img src={nepcomlogo} alt="hello1" style={{ height: "45%", width: "55%", marginBottom: "30px" }} />
                  </a>
                </div>
                <div className="img-container">
                  <img src={logosvg} alt="hello2" />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12">
              <form
                action=""
                className="login-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-head">
                  <h3 className="form-title">Login</h3>
                  <p className="form-desc">
                    Please enter the credential below to login.
                  </p>
                </div>

                <div className="form-group">
                  <div className="input-grp">
                    <i className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_342_113)">
                          <path
                            d="M14.9318 12.0922L21 15.9285V8.094L14.9318 12.0922Z"
                            fill="#17B978"
                          />
                          <path
                            d="M3 8.094V15.9285L9.06825 12.0922L3 8.094Z"
                            fill="#17B978"
                          />
                          <path
                            d="M19.8749 5.8125H4.12494C3.56357 5.8125 3.11807 6.231 3.03369 6.76988L11.9999 12.6773L20.9662 6.76988C20.8818 6.231 20.4363 5.8125 19.8749 5.8125Z"
                            fill="#17B978"
                          />
                          <path
                            d="M13.9013 12.7717L12.3094 13.8202C12.2149 13.8821 12.108 13.9125 12 13.9125C11.892 13.9125 11.7851 13.8821 11.6906 13.8202L10.0988 12.7706L3.03601 17.238C3.12264 17.7724 3.56589 18.1875 4.12501 18.1875H19.875C20.4341 18.1875 20.8774 17.7724 20.964 17.238L13.9013 12.7717Z"
                            fill="#17B978"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_342_113">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(3 3)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email"
                      value={loginData.username}
                      name="username"
                      onChange={changeLogInData}
                      // required
                      ref={register({ required: true })}
                    />
                    {errors.username && (
                      <p className="errormessage">Username is Required</p>
                    )}
                  </div>
                  <div className="input-grp">
                    <i className="icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.375 9.75H17.25V8.24999C17.25 5.35512 14.8949 3 12 3C9.10509 3 6.75001 5.35512 6.75001 8.24999V9.75H5.62501C5.41773 9.75 5.25 9.91773 5.25 10.125V19.5C5.25 20.3273 5.92271 21 6.75001 21H17.25C18.0773 21 18.75 20.3273 18.75 19.5V10.125C18.75 9.91773 18.5823 9.75 18.375 9.75ZM13.1228 17.5836C13.1345 17.6894 13.1005 17.7956 13.0294 17.8751C12.9584 17.9546 12.8566 18 12.75 18H11.25C11.1435 18 11.0416 17.9546 10.9706 17.8751C10.8995 17.7957 10.8655 17.6895 10.8772 17.5836L11.1138 15.4563C10.7296 15.1769 10.5 14.7349 10.5 14.25C10.5 13.4227 11.1727 12.75 12 12.75C12.8273 12.75 13.5 13.4227 13.5 14.25C13.5 14.7349 13.2704 15.1769 12.8863 15.4563L13.1228 17.5836ZM15 9.75H9.00001V8.24999C9.00001 6.59582 10.3458 5.25 12 5.25C13.6542 5.25 15 6.59582 15 8.24999V9.75Z"
                          fill="#17B978"
                        />
                      </svg>
                    </i>
                    <input
                      className="form-control"
                      type={inputtype}
                      placeholder="Password"
                      value={loginData.password}
                      name="password"
                      onChange={changeLogInData}
                      // required
                      ref={register({ required: true })}
                    />
                  </div>
                </div>

                <div className="form-group button-container">
                  <button type="submit" className="btn login-btn">
                    LOGIN
                  </button>
                </div>
              </form>

              <div className="text-center">
                <Link onClick={goBack} className="forget-password-link">
                  Forgot password
                </Link>
              </div>
              {/* <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles2}
            contentLabel="Example Modal"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                forgotPassword(e);
              }}
            >
              <div>
                <h3 style={{ color: "black" }}>Forgot Password</h3>
                <div className="form-group creatadmin">
                  <Row>
                    <Col>
                      <label>User Name:</label>
                      <input
                        type="text"
                        className="form-control underline-input"
                        required
                      />
                      {forgotUser && (
                        <p className="errormessage">User Does Not Exist</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row style={{ float: "right" }}>
                    <Button
                      style={{
                        marginTop: "20px",
                        marginRight: "10px",
                      }}
                      type="submit"
                      color="primary"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{
                        marginTop: "20px",
                        marginRight: "10px",
                      }}
                      color="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </Row>
                </div>
              </div>
            </form>
          </Modal> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function App(props) {
  const user = useSelector(selectUser);
  const dashboardvalue = useSelector(selectdashboard);
  const dispatch = useDispatch();
  const history = useHistory();

  // For POST requests
  // axios.interceptors.response.use(
  //   undefined,
  //   function axiosRetryInterceptor(err) {
  //     if (err) {
  //       if (err.response) {
  //         if (Number(err?.response?.status) === 403) {
  //           setTimeout(() => {
  //             dispatch(logout());
  //             localStorage.clear();
  //             localStorage.setItem("from_403_status", "call_toast");
  //             history.push("/");
  //             // toast.warn("Session Expired");
  //           }, 1000);
  //         }
  //       }
  //     }

  //     return Promise.reject(err);
  //   }
  // );

  return (
    <div>
      <ToastContainer />
      {!user ? (
        <Switch>
          <Route exact path="/">
            <LogIn />
          </Route>
          <Route exact path="/forgotpage">
            <Forgot />
          </Route>
          <Route path="/forgot-password/:email/:token">
            <ForgotPassword />
          </Route>
        </Switch>
      ) : (
        <>
          <Switch>
            <Route exact path="/">
              <NewDashboard
                Comp={MainDashboard}
                sidebarvalue={"dashboard"}
                dashboardcheck={true}
              />
              {/* {user.isw_user_role === 4 || user.isw_user_role === 5 ? null : (
                <MainDashboard />
              )} */}
            </Route>

            <Route path="/sent-mails">
              <NewDashboard Comp={Inbox} sidebarvalue={"sent"} />
            </Route>

            <Route path="/received-mails">
              <NewDashboard Comp={ReceivedInbox} sidebarvalue={"received"} />
            </Route>

            <Route path="/profile">
              <NewDashboard Comp={Profile} sidebarvalue={"dashboard"} />
            </Route>

            <Route path="/edit-profile/:admin_username">
              <NewDashboard Comp={EditProfile} sidebarvalue={"dashboard"} />
            </Route>

            <Route path="/client-list">
              <NewDashboard Comp={ClientUsers} sidebarvalue={"client"} />
            </Route>

            <Route path="/edit-client/:client_username">
              <NewDashboard Comp={EditClient} sidebarvalue={"client"} />
            </Route>

            <Route path="/client-view/:client_username">
              <NewDashboard Comp={ClientView} sidebarvalue={"client"} />
            </Route>

            <Route path="/create-client">
              <NewDashboard Comp={CreateClient} sidebarvalue={"client"} />
            </Route>

            {/* <Route path="/workFlowSteps/:workflow_id">
              <NewDashboard Comp={VerticalExample} />
            </Route> */}

            <Route path="/leads-list">
              <NewDashboard Comp={LeadList} sidebarvalue={"lead"} />
            </Route>

            <Route path="/add-basic-client">
              <NewDashboard Comp={AddBasicClient} sidebarvalue={"lead"} />
            </Route>

            <Route path="/start-client-session/:temp_client_id">
              <NewDashboard Comp={StartClientSession} sidebarvalue={"lead"} />
            </Route>

            <Route path="/create-client-lead/:temp_client_id/:client_username">
              <NewDashboard Comp={CreateClientLead} sidebarvalue={"lead"} />
            </Route>

            <Route path="/view-enquiry-workflow/:client_id/:enquiry_id/:client_name">
              <NewDashboard
                Comp={ViewEnquiryWorkFlow}
                sidebarvalue={"client"}
              />
            </Route>

            <Route path="/view-enquiry-workflowList/:client_id/:client_name">
              <NewDashboard
                Comp={ViewEnquiryWorkflowList}
                sidebarvalue={"client"}
              />
            </Route>

            <Route path="/add-invoice-details/:client_id">
              <NewDashboard Comp={AddInvoiceDetails} sidebarvalue={"client"} />
            </Route>

            <Route path="/edit-invoice-details/:client_id/:invoice_id">
              <NewDashboard Comp={EditInvoice} sidebarvalue={"client"} />
            </Route>

            <Route path="/view-invoice-details/:client_id/:invoice_id">
              <NewDashboard Comp={ViewInvoice} sidebarvalue={"client"} />
            </Route>

            <Route
              exact
              path="/commisionnetprintinvoice/:client_id/:invoice_id"
            >
              <PrintInvoice />
            </Route>
            <Route
              exact
              path="/commisiongrossprintinvoice/:client_id/:invoice_id"
            >
              <GrossPrintInvoice />
            </Route>
            <Route exact path="/payableprintinvoice/:client_id/:invoice_id">
              <PayablePrintInvoice />
            </Route>
            <Route
              exact
              path="/generalinvoiceprintinvoice/:client_id/:invoice_id"
            >
              <GeneralPrintInvoice />
            </Route>

            <Route path="/invoice">
              <NewDashboard Comp={Invoice} sidebarvalue={"client"} />
            </Route>

            <Route path="/workflow">
              <NewDashboard Comp={AllWorkFlowLists} sidebarvalue={"workflow"} />
            </Route>

            <Route path="/view-workflow/:workflow_id">
              <NewDashboard Comp={ViewWorkflow} sidebarvalue={"workflow"} />
            </Route>

            <Route path="/users">
              <NewDashboard Comp={AdminUsers} sidebarvalue={"manageuser"} />
            </Route>

            <Route path="/create-users">
              <NewDashboard Comp={CreateAdmin} sidebarvalue={"manageuser"} />
            </Route>

            <Route path="/edit-users/:admin_username">
              <NewDashboard Comp={EditAdmin} sidebarvalue={"manageuser"} />
            </Route>

            <Route path="/user-permissions/:admin_id/:admin_email">
              <NewDashboard
                Comp={UserPermissions}
                sidebarvalue={"manageuser"}
              />
            </Route>

            <Route path="/office">
              <NewDashboard Comp={Office} sidebarvalue={"managebranch"} />
            </Route>

            <Route path="/create-office">
              <NewDashboard Comp={CreateOffice} sidebarvalue={"managebranch"} />
            </Route>

            <Route path="/view-office/:branch_id">
              <NewDashboard Comp={ViewOffice} sidebarvalue={"managebranch"} />
            </Route>

            <Route path="/office-agents/:office_id">
              <NewDashboard Comp={OfficeAgents} sidebarvalue={"managebranch"} />
            </Route>

            <Route path="/create-office-users/:office_id">
              <NewDashboard
                Comp={AddOfficeUsers}
                sidebarvalue={"managebranch"}
              />
            </Route>

            <Route path="/edit-office/:office_ids">
              <NewDashboard Comp={EditOffice} sidebarvalue={"managebranch"} />
            </Route>

            <Route path="/agent">
              <NewDashboard Comp={Agents} sidebarvalue={"agent"} />
            </Route>

            <Route path="/view-agent/:agent_id/:agent_email">
              <NewDashboard Comp={ViewAgent} sidebarvalue={"agent"} />
            </Route>

            <Route path="/create-agent">
              <NewDashboard Comp={CreateAgent} sidebarvalue={"agent"} />
            </Route>

            <Route path="/edit-agent/:agent_id/:agent_email">
              <NewDashboard Comp={EditAgent} sidebarvalue={"agent"} />
            </Route>

            <Route path="/provider">
              <NewDashboard Comp={Colleges} sidebarvalue={"provider"} />
            </Route>

            <Route path="/create-provider">
              <NewDashboard Comp={CreateCollege} sidebarvalue={"provider"} />
            </Route>

            <Route path="/view-provider/:provider_id">
              <NewDashboard Comp={ViewCollege} sidebarvalue={"provider"} />
            </Route>

            <Route path="/edit-provider/:provider_id">
              <NewDashboard Comp={EditCollege} sidebarvalue={"provider"} />
            </Route>
            <Route path="/application-details">
              <NewDashboard
                Comp={ApplicationDetail}
                sidebarvalue={"provider"}
              />
            </Route>

            <Route path="/course">
              <NewDashboard Comp={Courses} sidebarvalue={"course"} />
            </Route>

            <Route path="/course-report/:course_id">
              <NewDashboard Comp={CourseView} sidebarvalue={"course"} />
            </Route>

            <Route path="/create-course">
              <NewDashboard Comp={CreateCourses} sidebarvalue={"course"} />
            </Route>

            <Route path="/edit-course/:course_id">
              <NewDashboard Comp={EditCourses} sidebarvalue={"course"} />
            </Route>

            <Route path="/edit-office-user/:office_id/:user_email">
              <NewDashboard Comp={EditOfficeUser} sidebarvalue={"agent"} />
            </Route>

            <Route path="/view-client-enquiry/:client_id/:client_name">
              <NewDashboard Comp={ClientEnquiry} sidebarvalue={"client"} />
            </Route>

            <Route path="/roles">
              <NewDashboard Comp={Roles} sidebarvalue={"managerole"} />
            </Route>

            <Route path="/view-permission/:role_id/:role_name">
              <NewDashboard Comp={ViewPermission} sidebarvalue={"course"} />
            </Route>

            <Route path="/admission-enquiry">
              <NewDashboard
                Comp={AdmisisonWorkFlowLists}
                sidebarvalue={"admission"}
              />
            </Route>

            <Route path="/migration-enquiry">
              <NewDashboard
                Comp={MigrationWorkFlowLists}
                sidebarvalue={"migration"}
              />
            </Route>

            <Route path="/insurance-enquiry">
              <NewDashboard
                Comp={InsuranceWorkFlowLists}
                sidebarvalue={"insurance"}
              />
            </Route>

            <Route path="/skillAssessment-enquiry">
              <NewDashboard
                Comp={SkillWorkFlowLists}
                sidebarvalue={"skillassessment"}
              />
            </Route>

            <Route path="/locations">
              <NewDashboard Comp={LocationList} sidebarvalue={"location"} />
            </Route>

            <Route path="/admin-config">
              <NewDashboard Comp={AdminConfig} sidebarvalue={"adminconfig"} />
            </Route>

            <Route path="/areas">
              <NewDashboard Comp={AreaList} sidebarvalue={"area"} />
            </Route>

            <Route path="/category">
              <NewDashboard Comp={CategoryList} sidebarvalue={"category"} />
            </Route>

            <Route path="/levels">
              <NewDashboard Comp={LevelList} sidebarvalue={"level"} />
            </Route>

            <Route path="/migrationprovider">
              <NewDashboard
                Comp={MigrationProviderList}
                sidebarvalue={"migrationprovider"}
              />
            </Route>

            <Route path="/intake">
              <NewDashboard Comp={IntakeList} sidebarvalue={"intake"} />
            </Route>

            <Route path="/client-source">
              <NewDashboard Comp={ClientSource} sidebarvalue={"clientsource"} />
            </Route>

            <Route path="/group-permissions">
              <NewDashboard
                Comp={GroupPermission}
                sidebarvalue={"grouppermission"}
              />
            </Route>

            <Route path="/create-group-permissions">
              <NewDashboard
                Comp={CreateGroupPermission}
                sidebarvalue={"grouppermission"}
              />
            </Route>

            <Route path="/edit-group-permissions/:group_id">
              <NewDashboard
                Comp={EditGroupPermission}
                sidebarvalue={"grouppermission"}
              />
            </Route>

            <Route path="/healthcovertype">
              <NewDashboard
                Comp={HealthList}
                sidebarvalue={"healthcovertype"}
              />
            </Route>

            <Route path="/interestedservices">
              <NewDashboard
                Comp={ServicesList}
                sidebarvalue={"interestedservices"}
              />
            </Route>

            <Route path="/visatype">
              <NewDashboard Comp={VisaTypeList} sidebarvalue={"visatype"} />
            </Route>

            <Route path="/application-ongoing">
              <NewDashboard
                Comp={ApplicationOnGoing}
                sidebarvalue={"applicationongoing"}
              />
            </Route>

            <Route path="/acc-admission">
              <NewDashboard
                Comp={ReceiveAdmission}
                sidebarvalue={"accadmission"}
              />
            </Route>

            <Route path="/acc-insurance">
              <NewDashboard
                Comp={ReceiveInsurance}
                sidebarvalue={"accrecinsurance"}
              />
            </Route>

            <Route path="/acc-migration">
              <NewDashboard
                Comp={ReceiveMigration}
                sidebarvalue={"accrecmigration"}
              />
            </Route>

            <Route path="/acc-general">
              <NewDashboard
                Comp={ReceiveGeneral}
                sidebarvalue={"accrecgeneral"}
              />
            </Route>

            <Route path="/acc-payable-admission">
              <NewDashboard
                Comp={PayableAdmission}
                sidebarvalue={"accpayableadmission"}
              />
            </Route>

            <Route path="/acc-payable-insurance">
              <NewDashboard
                Comp={PayableInsurance}
                sidebarvalue={"accpayableinsurance"}
              />
            </Route>

            <Route path="/acc-payable-migration">
              <NewDashboard
                Comp={PayableMigration}
                sidebarvalue={"accpayablemigration"}
              />
            </Route>

            <Route path="/acc-payable-general">
              <NewDashboard
                Comp={PayableGeneral}
                sidebarvalue={"accpayablegeneral"}
              />
            </Route>

            <Route path="/insurance">
              <NewDashboard
                Comp={InsuranceList}
                sidebarvalue={"settinginsurance"}
              />
            </Route>

            <Route path="/proforma">
              <NewDashboard Comp={ProformaList} sidebarvalue={"proforma"} />
            </Route>

            <Route path="/edit-proforma/:proforma_id">
              <NewDashboard Comp={EditProforma} sidebarvalue={"proforma"} />
            </Route>

            <Route path="/create-proforma">
              <NewDashboard Comp={CreateProforma} sidebarvalue={"proforma"} />
            </Route>

            <Route path="/ambassador">
              <NewDashboard Comp={Ambasador} sidebarvalue={"ambassador"} />
            </Route>

            <Route path="/edit-ambassador/:ambassador_email">
              <NewDashboard Comp={EditAmbasador} sidebarvalue={"ambassador"} />
            </Route>

            <Route path="/create-ambassador">
              <NewDashboard
                Comp={CreateAmbasador}
                sidebarvalue={"ambassador"}
              />
            </Route>

            <Route path="/brand-ambassador">
              <NewDashboard Comp={BrandAmbasador} sidebarvalue={"brandamb"} />
            </Route>

            <Route path="/edit-brand-ambassador/:brand_ambassador_id">
              <NewDashboard
                Comp={EditBrandAmbasador}
                sidebarvalue={"brandamb"}
              />
            </Route>

            <Route path="/create-brand-ambassador">
              <NewDashboard
                Comp={CreateBrandAmbasador}
                sidebarvalue={"brandamb"}
              />
            </Route>

            <Route path="/audit">
              <NewDashboard Comp={Audit} sidebarvalue={"audit"} />
            </Route>

            <Route path="/application-report">
              <NewDashboard
                Comp={ApplicationReports}
                sidebarvalue={"applicationreport"}
              />
            </Route>

            <Route path="/receivable-invoice-report">
              <NewDashboard
                Comp={ReceivableInvoiceReports}
                sidebarvalue={"receivablereport"}
              />
            </Route>

            <Route path="/payable-invoice-report">
              <NewDashboard
                Comp={PayableInvoiceReports}
                sidebarvalue={"payablereport"}
              />
            </Route>

            <Route path="/today-lead-report">
              <NewDashboard
                Comp={TodayLeadsReports}
                sidebarvalue={"todayLeadReport"}
              />
            </Route>

            <Route path="/leads-report">
              <NewDashboard
                Comp={LeadsReportsNew}
                sidebarvalue={"leadsreport"}
              />
            </Route>

            <Route path="/client-report">
              <NewDashboard
                Comp={ClientReportsNew}
                sidebarvalue={"clientreport"}
              />
            </Route>

            <Route path="/branch-wise-general-summery-report">
              <NewDashboard
                Comp={BranchWiseGeneralReport}
                sidebarvalue={"branchWiseSummeryReport"}
              />
            </Route>

            <Route path="/admisison-report">
              <NewDashboard
                Comp={AdmissionReport}
                sidebarvalue={"admissionSubReport"}
              />
            </Route>

            <Route path="/superAgent-receivable-admission-summery-report">
              <NewDashboard
                Comp={SuperAgentReceviableAdmissionSummeryReport}
                sidebarvalue={"superAgentReceviableAdmissionSummeryReport"}
              />
            </Route>

            <Route path="/subAgent-payable-admission-summery-report">
              <NewDashboard
                Comp={SubAgentPayableAdmissionSummeryReport}
                sidebarvalue={"subAgentPayableAdmissionSummeryReport"}
              />
            </Route>

            <Route path="/admission-provider-summery-report">
              <NewDashboard
                Comp={AdmissionProviderReport}
                sidebarvalue={"branchWiseAdmissionSummeryReport"}
              />
            </Route>

            <Route path="/migration-report">
              <NewDashboard
                Comp={MigrationReport}
                sidebarvalue={"migrationSubReport"}
              />
            </Route>

            <Route path="/subAgent-payable-migration-summery-report">
              <NewDashboard
                Comp={SubAgentPayableMigrationSummeryReport}
                sidebarvalue={"subAgentPayableMigrationSummeryReport"}
              />
            </Route>

            <Route path="/migration-provider-summery-report">
              <NewDashboard
                Comp={MigrationProviderReport}
                sidebarvalue={"migrationProviderSummeryReport"}
              />
            </Route>

            <Route path="/insurance-report">
              <NewDashboard
                Comp={InsuranceReport}
                sidebarvalue={"insurenceSubReport"}
              />
            </Route>

            <Route path="/subAgent-payable-insurence-summery-report">
              <NewDashboard
                Comp={SubAgentPayableInsuranceSummeryReport}
                sidebarvalue={"subAgentPayableInsurenceSummeryReport"}
              />
            </Route>

            <Route path="/insurence-provider-summery-report">
              <NewDashboard
                Comp={InsuranceProviderReport}
                sidebarvalue={"insurenceProviderSummeryReport"}
              />
            </Route>

            <Route path="/skillAssessment-report">
              <NewDashboard
                Comp={SkillAssessmentReport}
                sidebarvalue={"skillAssessmentSubReport"}
              />
            </Route>

            <Route path="/subAgent-payable-skill-assessment-summery-report">
              <NewDashboard
                Comp={SubAgentPayableSkillAssessmentSummeryReport}
                sidebarvalue={"subAgentPayableSkillAssessmentSummeryReport"}
              />
            </Route>

            <Route path="/skill-assessment-provider-summery-report">
              <NewDashboard
                Comp={SkillAssessmentProviderReport}
                sidebarvalue={"skillAssessmentProviderSummeryReport"}
              />
            </Route>

            <Route path="/quotations">
              <NewDashboard Comp={QuotationList} sidebarvalue={"quotation"} />
            </Route>

            <Route path="/create-quotation">
              <NewDashboard Comp={CreateQuotation} sidebarvalue={"quotation"} />
            </Route>

            <Route path="/edit-quotation/:quotation_id">
              <NewDashboard Comp={EditQuotation} sidebarvalue={"quotation"} />
            </Route>

            <Route exact path="/printquotation/:quotation_id">
              <PrintQuotation />
            </Route>

            {/* <Route exact path="/viewfile/:client_id/:file_id">
              <WorkflowFiles />
            </Route> */}
            <Route>
              <NewDashboard Comp={ErrorPage} sidebarvalue={"dashboard"} />
            </Route>
          </Switch>
        </>
      )}
    </div>
  );
}

// ReactDOM.render(<BrowserRouter><App></App></BrowserRouter>,
//   document.getElementById("root"));

export default App;
