import React, { useEffect, useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./../css/LoginForm.css";
import { selectAdmin, updateAdmin } from "./../features/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser, setdashboardcheck } from "./../features/userSlice";
import "./../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Multiselect } from "multiselect-react-dropdown";
import { useForm } from "react-hook-form";
import { selectdashboard } from "../features/dashboardSlice";
import { useStyles } from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import { check1pointpermission } from "../Permission/permission";

function EditBrandAmbasador(props) {
  let params = useParams();
  const admin = useSelector(selectAdmin);
  const history = useHistory();
  const username2 = params.admin_username;
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const { register, handleSubmit, errors } = useForm();
  console.log("User", admin);
  const particularUser = [];
  admin.map((item) => {
    if (item.username === username2) {
      particularUser.push(item);
    }
  });
  console.log("Particular User", particularUser);
  const dispatch = useDispatch();
  // console.log(params.admin_username);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const username = localStorage.getItem("isw_user_email");
  const classes = useStyles();
  const [stateCust, setstateCust] = useState({});
  const [availableroles, setAvailableroles] = useState(null);
  const [selectedOffice, setselectedOffice] = useState();
  const [selecteddate, setdate] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    var available_resource = resource_obj.includes(31);
    if (check1pointpermission(1400, permission_obj, resource_obj)) {
      getUser();
      getRoles();
      getOffice();
      dispatch(setdashboardcheck(31));
    } else {
      history.push("/errorPage");
    }
  };

  const getRoles = () => {
    axios
      .get(`${base_url.api3}/listAllRoles`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableroles(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  // console.log(availableroles);

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };
  // console.log(availableoffice);

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // const getCustomerById = () => {
  //   let customer = particularUser[0];
  //   // console.log(customer)
  //   setstateCust({
  //     id: customer.id,
  //     firstName: customer.firstName,
  //     lastName: customer.lastName,
  //     gender: customer.gender,
  //     username: customer.username,
  //     email: customer.email,
  //     officeName: customer.officeName,
  //     office: customer.office,
  //     phone: customer.phone,
  //     address: customer.address,
  //     street: customer.street,
  //     country: customer.country,
  //     nationality: customer.nationality,
  //     passport: customer.passport,
  //     state: customer.state,
  //     city: customer.city,
  //     pincode: customer.pincode,
  //     // dob: customer.dob,
  //     dob: customer.dob,
  //     roleId: customer.roleId,
  //     roleName: customer.roleName,
  //     status: Number(customer.status),
  //     updatedOn: customer.updatedOn,
  //   });
  //   setcountry(customer.country);
  //   setregion(customer.state);
  // };

  // console.log(stateCust);

  const getUser = () => {
    axios
      .get(`${base_url.api2}/getUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: username2,
        },
      })
      .then((response) => {
        let userData = response.data.data;
        let officeData = response.data.userOfficeData;
        setselectedOffice(response.data.userOfficeData);
        setcountry(userData.country);
        setregion(userData.state);
        setstateCust({
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          gender: userData.gender,
          email: userData.email,
          phone: userData.phone,
          address: userData.address,
          street: userData.street,
          pincode: userData.pincode,
          city: userData.city,
          country: userData.country,
          state: userData.state,
          nationality: userData.nationality,
          passport: userData.passport,
          roleId: userData.roleId,
          photo: userData.photo,
          dob: userData.dob,
          status: userData.status,
          ownerUser: userData.ownerUser,
          addedOn: userData.addedOn,
          updatedOn: userData.updatedOn,
          lastLogin: userData.lastLogin,
          roleName: userData.roleName,
          id: userData.id,
        });
        setdate(userData.dob);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const putCustomer = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.nationality = country1;
    stateCust.passport = country1;
    stateCust.state = region;
    console.log("From Statecust is", stateCust);
    // axios
    //   .post(`${base_url.api2}/updateUserByEmail`, stateCust, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       Authorization: jwtToken,
    //     },
    //   })
    //   .then(
    //     (response) => {
    //       console.log(response.data.data.data);
    //       toast.success("Success");
    //       dispatch(
    //         updateAdmin({
    //           id: stateCust.username,
    //           data: response.data.data.data,
    //         })
    //       );
    //       history.push("/users");
    //     },
    //     (error) => {
    //       console.log(error);
    //       toast.error(JSON.stringify(error.response.data.message));
    //     }
    //   );
  };

  const [officeState, setOffice] = useState();
  const onSelect = (selectedList, selectedItem) => {
    const client_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        officeName: selectedList[i].officeName,
        officeId: Number(selectedList[i].officeId),
        id: 0,
        userId: Number(stateCust.id),
      };
      client_obj.push(data);
    }
    setselectedOffice(client_obj);
  };
  console.log(selectedOffice);

  const onRemove = (selectedList, removedItem) => {
    console.log(selectedList);
    console.log(removedItem);
  };

  const goback = () => {
    history.push("/users");
  };
  const dashboardvalue = useSelector(selectdashboard);

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Card className="commoncomponent_cardcss">
        {/* {<ReactBootStrap.Spinner animation="border" />} */}
        <CardBody>
          <CardSubtitle className="font-weight-bold"></CardSubtitle>
          {availableroles == null ? (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <form onSubmit={handleSubmit(putCustomer)}>
              <div className="form-inner-admin">
                <Row>
                  <Col md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.createBtn2}
                      onClick={goback}
                    >
                      &#x2630; List
                    </Button>
                  </Col>
                  <Col md={4}>
                    <center>
                      <CardText className={classes.headtext}>
                        <strong>Edit Users</strong>
                      </CardText>
                    </center>
                  </Col>
                </Row>
                <div className=" creatadmin">
                  <Row>
                    <Col md={4}>
                      <label>Select Branch:</label>
                      <Multiselect
                        options={availableoffice}
                        selectedValues={selectedOffice} // Preselected value to persist in dropdown
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="officeName"
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4}>
                      <label>First Name:</label>
                      <input
                        type="text"
                        value={stateCust.firstName}
                        name="firstName"
                        onChange={inputEvent}
                        className="form-control form-control-sm"
                        ref={register({ required: true })}
                      />
                      {errors.firstName && (
                        <p className="errormessage">FirstName is Required</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <label>Last Name:</label>
                      <input
                        type="text"
                        value={stateCust.lastName}
                        name="lastName"
                        onChange={inputEvent}
                        className="form-control form-control-sm"
                        ref={register({ required: true })}
                      />
                      {errors.lastName && (
                        <p className="errormessage">LastName is Required</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <label>Gender:</label>
                      <select
                        defaultValue={stateCust.gender}
                        className={classes.selectdrop}
                        name="gender"
                        value={stateCust.gender}
                        onChange={inputEvent}
                        // id={stateCust.gender}
                        ref={register({ required: true })}
                      >
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Others">Others</option>
                      </select>
                      {errors.gender && (
                        <p className="errormessage">Gender is Required</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4}>
                      <label>Email / Username:</label>
                      <input
                        type="email"
                        value={stateCust.email}
                        name="username"
                        onChange={(e) => {
                          const value = e.target.value;
                          stateCust.email = value;
                          inputEvent(e);
                        }}
                        className="form-control form-control-sm"
                        readOnly
                      />
                    </Col>
                    <Col md={4}>
                      <label>Contact No:</label>
                      <input
                        type="text"
                        value={stateCust.phone}
                        name="phone"
                        onChange={inputEvent}
                        className="form-control form-control-sm"
                        ref={register({ required: true })}
                      />
                      {errors.phone && (
                        <p className="errormessage">Phone is Required</p>
                      )}
                    </Col>
                    <Col md={4}>
                      <label>Address:</label>
                      <input
                        type="text"
                        value={stateCust.address}
                        name="address"
                        onChange={inputEvent}
                        className="form-control form-control-sm"
                        ref={register({ required: true })}
                      />
                      {errors.address && (
                        <p className="errormessage">Address is Required</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4}>
                      <label>Country:</label>
                      <CountryDropdown
                        defaultOptionLabel="- Select Country -"
                        className="form-control underline-input countrydropdown2"
                        value={country1}
                        onChange={(val) => selectCountry(val)}
                        required
                      />
                    </Col>
                    <Col md={4}>
                      <label>State:</label>
                      {country1 == "Nepal" ? (
                        <select
                          className={classes.selectdrop}
                          name="gender"
                          value={region}
                          style={{ backgroundColor: "#f5f5f9" }}
                          onChange={(e) => selectRegion(e.target.value)}
                        >
                          <option value="Province No. 1">Province No. 1</option>
                          <option value="Madhesh Province">
                            Madhesh Province
                          </option>
                          <option value="Bagmati Province">
                            Bagmati Province
                          </option>
                          <option value="Gandaki Province">
                            Gandaki Province
                          </option>
                          <option value="Lumbini Province">
                            Lumbini Province
                          </option>
                          <option value="Karnali Province">
                            Karnali Province
                          </option>
                          <option value="Sudurpashchim Province">
                            Sudurpashchim Province
                          </option>
                        </select>
                      ) : (
                        <RegionDropdown
                          defaultOptionLabel="- Select State -"
                          className="form-control underline-input countrydropdown2"
                          country={country1}
                          value={region}
                          style={{ backgroundColor: "#f5f5f9" }}
                          onChange={(val) => selectRegion(val)}
                          required
                        />
                      )}
                    </Col>
                    <Col md={2}>
                      <label>City:</label>
                      <input
                        type="text"
                        value={stateCust.city}
                        name="city"
                        onChange={inputEvent}
                        className="form-control form-control-sm"
                        ref={register({ required: true })}
                      />
                      {errors.city && (
                        <p className="errormessage">City is Required</p>
                      )}
                    </Col>
                    <Col md={2}>
                      <label>PostCode:</label>
                      <input
                        type="text"
                        value={stateCust.pincode}
                        name="pincode"
                        onChange={inputEvent}
                        className="form-control form-control-sm"
                        ref={register({ required: true })}
                      />
                      {errors.pinCode && (
                        <p className="errormessage">PostCode is Required</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4} style={{ position: "relative" }}>
                      <label>Date of Birth:</label>
                      {!selecteddate ? (
                        <p className="dateptag">Select Date</p>
                      ) : null}
                      <DatePicker
                        selected={new Date(selecteddate) || moment()}
                        onChange={(date) => setdate(date)}
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        dropdownMode="select"
                        placeholder="Select Date"
                      />
                    </Col>
                    <Col md={4}>
                      <label>Role:</label>
                      <select
                        defaultValue={stateCust.roleName}
                        className={classes.selectdrop}
                        id={stateCust.roleId}
                        name="roleName"
                        onChange={(e) => {
                          const index = e.target.selectedIndex;
                          const optionElement = e.target.childNodes[index];
                          const optionElementId =
                            optionElement.getAttribute("id");
                          const optionElementvalue =
                            optionElement.getAttribute("value");
                          setstateCust.roleId = optionElementId;
                          setstateCust.roleName = optionElementvalue;
                          inputEvent(e);
                        }}
                        required
                      >
                        {availableroles.map((cuntitem) => (
                          <option
                            key={cuntitem.roleId}
                            id={cuntitem.roleId}
                            value={cuntitem.roleName}
                          >
                            {cuntitem.roleName}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={4}>
                      <label>Status:</label>
                      <select
                        defaultValue={
                          stateCust.status == 1 ? "Active" : "InActive"
                        }
                        className={classes.selectdrop}
                        name="status"
                        value={stateCust.status}
                        onChange={inputEvent}
                        ref={register({ required: true })}
                      >
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </select>
                      {errors.status && (
                        <p className="errormessage">Status is Required</p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <Button
                color="primary"
                type="submit"
                style={{ marginTop: "20px", marginLeft: "20px" }}
              >
                Update
              </Button>
              {/* <button type="submit" className="btn btn-primary cust-btn btn-sm editprofilebtn">
                                Update
                            </button> */}
            </form>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default withRouter(EditBrandAmbasador);
