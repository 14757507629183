import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { checkreportpermission, check4pointpermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";

function BranchWiseGeneralReport(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageNo, setPageNo] = useState(0);
  const [sortState, setSortState] = useState("office_name");
  const [direction, setDirection] = useState("DESC");
  const [rows, setRows] = React.useState(50);
  const [totalUser, setTotalUser] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberofElements, setNumberOfElements] = useState();
  // const datepast = moment().subtract(3, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [backdropopen, setbackdropopen] = useState(true);

  const [branchWiseData, setBranchWiseData] = useState();
  const [branchWisePageNo, setBranchWisePageNo] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [searchData, setSearch] = useState();
  const [display1, setDisplay] = useState("inline");
  const [searchInput, setSearchInput] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  let headData;
  let office_id;

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [branchWiseData]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    }
    if (actionType === 'DOWNLOAD') {
      setbackdropopen(true);
      axios
        .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
          headers: headData,
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  }

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(1015, permission_obj, resource_obj)) {

      if (user.isw_user_role === "1" || user.isw_user_role === "6") {
        headData = {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(pageNo),
          size: Number(rows),
        }
      } else {
        let datenow1 = isoDateString.split(".")[0];
        const office_obj = [];
        for (let i = 0; i < user?.isw_available_Office?.length; i++) {
          const element = user?.isw_available_Office?.[i];
          console.log(element);
          office_obj.push(element);
        }
        let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
        office_id = new_id;
        setSelectedOfficeId(new_id);
        headData = {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(pageNo),
          size: Number(rows),
          query: new_id,
        }
      }

      //if(available_permission) {
      getBranchWiseSummery(pageNo, headData);
      getOffice();
      dispatch(setdashboardcheck(60));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        setAvailableOffice();
        handle403error(error);
      });
  };

  const handlePageLead = (event, value) => {
    setPageNo(value - 1);
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value - 1),
          size: Number(rows),
        },
      })
      .then((response) => {
        setBranchWiseData(response.data.data.content);
        setBranchWisePageNo(response.data.data.totalPages);
        setTotalUser(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        setbackdropopen(false);
      })
      .catch((error) => {
        setBranchWiseData();
        setBranchWisePageNo(0);
        setTotalUser(0);
        setCountNumber(0);
        setNumberOfElements(0);
        setbackdropopen(false);
        handle403error(error);
      });
  };


  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();

  const getBranchWiseSummery = (value1, headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
        headers: headData,
      })
      .then((response) => {
        let branchDataObj = response.data.data.content;
        headData.size = response.data.data.totalElements;
        if (response.data.data.totalElements == 0) {
          headData.size = Number(50);
          setpdflength(50);
        } else {
          // setpdflength(response.data.data.totalElements);
          setpdflength(50);
        }
        getPdfData(headData.size = Number(50));
        setPdfHeader(headData);
        // for (let i = 0; i < branchDataObj.length; i++) {
        //   for (let j = 0; j < availableoffice.length; j++) {
        //     if(branchDataObj[i].office_name == availableoffice[j].officeName) {
        //       console.log(branchDataObj[i].office_name);
        //       console.log(availableoffice[j].officeName);
        //       branchDataObj[i]['office_code'] == availableoffice[j].officeCode;
        //       branchDataObj[i].office_id == availableoffice[j].officeId;
        //     }
        //   }
        // }
        // console.log(branchDataObj);
        setBranchWiseData(response.data.data.content);
        setBranchWisePageNo(response.data.data.totalPages);
        setTotalUser(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        // setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setBranchWiseData();
        setBranchWisePageNo(0);
        setTotalUser(0);
        setCountNumber(0);
        setNumberOfElements(0);
        handle403error(error);
      });
  };



  const getPdfData = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(pageNo),
          size: Number(rows),
        },
      })
      .then((response) => {
        let branchDataObj = response.data.data.content;
        // for (let i = 0; i < branchDataObj.length; i++) {
        //   for (let j = 0; j < availableoffice.length; j++) {
        //     if(branchDataObj[i].office_name == availableoffice[j].officeName) {
        //       console.log(branchDataObj[i].office_name);
        //       console.log(availableoffice[j].officeName);
        //       branchDataObj[i]['office_code'] == availableoffice[j].officeCode;
        //       branchDataObj[i].office_id == availableoffice[j].officeId;
        //     }
        //   }
        // }
        // console.log(branchDataObj);
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setPdfRow();
        handle403error(error);
      });
  };

  const [userOfficeId, setUserOfficeId] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? null
      : user?.isw_available_Office[0].officeId
  );

  const [selectedOfficeId, setSelectedOfficeId] = useState(null);

  const searchBranchWiseSummery = (e) => {
    setbackdropopen(true);
    setDisplay("none");
    setSearch(true);
    let srchdata = e.target.value;
    console.log(srchdata);
    setSearchInput(srchdata);
    setPageNo(0);

    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    console.log(optionElementId);

    if (optionElementId === "all") {
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(new_id);
      setUserOfficeId(new_id);
      setSelectedOfficeId(new_id);

      axios
        .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageNo),
            size: Number(rows),
            query: new_id
          },
        })
        .then((response) => {
          let branchDataObj = response.data.data.content;
          setBranchWiseData(response.data.data.content);
          setBranchWisePageNo(response.data.data.content.length);
          setTotalUser(1);
          setCountNumber(response.data.data.number);
          setNumberOfElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          setBranchWiseData();
          setBranchWisePageNo(0);
          setTotalUser(0);
          setCountNumber(0);
          setNumberOfElements(0);
          handle403error(error);
        });
    } else {
      console.log(optionElementId);
      setUserOfficeId(optionElementId);
      axios
        .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageNo),
            size: Number(rows),
            query: optionElementId,
          },
        })
        .then((response) => {
          let branchDataObj = response.data.data.content;
          setBranchWiseData(response.data.data.content);
          setBranchWisePageNo(response.data.data.content.length);
          setTotalUser(1);
          setCountNumber(response.data.data.number);
          setNumberOfElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          setBranchWiseData();
          setBranchWisePageNo(0);
          setTotalUser(0);
          setCountNumber(0);
          setNumberOfElements(0);
          handle403error(error);
        });
    }
  };

  const exportPdfLead = () => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(pdflength),
        },
      })
      .then((response) => {
        let branchDataObj = response.data.data.content;
        // for (let i = 0; i < branchDataObj.length; i++) {
        //   for (let j = 0; j < availableoffice.length; j++) {
        //     if(branchDataObj[i].office_name == availableoffice[j].officeName) {
        //       console.log(branchDataObj[i].office_name);
        //       console.log(availableoffice[j].officeName);
        //       branchDataObj[i]['office_code'] == availableoffice[j].officeCode;
        //       branchDataObj[i].office_id == availableoffice[j].officeId;
        //     }
        //   }
        // }
        // console.log(branchDataObj);
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Branch Name",
          "Address",
          "Country",
          "Total Enquiry",
          "Processing",
          "Ongoing",
          "Active Student",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.office_name,
            "Street: " + element.street + "\n" + "City: " + element.city,
            "State: " + element.state + "\n" + "Country: " + element.country,
            element.total,
            element.processingCount,
            element.onGoingCount,
            element.processingCount + element.onGoingCount,
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("BranchWise_General_Reports.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        setPdfRow();
        handle403error(error);
      });
  };

  const sortLeadData = (e) => {
    let sortFilter = e.target.id.trim();
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getOfficeWiseEnquiryReport`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortFilter,
          direction: direction,
          page: Number(pageNo),
          size: Number(rows),
        },
      })
      .then((response) => {
        setBranchWiseData(response.data.data.content);
        setBranchWisePageNo(response.data.data.totalPages);
        setTotalUser(response.data.data.totalElements);
        setCountNumber(response.data.data.number);
        setNumberOfElements(response.data.data.numberOfElements);
        setbackdropopen(false);
      })
      .catch((error) => {
        setBranchWiseData();
        setBranchWisePageNo(0);
        setTotalUser(0);
        setCountNumber(0);
        setNumberOfElements(0);
        setbackdropopen(false);
        handle403error(error);
      });
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Branchwise Summery Report</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Reports</a>
          <span className="s_span ">-</span><a className="b-link">General</a>
          <span className="s_span ">-</span>
          <a
            href="#/branch-wise-general-summery-report"
            className="b-link active"
          >
            Branch wise summary report
          </a>
        </div>
        {/* <a onClick={goback} className="back-btn">Back</a> */}
      </div>
      {/* <CardBody> */}
      {/*   <CardSubtitle className="font-weight-bold"></CardSubtitle> */}
      {/* <div> */}
      {/* <Row> */}
      {/* <Col md={6}>
                <CardText className={classes.headtext}>
                  <strong>Branchwise Summery Report</strong>
                </CardText>
              </Col> */}
      {/* <Col md={6}>
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                  <Col md={3}>
                    {permissionData?.includes(615) ?
                      <button
                        className="btn listing_addbutton"
                        onClick={exportPdfLead}
                      >
                        Export PDF
                      </button>
                      : null}
                  </Col>
                  <Col md={3}>
                    {branchWiseData != null ?
                      <>
                        {permissionData?.includes(616) ?
                          <CSVLink
                            filename="BranchWise_General_Reports.csv"
                            data={branchWiseData}
                            className="btn listing_addbutton"
                          // target="_blank"
                          >
                            Export CSV
                          </CSVLink>
                          : null}
                      </>
                      : null}
                  </Col>
                </Row>
              </Col> */}
      {/* </Row> */}
      {/* <br /> */}
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          borderRight: "1.5rem solid #e9ebf2",
          borderLeft: "1.5rem solid #e9ebf2",
          borderTop: "1.5rem solid #e9ebf2",
          marginBottom: "0",
        }}
      >
        <Col md={4}>
          <label className="area_rowslable_my">Branch:</label>
          {user.isw_user_role === "1" || user.isw_user_role === "6" ? (
            <select
              className="form-control"
              onChange={searchBranchWiseSummery}
              name="branch"
              style={{ backgroundColor: "#f5f5f9" }}
            >
              <option value="" id="all">
                - Show All -
              </option>
              {availableoffice?.map((cuntitem) => (
                <option
                  key={cuntitem.officeId}
                  value={cuntitem.officeName}
                  id={cuntitem.officeId}
                >
                  {cuntitem.officeName}
                  {" - "}
                  {cuntitem.officeCode}
                </option>
              ))}
            </select>
          ) : (
            <>
              <select
                className="form-control"
                onChange={searchBranchWiseSummery}
                name="branch"
                style={{ backgroundColor: "#f5f5f9" }}
                required
              >
                <option value="" id="all">
                  - Show All -
                </option>
                {user.isw_available_Office?.map((cuntitem) => (
                  <option
                    key={cuntitem.officeId}
                    value={cuntitem.officeName}
                    id={cuntitem.officeId}
                  >
                    {cuntitem.officeName}
                    {" - "}
                    {cuntitem.officeCode}
                  </option>
                ))}
              </select>
            </>
          )}
        </Col>
        <Col md={8}>{null}</Col>
      </div>
      <div
        className="newlisting_headertabdesign"
        style={{
          padding: "1.5rem",
          border: "1.5rem solid #e9ebf2",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <Row className="s_pagi" marginBottom="40px" style={{ marginTop: "5px" }}>
          <Col md={6}>
            {branchWiseData?.length ? (
              <p className="s_para" style={{ marginTop: "5px" }}>
                {/* {countNumber * rows + 1} to{" "}
                {countNumber * rows + numberofElements} of {totalUser} records */}
              </p>
            ) : (
              <p className="s_para" style={{ marginTop: "5px" }}>No Record Found</p>
            )}
          </Col>
          <Col md={6}>
            <div style={{ display: "flex", justifyContent: "right" }}>
              {check1pointpermission(
                1016,
                permissionData,
                rolepermission
              ) ? (
                <button
                  className="btn listing_addbutton"
                  onClick={exportPdfLead}
                >
                  Export PDF
                </button>
              ) : null}
              <>
                {/* {pdfRow != null && pdfRow.length != 0 ? (
                  <>
                    {permissionData?.includes(166) ? (
                      <CSVLink
                        filename="BranchWise_General_Reports.csv"
                        data={pdfRow ? pdfRow : null}
                        className="btn listing_addbutton"
                        // target="_blank"
                      >
                        Export CSV
                      </CSVLink>
                    ) : null}
                  </>
                ) : null} */}
                {dataForDownload != null ? (
                  <>
                    {check1pointpermission(
                      1016,
                      permissionData,
                      rolepermission
                    ) ? (
                      <>
                        <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')}>Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="BranchWise_General_Reports.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            </div>
          </Col>
        </Row>
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          {/* <Table responsive striped onScroll={scrolltable}> */}
          <Table
            className="workload card-table table-borderless"
            responsive
            striped
            onScroll={scrolltable}
          >
            <thead className="s_sticky">
              <tr>
                {/* <th>Id</th> */}
                <th style={{ minWidth: "50px", textAlign: "center" }}>SN </th>
                <th style={{ minWidth: "130px" }}>
                  NAME
                  <img
                    src={sort}
                    className="sorting"
                    id="office_name"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "140px" }}>
                  ADDRESS
                  <img
                    src={sort}
                    className="sorting"
                    id="office_name"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "130px" }}>
                  COUNTRY
                  <img
                    src={sort}
                    className="sorting"
                    id="office_name"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>
                  TOTAL ENQUIRY
                  <img
                    src={sort}
                    className="sorting"
                    id="total"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>
                  PROCESSING
                  <img
                    src={sort}
                    className="sorting"
                    id="processingCount"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>
                  ONGOING
                  <img
                    src={sort}
                    className="sorting"
                    id="processingCount"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "100px" }}>
                  ACTIVE STUDENT
                  <img
                    src={sort}
                    className="sorting"
                    id="disContinuedCount"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                {/* <th style={{ minWidth: "150px" }}>
                  COMPLETE 
                  <img
                    src={sort}
                    className="sorting"
                    id="completedCount"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th>
                <th style={{ minWidth: "150px" }}>
                  DISCONTINUE 
                  <img
                    src={sort}
                    className="sorting"
                    id="disContinuedCount"
                    onClick={sortLeadData}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th> */}
              </tr>
            </thead>
            <tbody>
              {branchWiseData != null
                ? branchWiseData &&
                branchWiseData.map((p, index) => (
                  <tr key={p.id}>
                    {/* <td>{p.id}</td> */}
                    <td style={{ width: "50px", textAlign: "center" }}>
                      {countNumber * rows + 1 + index}
                    </td>
                    <td><div style={{ width: "130px", wordWrap: "break-word", display: "inline-block" }}>{p.office_name}</div></td>
                    <td>
                      <div style={{ width: "140px", wordWrap: "break-word", display: "inline-block" }}>{p.street}</div>
                      <br />
                      <div style={{ width: "140px", wordWrap: "break-word", display: "inline-block" }}>{p.city}</div></td>
                    <td>
                      <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>
                        {p.state}
                      </div>
                      <br />
                      <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }}>
                        {p.country}</div></td>
                    <td>{p.total}</td>
                    <td>{p.processingCount}</td>
                    <td>{p.onGoingCount}</td>
                    <td>{p.onGoingCount + p.processingCount}</td>
                    {/* <td>{p.completedCount}</td>
                      <td>{p.disContinuedCount}</td> */}
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
          <Row>
            <Col md={6}>
              {branchWiseData?.length ? (
                <p className="s_para">
                  {/* {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberofElements} of {totalUser} records */}
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {/* <Pagination
                  count={branchWisePageNo}
                  onChange={handlePageLead}
                  shape="rounded"
                /> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* </div> */}
      {/* </CardBody> */}

      {/* </Card> */}
    </div>
  );
}

export default withRouter(BranchWiseGeneralReport);