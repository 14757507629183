import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck } from "../features/userSlice";
import "../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Modal from "react-modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SelectCurrency from "react-select-currency";
import { selectdashboard } from "../features/dashboardSlice";
import { useStyles } from "../../Utilities/CSSUtilities";
import { logout } from "../features/userSlice";
import callsaveauditapi from "../../services/auditservice";
import PasswordStrengthBar from "react-password-strength-bar";
import { getagentpermission, check1pointpermission } from "../Permission/permission";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "500px",
    marginRight: "-50%",
    transform: "translate(-36%, -50%)",
  },
};

const EditAgent = () => {
  const user = useSelector(selectUser);
  const [openapplicationform, setopenapplicationform] = useState(false);
  const [opentransactionalform, setopentransactionalform] = useState(false);
  const dashboardvalue = useSelector(selectdashboard);
  const dispatch = useDispatch();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  let params = useParams();
  const history = useHistory();
  const [backdrop, setbackdrop] = useState(false);
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const [currency1, setcurrency] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const jwtToken = "Bearer " + user.isw_user_token;
  const classes = useStyles();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [stateCust, setstateCust] = useState();
  const [applicationformlist, setapplicationformlist] = useState();
  const [transactionalarrangementlist, settransactionalarrangementlist] =
    useState();
  const [agentTypecheck, setagentTypecheck] = useState(true);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const [deleteAgreementId, setAgreementDeleteId] = useState();
  const [deleteAgreementFileName, setAgreementDeleteFileName] = useState();
  const [modalAgreementDeleteOpen, setAgreementDeleteOpen] =
    React.useState(false);
  const [transactionalformdeleteindex, settransactionalformdeleteindex] =
    useState([]);

  const [checkboxstate, setState] = React.useState({
    subAgent: false,
    superAgent: false,
    referal: false,
  });

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(720, permission_obj, resource_obj)) {

      // if (available_permission){
      setbackdrop(true);
      getAgentById();
      dispatch(setdashboardcheck(24));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      // console.log('No access to the page');
      history.push("/errorPage");
    }
  };

  const UpdatePassword = ({ p, setbackdrop }) => {
    const [passwordmodal, setpasswordmodal] = useState(false);
    const handlepasswordmodal = () => {
      setpasswordmodal(!passwordmodal);
      setchkPassState();
    };
    const [inputtype, setinputtype] = useState("password");
    const [chkPassState, setchkPassState] = useState();
    const isw_user_name = localStorage.getItem("isw_user_name");
    const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");

    const changePassword = (e) => {
      setbackdrop(true);
      let username = e.target[0].value;
      let password;
      password = chkPassState.trim();
      let data = {
        username,
        password,
      };

      axios
        .post(`${base_url.api3}/updateAgentPasswordByEmail`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Password Changed Successfully");
          let log =
            isw_user_name + " Updated passowrd of agent " + username + ".";
          callsaveauditapi(log);
          setbackdrop(false);
          handlepasswordmodal();
        })
        .catch((err) => {
          let log =
            isw_user_name +
            " failed to Updated passowrd of agent " +
            username +
            ".";
          callsaveauditapi(log);
          setbackdrop(false);
          handlepasswordmodal();
          handle403error(err);
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something Went Wrong!!");
          }
        });
    };
    return (
      <div>
        <p>
          <button
            className="btn listing_addbutton"
            onClick={handlepasswordmodal}
          >
            Update Password
          </button>
          {/* <Link onClick={handlepasswordmodal}>Update Password</Link> */}
        </p>
        <Modal
          isOpen={passwordmodal}
          onRequestClose={handlepasswordmodal}
          style={customStyles}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              changePassword(e);
            }}
          >
            <div>
              <h3 style={{ color: "black" }}>Update Password</h3>
              <div className="form-group createadmin">
                <Row>
                  <Col>
                    <label>User Name:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      value={params.agent_email}
                      readOnly
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col>
                    <label>Password:</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={inputtype}
                        value={chkPassState}
                        onChange={(e) => setchkPassState(e.target.value)}
                        className="form-control underline-input"
                        required
                      />
                      {inputtype === "password" ? (
                        <i
                          className="fa fa-eye-slash fonticonpositioning"
                          onClick={() => setinputtype("text")}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye fonticonpositioning"
                          onClick={() => setinputtype("password")}
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    <PasswordStrengthBar password={chkPassState} />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row className="justify-content-end">
                  <button
                    className="btn btn-secondary"
                    onClick={handlepasswordmodal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn listing_addbutton ml-1">
                    Update
                  </button>
                </Row>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  };

  const getAgentById = () => {
    axios
      .get(`${base_url.api3}/getAgentByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.agent_email,
        },
      })
      .then((res) => {
        let agent = res.data.data;
        setcurrency(agent.currency);
        // setapplicationformlist(agent.agentApplicationForm);
        settransactionalarrangementlist(agent.transactionalAgreement);
        setstateCust(agent);
        setbackdrop(false);
        setcountry(agent.country);
        setregion(agent.state);
        let agentsubAgent = agent.subAgent;
        let agentsuperAgent = agent.superAgent;
        let agentreferal = agent.referal;
        if (agentsubAgent === 1) {
          setState((prev) => {
            return {
              ...prev,
              subAgent: true,
            };
          });
        }
        if (agentsuperAgent === 1) {
          setState((prev) => {
            return {
              ...prev,
              superAgent: true,
            };
          });
        }
        if (agentreferal === 1) {
          setState((prev) => {
            return {
              ...prev,
              referal: true,
            };
          });
        }
      })
      .catch((err) => {
        setbackdrop(false);
        handle403error(err);
      });
  };
  const checkboxchange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };
  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const selectcurrency = (e) => {
    setcurrency(e.target.value);
  };
  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadLogo = async (data) => {
    console.log(data.target.files);
    const file = data.target.files[0];
    const base64 = await convertBase64(file);
    stateCust.photo = base64;
  };
  const goback = () => {
    history.push("/agent");
  };

  const closeModal = () => {
    setAgreementDeleteOpen(false);
  };

  const deleteAgentAgreementForm = (p, index) => {
    console.log(deleteAgreementId);
    let delete_path = deleteAgreementFileName.split("amazonaws.com/")[1];
    setbackdrop(true);
    axios
      .delete(`${base_url.api3}/deleteAgentAgreement`, {
        headers: {
          Authorization: jwtToken,
          id: deleteAgreementId,
          fileName: delete_path,
        },
      })
      .then(
        (response) => {
          var lines = [...transactionalarrangementlist];
          lines.splice(index, 1);
          settransactionalformdeleteindex((prev) => [...prev, p.id]);
          settransactionalarrangementlist(lines);
          console.log(response);
          getAgentById();
          setAgreementDeleteOpen(false);
          setbackdrop(false);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const updateAgent = (data2) => {
    setbackdrop(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.updatedOn = datenow;
    stateCust.country = country1;
    stateCust.currency = currency1;
    stateCust.state = region;
    stateCust.createdBy = stateCust.createdBy;
    stateCust.updatedBy = isw_user_name + " (" + isw_user_email + ")";
    delete stateCust["agentApplicationForm"];
    delete stateCust["transactionalAgreement"];
    let formData = new FormData();
    // let formData2 = new FormData();
    // let multiplefiles = data2.appForm;
    let fileToUpload = data2.transactionalArrangement;
    // if (multiplefiles.length) {
    //   for (let i = 0; i < multiplefiles.length; i++) {
    //     formData2.append(
    //       "agentApplicationForms",
    //       multiplefiles[i],
    //       multiplefiles[i].name
    //     );
    //   }
    // }
    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append(
          "agentTransactionalArrangement",
          fileToUpload[i],
          fileToUpload[i].name
        );
      }
    }
    let institutionTypearray = [];
    let arr = Object.keys(checkboxstate);
    for (let i = 0; i < arr.length; i++) {
      let key = arr[i];
      if (checkboxstate[key]) {
        institutionTypearray.push(key);
      }
    }
    stateCust.subAgent = 0;
    stateCust.superAgent = 0;
    stateCust.referal = 0;
    for (let i = 0; i < institutionTypearray.length; i++) {
      if (institutionTypearray[i] === "subAgent") {
        stateCust.subAgent = 1;
      } else if (institutionTypearray[i] === "superAgent") {
        stateCust.superAgent = 1;
      } else if (institutionTypearray[i] === "referal") {
        stateCust.referal = 1;
      }
    }
    if (stateCust.subAgent === 0 && stateCust.superAgent === 0 && stateCust.referal) {
      setagentTypecheck(false);
      setbackdrop(false);
    } else {
      console.log(stateCust);
      axios
        .put(`${base_url.api3}/updateAgentByUsername`, stateCust, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            console.log(response);
            toast.success("Success");
            if (fileToUpload.length) {
              axios
                .post(
                  `${base_url.api3}/uploadAgentTransactionalAgreements`,
                  formData,
                  {
                    headers: {
                      Authorization: jwtToken,
                      agentId: response.data.data.agentId,
                    },
                  }
                )
                .then((res) => {
                  setbackdrop(false);
                  history.push("/agent");
                  toast.success("Sucess");
                })
                .catch((err) => {
                  history.push("/agent");
                  toast.error("Error uploading Transactional Arrangement");
                  setbackdrop(false);
                  console.log(err);
                });
            }
            let logs =
              isw_user_name +
              " Updated Agent " +
              stateCust.name +
              "(" +
              stateCust.email +
              ").";
            callsaveauditapi(logs);
            if (!fileToUpload.length) {
              setbackdrop(false);
              history.push("/agent");
            }
          },
          (error) => {
            handle403error(error);
            let logs =
              isw_user_name +
              " failed to Updated Agent " +
              stateCust.firstName +
              " " +
              stateCust.lastName +
              "(" +
              stateCust.email +
              ").";
            callsaveauditapi(logs);
            setbackdrop(false);
          }
        );
    }
  };

  const downloadAll = (agentId, agentAgreementPath) => {
    setbackdrop(true);
    let file_name =
      agentAgreementPath.split("amazonaws.com/")[
      agentAgreementPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        agentId: agentId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api3}/downloadAgentTransactionalAgreementByInstitutionIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        console.log(response);
        const downloadFileType = localStorage.getItem(
          "agent_attachment_file_content_type"
        );
        console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((error) => {
        setbackdrop(false);
        console.log(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "agent_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.8rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Manage Clients</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Partner</a><span className="s_span ">-</span>
          <a className="b-link active">Provider Edit</a>
        </div>
        <a onClick={goback} className="sent-button">
          LIST
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <Row>
          <Col md={6}>
            <CardText className={classes.headtext}>
              <strong>Agent</strong>
            </CardText>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="btn listing_addbutton" onClick={goback}>
              &#x2630; List
            </button>
          </Col>
        </Row> */}
        <Row style={{ paddingLeft: "10px" }}>
          {check1pointpermission(
            722,
            permissionData,
            rolepermission
          ) ? (
            <UpdatePassword p={stateCust} setbackdrop={setbackdrop} />
          ) : null}
          &nbsp;&nbsp;&nbsp;
        </Row>
        <form id="myForm" onSubmit={handleSubmit(updateAgent)}>
          <div className="form-inner-admin form_inner_admin">
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Agent Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>
                    Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    className="form-control underline-input"
                    onChange={inputEvent}
                    name="name"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust?.name}
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.name && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </Col>

                <Col md={3}>
                  <label>ABN:</label>
                  <input
                    className="form-control underline-input"
                    onChange={inputEvent}
                    name="abn"
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust?.abn}
                    ref={register}
                    type="text"
                  />
                </Col>
                <Col md={3}>
                  <label>ACN:</label>
                  <input
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.acn}
                    name="acn"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    type="text"
                  />
                </Col>
                <Col md={3}>
                  <label>CAN:</label>
                  <input
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.can}
                    name="can"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    type="text"
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>PAN:</label>
                  <input
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.pan}
                    name="pan"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    type="text"
                  />
                </Col>
                <Col md={3}>
                  <label>VAT:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.vatNumber}
                    name="vatNumber"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Currency:</label>
                  {!currency1 ? (
                    <Backdrop className={classes.backdrop} open>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <SelectCurrency
                      value={currency1}
                      onChange={selectcurrency}
                      style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label>
                    Email<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={stateCust?.email}
                    name="email"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    readOnly
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>Commission Percentage:</label>
                  <input
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.commissionPercentage}
                    name="commissionPercentage"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onWheel={(event) => event.currentTarget.blur()}
                    ref={register}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <label>Referal Percentage:</label>
                  <input
                    className="form-control underline-input"
                    name="referalPercentage"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust?.referalPercentage}
                    onWheel={(event) => event.currentTarget.blur()}
                    ref={register}
                    type="number"
                  />
                </Col>
                <Col md={3}>
                  <label>Remarks:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.remark}
                    name="remark"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Agent Type<span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />

                  <Row style={{ gap: "10px" }}>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.subAgent}
                              onChange={checkboxchange}
                              color="primary"
                              name="subAgent"
                            />
                          }
                          label="Sub Agent"
                        /> */}
                    <div class="container">
                      <input
                        checked={checkboxstate.subAgent}
                        onChange={checkboxchange}
                        type="checkbox"
                        id="flexCheckDefault"
                        name="subAgent"
                      />
                      <label class="checkmark" for="flexCheckDefault" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefault"
                        style={{ marginLeft: "-10px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Sub Agent</label>
                    </div>
                    <br />
                  </Row>
                  <Row style={{ marginTop: "-15px", gap: "10px" }}>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.superAgent}
                              onChange={checkboxchange}
                              color="primary"
                              name="superAgent"
                            />
                          }
                          label="Super Agent"
                        /> */}
                    <div class="containerone" style={{ marginTop: "-30px" }}>
                      <input
                        checked={checkboxstate.superAgent}
                        onChange={checkboxchange}
                        type="checkbox"
                        id="flexCheckDefaultone"
                        name="superAgent"
                      />
                      <label class="checkmarkone" for="flexCheckDefaultone" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefaultone"
                        style={{ marginLeft: "-10px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Super Agent
                      </label>
                    </div>
                    <br />
                  </Row>
                  <Row style={{ marginTop: "10px", gap: "10px" }}>
                    <div class="containerone" style={{ marginTop: "-30px" }}>
                      <input
                        checked={checkboxstate.referal}
                        onChange={checkboxchange}
                        type="checkbox"
                        id="flexCheckDefaulttwo"
                        name="referal"
                      />
                      <label class="checkmarkone" for="flexCheckDefaulttwo" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefaulttwo"
                        style={{ marginLeft: "-10px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Referal
                      </label>
                    </div>
                    <br />
                  </Row>
                  {!agentTypecheck && (
                    <p className="errormessage">AgentType is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>Address:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.address}
                    name="address"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>

                <Col md={3}>
                  <label>Country:</label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="form-control underline-input countrydropdown2"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                  />
                </Col>
                <Col md={3}>
                  <label>State:</label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      defaultOptionLabel="- Select State -"
                      className="form-control underline-input countrydropdown2"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label>City:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.city}
                    style={{ backgroundColor: "#f5f5f9" }}
                    name="city"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>PostCode:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.zipCode}
                    name="zipCode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Website:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    style={{ backgroundColor: "#f5f5f9" }}
                    value={stateCust?.website}
                    name="website"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Latitude:</label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.lattitude}
                    name="lattitude"
                    style={{ backgroundColor: "#f5f5f9" }}
                    step="any"
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Longitude:</label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.longitude}
                    name="longitude"
                    step="any"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={4}>
                  <label>Logo:</label>
                  <img src={stateCust?.photo} alt="" height="50px" />

                  <input
                    type="file"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(data) => {
                      uploadLogo(data);
                    }}
                    accept=".jpg, .jpeg, .bmp, .gif, .png"
                  />
                </Col>
                <Col md={4}>
                  <label>Agreements:</label>
                  {check1pointpermission(
                    725,
                    permissionData,
                    rolepermission
                  ) ? (
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => setopentransactionalform(true)}
                    >
                      View Files
                    </span>
                  ) : null}
                  <Modal
                    isOpen={opentransactionalform}
                    onRequestClose={() => setopentransactionalform(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div>
                      <h5>Transactional Arrangement</h5>
                      <hr />
                      <TableContainer
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#f5f5f9",
                        }}
                        component={Paper}
                      >
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead
                            style={{ backgroundColor: "cornflowerblue" }}
                          >
                            <TableRow>
                              <TableCell>SN</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">View</TableCell>
                              <TableCell align="right">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          {transactionalarrangementlist?.length != null ? (
                            <TableBody>
                              {transactionalarrangementlist?.map((p, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {p.agentAgreementname}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    <a
                                      onClick={() => {
                                        downloadAll(
                                          stateCust?.agentId,
                                          p.agentAgreementPath
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      View
                                    </a>
                                  </TableCell>
                                  {check1pointpermission(
                                    726,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                    <TableCell
                                      align="right"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setAgreementDeleteId(p.id);
                                        setAgreementDeleteFileName(
                                          p.agentAgreementPath
                                        );
                                        setAgreementDeleteOpen(true);
                                      }}
                                    >
                                      Delete
                                    </TableCell>
                                  ) : null}
                                  <Modal
                                    isOpen={modalAgreementDeleteOpen}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h4 style={{ marginTop: "10px" }}>
                                      Are you sure you want to Delete the
                                      Agreement ?
                                    </h4>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "20px",
                                        justifyContent: "flex-end",
                                        gap: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <button
                                        className="btn listing_addbutton ml-1"
                                        onClick={() => {
                                          deleteAgentAgreementForm(p);
                                        }}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        className="btn btn-secondary cancel_margin"
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Modal>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            "No Files Available"
                          )}
                        </Table>
                      </TableContainer>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{
                          marginRight: "20px",
                          marginTop: "20px",
                          float: "right",
                          backgroundColor: "#f5f5f9",
                        }}
                        onClick={() => setopentransactionalform(false)}
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                  <input
                    type="file"
                    multiple
                    className="form-control underline-input"
                    name="transactionalArrangement"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={4}>
                  <label>University:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.agentUniversityName}
                    name="agentUniversityName"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={12}>
                  <label>Overview:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.overview}
                    name="overview"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={3}>
                  <label>Contact Person:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.contactPerson}
                    name="contactPerson"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Designation:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.designation}
                    name="designation"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Phone:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.phone}
                    name="phone"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Mobile:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    onChange={inputEvent}
                    value={stateCust?.mobile}
                    name="mobile"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                  {errors.mobile && (
                    <p className="errormessage">Mobile is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end">
                <button type="submit" className="sent-button">
                  Update
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div >
      {/* </Card> */}
    </div >
  );
};

export default EditAgent;
