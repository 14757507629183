import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardSubtitle, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import SelectCurrency from "react-select-currency";
import "../css/LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../css/Responsive.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "../../Utilities/CSSUtilities";
import PasswordStrengthBar from "react-password-strength-bar";
import { check4pointpermission, check1pointpermission } from "../Permission/permission";

const CreateAgent = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [backdrop, setbackdrop] = useState(false);
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const classes = useStyles();
  const [country1, setcountry] = useState();
  const [currency1, setcurrency] = useState("");
  const [region, setregion] = useState();
  const { register, handleSubmit, errors } = useForm();
  const [agentTypecheck, setagentTypecheck] = useState(true);
  const [inputtype, setinputtype] = useState("password");
  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();
  const [baseimage, setbaseimage] = useState("");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [checkboxstate, setState] = React.useState({
    subAgent: false,
    superAgent: false,
    referal: false,
  });
  const checkboxchange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(719, permission_obj, resource_obj)) {

      dispatch(setdashboardcheck(24));
    } else {
      history.push("/errorPage");
    }
  };
  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadLogo = async (data) => {
    console.log(data.target.files);
    const file = data.target.files[0];
    const base64 = await convertBase64(file);
    setbaseimage(base64);
  };
  const selectcurrency = (e) => {
    setcurrency(e.target.value);
  };
  const createAgent = (data2) => {
    setbackdrop(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let abn = data2.abn;
    let acn = data2.acn;
    let addedOn = datenow;
    let address = data2.street;
    let agentId = 0;
    let agentUniversityName = data2.university;
    let can = data2.can;
    let city = data2.city;
    let country = country1 || null;
    let currency = currency1 || "AUD";
    let commissionPercentage = data2.commissionPercentage;
    let contactPerson = data2.contactPerson;
    let designation = data2.designation;
    let email = data2.email;
    let username = data2.email;
    let remark = data2.remarks;
    let applicationFormLink = data2.appFormLink;
    let lattitude = data2.latitude;
    let photo = baseimage;
    let longitude = data2.longitude;
    let mobile = data2.mobile;
    let name = data2.name;
    let overview = data2.overview;
    let pan = data2.pan;
    let phone = data2.phoneNumber;
    let vatNumber = data2.vatNumber;
    let referalPercentage = data2.referalPercentage;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    let updatedBy = isw_user_name + " (" + isw_user_email + ")";
    let state = region || null;
    let status = 1;
    let password;
    if (chkPass == false) {
      password = chkPassState.trim();
    }
    let formData = new FormData();
    let fileToUpload = data2.transactionalArrangement;
    // if (multiplefiles.length) {
    //   for (let i = 0; i < multiplefiles.length; i++) {
    //     formData2.append(
    //       "agentApplicationForms",
    //       multiplefiles[i],
    //       multiplefiles[i].name
    //     );
    //   }
    // }
    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append(
          "agentTransactionalArrangement",
          fileToUpload[i],
          fileToUpload[i].name
        );
      }
    }
    let updatedOn = datenow;
    let website = data2.website;
    let zipCode = data2.zipCode;
    let institutionTypearray = [];
    let arr = Object.keys(checkboxstate);
    for (let i = 0; i < arr.length; i++) {
      let key = arr[i];
      if (checkboxstate[key]) {
        institutionTypearray.push(key);
      }
    }
    let subAgent = 0;
    let superAgent = 0;
    let referal = 0;
    for (let i = 0; i < institutionTypearray.length; i++) {
      if (institutionTypearray[i] === "subAgent") {
        subAgent = 1;
      } else if (institutionTypearray[i] === "superAgent") {
        superAgent = 1;
      } else if (institutionTypearray[i] === "referal") {
        referal = 1;
      }
    }
    let data = {
      abn,
      acn,
      addedOn,
      address,
      agentId,
      agentUniversityName,
      applicationFormLink,
      can,
      city,
      country,
      currency,
      commissionPercentage,
      contactPerson,
      designation,
      email,
      emailVerificationStatus: 0,
      lattitude,
      photo,
      password,
      longitude,
      mobile,
      name,
      overview,
      pan,
      phone,
      remark,
      referalPercentage,
      state,
      status,
      subAgent,
      superAgent,
      referal,
      username,
      updatedOn,
      vatNumber,
      website,
      zipCode,
      createdBy,
      updatedBy,
    };
    if (subAgent === 0 && superAgent === 0 && referal === 0) {
      setagentTypecheck(false);
      setbackdrop(false);
    } else {
      console.log(data);
      postCustomer(data, formData, fileToUpload);
      setagentTypecheck(true);
    }
  };
  const postCustomer = (
    data,
    formData,
    // formData2,
    // multiplefiles,
    fileToUpload
  ) => {
    axios
      .post(`${base_url.api3}/addNewAgent`, data)
      .then((response) => {
        toast.success("Success");
        if (fileToUpload.length) {
          axios
            .post(
              `${base_url.api3}/uploadAgentTransactionalAgreements`,
              formData,
              {
                headers: {
                  Authorization: jwtToken,
                  agentId: response.data.data.agentId,
                },
              }
            )
            .then((res) => {
              setbackdrop(false);
              history.push("/agent");
              toast.success("Sucess");
            })
            .catch((err) => {
              history.push("/agent");
              toast.error("Error uploading Transactional Arrangement");
              setbackdrop(false);
              console.log(err);
            });
        }
        // if (multiplefiles.length) {
        //   axios
        //     .post(`${base_url.api3}/uploadAgentApplicationForm`, formData2, {
        //       headers: {
        //         Authorization: jwtToken,
        //         agentId: response.data.data.agentId,
        //       },
        //     })
        //     .then((res) => {
        //       setbackdrop(false);
        //       history.push("/agent");
        //       toast.success("Sucess");
        //     })
        //     .catch((err) => {
        //       history.push("/agent");
        //       toast.error("Error uploading ApplicationForm");
        //       setbackdrop(false);
        //       console.log(err);
        //     });
        // }
        if (!fileToUpload.length) {
          setbackdrop(false);
          history.push("/agent");
        }
      })
      .catch((err) => {
        setbackdrop(false);
        if (err) {
          if (err.response) {
            if (Number(err?.response?.status) === 403) {
              setTimeout(() => {
                dispatch(logout());
                localStorage.clear();
                localStorage.setItem("from_403_status", "call_toast");
                history.push("/");
                toast.warn("Session Expired");
              }, 1000);
            } else {
              toast.error(JSON.stringify(err.response?.data?.message));
            }
          }
        }
      });
  };
  const goback = () => {
    history.push("/agent");
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <CardSubtitle className="font-weight-bold"></CardSubtitle>
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.8rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Agent Add</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Partner</a><span className="s_span ">-</span>
          <a href="#/create-agent" className="b-link active">
            Agent Add
          </a>
        </div>
        <a onClick={goback} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        {/* <Row>
          <Col md={6}>
            <CardText className={classes.headtext}>
              <strong>Agent</strong>
            </CardText>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="btn listing_addbutton" onClick={goback}>
              &#x2630; List
            </button>
          </Col>
        </Row> */}
        <form id="myForm" onSubmit={handleSubmit(createAgent)}>
          <div className="form-inner-admin form_inner_admin">
            <div style={{ display: "flex", marginTop: "10px" }}>
              {/* <p className="provider_containertitle">Agent Details</p> */}
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>
                    Name<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    className="form-control underline-input"
                    name="name"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                    type="text"
                  />
                  {errors.name && (
                    <p className="errormessage">Name is Required</p>
                  )}
                </Col>

                <Col md={3}>
                  <label>ABN:</label>
                  <input
                    className="form-control underline-input"
                    name="abn"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                    type="text"
                  />
                </Col>
                <Col md={3}>
                  <label>ACN:</label>
                  <input
                    className="form-control underline-input"
                    name="acn"
                    ref={register}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>CAN:</label>
                  <input
                    className="form-control underline-input"
                    name="can"
                    ref={register}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>PAN:</label>
                  <input
                    className="form-control underline-input"
                    name="pan"
                    ref={register}
                    type="text"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>VAT:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="vatNumber"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Currency:</label>
                  <SelectCurrency
                    value={currency1}
                    onChange={selectcurrency}
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Email<span style={{ color: "red" }}>*</span>:
                  </label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="email"
                    autoComplete="off"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: true })}
                  />
                  {errors.email && (
                    <p className="errormessage">Email is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>Commission Percentage:</label>
                  <input
                    className="form-control underline-input"
                    name="commissionPercentage"
                    onWheel={(event) => event.currentTarget.blur()}
                    ref={register}
                    type="number"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Referal Percentage:</label>
                  <input
                    className="form-control underline-input"
                    name="referalPercentage"
                    onWheel={(event) => event.currentTarget.blur()}
                    ref={register}
                    type="number"
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>
                    Password<span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />
                  <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      className="form-control underline-input"
                      value={chkPassState}
                      autoComplete="off"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        setchkPassState(e.target.value);
                        setchkPass(false);
                      }}
                      required
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} />
                </Col>
                <Col md={3}>
                  <label>
                    Agent Type<span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />
                  <Row style={{ gap: "10px" }}>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.subAgent}
                              onChange={checkboxchange}
                              color="#f5f5f9"
                              name="subAgent"
                            />
                          }
                          label="Sub Agent"
                        /> */}
                    <div class="container">
                      <input
                        checked={checkboxstate.subAgent}
                        onChange={checkboxchange}
                        type="checkbox"
                        id="flexCheckDefault"
                        name="subAgent"
                      />
                      <label class="checkmark" for="flexCheckDefault" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefault"
                        style={{ marginLeft: "-10px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Sub Agent</label>
                    </div>
                    <br />
                  </Row>
                  <Row style={{ marginTop: "-15px", gap: "10px" }}>
                    {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxstate.superAgent}
                              onChange={checkboxchange}
                              color="#f5f5f9"
                              name="superAgent"
                            />
                          }
                          label="Super Agent"
                        /> */}
                    <div class="containerone" style={{ marginTop: "-30px" }}>
                      <input
                        checked={checkboxstate.superAgent}
                        onChange={checkboxchange}
                        type="checkbox"
                        id="flexCheckDefaultone"
                        name="superAgent"
                      />
                      <label class="checkmarkone" for="flexCheckDefaultone" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefaultone"
                        style={{ marginLeft: "-10px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Super Agent
                      </label>
                    </div>
                    <br />
                  </Row>
                  <Row style={{ marginTop: "10px", gap: "10px" }}>
                    <div class="containerone" style={{ marginTop: "-30px" }}>
                      <input
                        checked={checkboxstate.referal}
                        onChange={checkboxchange}
                        type="checkbox"
                        id="flexCheckDefaulttwo"
                        name="referal"
                      />
                      <label class="checkmarkone" for="flexCheckDefaulttwo" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefaulttwo"
                        style={{ marginLeft: "-10px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Referal
                      </label>
                    </div>
                    <br />
                  </Row>
                  {!agentTypecheck && (
                    <p className="errormessage">AgentType is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Address</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <br />
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>Address:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="street"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>

                <Col md={3}>
                  <label>Country:</label>
                  <CountryDropdown
                    defaultOptionLabel="- Select Country -"
                    className="countrydropdown2 form-control underline-input"
                    value={country1}
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(val) => selectCountry(val)}
                  />
                </Col>
                <Col md={3}>
                  <label>State:</label>
                  {country1 == "Nepal" ? (
                    <select
                      className={classes.selectdrop}
                      name="gender"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => selectRegion(e.target.value)}
                    >
                      <option value="">- Select Province -</option>
                      <option value="Province No. 1">Province No. 1</option>
                      <option value="Madhesh Province">Madhesh Province</option>
                      <option value="Bagmati Province">Bagmati Province</option>
                      <option value="Gandaki Province">Gandaki Province</option>
                      <option value="Lumbini Province">Lumbini Province</option>
                      <option value="Karnali Province">Karnali Province</option>
                      <option value="Sudurpashchim Province">
                        Sudurpashchim Province
                      </option>
                    </select>
                  ) : (
                    <RegionDropdown
                      defaultOptionLabel="- Select State -"
                      className="countrydropdown2 form-control underline-input"
                      country={country1}
                      value={region}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectRegion(val)}
                    />
                  )}
                </Col>
                <Col md={3}>
                  <label>City:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="city"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                <Col md={3}>
                  <label>PostCode:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="zipCode"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Website:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="website"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Latitude:</label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    name="latitude"
                    step="any"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Longitude:</label>
                  <input
                    type="number"
                    className="form-control underline-input"
                    name="longitude"
                    step="any"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin">
              <Row>
                {/* <Col md={3}>
                      <label>Student App Form Link:</label>
                      <input
                        type="text"
                        className="form-control underline-input"
                        name="appFormLink"
                        ref={register}
                      />
                    </Col>
                    <Col md={3}>
                      <label>Student App Form :</label>
                      <input
                        type="file"
                        multiple
                        className="form-control underline-input"
                        name="appForm"
                        ref={register}
                      />
                    </Col> */}
                <Col md={3}>
                  <label>Logo:</label>
                  <input
                    type="file"
                    className="form-control underline-input"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={(data) => {
                      uploadLogo(data);
                    }}
                    accept=".jpg, .jpeg, .bmp, .gif, .png"
                  />
                </Col>
                <Col md={3}>
                  <label>Agreements:</label>
                  <input
                    type="file"
                    multiple
                    className="form-control underline-input"
                    name="transactionalArrangement"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>University:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="university"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register}
                  />
                </Col>
                <Col md={3}>
                  <label>Remarks:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    name="remarks"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={12}>
                  <label>Overview:</label>
                  <br />
                  <textarea
                    type="text"
                    style={{ width: "100%", backgroundColor: "#f5f5f9" }}
                    className="form-control underline-input"
                    rows={3}
                    name="overview"
                    ref={register}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Contact Details</p>
              {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
            </div>
            <div className="form-group provideradmin2">
              <Row>
                <Col md={3}>
                  <label>Contact Person:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="contactPerson"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Designation:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="designation"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Phone:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="phoneNumber"
                    ref={register}
                    style={{ backgroundColor: "#f5f5f9" }}
                  />
                </Col>
                <Col md={3}>
                  <label>Mobile:</label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className="form-control underline-input"
                    name="mobile"
                    style={{ backgroundColor: "#f5f5f9" }}
                    ref={register({ required: false })}
                  />
                  {errors.mobile && (
                    <p className="errormessage">Mobile is Required</p>
                  )}
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={6}>{null}</Col>
              <Col md={6} className="d-flex justify-content-end mt-2">
                <button
                  className="outline-button"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    document.getElementById("myForm").reset();
                  }}
                >
                  RESET
                </button>
                <button type="submit" className="sent-button">
                  SUBMIT
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
};

export default CreateAgent;