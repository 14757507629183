import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Table, Card, Col, Row, Button } from "reactstrap";
import Modal from "react-modal";
import { toast } from "react-toastify";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../../Utilities/CSSUtilities";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chart from "react-google-charts";
import { logout, selectUser } from "../features/userSlice";
import moment from "moment";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import close from "../../../src/components/images/closeicon.png";
import {
    customStyles,
    customStylesAttachment,
} from "../../Utilities/CSSUtilities";
import { useForm } from "react-hook-form";
import {
    editorConfiguration,
    customFileStyles,
} from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import Illustrationwork from "./../images/client-dashboard/Illustrationwork.png";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import { check1pointpermission } from "../Permission/permission";

export const customStyles3 = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        minWidth: "700px",
        maxWidth: "900px",
        minHeight: "500px",
        maxHeight: "600px",
        transform: "translate(-50%, -50%)",
    },
};
export const customStyles2 = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "900px",
        maxHeight: "600px",
        transform: "translate(-50%, -50%)",
    },
};

const WorkflowFiles = ({
    clientId, fileName
}) => {
    console.log(clientId);
    console.log(fileName);
    const user = useSelector(selectUser);
    const isw_user_name = localStorage.getItem("isw_user_name");
    const isw_user_email = localStorage.getItem("isw_user_email");
    const history = useHistory();
    const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    // const selected_client_id = localStorage.getItem("selected_client_id");
    const params = useParams();
    const selected_client_id =
        params.client_id || localStorage.getItem("selected_client_id");
    const [descriptionState2, setdescriptionState2] = useState(null);
    const classes = useStyles();
    const [noteworkflowtype, setnoteworkflowtype] = useState("");
    const [backdropopen, setbackdropopen] = useState(false);
    const [permissionData, setPermissionData] = useState([]);
    const [rolepermission, setrolepermission] = useState([]);
    const [pageno, setpageno] = useState(0);


    const [documentpage, setDocPage] = useState(0);
    const [getDocPage, setgetDocPage] = useState();
    // ---------->Workload notes useState<------------
    const [workloadpageno, setworkloadpageno] = useState(0);
    const [workloadtitlevalue, setworkloadtitlevalue] = useState("");
    const [workloaddescriptionvalue2, setworkloaddescriptionvalue2] =
        useState("");
    const [workloaddescriptionvalue, setworkloaddescriptionvalue] = useState("");
    const [workloadnoteid, setworkloadnoteid] = useState("");
    const [viewworkloadmodal, setviewworkloadmodal] = useState(false);
    // ---------->Workload notes useState End<------------
    const dispatch = useDispatch();
    const [clientDocuments, setclientDocuments] = useState();


    const [viewAttachment, setViewAttachment] = useState(false);
    const [clientAllDocuments, setclientAllDocuments] = useState();
    const [documentallpage, setAllDocPage] = useState(0);
    const [getAllDocPage, setgetAllDocPage] = useState();
    const [totalAllDocUser, settotalAllDocUser] = useState();
    const [countAllDocnumber, setcountAllDocnumber] = useState();
    const [numberofAllDocElements, setnumberofAllDocElements] = useState();

    useEffect(() => {
        console.log("Current Page No--->", documentpage);
        window.addEventListener("storage", function (e) {
            if (e.key === null) {
                dispatch(logout());
                history.push("/");
            }
        });
        setbackdropopen(true);
        getPermissions();
        // openViewModal();
        let card = document.getElementsByClassName("alert");
        console.log(card);
    }, []);

    const getPermissions = () => {
        const resource_obj = [];
        const permission_obj = [];
        for (let i = 0; i < user.isw_role_permissions.length; i++) {
            resource_obj.push(user.isw_role_permissions[i].resourceId);
        }
        for (let i = 0; i < user.isw_user_permissions.length; i++) {
            permission_obj.push(user.isw_user_permissions[i].permissionId);
        }
        setPermissionData(permission_obj);
        setrolepermission(resource_obj);

        // if (check1pointpermission(306, permission_obj, resource_obj)) {

        //     //getData();


        // } else {
        //     history.push("/errorPage");
        // }
        getAllClientDoc(documentallpage);
    };

    const getAllClientDoc = (documentallpage) => {
        setbackdropopen(false);
        axios
            .get(`${base_url.api2}/getAttachmentsByClientId`, {
                headers: {
                    Authorization: jwtToken,
                    clientId: selected_client_id,
                    page: documentallpage,
                    size: 20,
                },
            })
            .then((res) => {
                setclientAllDocuments(res.data.data.content);
                setgetAllDocPage(res.data.data.totalPages);
                settotalAllDocUser(res.data.data.totalElements);
                setcountAllDocnumber(res.data.data.number);
                setnumberofAllDocElements(res.data.data.numberOfElements);
            })
            .catch((err) => {
                setclientAllDocuments(null);
                setgetAllDocPage(null);
                settotalAllDocUser(null);
                setcountAllDocnumber(null);
                setnumberofAllDocElements(null);
                console.log(err);
            });
    };


    const readAllChunks = (readableStream) => {
        localStorage.setItem(
            "client_attachment_file_content_type",
            readableStream.headers.get("content-type")
        );
        const reader = readableStream.body.getReader();
        const chunks = [];

        function pump() {
            return reader.read().then(({ value, done }) => {
                if (done) {
                    return chunks;
                }
                chunks.push(value);
                return pump();
            });
        }
        return pump();
    };

    const readnullFile = () => {
        let card = document.getElementsByClassName("alert");
        console.log(card);
        card.style.display = "none";
    };

    const [finalFile, setFinalFile] = useState();
    const [finalFileType, setFinalFileType] = useState();
    const openViewModal = async () => {
        setbackdropopen(true);

        // let file_name = path.split("amazonaws.com/")[1];
        // console.log(path);
        // console.log(file_name);
        // let file_type = params.file_id.split(".");
        let file_type = fileName.split(".");
        console.log(file_type);
        console.log(params.client_id);
        console.log(params.file_id);

        // let file_name = path.split("amazonaws.com/")[1];
        // console.log(path);
        // console.log(file_name);
        // let file_type = path.split(".");

        const header = {
            method: "GET",
            headers: {
                Authorization: jwtToken,
                clientId: params.client_id,
                fileName: `${params.client_id}/${fileName}`,
            },
        };

        var url = `${base_url.api2}/downloadEnquiryAttachmentByClientIdAndFileName`;
        // fetch(url, header)
        //     .then((response) => readAllChunks(response))
        //     .then(async function (response) {
        //         const downloadFileType = localStorage.getItem(
        //             "enquiry_attachment_file_content_type"
        //         );
        //         var blob = new Blob(response, { type: downloadFileType });
        //         console.log(blob);
        //         var link = document.createElement("a");
        //         console.log(link);
        //         link.href = window.URL.createObjectURL(blob);
        //         console.log(window.URL.createObjectURL(blob));
        //         setFinalFile(URL.createObjectURL(blob));
        //         console.log(URL.createObjectURL(blob));
        //         setFinalFileType(file_type[file_type.length - 1]);
        //         console.log(file_type[file_type.length - 1]);
        //         link.download = params.file_id;
        //         console.log(link.download);
        //         setbackdropopen(false);
        //         setViewAttachment(true);
        //     })
        //     .catch((error) => {
        //         toast.error("Oops! File not Found");
        //         setFinalFile("");
        //         setFinalFileType("");
        //         setbackdropopen(false);
        //     });
        try {
            await
                fetch(url, header)
                    .then((response) => readAllChunks(response))
                    .then((response) => {
                        const downloadFileType = localStorage.getItem(
                            "enquiry_attachment_file_content_type"
                        );
                        //Create a Blob from the PDF Stream
                        setFinalFileType(file_type[file_type.length - 1]);
                        const my_state = file_type[file_type.length - 1];

                        //Build a URL from the file


                        //Open the URL on new Window

                        if (my_state == "pdf" || my_state == "PDF") {
                            console.log("coming if");
                            const file = new Blob(response, { type: "application/pdf" });
                            const fileURL = URL.createObjectURL(file);
                            const pdfWindow = window.open();
                            pdfWindow.location.href = fileURL;
                        } else if (my_state == "png") {
                            console.log("coming else1");
                            const file1 = new Blob(response, { type: "image/png" });
                            const fileURL1 = URL.createObjectURL(file1);
                            const pdfWin = window.open();
                            pdfWin.location.href = fileURL1;
                        } else if (my_state == "jpg") {
                            console.log("coming else2");
                            const file2 = new Blob(response, { type: "image/jpeg" });
                            const fileURL2 = URL.createObjectURL(file2);
                            const pdfWi = window.open();
                            pdfWi.location.href = fileURL2;
                        } else if (my_state == "csv") {
                            console.log("coming else3");
                            const file3 = new Blob(response, { type: "text/csv" });
                            const fileURL3 = URL.createObjectURL(file3);
                            const pdfW = window.open();
                            pdfW.location.href = fileURL3;
                        }
                        else if (my_state == "docx") {
                            console.log("coming else4");
                            const file4 = new Blob(response, { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
                            const fileURL4 = URL.createObjectURL(file4);
                            const pdfWs = window.open();
                            pdfWs.location.href = fileURL4;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
        } catch (error) {
            return { error };
        }
    };

    useEffect(() => {
        let width = document.querySelector("table")?.offsetWidth;
        if (document.querySelector(".scroller")) {
            document.querySelector(".scroller").style.width = `${width}px`;
        }
    }, [clientAllDocuments]);

    useEffect(() => {
        let width = document.querySelector("table")?.offsetWidth;
        if (document.querySelector(".scroller")) {
            document.querySelector(".scroller").style.width = `${width}px`;
        }
    }, [clientDocuments]);

    return (
        <>
            {/* <Backdrop className={classes.backdrop} open={backdropopen}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="my-apple"
            >
                {finalFile != undefined ? (
                    <FileViewer
                        className="file-viewer-style"
                        fileType={finalFileType}
                        filePath={finalFile}
                    />) : null}
            </div> */}
            <a
                className="sent-button"
                style={{ marginRight: "10px" }}

                target="_blank"
                rel="noopener noreferrer"
                onClick={openViewModal}
            >
                View
            </a>
        </>
    );
};

export default WorkflowFiles;
