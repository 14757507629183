import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  gettabvalue,
  getworkflowType,
  selectUser,
  setdashboardcheck,
} from "./../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PasswordStrengthBar from "react-password-strength-bar";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import sort from "./../images/sort.svg";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "../Permission/permission";

function UserClient(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [backdropopen, setbackdropopen] = useState(false);
  const [inputtype, setinputtype] = useState("password");
  const classes = useStyles();
  const [client_users, setClientDetails] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [remarkcontent, setremarkcontent] = useState();
  const [datefilter, setdatefilter] = useState();
  const [statusstate, setstatusstate] = useState(1);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [rows, setrows] = React.useState(50);
  const [clientstatus, setclientstatus] = useState();
  const [clientstatususername, setclientstatususername] = useState();
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const params = useParams();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [client_users]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(601, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(5));
      if (localStorage.getItem("clientPageNo")) {
        let currentPage = localStorage.getItem("clientPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        allclientdetails(currentPage, statusstate);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        allclientdetails(0, statusstate);
      }
    } else {
      // console.log('No access to the page');
      history.push("/errorPage");
    }
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    allclientdetails(value - 1, statusstate);
  };

  const [userOfficeId, setuserOfficeId] = useState(
    user.isw_user_role === 1 || user.isw_user_role === 6
      ? "all"
      : user?.isw_available_Office[0].officeId
  );
  const allclientdetails = (value1, statusstate) => {
    setbackdropopen(true);
    // if (userOfficeId === "all") {
    //   axios
    //     .get(`${base_url.api3}/getClientByClientStatus`, {
    //       headers: {
    //         Authorization: jwtToken,
    //         status: statusstate,
    //         columnName: sortState,
    //         direction: direction,
    //         page: Number(value1),
    //         size: Number(rows),
    //       },
    //     })
    //     .then(
    //       (response) => {
    //         setbackdropopen(false);
    //         settotalUser(response.data.data.totalElements);
    //         setcountnumber(response.data.data.number);
    //         setnumberofElements(response.data.data.numberOfElements);
    //         setClientDetails(response.data.data.content);
    //         setsearch(false);
    //         setdatefilter(false);
    //         setgetpageno(response.data.data.totalPages);
    //       },
    //       (error) => {
    //         setbackdropopen(false);
    //         setClientDetails(null);
    //         console.log(JSON.stringify(error));
    //       }
    //     );
    // } else {
    axios
      .get(`${base_url.api3}/getClientByConsultantId`, {
        headers: {
          Authorization: jwtToken,
          consultantId: Number(params.admin_id),
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setClientDetails(response.data.data.content);
          setsearch(false);
          setdatefilter(false);
          setgetpageno(response.data.data.totalPages);
        },
        (error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        }
      );
    // }
  };

  const handleChangeOffice = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    setuserOfficeId(
      optionElementName === "all"
        ? optionElementName
        : Number(optionElementName)
    );
    setpaginationpageno(1);
    if (optionElementName === "all") {
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            columnName: sortState,
            direction: direction,
            page: 0,
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setClientDetails(response.data.data.content);
            setsearch(false);
            setbackdropopen(false);
            setdatefilter(false);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/getClientByClientStatusAndOfficeId`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            officeId: Number(optionElementName),
            columnName: sortState,
            direction: direction,
            page: 0,
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setbackdropopen(false);
            setClientDetails(response.data.data.content);
            setsearch(false);
            setdatefilter(false);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    }
  };

  const createClient = () => {
    localStorage.setItem("client_prev_path", "/client-list");
    history.push("/create-client");
  };

  const handleChangeTableStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setstatusstate(Number(optionElementName));
    // console.log(optionElementName);
    allclientdetails(pageno, optionElementName);
  };

  const deleteUser = () => {
    let status1;
    if (clientstatus === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      username: clientstatususername,
      status: status1,
    };
    axios
      .post(`${base_url.api3}/updateClientByUsername`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success("Status Changed");
          setuserStatus(response.data.data.status);
          setchangestatusmodal(false);
          let logs =
            isw_user_name +
            " Updated status of Client " +
            response.data.data.email +
            " to " +
            response.data.data.status +
            ".";
          callsaveauditapi(logs);
          allclientdetails(pageno, statusstate);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const viewClient = (elem) => {
    localStorage.setItem("clientPageNo", pageno);
    var client_username = elem.target.id;
    localStorage.setItem("comingfromwhere", "adminpage");
    localStorage.setItem(
      "prev_path",
      "/user-permissions/" + params.admin_id + "/" + params.admin_email
    );
    history.push("/client-view/" + client_username);
  };

  const viewEnquiry = (p, e) => {
    localStorage.setItem(
      "prev_path",
      "/user-permissions/" + params.admin_id + "/" + params.admin_email
    );
    localStorage.setItem("clientPageNo", pageno);
    dispatch(getworkflowType("Admission"));
    dispatch(gettabvalue(0));
    var name = p.firstName + " " + p.lastName;
    // history.push("/view-client-enquiry/" + p.id + "/" + p.email);

    localStorage.setItem("selected_client_id", p.id);
    localStorage.setItem("selected_client_username", p.email);

    history.push("/view-enquiry-workflowList/" + p.id + "/" + p.email);
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [userStatus, setuserStatus] = useState();

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setpaginationpageno(value);
    setbackdropopen(true);
    setpageno(value - 1);
    if (datefilter) {
      axios
        .post(`${base_url.api3}/searchClientByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setbackdropopen(false);
            setcountnumber(response.data.data.number);
            setClientDetails(response.data.data.content);
            setsearch(false);
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/searchClientDetails`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            setbackdropopen(false);
            setcountnumber(response.data.data.number);
            setClientDetails(response.data.data.content);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    }
  };
  const [display1, setdisplay] = useState("inline");
  const submitSearch = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    // console.log("Search", srchdata);
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      axios
        .get(`${base_url.api3}/searchClient`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: srchdata,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            setbackdropopen(false);
            setClientDetails(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
            setsearch(true);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/searchClientByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: srchdata,
            columnName: sortState,
            officeId: Number(userOfficeId),
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setbackdropopen(false);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            setClientDetails(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
            setsearch(true);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    }
  };

  const [selectedclient, setselectedclient] = useState();

  const [chkPassState, setchkPassState] = useState();
  const submitNewPassword = (e) => {
    let username = e.target[0].value;
    let password = chkPassState.trim();
    let data = {
      username,
      password,
    };
    axios
      .post(`${base_url.api3}/updateClientPasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (res) => {
          console.log(res.data.data);
          toast.success(res.data.message);
          let logs =
            isw_user_name +
            " Updated password of Client " +
            res.data.data.email +
            ".";
          callsaveauditapi(logs);

          setIsOpen2(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    axios
      .get(`${base_url.api3}/getClientByClientStatus`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          setbackdropopen(false);
          setcountnumber(response.data.data.number);
          setClientDetails(response.data.data.content);
          setsearch(false);
          setgetpageno(response.data.data.totalPages);
        },
        (error) => {
          setbackdropopen(false);
          setClientDetails(null);
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    setpaginationpageno(1);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      axios
        .post(`${base_url.api3}/searchClientByDate`, data, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setbackdropopen(false);
            setClientDetails(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setsearch(false);
            setdatefilter(true);
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            console.log(JSON.stringify(error.response));
          }
        );
    } else {
      axios
        .post(`${base_url.api3}/searchClientByOfficeIdAndDate`, data, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(userOfficeId),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setbackdropopen(false);
            setClientDetails(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setsearch(false);
            setdatefilter(true);
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            console.log(JSON.stringify(error.response));
          }
        );
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <div>
          <div className="newlisting_headertabdesign">
            <div className="above-scroller" onScroll={scrolldiv}>
              <div className="scroller"></div>
            </div>
            <div className="displaytablerow">
              {/* <Table responsive striped onScroll={scrolltable}> */}
              <Table
                className="workload card-table table-borderless"
                responsive
                onScroll={scrolltable}
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                    {/* <th style={{ minWidth: "135px" }}>
                      Lead Type{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="enquiryLeadType"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th> */}
                    <th style={{ minWidth: "110px" }}>
                      Contact{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="state"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "140px" }}>
                      Name{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="firstName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "115px" }}>
                      Source{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="clientSource"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "120px" }}>
                      Country{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="country"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    {/* <th style={{ minWidth: "120px" }}>
                      City{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="city"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th> */}
                    <th style={{ minWidth: "120px" }}>
                      Branch{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="officeName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "120px" }}>
                      Consultant{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="consultantName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "110px" }}>
                      Status{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="status"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "130px" }}>
                      Comments{" "}
                      <img
                        src={sort}
                        className="sorting"
                        id="comment"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    {Number(user?.isw_user_role) === 1 ||
                      Number(user?.isw_user_role) === 4 ||
                      Number(user?.isw_user_role) === 5 ||
                      Number(user?.isw_user_role) === 6 ? (
                      <th>Action</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {client_users != null ? (
                    client_users &&
                    client_users?.map((p, index) => (
                      <>
                        {p.status === 1 ?
                          <tr key={index + 1}>
                            <td style={{ textAlign: "center", width: "50px" }}>
                              {countnumber * rows + 1 + index}
                            </td>
                            {/* <td>{p.enquiryLeadType}</td> */}
                            <td>
                              {p?.clientDetail?.phone}
                              <br />
                              {p.secondaryphone}
                            </td>
                            <td>
                              {check1pointpermission(
                                306,
                                permissionData,
                                rolepermission
                              ) ? (
                                <a
                                  style={{ cursor: "pointer", color: "blue" }}
                                  id={p.username}
                                  onClick={(e) => viewEnquiry(p, e)}
                                  href={
                                    `#/view-enquiry-workflowList/` +
                                    p.id +
                                    `/` +
                                    p.email
                                  }
                                >
                                  {p.firstName +
                                    " " +
                                    p.middleName +
                                    " " +
                                    p.lastName}
                                </a>
                              ) : (
                                <>
                                  {p.firstName +
                                    " " +
                                    p.middleName +
                                    " " +
                                    p.lastName}
                                </>
                              )}
                              <br />
                              {p.email}
                            </td>
                            <td>{p.clientSource}</td>

                            <td>
                              {p.city}
                              <br />
                              {p.country}
                            </td>

                            <td>{p.officeCode}</td>
                            {/* <td>
                              {p.consultantName}
                              <br />
                              {p.consultantEmail}
                            </td> */}
                            <td>
                              <>
                                <div class="s_tooltip">
                                  {p.consultantName != null ? p.consultantName.split(" ")[0] : ""}
                                  <span class="s_tooltiptext">
                                    {p.consultantEmail}
                                  </span>
                                </div>
                              </>
                            </td>
                            {p.status === 1 ? <td>Active</td> : <td>InActive</td>}
                            <td className="leadellipsis">
                              {p.clientDetail?.comment}
                              {p.clientDetail?.comment != "" ? (
                                <span
                                  className="leadellipsisspan"
                                  onClick={() => {
                                    setremarkmodelopen(true);
                                    setremarkcontent(p.clientDetail.comment);
                                  }}
                                >
                                  View More
                                </span>
                              ) : null}
                            </td>
                            <Modal
                              isOpen={remarkmodelopen}
                              onRequestClose={() => setremarkmodelopen(false)}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >
                              <h4>Remark</h4>
                              {remarkcontent ? (
                                <textarea
                                  style={{ minWidth: "600px" }}
                                  className="form-control"
                                  value={remarkcontent}
                                  rows={10}
                                  readonly
                                />
                              ) : (
                                <p> No Record Available</p>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  color="secondary"
                                  onClick={() => setremarkmodelopen(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal>
                            <td id={p.id}>
                              {Number(user?.isw_user_role) === 1 ||
                                Number(user?.isw_user_role) === 4 ||
                                Number(user?.isw_user_role) === 5 ||
                                Number(user?.isw_user_role) === 6 ? (
                                <div
                                  style={{
                                    position: "relative",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    className="workflowdashboard_fa-ellipsis"
                                    data-toggle="dropdown"
                                  >
                                    <i
                                      className="fa fa-ellipsis-v "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="dropdown-menu workflowdashboarddropdown">
                                    {check1pointpermission(
                                      305,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <p>
                                        {p.status === 0 ? (
                                          <Link
                                            id={p.username}
                                            onClick={() => {
                                              // deleteUser(p.status, p.username)
                                              setclientstatus(p.status);
                                              setclientstatususername(p.username);
                                              setchangestatusmodal(true);
                                            }}
                                          >
                                            Unarchive
                                          </Link>
                                        ) : (
                                          <Link
                                            id={p.username}
                                            onClick={() => {
                                              // deleteUser(p.status, p.username)
                                              setclientstatus(p.status);
                                              setclientstatususername(p.username);
                                              setchangestatusmodal(true);
                                            }}
                                          >
                                            Archive
                                          </Link>
                                        )}
                                      </p>
                                    ) : null}
                                    {check1pointpermission(
                                      303,
                                      permissionData,
                                      rolepermission
                                    ) ? (
                                      <p>
                                        <a
                                          id={p.username}
                                          onClick={viewClient}
                                          href={`#/edit-client/` + p.username}
                                        >
                                          Edit
                                        </a>
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              <Modal
                                isOpen={changestatusmodal}
                                onRequestClose={() => setchangestatusmodal(false)}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <h4 style={{ marginTop: "10px" }}>
                                  Are you sure you want to{" "}
                                  {clientstatus === 1 ? "Archive" : "Unarchive"}{" "}
                                  current record ?{" "}
                                </h4>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    justifyContent: "flex-end",
                                    gap: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <button
                                    className="btn listing_addbutton ml-1"
                                    onClick={deleteUser}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="btn btn-secondary cancel_margin"
                                    onClick={() => setchangestatusmodal(false)}
                                  >
                                    No
                                  </button>
                                </div>
                              </Modal>
                              <Modal
                                isOpen={modalIsOpen2}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    submitNewPassword(e);
                                  }}
                                >
                                  <div className="form-inner-admin">
                                    <h3 style={{ color: "black" }}>
                                      Update Password
                                    </h3>
                                    <div className="form-group creatadmin">
                                      <Row>
                                        <Col>
                                          <label>User Name:</label>
                                          <input
                                            type="text"
                                            className="form-control underline-input"
                                            value={selectedclient}
                                            readOnly
                                            required
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="form-group creatadmin">
                                      <Row>
                                        <Col>
                                          <label>Password:</label>
                                          <div style={{ position: "relative" }}>
                                            <input
                                              type={inputtype}
                                              className="form-control underline-input"
                                              value={chkPassState}
                                              onChange={(e) =>
                                                setchkPassState(e.target.value)
                                              }
                                              required
                                            />
                                            {inputtype === "password" ? (
                                              <i
                                                className="fa fa-eye-slash fonticonpositioning"
                                                onClick={() => setinputtype("text")}
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-eye fonticonpositioning"
                                                onClick={() =>
                                                  setinputtype("password")
                                                }
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </div>
                                          <PasswordStrengthBar
                                            password={chkPassState}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="form-group creatadmin">
                                      <Row className="justify-content-end">
                                        <div>
                                          <Button
                                            type="submit"
                                            className="btn listing_addbutton"
                                          >
                                            Update
                                          </Button>
                                        </div>
                                        <div>
                                          <Button
                                            color="secondary"
                                            onClick={closeModal}
                                          >
                                            Cancel
                                          </Button>
                                        </div>
                                      </Row>
                                    </div>
                                  </div>
                                </form>
                              </Modal>
                              <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                                  Change Status ?
                                </h2>
                                {p.status === 0 ? (
                                  <Button color="danger" onClick={deleteUser}>
                                    Unarchive
                                  </Button>
                                ) : (
                                  <Button color="danger" onClick={deleteUser}>
                                    Archive
                                  </Button>
                                )}
                                &nbsp;&nbsp;&nbsp;
                                <Button color="secondary" onClick={closeModal}>
                                  Cancel
                                </Button>
                              </Modal>
                            </td>
                          </tr>
                          :
                          <>
                            {client_users != null ?
                              <tr key={index + 1} className="s_inactive">
                                <td style={{ textAlign: "center", width: "50px" }}>
                                  {countnumber * rows + 1 + index}
                                </td>
                                {/* <td>{p.enquiryLeadType}</td> */}
                                <td>
                                  {p?.clientDetail?.phone}
                                  <br />
                                  {p.secondaryphone}
                                </td>
                                <td>
                                  {check1pointpermission(
                                    306,
                                    permissionData,
                                    rolepermission
                                  ) ? (
                                    <a
                                      style={{ cursor: "pointer", color: "blue" }}
                                      id={p.username}
                                      onClick={(e) => viewEnquiry(p, e)}
                                      href={
                                        `#/view-enquiry-workflowList/` +
                                        p.id +
                                        `/` +
                                        p.email
                                      }
                                    >
                                      {p.firstName +
                                        " " +
                                        p.middleName +
                                        " " +
                                        p.lastName}
                                    </a>
                                  ) : (
                                    <>
                                      {p.firstName +
                                        " " +
                                        p.middleName +
                                        " " +
                                        p.lastName}
                                    </>
                                  )}
                                  <br />
                                  {p.email}
                                </td>
                                <td>{p.clientSource}</td>
                                <td>
                                  {p.city}
                                  <br />
                                  {p.country}
                                </td>
                                <td>{p.officeCode}</td>
                                {/* <td>
                                  {p.consultantName}
                                  <br />
                                  {p.consultantEmail}
                                </td> */}
                                <td>
                                  <>
                                    <div class="s_tooltip">
                                      {p.consultantName != null ? p.consultantName.split(" ")[0] : ""}
                                      <span class="s_tooltiptext">
                                        {p.consultantEmail}
                                      </span>
                                    </div>
                                  </>
                                </td>
                                {p.status === 1 ? <td>Active</td> : <td>InActive</td>}
                                <td className="leadellipsis">
                                  {p.clientDetail?.comment}
                                  {p.clientDetail?.comment != "" ? (
                                    <span
                                      className="leadellipsisspan"
                                      onClick={() => {
                                        setremarkmodelopen(true);
                                        setremarkcontent(p.clientDetail.comment);
                                      }}
                                    >
                                      View More
                                    </span>
                                  ) : null}
                                </td>
                                <Modal
                                  isOpen={remarkmodelopen}
                                  onRequestClose={() => setremarkmodelopen(false)}
                                  style={customStyles}
                                  contentLabel="Example Modal"
                                >
                                  <h4>Remark</h4>
                                  {remarkcontent ? (
                                    <textarea
                                      style={{ minWidth: "600px" }}
                                      className="form-control"
                                      value={remarkcontent}
                                      rows={10}
                                      readonly
                                    />
                                  ) : (
                                    <p> No Record Available</p>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      margin: "10px 0",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      color="secondary"
                                      onClick={() => setremarkmodelopen(false)}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Modal>
                                <td id={p.id}>
                                  {Number(user?.isw_user_role) === 1 ||
                                    Number(user?.isw_user_role) === 4 ||
                                    Number(user?.isw_user_role) === 5 ||
                                    Number(user?.isw_user_role) === 6 ? (
                                    <div
                                      style={{
                                        position: "relative",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                        className="workflowdashboard_fa-ellipsis"
                                        data-toggle="dropdown"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v "
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <div className="dropdown-menu workflowdashboarddropdown">
                                        {check1pointpermission(
                                          305,
                                          permissionData,
                                          rolepermission
                                        ) ? (
                                          <p>
                                            {p.status === 0 ? (
                                              <Link
                                                id={p.username}
                                                onClick={() => {
                                                  // deleteUser(p.status, p.username)
                                                  setclientstatus(p.status);
                                                  setclientstatususername(p.username);
                                                  setchangestatusmodal(true);
                                                }}
                                              >
                                                Unarchive
                                              </Link>
                                            ) : (
                                              <Link
                                                id={p.username}
                                                onClick={() => {
                                                  // deleteUser(p.status, p.username)
                                                  setclientstatus(p.status);
                                                  setclientstatususername(p.username);
                                                  setchangestatusmodal(true);
                                                }}
                                              >
                                                Archive
                                              </Link>
                                            )}
                                          </p>
                                        ) : null}
                                        {check1pointpermission(
                                          303,
                                          permissionData,
                                          rolepermission
                                        ) ? (
                                          <p>
                                            <a
                                              id={p.username}
                                              onClick={viewClient}
                                              href={`#/edit-client/` + p.username}
                                            >
                                              Edit
                                            </a>
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                  ) : null}
                                  <Modal
                                    isOpen={changestatusmodal}
                                    onRequestClose={() => setchangestatusmodal(false)}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h4 style={{ marginTop: "10px" }}>
                                      Are you sure you want to{" "}
                                      {clientstatus === 1 ? "Archive" : "Unarchive"}{" "}
                                      current record ?{" "}
                                    </h4>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "20px",
                                        justifyContent: "flex-end",
                                        gap: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <button
                                        className="btn listing_addbutton ml-1"
                                        onClick={deleteUser}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        className="btn btn-secondary cancel_margin"
                                        onClick={() => setchangestatusmodal(false)}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </Modal>
                                  <Modal
                                    isOpen={modalIsOpen2}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        submitNewPassword(e);
                                      }}
                                    >
                                      <div className="form-inner-admin">
                                        <h3 style={{ color: "black" }}>
                                          Update Password
                                        </h3>
                                        <div className="form-group creatadmin">
                                          <Row>
                                            <Col>
                                              <label>User Name:</label>
                                              <input
                                                type="text"
                                                className="form-control underline-input"
                                                value={selectedclient}
                                                readOnly
                                                required
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="form-group creatadmin">
                                          <Row>
                                            <Col>
                                              <label>Password:</label>
                                              <div style={{ position: "relative" }}>
                                                <input
                                                  type={inputtype}
                                                  className="form-control underline-input"
                                                  value={chkPassState}
                                                  onChange={(e) =>
                                                    setchkPassState(e.target.value)
                                                  }
                                                  required
                                                />
                                                {inputtype === "password" ? (
                                                  <i
                                                    className="fa fa-eye-slash fonticonpositioning"
                                                    onClick={() => setinputtype("text")}
                                                    aria-hidden="true"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-eye fonticonpositioning"
                                                    onClick={() =>
                                                      setinputtype("password")
                                                    }
                                                    aria-hidden="true"
                                                  ></i>
                                                )}
                                              </div>
                                              <PasswordStrengthBar
                                                password={chkPassState}
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="form-group creatadmin">
                                          <Row className="justify-content-end">
                                            <div>
                                              <Button
                                                type="submit"
                                                className="btn listing_addbutton"
                                              >
                                                Update
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                color="secondary"
                                                onClick={closeModal}
                                              >
                                                Cancel
                                              </Button>
                                            </div>
                                          </Row>
                                        </div>
                                      </div>
                                    </form>
                                  </Modal>
                                  <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                  >
                                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                                      Change Status ?
                                    </h2>
                                    {p.status === 0 ? (
                                      <Button color="danger" onClick={deleteUser}>
                                        Unarchive
                                      </Button>
                                    ) : (
                                      <Button color="danger" onClick={deleteUser}>
                                        Archive
                                      </Button>
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    <Button color="secondary" onClick={closeModal}>
                                      Cancel
                                    </Button>
                                  </Modal>
                                </td>
                              </tr>
                              :
                              <p className={classes.headertekst}>{null}</p>}
                          </>
                        }
                      </>
                    ))
                  ) : (
                    <p className={classes.headertekst}>{null}</p>
                  )}
                </tbody>
                {/* )} */}
              </Table>
            </div>
            <Row>
              <Col md={6}>
                {client_users?.length ? (
                  <p style={{ marginTop: "15px" }}>
                    {countnumber * rows + 1} to{" "}
                    {countnumber * rows + numberofElements} of {totalUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  {searchdata || datefilter ? (
                    <Pagination
                      page={paginationpageno}
                      count={getpageno}
                      onChange={handlesearch}
                      shape="rounded"
                    />
                  ) : (
                    <Pagination
                      page={paginationpageno}
                      count={getpageno}
                      onChange={handlepage}
                      shape="rounded"
                    />
                  )}{" "}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserClient;
