import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../css/AddInvoiceDetails.css";
import "../css/Responsive.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import moment from "moment";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import NepcomServices from "../../assets/images/nepcoms-n.png";
import NepcomLogo from "../../assets/images/Nepcoms-01.png";

const selectheight = "35px";
const selectWidth = "100%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
}));

const PayablePrintInvoice = () => {
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [courseName, setcourseName] = useState();
  const [configkey, setconfigkey] = useState();
  const [stateCust, setstateCust] = useState();
  const [commissiontotal, setcommissionTotal] = useState();
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getadminaddress();
    getinvoicedetails();
  }, []);
  const getadminaddress = () => {
    axios
      .get(`${base_url.api3}/getByConfigKey`, {
        headers: {
          configKey: "isw_address",
        },
      })
      .then((res) => {
        setconfigkey(res.data.data.configValue);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [superAgentData, setSuperAgentData] = useState();
  const getinvoicedetails = () => {
    axios
      .get(`${base_url.api2}/getInvoiceById`, {
        headers: {
          Authorization: jwtToken,
          invoiceId: params.invoice_id,
        },
      })
      .then((res) => {
        setstateCust(res.data.data);
        setcommissionTotal(res.data.data.totalCommission);
        axios
          .get(`${base_url.api2}/getEnquiryById`, {
            headers: {
              Authorization: jwtToken,
              enquiryId: res.data.data.enquiryId,
            },
          })
          .then((res) => {
            setcourseName(res.data.data.courseName);
            let superAgentId = res.data.data.superAgentId;
            if (superAgentId != null) {
              axios
                .get(`${base_url.api3}/getAgentById`, {
                  headers: {
                    Authorization: jwtToken,
                    agentId: Number(superAgentId),
                  },
                })
                .then((resp1) => {
                  setSuperAgentData(resp1.data.data);
                });
            }
          });
      });
  };
  return (
    <div style={{ padding: "50px 80px", background: "white" }}>
      <div className="printinvoice_headerrightbox">
        {/* <div>
          <div dangerouslySetInnerHTML={{ __html: configkey }} />
        </div> */}
        <img src={NepcomLogo} style={{ width: "251px", height: "90px" }} />
        <h3
          style={{
            fontWeight: "bold",
            marginRight: "50px",
            marginTop: "15px",
            fontSize: "38px",
          }}
        >
          TAX INVOICE
        </h3>
      </div>
      <hr className="printinvoice_horiline" />
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              border: "2px solid #737272",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <h3
              className="nomarginbottom"
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {stateCust?.invoiceStatus}
            </h3>
          </div>
        </div>
        <div className="print_header printinvoice_header">
          <div style={{ flex: "0.7" }}>
            <h4 style={{ fontWeight: "bold" }}>INVOICE TO : </h4>
            <hr />
            <>
              {((stateCust?.commisionType == "commissionInvoiceNet" || "commissionInvoiceGross") && (superAgentData?.agentId != null)) ? (
                <>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Name:</Typography> */}
                    <h6 style={{ fontWeight: "bold" }}>
                      {superAgentData?.name}
                    </h6>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h6 style={{ fontWeight: "bold" }}>ID:</h6>
                    <h6>{stateCust?.externalId}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Course:</Typography> */}
                    <h6>{stateCust?.agentName}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Course:</Typography> */}
                    <h6>{stateCust?.courseName}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Provider:</Typography> */}
                    <h6>{stateCust?.serviceProvider}</h6>
                  </div>
                </>
              ) : (
                <>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Student Name:</Typography> */}
                    <h6 style={{ fontWeight: "bold" }}>
                      {stateCust?.clientUsername}
                    </h6>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h6 style={{ fontWeight: "bold" }}>ID:</h6>
                    <h6>{stateCust?.externalId}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Course:</Typography> */}
                    <h6>{stateCust?.agentName}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Course:</Typography> */}
                    <h6>{stateCust?.courseName}</h6>
                  </div>
                  <div className="printinvoice_headerrightbox">
                    {/* <Typography style={{ flex: 0.3 }}>Provider:</Typography> */}
                    <h6>{stateCust?.serviceProvider}</h6>
                  </div>
                </>
              )}
            </>
          </div>
          <div
            style={{
              flex: "0.3",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <div className="printinvoice_headerrightboxtop">
              <div className="printinvoice_headerrightboxnew">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  INVOICE NO :
                </Typography>
                <Typography variant="body1">#{stateCust?.id}</Typography>
              </div>
              <div className="printinvoice_headerrightboxnew">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  INVOICE DATE :
                </Typography>
                <Typography variant="body1">
                  {moment(stateCust?.invoiceCreatedDate).format("DD/MM/YYYY")}
                </Typography>
              </div>
              <div className="printinvoice_headerrightboxnew print_invoicetotaldue">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  TOTAL DUE :{/* {`Total Due (in ${stateCust?.currency}):`} */}
                </Typography>
                {/* <Typography variant="body1">${Number(stateCust?.totalDue).toFixed(2)}</Typography> */}
                <Typography variant="body1">(In AUD)   {Number(stateCust?.totalDue).toFixed(2)}</Typography>
              </div>
              <div className="printinvoice_headerrightboxnew">
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  DUE DATE :
                </Typography>
                <Typography variant="body1">
                  {moment(stateCust?.invoiceDueDate).format("DD/MM/YYYY")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="print_middle" style={{ marginBottom: "20px" }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bolder" }}>
                  DESCRIPTION
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  FEE
                </TableCell>
                <TableCell
                  align="right"
                  style={{ padding: "0", fontWeight: "bolder" }}
                >
                  COMMISSION(%)
                </TableCell>
                <TableCell
                  align="right"
                  style={{ padding: "0", fontWeight: "bolder" }}
                >
                  COMMISSION
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  TAX(%)
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bolder" }}>
                  TAX AMOUNT
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stateCust?.invoiceDetails.map((p, index) => (
                <TableRow key={index + 1}>
                  <TableCell component="th" scope="row">
                    <Typography>{p.description}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.amount ? p.amount.toFixed(2)
                      : Number(0).toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.commissionPercentage ? p.commissionPercentage.toFixed(2)
                      : Number(0).toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.commissionAmount ? p.commissionAmount.toFixed(2)
                      : Number(0).toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.taxPercentage ? p.taxPercentage.toFixed(2)
                      : Number(0).toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{p.taxAmount ? p.taxAmount.toFixed(2)
                      : Number(0).toFixed(2)}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead className="printinvoice_tr">
              <TableRow>
                <TableCell>
                  <Typography>{`Total(in ${stateCust?.currency}):`}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{Number(stateCust?.netAmount).toFixed(2)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{null}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{commissiontotal ? commissiontotal.toFixed(2)
                    : Number(0).toFixed(2)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{null}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{stateCust?.totalTax ? stateCust?.totalTax.toFixed(2)
                    : Number(0).toFixed(2)}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
      <div
        className="print_footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ flex: ".6" }}>{null}</div>

        <div className="print_footersummary" style={{ flex: "0.4" }}>
          <div style={{ backgroundColor: "#737373", padding: "10px" }}>
            <h4 style={{ color: "white" }}>Invoice Summery    (In AUD)</h4>
          </div>
          <div
            className="printinvoice_divlisting"
            style={{ backgroundColor: "rgba(0,0,0,.2)" }}
          >
            <h5 className="printinvoice_divlistingtextfirst">
              Fee received from client:
            </h5>
            <h5 className="printinvoice_divlistingtextsecond">
              {stateCust?.netAmount.toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
          <div className="printinvoice_divlisting">
            <h5 className="printinvoice_divlistingtextfirst">Commission:</h5>
            <h5 className="printinvoice_divlistingtextsecond">
              {stateCust?.totalCommission.toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
          <div
            className="printinvoice_divlisting"
            style={{ backgroundColor: "rgba(0,0,0,.2)" }}
          >
            <h5 className="printinvoice_divlistingtextfirst">Tax:</h5>
            <h5 className="printinvoice_divlistingtextsecond">
              {stateCust?.totalTax.toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
          <div className="printinvoice_divlisting">
            <h5 className="printinvoice_divlistingtextfirst">
              Total claimed (inc. Tax):
            </h5>
            <h5 className="printinvoice_divlistingtextsecond">
            {(
                (Number(stateCust?.totalCommission) +
                  Number(stateCust?.totalTax))).toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
          <div
            className="printinvoice_divlisting"
            style={{ backgroundColor: "rgba(0,0,0,.2)" }}
          >
            <h5
              style={{ fontWeight: "bold" }}
              className="printinvoice_divlistingtextfirst"
            >
              Net Fee Transferred :
            </h5>
            <h5
              style={{ fontWeight: "bold" }}
              className="printinvoice_divlistingtextsecond"
            >
              {Number(stateCust?.netAmount) -
                (Number(stateCust?.totalCommission) +
                  Number(stateCust?.totalTax)).toFixed(2) || Number(0).toFixed(2)}
            </h5>
          </div>
        </div>
      </div>
      <img
        alt="Nepcoms Services"
        src={NepcomServices}
        className="printinvoice_footerimage"
      />
      <div style={{ position: "absolute", width: "86%", bottom: "20px" }}>
        <hr className="printinvoice_horiline" />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p
              className="nomarginbottom"
              style={{ fontWeight: "bolder", fontSize: 18 }}
            >
              Nepcoms Services
            </p>
            <div style={{ fontWeight: "bold", marginLeft: "20px" }}>
              <div dangerouslySetInnerHTML={{ __html: configkey }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
            }}
          >
            <div>
              <p className="nomarginbottom">Print By: {isw_user_name}</p>
            </div>
            <div>
              <p>
                Print On: {moment().date()},{moment(datenow).format("MMMM")}{" "}
                {moment().year()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayablePrintInvoice;