import { createSlice } from "@reduxjs/toolkit";

export const collegeSlice = createSlice({
  name: "college",
  initialState: {
    college: [],
  },
  reducers: {
    getcollege: (state, action) => {
      state.college = action.payload;
    },

    addtocollege: (state, action) => {
      state.college = [...state.college, action.payload];
    },
    removecollege: (state, action) => {
      const index = state.college.findIndex(
        (collegeUser) => collegeUser.institutionId === Number(action.payload.id)
      );
      console.log(index);
      let newcollege = [...state.college];
      if (index >= 0) {
        newcollege.splice(index, 1);
      } else {
        console.warn("Cant remove");
      }
      return {
        ...state,
        college: newcollege,
      };
    },
    updatecollege: (state, action) => {
      console.log(action.payload.id);
      console.log(Number(action.payload.id));
      const index = state.college.findIndex(
        (collegeUser) => collegeUser.institutionId === Number(action.payload.id)
      );
      let newcollege = [...state.college];
      if (index >= 0) {
        newcollege.splice(index, 1);
        newcollege.splice(index, 0, action.payload.data);
      } else {
        console.warn("Cant update");
      }
      //   newcollege.push(action.payload.data)
      return {
        ...state,
        college: newcollege,
      };
    },
  },
});

export const {
  getcollege,
  addtocollege,
  removecollege,
  updatecollege,
} = collegeSlice.actions;

export const selectcollege = (state) => state.college.college;

export default collegeSlice.reducer;
