import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";

const RoleDeleteModal = ({ deleteCurrentRole, p, }) => {

  const [roleState2, setroleState2] = useState();
  const [roleSta, setroleSta] = useState(p.id);
  const [roleNa, setroleNa] = useState(p.roleName);
  const [deletemodal, setdeletemodal] = useState(false);

  console.log(p);
  const opendeletemodal = () => {
    setdeletemodal(true);
    console.log(p);
    console.log(roleSta);
    localStorage.setItem('selected_roleId', roleSta);
    localStorage.setItem('selected_roleName', roleNa);
    setroleState2({
      id: roleSta,
      roleName: p.roleName,
      roleCode: p.roleCode,
      status: p.status,
    });
  }
  const closedeletemodal = () => setdeletemodal(false);



  const submitdelete = () => {
    deleteCurrentRole();
    closedeletemodal();
  };

  return (
    <>
      <button style={{ cursor: "pointer" }} onClick={opendeletemodal} className="role-deletebtn">
        Delete
      </button>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>Are you sure you want to delete current record ?</h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn listing_addbutton ml-1"
            onClick={submitdelete}
          >
            Delete
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={closedeletemodal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RoleDeleteModal;
