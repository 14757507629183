import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";

import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectUser,
  setdashboardcheck,
  logout,
} from "./../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import { useStyles } from "../../Utilities/CSSUtilities";
import sort from "./../images/sort.svg";

function ApplicationReports(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("enquiryId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");
  const [backdropopen, setbackdropopen] = useState(false);
  const [officeid, setOfficeId] = useState(
    user.isw_available_Office[0].officeId
  );

  const [admissionEnquiry, setadmissionEnquiry] = useState();
  const [admissionEnquiryPageNo, setadmissionEnquiryPageNo] = useState();
  const [
    admissionEnquirynumberofelements,
    setadmissionEnquirynumberofelements,
  ] = useState();
  const [admissionEnquirytotaluser, setadmissionEnquirytotaluser] = useState();
  const [admissionEnquirycountno, setadmissionEnquirycountno] = useState();

  const [migrationEnquiry, setmigrationEnquiry] = useState();
  const [migrationEnquiryPageNo, setmigrationEnquiryPageNo] = useState();
  const [
    migrationEnquirynumberofelements,
    setmigrationEnquirynumberofelements,
  ] = useState();
  const [migrationEnquirytotaluser, setmigrationEnquirytotaluser] = useState();
  const [migrationEnquirycountno, setmigrationEnquirycountno] = useState();

  const [insuranceEnquiry, setinsuranceEnquiry] = useState();
  const [insuranceEnquiryPageNo, setinsuranceEnquiryPageNo] = useState();
  const [
    insuranceEnquirynumberofelements,
    setinsuranceEnquirynumberofelements,
  ] = useState();
  const [insuranceEnquirytotaluser, setinsuranceEnquirytotaluser] = useState();
  const [insuranceEnquirycountno, setinsuranceEnquirycountno] = useState();

  const [skillassementEnquiry, setskillassessmentEnquiry] = useState();
  const [skillassementEnquiryPageNo, setskillassessmentEnquiryPageNo] =
    useState();
  const [
    skillassessmentEnquirynumberofelements,
    setskillassessmentEnquirynumberofelements,
  ] = useState();
  const [skillassessmentEnquirytotaluser, setskillassessmentEnquirytotaluser] =
    useState();
  const [skillassessmentEnquirycountno, setskillassessmentEnquirycountno] =
    useState();

  const [datefilter, setdatefilter] = useState(false);
  const [migrationdatefilter, setmigrationdatefilter] = useState(false);
  const [insurancedatefilter, setinsurancedatefilter] = useState(false);
  const [skilldatefilter, setskilldatefilter] = useState(false);
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const datepast = moment().subtract(1, "M").format("YYYY-MM-DDThh:mm:ss");
  const [permissionData, setPermissionData] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    getWorkflowReports(pageno);
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [admissionEnquiry]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [migrationEnquiry]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [admissionEnquiry]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [admissionEnquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    var available_resource = resource_obj.includes(47);
    var available_permission = permission_obj.includes(407);


    //if (available_permission) {
    getWorkflowReports(pageno);
    getOffice();
    dispatch(setdashboardcheck(47));
    getConsltant();
    // } else {
    //   history.push("/errorPage");
    // }

  }

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = (p) => {
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then(
        (res) => {
          let apiData = res.data.data;
          const consultant_obj = [];
          for (let i = 0; i < apiData.length; i++) {
            let data = {
              value: apiData[i].id,
              label: apiData[i].firstName + " " + apiData[i].lastName,
            };
            consultant_obj.push(data);
          }
          setAssigneeData(consultant_obj);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const exportPdfAdmission = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "Assignee",
      "Course Applied",
      "Office",
      "Source",
      "Created",
    ];
    var rows = [];
    admissionEnquiry?.forEach((element) => {
      var temp = [
        element.clientName +
        "\n" +
        element.clientUsername +
        "\n" +
        "Phone: " +
        element.clientPhoneNumber,
        element.consultantName,
        "Provider: " +
        element.institutionName +
        "\n\n" +
        "Course: " +
        element.courseName +
        "\n\n" +
        "Intake: " +
        element.intake,
        element.officeName,
        element.enquirySource,
        moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    // doc.autoTable(col, rows, { pageBreak: 'auto' });
    doc.save("Admission_Reports.pdf");
  };

  const exportPdfMigration = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "Assignee",
      "Migration Provider",
      "Office",
      "VisaType",
      "Created",
    ];
    var rows = [];
    migrationEnquiry?.forEach((element) => {
      var temp = [
        element.clientName +
        "\n" +
        element.clientUsername +
        "\n" +
        "Phone: " +
        element.clientPhoneNumber,
        element.consultantName,
        element.institutionName,
        element.officeName,
        element.visaType,
        moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    doc.save("Migration_Reports.pdf");
  };

  const exportPdfInsurance = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "Assignee",
      "Insurance Details",
      "Office",
      "Created",
    ];
    var rows = [];
    insuranceEnquiry?.forEach((element) => {
      var temp = [
        element.clientName +
        "\n" +
        element.clientUsername +
        "\n" +
        "Phone: " +
        element.clientPhoneNumber,
        element.consultantName,
        element.institutionName +
        "\n\n" +
        "Type: " +
        element.insuranceType +
        "\n\n" +
        "Intake: " +
        element.intake,
        element.officeName,
        moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    doc.save("Insurance_Reports.pdf");
  };

  const exportPdfSkill = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "Assignee",
      "Skill Assement",
      "Office",
      "Created",
    ];
    var rows = [];
    skillassementEnquiry?.forEach((element) => {
      var temp = [
        element.clientName +
        "\n" +
        element.clientUsername +
        "\n" +
        "Phone: " +
        element.clientPhoneNumber,
        element.consultantName,
        element.workflowName +
        "/n/n" +
        "Provider: " +
        element.institutionName +
        "\n\n" +
        "Course: " +
        element.courseName +
        "\n\n" +
        "Intake: " +
        element.intake,
        element.officeName,
        moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    doc.save("Skill_Assessment_Reports.pdf");
  };

  const handlepageAdmission = (event, value) => {
    // setpageno(value - 1);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    console.log("Admission Clicked--", officeid);
    if (officeid === "all" || officeid == undefined) {
      //Admission Reports
      if (admissionAssigneeState) {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              assigneeId: admissionAssigneeData,
              columnName: sorting,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setadmissionEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            handle403error(error);
          });
      } else {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setadmissionEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    } else {
      //Admission Reports
      if (admissionAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                officeId: officeid,
                assigneeId: admissionAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setadmissionEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            handle403error(error);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                officeId: officeid,
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setadmissionEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    }
  };

  const handlepageMigration = (event, value) => {
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    if (officeid === "all" || officeid == undefined) {
      //Migration Reports
      if (migrationAssigneeState) {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: migrationAssigneeData,
              columnName: sorting,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setmigrationEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            setmigrationEnquiryPageNo(0);
            console.log(JSON.stringify(error));
          });
      } else {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setmigrationEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            setmigrationEnquiryPageNo(0);
            handle403error(error);
          });
      }
    } else {
      //Migration Reports
      if (migrationAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                officeId: officeid,
                assigneeId: migrationAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setmigrationEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            setmigrationEnquiryPageNo(0);
            handle403error(error);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                officeId: officeid,
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setmigrationEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            setmigrationEnquiryPageNo(0);
            handle403error(error);
          });
      }
    }
  };

  const handlepageInsurance = (event, value) => {
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    if (officeid === "all" || officeid == undefined) {
      //Insurance Reports
      if (insuranceAssigneeState) {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              assigneeId: insuranceAssigneeData,
              columnName: sorting,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            setinsuranceEnquiryPageNo(0);
            handle403error(error);
          });
      } else {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            setinsuranceEnquiryPageNo(0);
            handle403error(error);
          });
      }
    } else {
      //Insurance Reports
      if (insuranceAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                officeId: officeid,
                assigneeId: insuranceAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            setinsuranceEnquiryPageNo(0);
            handle403error(error);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            setinsuranceEnquiryPageNo(0);
            handle403error(error);
          });
      }
    }
  };

  const handlepageSkillAssessment = (event, value) => {
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    if (officeid === "all" || officeid == undefined) {
      //SkillAssessment Reports
      if (skillAssessmentAssigneeState) {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              assigneeId: skillAssessmentAssigneeData,
              columnName: sorting,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            setskillassessmentEnquiryPageNo(0);
            handle403error(error);
          });
      } else {
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              columnName: sortState,
              direction: direction,
              page: Number(value - 1),
              size: Number(rows),
            },
          })
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            setskillassessmentEnquiryPageNo(0);
            handle403error(error);
          });
      }
    } else {
      //SkillAssessment Reports
      if (skillAssessmentAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                officeId: officeid,
                assigneeId: skillAssessmentAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            setskillassessmentEnquiryPageNo(0);
            handle403error(error);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            setskillassessmentEnquiryPageNo(0);
            handle403error(error);
          });
      }
    }
  };

  const getWorkflowReports = (value1) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      //Admission Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          console.log("Admission Response");
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setskillassessmentEnquiry(response.data.data.content);
          setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      //Admission Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassessmentEnquiry(response.data.data.content);
          setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [Admissiondatesrange, setAdmissiondatesrange] = useState();
  const [admissiondatevalue, setadmissiondatevalue] = useState("Click To Open");
  const handleApplyAdmission = (event, picker) => {
    setbackdropopen(true);
    setadmissiondatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setdatefilter(true);
    setpageno(0);
    console.log(event, picker);
    let dateMap = {
      createdFromDate: picker.startDate.format("YYYY-MM-DDThh:mm:ss"),
      createdToDate: picker.endDate.format("YYYY-MM-DDThh:mm:ss"),
    };
    setAdmissiondatesrange(dateMap);
    if (officeid === "all" || officeid == undefined) {
      //Admission Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      //Admission Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });
    }
  };

  const [Migrationdatesrange, setMigrationdatesrange] = useState();
  const [migrationdatevalue, setmigrationdatevalue] = useState("Click to Open");
  const handleApplyMigration = (event, picker) => {
    setbackdropopen(true);
    setmigrationdatefilter(true);
    setpageno(0);
    setmigrationdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let dateMap = {
      createdFromDate: picker.startDate.format("YYYY-MM-DDThh:mm:ss"),
      createdToDate: picker.endDate.format("YYYY-MM-DDThh:mm:ss"),
    };
    setMigrationdatesrange(dateMap);
    if (officeid === "all" || officeid == undefined) {
      //Migration Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });
    } else {
      //Migration Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });
    }
  };

  const [Insurancedatesrange, setInsurancedatesrange] = useState();
  const [insurancedatevalue, setinsurancedatevalue] = useState("Click to Open");
  const handleApplyInsurance = (event, picker) => {
    setbackdropopen(true);
    setinsurancedatefilter(true);
    setpageno(0);
    setinsurancedatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let dateMap = {
      createdFromDate: picker.startDate.format("YYYY-MM-DDThh:mm:ss"),
      createdToDate: picker.endDate.format("YYYY-MM-DDThh:mm:ss"),
    };
    setInsurancedatesrange(dateMap);
    if (officeid === "all" || officeid == undefined) {
      //Insurance Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      //Insurance Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const [SkillAssessmentdatesrange, setSkillAssessmentsrange] = useState();
  const [skillassessmentdatevalue, setskillassessmentdatevalue] =
    useState("Click to Open");
  const handleApplySkillAssessment = (event, picker) => {
    setbackdropopen(true);
    setskilldatefilter(true);
    setpageno(0);
    setskillassessmentdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let dateMap = {
      createdFromDate: picker.startDate.format("YYYY-MM-DDThh:mm:ss"),
      createdToDate: picker.endDate.format("YYYY-MM-DDThh:mm:ss"),
    };
    setSkillAssessmentsrange(dateMap);
    if (officeid === "all" || officeid == undefined) {
      //SkillAssessment Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setbackdropopen(false);
          setskillassessmentEnquiry(response.data.data.content);
          setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    } else {
      //SkillAssessment Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              officeId: Number(user.isw_available_Office[0].officeId),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setbackdropopen(false);
          setskillassessmentEnquiry(response.data.data.content);
          setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });
    }
  };

  const handleOffice = (event) => {
    setbackdropopen(true);
    console.log(event.target.value);
    setOfficeId(event.target.value);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    if (event.target.value === "all") {
      //Admission Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setskillassessmentEnquiry(response.data.data.content);
          setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      //Admission Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              officeId: Number(event.target.value),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              officeId: Number(event.target.value),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              officeId: Number(event.target.value),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .post(
          `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
          dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              officeId: Number(event.target.value),
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassessmentEnquiry(response.data.data.content);
          setskillassessmentEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const sortAdmission = (e) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }

    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }

    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      if (admissionAssigneeState) {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  assigneeId: admissionAssigneeData,
                  officeId: officeid,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("Admission Response");
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                assigneeId: admissionAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("Admission Response");
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      } else {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  officeId: officeid,
                  columnName: e.target.id,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("Admission Response");
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("Admission Response");
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      }
    } else {
      if (admissionAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                assigneeId: admissionAssigneeData,
                // officeId: Number(user.isw_available_Office[0].officeId),
                officeId: Number(officeid),
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                // officeId: Number(user.isw_available_Office[0].officeId),
                officeId: Number(officeid),
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      }
    }
  };

  const sortMigration = (e) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }

    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      if (migrationAssigneeState) {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Migration",
                  assigneeId: migrationAssigneeData,
                  officeId: officeid,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("Migration Response");
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                assigneeId: migrationAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("Migration Response");
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      } else {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Migration",
                  officeId: officeid,
                  columnName: e.target.id,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("Migration Response");
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("Migration Response");
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      }
    } else {
      if (migrationAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                // officeId: Number(user.isw_available_Office[0].officeId),
                officeId: Number(officeid),
                assigneeId: migrationAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                // officeId: Number(user.isw_available_Office[0].officeId),
                officeId: Number(officeid),
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      }
    }
  };

  const sortInsurance = (e) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }

    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      // insuranceAssigneeData
      if (insuranceAssigneeState) {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  assigneeId: insuranceAssigneeData,
                  officeId: officeid,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("Insurance Response");
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                assigneeId: insuranceAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("Insurance Response");
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      } else {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  officeId: officeid,
                  columnName: e.target.id,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("Insurance Response");
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("Insurance Response");
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      }
    } else {
      if (insuranceAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                assigneeId: insuranceAssigneeData,
                officeId: Number(officeid),
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                officeId: Number(officeid),
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      }
    }
  };

  const sortSkillAssessment = (e) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }

    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }

    if (user.isw_user_role === 1 || user.isw_user_role === 6) {
      if (skillAssessmentAssigneeState) {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  assigneeId: skillAssessmentAssigneeData,
                  officeId: officeid,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("SkillAssessment Response");
              setskillassessmentEnquiry(response.data.data.content);
              setskillassessmentEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                assigneeId: skillAssessmentAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("SkillAssessment Response");
              setskillassessmentEnquiry(response.data.data.content);
              setskillassessmentEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      } else {
        if (officeid != "all") {
          axios
            .post(
              `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
              dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  officeId: officeid,
                  columnName: e.target.id,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              console.log("SkillAssessment Response");
              setskillassessmentEnquiry(response.data.data.content);
              setskillassessmentEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        } else {
          axios
            .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then((response) => {
              console.log("SkillAssessment Response");
              setskillassessmentEnquiry(response.data.data.content);
              setskillassessmentEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
              setbackdropopen(false);
            })
            .catch((error) => {
              handle403error(error);
              setbackdropopen(false);
            });
        }
      }
    } else {
      if (skillAssessmentAssigneeState) {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                assigneeId: skillAssessmentAssigneeData,
                // officeId: Number(user.isw_available_Office[0].officeId),
                officeId: Number(officeid),
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      } else {
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                // officeId: Number(user.isw_available_Office[0].officeId),
                officeId: Number(officeid),
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            handle403error(error);
            setbackdropopen(false);
          });
      }
    }
  };

  const [admissionAssigneeState, setAdmissionAssigneeState] = useState();
  const [admissionAssigneeData, setAdmissionAssigneeData] = useState();
  const onSelectAdmission = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    // console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setAdmissionAssigneeData(consultant_obj.toString());
      setAdmissionAssigneeState(true);
      if (officeid === "all" || officeid == undefined) {
        //Admission Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              assigneeId: consultant_obj.toString(),
              columnName: sorting,
              direction: direction,
              page: pageno,
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setadmissionEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setadmissionEnquiry();
            setadmissionEnquirytotaluser(0);
            setadmissionEnquirycountno(0);
            setadmissionEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //Admission Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                officeId: officeid,
                columnName: sorting,
                assigneeId: consultant_obj.toString(),
                direction: direction,
                page: pageno,
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setadmissionEnquiryPageNo(response.data.data.totalPages);
          })
          .catch((error) => {
            setadmissionEnquiry();
            setadmissionEnquirytotaluser(0);
            setadmissionEnquirycountno(0);
            setadmissionEnquirynumberofelements(0);
            handle403error(error);
          });
      }
    } else {
      setAdmissionAssigneeState(false);
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        //Admission Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            setadmissionEnquiry();
            setadmissionEnquiryPageNo(0);
            setadmissionEnquirytotaluser(0);
            setadmissionEnquirycountno(0);
            setadmissionEnquirynumberofelements(0);
            setbackdropopen(false);
            handle403error(error);
          });
      } else {
        //Admission Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setadmissionEnquiry(response.data.data.content);
            setadmissionEnquiryPageNo(response.data.data.totalPages);
            setadmissionEnquirytotaluser(response.data.data.totalElements);
            setadmissionEnquirycountno(response.data.data.number);
            setadmissionEnquirynumberofelements(
              response.data.data.numberOfElements
            );
            setbackdropopen(false);
          })
          .catch((error) => {
            setadmissionEnquiry();
            setadmissionEnquiryPageNo(0);
            setadmissionEnquirytotaluser(0);
            setadmissionEnquirycountno(0);
            setadmissionEnquirynumberofelements(0);
            setbackdropopen(false);
            handle403error(error);
          });
      }
    }
  };

  const [migrationAssigneeState, setMigrationAssigneeState] = useState();
  const [migrationAssigneeData, setMigrationAssigneeData] = useState();
  const onSelectMigration = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    // console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setMigrationAssigneeData(consultant_obj.toString());
      setMigrationAssigneeState(true);
      if (officeid === "all" || officeid == undefined) {
        //Migration Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              assigneeId: consultant_obj.toString(),
              columnName: sorting,
              direction: direction,
              page: pageno,
              size: Number(rows),
            },
          })
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquiryPageNo(0);
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //Migration Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                officeId: officeid,
                columnName: sorting,
                assigneeId: consultant_obj.toString(),
                direction: direction,
                page: pageno,
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquiryPageNo(0);
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            handle403error(error);
          });
      }
    } else {
      setMigrationAssigneeState(false);
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        //Migration Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquiryPageNo(0);
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //Migration Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setmigrationEnquiry();
            setmigrationEnquiryPageNo(0);
            setmigrationEnquirytotaluser(0);
            setmigrationEnquirycountno(0);
            setmigrationEnquirynumberofelements(0);
            handle403error(error);
          });
      }
    }
  };

  const [insuranceAssigneeState, setInsuranceAssigneeState] = useState();
  const [insuranceAssigneeData, setInsuranceAssigneeData] = useState();
  const onSelectInsurance = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    // console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setInsuranceAssigneeData(consultant_obj.toString());
      setInsuranceAssigneeState(true);
      if (officeid === "all" || officeid == undefined) {
        //Insurance Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              assigneeId: consultant_obj.toString(),
              columnName: sorting,
              direction: direction,
              page: pageno,
              size: Number(rows),
            },
          })
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquiryPageNo(0);
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //Admission Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                officeId: officeid,
                columnName: sorting,
                assigneeId: consultant_obj.toString(),
                direction: direction,
                page: pageno,
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    } else {
      setInsuranceAssigneeState(false);
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        //Insurance Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquiryPageNo(0);
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //Insurance Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setinsuranceEnquiry(response.data.data.content);
            setinsuranceEnquiryPageNo(response.data.data.totalPages);
            setinsuranceEnquirytotaluser(response.data.data.totalElements);
            setinsuranceEnquirycountno(response.data.data.number);
            setinsuranceEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setinsuranceEnquiry();
            setinsuranceEnquiryPageNo(0);
            setinsuranceEnquirytotaluser(0);
            setinsuranceEnquirycountno(0);
            setinsuranceEnquirynumberofelements(0);
            handle403error(error);
          });
      }
    }
  };

  const [skillAssessmentAssigneeState, setSkillAssessmentAssigneeState] =
    useState();
  const [skillAssessmentAssigneeData, setSkillAssessmentAssigneeData] =
    useState();
  const onSelectSkillAssessment = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "clientName") {
      sorting = "client_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "courseName") {
      sorting = "course_name";
    } else if (sortState == "institutionName") {
      sorting = "institution_name";
    } else if (sortState == "currentStage") {
      sorting = "current_stage";
    } else if (sortState == "officeCode") {
      sorting = "office_code";
    } else if (sortState == "enquirySource") {
      sorting = "enquiry_source";
    } else {
      sorting = "enquiry_id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    // console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setSkillAssessmentAssigneeData(consultant_obj.toString());
      setSkillAssessmentAssigneeState(true);
      if (officeid === "all" || officeid == undefined) {
        //SkillAssessment Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              assigneeId: consultant_obj.toString(),
              columnName: sorting,
              direction: direction,
              page: pageno,
              size: Number(rows),
            },
          })
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquiryPageNo(0);
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //Admission Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                officeId: officeid,
                columnName: sorting,
                assigneeId: consultant_obj.toString(),
                direction: direction,
                page: pageno,
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquiryPageNo(0);
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            handle403error(error);
          });
      }
    } else {
      setSkillAssessmentAssigneeState(false);
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        //SkillAssessment Reports
        axios
          .post(`${base_url.api4}/getEnquiryReportByWorkflowType`, dateMap, {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          })
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquiryPageNo(0);
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            handle403error(error);
          });
      } else {
        //SkillAssessment Reports
        axios
          .post(
            `${base_url.api4}/getEnquiryReportByWorkflowTypeAndOfficeId`,
            dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                officeId: Number(user.isw_available_Office[0].officeId),
                columnName: sortState,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setskillassessmentEnquiry(response.data.data.content);
            setskillassessmentEnquiryPageNo(response.data.data.totalPages);
            setskillassessmentEnquirytotaluser(
              response.data.data.totalElements
            );
            setskillassessmentEnquirycountno(response.data.data.number);
            setskillassessmentEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            setskillassessmentEnquiry();
            setskillassessmentEnquiryPageNo(0);
            setskillassessmentEnquirytotaluser(0);
            setskillassessmentEnquirycountno(0);
            setskillassessmentEnquirynumberofelements(0);
            handle403error(error);
          });
      }
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss maindashboard_select card">
        <div>
          <Row>
            <Col md={6}>
              <div style={{ float: "left" }} className="maindashboard_select">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Branch
                </Typography>
                <FormControl
                  className={`${classes.formControl} office_dropdown`}
                >
                  <br />
                  <select
                    native
                    value={officeid}
                    id="demo-simple-select3"
                    style={{
                      height: "38px",
                      borderRadius: "5px",
                      borderColor: "#cccaca",
                    }}
                    onChange={(e) => handleOffice(e)}
                  >
                    {/* <option aria-label="None" value="" /> */}
                    {user.isw_user_role === 1 || user.isw_user_role === 6 ? (
                      <>
                        <option className="option_text" value="all">
                          Show All
                        </option>
                        {availableoffice?.map((p, index) => (
                          <option
                            className="option_text"
                            key={index}
                            value={p.officeId}
                          >{`${p.officeName}-${p.city}-${p.country}`}</option>
                        ))}
                      </>
                    ) : (
                      <>
                        {user.isw_available_Office?.map((p, index) => (
                          <option
                            key={index}
                            value={p.officeId}
                          >{`${p.country}-${p.city}-${p.officeName}`}</option>
                        ))}
                      </>
                    )}
                  </select>
                </FormControl>
              </div>
            </Col>
            {/* <Col md={6}>
              <div style={{ float: "left" }} className="maindashboard_select">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Assignee
                </Typography>
                <FormControl
                  className={`${classes.formControl} office_dropdown`}
                >
                  <br />
                  <Select
                    isMulti
                    name="assigneeId"
                    options={assigneeData}
                    // styles={styles}
                    // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onSelect}
                    required
                  />
                </FormControl>
              </div>
            </Col> */}
            <Col md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <form className="master">
                  <div>
                    <label style={{ fontWeight: "bold", marginLeft: "8px" }}>
                      Limit
                    </label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                        style={{
                          border: "2px solid #ced4da ",
                          paddingTop: "5px",
                        }}
                      >
                        <option className="option_text" value="10">
                          10
                        </option>
                        <option className="option_text" value="20">
                          20
                        </option>
                        <option className="option_text" value="50">
                          50
                        </option>
                        <option className="option_text" value="100">
                          100
                        </option>
                      </select>
                    </FormControl>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="responsive_client_application">
        <ul className="nav nav-tabs ">
          <li className="nav-item ">
            <a className="nav-link active" data-toggle="tab" href="#admission">
              Admission
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#migration">
              Migration
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#insurance">
              Insurance
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#skillassessment">
              Skill Assessment
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div id="admission" className="tab-pane active mt-2">
            <div>
              <div>
                <Row>
                  <Col md={6}>
                    <CardText className={classes.headtext}>
                      <strong>Admission Reports</strong>
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={3}></Col>
                      <Col md={3}>
                        {permissionData?.includes(409) ? (
                          <button
                            className="btn listing_addbutton"
                            onClick={exportPdfAdmission}
                          >
                            Export PDF
                          </button>
                        ) : null}
                      </Col>
                      <Col md={3}>
                        {admissionEnquiry != null ? (
                          <>
                            {permissionData?.includes(410) ? (
                              <CSVLink
                                filename="Admission-Enquiry-Reports.csv"
                                data={admissionEnquiry}
                                className="btn listing_addbutton"
                              // target="_blank"
                              >
                                Export CSV
                              </CSVLink>
                            ) : null}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={4}>
                      {/* ------------------------Date Range--------------------------- */}
                      <label className="area_rowslable">Date</label>
                      <DateRangePicker
                        onEvent={handleEvent}
                        onCallback={handleCallback}
                        onApply={handleApplyAdmission}
                      >
                        <p className="datepickerrangevaluetext">
                          {admissiondatevalue}
                        </p>
                      </DateRangePicker>
                      {datefilter ? (
                        <div style={{ float: "left" }}>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setdisplay("inline");
                              setdatefilter(false);
                              setadmissiondatevalue("Click to Open");
                              setdirection("DESC");
                              getWorkflowReports(0);
                            }}
                          >
                            Clear Search
                          </a>
                        </div>
                      ) : null}
                      {/* ------------------------Date Range--------------------------- */}
                    </Col>
                    <Col md={8}>
                      <label className="area_rowslable">Assignee</label>
                      <Select
                        isMulti
                        name="assigneeId"
                        options={assigneeData}
                        // styles={styles}
                        // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectAdmission}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={6}>
                      {admissionEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {admissionEnquirycountno * rows + 1} to{" "}
                          {admissionEnquirycountno * rows +
                            admissionEnquirynumberofelements}{" "}
                          of {admissionEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div className="displaytablerow">
                    <Table
                      id="myTable"
                      responsive
                      striped
                      onScroll={scrolltable}
                    >
                      <thead>
                        <tr>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            SN{" "}
                          </th>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            EID{" "}
                          </th>
                          <th>
                            CLIENT {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="clientName"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th>
                            CONSULTANT {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="consultantName"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "160px" }}>
                            COURSE {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="courseName"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "210px" }}>
                            PROVIDER {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="institutionName"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "160px" }}>
                            APPLICATION {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="currentStage"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "110px" }}>
                            BRANCH {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="officeCode"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "100px" }}>
                            INTAKE {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="intake"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "110px" }}>
                            SOURCE {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="enquirySource"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "120px" }}>
                            CREATED {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="created"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "120px" }}>
                            UPDATED {/* <UnfoldMoreIcon */}
                            <img
                              src={sort}
                              className="sorting"
                              id="updated"
                              onClick={sortAdmission}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {admissionEnquiry != null
                          ? admissionEnquiry &&
                          admissionEnquiry.map((p, index) => (
                            <tr key={p.enquiryId}>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {admissionEnquirycountno * rows + 1 + index}
                              </td>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {p.enquiryId}
                              </td>
                              <td>
                                {p.clientName}
                                <br />
                                {p.clientUsername}
                              </td>
                              <td>{p.consultantName}</td>
                              <td>{p.courseName}</td>
                              <td>{p.institutionName}</td>
                              <td>
                                {p.workflowName}
                                <br />
                                Current Stage: {p.currentStage}
                              </td>
                              <td>
                                {p.officeName}-{p.officeCode}
                              </td>
                              <td>{p.intake}</td>
                              <td>{p.enquirySource}</td>
                              <td>
                                {p.created
                                  ? moment(p.created.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                              <td>
                                {p.updated
                                  ? moment(p.updated.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </Table>
                  </div>

                  <Row>
                    <Col md={6}>
                      {admissionEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {admissionEnquirycountno * rows + 1} to{" "}
                          {admissionEnquirycountno * rows +
                            admissionEnquirynumberofelements}{" "}
                          of {admissionEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className={classes.root}>
                        <Pagination
                          count={admissionEnquiryPageNo}
                          onChange={handlepageAdmission}
                          shape="rounded"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div id="migration" className="tab-pane fade mt-2">
            <div>
              <div>
                <Row>
                  <Col md={6}>
                    <CardText className={classes.headtext}>
                      <strong>Migration Reports</strong>
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={3}></Col>
                      <Col md={3}>
                        {permissionData?.includes(411) ? (
                          <button
                            className="btn listing_addbutton"
                            onClick={exportPdfMigration}
                          >
                            Export PDF
                          </button>
                        ) : null}
                      </Col>
                      <Col md={3}>
                        {migrationEnquiry != null ? (
                          <>
                            {permissionData?.includes(412) ? (
                              <CSVLink
                                filename="Migration-Enquiry-Reports.csv"
                                data={migrationEnquiry}
                                className="btn listing_addbutton"
                              // target="_blank"
                              >
                                Export CSV
                              </CSVLink>
                            ) : null}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={4}>
                      {/* ------------------------Date Range--------------------------- */}
                      <label className="area_rowslable">Date</label>
                      <DateRangePicker
                        onEvent={handleEvent}
                        onCallback={handleCallback}
                        onApply={handleApplyMigration}
                      >
                        <p className="datepickerrangevaluetext">
                          {migrationdatevalue}
                        </p>
                      </DateRangePicker>
                      {migrationdatefilter ? (
                        <div style={{ float: "left" }}>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setdisplay("inline");
                              setmigrationdatefilter(false);
                              setmigrationdatevalue("Click to Open");
                              setdirection("DESC");
                              getWorkflowReports(0);
                            }}
                          >
                            Clear Search
                          </a>
                        </div>
                      ) : null}
                      {/* ------------------------Date Range--------------------------- */}
                    </Col>
                    <Col md={8}>
                      <label className="area_rowslable">Assignee</label>
                      <Select
                        isMulti
                        name="assigneeId"
                        options={assigneeData}
                        // styles={styles}
                        // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectMigration}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={6}>
                      {migrationEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {migrationEnquirycountno * rows + 1} to{" "}
                          {migrationEnquirycountno * rows +
                            migrationEnquirynumberofelements}{" "}
                          of {migrationEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div className="displaytablerow">
                    <Table responsive striped onScroll={scrolltable}>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            SN
                          </th>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            EID
                          </th>
                          <th>
                            Client{" "}
                            <UnfoldMoreIcon
                              id="clientName"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th>
                            Consultant{" "}
                            <UnfoldMoreIcon
                              id="consultantName"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "120px" }}>
                            Visa Type{" "}
                            <UnfoldMoreIcon
                              id="visaType"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "160px" }}>
                            Application{" "}
                            <UnfoldMoreIcon
                              id="currentStage"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "180px" }}>
                            Migration Provider{" "}
                            <UnfoldMoreIcon
                              id="institutionName"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "110px" }}>
                            Branch{" "}
                            <UnfoldMoreIcon
                              id="institutionName"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "100px" }}>
                            Intake{" "}
                            <UnfoldMoreIcon
                              id="officeCode"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "120px" }}>
                            Created{" "}
                            <UnfoldMoreIcon
                              id="created"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th style={{ minWidth: "120px" }}>
                            Updated{" "}
                            <UnfoldMoreIcon
                              id="updated"
                              onClick={sortMigration}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {migrationEnquiry != null
                          ? migrationEnquiry &&
                          migrationEnquiry.map((p, index) => (
                            <tr key={p.enquiryId}>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {migrationEnquirycountno * rows + 1 + index}
                              </td>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {p.enquiryId}
                              </td>
                              <td>
                                {p.clientName}
                                <br />
                                {p.clientUsername}
                                <br />
                                {p.clientPhoneNumber}
                              </td>
                              <td>{p.consultantName}</td>
                              <td>{p.visaType}</td>
                              <td>
                                {p.workflowName}
                                <br />
                                Current Stage: {p.currentStage}
                              </td>
                              <td>{p.institutionName}</td>
                              <td>{p.officeCode}</td>
                              <td>{p.intake}</td>
                              <td>
                                {p.created
                                  ? moment(p.created.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                              <td>
                                {p.updated
                                  ? moment(p.updated.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col md={6}>
                      {migrationEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {migrationEnquirycountno * rows + 1} to{" "}
                          {migrationEnquirycountno * rows +
                            migrationEnquirynumberofelements}{" "}
                          of {migrationEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className={classes.root}>
                        <Pagination
                          count={migrationEnquiryPageNo}
                          onChange={handlepageMigration}
                          shape="rounded"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div id="insurance" className="tab-pane fade mt-2">
            <div>
              <div>
                <Row>
                  <Col md={6}>
                    <CardText className={classes.headtext}>
                      <strong>Insurance Reports</strong>
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={3}></Col>
                      <Col md={3}>
                        {permissionData?.includes(413) ? (
                          <button
                            className="btn listing_addbutton"
                            onClick={exportPdfInsurance}
                          >
                            Export PDF
                          </button>
                        ) : null}
                      </Col>
                      <Col md={3}>
                        {insuranceEnquiry != null ? (
                          <>
                            {permissionData?.includes(414) ? (
                              <CSVLink
                                filename="Insurance-Enquiry-Reports.csv"
                                data={insuranceEnquiry}
                                className="btn listing_addbutton"
                              // target="_blank"
                              >
                                Export CSV
                              </CSVLink>
                            ) : null}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={4}>
                      {/* ------------------------Date Range--------------------------- */}
                      <label className="area_rowslable">Date</label>
                      <DateRangePicker
                        onEvent={handleEvent}
                        onCallback={handleCallback}
                        onApply={handleApplyInsurance}
                      >
                        <p className="datepickerrangevaluetext">
                          {insurancedatevalue}
                        </p>
                      </DateRangePicker>
                      <br />
                      {insurancedatefilter ? (
                        <div style={{ float: "left" }}>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setdisplay("inline");
                              setinsurancedatefilter(false);
                              setinsurancedatevalue("Click to Open");
                              setdirection("DESC");
                              getWorkflowReports(0);
                            }}
                          >
                            Clear Search
                          </a>
                        </div>
                      ) : null}
                      {/* ------------------------Date Range--------------------------- */}
                    </Col>
                    <Col md={8}>
                      <label className="area_rowslable">Assignee</label>
                      <Select
                        isMulti
                        name="assigneeId"
                        options={assigneeData}
                        // styles={styles}
                        // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectInsurance}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={6}>
                      {insuranceEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {insuranceEnquirycountno * rows + 1} to{" "}
                          {insuranceEnquirycountno * rows +
                            insuranceEnquirynumberofelements}{" "}
                          of {insuranceEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th style={{ minWidth: "50px", textAlign: "center" }}>
                          SN
                        </th>
                        <th style={{ minWidth: "50px", textAlign: "center" }}>
                          EID
                        </th>
                        <th>
                          Client{" "}
                          <UnfoldMoreIcon
                            id="clientName"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th>
                          Consultant{" "}
                          <UnfoldMoreIcon
                            id="consultantName"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "160px" }}>
                          Application{" "}
                          <UnfoldMoreIcon
                            id="currentStage"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "175px" }}>
                          Insurance Name{" "}
                          <UnfoldMoreIcon
                            id="institutionName"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "195px" }}>
                          Insurance Provider{" "}
                          <UnfoldMoreIcon
                            id="insuranceType"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "110px" }}>
                          Branch{" "}
                          <UnfoldMoreIcon
                            id="officeCode"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "100px" }}>
                          Intake{" "}
                          <UnfoldMoreIcon
                            id="intake"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "120px" }}>
                          Created{" "}
                          <UnfoldMoreIcon
                            id="created"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "120px" }}>
                          Updated{" "}
                          <UnfoldMoreIcon
                            id="updated"
                            onClick={sortInsurance}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {insuranceEnquiry != null
                        ? insuranceEnquiry &&
                        insuranceEnquiry.map((p, index) => (
                          <tr key={p.enquiryId}>
                            <td
                              style={{
                                minWidth: "50px",
                                textAlign: "center",
                              }}
                            >
                              {insuranceEnquirycountno * rows + 1 + index}
                            </td>
                            <td
                              style={{
                                minWidth: "50px",
                                textAlign: "center",
                              }}
                            >
                              {p.enquiryId}
                            </td>
                            <td>
                              {p.clientName}
                              <br />
                              {p.clientUsername}
                              <br />
                              {p.clientPhoneNumber}
                            </td>
                            <td>{p.consultantName}</td>
                            <td>
                              {p.workflowName}
                              <br />
                              Current Stage: {p.currentStage}
                            </td>
                            <td>{p.institutionName}</td>
                            <td>{p.insuranceType}</td>
                            <td>{p.officeCode}</td>
                            <td>{p.intake}</td>
                            <td>
                              {p.created
                                ? moment(p.created.split(".")[0]).format(
                                  "DD-MMM-YY"
                                )
                                : null}
                            </td>
                            <td>
                              {p.updated
                                ? moment(p.updated.split(".")[0]).format(
                                  "DD-MMM-YY"
                                )
                                : null}
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </Table>
                  <Row>
                    <Col md={6}>
                      {insuranceEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {insuranceEnquirycountno * rows + 1} to{" "}
                          {insuranceEnquirycountno * rows +
                            insuranceEnquirynumberofelements}{" "}
                          of {insuranceEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className={classes.root}>
                        <Pagination
                          count={insuranceEnquiryPageNo}
                          onChange={handlepageInsurance}
                          shape="rounded"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div id="skillassessment" className="tab-pane fade mt-2">
            <div>
              <div>
                <Row>
                  <Col md={6}>
                    <CardText className={classes.headtext}>
                      <strong>Skill Assessment Reports</strong>
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={3}></Col>
                      <Col md={3}>
                        {permissionData?.includes(415) ? (
                          <button
                            className="btn listing_addbutton"
                            onClick={exportPdfSkill}
                          >
                            Export PDF
                          </button>
                        ) : null}
                      </Col>
                      <Col md={3}>
                        {skillassementEnquiry != null ? (
                          <>
                            {permissionData?.includes(416) ? (
                              <CSVLink
                                filename="SkillAssement-Enquiry-Reports.csv"
                                data={skillassementEnquiry}
                                className="btn listing_addbutton"
                              // target="_blank"
                              >
                                Export CSV
                              </CSVLink>
                            ) : null}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={4}>
                      {/* ------------------------Date Range--------------------------- */}
                      <label className="area_rowslable">Date</label>
                      <DateRangePicker
                        onEvent={handleEvent}
                        onCallback={handleCallback}
                        onApply={handleApplySkillAssessment}
                      >
                        <p className="datepickerrangevaluetext">
                          {skillassessmentdatevalue}
                        </p>
                      </DateRangePicker>
                      {skilldatefilter ? (
                        <div style={{ float: "left" }}>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setdisplay("inline");
                              setskilldatefilter(false);
                              setskillassessmentdatevalue("Click to Open");
                              setdirection("DESC");
                              getWorkflowReports(0);
                            }}
                          >
                            Clear Search
                          </a>
                        </div>
                      ) : null}
                      {/* ------------------------Date Range--------------------------- */}
                    </Col>
                    <Col md={8}>
                      <label className="area_rowslable">Assignee</label>
                      <Select
                        isMulti
                        name="assigneeId"
                        options={assigneeData}
                        // styles={styles}
                        // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectSkillAssessment}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="newlisting_headertabdesign">
                  <Row>
                    <Col md={6}>
                      {skillassementEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {skillassessmentEnquirycountno * rows + 1} to{" "}
                          {skillassessmentEnquirycountno * rows +
                            skillassessmentEnquirynumberofelements}{" "}
                          of {skillassessmentEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th style={{ minWidth: "50px", textAlign: "center" }}>
                          SN
                        </th>
                        <th style={{ minWidth: "50px", textAlign: "center" }}>
                          EID
                        </th>
                        <th style={{ minWidth: "200px" }}>
                          Client{" "}
                          <UnfoldMoreIcon
                            id="clientName"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th>
                          Consultant{" "}
                          <UnfoldMoreIcon
                            id="consultantName"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "160px" }}>
                          Application{" "}
                          <UnfoldMoreIcon
                            id="currentStage"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "110px" }}>
                          Institution Name{" "}
                          <UnfoldMoreIcon
                            id="institutionName"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "110px" }}>
                          Branch{" "}
                          <UnfoldMoreIcon
                            id="officeCode"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "100px" }}>
                          Intake{" "}
                          <UnfoldMoreIcon
                            id="intake"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "120px" }}>
                          Created{" "}
                          <UnfoldMoreIcon
                            id="created"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                        <th style={{ minWidth: "120px" }}>
                          Updated{" "}
                          <UnfoldMoreIcon
                            id="updated"
                            onClick={sortSkillAssessment}
                            style={{ cursor: "pointer" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {skillassementEnquiry != null
                        ? skillassementEnquiry &&
                        skillassementEnquiry.map((p, index) => (
                          <tr key={p.enquiryId}>
                            <td
                              style={{
                                minWidth: "50px",
                                textAlign: "center",
                              }}
                            >
                              {skillassessmentEnquirycountno * rows +
                                1 +
                                index}
                            </td>
                            <td
                              style={{
                                minWidth: "50px",
                                textAlign: "center",
                              }}
                            >
                              {p.enquiryId}
                            </td>
                            <td>
                              {p.clientName}
                              <br />
                              {p.clientUsername}
                              <br />
                              {p.clientPhoneNumber}
                            </td>
                            <td>{p.consultantName}</td>
                            <td>
                              {p.workflowName}
                              <br />
                              Current Stage: {p.currentStage}
                            </td>
                            <td>{p.institutionName}</td>
                            <td>{p.officeCode}</td>
                            <td>{p.intake}</td>
                            <td>
                              {p.created
                                ? moment(p.created.split(".")[0]).format(
                                  "DD-MMM-YY"
                                )
                                : null}
                            </td>
                            <td>
                              {p.updated
                                ? moment(p.updated.split(".")[0]).format(
                                  "DD-MMM-YY"
                                )
                                : null}
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </Table>
                  <Row>
                    <Col md={6}>
                      {skillassementEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {skillassessmentEnquirycountno * rows + 1} to{" "}
                          {skillassessmentEnquirycountno * rows +
                            skillassessmentEnquirynumberofelements}{" "}
                          of {skillassessmentEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className={classes.root}>
                        <Pagination
                          count={skillassementEnquiryPageNo}
                          onChange={handlepageSkillAssessment}
                          shape="rounded"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ApplicationReports);
