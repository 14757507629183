import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, useParams, useHistory } from "react-router-dom";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import "../css/EnquiryWorkflowStepDetails.css";
import { Col, InputGroup, Row } from "reactstrap";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import EmailIcon from "@material-ui/icons/Email";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout } from "../features/userSlice";
import CheckIcon from "@material-ui/icons/Check";
import { useForm } from "react-hook-form";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DatePicker from "react-datepicker";
import {
  // editorConfiguration,
  customStyles,
  customStylesAttachment,
} from "../../Utilities/CSSUtilities";
import email from "../images/workload/email.svg";
import notes from "../images/workload/notes.svg";
import attachfile from "../images/workload/attach-file.svg";
import calender from "../images/workload/calendar.svg";
import { check1pointpermission } from "../Permission/permission";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.02)",
    // backgroundColor: "transparent",
    opacity: "0.7",
    color: "black",
  },
  backdrop2: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  btnclr: {
    color: "white",
    padding: "10px 15px",
    backgroundColor: "#17b978",
    borderRadius: "8px",
  },
  btnclr2: {
    color: "white",
    padding: "10px 15px",
    backgroundColor: "gray",
    borderRadius: "8px",
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();

  return <Tooltip arrow classes={classes2} {...props} />;
}

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "horizontalLine",
      "underline",
      "link",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "alignment",
      "|",
      // "imageUpload",
      "imageInsert",
      "blockQuote",
      "fontBackgroundColor",
      "fontColor",
      "insertTable",
      "fontSize",
      "fontFamily",
      // "htmlEmbed",
      // "mediaEmbed",
      "codeBlock",
      // "code",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:full",
      "imageStyle:side",
      "linkImage",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  licenseKey: "",
};

const EnquiryWorkflowStepDetails = (props) => {
  const isoDateString = new Date().toISOString();
  const datetimelocal = isoDateString.split(".")[0];
  let params = useParams();
  const user = useSelector(selectUser);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  // const selected_client_id = localStorage.getItem("selected_client_id");
  const selected_client_id =
    params.client_id || localStorage.getItem("selected_client_id");
  const { register, handleSubmit } = useForm();
  const [descriptionState, setdescriptionState] = useState(null);
  const [descriptionState2, setdescriptionState2] = useState(null);
  const [template, settemplate] = useState(null);
  const [log, setlogs] = useState();
  const [loadermodal, setloadermodal] = useState(true);
  const [radioattachmentvalue, setradioattachmentvalue] =
    React.useState("miscellaneous_doc");
  const [notestitle, setnotestitle] = useState();
  const [emailworkflowstepid, setemailworkflowstepid] = useState();
  const [filesizemore, setfilesizemore] = useState(false);
  const [selecteddate, setdate] = useState(null);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.workflowSteps.length > 0) {
      let chk = false;
      props.workflowSteps.forEach((element, index) => {
        if (props.workflowSteps[index - 1]?.status === 1) {
          getlogsbyworkflowstep(element.id);
          setExpanded(`panel${index + 1}`);
          chk = true;
        }
      });
      if (chk === false) {
        getlogsbyworkflowstep(props.workflowSteps[0].id);
        setExpanded("panel1");
      }
    }
    getPermissions();
  }, [props.workflowSteps]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {


    } else {
      history.push("/errorPage");
    }
  };

  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setradioattachmentvalue(event.target.value);
  };
  const handleChange = (panel, id) => (event, newExpanded) => {
    console.log('coming here');
    setbackdropopen(true);
    localStorage.setItem("selected_workflow_step_id", id);
    getlogsbyworkflowstep(id);
    setExpanded(newExpanded ? panel : false);
    setlogs(null);
  };

  const getlogsbyworkflowstep = (id) => {
    // console.log(id);
    axios
      .get(`${base_url.api2}/getLogsByEnquiryWorkflowStepsId`, {
        headers: {
          Authorization: jwtToken,
          enquiryWorkflowStepsId: Number(id),
        },
      })
      .then(
        (res) => {
          setbackdropopen(false);
          const log_obj = [];
          for (let i = 0; i < res.data.data.length; i++) {
            const editor_name = res.data.data[i].log.split(" - ")[1];
            const logData = res.data.data[i].log.split(" - ")[0];
            res.data.data[i].editorName = editor_name;
            res.data.data[i].log = logData;
            log_obj.push(res.data.data[i]);
          }
          // console.log(log_obj);
          // console.log(res.data.data);
          setlogs(log_obj);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
          setlogs([]);
        }
      );
  };
  var subtitle;
  const [noteid, setnoteid] = useState();
  const [workflowstepname, setworkflowstepname] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);

  const openModal3 = () => {
    setIsOpen3(true);
  };
  const openModal = (id, name) => {
    console.log(id);
    setnoteid(id);
    setworkflowstepname(name);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const [modalIsopen4, setIsOpen4] = useState(false);
  const closeModal = (id) => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    // localStorage.getItem('selected_workflow_step_id');
    // getlogsbyworkflowstep(selected_workflow_step_id);
  };

  const [selectedstepId, setselectedstepId] = useState(null);
  const [attachments, setattachments] = useState(null);
  const openModal2 = (id) => {
    localStorage.setItem("selected_workflow_step_id", id);
    // setradioattachmentvalue();
    // setnotes([]);
    console.log(id);
    setselectedstepId(id);
    // setnoteid(id);
    setIsOpen2(true);
    getattachment(id);
  };
  const [appointmentenquirystepid, setappointmentenquirystepid] = useState();
  const openmodalappointment = (id) => {
    setdate(null);
    setappointmentenquirystepid(id);
    setIsOpen4(true);
  };
  const attachment_obj3 = [];
  const [attachment_obj, setattachmentobj] = useState();
  const getattachment = (id) => {
    axios
      .get(`${base_url.api2}/getAttachmentsByEnquiryWorkflowStepsId`, {
        headers: {
          Authorization: jwtToken,
          enquiryWorkflowStepsId: Number(id),
        },
      })
      .then(
        (res) => {
          setattachments(res.data.data);
          props.getAttachments();
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const createLog = (id1, index) => {
    setbackdropopen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let createdOn = datenow;
    let enquiryId = Number(params.enquiry_id);
    let currentStageId =
      Number(props.workflowSteps[index + 1].id) ||
      Number(props.workflowSteps[index].id);
    // let enquiryId = Number(props.enquiryId);
    let enquiryWorkflowStepsId = Number(id1);
    let id = 0;
    let logs = "";
    let currentStage;
    let enquiryStatus = null;
    let nextStage;
    if (index + 1 < props.workflowSteps.length) {
      enquiryStatus = "Processing";
      currentStage = props.workflowSteps[index + 1].workflowStepsName;
      if (props.workflowSteps[`${index + 2}`]) {
        nextStage = props.workflowSteps[`${index + 2}`].workflowStepsName;
      } else {
        nextStage = "Steps Completed";
      }
      logs =
        props.workflowSteps[index].workflowStepsName +
        " to " +
        user.isw_user_name;
    } else {
      enquiryStatus = "Completed";
      currentStage = "Steps Completed";
      nextStage = "Steps Completed";
      logs = user.isw_user_name + " completed all steps";
    }

    // console.log("Logs", logs);

    let log = logs;
    let data = {
      createdOn,
      enquiryId,
      enquiryWorkflowStepsId,
      id,
      log,
    };
    // console.log(data);
    postLogs(data, currentStage, nextStage, enquiryStatus, currentStageId);
  };
  const postLogs = (
    data,
    currentStage,
    nextStage,
    enquiryStatus,
    currentStageId
  ) => {
    axios
      .post(`${base_url.api2}/createLog`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        if (res.data.status_code == 200) {
          // updateEnquiryStage(currentStage, enquiryStatus);
          updateStatus(
            data.enquiryWorkflowStepsId,
            currentStage,
            nextStage,
            enquiryStatus,
            currentStageId
          );
          getlogsbyworkflowstep(res.data.enquiryWorkflowStepsId);
          // toast.success("Success");
          // console.log(res.data.data);
        }
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const updateStatus = (
    id,
    currentStage,
    nextStage,
    enquiryStatus,
    currentStageId
  ) => {
    let status = 1;
    let data = {
      id,
      status,
    };
    let data2 = {
      enquiryId: params.enquiry_id,
      currentStage,
      nextStage,
      enquiryStatus,
      currentStageId,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data2, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        axios
          .post(`${base_url.api2}/updateEnquiryWorkflowStepsStatusById`, data, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            props.getEnquiry();
          })
          .catch((err) => {
            setbackdropopen(false);
            console.log(JSON.stringify(err.response.data));
          });
      })
      .catch((err) => {
        setbackdropopen(false);
        toast.error(JSON.stringify(err.response.data.message));
        handle403error(err);
      });
    axios
      .post(`${base_url.api2}/updateEnquiryWorkflowStepsStatusById`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        // toast.success('Success')
        props.getEnquiry();
        // props.getEnquiry(props.enquiryId);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err.response.data.message));
        handle403error(err);
      });
  };
  // Proforma templates
  const [proformadetails, setproformadetails] = useState();
  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log("Proforma res", res);
        setproformadetails(res.data.data);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        handle403error(err);
      });
  };
  const submit = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let enquiryId = Number(params.enquiry_id);
    let title = notestitle;
    let notes;
    if (descriptionState) {
      notes = descriptionState;
    } else {
      notes = descriptionState2;
    }
    let enquiryWorkflowStepsId = Number(noteid);
    let enquiryWorkflowStepsName = workflowstepname;
    let createdOn = datenow;
    let addedBy = isw_user_name + "( " + user.isw_user_email + " )";
    let id = 0;
    let data = {
      enquiryId,
      title,
      notes,
      enquiryWorkflowStepsId,
      enquiryWorkflowStepsName,
      id,
      createdOn,
      addedBy,
    };
    postNote(data);
  };
  const postNote = (data) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    // let logs2 = user.isw_user_name + " added Note " + data.title;
    let logs2 = "Added note - By " + user.isw_user_name;

    axios
      .post(`${base_url.api2}/createNotes`, data, {
        headers: {
          Authorization: jwtToken,
          log: logs2,
          enquiryId: Number(params.enquiry_id),
          // enquiryId: Number(props.enquiryId),
          createdOn: datenow,
        },
      })
      .then((res) => {
        toast.success("Success");
        props.getAllNotes(0);
        // props.getAllNotes(props.enquiryId);
        axios
          .get(`${base_url.api2}/getNotesByEnquiryWorkflowStepsId`, {
            headers: {
              Authorization: jwtToken,
              enquiryWorkflowStepsId: Number(noteid),
            },
          })
          .then(
            (res) => {
              console.log(res.data.data);
              // setnotes(res.data.data);
              setIsOpen(false);
              getlogsbyworkflowstep(noteid);
            },
            (error) => {
              handle403error(error);
            }
          );
      });
  };
  // console.log(notes);
  const [backdropopen, setbackdropopen] = useState(false);
  const [displayattachmentradio, setdisplayattachmentradio] = useState(false);
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const onSubmitAttachment = (data) => {
    if (filesizemore === true) {
      return false;
    }
    let fileToUpload = data.picture;
    let formData = new FormData();

    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append("stepfile", fileToUpload[i], fileToUpload[i].name);
      }
      postAttachment(formData, fileToUpload);
    } else {
      setattachmentdisplay(true);
    }
  };


  const postAttachment = (formData, fileToUpload) => {
    // console.log(fileToUpload);
    // let formData = new FormData();
    // let fileName = fileToUpload.name;
    // let logs3 = user.isw_user_name + " added Attachment " + fileName;
    // formData.append("file", fileToUpload, fileToUpload.name);

    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let logs3 = "Added attachment - By " + user.isw_user_name;
    setbackdropopen(true);

    if (fileToUpload.length) {
      if (radioattachmentvalue != undefined) {
        axios
          .post(`${base_url.api2}/uploadAttachment`, formData, {
            headers: {
              Authorization: jwtToken,
              log: logs3,
              documentType: radioattachmentvalue,
              enquiryId: params.enquiry_id,
              createdOn: datenow,
              clientId: selected_client_id,
              enquiryWorkflowStepsId: Number(selectedstepId),
              createdBy: isw_user_name + " (" + isw_user_email + ")",
            },
          })
          .then(
            (res) => {
              console.log(res.data.data);
              getattachment(res.data.data[0].enquiryWorkflowStepsId);
              getlogsbyworkflowstep(res.data.data[0].enquiryWorkflowStepsId);
              setIsOpen2(false);
              setbackdropopen(false);
              toast.success(res.data.message);
            },
            (error) => {
              setbackdropopen(false);
              handle403error(error);
              // toast.error(JSON.stringify(error.response.data.message));
            }
          );
      } else {
        console.log("select type");
        setdisplayattachmentradio(true);
      }
    }
  };

  const submit3 = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let date = datenow;
    let fromEmailId = user.isw_user_email;
    let id = 0;
    let toEmailId = e.target[0].value;
    let subject = e.target[1].value;
    let description = e.target[2].value;
    let mailType = "sent";
    const data = {
      date,
      fromEmailId,
      id,
      toEmailId,
      subject,
      mailType,
      description,
    };
    const data3 = {
      createdOn: datenow,
      enquiryId: params.enquiry_id,
      id: 0,
      enquiryWorkflowStepsId: emailworkflowstepid,
      log: `Added Email - By ${user.isw_user_name}`,
    };
    postEmail(data, data3);
  };
  const postEmail = (data, data3) => {
    axios.post(`${base_url.api4}/createEmail`, data).then(
      (res) => {
        console.log("Response from mail", res);

        axios
          .post(`${base_url.api2}/createLog`, data3, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        setIsOpen3(false);
        toast.success(res.data.message);
      },
      (error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      }
    );
  };
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const onSubmitAppointment = (e) => {
    let appointmentDate = moment(selecteddate).format("YYYY-MM-DDThh:mm:ss");
    let clientId = Number(selected_client_id);
    let clientName = params.client_name;
    let counsellingCreatedBy = Number(isw_adminId);
    let counselorName = isw_user_name;
    let id = 0;
    let notes = e.target[0].value;
    let enquiryId = Number(params.enquiry_id);
    let enquiryWorkflowStepId = Number(appointmentenquirystepid);
    // let logs2 = user.isw_user_name + " added Appointment " + notes;
    let logs2 = "Added appointment - By " + user.isw_user_name;
    let data = {
      appointmentDate,
      clientId,
      clientName,
      counsellingCreatedBy,
      enquiryWorkflowStepId,
      counselorName,
      id,
      notes,
      enquiryId,
    };
    axios
      .post(`${base_url.api4}/addAppointments`, data, {
        headers: {
          Authorization: jwtToken,
          log: logs2,
        },
      })
      .then((res) => {
        toast.success("Appointment Created");
        closeModal();
      })
      .catch((err) => {
        toast.error(JSON.stringify(err.response.data.message));
        handle403error(err);
      });
  };
  const chkfilesize = (e) => {
    if (e.target.files) {
      if (e.target.files.length) {
        let fileSize = 0;
        for (let i = 0; i < e.target.files.length; i++) {
          fileSize += e.target.files[i].size;
        }
        // console.log(fileSize);
        if (fileSize / 1048576 > 200) {
          setfilesizemore(true);
        } else {
          setfilesizemore(false);
        }
      }
    }
  };
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop2} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.workflowSteps !== null ? (
        props.workflowSteps.map((item, index) => (
          <div key={index + 1}>
            <Accordion
              // disabled={
              //   props.workflowSteps[index - 1]?.status === 0 ? true : false
              // }
              square
              expanded={expanded === `panel${index + 1}`}
              onChange={() => {
                if ((props.workflowSteps[index - 1]?.status != 0) && (item.status == 0)) {
                  handleChange(`panel${index + 1}`, item.id)
                }
              }}
            >
              <div className="accordion_radio">
                {/* {item.status === 1 ? (
                  <CheckIcon
                    className="positioning color"
                    fontSize="small"
                    style={{ marginLeft: "10px" }}
                  />
                ) : (
                  <FiberManualRecordIcon
                    className="positioning"
                    style={{ marginLeft: "10px" }}
                    fontSize="small"
                    color="primary"
                  />
                )} */}
                <AccordionSummary
                  className="accordion_radio2 dummy3_accordion"
                  aria-controls={`panel${index + 1}d-header`}
                  id={`panel${index + 1}d-header`}
                >
                  <div className="accordion_name">
                    <Typography>{item.workflowStepsName}</Typography>
                    <div className="accordion_namebutton">
                      {/* {(permissionData?.includes(36)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                      {check1pointpermission(
                        317,
                        permissionData,
                        rolepermission
                      ) ? (
                        <img src={email} title="Email" alt=""
                          style={{ margin: "1px" }}
                          onClick={() => {
                            if (props.workflowSteps[index - 1]?.status === 0) {
                              return false;
                            } else {
                              openModal3();
                              setemailworkflowstepid(item.id);
                            }
                          }}
                        />
                      ) : null}
                      {/* <img src={email} title="Email" alt=""
                          style={{ margin: "1px" }}
                        />} */}
                      <Modal
                        isOpen={modalIsOpen3}
                        style={customStyles}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                      >
                        <div>
                          <Typography variant="body1" color="primary">
                            Send Email
                          </Typography>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submit3(e);
                            }}
                          >
                            <div className="">
                              <Row>
                                <Col>
                                  <label>To</label>
                                  <input
                                    type="text"
                                    className="form-control underline-input"
                                    value={params.client_name}
                                    readOnly
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="creatadmin">
                              <Row>
                                <Col>
                                  <label>Subject</label>
                                  <input
                                    type="text"
                                    className="form-control underline-input"
                                    required
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="creatadmin">
                              <Row>
                                <Col>
                                  <label>Description</label>
                                  <textarea
                                    type="text"
                                    className="form-control underline-input"
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    required
                                  />
                                </Col>
                              </Row>
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "5px",
                              }}
                            >
                              <button
                                className="btn btn-secondary"
                                // onClick={closeModal}
                                onClick={() => setIsOpen3(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn listing_addbutton ml-1"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </Modal>
                      {/* {(permissionData?.includes(37)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                      {check1pointpermission(
                        318,
                        permissionData,
                        rolepermission
                      ) ? (
                        <img src={notes} title="Notes" alt=""
                          style={{ margin: "1px" }}
                          onClick={() => {
                            if (props.workflowSteps[index - 1]?.status === 0) {
                              return false;
                            } else {
                              setloadermodal(false);
                              getproforma();
                              openModal(item.id, item.workflowStepsName);
                            }
                          }}
                        />
                      ) : null}
                      {/* <img src={notes} title="Notes" alt=""
                          style={{ margin: "1px" }}
                        />} */}
                      {loadermodal === false ? (
                        <Backdrop className={classes.backdrop} open>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      ) : (
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <div>
                            <div>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  submit(e);
                                }}
                              >
                                <Row>
                                  <Col>
                                    <h4>Add Note</h4>
                                  </Col>
                                  <Col md={6}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        padding: "5px",
                                      }}
                                    >
                                      <button
                                        className="btn btn-secondary cancel_margin"
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn listing_addbutton ml-1"
                                        type="submit"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={9}>
                                    <label>Title</label>
                                    <br />
                                    <input
                                      type="text"
                                      value={notestitle}
                                      onChange={(e) =>
                                        setnotestitle(e.target.value)
                                      }
                                      className="form-control underline-input"
                                      placeholder="Title Here"
                                      style={{
                                        width: "100%",
                                        backgroundColor: "f5f5f9",
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    md={3}
                                    className="form-group notesformmgroup"
                                  >
                                    <label>Templates</label>
                                    <select
                                      onChange={(e) => settemplate(e.target.value)}
                                      style={{
                                        width: "100%",
                                        height: "40px",
                                        backgroundColor: "f5f5f9",
                                      }}
                                    >
                                      <option value="">- Select Template -</option>
                                      {proformadetails?.map((item, index) => (
                                        <option key={index} value={item.template}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>
                                </Row>
                                <label>Note</label>
                                {template === "null" ? (
                                  <CKEditor
                                    editor={Editor}
                                    config={editorConfiguration}
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    data="<p></p>"
                                    onReady={(editor) => {
                                      console.log(
                                        "Editor is ready to use!",
                                        editor
                                      );
                                      // setloadermodal(true);
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setdescriptionState2(data);
                                    }}
                                  />
                                ) : (
                                  <CKEditor
                                    editor={Editor}
                                    config={editorConfiguration}
                                    data={template}
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    onReady={(editor) => {
                                      console.log(
                                        "Editor is ready to use!",
                                        editor
                                      );
                                      // setloadermodal(true);
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setdescriptionState(data);
                                    }}
                                  />
                                )}
                              </form>
                            </div>
                          </div>
                        </Modal>
                      )}
                      {/* {(permissionData?.includes(40)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                      {check1pointpermission(
                        321,
                        permissionData,
                        rolepermission
                      ) ? (
                        <img src={attachfile} title="Attachment" alt=""
                          style={{ margin: "1px" }}
                          onClick={() => {
                            if (props.workflowSteps[index - 1]?.status === 0) {
                              return false;
                            } else {
                              setfilesizemore(false);
                              openModal2(item.id);
                            }
                          }}
                        />
                      ) : null}
                      {/*  <img src={attachfile} title="Attachment" alt=""
                          style={{ margin: "1px" }}
                         />} */}
                      <Modal
                        isOpen={modalIsOpen2}
                        onRequestClose={closeModal}
                        style={customStylesAttachment}
                        contentLabel="Example Modal"
                      >
                        <div>
                          <h4>Attachment</h4>
                          <hr />
                          <div>
                            <form
                              className="form-group dummymargin"
                              style={{ display: "flex", flexDirection: "column" }}
                              onChange={(e) => chkfilesize(e)}
                              onSubmit={handleSubmit(onSubmitAttachment)}
                            >
                              <div>
                                <label>File:</label>
                                <br />
                                <input
                                  ref={register}
                                  type="file"
                                  name="picture"
                                  accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf,.PDF, ."
                                  multiple
                                />
                                {attachmentdisplay && (
                                  <p className="errormessage">
                                    Attachment is Required
                                  </p>
                                )}
                                {filesizemore && (
                                  <p className="errormessage">
                                    File Size cannot be more than 200 mb
                                  </p>
                                )}
                              </div>
                              <hr />
                              <div className="dummy3_attachment_radio">
                                <label>File Type:</label>
                                <FormControl component="fieldset">
                                  <br />
                                  <RadioGroup
                                    aria-label="gender"
                                    name="attachmenttype"
                                    style={{ display: "flex" }}
                                    value={radioattachmentvalue}
                                    onChange={handleRadioChange}
                                  >
                                    <Row className="adminusers_row">
                                      <Col className="">
                                        <FormControlLabel
                                          value="coe"
                                          control={<Radio color="primary" />}
                                          label="COE"
                                        />
                                        <FormControlLabel
                                          value="offer_letter"
                                          control={<Radio color="primary" />}
                                          label="Offer Letter"
                                        />
                                        <FormControlLabel
                                          value="insurance_health_cover"
                                          control={<Radio color="primary" />}
                                          label="Insurance Health Cover"
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="adminusers_row">
                                      <Col>
                                        <FormControlLabel
                                          value="visa"
                                          control={<Radio color="primary" />}
                                          label="Visa"
                                        />
                                        <FormControlLabel
                                          value="miscellaneous_doc"
                                          control={<Radio color="primary" />}
                                          label="Miscellaneous"
                                        />
                                      </Col>
                                    </Row>
                                  </RadioGroup>
                                </FormControl>
                                {displayattachmentradio && (
                                  <p className="errormessage">
                                    Attachment Type is Required
                                  </p>
                                )}
                              </div>
                              <hr />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "5px",
                                }}
                              >
                                <button
                                  className="outline-button"
                                  onClick={closeModal}
                                  style={{ marginRight: "10px" }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="sent-button"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                            {/* <form class="mt-3">
                              <label for="fileUpload" class="form-label">File</label>
                              <input
                                ref={register}
                                type="file"
                                name="picture"
                                class="form-control"
                                id="fileUpload"
                                // accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf, .docx, ."
                                multiple
                              />
                              {attachmentdisplay && (
                                <p className="errormessage">
                                  Attachment is Required
                                </p>
                              )}
                              {filesizemore && (
                                <p className="errormessage">
                                  File Size cannot be more than 200 mb
                                </p>
                              )}
                              <br />
                              <label for="fileUpload" class="form-label">File Type
                              </label>
                              <div class="file-type-checkbox d-flex justify-content-between">
                                <span class="form-check checkbox-group">
                                  <input type="radio"
                                    // name="file-type"
                                    id="check1"
                                    aria-label="gender"
                                    name="attachmenttype"
                                    style={{ display: "flex" }}
                                    value={radioattachmentvalue}
                                    onChange={handleRadioChange} />
                                  <label class="form-check-label mx-2"
                                    for="check1"
                                    value="coe"
                                    //  control={<Radio color="primary" />}
                                    label="COE"> COE </label>
                                </span>
                                <span class="form-check checkbox-group" >
                                  <input
                                    type="radio"
                                    name="file-type"
                                    id="check2"
                                    value="offer_letter"
                                    // control={<Radio color="primary" />}
                                    label="Offer Letter"
                                  />
                                  <label class="form-check-label mx-2" for="check2" >
                                    Offer Letter
                                  </label>
                                </span>
                                <span class="form-check checkbox-group" >
                                  <input
                                    type="radio"
                                    name="file-type"
                                    id="check3"
                                    value="insurance_health_cover"
                                    control={<Radio color="primary" />}
                                    label="Insurance Health Cover"
                                  />
                                  <label class="form-check-label mx-2" for="check3" >
                                    Insurance Health Cover
                                  </label>
                                </span>
                                <span class="form-check checkbox-group" >
                                  <input type="radio"
                                    name="file-type"
                                    id="check4"
                                    value="visa"
                                    control={<Radio color="primary" />}
                                    label="Visa" />
                                  <label class="form-check-label mx-2" for="check4" >
                                    Visa
                                  </label>
                                </span>
                                <span class="form-check checkbox-group" >
                                  <input type="radio"
                                    name="file-type"
                                    id="check5"
                                    value="miscellaneous_doc"
                                    control={<Radio color="primary" />}
                                    label="Miscellaneous" />
                                  <label class="form-check-label mx-2" for="check5" >
                                    Miscellaneous
                                  </label>
                                </span>
                                {displayattachmentradio && (
                                  <p className="errormessage">
                                    Attachment Type is Required
                                  </p>
                                )}
                              </div>
                              {/* <hr /> *
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "5px",
                              }}
                            >
                              <button
                                className="outline-button"
                                onClick={closeModal}
                                style={{ marginRight: "10px" }}
                              >
                                Cancel
                              </button>
                              <button
                                className="sent-button"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </form> */}
                          </div>
                        </div>
                      </Modal>
                      {/* {(permissionData?.includes(44)) && (props.workflowSteps[index - 1]?.status === 0) ? ( */}
                      {check1pointpermission(
                        325,
                        permissionData,
                        rolepermission
                      ) ? (
                        <img src={calender} title="appointment" alt=""
                          style={{ margin: "1px" }}
                          onClick={() => {
                            if (props.workflowSteps[index - 1]?.status === 0) {
                              return false;
                            } else {
                              openmodalappointment(item.id);
                            }
                          }}
                        />
                      ) : null}
                      {/* // <img src={calender} title="appointment" alt=""
                    //   style={{ margin: "1px" }}
                    // />} */}
                      <Modal
                        isOpen={modalIsopen4}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div>
                          <h2>Create Appointment</h2>
                          <hr />
                          <div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                onSubmitAppointment(e);
                              }}
                            >
                              <label>Appointment Note</label>
                              <input type="text" className="form-control" />
                              <hr />
                              <label>Appointment Date</label>
                              <DatePicker
                                selected={
                                  selecteddate ? new Date(selecteddate) : null
                                }
                                onChange={(date) => setdate(date)}
                                minDate={new Date(datetimelocal)}
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={15}
                                dateFormat="Pp"
                              />
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "5px",
                                }}
                              >
                                <button
                                  className="btn btn-secondary"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn listing_addbutton ml-1"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Modal>
                      {(props.workflowSteps[index - 1]?.status != 0) && (item.status == 0) ? (
                        <>
                          {check1pointpermission(
                            316,
                            permissionData,
                            rolepermission
                          ) ? (
                            <button
                              variant="outlined"
                              color="primary"
                              onClick={() => createLog(item.id, index)}
                              className="step_complete_css"
                            // style={{ marginTop: "10px", marginBottom: "10px" }}
                            >
                              Step Completed
                            </button>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                </AccordionSummary>

              </div >
              <div className="accordiondetails">
                <div>
                  {log?.map((p, index) => (
                    <div key={index + 1} className="logs">
                      <div>
                        <Typography
                          className="log_item1"
                          color="primary"
                          variant="body1"
                          style={{ lineHeight: "2" }}
                        >
                          {p.log} -{" "}
                          <span style={{ fontSize: "10px" }}>
                            {p.editorName}
                          </span>
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption" className="log_item2">
                          <td>
                            {p.createdOn
                              ? moment(p.createdOn.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                          </td>
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Accordion >
          </div >
        ))
      ) : (
        <Typography>No WorkflowSteps Assigned</Typography>
      )}
      {
        props.workflowSteps?.length > 0 ? (
          <Row>
            <Col>
              <p style={{ marginTop: "15px" }}>
                {props.workflowSteps?.length} to {props.workflowSteps?.length} of{" "}
                {props.workflowSteps?.length} records
              </p>
            </Col>
          </Row>
        ) : null
      }
    </div >
  );
};

export default EnquiryWorkflowStepDetails;
