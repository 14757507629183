import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import callsaveauditapi from "./../../../services/auditservice";

const BranchDeleteModal = ({ p, officeId, toast, pageno, handle403error, getallOfficelist }) => {
  console.log(officeId);
  const [OfficeNa, setOfficeNa] = useState(p.officeName);
  const [deletemodal, setdeletemodal] = useState(false);
  const opendeletemodal = () => {
    setdeletemodal(true);
    console.log(p);
    localStorage.setItem('selected_OfficeName', OfficeNa);
  };
  const closedeletemodal = () => setdeletemodal(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const submitdelete = (officeId) => {
    deleteUser(officeId);
    closedeletemodal();
  };
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const selectedId = localStorage.getItem('selected_OfficeId');
  const selectedRame = localStorage.getItem('selected_OfficeName');
  const deleteUser = () => {
    axios
      .delete(`${base_url.api1}/deleteOfficeById`, {
        headers: {
          Authorization: jwtToken,
          officeId: officeId,
        },
      })
      .then(
        (response) => {
          console.log(response.data.message);

          let log = isw_user_name + " Deleted Branch " + selectedRame + ".";
          callsaveauditapi(log);
          setIsOpen(!modalIsOpen);
          toast.success(response.data.message);
          getallOfficelist(pageno);
          // window.location.reload(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  return (
    <>
      <button style={{ cursor: "pointer" }} onClick={opendeletemodal} className="role-deletebtn">
        Delete
      </button>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}> you sure you want to delete current record ?</h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn listing_addbutton ml-1"
            onClick={submitdelete}
          >
            Delete
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={closedeletemodal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BranchDeleteModal;
