import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { CardText, Col, Row, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast, ToastContainer } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { selectdashboard } from "../features/dashboardSlice";
import { useStyles } from "../../Utilities/CSSUtilities";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import sort from "./../images/sort.svg";
import { check1pointpermission } from "../Permission/permission";

function Audit() {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("createdAt");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");
  const [backdropopen, setbackdropopen] = useState(false);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const dashboardvalue = useSelector(selectdashboard);
  const [allAudit, setAllAudit] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allAudit]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    var available_resource = resource_obj.includes(45);
    if (check1pointpermission(1700, permission_obj, resource_obj)) {
      getAllAudit(pageno);
      dispatch(setdashboardcheck(45));
    } else {
      history.push("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllAudit(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getAllAudit = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api4}/getAllAudits`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setAllAudit(response.data.data.content);
          setbackdropopen(false);
          document.getElementById("myForm").reset();
          setsearch(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api4}/getAllAudits`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setAllAudit(response.data.data.content);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();

  const submitSearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api4}/searchAudit`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setAllAudit(response.data.data.content);
          setbackdropopen(false);
          setsearch(true);
        },
        (error) => {
          setbackdropopen(false);
          setAllAudit([]);
          handle403error(error);
        }
      );
  };

  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpageno(value - 1);
    axios
      .get(`${base_url.api4}/searchAudit`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: searchinput,
          columnName: sortState,
          page: Number(value - 1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setAllAudit(response.data.data.content);
          setbackdropopen(false);
          setsearch(true);
        },
        (error) => {
          setbackdropopen(false);
          setAllAudit(null);
          handle403error(error);
        }
      );
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.8rem", marginBottom: "-13px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Audit Logs</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span>
          <a href="#/audit" className="b-link active">
            Audit Logs
          </a>
        </div>
        {/* <a onClick={goback} className="back-btn">Back</a> */}
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
            <CardText
              className={classes.headtext}
              style={{ marginTop: "-10px" }}
            >
              <strong>Audit Logs</strong>
            </CardText>
          </Col> */}
          {/* <Col md={6}>
                {isw_user_role == 1 ? (
                  <Button
                    className={classes.createBtn}
                    color="primary"
                    onClick={createadminpage}
                  >
                    + Add
                  </Button>
                ) : null}
              </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={2}>
              <form className="master">
                <div style={{ marginTop: "-10px", width: "fit-content" }}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                      style={{
                        border: "2px solid #ced4da ",
                        paddingTop: "5px",
                        backgroundColor: "#F5F5F9",
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form>
              <br />
            </Col>
            <Col md={3}></Col>
            <Col md={4}></Col>
            <Col md={3}>
              <div>
                <div className="clearsearchclass">
                  <form
                    id="myForm"
                    className="d-flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitSearch(e);
                    }}
                  >
                    <input
                      className="form-control"
                      placeholder="Search.."
                      name="search"
                    />
                    <button className="btn listpagesearch_button" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                {searchdata ? (
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setpageno(0);
                      getAllAudit(0);
                    }}
                  >
                    Clear Search
                  </a>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              {allAudit?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
          </Row>
          {/* <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div> */}
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <table
              id="example"
              className="table workload card-table table-borderless table-responsive"
              style={{ width: "100%" }}
            >
              <thead className="s_sticky">
                <tr>
                  {/* <th>S.No</th> */}
                  <th style={{ minWidth: "180px" }}>
                    User{" "}
                    <img
                      src={sort}
                      className="sorting"
                      // className={display}
                      id="createdByName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    Ip Address{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="adminIp"
                      // className={display}
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    Created{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="createdAt"
                      // className={display}
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "200px" }}>
                    Log{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="log"
                      // className={display}
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {allAudit != null ? (
                  allAudit &&
                  allAudit.map((p, index) => (
                    <tr key={index}>
                      {/* <td>{index+1}</td> */}
                      <td>{p.createdByName}</td>
                      <td>{p.adminIp}</td>
                      <td>
                        {p.createdAt
                          ? moment(p.createdAt.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                        <br />
                        {p.createdAt
                          ? moment(p.createdAt.split(".")[0]).format(" hh:mm a")
                          : null}
                        {/* <br />
                          <br />
                          {p.createdAt
                            ? moment(p.createdAt).local().format("DD MMM YYYY HH:mm a")
                            : null} */}
                      </td>
                      <td>{p.log}</td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </table>
          </div>
          <Row>
            <Col md={6}>
              {allAudit?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(Audit);
