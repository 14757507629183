import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import DeleteModal from "../../Modal/Masters/DeleteModal";
import EditModal from "../../Modal/Masters/EditModal";

function LevelList(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const history = useHistory();
  const [backdropopen, setbackdropopen] = useState(false);
  const jwtToken = "Bearer " + user.isw_user_token;
  const [deleteuser, setDeleteuser] = useState(null);
  const [deleteuserName, setDeleteuserName] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("levelId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allLevelList, setAllLevelList] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allLevelList]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
   
    if (check4pointpermission(1548, 1552, permission_obj, resource_obj)) {
      //if (available_permission) {
      getalllevel(pageno);
      dispatch(setdashboardcheck(36));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getalllevel(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const handlesearch = (e, value) => {
    setbackdropopen(true);
    // setdisplay("display");
    setpageno(value - 1);
    console.log(value);
    axios
      .get(`${base_url.api1}/searchLevelByName`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: searchinput,
          columnName: "name",
          direction: "DESC",
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setsearch(true);
        setAllLevelList(res.data.data.content);
        setbackdropopen(false);

        // document.getElementById("myForm").reset();
      })
      .catch((err) => {
        setbackdropopen(false);
        setAllLevelList();
        handle403error(err);
      });
  };

  const getalllevel = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getAllLevels`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setAllLevelList(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setbackdropopen(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          setbackdropopen(false);
          setAllLevelList(null);
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api1}/getAllLevels`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          setbackdropopen(false);
          setAllLevelList(response.data.data.content);
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const deleteCurrentUser = (id, name) => {
    axios
      .delete(`${base_url.api1}/deleteLevelById`, {
        headers: {
          Authorization: jwtToken,
          id,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);

          let log = isw_user_name + " Deleted Level " + name + ".";
          callsaveauditapi(log);
          getalllevel(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [editLevelName, seteditLevelName] = useState();
  const [editstatus, seteditstatus] = useState();
  const [isclosemodal, setisclosemodal] = useState(true);

  const editLevel = (id, name) => {
    setisclosemodal(false);
    let data = {
      levelId: id,
      name: editLevelName.trim(),
      status: editstatus,
    };
    axios
      .post(`${base_url.api1}/updateLevelById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success("Success");
          setisclosemodal(true);

          let log = isw_user_name + " Edited Level " + name + ".";
          callsaveauditapi(log);
          getalllevel(pageno);
        },
        (error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.message));
        }
      );
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = (elem) => {
    console.log(elem.target.id);
    setIsOpen2(true);
  };

  const createLevel = (data3) => {
    // let name = e.target[0].value.trim();
    // let status = Number(e.target[1].value);
    let name = data3.name.trim();
    let status = Number(data3.status);
    let levelId = 0;
    let data = {
      name,
      status,
      levelId,
    };
    console.log(data);
    axios
      .post(`${base_url.api1}/saveLevel`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          toast.success("Success");
          getalllevel(pageno);

          let log =
            isw_user_name +
            " Created Level " +
            "'" +
            response.data.data.name +
            "'.";
          callsaveauditapi(log);
          setIsOpen2(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const closeModal = () => {
    setIsOpen2(false);
  };

  const submitrows = (e) => {
    getalllevel(0);
  };

  const openModal3 = (elem, p) => {
    if (p.status === 1) {
      let data = {
        levelId: p.levelId,
        status: Number(0),
      };
      console.log(data);
      axios
        .post(`${base_url.api1}/updateLevelById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            toast.success(p.name + " Status: Inactive");
            getalllevel(pageno);

            let log =
              isw_user_name + " Made Status of " + p.name + " Inactive.";
            callsaveauditapi(log);
          },
          (error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          }
        );
    } else {
      console.log("called active");
      let data = {
        levelId: p.levelId,
        status: Number(1),
      };
      console.log(data);
      axios
        .post(`${base_url.api1}/updateLevelById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            toast.success(p.name + " Status: Active");
            getalllevel(pageno);

            let log = isw_user_name + " Made Status of " + p.name + " Active.";
            callsaveauditapi(log);
          },
          (error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          }
        );
    }
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const submitsearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api1}/searchLevelByName`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: "name",
          direction: "DESC",
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setsearch(true);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setAllLevelList(res.data.data.content);
        setbackdropopen(false);
        // document.getElementById("myForm").reset();
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
        setAllLevelList();
      });
  };
  const searchbystatus = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const value = optionElement.getAttribute("value");
    if (value === "all") {
      getalllevel(pageno);
    } else {
      axios
        .get(`${base_url.api1}/getLevelByStatus`, {
          headers: {
            Authorization: jwtToken,
            status: Number(value),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setAllLevelList(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Level</h3>
          <a href="#/" class="b-link">Home</a>
          -
          <a class="b-link">Settings</a>
          -
          <a class="b-link active">Manage Level</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1549, permissionData, rolepermission) ? (
          <a className="sent-button" onClick={openModal2}>
            {/* <AddIcon className="listing_addbutton_i" />  */}
            Add
          </a>
        ) : null}
        <Modal
          isOpen={modalIsOpen2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>
            <strong>Level</strong>
          </h2>
          {/* <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      createLevel(e);
                    }}
                  > */}
          <form id="myForm" onSubmit={handleSubmit(createLevel)}>
            <div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label>
                      Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="name"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <p className="errormessage">Level Name is Required</p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      name="status"
                      ref={register({ required: true })}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Level Status is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <Row className="justify-content-end mt-2">
                <Button color="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <button type="submit" className="btn listing_addbutton ml-1">
                  Submit
                </button>
              </Row>
              {/* <Button
                        color="primary"
                        type="submit"
                        style={{ marginTop: "20px" }}
                      >
                        Submit
                      </Button> */}
            </div>
          </form>
        </Modal>
      </div>
      <div>
        <Row>
          {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Manage Level</strong>
              </CardText>
            </Col> */}
          {/* <Col md={6} className="d-flex justify-content-end">
            {permissionData?.includes(128) ? (
              <button className="btn listing_addbutton" onClick={openModal2}>
                <AddIcon className="listing_addbutton_i" /> Add
              </button>
            ) : null}
            <Modal
              isOpen={modalIsOpen2}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h2>
                <strong>Level</strong>
              </h2>
               <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      createLevel(e);
                    }}
                  > 
              <form id="myForm" onSubmit={handleSubmit(createLevel)}>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={12}>
                        <label>
                          Name<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          name="name"
                          ref={register({ required: true })}
                        />
                        {errors.name && (
                          <p className="errormessage">
                            Level Name is Required
                          </p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                          Status<span style={{ color: "red" }}>*</span>:
                        </label>
                        <select
                          className={classes.selectdrop}
                          name="status"
                          ref={register({ required: true })}
                        >
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                        {errors.status && (
                          <p className="errormessage">
                            Level Status is Required
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <Row className="justify-content-end mt-2">
                    <Button color="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      className="btn listing_addbutton ml-1"
                    >
                      Submit
                    </button>
                  </Row>
                   <Button
                        color="primary"
                        type="submit"
                        style={{ marginTop: "20px" }}
                      >
                        Submit
                      </Button> 
                </div>
              </form>
            </Modal>
          </Col> */}
        </Row>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              <Row>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <div style={{ marginTop: "-10px",marginRight:"20px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className="s_client_limit"
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
                <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
                  <label className="area_rowslable">Status</label>

                  <select
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={searchbystatus}
                  >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </Row>
            </Col>
            <Col md={3}>{null}</Col>
            <Col md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitsearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getalllevel(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allLevelList?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {/* {permissionData?.includes(128) ? (
                <button className="btn btn-success" style={{ float: "right", height: "40px" }} onClick={openModal2}>
                   <AddIcon className="listing_addbutton_i" />
                  Add
                </button>
              ) : null}
              <Modal
                isOpen={modalIsOpen2}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <h2>
                  <strong>Level</strong>
                </h2>
                <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      createLevel(e);
                    }}
                  > 
                <form id="myForm" onSubmit={handleSubmit(createLevel)}>
                  <div>
                    <div className="form-group creatadmin">
                      <Row>
                        <Col md={12}>
                          <label>
                            Name<span style={{ color: "red" }}>*</span>:
                          </label>
                          <input
                            type="text"
                            className="form-control underline-input"
                            name="name"
                            ref={register({ required: true })}
                          />
                          {errors.name && (
                            <p className="errormessage">
                              Level Name is Required
                            </p>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col md={12}>
                          <label>
                            Status<span style={{ color: "red" }}>*</span>:
                          </label>
                          <select
                            className={classes.selectdrop}
                            name="status"
                            ref={register({ required: true })}
                          >
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          {errors.status && (
                            <p className="errormessage">
                              Level Status is Required
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <Row className="justify-content-end mt-2">
                      <Button color="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        className="btn listing_addbutton ml-1"
                      >
                        Submit
                      </button>
                    </Row>
                     <Button
                        color="primary"
                        type="submit"
                        style={{ marginTop: "20px" }}
                      >
                        Submit
                      </Button>
                  </div>
                </form>
              </Modal> */}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th>
                    NAME
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                    />
                  </th>
                  <th style={{ textAlign: "center", width: "175px" }}>
                    STATUS
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                    />
                  </th>
                  {check4pointpermission(
                    1550,
                    1551,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ textAlign: "center", width: "175px" }}>
                      ACTION
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allLevelList != null ? (
                  allLevelList &&
                  allLevelList?.map((p, index) => (
                    <tr key={p.levelId}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td>{p.name}</td>
                      <td style={{ textAlign: "center", width: "175px" }}>
                      {check1pointpermission(
                          1552,
                          permissionData,
                          rolepermission
                        ) ? (
                          <span
                            style={{ cursor: "pointer" }}
                            id={p.levelId}
                            onClick={(e) => {
                              openModal3(e, p);
                            }}
                          >
                            {p.status == "1" ? "Active" : "Inactive"}
                          </span>
                        ) : (
                          <>{p.status == "1" ? "Active" : "Inactive"}</>
                        )}
                      </td>
                      {check4pointpermission(
                          1550,
                          1551,
                          permissionData,
                          rolepermission
                        ) ? (
                        <td
                          id={p.levelId}
                          style={{ textAlign: "center", width: "175px" }}
                        >
                         {check1pointpermission(
                            1550,
                            permissionData,
                            rolepermission
                          ) ? (
                            <EditModal
                              header={"Level"}
                              editLevel={editLevel}
                              editname={editLevelName}
                              setname={seteditLevelName}
                              editstatus={editstatus}
                              setstatus={seteditstatus}
                              classes={classes}
                              name={p.name}
                              id={p.levelId}
                              status={p.status}
                              isclosemodal={isclosemodal}
                            />
                          ) : null}
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          {check1pointpermission(
                            1551,
                            permissionData,
                            rolepermission
                          ) ? (
                            <DeleteModal
                              deleteCurrentUser={deleteCurrentUser}
                              name={p.name}
                              id={p.levelId}
                            />
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <Row>
              <Col md={6}>
                <div>
                  <form className="master">
                    <div style={{ marginTop: "-10px" }}>
                      <label className="area_rowslable"></label>
                      <br />
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      {allLevelList?.length ? (
                        <div
                          className="s_spanPage"
                        >
                          {countnumber * rows + 1} to{" "}
                          {countnumber * rows + numberofElements} of {totalUser} records
                        </div>
                      ) : (
                        <span className="s_spanPage">No Record Found
                        </span>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  {searchdata ? (
                    <Pagination
                      count={getpageno}
                      onChange={handlesearch}
                      shape="rounded"
                    />
                  ) : (
                    <Pagination
                      count={getpageno}
                      onChange={handlepage}
                      shape="rounded"
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(LevelList);