import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import base_url from "./../api/bootapi";



const Forgot = () => {

    const [forgotUser, setForgotUser] = useState(false);
    const forgotPassword = (e) => {
      // console.log(e.target[0].value);
      axios
        .get(`${base_url.api2}/forgotAdminPassword`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            email: e.target[0].value.trim(),
          },
        })
        .then(
          (response) => {
            toast.success("Reset Password Link has been sent to your Email Id");
          },
          (error) => {
            setForgotUser(true);
            // toast.error("User Does Not Exist");
            // setIsOpen(false);
          }
        );
    };

  return (
    <div class="login-page forget-pw-page d-flex justify-content-center align-items-center">
    <div class="form-container">
        <form action="" class="login-form"               
            onSubmit={(e) => {
                e.preventDefault();
                forgotPassword(e);
              }}>
            <div class="form-head">
                <h3 class="form-title">Forgot Password</h3>
                <p class="form-desc">Please enter the credential below to recovery your password.</p>
            </div>

            <div class="form-group">
                <div class="input-grp">
                    <i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_342_113)">
                                <path d="M14.9318 12.0922L21 15.9285V8.094L14.9318 12.0922Z" fill="#17B978" />
                                <path d="M3 8.094V15.9285L9.06825 12.0922L3 8.094Z" fill="#17B978" />
                                <path
                                    d="M19.8749 5.8125H4.12494C3.56357 5.8125 3.11807 6.231 3.03369 6.76988L11.9999 12.6773L20.9662 6.76988C20.8818 6.231 20.4363 5.8125 19.8749 5.8125Z"
                                    fill="#17B978" />
                                <path
                                    d="M13.9013 12.7717L12.3094 13.8202C12.2149 13.8821 12.108 13.9125 12 13.9125C11.892 13.9125 11.7851 13.8821 11.6906 13.8202L10.0988 12.7706L3.03601 17.238C3.12264 17.7724 3.56589 18.1875 4.12501 18.1875H19.875C20.4341 18.1875 20.8774 17.7724 20.964 17.238L13.9013 12.7717Z"
                                    fill="#17B978" />
                            </g>
                            <defs>
                                <clipPath id="clip0_342_113">
                                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </i>
                    <input type="text" class="form-control" placeholder="Username or Email"/>
                </div>
            </div>

            <div class="form-group button-container">
                <button type="submit" class="btn login-btn">Submit</button>
            </div>

            <div class="text-center">
                <a href="#/" class="forget-password-link">Back To Login</a>
            </div>
        </form>
    </div>
</div>
  )
}

export default Forgot