import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, CardBody, Table } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../../Utilities/CSSUtilities";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { logout } from "../features/userSlice";

const OfficeClientDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [rows, setrows] = React.useState(20);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [user_lists, setUserList] = useState(null);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [backdrop, setbackdrop] = useState(false);
  const dispatch = useDispatch();
  const [paginationpageno, setpaginationpageno] = useState(1);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdrop(true);
    getClients();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [user_lists]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const handlepage = (event, value) => {
    setpaginationpageno(value);
    setpageno(value - 1);
    getallUserlist(value - 1);
  };

  const getClients = () => {
    if (localStorage.getItem("officeClientPageNo")) {
      let currentPage = localStorage.getItem("officeClientPageNo");
      setpageno(currentPage);
      setpaginationpageno(Number(currentPage) + 1);
      getallUserlist(currentPage);
    } else {
      setpageno(0);
      setpaginationpageno(1);
      getallUserlist(0);
    }
  };
  const getallUserlist = (value1) => {
    axios
      .get(`${base_url.api3}/getClientByOfficeId`, {
        headers: {
          Authorization: jwtToken,
          officeId: params.branch_id,
          columnName: "addedOn",
          direction: "DESC",
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setUserList(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdrop(false);
        },
        (error) => {
          setbackdrop(false);
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };
  const goback = () => {
    history.push("/office");
  };

  const editClient = (elem) => {
    var client_username = elem.target.id;
    // props.history.replace({ pathname: '/edit-Client/'+client_username })
    history.push("/edit-Client/" + client_username);
  };
  return (
    <div>
      <hr />
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        <Row>
          <Col md={6}>
            <h5>
              <strong>Branch:- {props.officeName}</strong>
            </h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button className="sent-button" onClick={goback}>
              List
            </button>
          </Col>
        </Row>
        <br />
        <div className="above-scroller" onScroll={scrolldiv}>
          <div className="scroller"></div>
        </div>
        <div className="displaytablerow">
          <Table
            className=" workload card-table table-borderless"
            responsive striped onScroll={scrolltable}>
            <thead className="s_sticky">
              <th style={{ minWidth: "50px", textAlign: "center" }}>SN</th>
              <th style={{ minWidth: "100px" }}>NAME</th>
              <th style={{ minWidth: "120px" }}>ADDRESS</th>
              <th style={{ minWidth: "120px" }}>COUNTRY</th>
              <th style={{ minWidth: "50px" }}>STATUS</th>
              <th style={{ minWidth: "120px" }}>VISA TYPE</th>
              <th style={{ minWidth: "120px" }}>CREATED</th>
            </thead>
            <tbody>
              {user_lists?.map((p, index) => (
                <tr key={index + 1}>
                  <td style={{ minWidth: "50px", textAlign: "center" }}>
                    {index + 1}
                  </td>
                  <td>
                    <a
                      id={p.username}
                      onClick={(e) => editClient(e, p)}
                      href={`#/edit-client/` + p.username}
                      title="Edit"
                    >
                      {p.firstName + " " + p.lastName}
                    </a>
                    <br />
                    {p.email}
                  </td>
                  <td>
                    {p.state}
                    <br />
                    PostCode:- {p.pincode}
                  </td>
                  <td>{p.country}</td>
                  {p.status === 1 ? <td>Active</td> : <td>InActive</td>}
                  <td>{p.visaType}</td>
                  <td>
                    {p.addedOn
                      ? moment(p.addedOn.split(".")[0]).format("DD-MMM-YY")
                      : null}
                    {/* <br />
                        {p.addedOn
                          ? moment(p.addedOn.split(".")[0]).format("hh.mm a")
                          : null} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <hr />
      <Row>
        <Col md={6}>
          {user_lists?.length ? (
            <p style={{ marginTop: "15px" }}>
              {countnumber * rows + 1} to{" "}
              {countnumber * rows + numberofElements} of {totalUser} records
            </p>
          ) : (
            <p className="formfooterrecordstyle">No Record Found</p>
          )}
        </Col>
        <Col md={6}>
          <div className={classes.root}>
            <Pagination
              page={paginationpageno}
              count={getpageno}
              onChange={handlepage}
              shape="rounded"
            />
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default OfficeClientDetail;
