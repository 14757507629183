import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
  Label,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { toast, ToastContainer } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import {
  selectUser,
  setdashboardcheck,
  logout,
  gettabvalue,
  getworkflowType,
} from "./../../features/userSlice";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import callsaveauditapi from "./../../../services/auditservice";
import autoTable from "jspdf-autotable";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import Select from "react-select";
import { CountryDropdown } from "react-country-region-selector";
import { checkreportpermission, check4pointpermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";
import ArchiveModal from "./../../Modal/Masters/ArchiveModal";
import ClientEmailModal from "./../../Modal/ClientEmailModal"
import ClientViewMoreModal from "./../../Modal/Masters/ClientViewMoreModal";

function ClientReportsNew(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [backdropopen, setbackdropopen] = useState(false);
  const [client_admin_users, setClientDetails] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("id");
  const [direction, setDirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [infinite, setinfinite] = React.useState(1000000);
  const [totalUser, settotalUser] = useState();
  const [remarkcontent, setremarkcontent] = useState();
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [datefilter, setdatefilter] = useState();
  const [statusstate, setstatusstate] = useState(1);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState();
  const [inputtype, setinputtype] = useState("password");
  const [selectedclient, setselectedclient] = useState();
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const [clientstatus, setclientstatus] = useState();
  const [clientstatususername, setclientstatususername] = useState();

  const submitNewPassword = (e) => {
    let username = e.target[0].value;
    let password = chkPassState.trim();
    let data = {
      username,
      password,
    };
    axios
      .post(`${base_url.api3}/updateClientPasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (res) => {
          console.log(res.data.data);
          toast.success(res.data.message);
          let logs =
            isw_user_name +
            " Updated password of Client " +
            res.data.data.email +
            ".";
          callsaveauditapi(logs);

          setIsOpen2(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [client_admin_users]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [chkPassState, setchkPassState] = useState();
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    setbackdropopen(true);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (intakeStatus) {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              country: country1,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              console.log(response.data.data);
              setDataForDownload(response.data.data);
              setDownloadReady(true);
              setbackdropopen(false);
            },
            (error) => {
              setDataForDownload();
              setDownloadReady(true);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              console.log(response.data.data);
              setDataForDownload(response.data.data);
              setDownloadReady(true);
              setbackdropopen(false);
            },
            (error) => {
              setDataForDownload();
              setDownloadReady(true);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    } else {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              country: country1,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              console.log(response.data.data);
              setDataForDownload(response.data.data);
              setDownloadReady(true);
              setbackdropopen(false);
            },
            (error) => {
              setDataForDownload();
              setDownloadReady(true);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              console.log(response.data.data);
              setDataForDownload(response.data.data);
              setDownloadReady(true);
              setbackdropopen(false);
            },
            (error) => {
              setDataForDownload();
              setDownloadReady(true);
              handle403error(error);
            }
          );
      }
    }
  }


  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(1011, permission_obj, resource_obj)) {
      //if (available_permission){
      dispatch(setdashboardcheck(59));
      getPdfData(statusstate);
      AllClientDetails(pageno, statusstate);
      getConsltant();
      getIntake();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };
  const [getpageno, setgetpageno] = useState();
  const handlePage = (event, value) => {
    setpageno(value - 1);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (assigneeState) {
      if (intakeStatus) {
        if (countryStatus) {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                intake: intakeData,
                country: country1,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(response.data.data.content);
                setSearchStatus(false);
                setdatefilter(false);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        } else {
          console.log("intake only");
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                intake: intakeData,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(response.data.data.content);
                setSearchStatus(false);
                setdatefilter(false);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        }
      } else {
        if (countryStatus) {
          console.log("country only");
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                country: country1,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(response.data.data.content);
                setSearchStatus(false);
                setdatefilter(false);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        } else {
          console.log("no intake & country");
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(response.data.data.content);
                setSearchStatus(false);
                setdatefilter(false);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        }
      }
    } else {
      console.log("no Filter");
      AllClientDetails(value - 1, statusstate);
    }
  };

  const [allofficeid, setallofficeid] = useState();
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [userOfficeId, setuserOfficeId] = useState(
    user.isw_user_role === 1 || user.isw_user_role === 6
      ? "all"
      : user?.isw_available_Office[0].officeId
  );

  const handleChangeOffice = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    setuserOfficeId(
      optionElementName === "all"
        ? optionElementName
        : Number(optionElementName)
    );
    setpaginationpageno(1);
    if (optionElementName === "all") {
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        axios
          .get(`${base_url.api3}/searchClientByAllFilter`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              direction: direction,
              page: 0,
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              setClientDetails(response.data.data.content);
              setSearchStatus(false);
              setbackdropopen(false);
              setdatefilter(false);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setgetpageno(response.data.data.totalPages);
              document.getElementById("myForm").reset();
            },
            (error) => {
              setbackdropopen(false);
              setClientDetails(null);
              handle403error(error);
            }
          );
      } else {
        const office_obj = [];
        for (let i = 0; i < user?.isw_available_Office?.length; i++) {
          const element = user?.isw_available_Office?.[i];
          console.log(element);
          office_obj.push(element);
        }
        //globalgetallenquiry(pageno);
        let office_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
        console.log(office_id);
        setallofficeid(office_id);
        console.log("hii if");
        axios
          .get(`${base_url.api3}/searchClientByAllFilter`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              officeId: office_id,
              columnName: sortState,
              direction: direction,
              page: 0,
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              setClientDetails(response.data.data.content);
              setSearchStatus(false);
              setbackdropopen(false);
              setdatefilter(false);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setgetpageno(response.data.data.totalPages);
              document.getElementById("myForm").reset();
            },
            (error) => {
              setbackdropopen(false);
              setClientDetails(null);
              handle403error(error);
            }
          );
      }
    } else {
      console.log("hii else", optionElementName);
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      //globalgetallenquiry(pageno);
      let office_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(office_id);
      setallofficeid(office_id);

      axios
        .get(`${base_url.api3}/searchClientByAllFilter`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            officeId: optionElementName,
            columnName: sortState,
            direction: direction,
            page: 0,
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            setbackdropopen(false);
            setClientDetails(response.data.data.content);
            setSearchStatus(false);
            setdatefilter(false);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setgetpageno(response.data.data.totalPages);
            document.getElementById("myForm").reset();
          },
          (error) => {
            setbackdropopen(false);
            setClientDetails(null);
            handle403error(error);
          }
        );
    }
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();

  const AllClientDetails = (value1, statusstate) => {
    document.getElementById("myForm").reset();
    setbackdropopen(true);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (intakeStatus) {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              country: country1,
              direction: direction,
              page: Number(value1),
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setpdflength(response.data.data.totalElements);
              getPdfData({
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  intake: intakeData,
                  country: country1,
                  direction: direction,
                }
              });
              setClientDetails(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setClientDetails();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              direction: direction,
              page: Number(value1),
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setClientDetails(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setClientDetails();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    } else {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              country: country1,
              direction: direction,
              page: Number(value1),
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setClientDetails(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setClientDetails();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              direction: direction,
              page: Number(value1),
              size: Number(rows),
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setClientDetails(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setClientDetails();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    }
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = (p) => {
    axios
      .get(`${base_url.api2}/getUsersByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
          status: 1,
        },
      })
      .then(
        (res) => {
          let apiData = res.data.data;
          const consultant_obj = [];
          for (let i = 0; i < apiData.length; i++) {
            let data = {
              value: apiData[i].id,
              label: apiData[i].first_name + " " + apiData[i].last_name,
            };
            consultant_obj.push(data);
          }
          setAssigneeData(consultant_obj);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const handleChangeTableStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    // setstatusstate(optionElementName);
    // console.log(optionElementName);
    AllClientDetails(pageno, optionElementName);
  };

  const [intakeStatus, setIntakeStatus] = useState();
  const [intakeData, setIntakeData] = useState();
  const handleChangeIntake = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setIntakeData(optionElementName);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (optionElementName != "") {
      setIntakeStatus(true);
      if (assigneeState) {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  query: searchData,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  query: searchData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      } else {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  columnName: sorting,
                  query: searchData,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  columnName: sorting,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  intake: optionElementName,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      }
    } else {
      setIntakeStatus(false);
      if (assigneeState) {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: selectedAssigneeData,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      } else {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      }
    }
  };

  const [countryStatus, setCountryStatus] = useState();
  const [country1, setCountry] = useState();
  const handleChangeCountry = (value) => {
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    setCountry(value);

    if (value != "") {
      setCountryStatus(true);
      if (assigneeState) {
        if (intakeStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  intake: intakeData,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  intake: intakeData,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  intake: intakeData,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  intake: intakeData,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  country: value,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      }
    } else {
      setCountryStatus(false);
      if (assigneeState) {
        if (intakeStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  assigneeId: selectedAssigneeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      } else {
        if (intakeStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sorting,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      }
    }
  };

  const classes = useStyles();

  const handleSearch = (e, value) => {
    setbackdropopen(true);
    setpageno(value - 1);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (datefilter) {
      axios
        .post(`${base_url.api3}/searchClientByDate`, datesRange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setClientDetails(clientdetails);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            query: searchData,
            columnName: sorting,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            setClientDetails(client_obj);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const [display1, setDisplay] = useState("inline");
  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }
    // getallEnquiry(pageno);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (assigneeState) {
      if (intakeStatus) {
        if (countryStatus) {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                country: country1,
                intake: intakeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                // for (let i = 0; i < client_obj.length; i++) {
                //   clientdetails.push(client_obj[i].clientDetail);
                // }
                // setClientDetails(clientdetails);
                setClientDetails(client_obj);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        } else {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                columnName: sorting,
                intake: intakeData,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                // for (let i = 0; i < client_obj.length; i++) {
                //   clientdetails.push(client_obj[i].clientDetail);
                // }
                // setClientDetails(clientdetails);
                setClientDetails(client_obj);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        }
      } else {
        if (countryStatus) {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                country: country1,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                // for (let i = 0; i < client_obj.length; i++) {
                //   clientdetails.push(client_obj[i].clientDetail);
                // }
                // setClientDetails(clientdetails);
                setClientDetails(client_obj);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        } else {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                assigneeId: selectedAssigneeData,
                columnName: sorting,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                // for (let i = 0; i < client_obj.length; i++) {
                //   clientdetails.push(client_obj[i].clientDetail);
                // }
                // setClientDetails(clientdetails);
                setClientDetails(client_obj);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        }
      }
    } else {
      if (intakeStatus) {
        if (countryStatus) {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                columnName: sorting,
                intake: intakeData,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(clientdetails);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        } else {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                columnName: sorting,
                intake: intakeData,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(clientdetails);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        }
      } else {
        if (countryStatus) {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                columnName: e.target.id,
                country: country1,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(clientdetails);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        } else {
          axios
            .get(`${base_url.api3}/getClientByClientStatus`, {
              headers: {
                Authorization: jwtToken,
                status: statusstate,
                columnName: e.target.id,
                direction: direction,
                page: Number(pageno),
                size: Number(rows),
              },
            })
            .then(
              (response) => {
                console.log("Client", response);
                const client_obj = [];
                for (let i = 0; i < response.data.data.content.length; i++) {
                  const element = response.data.data.content[i];
                  client_obj.push(element);
                }
                const clientdetails = [];
                for (let i = 0; i < client_obj.length; i++) {
                  clientdetails.push(client_obj[i].clientDetail);
                }
                setClientDetails(clientdetails);
                setgetpageno(response.data.data.totalPages);
                settotalUser(response.data.data.totalElements);
                setcountnumber(response.data.data.number);
                setnumberofElements(response.data.data.numberOfElements);
                setbackdropopen(false);
              },
              (error) => {
                setClientDetails();
                setgetpageno(0);
                settotalUser(0);
                setcountnumber(0);
                setnumberofElements(0);
                setbackdropopen(false);
                handle403error(error);
              }
            );
        }
      }
    }
  };

  const viewClient = (elem, p) => {
    localStorage.setItem("clientPageNo", pageno);
    var client_username = elem.target.id;
    localStorage.setItem("comingfromwhere", "userspage");
    if (client_username == "") {
      // props.history.replace({ pathname: '/edit-Client/'+client_username })
      console.log("coming if");
      history.push("/client-view/" + p.email);
    } else {
      console.log("coming else");
      history.push("/client-view/" + client_username);
    }
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesRange, setDatesRange] = useState();
  const [datevalue, setDateValue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setDatesRange(data);
    console.log(data);
    axios
      .post(`${base_url.api3}/searchClientByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("Client", response);
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setClientDetails(clientdetails);
          setSearchStatus(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        },
        (error) => {
          setClientDetails();
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [pdfRow, setPdfRow] = useState();

  const getPdfData = (statusstate) => {
    document.getElementById("myForm").reset();
    setbackdropopen(true);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (intakeStatus) {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              country: country1,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setPdfRow(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setPdfRow(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    } else {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              country: country1,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setPdfRow(response.data.data.content);
              setSearchStatus(false);
              setdatefilter(false);
              setgetpageno(response.data.data.totalPages);
              settotalUser(response.data.data.totalElements);
              setcountnumber(response.data.data.number);
              setnumberofElements(response.data.data.numberOfElements);
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setgetpageno(0);
              settotalUser(0);
              setcountnumber(0);
              setnumberofElements(0);
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              setPdfRow(response.data.data.content);
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    }
  };

  const exportPdf = () => {

    setbackdropopen(true);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    if (intakeStatus) {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              country: country1,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setPdfRow(response.data.data.content);
              console.log(response.data.data.content);
              const doc = new jsPDF();
              // autoTable(doc, { html: '#myTable' })
              var col = [
                "Client Details",
                "Address",
                "Source",
                "Office",
                "Status",
                "Created",
              ];
              var rows = [];
              response.data.data?.forEach((element) => {
                let clientStatus;
                if (element.status === 1) {
                  clientStatus = "Active";
                } else {
                  clientStatus = "Inactive";
                }
                console.log(clientStatus);
                var temp = [
                  element.firstName +
                  " " +
                  element.lastName +
                  "\n" +
                  element.email +
                  "\n" +
                  "Country: " +
                  element.country +
                  "\n" +
                  "State: " +
                  element.state +
                  "\n" +
                  "PostCode: " +
                  element.pincode,
                  element.clientSource,
                  element.officeName,
                  clientStatus,
                  moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
                ];
                rows.push(temp);
              });
              doc.autoTable(col, rows, { pageBreak: "auto" });
              // doc.autoTable(col, rows, { pageBreak: 'auto' });
              doc.save("Client_Reports.pdf");
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sorting,
              intake: intakeData,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setPdfRow(response.data.data.content);
              console.log(response.data.data.content);
              const doc = new jsPDF();
              // autoTable(doc, { html: '#myTable' })
              var col = [
                "Client Details",
                "Address",
                "Source",
                "Office",
                "Status",
                "Created",
              ];
              var rows = [];
              response.data.data?.forEach((element) => {
                let clientStatus;
                if (element.status === 1) {
                  clientStatus = "Active";
                } else {
                  clientStatus = "Inactive";
                }
                console.log(clientStatus);
                var temp = [
                  element.firstName +
                  " " +
                  element.lastName +
                  "\n" +
                  element.email +
                  "\n" +
                  "Country: " +
                  element.country +
                  "\n" +
                  "State: " +
                  element.state +
                  "\n" +
                  "PostCode: " +
                  element.pincode,
                  element.clientSource,
                  element.officeName,
                  clientStatus,
                  moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
                ];
                rows.push(temp);
              });
              doc.autoTable(col, rows, { pageBreak: "auto" });
              // doc.autoTable(col, rows, { pageBreak: 'auto' });
              doc.save("Client_Reports.pdf");

              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    } else {
      if (countryStatus) {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              country: country1,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              const client_obj = [];
              for (let i = 0; i < response.data.data.content.length; i++) {
                const element = response.data.data.content[i];
                client_obj.push(element);
              }
              const clientdetails = [];
              for (let i = 0; i < client_obj.length; i++) {
                clientdetails.push(client_obj[i].clientDetail);
              }
              setPdfRow(response.data.data.content);
              console.log(response.data.data.content);
              const doc = new jsPDF();
              // autoTable(doc, { html: '#myTable' })
              var col = [
                "Client Details",
                "Address",
                "Source",
                "Office",
                "Status",
                "Created",
              ];
              var rows = [];
              response.data.data?.forEach((element) => {
                let clientStatus;
                if (element.status === 1) {
                  clientStatus = "Active";
                } else {
                  clientStatus = "Inactive";
                }
                console.log(clientStatus);
                var temp = [
                  element.firstName +
                  " " +
                  element.lastName +
                  "\n" +
                  element.email +
                  "\n" +
                  "Country: " +
                  element.country +
                  "\n" +
                  "State: " +
                  element.state +
                  "\n" +
                  "PostCode: " +
                  element.pincode,
                  element.clientSource,
                  element.officeName,
                  clientStatus,
                  moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
                ];
                rows.push(temp);
              });
              doc.autoTable(col, rows, { pageBreak: "auto" });
              // doc.autoTable(col, rows, { pageBreak: 'auto' });
              doc.save("Client_Reports.pdf");
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setbackdropopen(false);
              handle403error(error);
            }
          );
      } else {
        axios
          .get(`${base_url.api3}/getAllClientByClientStatus`, {
            headers: {
              Authorization: jwtToken,
              status: statusstate,
              columnName: sortState,
              direction: direction,
            },
          })
          .then(
            (response) => {
              console.log("Client", response);
              setPdfRow(response.data.data.content);
              console.log(response.data.data);
              const doc = new jsPDF();
              // autoTable(doc, { html: '#myTable' })
              var col = [
                "Client Details",
                // "Address",
                "Source",
                "Office",
                "Status",
                "Created",
              ];
              var rows = [];
              response.data.data?.forEach((element) => {
                let clientStatus;
                if (element.status === 1) {
                  clientStatus = "Active";
                } else {
                  clientStatus = "Inactive";
                }
                console.log(clientStatus);
                var temp = [
                  element.firstName +
                  " " +
                  element.lastName +
                  "\n" +
                  element.email +
                  "\n" +
                  "Country: " +
                  element.country +
                  "\n" +
                  "State: " +
                  element.state +
                  "\n" +
                  "PostCode: " +
                  element.pincode,
                  element.clientSource,
                  element.officeName,
                  clientStatus,
                  moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
                ];
                rows.push(temp);
              });
              doc.autoTable(col, rows, { pageBreak: "auto" });
              // doc.autoTable(col, rows, { pageBreak: 'auto' });
              doc.save("Client_Reports.pdf");
              setbackdropopen(false);
            },
            (error) => {
              setPdfRow();
              setbackdropopen(false);
              handle403error(error);
            }
          );
      }
    }
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState();
  const onSelectAssignee = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    // console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(consultant_obj.toString());
      setAssigneeState(true);
      if (intakeStatus) {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  intake: intakeData,
                  country: country1,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  intake: intakeData,
                  country: country1,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  intake: intakeData,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  intake: intakeData,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      } else {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  country: country1,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  country: country1,
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  assigneeId: consultant_obj.toString(),
                  columnName: sorting,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  console.log("Client", response);
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      }
    } else {
      setAssigneeState(false);
      if (intakeStatus) {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  country: country1,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  country: country1,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  intake: intakeData,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      } else {
        if (countryStatus) {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  country: country1,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        } else {
          if (searchStatus) {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                  query: searchData,
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          } else {
            axios
              .get(`${base_url.api3}/getClientByClientStatus`, {
                headers: {
                  Authorization: jwtToken,
                  status: statusstate,
                  columnName: sortState,
                  direction: direction,
                  page: Number(pageno),
                  size: Number(rows),
                },
              })
              .then(
                (response) => {
                  const client_obj = [];
                  for (let i = 0; i < response.data.data.content.length; i++) {
                    const element = response.data.data.content[i];
                    client_obj.push(element);
                  }
                  const clientdetails = [];
                  for (let i = 0; i < client_obj.length; i++) {
                    clientdetails.push(client_obj[i].clientDetail);
                  }
                  setClientDetails(response.data.data.content);
                  setdatefilter(false);
                  setgetpageno(response.data.data.totalPages);
                  settotalUser(response.data.data.totalElements);
                  setcountnumber(response.data.data.number);
                  setnumberofElements(response.data.data.numberOfElements);
                  setbackdropopen(false);
                },
                (error) => {
                  setClientDetails();
                  setgetpageno(0);
                  settotalUser(0);
                  setcountnumber(0);
                  setnumberofElements(0);
                  setbackdropopen(false);
                  handle403error(error);
                }
              );
          }
        }
      }
    }
  };

  const [allIntake, setAllIntake] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
          columnName: "createdIntake",
          direction: "DESC",
        },
      })
      .then(
        (response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              createdIntake: moment(intakeData[i].createdIntake).format(
                "YYYY-MM"
              ),
              id: intakeData[i].id,
              name: intakeData[i].name,
              status: intakeData[i].status,
            };
            intakeObj.push(data);
          }
          setAllIntake(intakeObj);
        },
        (error) => {
          setAllIntake();
          handle403error(error);
        }
      );
  };

  const searchClientReport = (e) => {
    setbackdropopen(true);
    setDisplay("none");
    setSearchStatus(true);
    let srchInput = e.target[0].value.trim();
    setSearchData(srchInput);
    setpageno(0);
    console.log(srchInput);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "phone") {
      sorting = "phone";
    } else if (sortState == "email") {
      sorting = "email";
    } else if (sortState == "address") {
      sorting = "address";
    } else if (sortState == "city") {
      sorting = "city";
    } else if (sortState == "state") {
      sorting = "state";
    } else if (sortState == "addedOn") {
      sorting = "added_on";
    } else if (sortState == "visaExpiry") {
      sorting = "visaExpiry";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else {
      sorting = "id";
    }

    axios
      .get(`${base_url.api3}/getClientByClientStatus`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          query: srchInput,
          columnName: sorting,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setClientDetails(response.data.data.content);
          setdatefilter(false);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        },
        (error) => {
          setClientDetails();
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
  };

  const deleteUser = () => {
    let status1;
    if (clientstatus === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      username: clientstatususername,
      status: status1,
    };
    axios
      .post(`${base_url.api3}/updateClientByUsername`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success("Status Changed");
          setuserStatus(response.data.data.status);
          setchangestatusmodal(false);
          let logs =
            isw_user_name +
            " Updated status of Client " +
            response.data.data.email +
            " to " +
            response.data.data.status +
            ".";
          callsaveauditapi(logs);
          AllClientDetails(pageno, statusstate);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [userStatus, setuserStatus] = useState();

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const viewEnquiry = (p, e) => {
    localStorage.setItem("clientPageNo", pageno);
    dispatch(getworkflowType("Admission"));
    dispatch(gettabvalue(0));
    var name = p.firstName + " " + p.lastName;
    // history.push("/view-client-enquiry/" + p.id + "/" + p.email);

    localStorage.setItem("selected_client_id", p.id);
    localStorage.setItem("selected_client_username", p.email);

    localStorage.setItem("prev_path", "/client-list");
    history.push("/view-enquiry-workflowList/" + p.id + "/" + p.email);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold">Client Report</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a className="b-link">Reports</a>
          <span className="s_span ">-</span><a className="b-link">General</a>
          <span className="s_span ">-</span>
          <a href="#/client-report" className="b-link active">
            Client Reports
          </a>
        </div>
        {/* <a onClick={goback} className="back-btn">Back</a> */}
      </div>
      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={3}>

              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Limit:</label>
                <select
                  className="form-control"
                  name="branch"
                  value={rows}
                  onChange={handleRows}
                  style={{
                    width: "100%",
                    backgroundColor: "#f5f5f9"
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

            </Col>
            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Branch:</label>
                <select
                  className="form-control"
                  onChange={handleChangeOffice}
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  {Number(user?.isw_user_role) === 1 ||
                    Number(user?.isw_user_role) === 6 ||
                    Number(user?.isw_user_role) === 10 ||
                    Number(user?.isw_user_role) === 3 ||
                    Number(user?.isw_user_role) === 2 ||
                    Number(user?.isw_user_role) === 5 ||
                    Number(user?.isw_user_role) === 4 ? (
                    <option value="all">Show All</option>
                  ) : null}
                  {user?.isw_available_Office?.map((p, index) => (
                    <option key={index} value={p.officeId}>
                      {p.officeName}
                      {" - "}
                      {p.officeCode}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Consultant:</label>
                <Select
                  isMulti
                  name="assigneeId"
                  options={assigneeData}
                  // styles={styles}
                  // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                  className="brach_dropdown"
                  classNamePrefix="select"
                  onChange={onSelectAssignee}
                  style={{ backgroundColor: "#f5f5f9" }}
                  required
                />
              </div>
            </Col>
            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Intake:</label>
                <select
                  className="form-control"
                  onChange={handleChangeIntake}
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  <option value="">- Select Intake -</option>
                  {allIntake?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.name}
                      id={cuntitem.id}
                    >
                      {cuntitem.createdIntake}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            {/* &nbsp;&nbsp; */}
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Date:</label>
                <div className="clearsearchclass" style={{ width: "100%", marginTop: "0px" }}>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetext"
                        style={{ backgroundColor: "#f5f5f9", fontSize: "14px", color: "#6d7290", padding: "10px", width: "100%" }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    {/* {datefilter ? (
                  <div style={{ float: "left" }}>
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setDisplay("inline");
                        setDirection("DESC");
                        setDateValue("Click to open");
                        AllClientDetails(0, statusstate);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "7px",
                        position: "absolute",
                        right: "20px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Status:</label>
                <select
                  className="form-control"
                  onChange={handleChangeTableStatus}
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  <option value="1">Unarchive</option>
                  <option value="0">Archive</option>
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Country:</label>
                <CountryDropdown
                  defaultOptionLabel="- Select Country -"
                  className="form-control"
                  value={country1}
                  style={{ backgroundColor: "#f5f5f9" }}
                  onChange={(val) => handleChangeCountry(val)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div style={{ float: "right", width: "95%" }}>
                <label className="area_rowslable_my">Search:</label>
                <div
                  className="clearsearchclass"
                // style={{ marginRight: "22%", marginLeft: "-50%" }}
                >
                  <form
                    id="myForm"
                    className="d-flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchClientReport(e);
                    }}
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search.."
                      name="search"
                    />
                    <button className="btn listpagesearch_button" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                {searchStatus ? (
                  <a
                    className={classes.clrsrch}
                    style={{ marginRight: "22%" }}
                    onClick={() => {
                      setSearchStatus(false);
                      setDisplay("inline");
                      setpageno(0);
                      AllClientDetails(0, statusstate);
                    }}
                  >
                    Clear Search
                  </a>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >

          <Row className="s_pagi" marginBottom="40px" style={{ marginTop: "5px" }}>
            <Col md={6}>
              {client_admin_users?.length ? (
                <p className="s_para" style={{ marginTop: "5px" }}>
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para" style={{ marginTop: "5px" }}>No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(
                  1012,
                  permissionData,
                  rolepermission
                ) ? (
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdf}
                  >
                    Export PDF
                  </button>
                ) : null}
                <>
                  {/* {pdfRow != null ? (
                    <>
                      {permissionData?.includes(163) ? (
                        <CSVLink
                          filename="ClientReportsExport.csv"
                          data={pdfRow}
                          className="btn listing_addbutton"
                        // target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                      ) : null}
                    </>
                  ) : null} */}
                  {dataForDownload != null ? (
                    <>
                      {check1pointpermission(
                        1012,
                        permissionData,
                        rolepermission
                      ) ? (
                        <>
                          <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')}>Export CSV</button>
                          <CSVLink
                            data={dataForDownload}
                            filename="ClientReportsExport.csv"
                            className="hidden"
                            ref={csvLink}
                            target="_blank" />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable} style={{ width: '200%' }}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr className="s_tr">
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  {/* <th style={{ minWidth: "140px" }}>
                    LEAD TYPE{" "}
                    <img src={sort}
                      id="enquiryLeadType"
                      className="sorting"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                  <th style={{ minWidth: "90px" }}>DATE
                    <img
                      src={sort}
                      className="sorting"
                      id="addedOn"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    CLIENT{" "}
                    <img
                      src={sort}
                      id="firstName"
                      className="sorting"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    SOURCE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="clientSource"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    CONTACT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "60px" }}>
                    COUNTRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "120px" }}>
                    CITY 
                    <img
                      src={sort}
                      className="sorting"
                      id="city"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th> */}
                  <th style={{ minWidth: "80px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="officeName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "90px" }}>
                    CONSULTANT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="consultantName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th>
                    <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }}>
                      REMARKS
                    </div>
                  </th>
                  <th style={{ minWidth: "50px" }}>Created By</th>
                  {check1pointpermission(303, permissionData, rolepermission) ||
                    check1pointpermission(305, permissionData, rolepermission) ? (
                    <>
                      {Number(user?.isw_user_role) === 1 ||
                        Number(user?.isw_user_role) === 4 ||
                        Number(user?.isw_user_role) === 5 ||
                        Number(user?.isw_user_role) === 6 ? (
                        <th style={{ minWidth: "150px" }}>ACTION</th>
                      ) : null}
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {client_admin_users != null
                  ? client_admin_users &&
                  client_admin_users?.map((p, index) => (
                    <tr key={index + 1} className="s_tr">
                      <td style={{ textAlign: "center", width: "50px" }}>
                        {countnumber * rows + 1 + index}
                      </td>
                      <td>
                        {p.addedOn
                          ? moment(p.addedOn.split("T")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      <td>
                        {check1pointpermission(
                          306,
                          permissionData,
                          rolepermission
                        ) ? (
                          <a
                            className="greeneffect"
                            id={p.username}
                            onClick={(e) => viewEnquiry(p, e)}
                            style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}
                            href={
                              `#/view-enquiry-workflowList/` +
                              p.id +
                              `/` +
                              p.email
                            }
                          >
                            {p.firstName +
                              " " +
                              p.middleName +
                              " " +
                              p.lastName}
                          </a>
                        ) : (
                          <>
                            {p.firstName +
                              " " +
                              p.middleName +
                              " " +
                              p.lastName}
                          </>
                        )}
                        <br />
                        <div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.email}</div>
                      </td>
                      <td>{p.clientSource}</td>
                      <td>{p.phone == 0 ? " " : p.phone}
                        <br />
                        {p.secondaryPhone == 0 ? " " : p.secondaryPhone}</td>
                      <td><div style={{ width: "90px", wordWrap: "break-word", display: "inline-block" }}>{p.country}</div>
                        <br />
                        <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.city}</div></td>
                      <td>{p.officeCode}</td>
                      <td>
                        <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.consultantName}</div>
                        <br />
                        <div style={{ width: "100px", wordWrap: "break-word", display: "inline-block" }} >{p.consultantEmail}</div>
                      </td>
                      <td>
                        <ClientViewMoreModal
                          p={p}
                          id={p.id}
                          handle403error={handle403error}
                          sortState={sortState}
                          direction={direction}
                          pageno={pageno}
                          rows={rows}
                          AllClientDetails={AllClientDetails}
                          statusstate={statusstate}
                        />
                      </td>
                      <td>
                        <>
                          <div class="s_tooltip">
                            {p.createdBy != null ? p.createdBy.split(" ")[0] : ""}
                            <span class="s_tooltiptext">
                              {p.createdBy}
                            </span>
                          </div>
                        </>
                      </td>
                      <Modal
                        isOpen={remarkmodelopen}
                        onRequestClose={() => setremarkmodelopen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h4>Remark</h4>
                        {remarkcontent ? (
                          <textarea
                            style={{ minWidth: "600px" }}
                            className="form-control"
                            value={remarkcontent}
                            rows={10}
                            readonly
                          />
                        ) : (
                          <p> No Record Available</p>
                        )}
                        <div
                          className="s_newdiv"
                          style={{
                            display: "flex",
                            margin: "10px 0",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="secondary"
                            onClick={() => setremarkmodelopen(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal>

                      <td id={p.id}>
                        {Number(user?.isw_user_role) === 1 ||
                          Number(user?.isw_user_role) === 4 ||
                          Number(user?.isw_user_role) === 5 ||
                          Number(user?.isw_user_role) === 6 ||
                          Number(user?.isw_user_role) === 10 ? (
                          <div
                            style={{
                              position: "relative",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="workflowdashboard_fa-ellipsis"
                              data-toggle="dropdown"
                              style={{ width: "50px" }}
                            >
                              <i
                                className="fa fa-ellipsis-v "
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div
                              // class="s_dropname"
                              className="dropdown-menu workflowdashboarddropdown"
                            >
                              <ul style={{ margin: "0px" }} className="ulclass">
                                {check1pointpermission(
                                  303,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <li>
                                    <p>
                                      <a
                                        id={p.username}
                                        onClick={(e) => viewClient(e, p)}
                                        href={`#/edit-client/` + p.username}
                                        title="Edit"
                                        className="aclass"
                                      >
                                        Edit
                                      </a>
                                    </p>
                                  </li>
                                ) : null}
                                {check1pointpermission(342, permissionData, rolepermission) ? (
                                  <li>
                                    <p>
                                      <ClientEmailModal
                                        username={p.username}
                                        inputtype={inputtype}
                                        chkPassState={chkPassState}
                                        setchkPassState={setchkPassState}
                                        setinputtype={setinputtype}
                                        jwtToken={jwtToken}
                                        toast={toast}
                                        isw_user_name={isw_user_name}
                                        AllClientDetails={AllClientDetails}
                                        statusstate={statusstate}
                                        pageno={pageno}
                                      />
                                    </p>
                                  </li>
                                ) : null}
                                {check1pointpermission(
                                  305,
                                  permissionData,
                                  rolepermission
                                ) ? (
                                  <li>
                                    <p>
                                      {p.status === 0 ? (
                                        <ArchiveModal
                                          status={p.status}
                                          username={p.username}
                                          jwtToken={jwtToken}
                                          handle403error={handle403error}
                                          AllClientDetails={AllClientDetails}
                                          statusstate={statusstate}
                                          pageno={pageno}
                                        />
                                      ) : (
                                        <ArchiveModal
                                          status={p.status}
                                          username={p.username}
                                          jwtToken={jwtToken}
                                          handle403error={handle403error}
                                          AllClientDetails={AllClientDetails}
                                          statusstate={statusstate}
                                          pageno={pageno}
                                        />
                                      )}
                                    </p>
                                  </li>
                                ) : null}

                              </ul>
                            </div>
                          </div>
                        ) : null}

                        <Modal
                          isOpen={changestatusmodal}
                          onRequestClose={() => setchangestatusmodal(false)}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h4 style={{ marginTop: "10px" }}>
                            Are you sure you want to{" "}
                            {clientstatus === 1 ? "Archive" : "Unarchive"}{" "}
                            current record ?{" "}
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              justifyContent: "flex-end",
                              gap: "10px",
                              marginBottom: "10px",
                            }}>
                            <button
                              className="btn listing_addbutton ml-1"
                              onClick={deleteUser}
                            >
                              Yes
                            </button>
                            <button
                              className="btn btn-secondary cancel_margin"
                              onClick={() => setchangestatusmodal(false)}
                            >
                              No
                            </button>
                          </div>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen2}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitNewPassword(e);
                            }}
                          >
                            <div className="form-inner-admin">
                              <h3 style={{ color: "black" }}>
                                Update Password
                              </h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>User Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={selectedclient}
                                      readOnly
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Password:</label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type={inputtype}
                                        className="form-control underline-input"
                                        value={chkPassState}
                                        onChange={(e) =>
                                          setchkPassState(e.target.value)
                                        }
                                        required
                                      />
                                      {inputtype === "password" ? (
                                        <i
                                          className="fa fa-eye-slash fonticonpositioning"
                                          onClick={() => setinputtype("text")}
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-eye fonticonpositioning"
                                          onClick={() =>
                                            setinputtype("password")
                                          }
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div>
                                    <PasswordStrengthBar
                                      password={chkPassState}
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row className="justify-content-end">
                                  <div>
                                    <Button
                                      type="submit"
                                      className="btn listing_addbutton"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                  <div>
                                    <Button
                                      color="secondary"
                                      onClick={closeModal}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Change Status ?
                          </h2>
                          {p.status === 0 ? (
                            <Button color="danger" onClick={deleteUser}>
                              Unarchive
                            </Button>
                          ) : (
                            <Button color="danger" onClick={deleteUser}>
                              Archive
                            </Button>
                          )}
                          &nbsp;&nbsp;&nbsp;
                          <Button color="secondary" onClick={closeModal}>
                            Cancel
                          </Button>
                        </Modal>
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
              {/* )} */}
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {client_admin_users?.length ? (
                      <p className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser} records
                      </p>
                    ) : (
                      <p className="s_spanPage">No Record Found</p>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchStatus || datefilter ? (
                  <Pagination
                    count={getpageno}
                    onChange={handleSearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    onChange={handlePage}
                    shape="rounded"
                  />
                )}{" "}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default ClientReportsNew;