import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { customStyles } from "../../../Utilities/CSSUtilities";
import base_url from "../../../api/bootapi";
import axios from "axios";
import callsaveauditapi from "../../../services/auditservice";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getcourse, removecourse, selectcourse } from "../../features/courseSlice";
import { Link, useHistory } from "react-router-dom";



const UserArchiveModal = ({ username, status, jwtToken, handle403error, p, email, header, getalladminlist, pageno }) => {
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const openmodal = () => setchangestatusmodal(true);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const dispatch = useDispatch();

  const [deleteCollege, setDeleteCollege] = useState(null);
  const [deleteProvidername, setdeleteProviderName] = useState();
  const [modal, setModal] = useState(false);
  const [deleteProviderStatus, setdeleteProviderStatus] = useState();
  const toggle = (elem, p) => {
    console.log(elem.target.id);
    setDeleteCollege(elem.target.id);
    if (p) {
      setdeleteProviderName(p.name);
      if (p.status == 1) {
        setdeleteProviderStatus(0);
      } else {
        setdeleteProviderStatus(1);
      }
    }
    setModal(!modal);
  };

  const updateCurrentUserStatus = (elem) => {
    let status1;
    if (status === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      email: email,
      status: status1,
    };
    axios
      .post(`${base_url.api2}/updateUserDataByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let logs =
            isw_user_name + " updated status of the user " + email + " .";
          callsaveauditapi(logs);
          getalladminlist(pageno);
          setchangestatusmodal(false);
          // dispatch(
          //   removeAdmin({
          //     id: deleteuser,
          //   })
          // );
          // getalladminlist();
        },
        (error) => {
          toast.error(JSON.stringify(error?.response?.data?.message));
          let logs =
            isw_user_name +
            " failed to Update the status of the user " +
            email +
            " .";
          callsaveauditapi(logs);
          handle403error(error);
        }
      );

  };
  return (
    <>
      <span style={{ cursor: "pointer" }} id={username} onClick={() => openmodal(username)}>
        {header}
      </span>
      <Modal
        isOpen={changestatusmodal}
        onRequestClose={() => setchangestatusmodal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>
          Are you sure you want to{" "}
          {status === 1 ? "Archive" : "Unarchive"}{" "}
          current record ?{" "}
        </h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn listing_addbutton ml-1"
            onClick={updateCurrentUserStatus}
          >
            Yes
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={() => setchangestatusmodal(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default UserArchiveModal;