import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectcourse } from "../features/courseSlice";
import "../css/Responsive.css";
import { logout, selectUser, setdashboardcheck } from "../features/userSlice";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Modal from "react-modal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useStopwatch } from "react-timer-hook";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { customStyles, useStyles } from "../../Utilities/CSSUtilities";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "./../Permission/permission";
import DatePicker from "react-datepicker";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  editorConfiguration,
} from "../../Utilities/CSSUtilities";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";


function StartClientSession(props) {
  const editorRef = useRef();
  const user2 = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [backdropopen, setbackdropopen] = useState(false);
  const params = useParams();
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const [followupdate, setfollowupdate] = useState(null);
  const [deadlinedate, setdeadlinedate] = useState(null);

  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  const [workloaddescriptionvalue2, setworkloaddescriptionvalue2] =
    useState(null);
  const [workloaddescriptionvalue, setworkloaddescriptionvalue] = useState(null);

  // const [checkboxstate, setState] = React.useState({
  //   deposit: false,
  // });
  // const checkboxchange = (event) => {
  //   setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  // };

  // const [checkBtn, setcheckBtn] = useState();

  // function getCheckboxStatus(event) {
  //   var status = document.getElementById("flexCheckDefault").checked;
  //   if (status) {
  //     setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  //     console.log(event.target.checked, status);
  //     setcheckBtn(1);
  //   } else {
  //     setState({ ...checkboxstate, [event.target.name]: event.target.unchecked });
  //     console.log(event.target.checked, status);
  //     setcheckBtn(0);
  //   }
  // }

  const classes = useStyles();
  // console.log(props);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user2.isw_role_permissions.length; i++) {
      resource_obj.push(user2.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user2.isw_user_permissions.length; i++) {
      permission_obj.push(user2.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check1pointpermission(201, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(4));
      getClient();
      getallCollege();
      getServices();
      getOffice();
      getClientSource();
      getproforma();
    } else {
      history.push("/errorPage");
    }
  };

  // Proforma templates
  const [template, settemplate] = useState(null);
  const [loadermodal, setloadermodal] = useState(true);
  const [proformadetails, setproformadetails] = useState();
  const getproforma = () => {
    axios
      .get(`${base_url.api2}/getAllProforma`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log("Proforma res", res);
        setproformadetails(res.data.data);
        setTimeout(() => {
          setloadermodal(true);
        }, 2000);
      })
      .catch((err) => {
        setloadermodal(true);
        handle403error(err);
      });
  };

  const selectCountry = (val) => {
    setcountry(val);
  };

  const selectRegion = (val) => {
    setregion(val);
  };

  const [allcollege, setallcollege] = useState();
  const getallCollege = () => {
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: {
          Authorization: jwtToken,
          username: user2.isw_user_email,
          status: 1,
        },
      })
      .then(
        (response) => {
          var client_college = response.data.data.content;
          setallcollege(client_college);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allOffice, setallOffice] = useState();
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          // console.log("Dispatch College response", response);
          var client_college = response.data.data;
          setallOffice(client_college);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allconsultant, setconsultant] = useState();
  const getConsultant = (value) => {
    axios
      .get(`${base_url.api2}/getUsersSelectByOfficeId`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(value),
        },
      })
      .then(
        (res) => {
          // console.log("User by role", res.data.data.content);
          setconsultant(res.data.data);
        },
        (error) => {
          setconsultant(null);
          handle403error(error);
        }
      );
  };

  const [allservices, setservices] = useState();
  const getServices = () => {
    axios
      .get(`${base_url.api1}/getAllInterestedServices`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (res) => {
          setservices(res.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [clientuser, setClientUser] = useState(null);
  const [clientuserStatus, setClientUserStatus] = useState();
  const [checkedstatus, setcheckedstatus] = useState(false);
  const getClient = () => {
    axios
      .get(`${base_url.api3}/getClientTempById`, {
        headers: {
          Authorization: jwtToken,
          clientTempId: params.temp_client_id,
        },
      })
      .then(
        (response) => {
          setClientUser(response.data.data);
          console.log(response.data.data);
          setfirstName(response.data.data.firstName);
          setlastName(response.data.data.lastName);
          setemail(response.data.data.email);
          setphone(response.data.data.phone);
          setaddress(response.data.data.address);
          setpincode(response.data.data.pincode);
          setselectedAssigneemail(response.data.data.assigneeEmail);
          setSelectedAssigne(response.data.data.assigneeId);
          setselectedAssigneN(response.data.data.assigneeName);
          setcity(response.data.data.city);
          setselectedClientSource(response.data.data.clientSource);
          setSelectedserviceN(response.data.data.interestedServices);
          setSelectedOfficeName(response.data.data.officeName);
          setLeadType(response.data.data.leadType);
          setClientUserStatus(response.data.data.status);
          setcountry(response.data.data.country);
          setregion(response.data.data.state);
          // setsetComment(response.data.data.comments);
          setsetComment2(response.data.data.comments);
          // console.log(response.data.data.officeId);
          setSelectedOfficeId(response.data.data.officeId);
          setfollowupdate(response.data.data.followUpOn);
          //setdeadlinedate(response.data.data.deadline);
          getConsultant(response.data.data.officeId);
          setworkloaddescriptionvalue2(response.data.data.remarks);
          setremarks(response.data.data.remarks);
          // let deposit = response.data.data.deposit;
          // if (deposit === 1) {
          //   setState((prev) => {
          //     return {
          //       ...prev,
          //       deposit: true,
          //     };
          //   });
          // }
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [selectedOffice, setSelectedOfficeId] = useState(null);
  const [selectedOfficeN, setSelectedOfficeName] = useState(null);
  const handleOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedOfficeId = optionElement.getAttribute("id");
    const selectedOfficeName = optionElement.getAttribute("value");
    console.log(selectedOfficeId);
    console.log(selectedOfficeName);
    setSelectedOfficeId(selectedOfficeId);
    setSelectedOfficeName(selectedOfficeName);
    clientuser.officeName = selectedOfficeName;
    clientuser.officeId = selectedOfficeId;
    getConsultant(selectedOfficeId);
  };

  const [selectedAssigne, setSelectedAssigne] = useState(null);
  const [selectedAssigneN, setselectedAssigneN] = useState(null);
  const [selectedAssigneemail, setselectedAssigneemail] = useState(null);
  const handleAssigne = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedAssigneId = optionElement.getAttribute("id");
    const selectedAssignename = optionElement.getAttribute("consultantname");
    const selectedAssigneemail = optionElement.getAttribute("value");
    clientuser.assigneeName = selectedAssignename;
    clientuser.assigneeEmail = selectedAssigneemail;
    setSelectedAssigne(selectedAssigneId);
    setselectedAssigneN(selectedAssignename);
    setselectedAssigneemail(selectedAssigneemail);
  };

  const [selectedservice, setSelectedservice] = useState(null);
  const [selectedserviceN, setSelectedserviceN] = useState(null);
  const handleService = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceId = optionElement.getAttribute("id");
    const selectedServiceName = optionElement.getAttribute("value");
    setSelectedservice(selectedServiceId);
    setSelectedserviceN(selectedServiceName);
  };

  const [selectedEnquiryStatus, setEnquiryStatus] = useState(null);
  const handleEnquiryStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceName = optionElement.getAttribute("value");
    setEnquiryStatus(selectedServiceName);
  };

  const [selectedLeadType, setLeadType] = useState();
  const handleLeadType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceName = optionElement.getAttribute("value");
    setLeadType(selectedServiceName);
    console.log(selectedServiceName);
  };

  const isw_user_role_name = localStorage.getItem("isw_user_role_name");
  const isw_user_id = localStorage.getItem("isw_adminId");
  const [AssignBtn, setAssignedBtn] = useState();
  const [newState, setnewState] = useState();
  const [getfirstName, setfirstName] = useState(clientuser?.firstName);
  const [getlastName, setlastName] = useState(clientuser?.lastName);
  const [getemail, setemail] = useState(clientuser?.email);
  const [getphone, setphone] = useState(clientuser?.phone);
  const [getaddress, setaddress] = useState(clientuser?.address);
  const [getcity, setcity] = useState();
  const [getcounty, setcounty] = useState();
  const [getstate, setstate] = useState();
  const [getpincode, setpincode] = useState();
  const [getleadType, setleadType] = useState();
  const [getinterestedServices, setinterestedServices] = useState();
  const [getofficeId, setofficeId] = useState();
  const [getofficeName, setofficeName] = useState();
  const [getassigneeId, setassigneeId] = useState();
  const [getassigneeName, setassigneeName] = useState();
  const [getassigneeEmail, setassigneeEmail] = useState();
  const [getclientSource, setclientSource] = useState();
  const [getfollowUpOn, setfollowUpOn] = useState();
  const [getremarks, setremarks] = useState();


  const submit = (e) => {
    console.log("hello--->sangam");
    console.log(e);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let remarks;
    if (workloaddescriptionvalue) {
      remarks = workloaddescriptionvalue;
    } else {
      remarks = workloaddescriptionvalue2;
    }
    setremarks(remarks);
    let data = {
      id: clientuser?.id,
      addedOn: datenow,

      firstName: e.target[0].value.trim(),
      lastName: e.target[1].value.trim(),
      email: e.target[2].value.trim(),
      phone: e.target[3].value,
      address: e.target[4].value,
      city: e.target[5].value,
      country: country1,
      state: region,
      pincode: e.target[8].value,
      leadType: selectedLeadType,
      interestedServices: selectedserviceN,
      officeId: Number(selectedOffice),
      officeName: selectedOfficeN,
      assigneeId: Number(selectedAssigne),
      assigneeName: selectedAssigneN,
      assigneeEmail: selectedAssigneemail,
      clientSource: selectedClientSource,
      followUpOn: followupdate ? moment(followupdate).format("YYYY-MM-DD") : null,
      // deposit: checkBtn,
      //enquiryStatus: e.target[8].value,
      remarks: remarks,
      updatedOn: datenow,
      status: clientuserStatus,


      //deadline:deadlinedate?moment(deadlinedate).format("YYYY-MM-DD"): null,

    };

    if (AssignBtn == 2) {
      postEnquiry(data);
    } else {
      let data = {
        id: clientuser?.id,
        firstName: e.target[0].value.trim(),
        lastName: e.target[1].value.trim(),
        email: e.target[2].value,
        phone: e.target[3].value,
        address: e.target[4].value,
        city: e.target[5].value,
        country: country1,
        state: region,
        pincode: e.target[8].value,
        leadType: selectedLeadType,
        interestedServices: selectedserviceN,
        officeId: Number(selectedOffice),
        officeName: selectedOfficeN,
        assigneeId: Number(selectedAssigne),
        assigneeName: selectedAssigneN,
        assigneeEmail: selectedAssigneemail,
        clientSource: selectedClientSource,
        followUpOn: followupdate ? moment(followupdate).format("YYYY-MM-DD") : null,
        // deposit: checkBtn,
        // enquiryStatus: e.target[8].value,
        remarks: remarks,
        updatedOn: datenow,
        status: clientuserStatus,


        //deadline:deadlinedate?moment(deadlinedate).format("YYYY-MM-DD"): null,

      };
      console.log(data);
      setbackdropopen(true);
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");
          if (response.data.data.assigneeName != null) {
            setshowSession(true);
          }

          let log =
            isw_user_name +
            " Updated Lead " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          setnewState(true);
          setteampClient(response.data.data);
          setbackdropopen(false);
          history.push("/leads-list");
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
          setbackdropopen(false);
        });
    }
  };
  const [showSession, setshowSession] = useState(false);
  const [lead_id, setLeadId] = useState();
  const [respAssigneeId2, setrespAssigneeId2] = useState(false);
  const [teampClient, setteampClient] = useState({});
  const postEnquiry = (data) => {
    setbackdropopen(true);
    axios
      .post(`${base_url.api3}/addNewClientTemp`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let temp_client = response.data.data;
        toast.success("Success");
        if (response.data.data.assigneeId == null) {
          setrespAssigneeId2(true);
        } else {
          setrespAssigneeId2(false);
        }

        let log =
          isw_user_name +
          " Updated Lead " +
          temp_client.firstName +
          " " +
          temp_client.lastName +
          " (" +
          temp_client.email +
          ").";
        callsaveauditapi(log);
        setteampClient(response.data.data);
        setbackdropopen(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
        setbackdropopen(false);
      });
  };

  const [startSe, setStartSe] = useState(false);
  const [setSession2, setsetSession2] = useState(true);
  const sessionStart = () => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let todayDate = new Date();
    let fmt = "YYYY-MM-DDTHH:mm:ss";
    let m = moment.utc(todayDate, fmt);
    let createdAt = m.local().format(fmt);

    let data = {
      id: clientuser.id,
      startTime: createdAt,
    };
    // console.log(data);
    axios
      .post(`${base_url.api3}/updateClientTempById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        start();
        let temp_client = response.data.data;
        toast.success("Success");
        setClientUser(response.data.data);
        setteampClient(response.data.data);
        setsetSession2(false);
        setStartSe(true);

        let log =
          isw_user_name +
          " Resumed Session for " +
          temp_client.firstName +
          " " +
          temp_client.lastName +
          " (" +
          temp_client.email +
          ").";
        callsaveauditapi(log);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  var subtitle;
  const [clickedBtn, setclickedBtn] = useState();
  const [setComment, setsetComment] = useState();
  const [setComment2, setsetComment2] = useState();
  const updateSession = (e) => {
    // const isoDateString = new Date().toISOString();
    // const datenow = isoDateString.split(".")[0];

    var tempstartTime1 = clientuser?.startTime;
    var tempstartTime =
      tempstartTime1.split(".")[0].split("T")[0] +
      " " +
      tempstartTime1.split(".")[0].split("T")[1];

    let todayDate = new Date();
    let fmt = "YYYY-MM-DDTHH:mm:ss";
    let m = moment.utc(todayDate, fmt);
    let datenow = m.local().format(fmt);

    var endTime = datenow;
    var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
      moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
    );
    var d = moment.duration(duration);
    var totalTime =
      Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");

    // console.log('Start Time1-->',tempstartTime);
    // console.log('Start Time-->',moment(tempstartTime).format('YYYY-MM-DD HH:mm:ss'));
    // console.log('EndTime-->',moment(endTime).format('YYYY-MM-DD HH:mm:ss'));
    // console.log('diff-->',duration);
    // console.log('Duration-->',d);
    // console.log(totalTime);

    if (clickedBtn == 1) {
      if (setComment != null) {
        let final_comment;
        if (setComment2 != null) {
          final_comment =
            setComment2 +
            setComment +
            "\n--> Comment Added By " +
            isw_user_name +
            " (" +
            isw_user_email +
            ") on " +
            moment(datenow).format("DD MMM YYYY hh.mm a") +
            ".\n";
        } else if (setComment != null) {
          final_comment =
            setComment +
            "\n--> Comment Added By " +
            isw_user_name +
            " (" +
            isw_user_email +
            ") on " +
            moment(datenow).format("DD MMM YYYY hh.mm a") +
            ".\n";
        }
        // console.log(final_comment);
        let data = {
          id: clientuser.id,
          comments: final_comment,
          endTime: datenow,
          totalTime: totalTime,
        };

        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            toast.success("Success");
            setsetComment(null);
            document.getElementById("myForm").reset();

            let log =
              isw_user_name +
              " added Comment & Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
            let data1 = {
              id: clientuser?.id,
              firstName: getfirstName,
              lastName: getlastName,
              email: getemail,
              phone: getphone,
              address: getaddress,
              city: getcity,
              country: country1,
              state: region,
              pincode: getpincode,
              leadType: selectedLeadType,
              interestedServices: selectedserviceN,
              officeId: Number(selectedOffice),
              officeName: selectedOfficeN,
              assigneeId: Number(selectedAssigne),
              assigneeName: selectedAssigneN,
              assigneeEmail: selectedAssigneemail,
              clientSource: selectedClientSource,
              followUpOn: followupdate,

              // deposit: checkBtn,
              // enquiryStatus: e.target[8].value,
              remarks: getremarks + `<p>` + final_comment + `</P>`,
              updatedOn: datenow,
              status: clientuserStatus,


              //deadline:deadlinedate?moment(deadlinedate).format("YYYY-MM-DD"): null,

            };
            console.log(data1);
            axios
              .post(`${base_url.api3}/updateClientTempById`, data1, {
                headers: {
                  Authorization: jwtToken,
                },
              })
              .then((response) => {
                let temp_client = response.data.data;
                toast.success("Success");
                if (response.data.data.assigneeName != null) {
                  setshowSession(true);
                }

                let log =
                  isw_user_name +
                  " Updated Lead " +
                  temp_client.firstName +
                  " " +
                  temp_client.lastName +
                  " (" +
                  temp_client.email +
                  ").";
                callsaveauditapi(log);
                setnewState(true);
                setteampClient(response.data.data);
                history.push("/leads-list");
              })
              .catch((error) => {
                toast.error(JSON.stringify(error.response.data.message));
                handle403error(error);
              });
            getClient();
          })
          .catch((error) => {
            handle403error(error);
            // toast.error(JSON.stringify(error.response.data.message));
          });
      } else {
        let data = {
          id: clientuser.id,
          endTime: datenow,
          totalTime: totalTime,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
              endTime: datenow,
              totalTime: totalTime,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            toast.success("Success");

            let createdByName = isw_user_name + " (" + isw_user_email + ")";
            let auditdata = {
              adminIp: ipaddress,
              createdById: isw_adminId,
              createdByName: createdByName,
              id: 0,
              log:
                isw_user_name +
                " Saved Session for " +
                temp_client.firstName +
                " " +
                temp_client.lastName +
                " (" +
                temp_client.email +
                ").",
            };
            let log =
              isw_user_name +
              " Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
            getClient();
          })
          .catch((error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          });
      }
    } else if (clickedBtn == 2) {
      var tempstartTime = clientuser?.startTime;
      var endTime = datenow;
      var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
        moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
      );
      var d = moment.duration(duration);
      var totalTime =
        Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
      if (setComment != null) {
        let final_comment;
        if (setComment2 != null) {
          final_comment =
            setComment2 +
            setComment +
            "\n--> Comment Added By " +
            isw_user_name +
            " (" +
            isw_user_email +
            ") on " +
            moment(datenow).format("DD MMM YYYY hh.mm a") +
            ".\n";
        } else if (setComment != null) {
          final_comment =
            setComment +
            "\n--> Comment Added By " +
            isw_user_name +
            " (" +
            isw_user_email +
            ") on " +
            moment(datenow).format("DD MMM YYYY hh.mm a") +
            ".\n";
        }

        let data = {
          id: clientuser?.id,
          comments: final_comment,
          endTime: datenow,
          totalTime: totalTime,
          // status: 0,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            toast.success("Success");
            setsetComment(null);
            document.getElementById("myForm").reset();

            let log =
              isw_user_name +
              " added Comment & Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);
            localStorage.setItem(
              "client_prev_path",
              `/start-client-session/${clientuser?.id}`
            );
            history.push("/create-client-lead/" + clientuser?.id + "/" + clientuser?.username);
          })
          .catch((error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          });
      } else {
        let data = {
          id: clientuser?.id,
          endTime: datenow,
          totalTime: totalTime,
          // status: 0,
        };
        axios
          .post(`${base_url.api3}/updateClientTempById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            let temp_client = response.data.data;
            toast.success("Success");

            let log =
              isw_user_name +
              " Saved Session for " +
              temp_client.firstName +
              " " +
              temp_client.lastName +
              " (" +
              temp_client.email +
              ").";
            callsaveauditapi(log);

            localStorage.setItem(
              "client_prev_path",
              `/start-client-session/${clientuser?.id}`
            );
            history.push("/create-client-lead/" + clientuser?.id + "/" + clientuser?.username);
          })
          .catch((error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          });
      }
    } else if (clickedBtn == 3) {
      setIsOpen(true);
    }
  };

  const openClient = (e) => {
    localStorage.setItem("client_prev_path", "/start-client-session/" + e);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    var tempstartTime = teampClient.startTime;
    var endTime = datenow;
    var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
      moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
    );
    var d = moment.duration(duration);
    var totalTime =
      Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
    if (setComment != null) {
      let final_comment;
      if (setComment2 != null) {
        final_comment =
          setComment2 +
          setComment +
          "\n--> Comment Added By " +
          isw_user_name +
          " (" +
          isw_user_email +
          ") on " +
          moment(datenow).format("DD MMM YYYY hh.mm a") +
          ".\n";
      } else if (setComment != null) {
        final_comment =
          setComment +
          "\n--> Comment Added By " +
          isw_user_name +
          " (" +
          isw_user_email +
          ") on " +
          moment(datenow).format("DD MMM YYYY hh.mm a") +
          ".\n";
      }

      let data = {
        id: teampClient?.id,
        comments: final_comment,
        endTime: datenow,
        totalTime: totalTime,
        // status: 0,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");
          setsetComment(null);
          document.getElementById("myForm").reset();

          let log =
            isw_user_name +
            " added Comment & Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          // history.push("/create-client-lead/" + e);
          history.push("/create-client-lead/" + clientuser?.id + "/" + clientuser?.username);
        })
        .catch((error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    } else {
      let data = {
        id: teampClient?.id,
        endTime: datenow,
        totalTime: totalTime,
        // status: 0,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");

          let log =
            isw_user_name +
            " Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          // history.push("/create-client-lead/" + e);
          history.push("/create-client-lead/" + clientuser?.id + "/" + clientuser?.username);
        })
        .catch((error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  const goback = () => {
    history.push("/leads-list");
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeClient = () => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    // console.log(datenow);
    var tempstartTime = clientuser?.startTime;
    var endTime = datenow;
    var duration = moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(
      moment(tempstartTime, "YYYY-MM-DD HH:mm:ss")
    );
    var d = moment.duration(duration);
    var totalTime =
      Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
    if (setComment != null) {
      let final_comment;
      if (setComment2 != null) {
        final_comment =
          setComment2 +
          setComment +
          "\n--> Comment Added By " +
          isw_user_name +
          " (" +
          isw_user_email +
          ") on " +
          moment(datenow).format("DD MMM YYYY hh.mm a") +
          ".\n";
      } else if (setComment != null) {
        final_comment =
          setComment +
          "\n--> Comment Added By " +
          isw_user_name +
          " (" +
          isw_user_email +
          ") on " +
          moment(datenow).format("DD MMM YYYY hh.mm a") +
          ".\n";
      }

      let data = {
        id: clientuser?.id,
        comments: final_comment,
        endTime: datenow,
        totalTime: totalTime,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");
          setsetComment(null);
          document.getElementById("myForm").reset();

          let log =
            isw_user_name +
            " added Comment & Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          history.push("/leads-list");
        })
        .catch((error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        });
    } else {
      let data = {
        id: clientuser?.id,
        endTime: datenow,
        totalTime: totalTime,
      };
      axios
        .post(`${base_url.api3}/updateClientTempById`, data, {
          headers: {
            Authorization: jwtToken,
            endTime: datenow,
            totalTime: totalTime,
          },
        })
        .then((response) => {
          let temp_client = response.data.data;
          toast.success("Success");

          let log =
            isw_user_name +
            " Saved Session for " +
            temp_client.firstName +
            " " +
            temp_client.lastName +
            " (" +
            temp_client.email +
            ").";
          callsaveauditapi(log);
          history.push("/leads-list");
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setClientSource(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [selectedClientSource, setselectedClientSource] = useState(null);
  const handleClientSource = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    if (optionElementName === "Sub Agent") {
      console.log("hello Sangam1");
    } else if (optionElementName === "Referral") {
      console.log("hello Sangam2");
    } else {
      console.log("hello Sangam3");
    }
    setselectedClientSource(optionElementName);
  };





  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Card className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "0px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Leads</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span>
          <a href="#/client-list" class="b-link">
            Client
          </a>
          <span className="s_span ">-</span>
          <a href="#/lead-list" class="b-link">
            Lead
          </a>
          <span className="s_span ">-</span>
          <a href="#/" class="b-link active">
            Form
          </a>
        </ul>
        <a onClick={goback} class="back-btn">
          Back
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #ccc" }}>
        <Row>
          <Col md={4}>
            <CardText
              className={classes.headtext}
              style={{ marginLeft: "15px" }}
            >
              <strong>Receive Information</strong>
            </CardText>
          </Col>
          <Col md={4}>{null}</Col>
          {/* <Col md={4} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col> */}
        </Row>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit(e);
          }}
          className="s_ckeditor"
        >
          <div className="form-inner-admin">
            <div className="form-group creatadmin2">
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      defaultValue={clientuser?.firstName}
                      className="form-control underline-input"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        setfirstName(e.target.value)
                      }}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      defaultValue={clientuser?.lastName}
                      className="form-control underline-input"
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        setlastName(e.target.value)
                      }}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Email<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control underline-input"
                      defaultValue={clientuser?.email}
                      style={{ backgroundColor: "#f5f5f9" }}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Phone<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      defaultValue={clientuser?.phone}
                      onChange={(e) => {
                        setphone(e.target.value)
                      }}
                      style={{ backgroundColor: "#f5f5f9" }}
                      required
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label>
                      Address <span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      // required
                      name="address"
                      defaultValue={clientuser?.address}
                      placeholder="Address"
                      onChange={(e) => {
                        setaddress(e.target.value)
                      }}
                      style={{ backgroundColor: "#f5f5f9" }}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      City/Suburb<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      defaultValue={clientuser?.city}
                      style={{ backgroundColor: "#f5f5f9" }}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      value={country1}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      State<span style={{ color: "red" }}>*</span>:
                    </label>
                    {country1 == "Nepal" ? (
                      <select
                        className={classes.selectdrop}
                        name="gender"
                        value={region}
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(e) => selectRegion(e.target.value)}
                      >
                        <option value="">- Select Province -</option>
                        <option value="Province No. 1">Province No. 1</option>
                        <option value="Madhesh Province">Madhesh Province</option>
                        <option value="Bagmati Province">Bagmati Province</option>
                        <option value="Gandaki Province">Gandaki Province</option>
                        <option value="Lumbini Province">Lumbini Province</option>
                        <option value="Karnali Province">Karnali Province</option>
                        <option value="Sudurpashchim Province">
                          Sudurpashchim Province
                        </option>
                      </select>
                    ) : (
                      <RegionDropdown
                        defaultOptionLabel="- Select State -"
                        className="form-control underline-input countrydropdown2"
                        country={country1}
                        value={region}
                        onChange={(val) => selectRegion(val)}
                        required
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_skill">
                      PostCode<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control underline-input"
                      name="pincode"
                      defaultValue={clientuser?.pincode}
                      onChange={(e) => {
                        setpincode(e.target.value)
                      }}
                      style={{ backgroundColor: "#f5f5f9" }}
                      required
                    />
                  </Col>

                  <Col md={3}>
                    <label>
                      Lead Type<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      value={clientuser?.leadType}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        handleLeadType(e);
                        clientuser.leadType = e.target.value;
                        setleadType(e.target.value);
                      }}
                      required
                    >
                      <option value="">- Select Lead Type -</option>
                      <option value="ColdLead">Cold Lead</option>
                      <option value="WarmLead">Warm Lead</option>
                      <option value="HotLead">Hot Lead</option>
                      <option value="Converted">Converted Lead</option>
                    </select>
                  </Col>
                  <Col md={3}>
                    <label>
                      Services
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      defaultValue={clientuser?.interestedServices}
                      value={clientuser?.interestedServices}
                      onChange={(e) => {
                        handleService(e);
                        clientuser.interestedServices = e.target.value;
                        setinterestedServices(e.target.value);
                      }}
                      style={{ backgroundColor: "#f5f5f9" }}
                      required
                    >
                      {allservices?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          id={cuntitem.id}
                          value={cuntitem.name}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={3}>
                    <label >Source
                      <span style={{ color: "red" }}></span>:
                    </label>
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      name="clientsource"
                      defaultValue={clientuser?.clientSource}
                      value={clientuser?.clientSource}
                      className="form-control"
                      onChange={(e) => {
                        handleClientSource(e);
                        clientuser.clientSource = e.target.value;
                        setclientSource(e.target.value);
                      }}
                    >
                      <option value="">- Select Source -</option>
                      {allClientSource?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin">
                <Row>
                  {/* <Col md={3}>
                    <label>
                      Enquiry Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      style={{ backgroundColor: "#f5f5f9" }}
                      onChange={(e) => {
                        handleEnquiryStatus(e);
                        clientuser.enquiryStatus = e.target.value;
                      }}
                      defaultValue={clientuser?.enquiryStatus}
                      value={clientuser?.enquiryStatus}
                      required
                    >
                      <option value="Enquiry">Application Processing</option>
                      <option value="Processing">Processing</option>
                      <option value="OnGoing">OnGoing</option>
                      <option value="Completed">Completed</option>
                      <option value="RefundProcessing">
                        Refund Processing
                      </option>
                      <option value="Discontinued">Discontinued</option>
                    </select>
                  </Col> */}
                  <Col md={3}>
                    <label>
                      Branch<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      className={classes.selectdrop}
                      onChange={handleOffice}
                      // defaultValue={clientuser?.officeName}
                      value={clientuser?.officeId}
                      required
                    >
                      {/* {allOffice?.map((cuntitem) => (
                            <option
                              key={cuntitem.officeId}
                              id={cuntitem.officeId}
                              defaultValue={cuntitem.officeName}
                            >
                              {cuntitem.officeName}
                            </option>
                          ))} */}
                      {user2?.isw_available_Office?.map((p, index) => (
                        <option
                          key={p.officeId}
                          id={p.officeId}
                          value={p.officeId}
                        >
                          {p.officeName}
                          {" - "}
                          {p.officeCode}
                        </option>
                      ))}
                    </select>
                  </Col>

                  <Col md={3}>
                    <label>
                      Consultant<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      style={{ backgroundColor: "#f5f5f9" }}
                      className={classes.selectdrop}
                      onChange={handleAssigne}
                      // defaultValue={clientuser?.assigneeEmail}
                      value={clientuser?.assigneeEmail}
                      required
                    >
                      <option value="">- Select Consultant -</option>
                      {allconsultant != null ? (
                        allconsultant.map((cuntitem) => (
                          <option
                            key={cuntitem.id}
                            id={cuntitem.id}
                            value={cuntitem.email}
                            consultantname={`${cuntitem.firstName} ${cuntitem.lastName}`}
                          >
                            {cuntitem.firstName} {cuntitem.lastName} (
                            {cuntitem.email})
                          </option>
                        ))
                      ) : (
                        <option value="">No Consultant Available</option>
                      )}
                    </select>
                  </Col>

                  <Col md={3}>
                    <label >Follow-up
                      <span style={{ color: "red" }}></span>:
                    </label>
                    <DatePicker
                      style={{ backgroundColor: "#f5f5f9" }}
                      selected={followupdate ? new Date(followupdate) : null}
                      onChange={(date) => setfollowupdate(date)}
                      defaultValue={clientuser ? moment(clientuser.followUpOn).format("YYYY-MM-DD") : null}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                      minDate={new Date()}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Templates</label>
                    <select
                      onChange={(e) => settemplate(e.target.value)}
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "f5f5f9",
                      }}
                    >
                      <option value="">- Select Template -</option>
                      {proformadetails?.map((item, index) => (
                        <option key={index} value={item.template}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              {/* <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <div class="container" style={{ marginTop: "20px" }}>
                      <input
                        type="checkbox"
                        checked={checkboxstate.deposit}
                        onChange={getCheckboxStatus}
                        id="flexCheckDefault"
                        name="deposit"
                      />
                      <label class="checkmark" for="flexCheckDefault" style={{ marginTop: "10px" }}>
                      </label>
                      <label class="form-check-label boxes" for="flexCheckDefault"
                        style={{ marginLeft: "7px", fontSize: "14px", fontWeight: "bold", color: "#071a52" }}>
                        Deposit</label>
                    </div>
                    <br />
                  </Col>
                  <Col md={3}>
              <label >Deadline
              <span style={{ color: "red" }}></span>:
              </label>
              <DatePicker
                    style={{ backgroundColor: "#f5f5f9" }}
                    selected={deadlinedate ? new Date(deadlinedate) : null}
                    onChange={(date) => setdeadlinedate(date)}
                    defaultValue={clientuser? moment(clientuser.deadline).format("YYYY-MM-DD") : null}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                  />
              </Col>

                </Row>
              </div> */}
              {/* <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label>Remark</label>
                    <textarea
                      type="text"
                      style={{ backgroundColor: "#f5f5f9" }}
                      className="form-control underline-input"
                      defaultValue={ReactHtmlParser(clientuser?.remarks)}
                      rows={7}
                    /> */}
              {/* <div>
                      {ReactHtmlParser(clientuser?.remarks)}
                    </div> */}
              {/* <SunEditor
                      ref={editorRef}
                      height="200"
                      setContents={template}
                      required
                    /> */}
              {/* </Col>
                </Row>
              </div> */}
              <label>Remark</label>
              {template === null ? (
                <CKEditor
                  editor={Editor}
                  config={editorConfiguration}
                  data={workloaddescriptionvalue2}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setworkloaddescriptionvalue2(data);
                    setremarks(data);
                  }}
                />
              ) : (
                <CKEditor
                  editor={Editor}
                  config={editorConfiguration}
                  data={template}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                    // setloadermodal(true);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setworkloaddescriptionvalue(data);
                    setremarks(data);
                  }}
                />
              )}
              <div className="form-group creatadmin">
                <Col md={12} className="d-flex justify-content-end mt-2">
                  <button
                    type="submit"
                    className="btn listing_addbutton"
                    style={{
                      backgroundColor: "#17b978",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      setAssignedBtn(1);
                    }}
                  >
                    Update
                  </button>
                </Col>
              </div>
            </div>
          </div>
        </form>
        {/* {showSession? */}
        <div className="form-inner-admin">
          {setSession2 ? (
            <Button className="btn listing_addbutton" onClick={sessionStart}>
              Resume Session
            </Button>
          ) : null}
          {startSe ? (
            <div className="form-inner-admin">
              <form
                id="myForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  updateSession(e);
                }}
              >
                <h5>
                  <strong>Check-In Details</strong>
                </h5>
                <br />
                <h6>
                  <strong>Session Time: </strong>
                  <span>{hours}</span>:<span>{minutes}</span>:
                  <span>{seconds} sec</span>
                </h6>
                <br />
                <FiberManualRecordIcon fontSize="small" color="primary" />
                {clientuser?.enquiryStatus}
                <div className="form-group creatadmin2">
                  <Row>
                    <Col md={6}>
                      {/* <Row>
                            <Col md={6}>
                              <p><strong>Contact</strong></p>
                              <AccountCircleIcon/>
                              <strong>{clientuser?.firstName} {clientuser?.lastName}</strong>
                              <br/>
                              {clientuser?.email}
                            </Col>
                          </Row>
                          <Row><br/><br/><br/></Row> */}
                      <Row style={{ marginLeft: "0px" }}>
                        <Col md={0.5}>
                          <QueryBuilderIcon />
                        </Col>
                        <Col md={3}>
                          <span>
                            <strong>Check-In Date</strong>
                          </span>
                          <br />
                          <p>
                            {moment(clientuser?.startTime.split(".")[0]).format(
                              "DD-MMM-YY hh.mm a"
                            )}
                          </p>
                        </Col>
                        <Col md={3}>
                          <span>
                            <strong>Session Start</strong>
                          </span>
                          <br />
                          <p>
                            {moment(clientuser?.startTime.split(".")[0]).format(
                              "DD-MMM-YY hh.mm a"
                            )}
                          </p>
                        </Col>
                        <Col md={3}>
                          <span>
                            <strong>Session End</strong>
                          </span>
                          <br />
                          {/* {clientuser?.endTime ? (
                                <p>
                                  {moment(clientuser?.endTime).format("LTS")}
                                </p>
                              ) : null} */}
                        </Col>
                        <Col md={2.5}>
                          <span>
                            <strong>Total Time</strong>
                          </span>
                          <br />
                          <span>{hours}</span>:<span>{minutes}</span>:
                          <span>{seconds} sec</span>
                          {/* {clientuser?.totalTime ? (
                                <p>{clientuser?.totalTime}</p>
                              ) : null} */}
                        </Col>
                      </Row>
                      <br />
                      {/* <br/> */}
                      {/* <Row>
                            <Col md={12}>
                              <span>
                                <strong>
                                  Check-In Assignee
                                  <EditIcon/>
                                  <a onClick={changeAssignee}>Change Assignee</a>
                                </strong>
                              </span><br/>
                            </Col>
                          </Row><br/> */}
                      {/* <Row style={{marginLeft:"0px"}}>
                            <AccountCircleIcon/>
                            <strong>{clientuser?.assigneeName}</strong>
                          </Row><br/><br/> */}
                      <Row className={classes.commentBox}>
                        <label>
                          <strong>Comment</strong>
                        </label>
                        <br />
                        <TextareaAutosize
                          rowsMax={6}
                          style={{ height: "100px" }}
                          // defaultValue={clientuser?.comments}
                          className="form-control underline-input"
                          onChange={(e) => {
                            console.log(e.target.value);
                            setsetComment(e.target.value);
                          }}
                        />
                      </Row>
                      <Row>
                        <div className="form-group creatadmin d-flex">
                          <button
                            type="submit"
                            name="btn1"
                            className="btn listing_addbutton"
                            onClick={() => {
                              setclickedBtn(1);
                            }}
                          >
                            Save
                          </button>
                          &nbsp;&nbsp;
                          {clientuserStatus != 0 ? (
                            <button
                              type="submit"
                              name="btn2"
                              className="btn listing_addbutton"
                              onClick={() => {
                                setclickedBtn(2);
                              }}
                            >
                              Convert To Client
                            </button>
                          ) : (
                            <button
                              type="submit"
                              name="btn3"
                              id={clientuser?.id}
                              className="btn listing_addbutton"
                              onClick={closeClient}
                            >
                              Complete Session
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {clientuserStatus != 0 ? (
                            <button
                              type="submit"
                              name="btn3"
                              id={clientuser?.id}
                              className="btn listing_addbutton"
                              onClick={() => {
                                setclickedBtn(3);
                              }}
                            >
                              Complete Session
                            </button>
                          ) : null}
                          <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h4 style={{ color: "blue" }}>
                              Would You Like to fill the client
                              <br />
                              details and create application ?
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                className="btn listing_addbutton"
                                onClick={() => openClient(clientuser?.id)}
                              >
                                Yes
                              </Button>
                              <Button
                                className="ml-2"
                                color="secondary"
                                onClick={closeClient}
                              >
                                No
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </form>
              <Row className={classes.commentBox}>
                <label>
                  <strong>Previous Comments</strong>
                </label>
                <br />
                <textarea
                  rowsMax={6}
                  style={{ height: "500px" }}
                  defaultValue={clientuser?.comments}
                  className="form-control underline-input"
                />
                {/* <TextareaAutosize
                      rowsMax={6}
                      style={{ height: "100px" }}
                      defaultValue={clientuser?.comments}
                      className="form-control underline-input"
                      // onChange={(e) => {
                      //   console.log(e.target.value);
                      //   setsetComment(e.target.value);
                      // }}
                    /> */}
              </Row>
            </div>
          ) : null}
        </div>
        {/* :null} */}
      </div>
      {/* </Card> */}
    </div>
  );
}

export default withRouter(StartClientSession);
