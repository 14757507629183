import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { mycustomStyles } from "../../../Utilities/CSSUtilities";
// import close from "../src/components/images/closeicon.png";
import close from "./../../../../src/components/images/closeicon.png";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import base_url from "./../../../api/bootapi";

const SkillAssesmentViewMoreModal = ({ p, toast, id, headData, handle403error, getallLeads, sortState, direction, pageno, rows }) => {
  const [deletemodal, setdeletemodal] = useState(false);
  const [descriptionState2, setdescriptionState2] = useState(null);
  const [rema, setrema] = useState(false);
  const [getpageno, setgetpageno] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [enquiry, setEnquiry] = useState(null);
  const opendeletemodal = () => {
    setdeletemodal(true);
    setrema(p.remarks);
  }
  console.log(p);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const closedeletemodal = () => setdeletemodal(false);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    console.log(files, info);
  };
  const editorRef = useRef();



  const changeRemarks = (e) => {
    p.remarks = e;
    setdescriptionState2(p.remarks);
  };



  const editRemark = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    const data = {
      enquiryId: p.enquiryId,
      addedBy: isw_user_name,
      remarks: descriptionState2,
      updatedOn: datenow,
    };
    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Remarks Updated");
        setdeletemodal(false);
      });
  };

  return (
    <>
      <button style={{ cursor: "pointer" }} className="role-deletebtn" onClick={opendeletemodal}>
        View
      </button>
      <Modal
        isOpen={deletemodal}
        onRequestClose={closedeletemodal}
        style={mycustomStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ fontSize: "xx-large", fontStyle: "italic", marginBottom: "20px" }}>Remark</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editRemark(e);
          }}
        >
          <SunEditor
            rows={12}
            ref={editorRef}
            style={{ backgroundColor: "#eee" }}
            // style={{ minWidth: "600px" , backgroundColor:"#eee" }}
            height="450"
            setContents={rema}
            onChange={(e) =>
              changeRemarks(e)
            }
            onImageUploadBefore={handleImageUploadBefore}
            setOptions={{
              buttonList: [
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "list",
                  "align",
                  "outdent",
                  "align",
                  "subscript",
                  "fontColor",
                  "imageGallery",
                  "template",
                  "video",
                ]
              ]
            }}
            required
          />
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
              gap: "10px"
            }}
          >
            <button
              color="secondary"
              style={{ backgroundColor: '#F082AC', color: "#fff", padding: "8px 15px", outline: "none", borderRadius: "8px", border: "none" }}
              onClick={() => setdeletemodal(false)}
            >
              Cancel
            </button >
            <button style={{ backgroundColor: '#6F93F6', color: "#fff", padding: "8px 15px", outline: "none", borderRadius: "8px", border: "none" }} type="submit">
              Update
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SkillAssesmentViewMoreModal;