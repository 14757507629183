import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import "../../css/Responsive.css";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  logout,
} from "../../features/userSlice";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useStyles } from "../../../Utilities/CSSUtilities";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { checkFinancepermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";
import PayableInvoiceStatus from "../../Modal/PayableInvoiceStatus";
import UpdatePaymentDateModal from "../../Modal/UpdatePaymentDateModal";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";

function PayableAdmission(props) {
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_user_role = localStorage.getItem("isw_user_role");
  const dispatch = useDispatch();
  const history = useHistory();
  const [sortState, setsortState] = useState("invoice_created_date");
  const [direction, setdirection] = useState("DESC");
  const [backdropopen, setbackdropopen] = useState(false);
  const [pageno, setPageNo] = useState(0);
  const [enquiry, setEnquiry] = useState(null);
  const [display1, setDisplay] = useState("inline");
  const classes = useStyles();
  const [totalUser, setTotalInvoice] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberofElements, setNumberOfElements] = useState();
  const [rows, setRows] = React.useState(50);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [invoiceStatusData, setInvoiceStatusData] = useState("Active");
  const [providerData, setProviderData] = useState(null);
  const [invoiceCommisionTypeData, setInvoiceCommisionTypeData] =
    useState(null);
  const [selectedSubAgent, setSelectedSubAgent] = useState(null);
  const [invoiceSubTypeData, setInvoiceSubTypeData] = useState("Admission");
  const [invoiceAccountTypeData, setInvoiceAccountTypeData] =
    useState("Payable");
  const [totalInvoicePaidData, setTotalInvoicePaidData] = useState(0);
  const [totalTaxPaidData, setTotalTaxPaidData] = useState(0);
  const [totalPagePaidData, setTotalPagePaidData] = useState(0);
  let headData;
  let office_id;

  const [userOfficeId, setUserOfficeId] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? null
      : user?.isw_available_Office[0].officeId
  );

  let new_office_id;

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      console.log(element);
      office_obj.push(element);
    }
    let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
    console.log("Line number 89", new_id);
    new_office_id = new_id;
    setUserOfficeId(new_id);
    getPermissions();
  }, [rows]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [enquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(515, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(78));
      getSubAgent();
      getAllProvider(userOfficeId);
      if (localStorage.getItem("payInstitutePageNo")) {
        let currentPage = localStorage.getItem("payInstitutePageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage) + 1);
        if (isw_user_role === "1" || isw_user_role === "6") {
          headData = {
            Authorization: jwtToken,
            invoiceSubType: invoiceSubTypeData,
            invoiceStatus: invoiceStatusData,
            invoiceType: invoiceAccountTypeData,
            invoicePaidFromDate: paidStartDate,
            invoicePaidToDate: paidEndDate,
            invoiceSuperAgent: selectedSubAgent,
            invoiceProvider: providerData,
            officeId: userOfficeId,
            commisionType: invoiceCommisionTypeData,
            columnName: sortState,
            direction: direction,
            page: Number(currentPage),
            size: rows,
          };
        } else {
          const office_obj = [];
          for (let i = 0; i < user?.isw_available_Office?.length; i++) {
            const element = user?.isw_available_Office?.[i];
            console.log(element);
            office_obj.push(element);
          }
          let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
          office_id = new_id;
          setSelectedOfficeId(new_id);

          headData = {
            Authorization: jwtToken,
            invoiceSubType: invoiceSubTypeData,
            invoiceStatus: invoiceStatusData,
            invoiceType: invoiceAccountTypeData,
            invoicePaidFromDate: paidStartDate,
            invoicePaidToDate: paidEndDate,
            invoiceSuperAgent: selectedSubAgent,
            invoiceProvider: providerData,
            officeId: new_id,
            commisionType: invoiceCommisionTypeData,
            columnName: sortState,
            direction: direction,
            page: Number(currentPage),
            size: rows,
          };
        }
        getAllData(headData);
      } else {
        setPageNo(0);
        setPaginationPageNo(1);
        if (isw_user_role === "1" || isw_user_role === "6") {
          headData = {
            Authorization: jwtToken,
            invoiceSubType: invoiceSubTypeData,
            invoiceStatus: invoiceStatusData,
            invoiceType: invoiceAccountTypeData,
            invoicePaidFromDate: paidStartDate,
            invoicePaidToDate: paidEndDate,
            invoiceSuperAgent: selectedSubAgent,
            invoiceProvider: providerData,
            officeId: userOfficeId,
            commisionType: invoiceCommisionTypeData,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: rows,
          };
        } else {
          const office_obj = [];
          for (let i = 0; i < user?.isw_available_Office?.length; i++) {
            const element = user?.isw_available_Office?.[i];
            console.log(element);
            office_obj.push(element);
          }
          let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
          office_id = new_id;
          setSelectedOfficeId(new_id);

          headData = {
            Authorization: jwtToken,
            invoiceSubType: invoiceSubTypeData,
            invoiceStatus: invoiceStatusData,
            invoiceType: invoiceAccountTypeData,
            invoicePaidFromDate: paidStartDate,
            invoicePaidToDate: paidEndDate,
            invoiceSuperAgent: selectedSubAgent,
            invoiceProvider: providerData,
            officeId: new_id,
            commisionType: invoiceCommisionTypeData,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: rows,
          };
        }
        getAllData(headData);
      }
    } else {
      history.push("/errorPage");
    }
  };

  const [allProvider, setAllProvider] = useState();
  const getAllProvider = (userOfficeId) => {
    console.log(userOfficeId);
    if (userOfficeId == null) {
      axios
        .get(`${base_url.api2}/getByWorkflowTypeBasedOnInstitution`, {
          headers: {
            Authorization: jwtToken,
            workflowType: invoiceSubTypeData,
          },
        })
        .then(
          (response) => {
            setAllProvider(response.data.data);
          },
          (error) => {
            setAllProvider([]);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(
          `${base_url.api2}/getByWorkflowTypeAndOfficeIdBasedOnInstitution`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: invoiceSubTypeData,
              officeId: Number(userOfficeId),
            },
          }
        )
        .then(
          (response) => {
            setAllProvider(response.data.data);
          },
          (error) => {
            handle403error(error);
            setAllProvider([]);
          }
        );
    }
  };

  const [subAgentData, setSubAgent] = useState();
  const getSubAgent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Sub Agent",
        },
      })
      .then(
        (response) => {
          setSubAgent(response.data.data);
        },
        (error) => {
          handle403error(error);
          setSubAgent();
        }
      );
  };

  const [getPageNo, setGetPageNo] = useState();
  const handlePage = (event, value) => {
    setPaginationPageNo(value);
    setPageNo(value - 1);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      searchQuery: searchInput,
      columnName: sortState,
      direction: direction,
      page: Number(value - 1),
      size: rows,
    };
    getAllData(headData);
  };

  const openInvoice = (p, e) => {
    localStorage.setItem("payInstitutePageNo", pageno);
    localStorage.setItem("prev_path3", "/acc-payable-admission");
    history.push({
      pathname: "/view-invoice-details/" + p.clientId + "/" + p.id,
    });
  };

  const [searchStatus, setSearchStatus] = useState();
  const [searchInput, setSearchInput] = useState(null);
  const submitInvoiceSearch = (e) => {
    setbackdropopen(true);
    setSearchStatus(true);
    let srchData = e.target[0].value.trim();
    setSearchInput(srchData);
    setPageNo(0);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      columnName: sortState,
      direction: direction,
      searchQuery: srchData,
      page: Number(0),
      size: rows,
    };
    getAllData(headData);
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      columnName: sortState,
      direction: direction,
      searchQuery: searchInput,
      page: Number(pageno),
      size: rows,
    };
    getAllData(headData);
  };

  const [selectedOfficeId, setSelectedOfficeId] = useState(null);

  const handleChangeOffice = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementName = optionElement.getAttribute("value");
    if (optionElementName === "all") {
      // setUserOfficeId(null);
      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(new_id);
      setUserOfficeId(new_id);
      setSelectedOfficeId(new_id)
      headData = {
        Authorization: jwtToken,
        invoiceSubType: invoiceSubTypeData,
        invoiceStatus: invoiceStatusData,
        invoiceType: invoiceAccountTypeData,
        invoicePaidFromDate: paidStartDate,
        invoicePaidToDate: paidEndDate,
        invoiceSuperAgent: selectedSubAgent,
        invoiceProvider: providerData,
        officeId: new_id,
        commisionType: invoiceCommisionTypeData,
        searchQuery: searchInput,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: rows,
      };
      getAllData(headData);
    } else {
      setUserOfficeId(optionElementName);
      headData = {
        Authorization: jwtToken,
        invoiceSubType: invoiceSubTypeData,
        invoiceStatus: invoiceStatusData,
        invoiceType: invoiceAccountTypeData,
        invoicePaidFromDate: paidStartDate,
        invoicePaidToDate: paidEndDate,
        invoiceSuperAgent: selectedSubAgent,
        invoiceProvider: providerData,
        officeId: optionElementName,
        commisionType: invoiceCommisionTypeData,
        searchQuery: searchInput,
        columnName: sortState,
        direction: direction,
        page: Number(pageno),
        size: rows,
      };
      getAllData(headData);
    }
  };

  const handleChangeInvoiceStatus = (e) => {
    setbackdropopen(true);
    setInvoiceStatusData(e.target.value);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: e.target.value,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      searchQuery: searchInput,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: rows,
    };
    getAllData(headData);
  };

  const handleChangeSubAgent = (e) => {
    setbackdropopen(true);
    setSelectedSubAgent(e.target.value);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: e.target.value,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      searchQuery: searchInput,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: rows,
    };
    getAllData(headData);
  };

  const handleChangeProvider = (e) => {
    setbackdropopen(true);
    setProviderData(e.target.value);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: e.target.value,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      searchQuery: searchInput,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: rows,
    };
    getAllData(headData);
  };

  const handleChangeInvoiceCommisionType = (e) => {
    setbackdropopen(true);
    setInvoiceCommisionTypeData(e.target.value);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: e.target.value,
      searchQuery: searchInput,
      columnName: sortState,
      direction: direction,
      page: Number(pageno),
      size: rows,
    };
    getAllData(headData);
  };

  const [datesRange, setDatesRange] = useState();
  const [paidStartDate, setPaidStartDate] = useState(null);
  const [paidEndDate, setPaidEndDate] = useState(null);
  const [dateValue, setDateValue] = useState("Click to open");
  const [dateFilter, setDateFilter] = useState();
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setPageNo(0);
    setDateFilter(true);

    let dateData = {
      paidStartDate: picker.startDate.format("YYYY-MM-DD"),
      paidEndDate: picker.endDate.format("YYYY-MM-DD"),
    };
    setPaidStartDate(picker.startDate.format("YYYY-MM-DD"));
    setPaidEndDate(picker.endDate.format("YYYY-MM-DD"));
    setDatesRange(dateData);
    setPageNo(0);
    headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: dateData.paidStartDate,
      invoicePaidToDate: dateData.paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      searchQuery: searchInput,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: rows,
    };
    getAllData(headData);
  };

  const handleAction = (actionType) => {
    setbackdropopen(true);
    if (actionType === 'DOWNLOAD') {
      axios
        .get(`${base_url.api2}/getInvoiceByInvoiceSubType`, {
          headers: {
            Authorization: jwtToken,
            invoiceSubType: invoiceSubTypeData,
            invoiceStatus: invoiceStatusData,
            invoiceType: invoiceAccountTypeData,
            invoicePaidFromDate: paidStartDate,
            invoicePaidToDate: paidEndDate,
            invoiceSuperAgent: selectedSubAgent,
            invoiceProvider: providerData,
            officeId: userOfficeId,
            commisionType: invoiceCommisionTypeData,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(pdflength),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  };

  const [pdflength, setpdflength] = useState(50);
  const [pdfHeader, setPdfHeader] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  const getAllData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getInvoiceByInvoiceSubType`, {
        headers: headData,
      })
      .then(
        (response) => {
          headData.size = response.data.data.totalElements;
          if (response.data.data.totalElements == 0) {
            headData.size = Number(50);
            setpdflength(50);
          } else {
            // setpdflength(response.data.data.totalElements);
            setpdflength(50);
          }
          getPdfData(headData.size = Number(50));
          setPdfHeader(headData);
          setGetPageNo(response.data.data.totalPages);
          setTotalInvoice(response.data.data.totalElements);
          setCountNumber(response.data.data.number);
          setNumberOfElements(response.data.data.numberOfElements);
          setRows(response.data.data.size);
          setEnquiry(response.data.data.content);
          console.log(response.data.data.content);
          setbackdropopen(false);
          setTotalInvoicePaidData(response.data.sumTotalCommission);
          setTotalTaxPaidData(response.data.sumTotalTax);

          let totalPrice = response.data.data.content.reduce(function (
            accumulator,
            item
          ) {
            return accumulator + item.totalPaid;
          },
            0);
          setTotalPagePaidData(totalPrice);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          handle403error(error);
          setGetPageNo(0);
          setTotalInvoice(0);
          setCountNumber(0);
          setNumberOfElements(0);
          setRows(0);
          setEnquiry();
          setbackdropopen(false);
          setTotalInvoicePaidData(0);
          setTotalTaxPaidData(0);
          setTotalPagePaidData(0);
        }
      );
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getInvoiceByInvoiceSubType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const exportPdfInvoiceList = () => {
    let headData = {
      Authorization: jwtToken,
      invoiceSubType: invoiceSubTypeData,
      invoiceStatus: invoiceStatusData,
      invoiceType: invoiceAccountTypeData,
      invoicePaidFromDate: paidStartDate,
      invoicePaidToDate: paidEndDate,
      invoiceSuperAgent: selectedSubAgent,
      invoiceProvider: providerData,
      officeId: userOfficeId,
      commisionType: invoiceCommisionTypeData,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getInvoiceByInvoiceSubType`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Invoice No",
          "Invoice Type",
          "Created Date",
          "Student Details",
          "Provider",
          "Course",
          "Amount",
          "Tax",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.id,
            element.commisionType,
            "Invoice Date: " +
            moment(element.invoiceCreatedDate).format("DD/MM/YYYY hh:mm:ss"),
            element.clientUsername +
            "\n" +
            element.clientEmail,
            element.instituteName,
            element.courseName,
            element.totalCommission,
            element.totalTax,
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("Invoice_list.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Account Payable Admission</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span>
          <a class="b-link">Finance</a>
          <span className="s_span ">-</span>
          <a class="b-link">Account Payable</a>
          <span className="s_span ">-</span>
          <a href="#/acc-payable-admission" class="b-link active">
            Admission
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
      </div>
      <div>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Admission Invoice</strong>
              </CardText>
            </Col>
          </Row> */}
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={3}>
              <div>
                <label for="" className="s_label_color">Limit</label>
                <select className="form-control"
                  name="branch"
                  value={rows}
                  onChange={handleRows}
                  style={{ width: '95%', backgroundColor: "#f5f5f9" }}
                  class="form-select"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Branch:</label>
                <select
                  className="form-control"
                  onChange={handleChangeOffice}
                  name="branch"
                  style={{ backgroundColor: "#f5f5f9" }}
                >
                  {Number(user?.isw_user_role) === 1 ||
                    Number(user?.isw_user_role) === 6 ||
                    Number(user?.isw_user_role) === 10 ||
                    Number(user?.isw_user_role) === 3 ||
                    Number(user?.isw_user_role) === 2 ||
                    Number(user?.isw_user_role) === 5 ||
                    Number(user?.isw_user_role) === 4 ? (
                    <option value="all">Show All</option>
                  ) : null}
                  {user?.isw_available_Office?.map((p, index) => (
                    <option key={index} value={p.officeId}>
                      {p.officeName}
                      {" - "}
                      {p.officeCode}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={3}>
              <label className="area_rowslable_my">Sub Agent:</label>
              <select
                className="form-control"
                name="subagent"
                onChange={handleChangeSubAgent}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="null">- Select Sub Agent -</option>
                {subAgentData?.map((cuntitem) => (
                  <option
                    key={cuntitem.id}
                    value={cuntitem.username}
                    id={cuntitem.name}
                  >
                    {cuntitem.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Provider:</label>
                <select
                  className="form-control"
                  onChange={handleChangeProvider}
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">- Select Provider -</option>
                  {allProvider?.map((p, index) => (
                    <option key={index} value={p.institutionId}>
                      {p.institutionName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>


          </Row>
          <br />
          <Row>

            <Col md={3}>
              <label className="area_rowslable_my">Invoice Status:</label>
              <select
                className="form-control"
                name="subagent"
                value={invoiceStatusData}
                onChange={handleChangeInvoiceStatus}
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <option value="null">- Select Invoice Status -</option>
                <option value="Paid">Paid</option>
                {/* <option value="PartiallyPaid">Partially Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Refund">Refund</option> */}
                <option value="Active">Active</option>
              </select>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Invoice Type:</label>
                <select
                  className="form-control"
                  onChange={handleChangeInvoiceCommisionType}
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">- Select Invoice Type -</option>
                  <option value="commissionInvoiceNet">
                    Commission Invoice-Net
                  </option>
                  <option value="commissionInvoiceGross">
                    Commission Invoice-Gross
                  </option>
                  <option value="General Invoice">General Invoice</option>
                </select>
              </div>
            </Col>
            <Col md={3}>
              <label className="area_rowslable_my">Paid Date:</label>
              <div
                className="clearsearchclass"
                style={{ width: "95%", marginTop: "0px" }}
              >
                <form
                  id="myForm"
                  className="d-flex"
                  style={{ position: "relative" }}
                >
                  <DateRangePicker onApply={handleApply}>
                    <p
                      className="datepickerrangevaluetext"
                      style={{
                        backgroundColor: "#f5f5f9",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      {dateValue}
                    </p>
                  </DateRangePicker>
                  {/* {dateFilter ? (
                <div style={{ float: "left", backgroundColor: "#f5f5f9" }}>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setDisplay("inline");
                      setdirection("DESC");
                      setDateValue("Click to open");
                      setDateFilter(false);
                      setPageNo(0);
                      headData = {
                        Authorization: jwtToken,
                        invoiceSubType: invoiceSubTypeData,
                        invoiceStatus: invoiceStatusData,
                        invoiceType: invoiceAccountTypeData,
                        invoicePaidFromDate: null,
                        invoicePaidToDate: null,
                        invoiceSuperAgent: selectedSubAgent,
                        invoiceProvider: providerData,
                        officeId: userOfficeId,
                        commisionType: invoiceCommisionTypeData,
                        searchQuery: searchInput,
                        columnName: sortState,
                        direction: direction,
                        page: Number(0),
                        size: rows,
                      };
                      getAllData(headData);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null} */}
                  <span
                    className="listpagesearch_button"
                    style={{
                      top: "10px",
                      position: "absolute",
                      right: "10px",
                      pointerEvents: "none",
                    }}
                  >
                    <i className="fa fa-calendar"></i>
                  </span>
                </form>
              </div>
            </Col>
            <Col md={3}>
              <label className="area_rowslable_my">Search:</label>
              <div
                className="clearsearchclass"
                style={{ width: "95%", backgroundColor: "#f5f5f9" }}
              >
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitInvoiceSearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchStatus ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setDisplay("inline");
                    setPageNo(0);
                    setSearchStatus(false);
                    setSearchInput(null);
                    document.getElementById("myForm").reset();
                    headData = {
                      Authorization: jwtToken,
                      invoiceSubType: invoiceSubTypeData,
                      invoiceStatus: invoiceStatusData,
                      invoiceType: invoiceAccountTypeData,
                      invoicePaidFromDate: paidStartDate,
                      invoicePaidToDate: paidEndDate,
                      invoiceSuperAgent: selectedSubAgent,
                      invoiceProvider: providerData,
                      officeId: userOfficeId,
                      commisionType: invoiceCommisionTypeData,
                      columnName: sortState,
                      direction: direction,
                      page: Number(0),
                      size: rows,
                    };
                    getAllData(headData);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi" marginBottom="40px">
            <Col md={6}>
              {enquiry?.length ? (
                <p className="s_para">
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={3}>
              <label className="amount_rowslabel">
                <strong>Grand Total Amount:</strong>{" "}
                {totalInvoicePaidData != "null"
                  ? (Math.round(totalInvoicePaidData * 100) / 100).toFixed(2)
                  : 0}
              </label>
            </Col>
            <Col md={3}>
              <label className="amount_rowslabel">
                <strong>Grand Tax total:</strong>{" "}
                {totalTaxPaidData != "null"
                  ? (Math.round(totalTaxPaidData * 100) / 100).toFixed(2)
                  : 0}
              </label>
            </Col>
            <Col md={6}></Col>
            <Col md={6} style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(
                  524,
                  permissionData,
                  rolepermission
                ) ? (
                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfInvoiceList}
                  >
                    Export PDF
                  </button>
                ) : null}
                {check1pointpermission(
                  524,
                  permissionData,
                  rolepermission
                ) ? (
                  <>
                    {dataForDownload != null ? (
                      <>
                        <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')}>Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="Invoice_list.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            {/* <table
              id="example"
              className="table workload card-table table-borderless table-responsive"
              style={{ width: "100%" }}
            > */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                  <th style={{ minWidth: "100px" }}>
                    BILL NO {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="id"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    INVOICE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="commision_type"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "125px" }}>
                    INVOICE TYPE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="commision_type"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "115px" }}>
                    INVOICE DATE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="invoice_created_date"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    CLIENT{/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="client_username"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    PROVIDER{" "}
                    {/* <UnfoldMoreIcon
                        id="institute_name"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      /> */}
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    COURSE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="course_name"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    AMOUNT{" "}
                    {/* <UnfoldMoreIcon
                        id="sharing_commission_amount"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      /> */}
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    TAX AMOUNT{" "}
                    {/* <UnfoldMoreIcon
                        id="sharing_tax_amount"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      /> */}
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="invoice_status"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {invoiceStatusData != "Active" ? (
                    <th style={{ minWidth: "120px" }}>
                      PAID DATE {/* <UnfoldMoreIcon */}
                      <img
                        src={sort}
                        className="sorting"
                        id="invoiceCreatedByName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                  ) : null}
                  {invoiceStatusData != "Active" ? (
                    <th style={{ minWidth: "120px" }}>
                      PAID BY {/* <UnfoldMoreIcon */}
                      <img
                        src={sort}
                        className="sorting"
                        id="invoiceCreatedByName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                  ) : null}
                  <th style={{ minWidth: "120px" }}>
                    CREATED BY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="invoiceCreatedByName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    UPDATED {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="invoiceCreatedByName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "130px" }}>ACTION</th> */}
                </tr>
              </thead>
              {enquiry != null ? (
                <tbody>
                  {enquiry?.map((p, index) => (
                    <>
                      {p.parentInvoiceId != null ?
                        <tr key={index}>
                          <td style={{ width: "50px", textAlign: "center" }}>
                            {countNumber * rows + 1 + index}
                          </td>
                          <td>
                            {check1pointpermission(
                              504,
                              permissionData,
                              rolepermission
                            ) ? (
                              <a
                                id={p}
                                className="greeneffect"
                                onClick={(e) => openInvoice(p, e)}
                                href={
                                  `#/view-invoice-details/` +
                                  p.clientId +
                                  `/` +
                                  p.id
                                }
                              >
                                {p.id}
                              </a>
                            ) : null}
                          </td>
                          <td>
                            <a
                              id={p}
                              className="greeneffect"
                              onClick={(e) => openInvoice(p, e)}
                              href={
                                `#/view-invoice-details/` +
                                p.clientId +
                                `/` +
                                p.parentInvoiceId
                              }
                            >
                              {p.parentInvoiceId}
                            </a>
                          </td>
                          <td style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>
                            {p.commisionType == "commissionInvoiceNet"
                              ? "Commission Invoice-Net"
                              : null}
                            {p.commisionType == "commissionInvoiceGross"
                              ? "Commission Invoice-Gross"
                              : null}
                            {p.commisionType == "General Invoice"
                              ? "General Invoice"
                              : null}
                          </td>
                          <td>
                            {p.invoiceCreatedDate
                              ? moment(p.invoiceCreatedDate.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                            {/* <br />
                        {p.invoiceCreatedDate
                          ? moment(p.invoiceCreatedDate.split(".")[0]).format(
                            "hh.mm a"
                          )
                          : null} */}
                          </td>
                          <td>
                            <div style={{ width: "140px", wordWrap: "break-word", display: "inline-block" }}>
                              {p.clientUsername}
                            </div>
                            <br />
                            <div style={{ width: "140px", wordWrap: "break-word", display: "inline-block" }}>
                              {p.clientEmail}
                            </div>
                          </td>
                          <td><div style={{ width: "140px", wordWrap: "break-word", display: "inline-block" }}>{p.instituteName}</div></td>
                          <td><div style={{ width: "140px", wordWrap: "break-word", display: "inline-block" }}>{p.courseName}</div></td>
                          <td>
                            {p.totalCommission}
                          </td>
                          <td>{p.totalTax}</td>
                          {invoiceStatusData === "Active" ? (
                            <td>
                              <PayableInvoiceStatus
                                invoiceSubTypeData={invoiceSubTypeData}
                                invoiceStatusData={invoiceStatusData}
                                invoiceStatus={p.invoiceStatus}
                                taxamount={p.totalTax}
                                amount={p.totalCommission}
                                invoiceId={p.id}
                                getAllData={getAllData}
                                pageno={pageno}
                                invoiceAccountTypeData={invoiceAccountTypeData}
                                paidStartDate={paidStartDate}
                                paidEndDate={paidEndDate}
                                selectedSubAgent={selectedSubAgent}
                                providerData={providerData}
                                userOfficeId={userOfficeId}
                                invoiceCommisionTypeData={invoiceCommisionTypeData}
                                sortState={sortState}
                                direction={direction}
                                currentPage={0}
                                rows={rows}
                              />
                            </td>
                          ) : (
                            <td>
                              {p.invoiceStatus}
                              <br />
                              {p.invoiceStatus == "Paid" ? (
                                <>
                                  {p.invoicePaidDate
                                    ? moment(
                                      p.invoicePaidDate.split(".")[0]
                                    ).format("DD-MMM-YY")
                                    : null}
                                  <br />
                                  {p.invoicePaidDate
                                    ? moment(
                                      p.invoicePaidDate.split(".")[0]
                                    ).format("hh.mm a")
                                    : null}
                                </>
                              ) : null}
                            </td>
                          )}
                          {invoiceStatusData != "Active" ? (
                            <td>
                              {/* {p.invoicePayDate
                              ? moment(p.invoicePayDate.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null} */}
                              <UpdatePaymentDateModal
                                invoiceSubTypeData={invoiceSubTypeData}
                                invoiceStatusData={invoiceStatusData}
                                invoiceStatus={p.invoiceStatus}
                                invoicePayDate={p.invoicePayDate
                                  ? moment(p.invoicePayDate.split(".")[0]).format(
                                    "DD-MMM-YY"
                                  )
                                  : null}
                                taxamount={p.totalTax}
                                amount={p.totalCommission}
                                invoiceId={p.id}
                                getAllData={getAllData}
                                pageno={pageno}
                                invoiceAccountTypeData={invoiceAccountTypeData}
                                paidStartDate={paidStartDate}
                                paidEndDate={paidEndDate}
                                selectedSubAgent={selectedSubAgent}
                                providerData={providerData}
                                userOfficeId={userOfficeId}
                                invoiceCommisionTypeData={invoiceCommisionTypeData}
                                sortState={sortState}
                                direction={direction}
                                currentPage={0}
                                rows={rows}
                                check1pointpermission={check1pointpermission}
                                permissionData={permissionData}
                                rolepermission={rolepermission}
                              />
                            </td>
                          ) : null}

                          {invoiceStatusData != "Active" ? (
                            <>
                              <td style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.invoicePaidBy}</td>
                            </>
                          ) : null}
                          <td>{p.invoiceCreatedBy}</td>
                          <td style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>
                            {p.updatedOn
                              ? moment(p.updatedOn.split(".")[0]).format(
                                "DD-MMM-YY"
                              )
                              : null}
                          </td>
                          {/* <td>
                      {check1pointpermission(
                        504,
                        permissionData,
                        rolepermission
                       ) ? (
                          <button
                            className="role-deletebtn"
                            id={p}
                            onClick={(e) => openInvoice(p, e)}
                            href={
                              `#/view-invoice-details/` +
                              p.clientId +
                              `/` +
                              p.id
                            }
                          >
                            View Invoice
                          </button>
                        ) : null}
                      </td> */}
                        </tr>
                        :
                        <>

                        </>
                      }
                    </>
                  ))}
                </tbody>
              ) : (
                <Typography>{null}</Typography>
              )}
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                  <br />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                    <select
                      className={classes.selectlimit}
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                  {enquiry?.length ? (
                    <p className="s_spanPage">
                      {countNumber * rows + 1} to{" "}
                      {countNumber * rows + numberofElements} of {totalUser} records
                    </p>
                  ) : (
                    <p className="s_spanPage">No Record Found</p>
                  )}
                </div>
              </form>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  page={paginationPageNo}
                  count={getPageNo}
                  onChange={handlePage}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default PayableAdmission;
