import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setdashboardcheck,
  logout,
} from "./../../features/userSlice";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../../css/Responsive.css";
import { useForm } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import callsaveauditapi from "../../../services/auditservice";
import {
  check1pointpermission,
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";
import sort from "../../images/sort.svg";
import DeleteModal from "../../Modal/Masters/DeleteModal";
import EditAreaModal from "../../Modal/Masters/EditAreaModal";

function AreaList(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const history = useHistory();
  const [country1, setcountry] = useState();
  const [backdropopen, setbackdropopen] = useState(false);
  const jwtToken = "Bearer " + user.isw_user_token;
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("areaId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [allAreaData, setAllAreaData] = useState();
  const [display1, setdisplay] = useState("inline");

  const selectCountry = (val) => {
    setcountry(val);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allAreaData]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    // var available_resource = resource_obj.includes(35);
    // var available_permission = permission_obj.includes(291) || permission_obj.includes(292) || permission_obj.includes(293) || permission_obj.includes(294);

    if (check4pointpermission(1504, 1508, permission_obj, resource_obj)) {
      //if (available_permission) {
      getAllArea(pageno);
      dispatch(setdashboardcheck(35));
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllArea(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const [searchdata, setsearch] = useState();
  const getAllArea = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getAllAreas`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
          setAllAreaData(response.data.data.content);
          setsearch(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          setbackdropopen(false);
          setAllAreaData(null);
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api1}/getAllAreas`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          var client_admin = response.data.data.content;
          setbackdropopen(false);
          setAllAreaData(response.data.data.content);
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const deleteCurrentUser = (id, name) => {
    axios
      .delete(`${base_url.api1}/deleteAreaById`, {
        headers: {
          Authorization: jwtToken,
          id,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);

          let log = isw_user_name + " Deleted Area " + name + ".";
          callsaveauditapi(log);
          getAllArea(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [editAreaId, seteditAreaId] = useState();
  const [editAreaName, seteditAreaName] = useState();
  const [editAreastatus, seteditAreastatus] = useState();
  const [editAreacountry, seteditAreacountry] = useState();
  const editArea = (elem, p) => {
    seteditAreaId(elem.target.id);
    seteditAreaName(p.name);
    seteditAreastatus(p.status);
    seteditAreacountry(p.country);
    setIsOpen3(true);
    // history.push("/edit-area/" + area_Id);
  };

  const updateArea = (e) => {
    let data = {
      areaId: editAreaId,
      name: editAreaName.trim(),
      status: editAreastatus,
      country: editAreacountry,
    };
    console.log(data);
    axios
      .post(`${base_url.api1}/updateAreaById`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success("Success");
          setIsOpen3(false);

          let log = isw_user_name + " Edited Area " + editAreaName + ".";
          callsaveauditapi(log);

          getAllArea(pageno);
        },
        (error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.message));
        }
      );
  };

  var subtitle;

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = (elem) => {
    console.log(elem.target.id);
    setcountry();
    setIsOpen2(true);
  };

  const createArea = (data2) => {
    let name = data2.name.trim();
    let status = Number(data2.status);
    let country = country1;
    let areaId = 0;
    let data = {
      name,
      status,
      areaId,
      country,
    };
    console.log(data);
    axios
      .post(`${base_url.api1}/saveArea`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          toast.success("Success");
          getAllArea(pageno);

          let log =
            isw_user_name +
            " Created Area " +
            "'" +
            response.data.data.name +
            "'.";
          callsaveauditapi(log);

          setIsOpen2(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const submitrows = (e) => {
    getAllArea(0);
  };

  // Change Status of Area

  const changeStatus = (elem, p) => {
    if (p.status === 1) {
      let data = {
        areaId: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api1}/updateAreaById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllArea(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    } else {
      let data = {
        areaId: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api1}/updateAreaById`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          toast.success("Status changed");
          getAllArea(pageno);
        })
        .catch((err) => {
          handle403error(err);
        });
    }
  };
  const [searchinput, setsearchinput] = useState();
  const submitsearch = (e) => {
    setbackdropopen(true);
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api1}/searchAreaByName`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: "name",
          direction: "DESC",
          page: Number(0),
          size: Number(rows),
        },
      })
      .then((res) => {
        setgetpageno(res.data.data.totalPages);
        setsearch(true);
        setAllAreaData(res.data.data.content);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        setAllAreaData();
        handle403error(err);
      });
  };
  const searchbystatus = (event) => {
    setbackdropopen(true);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const value = optionElement.getAttribute("value");
    if (value === "all") {
      getAllArea(pageno);
    } else {
      axios
        .get(`${base_url.api1}/getAreaByStatus`, {
          headers: {
            Authorization: jwtToken,
            status: Number(value),
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setgetpageno(response.data.data.totalPages);
          setAllAreaData(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setsearch(false);
          setbackdropopen(false);
          document.getElementById("myForm").reset();
        })
        .catch((err) => {
          setbackdropopen(false);
          handle403error(err);
        });
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Area</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span>
          <a class="b-link">Settings</a>
          <span className="s_span ">-</span>
          <a class="b-link active">Area List</a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(1505, permissionData, rolepermission) ? (
          <a className="sent-button" onClick={openModal2}>
            Add
          </a>
        ) : null}
        <Modal
          isOpen={modalIsOpen2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>
            <strong> Area</strong>
          </h2>
          <form onSubmit={handleSubmit(createArea)}>
            <div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={12}>
                    <label>
                      Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control underline-input"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <p className="errormessage">Name is Required</p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      value={country1}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>
                      Status<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      name="status"
                      className={classes.selectdrop}
                      ref={register({ required: true })}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    {errors.status && (
                      <p className="errormessage">Status is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <Row className="justify-content-end mt-2">
                <Button color="secondary" onClick={() => setIsOpen2(false)}>
                  Cancel
                </Button>
                <button type="submit" className="btn listing_addbutton ml-1">
                  Submit
                </button>
              </Row>
            </div>
          </form>
        </Modal>
      </div>
      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={6}>
              <Row>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <div style={{ marginTop: "-10px", marginRight: "20px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}
                    >
                      <select
                        className="s_client_setting"
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
                <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
                  <label className="area_rowslable">Status</label>
                  <br />
                  <select
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={searchbystatus}
                  >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </Row>
            </Col>
            <Col md={3}>{null}</Col>
            <Col md={3} className="mt-2">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitsearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setpageno(0);
                    getAllArea(0);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "-25px",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allAreaData?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {null}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  {/* <th>Id</th> */}
                  <th style={{ textAlign: "center", width: "80px" }}>SN</th>
                  <th style={{ textAlign: "center" }}>
                    NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  <th style={{ textAlign: "center", width: "375px" }}>
                    STATUS{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                      style={{
                        cursor: "pointer",
                        display: `${display1}`,
                        height: "10px",
                      }}
                    />
                  </th>
                  {check4pointpermission(
                    1506,
                    1507,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ textAlign: "center", width: "375px" }}>
                      ACTION
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {allAreaData != null ? (
                  allAreaData &&
                  allAreaData?.map((p, index) => (
                    <tr key={p.areaId}>
                      {/* <td>{p.areaId}</td> */}
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center", width: "375px" }}>
                        {p.name}
                      </td>
                      <td style={{ textAlign: "center", width: "375px" }}>
                        {check1pointpermission(
                          1508,
                          permissionData,
                          rolepermission
                        ) ? (
                          <Link
                            id={p.areaId}
                            onClick={(e) => changeStatus(e, p)}
                          >
                            {p.status == 1 ? "Active" : "Inactive"}
                          </Link>
                        ) : (
                          <>{p.status == 1 ? "Active" : "Inactive"}</>
                        )}
                      </td>
                      {check4pointpermission(
                        1506,
                        1507,
                        permissionData,
                        rolepermission
                      ) ? (
                        <td
                          id={p.areaId}
                          style={{ textAlign: "center", width: "375px" }}
                        >
                          {check1pointpermission(
                            1506,
                            permissionData,
                            rolepermission
                          ) ? (
                            // <Link
                            //   id={p.areaId}
                            //   onClick={(e) => {
                            //     e.preventDefault();
                            //     editArea(e, p);
                            //   }}
                            // >
                            //   <EditAreaModal
                            //     editArea={editArea}
                            //     sortState={sortState}
                            //     direction={direction}
                            //     rows={rows}
                            //     getAllAreas={getAllArea}
                            //     handle403error={handle403error}
                            //     p={p}
                            //   />
                            // </Link>
                            <EditAreaModal
                              getAllArea={getAllArea}
                              toast={toast}
                              handle403error={handle403error}
                              pageno={pageno}
                              isw_user_name={isw_user_name}
                              p={p}
                              onClick={(e) => {
                                localStorage.setItem("selectedArea", p);
                                console.log("hello");
                              }}
                            />
                          ) : null}
                          {/* <Modal
                            isOpen={modalIsOpen3}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h2>
                              <strong> Area</strong>
                            </h2>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                editArea(e);
                              }}
                            >
                              <div>
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col md={12}>
                                      <label>
                                        Name
                                        <span style={{ color: "red" }}>*</span>:
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control underline-input"
                                        value={editAreaName}
                                        onChange={(e) => {
                                          seteditAreaName(e.target.value);
                                        }}
                                        required
                                        // readOnly
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col md={12}>
                                      <label>
                                        Country
                                        <span style={{ color: "red" }}>*</span>:
                                      </label>
                                      <CountryDropdown
                                        defaultOptionLabel="- Select Country -"
                                        className="form-control underline-input countrydropdown2"
                                        value={editAreacountry}
                                        onChange={(val) =>
                                          seteditAreacountry(val)
                                        }
                                        required
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col md={12}>
                                      <label>Status:</label>
                                      <select
                                        className={classes.selectdrop}
                                        name="status"
                                        value={editAreastatus}
                                        onChange={(e) => {
                                          seteditAreastatus(e.target.value);
                                        }}
                                        required
                                      >
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </div>
                                <Row className="justify-content-end mt-2">
                                  <Button
                                    color="secondary"
                                    onClick={() => setIsOpen3(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <button
                                    className="btn listing_addbutton ml-1"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </Row>
                              </div>
                            </form>
                          </Modal> */}
                          &nbsp;|&nbsp;
                          {check1pointpermission(
                            1507,
                            permissionData,
                            rolepermission
                          ) ? (
                            <DeleteModal
                              deleteCurrentUser={deleteCurrentUser}
                              name={p.name}
                              id={p.areaId}
                            />
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label
                      className="area_rowslable"
                      style={{ fontWeight: "bold" }}
                    ></label>
                    <br />
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}
                    >
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allAreaData?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser}{" "}
                        records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  count={getpageno}
                  onChange={handlepage}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(AreaList);
