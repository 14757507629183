import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
  Label,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast, ToastContainer } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useStyles } from "../../Utilities/CSSUtilities";
import Select from "react-select";

function ClientReports(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [backdropopen, setbackdropopen] = useState(false);
  const [client_admin_users, setClientDetails] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [datefilter, setdatefilter] = useState();
  const [statusstate, setstatusstate] = useState(1);
  const [permissionData, setPermissionData] = useState();

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [client_admin_users]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    var available_resource = resource_obj.includes(48);
    var available_permission = permission_obj.includes(420);

    if (available_resource || available_permission) {
      //if (available_permission){
      dispatch(setdashboardcheck(48));
      allclientdetails(pageno, statusstate);
      getConsltant();
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      // console.log('No access to the page');
      history.push("/errorPage");
    }
  };
  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    if (assigneeState) {
      let sorting;
      if (sortState == "firstName") {
        sorting = "first_name";
      } else if (sortState == "clientSource") {
        sorting = "client_source";
      } else if (sortState == "officeName") {
        sorting = "office_name";
      } else if (sortState == "consultantName") {
        sorting = "consultant_name";
      } else {
        sorting = "id";
      }
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setClientDetails(response.data.data.content);

            setsearch(false);
            setdatefilter(false);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      allclientdetails(value - 1, statusstate);
    }
  };

  const allclientdetails = (value1, statusstate) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api3}/getClientByClientStatus`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("Client", response);
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setClientDetails(response.data.data.content);
          setsearch(false);
          setdatefilter(false);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        },
        (error) => {
          setClientDetails();
          setgetpageno(0);
          settotalUser(0);
          setcountnumber(0);
          setnumberofElements(0);
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const [assigneeData, setAssigneeData] = useState();
  const getConsltant = (p) => {
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then(
        (res) => {
          let apiData = res.data.data;
          const consultant_obj = [];
          for (let i = 0; i < apiData.length; i++) {
            let data = {
              value: apiData[i].id,
              label: apiData[i].firstName + " " + apiData[i].lastName,
            };
            consultant_obj.push(data);
          }
          setAssigneeData(consultant_obj);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const handleChangeTableStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    // setstatusstate(optionElementName);
    // console.log(optionElementName);
    allclientdetails(pageno, optionElementName);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userStatus, setuserStatus] = useState();

  const classes = useStyles();

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpageno(value - 1);
    console.log(value);
    if (datefilter) {
      axios
        .post(`${base_url.api3}/searchClientByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setClientDetails(clientdetails);
            setsearch(false);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/searchClientDetails`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            setClientDetails(client_obj);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };
  const [display1, setdisplay] = useState("inline");
  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else {
      sorting = "id";
    }
    if (assigneeState) {
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            assigneeId: selectedAssigneeData,
            columnName: sorting,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            // for (let i = 0; i < client_obj.length; i++) {
            //   clientdetails.push(client_obj[i].clientDetail);
            // }
            // setClientDetails(clientdetails);
            setClientDetails(client_obj);
            setsearch(false);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            columnName: e.target.id,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setClientDetails(clientdetails);
            setsearch(false);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    console.log(data);
    axios
      .post(`${base_url.api3}/searchClientByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log("Client", response);
          const client_obj = [];
          for (let i = 0; i < response.data.data.content.length; i++) {
            const element = response.data.data.content[i];
            client_obj.push(element);
          }
          const clientdetails = [];
          for (let i = 0; i < client_obj.length; i++) {
            clientdetails.push(client_obj[i].clientDetail);
          }
          setClientDetails(clientdetails);
          setsearch(false);
          setdatefilter(true);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const exportPdf = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "Address",
      "Source",
      "Office",
      "Status",
      "Created",
    ];
    var rows = [];
    client_admin_users?.forEach((element) => {
      let clientStatus;
      if (element.status === 1) {
        clientStatus = "Active";
      } else {
        clientStatus = "Inactive";
      }
      console.log(clientStatus);
      var temp = [
        element.firstName +
        " " +
        element.lastName +
        "\n" +
        element.email +
        "\n" +
        "Phone: " +
        element.clientDetail.phone,
        "Country: " +
        element.country +
        "\n" +
        "State: " +
        element.state +
        "\n" +
        "PostCode: " +
        element.pincode,
        element.clientSource,
        element.officeName,
        clientStatus,
        moment(element.created).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    // doc.autoTable(col, rows, { pageBreak: 'auto' });
    doc.save("Client_Reports.pdf");
  };

  const [assigneeState, setAssigneeState] = useState();
  const [selectedAssigneeData, setSelectedAssigneeData] = useState();
  const onSelectAssignee = (selectedList, selectedItem) => {
    let sorting;
    if (sortState == "firstName") {
      sorting = "first_name";
    } else if (sortState == "clientSource") {
      sorting = "client_source";
    } else if (sortState == "officeName") {
      sorting = "office_name";
    } else if (sortState == "consultantName") {
      sorting = "consultant_name";
    } else {
      sorting = "id";
    }
    const consultant_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      consultant_obj.push(selectedList[i].value);
    }
    // console.log("Office id --",consultant_obj);
    if (consultant_obj.length > 0) {
      setSelectedAssigneeData(consultant_obj.toString());
      setAssigneeState(true);
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            assigneeId: consultant_obj.toString(),
            columnName: sorting,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setClientDetails(response.data.data.content);

            setsearch(false);
            setdatefilter(false);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      setAssigneeState(false);
      axios
        .get(`${base_url.api3}/getClientByClientStatus`, {
          headers: {
            Authorization: jwtToken,
            status: statusstate,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log("Client", response);
            const client_obj = [];
            for (let i = 0; i < response.data.data.content.length; i++) {
              const element = response.data.data.content[i];
              client_obj.push(element);
            }
            const clientdetails = [];
            for (let i = 0; i < client_obj.length; i++) {
              clientdetails.push(client_obj[i].clientDetail);
            }
            setClientDetails(response.data.data.content);
            setsearch(false);
            setdatefilter(false);
            setgetpageno(response.data.data.totalPages);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            setClientDetails();
            setgetpageno(0);
            settotalUser(0);
            setcountnumber(0);
            setnumberofElements(0);
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Client Reports</strong>
              </CardText>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}>
                  {permissionData?.includes(421) ? (
                    <button
                      className="btn listing_addbutton"
                      onClick={exportPdf}
                    >
                      Export PDF
                    </button>
                  ) : null}
                </Col>
                <Col md={3}>
                  {client_admin_users != null ? (
                    <>
                      {permissionData?.includes(422) ? (
                        <CSVLink
                          filename="ClientReportsExport.csv"
                          data={client_admin_users}
                          className="btn listing_addbutton"
                        // target="_blank"
                        >
                          Export CSV
                        </CSVLink>
                      ) : null}
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="newlisting_headertabdesign">
            <Row>
              <Col md={4}>
                <label className="area_rowslable">Date</label>
                <DateRangePicker
                  onEvent={handleEvent}
                  onCallback={handleCallback}
                  onApply={handleApply}
                >
                  <p className="datepickerrangevaluetext">{datevalue}</p>
                </DateRangePicker>
                {datefilter ? (
                  <div style={{ float: "left" }}>
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setdisplay("inline");
                        setdirection("DESC");
                        setdatevalue("Click to open");
                        allclientdetails(0, statusstate);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <label className="area_rowslable">Consultant</label>
                <Select
                  isMulti
                  name="assigneeId"
                  options={assigneeData}
                  // styles={styles}
                  // controlShouldRenderValue = {consultantState?.length <='2'?true:false}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onSelectAssignee}
                  required
                />
              </Col>
              <Col md={2}>
                <div className={classes.side}>
                  <label className="area_rowslable">Status</label>
                  <select
                    className="form-control"
                    onChange={handleChangeTableStatus}
                  >
                    <option value="1">Unarchive</option>
                    <option value="0">Archive</option>
                  </select>
                </div>
              </Col>
            </Row>
          </div>
          <div className="newlisting_headertabdesign">
            <Row>
              <Col md={6}>
                {client_admin_users?.length ? (
                  <p style={{ marginTop: "15px" }}>
                    {countnumber * rows + 1} to{" "}
                    {countnumber * rows + numberofElements} of {totalUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
            </Row>
            <div className="above-scroller" onScroll={scrolldiv}>
              <div className="scroller"></div>
            </div>
            <div className="displaytablerow">
              <Table responsive striped onScroll={scrolltable}>
                <thead>
                  <tr>
                    <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                    <th>
                      Name{" "}
                      <UnfoldMoreIcon
                        id="firstName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "120px" }}>
                      Source{" "}
                      <UnfoldMoreIcon
                        id="clientSource"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th>
                      Contact{" "}
                      <UnfoldMoreIcon
                        id="email"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "130px" }}>
                      Country{" "}
                      <UnfoldMoreIcon
                        id="country"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th>
                      City{" "}
                      <UnfoldMoreIcon
                        id="city"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "130px" }}>
                      Branch{" "}
                      <UnfoldMoreIcon
                        id="officeName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "190px" }}>
                      Consultant Name{" "}
                      <UnfoldMoreIcon
                        id="consultantName"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                    <th style={{ minWidth: "120px" }}>
                      Active{" "}
                      <UnfoldMoreIcon
                        id="status"
                        onClick={sortClient}
                        style={{ cursor: "pointer", display: `${display1}` }}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {client_admin_users != null
                    ? client_admin_users &&
                    client_admin_users?.map((p, index) => (
                      <tr key={p.id}>
                        <td style={{ width: "50px", textAlign: "center" }}>
                          {countnumber * rows + index + 1}
                        </td>
                        <td>
                          {p.firstName +
                            " " +
                            p.middleName +
                            " " +
                            p.lastName}
                        </td>
                        <td>{p.clientSource}</td>
                        <td>
                          {p.email}
                          <br />
                          {p.phone}
                        </td>
                        <td>{p.country}</td>
                        <td>{p.city}</td>
                        <td>{p.officeName}</td>
                        <td>{p.consultantName}</td>
                        <td>{p.status == 1 ? "Active" : "Inactive"}</td>
                      </tr>
                    ))
                    : null}
                </tbody>
                {/* )} */}
              </Table>
            </div>
            <Row>
              <Col md={6}>
                {client_admin_users?.length ? (
                  <p style={{ marginTop: "15px" }}>
                    {countnumber * rows + 1} to{" "}
                    {countnumber * rows + numberofElements} of {totalUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
              <Col md={6}>
                <div className={classes.root}>
                  {searchdata || datefilter ? (
                    <Pagination
                      count={getpageno}
                      onChange={handlesearch}
                      shape="rounded"
                    />
                  ) : (
                    <Pagination
                      count={getpageno}
                      onChange={handlepage}
                      shape="rounded"
                    />
                  )}{" "}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientReports;
