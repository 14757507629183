import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useDispatch, useSelector } from "react-redux";
import { setdashboardcheck, selectUser, logout } from "../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import AddIcon from "@material-ui/icons/Add";
import callsaveauditapi from "../../services/auditservice";
import { getofficepermission,  check1pointpermission ,check4pointpermission} from "../Permission/permission";
import sort from "./../images/sort.svg";
import BranchDeleteModal from "../Modal/Masters/BranchDeleteModal";

const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    transform: "translate(-50%, -50%)",
  },
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  createBtn: {
    float: "right",
  },
  modalcss: {
    marginTop: "10%",
  },
  srch: {
    float: "right",
  },
  srchbtn: {
    padding: "1px 10px",
    marginTop: "8px",
    // marginRight: "16px",
    background: "#ddd",
    fontSize: "17px",
    cursor: "pointer",
  },
  clrsrch2: {
    float: "right",
    marginTop: "15px",
    marginRight: "-245px",
    cursor: "pointer",
  },
  clrsrch: {
    float: "right",
    marginTop: "5px",
    marginRight: "15px",
    cursor: "pointer",
  },
  root: {
    float: "right",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Office(props) {
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [deleteuser, setDeleteuser] = useState(null);
  const history = useHistory();
  const [backdropopen, setbackdropopen] = useState(false);
  const [office_lists, setOfficeList] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("officeId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const dispatch = useDispatch();
  const [datefilter, setdatefilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [remarkcontent, setremarkcontent] = useState();
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [paginationpageno, setpaginationpageno] = useState(1);
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const selectedId = localStorage.getItem('selected_OfficeId');
  const selectedRame = localStorage.getItem('selected_OfficeName'); 

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [office_lists]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(604, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(21));
      if (localStorage.getItem("officeListPageNo")) {
        let currentPage = localStorage.getItem("officeListPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getallOfficelist(currentPage);
      } else {
        setpageno(0);
        setpaginationpageno(1);
        getallOfficelist(0);
      }
    } else {
      history.push("/errorPage");
    }
  };

  const createoffice = () => {
    history.push("/create-office");
  };

  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getallOfficelist(value - 1);
    setpaginationpageno(value);
  };
  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const getallOfficelist = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/getAllOffice`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          var office_list_data = response.data.data.content;
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setOfficeList(office_list_data);
          setsearch(false);
          setdatefilter(false);
          setbackdropopen(false);
          //document.getElementById("myForm").reset();
        },
        (error) => {
          handle403error(error);
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
        }
      );
  };


  const editOffice = (elem) => {
    localStorage.setItem("officeListPageNo", pageno);
    var office_ids = elem.target.id;
    history.push("/edit-office/" + office_ids);
  };

  const viewAgents = (elem, p) => {
    localStorage.setItem("officeListPageNo", pageno);
    var office_id = elem.target.id;
    console.log(p);
    history.push("/office-agents/" + office_id);
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deleteBranch, setdeleteBranch] = useState();
  const openModal = (elem, p) => {
    console.log(elem.target.id);
    setdeleteBranch(p.officeName);
    // console.log(p.officeName);
    setDeleteuser(elem.target.id);
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const classes = useStyles();
  const [display1, setdisplay] = useState("inline");
  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const submit = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api1}/searchOffice`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          searchQuery: srchdata,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setOfficeList(response.data.data.content);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setsearch(true);
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpaginationpageno(value);
    setpageno(value - 1);
    if (datefilter) {
      axios
        .post(`${base_url.api1}/searchOfficeByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            setgetpageno(response.data.data.totalPages);
            var office_list_data = response.data.data.content;
            setOfficeList(office_list_data);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setsearch(false);
            setbackdropopen(false);
          },
          (error) => {
            if (error.response) {
              toast.error(JSON.stringify(error.response.data.message));
            } else {
              toast.error(JSON.stringify("Something Went Wrong"));
            }
            handle403error(error);
            setbackdropopen(false);
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/searchOffice`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            searchQuery: searchinput,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setOfficeList(response.data.data.content);
            settotalUser(response.data.data.totalElements);
            setcountnumber(response.data.data.number);
            setnumberofElements(response.data.data.numberOfElements);
            setbackdropopen(false);
          },
          (error) => {
            if (error.response) {
              toast.error(JSON.stringify(error.response.data.message));
            } else {
              toast.error(JSON.stringify("Something Went Wrong"));
            }
            handle403error(error);
            setbackdropopen(false);
          }
        );
    }
  };
  const submitrows = (e) => {
    setbackdropopen(true);
    setpageno(0);
    if (searchinput != null) {
      axios
        .get(`${base_url.api1}/searchOffice`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setOfficeList(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            setbackdropopen(false);
          },
          (error) => {
            if (error.response) {
              toast.error(JSON.stringify(error.response.data.message));
            } else {
              toast.error(JSON.stringify("Something Went Wrong"));
            }
            handle403error(error);
            setbackdropopen(false);
          }
        );
    } else {
      // getallOfficelist(pageno);
      getallOfficelist(0);
    }
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    axios
      .get(`${base_url.api1}/getAllOffice`, {
        headers: {
          Authorization: jwtToken,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          var office_list_data = response.data.data.content;
          setOfficeList(office_list_data);
          setsearch(false);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    axios
      .post(`${base_url.api1}/searchOfficeByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          var office_list_data = response.data.data.content;
          setOfficeList(office_list_data);
          setsearch(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setdatefilter(true);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const viewOffice = (elem) => {
    localStorage.setItem("officeListPageNo", pageno);
    var branch_id = elem.target.id;
    // props.history.replace({ pathname: '/edit-Client/'+client_username })
    history.push("/view-office/" + branch_id);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem"}}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Branch List</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Access Level</a><span className="s_span ">-</span>
          <a href="#/office" class="b-link active">
            Branch List
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        {check1pointpermission(605, permissionData, rolepermission) ? (
          <button
            className="sent-button"
            onClick={createoffice}
            style={{ float: "right" }}
          >
            Add
          </button>
        ) : null}
      </div>
      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {office_lists?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {/* <Col md={6}>
              {permissionData?.includes(1) ? (
                <button
                  className="btn btn-success"
                  onClick={createoffice}
                  style={{ float: "right" }}
                >
                  Add
                </button>
              ) : null}
            </Col> */}
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  {/* <th>Id</th> */}
                  <th style={{ width: "100px", textAlign: "center" }}>SN</th>
                  <th style={{ minWidth: "100px", textAlign: "center" }}>
                    BR. NO.
                  </th>
                  <th style={{ minWidth: "250px" }}>
                    NAME {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="officeName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "250px" }}>
                    STATE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "210px" }}>
                    COUNTRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "210px" }}>
                    CODE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="officeCode"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "210px" }}>
                    STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    REMARKS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="comment"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>    
                  {check4pointpermission(
                     606,
                     607,
                    permissionData,
                    rolepermission
                  ) ? (
                    <th style={{ minWidth: "390px" }}>ACTION</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {office_lists !== null ? (
                  office_lists &&
                  office_lists.map((p, index) => (
                    <tr key={p.officeId}>
                      {/* <td>{p.officeId}</td> */}
                      <td style={{ width: "50px", textAlign: "center" }}>
                        {index + 1}
                      </td>
                      <td style={{ minWidth: "80px", textAlign: "center" }}>
                        {p.officeId}
                      </td>
                      <td>
                      {check1pointpermission(
                          608,
                          permissionData,
                          rolepermission
                        ) ? (
                          <a
                            className="grreneffect"
                            id={p.officeId}
                            onClick={viewOffice}
                            href={`#/view-office/` + p.officeId}
                          >
                            {p.officeName}
                          </a>
                        ) : (
                          <>{p.officeName}</>
                        )}
                      </td>
                      <td>{p.state}</td>
                      <td>{p.country}</td>
                      <td>{p.officeCode}</td>
                      <td>{p.status == 1 ? "Active" : "Inactive"}</td>
                      <td className="leadellipsis">
                        {p.comment}
                        {p.comment != null ? (
                          // <span
                          //   className="leadellipsisspan"
                          //   onClick={() => {
                          //     setremarkmodelopen(true);
                          //     setremarkcontent(p.comment);
                          //   }}
                          // >
                          //   View More
                          // </span>
                          <button
                            // className="leadellipsisspan"
                            className="role-deletebtn"
                            onClick={() => {
                              setremarkmodelopen(true);
                              setremarkcontent(p.comment);
                            }}
                          >
                            View More
                          </button>
                        ) : null}
                      </td>
                      <Modal
                        isOpen={remarkmodelopen}
                        onRequestClose={() => setremarkmodelopen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h4>Remark</h4>
                        {remarkcontent ? (
                          <textarea
                            style={{ minWidth: "600px" }}
                            className="form-control"
                            value={remarkcontent}
                            rows={10}
                            readonly
                          />
                        ) : (
                          <p> No Record Available</p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            margin: "10px 0",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="secondary"
                            onClick={() => setremarkmodelopen(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal>
                      {check4pointpermission(
                        606,
                        607,
                        permissionData,
                        rolepermission
                      ) ? (
                      <td>
                          <>
                            {/* <Link
                            id={p.officeId}
                            onClick={(e) => {
                              viewAgents(e, p);
                            }}
                          >
                            View Users
                          </Link>
                          &nbsp;&nbsp;|&nbsp;&nbsp; */}
                            {/* <div
                              style={{
                                position: "relative",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="workflowdashboard_fa-ellipsis"
                                data-toggle="dropdown"
                              >
                                <i
                                  className="fa fa-ellipsis-v "
                                  aria-hidden="true"
                                ></i>
                              </div> */}
                            {/* <div className="dropdown-menu workflowdashboarddropdown"> */}
                            <div style={{ display: "flex" }}>
                            {check1pointpermission(
                              606,
                              permissionData,
                              rolepermission
                            ) ? (
                                <p>
                                  <button
                                    className="sent-button"
                                    id={p.officeId}
                                    onClick={editOffice}
                                    href={`#/edit-office/` + p.officeId}
                                  >
                                    Edit
                                  </button>
                                </p>
                              ) : null}
                              &nbsp; &nbsp;
                              {check1pointpermission(
                                607,
                                permissionData,
                                rolepermission
                              ) ? (
                                <p>
                                  {/* {p.officeId != "20" ? ( */}
                                  {p.officeId != "12" ? (
                                    // <>
                                    //   <button
                                    //     className="role-deletebtn"
                                    //     id={p.officeId}
                                    //     onClick={(e) => {
                                    //       e.preventDefault();
                                    //       openModal(e, p);
                                    //     }}
                                    //   >
                                    //     Delete
                                    //   </button>
                                    // </>
                                    <BranchDeleteModal
                                    p={p}
                                    officeId={p.officeId}
                                    toast={toast}
                                    pageno={pageno}
                                    handle403error={handle403error}
                                    getallOfficelist={getallOfficelist}
                                    />
                                  ) : null}
                                </p>
                              ) : null}
                            </div>
                            {/* </div> */}
                            {/* </div> */}
                            {/* <Modal
                              isOpen={modalIsOpen}
                              onRequestClose={closeModal}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >
                              <h5>
                                Are you sure you want to delete current record?
                              </h5>
                              <div style={{ float: "right" }}>
                                <Button color="danger" onClick={deleteUser}>
                                  Delete
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button color="secondary" onClick={closeModal}>
                                  Cancel
                                </Button>
                              </div>
                            </Modal> */}
                          </>
                      </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
            <div>
                  <form className="master">
                    <div style={{ marginTop: "-10px" }}>
                      <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                      <br />
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                        <select
                          className={classes.selectlimit}
                          labelId="simple-select-label"
                          id="simple-select"
                          value={rows}
                          onChange={handleRows}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
              {office_lists?.length ? (
                <p className="s_spanPage">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_spanPage">No Record Found</p>
              )}
                     </div>
                  </form>
                </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata || datefilter ? (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    page={paginationpageno}
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Office;
