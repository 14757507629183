import React, { useEffect, useState } from "react";
import "../css/Notes.css";
import { withRouter, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { Table, Row, Col, Button } from "reactstrap";
import { customStyles, customFileStyles, useStyles } from "../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from "../../api/bootapi";
import Modal from "react-modal";
import "../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import callsaveauditapi from "../../services/auditservice";
import Pagination from "@material-ui/lab/Pagination";
import {
  selectUser,
} from "./../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import logger from 'logging-library';
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import FilePreviewer from 'react-file-previewer';
import { check1pointpermission } from "../Permission/permission";



const Attachments = (props) => {
  const user = useSelector(selectUser);
  const [allAttahments, setallAttahments] = useState(props.attachments);
  const [backdropopen, setbackdropopen] = useState(false);
  const [deleteattachment, setdeleteattachment] = useState(false);
  const [editAttachment, setEditAttachment] = useState(false);
  const [viewAttachment, setViewAttachment] = useState(false);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [deleteid, setdeleteid] = useState();
  const [deletePath, setdeletePath] = useState();
  const classes = useStyles();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [documenttotalUser, setdocumenttotalUser] = useState();
  const [documentcountnumber, setdocumentcountnumber] = useState();
  const [documentnumberofElements, setdocumentnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [documentgetpageno, setdocumentgetpageno] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const history = useHistory();
  const [getClientId, setClientId] = useState();

  useEffect(() => {
    getPermissions();
    setallAttahments(props.attachments);
    const data2 = props.attachments?.slice(0, 10) || [];
    setdocumentcountnumber(Number(0));
    setdocumentdetails(
      props.attachments.length > 0 ? props.attachments.slice(0, 10) : []
    );
    setdocumentnumberofElements(data2.length);
    setdocumentgetpageno(Math.ceil(Number(props.attachments.length) / 10));
    setdocumenttotalUser(props.attachments.length);
  }, [props.attachments]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {


    } else {
      history.push("/errorPage");
    }
  };


  const handledocumentpage = (event, value) => {
    let data = allAttahments.slice((value - 1) * 10, value * 10);
    setdocumentcountnumber(Number(value - 1));
    setdocumentdetails(data);
    setdocumentnumberofElements(data.length);
  };

  const deletestudentappform = () => {
    let delete_path = deletePath.split('amazonaws.com/')[1]
    axios
      .delete(`${base_url.api2}/deleteAttachmentByAttachmentId`, {
        headers: {
          Authorization: jwtToken,
          attachmentId: deleteid,
          fileName: `${getClientId}/${delete_path}`,
        },
      })
      .then((res) => {
        setdeleteattachment(false);
        props.getAttachments();
      });
  };

  const [fetchedattachmentName, setattachmentName] = useState();
  const [oldattachmentName, setoldattachmentName] = useState();
  const [fetchedattachmentId, setattachmentId] = useState();
  const openEditModal = (e, item) => {
    // console.log(item);
    setoldattachmentName(item.oldFileName);
    setattachmentName(item.attachmentName);
    setattachmentId(item.id);
    setEditAttachment(true);
  };

  const [finalFile, setFinalFile] = useState();
  const [finalFileType, setFinalFileType] = useState();
  const openViewModal = (path, clientId) => {
    setbackdropopen(true);
    console.log(path);
    setViewAttachment(true);
    let file_name = path.split("amazonaws.com/")[1];
    let file_type = path.split(".");
    console.log(file_type[file_type.length - 1]);

    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        clientId: clientId,
        fileName: `${clientId}/${file_name}`,
      },
    };

    var url = `${base_url.api2}/downloadEnquiryAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        console.log(response);
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        // console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        setFinalFile(URL.createObjectURL(blob));
        setFinalFileType(file_type[file_type.length - 1]);
        link.download = file_name;
        // link.click();
        // setFinalFile(response);
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        console.log(error);
      });
  };
  // const openViewModal = (e, item) =>{
  //   console.log(item.attachmentName);
  //   setattachmentName(item.attachmentName);
  //   // return (
  //   //   <FileViewer
  //   //     fileType={type}
  //   //     filePath={file}
  //   //     errorComponent={CustomErrorComponent}
  //   //     onError={()=>console.log('Error happened')}
  //   //   />
  //   // );
  // }

  const changeAttachmentName = (e) => {
    let enquiryWorkflowStepsAttachments = {
      id: Number(fetchedattachmentId),
      attachmentName: e.target[1].value,
    };
    axios
      .put(
        `${base_url.api2}/updateEnquiryAttachmentDataById`,
        enquiryWorkflowStepsAttachments,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        response.data.data.oldFileName = oldattachmentName;
        setEditAttachment(false);
        props.getAttachments();
        let logs =
          isw_user_name +
          " Updated Uploaded File Name of enquiry id" +
          e.target[0].value +
          " to " +
          e.target[1].value +
          ".";
        callsaveauditapi(logs);
      })
      .catch((error) => {
        // toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error));
      });
  };

  const downloadAll = (path, clientId) => {
    setbackdropopen(true);
    let file_name = path.split("amazonaws.com/")[1];
    const header = {
      method: "GET",
      headers: {
        Authorization: jwtToken,
        clientId: clientId,
        fileName: `${clientId}/${file_name}`,
      },
    };

    var url = `${base_url.api2}/downloadEnquiryAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        console.log(response);
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        console.log(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "enquiry_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  return (
    <div className="mainnote">
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Button  style={{float:"right"}} variant="outlined" color="primary" type="submit">
        <a
          href={attachmenturl + "" + downloadAll(props.attachments)}
          style={{color:"white"}}
          download
        >
          Download All
        </a>
      </Button> */}
      <Table responsive striped>
        <thead>
          <tr>
            <th>SN</th>
            <th>Step</th>
            <th>Type</th>
            <th>Title</th>
            <th>Added On</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {documentdetails?.map((item, index) => (
            <tr key={index + 1}>
              <td>{index + 1}</td>
              <td>{item.enquiryWorkflowStepsName}</td>
              <td>{item.documentType}</td>
              <td>
                {check1pointpermission(
                  323,
                  permissionData,
                  rolepermission
                ) ? (
                  <a
                    onClick={() => {
                      downloadAll(item.path, item.clientId);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {item.attachmentName}
                  </a>
                ) : <>{item.attachmentName}</>}
              </td>
              <td>
                {item.createdOn
                  ? moment(item.createdOn.split(".")[0]).format("DD-MMM-YY")
                  : null}
                <br />
                {item.createdOn
                  ? moment(item.createdOn.split(".")[0]).format("hh.mm a")
                  : null}
                <br />
              </td>
              <td>
                {(check1pointpermission(322, permissionData, rolepermission) ||
                  check1pointpermission(323, permissionData, rolepermission) ||
                  check1pointpermission(324, permissionData, rolepermission)) ?
                  <div
                    style={{
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    <div
                      className="workflowdashboard_fa-ellipsis"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-ellipsis-v " aria-hidden="true"></i>
                    </div>
                    <div className="dropdown-menu workflowdashboarddropdown">
                      <ul style={{ margin: "0px" }} className="ulclass">
                        {check1pointpermission(
                          322,
                          permissionData,
                          rolepermission
                        ) ? (
                          <li>
                            <p>
                              <a
                                onClick={(e) => {
                                  setEditAttachment(true);
                                  openEditModal(e, item);
                                }}
                              >
                                Rename
                              </a>
                            </p>
                          </li>
                        ) : null}
                        {check1pointpermission(
                          324,
                          permissionData,
                          rolepermission
                        ) ? (
                          <li>
                            <p>
                              <a
                                onClick={() => {
                                  setdeleteattachment(true);
                                  setdeleteid(item.id);
                                  setdeletePath(item.path);
                                  setClientId(item.clientId);
                                }}
                              >
                                Delete
                              </a>
                            </p>
                          </li>
                        ) : null}
                        {check1pointpermission(
                          323,
                          permissionData,
                          rolepermission
                        ) ? (
                          <li>
                            <p>
                              <a
                                onClick={() => {
                                  downloadAll(item.path, item.clientId);
                                }}
                              >
                                Download
                              </a>
                            </p>
                          </li>
                        ) : null}
                        {check1pointpermission(
                          323,
                          permissionData,
                          rolepermission
                        ) ? (
                          <li>
                            <p>
                              <a
                                onClick={() => {
                                  openViewModal(item.path, item.clientId);
                                }}
                              >
                                View
                              </a>
                            </p>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                  : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        isOpen={editAttachment}
        onRequestClose={() => setEditAttachment(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changeAttachmentName(e);
          }}
        >
          <div>
            <h3 style={{ color: "black" }}>Change Title</h3>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Existing File Title:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={fetchedattachmentName}
                    readOnly
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Rename:</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="form-control underline-input"
                      required
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row className="justify-content-end mt-2">
                <button className="btn listing_addbutton mr-1" type="submit">
                  Update
                </button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setEditAttachment(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={deleteattachment}
        onRequestClose={() => setdeleteattachment(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 style={{ marginTop: "10px" }}>Are you sure you want to delete current record ? </h4>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn listing_addbutton ml-1"
            onClick={deletestudentappform}
          >
            Yes
          </button>
          <button
            className="btn btn-secondary cancel_margin"
            onClick={() => setdeleteattachment(false)}
          >
            No
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={viewAttachment}
        onRequestClose={() => setViewAttachment(false)}
        style={customFileStyles}
        // style={{margin:'20px'}}
        contentLabel="Example Modal"
      >
        <FileViewer
          fileType={finalFileType ? finalFileType : null}
          filePath={finalFile ? finalFile : null}
          errorComponent={CustomErrorComponent}
          onError={() => console.log('Error happened')}
        />
        {/* <FilePreviewer
          file={{
            url: finalFileType
          }}
        /> */}
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {documentdetails?.length ? (
            <p>
              {documentcountnumber * 10 + 1} to{" "}
              {documentcountnumber * 10 + documentnumberofElements} of{" "}
              {documenttotalUser} records
            </p>
          ) : (
            <p>No Record Found </p>
          )}
        </div>
        <Pagination count={documentgetpageno} onChange={handledocumentpage} shape="rounded" />
      </div>
    </div>
  );
};

export default Attachments;
