import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Pagination from "@material-ui/lab/Pagination";
import "../../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { selectdashboard } from "../../features/dashboardSlice";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import { checkFinancepermission, check1pointpermission } from "../../Permission/permission";
import sort from "../../images/sort.svg";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import FormControl from "@material-ui/core/FormControl";

const MigrationOnGoing = () => {

  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [backdropopen, setbackdropopen] = useState(false);
  const [deleteEnquiry, setDeleteEnquiry] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const history = useHistory();
  const [sortState, setSortState] = useState("created");
  const [direction, setDirection] = useState("DESC");
  const [pageNo, setPageNo] = useState(0);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [enquiry, setEnquiry] = useState(null);
  const [display1, setDisplay] = useState("inline");
  const [value, setValue] = React.useState(0);

  const isw_user_name = localStorage.getItem("isw_user_name");
  const [deletedUser, setDeletedUser] = useState();
  const [totalApplication, setTotalApplication] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberofElements] = useState();
  const [rows, setRows] = React.useState(50);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const [enquiryStatusData, setEnquiryStatusData] = useState("OnGoing");
  const [enquirySourceType, setEnquirySourceType] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [selectedCource, setSelectedCource] = useState(null);
  const [selectedCurrentStage, setSelectedCurrentStage] = useState(null);
  const [selectedIntake, setSelectedIntake] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [WorkFlowStatusData, setWorkFlowStatusData] = useState("");
  const [selectedOffice, setSelectedOffice] = useState(
    Number(user?.isw_user_role) === 1 || Number(user?.isw_user_role) === 6
      ? null
      : user?.isw_available_Office[0].officeId
  );
  const [searchStatus, setSearchStatus] = useState();
  const [searchData, setSearchData] = useState(null);
  let headData;
  let office_id;

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    const office_obj = [];
    for (let i = 0; i < user?.isw_available_Office?.length; i++) {
      const element = user?.isw_available_Office?.[i];
      console.log(element);
      office_obj.push(element);
    }
    let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
    setSelectedOffice(new_id);
    getPermissions();
  }, [rows]);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [enquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(508, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(51));

      if (user?.isw_user_role === "1" || user?.isw_user_role === "6") {
        if (localStorage.getItem("paymentProcessPageNo")) {
          let currentPage = localStorage.getItem("paymentProcessPageNo");
          setPageNo(currentPage);
          setPaginationPageNo(Number(currentPage) + 1);
          headData = {
            Authorization: jwtToken,
            columnName: "created",
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            direction: "DESC",
            enquiryStatus: enquiryStatusData,
            institutionId: selectedInstitution,
            intake: selectedIntake,
            officeId: selectedOffice,
            page: Number(0),
            query: searchData,
            size: rows,
            sourceType: enquirySourceType,
            status: selectedStatus,
            workflowType: "Migration",
          };
          getAllData(headData);
        } else {
          setPageNo(0);
          setPaginationPageNo(1);
          headData = {
            Authorization: jwtToken,
            columnName: "created",
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            direction: "DESC",
            enquiryStatus: enquiryStatusData,
            institutionId: selectedInstitution,
            intake: selectedIntake,
            officeId: selectedOffice,
            page: Number(0),
            query: searchData,
            size: rows,
            sourceType: enquirySourceType,
            status: selectedStatus,
            workflowType: "Migration",
          };
          getAllData(headData);
        }
      } else {
        if (localStorage.getItem("paymentProcessPageNo")) {
          let currentPage = localStorage.getItem("paymentProcessPageNo");
          setPageNo(currentPage);
          setPaginationPageNo(Number(currentPage) + 1);
          const office_obj = [];
          for (let i = 0; i < user?.isw_available_Office?.length; i++) {
            const element = user?.isw_available_Office?.[i];
            console.log(element);
            office_obj.push(element);
          }
          let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
          office_id = new_id;
          setUserOfficeId(new_id);

          headData = {
            Authorization: jwtToken,
            columnName: "created",
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            direction: "DESC",
            enquiryStatus: enquiryStatusData,
            institutionId: selectedInstitution,
            intake: selectedIntake,
            officeId: new_id,
            page: Number(0),
            query: searchData,
            size: rows,
            sourceType: enquirySourceType,
            status: selectedStatus,
            workflowType: "Migration",
          };
          getAllData(headData);
        } else {
          setPageNo(0);
          setPaginationPageNo(1);
          const office_obj = [];
          for (let i = 0; i < user?.isw_available_Office?.length; i++) {
            const element = user?.isw_available_Office?.[i];
            console.log(element);
            office_obj.push(element);
          }
          let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
          office_id = new_id;
          setUserOfficeId(new_id);

          headData = {
            Authorization: jwtToken,
            columnName: "created",
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            direction: "DESC",
            enquiryStatus: enquiryStatusData,
            institutionId: selectedInstitution,
            intake: selectedIntake,
            officeId: new_id,
            page: Number(0),
            query: searchData,
            size: rows,
            sourceType: enquirySourceType,
            status: selectedStatus,
            workflowType: "Migration",
          };
          getAllData(headData);
        }
      }
      getFilterData();
      getIntakeData();
    } else {
      history.push("/errorPage");
    }
  };

  const [allIntake, setAllIntake] = useState();
  const getIntakeData = (selectedOffice) => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              createdIntake: moment(intakeData[i].createdIntake).format(
                "YYYY-MM"
              ),
              val: moment(intakeData[i].name).format("YYYYMM"),
              id: intakeData[i].id,
              name: intakeData[i].name,
              status: intakeData[i].status,
            };
            console.log(data);
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        },
        (error) => {
          setAllIntake();
          handle403error(error);
        }
      );
  };

  const [providerData, setProviderData] = useState();
  const [courseData, setCourseData] = useState();
  const [currentStageData, setCurrentStageData] = useState();
  const getFilterData = () => {
    axios
      .get(`${base_url.api2}/getDataByEnquiryStatus`, {
        headers: {
          Authorization: jwtToken,
          enquiryStatus: "OnGoing",
        },
      })
      .then(
        (response) => {
          setProviderData(response.data.institution_data);
          setCourseData(response.data.course_data);
          setCurrentStageData(response.data.current_stage_data);
        },
        (error) => {
          setProviderData();
          setCourseData();
          setCurrentStageData();
          handle403error(error);
        }
      );
  };
  const [getPageNo, setGetPageNo] = useState();
  const handlePage = (event, value) => {
    setPageNo(value - 1);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      workflowType: "Admission",
      page: Number(value - 1),
      size: rows,
    };
    getAllData(headData);
    setPaginationPageNo(value);
  };

  const deleteSelectedEnquiry = (elem) => {
    axios
      .delete(`${base_url.api2}/deleteEnquiryById`, {
        headers: {
          Authorization: jwtToken,
          enquiryId: deleteEnquiry,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.data.message);
          let logs = isw_user_name + " Deleted Enquiry of " + deletedUser + " Delete Invoice " + deleteEnquiry;
          callsaveauditapi(logs);
          headData = {
            Authorization: jwtToken,
            enquiryStatus: enquiryStatusData,
            officeId: selectedOffice,
            sourceType: enquirySourceType,
            institutionId: selectedInstitution,
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            intake: selectedIntake,
            status: selectedStatus,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageNo),
            size: rows,
          };
          getAllData(headData);
          setIsOpen(false);
        },
        (error) => {
          handle403error(error);
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };

  var subtitle;
  const openModal = (elem, p) => {
    console.log(elem.target.id);
    setDeletedUser(p.clientUsername);
    setDeleteEnquiry(elem.target.id);
    setIsOpen(true);
  };
  const [userrole, setuserrole] = useState();
  const [assignconsultantid, setassignconsultantid] = useState();
  const openModal2 = (p) => {
    setassignconsultantid(p.enquiryId);
    axios
      .get(`${base_url.api2}/getUserByRoleId`, {
        headers: {
          Authorization: jwtToken,
          roleId: 4,
          direction: "ASC",
          columnName: "firstName",
        },
      })
      .then(
        (res) => {
          console.log("User by role", res.data.data);
          setuserrole(res.data.data);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );

    setIsOpen2(true);
  };
  const [selecteduserrole, setselecteduserrole] = useState(null);
  const handleChange4 = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedcourseId = optionElement.getAttribute("id");
    console.log(selectedcourseId);
    setselecteduserrole(selectedcourseId);
  };

  const submit = (e, p) => {
    let enquiryId = Number(assignconsultantid);
    let consultantId = Number(selecteduserrole);
    let consultantName = e.target[0].value;
    let data = {
      enquiryId,
      consultantId,
      consultantName,
    };
    console.log(data);
    postConsultant(data);
  };
  const postConsultant = (data) => {
    console.log("Page", pageNo);

    axios
      .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          console.log(response.data.data);
          toast.success("Success");
          setIsOpen2(false);
          headData = {
            Authorization: jwtToken,
            enquiryStatus: enquiryStatusData,
            officeId: selectedOffice,
            sourceType: enquirySourceType,
            institutionId: selectedInstitution,
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            intake: selectedIntake,
            status: selectedStatus,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageNo),
            size: rows,
          };
          getAllData(headData);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen3(false);
    setIsOpen2(false);
    setIsOpen(false);
  };

  const classes = useStyles();

  const viewWorkflow = (p, e) => {
    localStorage.setItem("paymentProcessPageNo", pageNo);
    localStorage.setItem("selected_client_username", p.clientUsername);
    localStorage.setItem("selected_client_id", p.clientId);
    localStorage.setItem("prev_path2", "/application-ongoing");
    localStorage.setItem("prev_path", "/application-ongoing");
    dispatch(getworkflowType(p.workflowType));
    dispatch(gettabvalue(0));
    history.push(
      "/view-enquiry-workflowList/" + p.clientId + "/" + p.clientUsername
    );
  };

  const [workflowName, setworkflowName] = useState();
  const handleChangeWorkflowType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    // setSelectedrole(optionElementId);
    console.log(optionElementId);
    axios
      .get(`${base_url.api2}/getWorkflowByWorkflowType`, {
        headers: {
          Authorization: jwtToken,
          workflowType: optionElementId,
        },
      })
      .then((response) => {
        console.log(response);
        setworkflowName(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };
  const [workflowId, setWorkflowId] = useState();
  const handleChangeworkflowName = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    setWorkflowId(optionElementId);
  };
  const [clientId, setclientId] = useState();
  const [enquiryId, setenquiryId] = useState();
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const openAssignWorkflow = (p) => {
    setclientId(p.clientId);
    setenquiryId(p.enquiryId);
    setIsOpen3(true);
  };

  const assignWorkflow = (e, p) => {
    console.log("PageNo", pageNo);
    let data = {
      clientId: Number(clientId),
      enquiryId: Number(enquiryId),
      enquiryWorkflowId: Number(0),
      workflowId: Number(workflowId),
      workflowType: e.target[0].value,
      status: Number(e.target[2].value),
    };
    console.log("Data", data);
    axios
      .post(`${base_url.api2}/createEnquiryWorkflow`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status_code == 200) {
          console.log(response);

          if (response.data.data.enquiryWorkflowSteps.length) {
            let data = {
              enquiryId: response.data.data.enquiryId,
              currentStageId: response.data.data.enquiryWorkflowSteps[0].id,
            };
            axios
              .post(`${base_url.api2}/updateEnquiryByEnquiryId`, data, {
                headers: {
                  Authorization: jwtToken,
                },
              })
              .then((response) => {
                console.log(response.data.data);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            return null;
          }

          toast.success("Success");
          headData = {
            Authorization: jwtToken,
            enquiryStatus: enquiryStatusData,
            officeId: selectedOffice,
            sourceType: enquirySourceType,
            institutionId: selectedInstitution,
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            intake: selectedIntake,
            status: selectedStatus,
            query: searchData,
            columnName: sortState,
            direction: direction,
            page: Number(pageNo),
            size: rows,
          };
          getAllData(headData);
          setIsOpen3(false);
          setIsOpen(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        handle403error(error);
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  // const handlesearch = (e, value) => {
  //   setDisplay("none");
  //   setPageNo(value - 1);
  //   setPaginationPageNo(value);
  //   // axios
  //   //   .get(`${base_url.api2}/searchEnquiry`, {
  //   //     headers: {
  //   //       Authorization: jwtToken,
  //   //       searchQuery: searchData,
  //   //       page: Number(value - 1),
  //   //       size: rows,
  //   //     },
  //   //   })
  //   //   .then(
  //   //     (res) => {
  //   //       console.log(res.data.data);
  //   //       setGetPageNo(res.data.data.totalPages);
  //   //       setEnquiry(res.data.data.content);
  //   //       setSearchStatus(true);
  //   //       setbackdropopen(false);
  //   //     },
  //   //     (error) => {
  //   //       setbackdropopen(false);
  //   //       // console.log(error);
  //   //     }
  //   //   );
  // };

  const sortClient = (e) => {
    setSortState(e.target.id);
    if (direction == "DESC") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("DESC");
    }

    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: e.target.id,
      direction: direction,
      workflowType: "Admission",
      page: Number(pageNo),
      size: rows,
    };
    getAllData(headData);
  };

  const [userOfficeId, setUserOfficeId] = useState(null);

  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    // setSelectedOffice(optionElementId);

    if (optionElementId === "all") {

      const office_obj = [];
      for (let i = 0; i < user?.isw_available_Office?.length; i++) {
        const element = user?.isw_available_Office?.[i];
        console.log(element);
        office_obj.push(element);
      }
      let new_id = Array.prototype.map.call(office_obj, function (item) { return item.officeId; }).join(",");
      console.log(new_id);
      setSelectedOffice(new_id);
      setUserOfficeId(new_id);

      headData = {
        Authorization: jwtToken,
        enquiryStatus: enquiryStatusData,
        officeId: new_id,
        sourceType: enquirySourceType,
        institutionId: selectedInstitution,
        courseId: selectedCource,
        currentStage: selectedCurrentStage,
        intake: selectedIntake,
        status: selectedStatus,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(pageNo),
        size: rows,
        workflowType: "Migration"
      };
      getAllData(headData);
    } else {
      setSelectedOffice(optionElementId);
      headData = {
        Authorization: jwtToken,
        enquiryStatus: enquiryStatusData,
        officeId: optionElementId,
        sourceType: enquirySourceType,
        institutionId: selectedInstitution,
        courseId: selectedCource,
        currentStage: selectedCurrentStage,
        intake: selectedIntake,
        status: selectedStatus,
        query: searchData,
        columnName: sortState,
        direction: direction,
        page: Number(pageNo),
        size: rows,
        workflowType: "Migration"
      };
      getAllData(headData);
    }
  };

  const handleChangeProvider = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    setSelectedInstitution(optionElementId);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: optionElementId,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
      workflowType: "Migration"
    };
    getAllData(headData);
  };

  const handleChangeCourse = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    setSelectedCource(optionElementId);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: optionElementId,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
      workflowType: "Migration"
    };
    getAllData(headData);
  };

  const enquirySearch = (e) => {
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setSearchStatus(true);
    setPageNo(0);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: srchdata,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: rows,
      workflowType: "Migration"
    };
    getAllData(headData);
  };

  const handleChangeCurrentStage = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    setSelectedCurrentStage(optionElementId);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: optionElementId,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
      workflowType: "Migration"
    };
    getAllData(headData);
  };

  const handleChangeSourceType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    setEnquirySourceType(optionElementId);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: optionElementId,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
      workflowType: "Migration"
    };
    getAllData(headData);
  };

  const handleChangeIntake = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    setSelectedIntake(optionElementId);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: optionElementId,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
      workflowType: "Migration"
    };
    getAllData(headData);
  };

  const handleChangeStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("value");
    setSelectedStatus(optionElementId);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: optionElementId,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
    };
    getAllData(headData);
  };

  const handleAction = (actionType) => {
    setbackdropopen(true);
    if (actionType === 'DOWNLOAD') {
      axios
        .get(`${base_url.api2}/getEnquiryByTabFilter`, {
          headers: {
            Authorization: jwtToken,
            enquiryStatus: enquiryStatusData,
            officeId: selectedOffice,
            sourceType: enquirySourceType,
            institutionId: selectedInstitution,
            courseId: selectedCource,
            currentStage: selectedCurrentStage,
            intake: selectedIntake,
            status: selectedStatus,
            query: searchData,
            columnName: sortState,
            direction: direction,
            workflowType: "Admission",
            page: Number(0),
            size: Number(pdflength),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  };

  const [pdflength, setpdflength] = useState();
  const [pdfHeader, setPdfHeader] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  const getAllData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByTabFilter`, {
        headers: headData,
      })
      .then(
        (response) => {
          console.log(response.data.data.content);
          headData.size = response.data.data.totalElements;
          if (response.data.data.totalElements == 0) {
            headData.size = Number(50);
            setpdflength(50);
          } else {
            // setpdflength(response.data.data.totalElements);
            setpdflength(50);
          }
          getPdfData(headData.size = Number(50));
          setGetPageNo(response.data.data.totalPages);
          setTotalApplication(response.data.data.totalElements);
          setCountNumber(response.data.data.number);
          setNumberofElements(response.data.data.numberOfElements);
          // setRows(response.data.data.size);
          setEnquiry(response.data.data.content);
          setbackdropopen(false);
        },
        (error) => {
          if (error.response) {
            toast.error(JSON.stringify(error.response.data.message));
          } else {
            toast.error(JSON.stringify("Something Went Wrong"));
          }
          handle403error(error);
          setGetPageNo(0);
          setTotalApplication(0);
          setCountNumber(0);
          setNumberofElements(0);
          // setRows(0);
          setEnquiry();
          setbackdropopen(false);
        }
      );
  };

  const getPdfData = (headData) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByTabFilter`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const exportPdfInvoiceList = () => {
    let headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      query: searchData,
      columnName: sortState,
      direction: direction,
      workflowType: "Admission",
      page: Number(0),
      size: Number(pdflength),
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getEnquiryByTabFilter`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Lead Details",
          "Created Date",
          "EnquirySource",
          "Current Status",
          "Provider",
          "Course",
          "Intake",
          "Updated Date",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.clientName +
            "\n" +
            element.clientUsername +
            "\n" +
            "Phone: " +
            element.clientPhoneNumber,
            "Created Date: " +
            moment(element.created).format("DD/MM/YYYY hh:mm:ss"),
            element.enquirySource,
            element.currentStage,
            element.institutionName,
            element.courseName,
            element.intake,
            "Updated Date: " +
            moment(element.updated).format("DD/MM/YYYY hh:mm:ss"),
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("Invoice_list.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  const handleChangeWorkFlowStatus = (e) => {
    setbackdropopen(true);
    setWorkFlowStatusData(e.target.value);
    headData = {
      Authorization: jwtToken,
      enquiryStatus: enquiryStatusData,
      officeId: selectedOffice,
      sourceType: enquirySourceType,
      institutionId: selectedInstitution,
      courseId: selectedCource,
      currentStage: selectedCurrentStage,
      intake: selectedIntake,
      status: selectedStatus,
      workflowType: e.target.value,
      query: searchData,
      columnName: sortState,
      direction: direction,
      page: Number(pageNo),
      size: rows,
    };
    getAllData(headData);
  };

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  return (
    <div>
      <div>
        {/* <Row>
          <Col md={6}>
            <CardText
              className={classes.headtext}
              style={{ marginTop: "-10px" }}
            >
              <strong>Application OnGoing Follow-Up Sheet</strong>
            </CardText>
          </Col>
        </Row> */}
        <ToastContainer />
        <Backdrop className={classes.backdrop} open={backdropopen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row>
            <Col md={3}>
              <div>
                <label for="" className="s_label_color">Limit</label>
                <select className="form-control"
                  name="branch"
                  value={rows}
                  onChange={handleRows}
                  style={{ width: '95%', backgroundColor: "#f5f5f9" }}
                  class="form-select"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Branch</label>
                <select
                  className="form-control"
                  onChange={handleChangeOffice}
                  name="branch"
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  {Number(user?.isw_user_role) === 1 ||
                    Number(user?.isw_user_role) === 6 ||
                    Number(user?.isw_user_role) === 10 ||
                    Number(user?.isw_user_role) === 3 ||
                    Number(user?.isw_user_role) === 2 ||
                    Number(user?.isw_user_role) === 5 ||
                    Number(user?.isw_user_role) === 4 ? (
                    <option value="all">Show All</option>
                  ) : null}
                  {user?.isw_available_Office?.map((p, index) => (
                    <option key={index} value={p.officeId}>
                      {p.officeName}
                      {" - "}
                      {p.officeCode}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Provider</label>
                <select
                  className="form-control"
                  onChange={handleChangeProvider}
                  name="branch"
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">-Select Provider-</option>
                  {providerData?.map((p, index) => (
                    <option key={index} value={p.institutionId}>
                      {p.institutionName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Course</label>
                <select
                  className="form-control"
                  onChange={handleChangeCourse}
                  name="branch"
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">-Select Course-</option>
                  {courseData?.map((p, index) => (
                    <option key={index} value={p.courseId}>
                      {p.courseName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Current Stage</label>
                <select
                  className="form-control"
                  onChange={handleChangeCurrentStage}
                  name="branch"
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">-Select Current Stage-</option>
                  {currentStageData?.map((p, index) => (
                    <option key={index} value={p.currentStage}>
                      {p.currentStage}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Source</label>
                <select
                  className="form-control"
                  onChange={handleChangeSourceType}
                  name="sourceType"
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">-Select Source Type-</option>
                  <option value="online">Online</option>
                  <option value="walkin">Walk In</option>
                </select>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <label className="area_rowslable_my">Intake</label>
                <select
                  className="form-control"
                  onChange={handleChangeIntake}
                  name="intake"
                  style={{ width: "95%", backgroundColor: "#f5f5f9" }}
                >
                  <option value="null">- Select Intake -</option>
                  {allIntake?.map((cuntitem) => (
                    <option
                      key={cuntitem.id}
                      value={cuntitem.name}
                      id={cuntitem.id}
                    >
                      {cuntitem.createdIntake}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            {/* <Col md={3}>
            <div>
              <label className="area_rowslable_my">Status</label>
              <select
                className="form-control"
                onChange={handleChangeStatus}
                name="status"
                style={{ width: "95%",backgroundColor:"#f5f5f9"  }}
              >
                <option value="1">Active</option>
                <option value="0">InActive</option>
              </select>
            </div>
          </Col> */}
            <Col md={3}>
              <label className="area_rowslable_my">Search</label>
              <div className="clearsearchclass" style={{ width: "95%" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    enquirySearch(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchStatus ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setDisplay("inline");
                    setPageNo(0);
                    headData = {
                      Authorization: jwtToken,
                      enquiryStatus: enquiryStatusData,
                      officeId: selectedOffice,
                      sourceType: enquirySourceType,
                      institutionId: selectedInstitution,
                      courseId: selectedCource,
                      currentStage: selectedCurrentStage,
                      intake: selectedIntake,
                      status: selectedStatus,
                      query: null,
                      columnName: sortState,
                      direction: direction,
                      page: Number(0),
                      size: rows,
                      workflowType: "Migration",
                    };
                    getAllData(headData);
                    setSearchStatus(false);
                    document.getElementById("myForm").reset();
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </Col>
          </Row>

          {/* <Row>
        <Col md={3}>
            <label className="area_rowslable_my">WorkFlow Type:</label>
            <select
              className="form-control"
              name="workflowName"
              value={WorkFlowStatusData}
              style={{ backgroundColor: "#f5f5f9", width: "95%" }}
              onChange={handleChangeWorkFlowStatus}
            >
              <option value="null">- Select WorkFlow Type -</option>
              <option value="Admission">Admission</option>
              <option value="Migration">Migration</option>
              <option value="Insurance">Insurance</option>
              <option value="SkillAssessment">SkillAssessment</option>
            </select>
          </Col>
        </Row> */}

        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi" marginBottom="40px">
            <Col md={6}>
              {enquiry?.length ? (
                <p className="s_para">
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalApplication}{" "}
                  records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6} style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(
                  519,
                  permissionData,
                  rolepermission
                ) ? (

                  <button
                    className="btn listing_addbutton"
                    onClick={exportPdfInvoiceList}
                  >
                    Export PDF
                  </button>
                ) : null}
                {check1pointpermission(
                  519,
                  permissionData,
                  rolepermission
                ) ? (
                  <>
                    {dataForDownload != null ? (
                      <>
                        <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')} >Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="Invoice_list.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              {/* <table
            id="example"
            className="table workload card-table table-borderless table-responsive"
            style={{ width: "100%" }}
          > */}
              <thead className="s_sticky">
                <tr style={{ color: "#6d7290" }}>
                  {/* <th style={{ minWidth: "60px", paddingLeft: "0" }}>S. No.</th> */}
                  <th style={{ minWidth: "40px" }}>SN </th>
                  <th style={{ minWidth: "110px" }}>
                    DATE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="created"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CLIENT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="clientUsername"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    CONTACT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="clientUsername"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {/* <th>
                    Phone{" "}
                    <UnfoldMoreIcon
                      id="clientPhoneNumber"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th> */}
                  {/* <th style={{ minWidth: "120px" }}>
                  SOURCE 
                  <img
                    src={sort}
                    className="sorting"
                    id="enquirySource"
                    onClick={sortClient}
                    style={{ cursor: "pointer", display: `${display1}` }}
                  />
                </th> */}
                  <th style={{ minWidth: "130px" }}>
                    CURRENT STATUS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="currentStage"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    VISA TYPE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="visaType"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    VISA EXPIRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="visaExpiryDate"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "135px" }}>
                    VISA OFFICE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="institutionName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    BRANCH {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="office_name"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    CONSULTANT {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="consultantName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    FOLLOW-UP {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="followUpDate"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    DEADLINE {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="deadLineDate"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>
                    UPDATED {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="updated"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  {/* {check1pointpermission(502,permissionData,rolepermission)  || check1pointpermission( 503,permissionData,rolepermission ) ? (  
                  <th style={{ minWidth: "100px" }}>ACTION</th>
                ) : null} */}
                </tr>
              </thead>
              {enquiry != null ? (
                <tbody>
                  {enquiry?.map((p, index) => (
                    <tr key={p.enquiryId} style={{ color: "#6d7290" }}>
                      <td>{countNumber * rows + 1 + index}</td>
                      <td>
                        {p.created
                          ? moment(p.created.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      <td>
                        <a
                          className="greeneffect"
                          onClick={(e) => viewWorkflow(p, e)}
                          style={{ width: "135px", wordWrap: "break-word", display: "inline-block" }}
                          href={
                            `#/view-enquiry-workflow/` +
                            p.clientId +
                            `/` +
                            p.enquiryId +
                            `/` +
                            p.clientUsername
                          }
                        >
                          {p.clientName}
                        </a>
                        <br />
                        <div style={{ width: "135px", wordWrap: "break-word", display: "inline-block" }}>
                          {p.clientUsername}
                        </div>
                      </td>
                      <td>
                        <div style={{ width: "110px", wordWrap: "break-word", display: "inline-block" }}>
                          {p.clientPhoneNumber == 0 ? " " : p.clientPhoneNumber}
                        </div>
                      </td>
                      {/* <td><div style={{width:"120px", wordWrap:"break-word",display:"inline-block"}}>{p.enquirySource}</div></td> */}
                      <td><div style={{ width: "135px", wordWrap: "break-word", display: "inline-block" }}>{p.currentStage}</div></td>
                      <td><div style={{ width: "130px", wordWrap: "break-word", display: "inline-block" }}>{p.visaType}</div></td>
                      <td>
                        {p.visaExpiryDate
                          ? moment(p.visaExpiryDate.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      <td><div style={{ width: "130px", wordWrap: "break-word", display: "inline-block" }}>{p.institutionName}</div></td>
                      <td><div style={{ width: "120px", wordWrap: "break-word", display: "inline-block" }}>{p.officeName}</div></td>
                      <td>
                        <>
                          <div class="s_tooltip">
                            {p.consultantName != null ? p.consultantName.split(" ")[0] : ""}
                            <span class="s_tooltiptext">
                              {p.consultantName}
                            </span>
                          </div>
                        </>
                      </td>
                      <td>
                        {p.followUpDate
                          ? moment(p.followUpDate.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      <td>
                        {p.deadLineDate
                          ? moment(p.deadLineDate.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      {/* <td>{p.status == "1" ? "Active" : "Inactive"}</td> */}
                      <td>
                        {p.updated
                          ? moment(p.updated.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      {check1pointpermission(502, permissionData, rolepermission) || check1pointpermission(503, permissionData, rolepermission) ? (
                        <td className="d-flex">
                          {/* {p.workflowId == null ? (
                          <button
                            className="btn listing_addbutton"
                            id={p.enquiryId}
                            onClick={() => openAssignWorkflow(p)}
                          >
                            Assign Workflow
                          </button>
                        ) : (
                          <>
                          {check1pointpermission(
                            502,
                            permissionData,
                            rolepermission
                          ) ? (
                              <a
                                className="btn listing_addbutton"
                                onClick={(e) => viewWorkflow(p, e)}
                                href={
                                  `#/view-enquiry-workflowList/` +
                                  p.clientId +
                                  `/` +
                                  p.clientUsername
                                }
                              >
                                View Workflow
                              </a>
                            ) : null}
                          </>
                        )}
                        &nbsp;&nbsp;
                        {p.consultantName == null ? (
                          <button
                            className="btn listing_addbutton"
                            id={p.enquiryId}
                            onClick={() => openModal2(p)}
                          >
                            Assign Consultant
                          </button>
                        ) : null}
                        &nbsp;&nbsp; */}
                          {/* <Modal
                          isOpen={modalIsOpen3}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              assignWorkflow(e, p);
                            }}
                          >
                            <div className="form-inner-admin">
                              <h3 style={{ color: "black" }}>
                                Assign Workflow
                              </h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Select Workflow Type:</label>
                                    <select
                                      className={classes.selectdrop}
                                      onChange={handleChangeWorkflowType}
                                      required
                                    >
                                      <option value="">
                                        - Select Workflow Type -
                                      </option>
                                      <option value="Admission">
                                        Admission
                                      </option>
                                      <option value="Migration">
                                        Migration
                                      </option>
                                      <option value="Insurance">
                                        Insurance
                                      </option>
                                      <option value="SkillAssessment">
                                        Skill Assessment
                                      </option>
                                    </select>
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Workflow Name:</label>
                                    <select
                                      className={classes.selectdrop}
                                      onChange={handleChangeworkflowName}
                                      required
                                    >
                                      <option value="">
                                        - Select Workflow -
                                      </option>
                                      {workflowName?.map((cuntitem) => (
                                        <option
                                          key={cuntitem.workflowId}
                                          id={cuntitem.workflowId}
                                          value={cuntitem.workflowName}
                                        >
                                          {cuntitem.workflowName}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Status:</label>
                                    <select
                                      className={classes.selectdrop}
                                      required
                                    >
                                      <option id="1" value="1">
                                        Active
                                      </option>
                                      <option id="0" value="0">
                                        InActive
                                      </option>
                                    </select>
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row className="justify-content-end mt-2">
                                  <div>
                                    <Button
                                      color="secondary"
                                      onClick={closeModal}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn listing_addbutton ml-1"
                                    >
                                      Update
                                    </button>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </Modal>
                        <Modal
                          isOpen={modalIsOpen2}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Assign Consultant
                          </h2>
                          <hr />
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submit(e, p);
                            }}
                          >
                            
                            {userrole == null ? (
                              <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                              </Backdrop>
                            ) : (
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col md={12}>
                                    <label>Select Consultant Name:</label>
                                    <select
                                      className={classes.selectdrop}
                                      onChange={handleChange4}
                                      required
                                    >
                                      <option value="">
                                        - Select Consultant -
                                      </option>
                                      {userrole.map((cuntitem) => (
                                        <option
                                          key={cuntitem.id}
                                          id={cuntitem.id}
                                          value={`${
                                            cuntitem.firstName +
                                            "" +
                                            cuntitem.lastName
                                          }`}
                                        >
                                          {cuntitem.firstName +
                                            " " +
                                            cuntitem.lastName}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            <button
                              type="submit"
                              className="btn listing_addbutton"
                            >
                              Submit
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="secondary" onClick={closeModal}>
                              Cancel
                            </Button>
                          </form>
                        </Modal> */}
                          {/* {check1pointpermission(
                          503,
                          permissionData,
                          rolepermission
                        ) ? (
                          <button
                            className="role-deletebtn"
                            id={p.enquiryId}
                            onClick={(e) => {
                              e.preventDefault();
                              openModal(e, p);
                            }}
                          >
                            Delete
                          </button>
                        ) : null} */}
                          <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h2
                              style={{ color: "#071a52" }}
                              ref={(_subtitle) => (subtitle = _subtitle)}
                            >
                              Delete Enquiry ?
                            </h2>
                            <button
                              className="sent-button"
                              onClick={deleteSelectedEnquiry}
                            >
                              Delete
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <Button color="secondary" onClick={closeModal}>
                              Cancel
                            </Button>
                          </Modal>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Typography>{null}</Typography>
              )}
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                  <br />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                    <select
                      className={classes.selectlimit}
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                  {enquiry?.length ? (
                    <p className="s_spanPage">
                      {countNumber * rows + 1} to{" "}
                      {countNumber * rows + numberOfElements} of {totalApplication}{" "}
                      records
                    </p>
                  ) : (
                    <p className="s_spanPage">No Record Found</p>
                  )}
                </div>
              </form>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                <Pagination
                  page={paginationPageNo}
                  count={getPageNo}
                  onChange={handlePage}
                  shape="rounded"
                />
              </div>
            </Col>
          </Row>
          {/* } */}
        </div>
      </div>
    </div>
  )
}

export default MigrationOnGoing