import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast, ToastContainer } from "react-toastify";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import { selectAdmin } from "./../features/adminSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import { CSVLink } from "react-csv";

import jsPDF from "jspdf";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles } from "../../Utilities/CSSUtilities";

function PayableInvoiceReports(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  //   console.log(alladmin);
  const history = useHistory();
  const jwtToken = "Bearer " + user.isw_user_token;
  const [backdropopen, setbackdropopen] = useState(false);
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("invoiceCreatedDate");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  // const [invoiceStatusState, setInvoiceStatusState] = useState("Paid");
  const [invoiceStatusState, setInvoiceStatusState] = useState("Active");
  const [providerId, setproviderId] = useState(null);

  const [intakeId, setintakeId] = useState(null);

  const datepast = moment().subtract(12, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];

  const [officeid, setofficeid] = useState();

  const [admissionEnquiry, setadmissionEnquiry] = useState();
  const [admissionEnquiryPageNo, setadmissionEnquiryPageNo] = useState();
  const [
    admissionEnquirynumberofelements,
    setadmissionEnquirynumberofelements,
  ] = useState();
  const [admissionEnquirytotaluser, setadmissionEnquirytotaluser] = useState();
  const [admissionEnquirycountno, setadmissionEnquirycountno] = useState();

  const [migrationEnquiry, setmigrationEnquiry] = useState();
  const [migrationEnquiryPageNo, setmigrationEnquiryPageNo] = useState();
  const [
    migrationEnquirynumberofelements,
    setmigrationEnquirynumberofelements,
  ] = useState();
  const [migrationEnquirytotaluser, setmigrationEnquirytotaluser] = useState();
  const [migrationEnquirycountno, setmigrationEnquirycountno] = useState();

  const [insuranceEnquiry, setinsuranceEnquiry] = useState();
  const [insuranceEnquiryPageNo, setinsuranceEnquiryPageNo] = useState();
  const [
    insuranceEnquirynumberofelements,
    setinsuranceEnquirynumberofelements,
  ] = useState();
  const [insuranceEnquirytotaluser, setinsuranceEnquirytotaluser] = useState();
  const [insuranceEnquirycountno, setinsuranceEnquirycountno] = useState();

  const [skillassementEnquiry, setskillassementEnquiry] = useState();
  const [skillassementEnquiryPageNo, setskillassementEnquiryPageNo] =
    useState();
  const [
    skillassessmentEnquirynumberofelements,
    setskillassessmentEnquirynumberofelements,
  ] = useState();
  const [skillassessmentEnquirytotaluser, setskillassessmentEnquirytotaluser] =
    useState();
  const [skillassessmentEnquirycountno, setskillassessmentEnquirycountno] =
    useState();

  const [invoiceOnlyPaginate, setinvoiceOnlyPaginate] = useState();
  const [invoiceInstituteOnlyPaginate, setinvoiceInstituteOnlyPaginate] =
    useState();
  const [invoiceInakeOnlyPaginate, setinvoiceInakeOnlyPaginate] = useState();
  const [invoiceInstituteInakePaginate, setinvoiceInstituteInakePaginate] =
    useState();
  const [permissionData, setPermissionData] = useState();
  const [currentWorkFlow, setCurrentWorkFlow] = useState("admission");

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [admissionEnquiry]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [migrationEnquiry]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [insuranceEnquiry]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [skillassementEnquiry]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    var available_resource = resource_obj.includes(53);
    var available_permission = permission_obj.includes(432);


    //if (available_permission){
    getWorkflowReports(pageno);
    getInstitution();
    getMigration();
    getInsurance();
    getIntake();
    dispatch(setdashboardcheck(53));
    // } else {
    //   history.push("/errorPage");
    // }

  }

  const [availableBranch, setAvailableBranch] = useState();
  const getInstitution = () => {
    axios
      .get(`${base_url.api2}/findAllInstitutionBranches`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableBranch(response.data.data);
      })
      .catch((error) => {
        setAvailableBranch();
        handle403error(error);
      });
  };

  const [availableMigration, setAvailableMigration] = useState();
  const getMigration = () => {
    axios
      .get(`${base_url.api1}/getAllMigrationProvider`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableMigration(response.data.data);
      })
      .catch((error) => {
        setAvailableMigration();
        handle403error(error);
      });
  };

  const [availableInsurance, setAvailableInsurance] = useState();
  const getInsurance = () => {
    axios
      .get(`${base_url.api1}/findAllInsurance`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setAvailableInsurance(response.data.data);
      })
      .catch((error) => {
        setAvailableInsurance();
        handle403error(error);
      });
  };

  const [availableIntake, setAvailableIntake] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let intakeData = response.data.data;
        let intakeObj = [];
        for (let i = 0; i < intakeData.length; i++) {
          let data = {
            createdIntake: moment(intakeData[i].createdIntake).format(
              "YYYY-MM"
            ),
            id: intakeData[i].id,
            name: intakeData[i].name,
            status: intakeData[i].status,
          };
          intakeObj.push(data);
        }
        setAvailableIntake(intakeObj);
      })
      .catch((error) => {
        setAvailableIntake();
        handle403error(error);
      });
  };

  const handlepageAdmission = (event, value) => {
    setpageno(value - 1);
    // console.log("Admission Clicked");
    if (invoiceStatusState == "Active") {
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                columnName: sortState,
                invoiceType: "Payable",
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  columnName: sortState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Admission",
                intake: intakeId,
                invoiceType: "Payable",
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    } else {
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  institutionId: Number(providerId),
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  invoiceStatus: invoiceStatusState,
                  intake: intakeId,
                  invoiceType: "Payable",
                  columnName: sortState,
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Admission",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setadmissionEnquiry(response.data.data.content);
              setadmissionEnquiryPageNo(response.data.data.totalPages);
              setadmissionEnquirytotaluser(response.data.data.totalElements);
              setadmissionEnquirycountno(response.data.data.number);
              setadmissionEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    }
  };

  const handlepageMigration = (event, value) => {
    setpageno(value - 1);
    // console.log("Migration Clicked");
    if (invoiceStatusState == "Active") {
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                columnName: sortState,
                invoiceType: "Payable",
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Migration",
                  columnName: sortState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                intake: intakeId,
                invoiceType: "Payable",
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Migration",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    } else {
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate)
          //Migration Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Migration",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setmigrationEnquiry(response.data.data.content);
              setmigrationEnquiryPageNo(response.data.data.totalPages);
              setmigrationEnquirytotaluser(response.data.data.totalElements);
              setmigrationEnquirycountno(response.data.data.number);
              setmigrationEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
      } else if (invoiceInstituteOnlyPaginate) {
        //Migration Reports
        axios
          .get(
            `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
            // dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                columnName: sortState,
                invoiceStatus: invoiceStatusState,
                institutionId: Number(providerId),
                invoiceType: "Payable",
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            handle403error(error);
          });
      } else if (invoiceInakeOnlyPaginate) {
        //Admission Reports
        axios
          .get(
            `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
            // dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                invoiceStatus: invoiceStatusState,
                intake: intakeId,
                invoiceType: "Payable",
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            handle403error(error);
          });
      } else if (invoiceInstituteInakePaginate) {
        //Admission Reports
        axios
          .get(
            `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
            // dateMap,
            {
              headers: {
                Authorization: jwtToken,
                workflowType: "Migration",
                columnName: sortState,
                invoiceStatus: invoiceStatusState,
                invoiceType: "Payable",
                institutionId: Number(providerId),
                direction: direction,
                page: Number(value - 1),
                intake: intakeId,
                size: Number(rows),
              },
            }
          )
          .then((response) => {
            setmigrationEnquiry(response.data.data.content);
            setmigrationEnquiryPageNo(response.data.data.totalPages);
            setmigrationEnquirytotaluser(response.data.data.totalElements);
            setmigrationEnquirycountno(response.data.data.number);
            setmigrationEnquirynumberofelements(
              response.data.data.numberOfElements
            );
          })
          .catch((error) => {
            handle403error(error);
          });
      }
    }
  };

  const handlepageInsurance = (event, value) => {
    setpageno(value - 1);
    // console.log("Insurance Clicked");
    if (invoiceStatusState == "Active") {
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                columnName: sortState,
                invoiceType: "Payable",
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  columnName: sortState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "Insurance",
                intake: intakeId,
                invoiceType: "Payable",
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    } else {
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //Insurance Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Insurance Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  institutionId: Number(providerId),
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Insurance Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  intake: intakeId,
                  columnName: sortState,
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "Insurance",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setinsuranceEnquiry(response.data.data.content);
              setinsuranceEnquiryPageNo(response.data.data.totalPages);
              setinsuranceEnquirytotaluser(response.data.data.totalElements);
              setinsuranceEnquirycountno(response.data.data.number);
              setinsuranceEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    }
  };

  const handlepageSkillAssessment = (event, value) => {
    setpageno(value - 1);
    // console.log("SkillAssessment Clicked");
    if (invoiceStatusState == "Active") {
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                columnName: sortState,
                invoiceType: "Payable",
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  columnName: sortState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Admission Reports
          axios
            .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
              headers: {
                Authorization: jwtToken,
                workflowType: "SkillAssessment",
                intake: intakeId,
                invoiceType: "Payable",
                columnName: sortState,
                direction: direction,
                page: Number(value - 1),
                size: Number(rows),
              },
            })
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    } else {
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      if (user.isw_user_role === 1 || user.isw_user_role === 6) {
        if (invoiceOnlyPaginate) {
          //SkillAssessment Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteOnlyPaginate) {
          //Migration Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  institutionId: Number(providerId),
                  invoiceType: "Payable",
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInakeOnlyPaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  intake: intakeId,
                  columnName: sortState,
                  direction: direction,
                  page: Number(value - 1),
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        } else if (invoiceInstituteInakePaginate) {
          //Admission Reports
          axios
            .get(
              `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
              // dateMap,
              {
                headers: {
                  Authorization: jwtToken,
                  workflowType: "SkillAssessment",
                  columnName: sortState,
                  invoiceStatus: invoiceStatusState,
                  invoiceType: "Payable",
                  institutionId: Number(providerId),
                  direction: direction,
                  page: Number(value - 1),
                  intake: intakeId,
                  size: Number(rows),
                },
              }
            )
            .then((response) => {
              setskillassementEnquiry(response.data.data.content);
              setskillassementEnquiryPageNo(response.data.data.totalPages);
              setskillassessmentEnquirytotaluser(
                response.data.data.totalElements
              );
              setskillassessmentEnquirycountno(response.data.data.number);
              setskillassessmentEnquirynumberofelements(
                response.data.data.numberOfElements
              );
            })
            .catch((error) => {
              handle403error(error);
            });
        }
      }
    }
  };

  const getWorkflowReports = (value1) => {
    setbackdropopen(true);
    //Admission Reports
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    setinvoiceOnlyPaginate(true);
    if (invoiceStatusState == "Active") {
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              columnName: sortState,
              invoiceStatus: invoiceStatusState,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              invoiceStatus: invoiceStatusState,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });

      //Insurance Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              columnName: sortState,
              invoiceStatus: invoiceStatusState,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              columnName: sortState,
              invoiceStatus: invoiceStatusState,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const handleApply = (event, picker) => {
    setpageno(0);
    setpageno(0);
    console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    console.log(data);
    // axios
    //   .post(`${base_url.api2}/searchUserByDate`, data, {
    //     headers: {
    //       Authorization: jwtToken,
    //       columnName: sortState,
    //       direction: direction,
    //       page: Number(pageno),
    //       size: Number(rows),
    //     },
    //   })
    //   .then(
    //     (response) => {
    //       console.log(response.data.data);
    //       var client_admin = response.data.data.content;
    //     //   setClientDetails(response.data.data.content);
    //       setgetpageno(response.data.data.totalPages);
    //       setpageno(0);
    //       setdatefilter(true);
    //       dispatch(getAdmin(client_admin));
    //     },
    //     (error) => {
    //       // toast.error(JSON.stringify(error.response.data.message));
    //       console.log(JSON.stringify(error.response));
    //     }
    //   );
  };

  const handleInvoiceStatus = (event) => {
    setbackdropopen(true);
    console.log(event.target.value);
    setInvoiceStatusState(event.target.value);
    if (providerId != null && intakeId === null) {
      // console.log("Provider------", providerId);
      setinvoiceOnlyPaginate(false);
      setinvoiceInstituteOnlyPaginate(true);
      setinvoiceInakeOnlyPaginate(false);
      setinvoiceInstituteInakePaginate(false);
      callOnInvoiceStatusInstituteChange(event.target.value, providerId);
    } else if (providerId === null && intakeId != null) {
      // console.log("Intake------", intakeId);
      setinvoiceOnlyPaginate(false);
      setinvoiceInstituteOnlyPaginate(false);
      setinvoiceInakeOnlyPaginate(true);
      setinvoiceInstituteInakePaginate(false);
      callOnInvoiceStatusIntakeChange(event.target.value, intakeId);
    } else if (providerId != null && intakeId != null) {
      // console.log("Provider------", providerId);
      // console.log("Intake------", intakeId);
      setinvoiceOnlyPaginate(false);
      setinvoiceInstituteOnlyPaginate(false);
      setinvoiceInakeOnlyPaginate(false);
      setinvoiceInstituteInakePaginate(true);
      callOnAllChange(event.target.value);
    } else if (providerId === null && intakeId === null) {
      // console.log("Invoice Type------", event.target.value);
      setinvoiceOnlyPaginate(true);
      setinvoiceInstituteOnlyPaginate(false);
      setinvoiceInakeOnlyPaginate(false);
      setinvoiceInstituteInakePaginate(false);
      callOnInvoiceStatusChange(event.target.value);
    }
  };

  const callOnInvoiceStatusChange = (data) => {
    setbackdropopen(true);
    if (data == "Active") {
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowType`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            columnName: sortState,
            invoiceType: "Payable",
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      //Admission Reports
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              columnName: sortState,
              invoiceStatus: data,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              invoiceStatus: data,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              columnName: sortState,
              invoiceStatus: data,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowType`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              columnName: sortState,
              invoiceStatus: data,
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const callOnInvoiceStatusInstituteChange = (data, providerId) => {
    setbackdropopen(true);
    if (data == "Active") {
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            institutionId: Number(providerId),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            institutionId: Number(providerId),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            institutionId: Number(providerId),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionId`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            institutionId: Number(providerId),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      // Admission Reports
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const callOnInvoiceStatusIntakeChange = (data, intakeId) => {
    setbackdropopen(true);
    if (data == "Active") {
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            intake: intakeId,
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            intake: intakeId,
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            intake: intakeId,
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(`${base_url.api4}/getAllInvoiceByWorkflowTypeAndIntake`, {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            intake: intakeId,
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      // Admission Reports
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              invoiceStatus: data,
              intake: intakeId,
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          setbackdropopen(false);
          handle403error(error);
        });

      //Migration Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              invoiceStatus: data,
              intake: intakeId,
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              invoiceStatus: data,
              intake: intakeId,
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              invoiceStatus: data,
              intake: intakeId,
              invoiceType: "Payable",
              columnName: sortState,
              direction: direction,
              page: Number(pageno),
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const callOnAllChange = (data) => {
    setbackdropopen(true);
    if (data == "Active") {
      axios
        .get(
          `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              columnName: sortState,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .get(
          `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(
          `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              columnName: sortState,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(
          `${base_url.api4}/getAllInvoiceByWorkflowTypeAndInstitutionIdAndIntake`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              columnName: sortState,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    } else {
      //Admission Reports
      let dateMap = {
        createdFromDate: datepast,
        createdToDate: datenow,
      };
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
              columnName: sortState,
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setadmissionEnquiry(response.data.data.content);
          setadmissionEnquiryPageNo(response.data.data.totalPages);
          setadmissionEnquirytotaluser(response.data.data.totalElements);
          setadmissionEnquirycountno(response.data.data.number);
          setadmissionEnquirynumberofelements(
            response.data.data.numberOfElements
          );
          setbackdropopen(false);
        })
        .catch((error) => {
          handle403error(error);
          setbackdropopen(false);
        });

      //Migration Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
              columnName: sortState,
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setmigrationEnquiry(response.data.data.content);
          setmigrationEnquiryPageNo(response.data.data.totalPages);
          setmigrationEnquirytotaluser(response.data.data.totalElements);
          setmigrationEnquirycountno(response.data.data.number);
          setmigrationEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //Insurance Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
              columnName: sortState,
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setinsuranceEnquiry(response.data.data.content);
          setinsuranceEnquiryPageNo(response.data.data.totalPages);
          setinsuranceEnquirytotaluser(response.data.data.totalElements);
          setinsuranceEnquirycountno(response.data.data.number);
          setinsuranceEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });

      //SkillAssessment Reports
      axios
        .get(
          `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
          // dateMap,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
              columnName: sortState,
              invoiceStatus: data,
              institutionId: Number(providerId),
              invoiceType: "Payable",
              direction: direction,
              page: Number(pageno),
              intake: intakeId,
              size: Number(rows),
            },
          }
        )
        .then((response) => {
          setskillassementEnquiry(response.data.data.content);
          setskillassementEnquiryPageNo(response.data.data.totalPages);
          setskillassessmentEnquirytotaluser(response.data.data.totalElements);
          setskillassessmentEnquirycountno(response.data.data.number);
          setskillassessmentEnquirynumberofelements(
            response.data.data.numberOfElements
          );
        })
        .catch((error) => {
          handle403error(error);
        });
    }
  };

  const handleProvider = (event) => {
    // console.log(event.target.value);
    // console.log(intakeId);
    if (event.target.value == "") {
      setproviderId(null);
      if (intakeId != null) {
        // console.log("Intake------", intakeId);
        setinvoiceOnlyPaginate(false);
        setinvoiceInstituteOnlyPaginate(false);
        setinvoiceInakeOnlyPaginate(true);
        setinvoiceInstituteInakePaginate(false);
        callOnInvoiceStatusIntakeChange(invoiceStatusState, intakeId);
      } else if (intakeId === null) {
        // console.log("Invoice Type------", event.target.value);
        setinvoiceOnlyPaginate(true);
        setinvoiceInstituteOnlyPaginate(false);
        setinvoiceInakeOnlyPaginate(false);
        setinvoiceInstituteInakePaginate(false);
        callOnInvoiceStatusChange(invoiceStatusState);
      }
    } else {
      if (intakeId === null) {
        console.log("Intake not present");
        setproviderId(event.target.value);
        filterByProvider(event.target.value);
      } else {
        console.log("Intake", intakeId);
        setproviderId(event.target.value);
        filterByProvider2(event.target.value, intakeId);
      }
    }
  };
  const filterByProvider = (data) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    // Admission Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setadmissionEnquiry(response.data.data.content);
        setadmissionEnquiryPageNo(response.data.data.totalPages);
        setadmissionEnquirytotaluser(response.data.data.totalElements);
        setadmissionEnquirycountno(response.data.data.number);
        setadmissionEnquirynumberofelements(
          response.data.data.numberOfElements
        );
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });

    //Migration Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setmigrationEnquiry(response.data.data.content);
        setmigrationEnquiryPageNo(response.data.data.totalPages);
        setmigrationEnquirytotaluser(response.data.data.totalElements);
        setmigrationEnquirycountno(response.data.data.number);
        setmigrationEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //Insurance Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setinsuranceEnquiry(response.data.data.content);
        setinsuranceEnquiryPageNo(response.data.data.totalPages);
        setinsuranceEnquirytotaluser(response.data.data.totalElements);
        setinsuranceEnquirycountno(response.data.data.number);
        setinsuranceEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //SkillAssessment Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionId`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setskillassementEnquiry(response.data.data.content);
        setskillassementEnquiryPageNo(response.data.data.totalPages);
        setskillassessmentEnquirytotaluser(response.data.data.totalElements);
        setskillassessmentEnquirycountno(response.data.data.number);
        setskillassessmentEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const filterByProvider2 = (data, intakeId) => {
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    // Admission Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            intake: intakeId,
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setadmissionEnquiry(response.data.data.content);
        setadmissionEnquiryPageNo(response.data.data.totalPages);
        setadmissionEnquirytotaluser(response.data.data.totalElements);
        setadmissionEnquirycountno(response.data.data.number);
        setadmissionEnquirynumberofelements(
          response.data.data.numberOfElements
        );
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });

    //Migration Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            intake: intakeId,
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setmigrationEnquiry(response.data.data.content);
        setmigrationEnquiryPageNo(response.data.data.totalPages);
        setmigrationEnquirytotaluser(response.data.data.totalElements);
        setmigrationEnquirycountno(response.data.data.number);
        setmigrationEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //Insurance Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            intake: intakeId,
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setinsuranceEnquiry(response.data.data.content);
        setinsuranceEnquiryPageNo(response.data.data.totalPages);
        setinsuranceEnquirytotaluser(response.data.data.totalElements);
        setinsuranceEnquirycountno(response.data.data.number);
        setinsuranceEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //SkillAssessment Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            invoiceStatus: invoiceStatusState,
            institutionId: Number(data),
            invoiceType: "Payable",
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            intake: intakeId,
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setskillassementEnquiry(response.data.data.content);
        setskillassementEnquiryPageNo(response.data.data.totalPages);
        setskillassessmentEnquirytotaluser(response.data.data.totalElements);
        setskillassessmentEnquirycountno(response.data.data.number);
        setskillassessmentEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const handleIntake = (event) => {
    setintakeId(event.target.value);
    if (event.target.value == "") {
      setintakeId(null);
      if (providerId != null) {
        // console.log("Provider------", providerId);
        setinvoiceOnlyPaginate(false);
        setinvoiceInstituteOnlyPaginate(true);
        setinvoiceInakeOnlyPaginate(false);
        setinvoiceInstituteInakePaginate(false);
        callOnInvoiceStatusInstituteChange(invoiceStatusState, providerId);
      } else if (providerId === null) {
        // console.log("Invoice Type------", event.target.value);
        setinvoiceOnlyPaginate(true);
        setinvoiceInstituteOnlyPaginate(false);
        setinvoiceInakeOnlyPaginate(false);
        setinvoiceInstituteInakePaginate(false);
        callOnInvoiceStatusChange(invoiceStatusState);
      }
    } else {
      if (providerId === null) {
        console.log("Provider not present");
        setintakeId(event.target.value);
        filterByIntake(event.target.value);
      } else {
        console.log("Provider", providerId);
        setintakeId(event.target.value);
        filterByIntake2(event.target.value, providerId);
      }
    }
  };

  const filterByIntake = (data) => {
    setbackdropopen(true);
    // Admission Reports
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setadmissionEnquiry(response.data.data.content);
        setadmissionEnquiryPageNo(response.data.data.totalPages);
        setadmissionEnquirytotaluser(response.data.data.totalElements);
        setadmissionEnquirycountno(response.data.data.number);
        setadmissionEnquirynumberofelements(
          response.data.data.numberOfElements
        );
        setbackdropopen(false);
      })
      .catch((error) => {
        setbackdropopen(false);
        handle403error(error);
      });

    //Migration Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setmigrationEnquiry(response.data.data.content);
        setmigrationEnquiryPageNo(response.data.data.totalPages);
        setmigrationEnquirytotaluser(response.data.data.totalElements);
        setmigrationEnquirycountno(response.data.data.number);
        setmigrationEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //Insurance Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setinsuranceEnquiry(response.data.data.content);
        setinsuranceEnquiryPageNo(response.data.data.totalPages);
        setinsuranceEnquirytotaluser(response.data.data.totalElements);
        setinsuranceEnquirycountno(response.data.data.number);
        setinsuranceEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //SkillAssessment Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setskillassementEnquiry(response.data.data.content);
        setskillassementEnquiryPageNo(response.data.data.totalPages);
        setskillassessmentEnquirytotaluser(response.data.data.totalElements);
        setskillassessmentEnquirycountno(response.data.data.number);
        setskillassessmentEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const filterByIntake2 = (data, providerId) => {
    // Admission Reports
    setbackdropopen(true);
    let dateMap = {
      createdFromDate: datepast,
      createdToDate: datenow,
    };
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Admission",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            institutionId: Number(providerId),
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setadmissionEnquiry(response.data.data.content);
        setadmissionEnquiryPageNo(response.data.data.totalPages);
        setadmissionEnquirytotaluser(response.data.data.totalElements);
        setadmissionEnquirycountno(response.data.data.number);
        setadmissionEnquirynumberofelements(
          response.data.data.numberOfElements
        );
        setbackdropopen(false);
      })
      .catch((error) => {
        handle403error(error);
        setbackdropopen(false);
      });

    //Migration Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Migration",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            institutionId: Number(providerId),
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setmigrationEnquiry(response.data.data.content);
        setmigrationEnquiryPageNo(response.data.data.totalPages);
        setmigrationEnquirytotaluser(response.data.data.totalElements);
        setmigrationEnquirycountno(response.data.data.number);
        setmigrationEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //Insurance Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "Insurance",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            institutionId: Number(providerId),
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setinsuranceEnquiry(response.data.data.content);
        setinsuranceEnquiryPageNo(response.data.data.totalPages);
        setinsuranceEnquirytotaluser(response.data.data.totalElements);
        setinsuranceEnquirycountno(response.data.data.number);
        setinsuranceEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });

    //SkillAssessment Reports
    axios
      .get(
        `${base_url.api4}/getInvoiceReportByStatusAndWorkflowTypeAndInstitutionIdAndIntake`,
        // dateMap,
        {
          headers: {
            Authorization: jwtToken,
            workflowType: "SkillAssessment",
            invoiceStatus: invoiceStatusState,
            invoiceType: "Payable",
            institutionId: Number(providerId),
            intake: data,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        }
      )
      .then((response) => {
        setskillassementEnquiry(response.data.data.content);
        setskillassementEnquiryPageNo(response.data.data.totalPages);
        setskillassessmentEnquirytotaluser(response.data.data.totalElements);
        setskillassessmentEnquirycountno(response.data.data.number);
        setskillassessmentEnquirynumberofelements(
          response.data.data.numberOfElements
        );
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const exportPdfAdmission = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = ["Client Details", "InvoiceType", "Amount", "Paid", "Due"];
    var rows = [];
    admissionEnquiry?.forEach((element) => {
      var temp = [
        element.clientUsername + "\n" + element.clientEmail,
        element.invoiceType,
        element.serviceProvider,
        "Currency: " +
        element.currency +
        "\n" +
        "Total Amt: " +
        element.totalAmountIncludingTax +
        "\n" +
        "Tax: " +
        element.totalTax,
        element.totalPaid +
        "\n" +
        moment(element.invoicePaidDate).format("DD/MM/YYYY hh:mm:ss a"),
        element.totalDue +
        "\n" +
        moment(element.invoiceDueDate).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    // doc.autoTable(col, rows, { pageBreak: 'auto' });
    doc.save("Admission_Invoice_Reports.pdf");
  };

  const exportPdfMigration = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "InvoiceType",
      "Visa Office",
      "Amount",
      "Paid",
      "Due",
    ];
    var rows = [];
    migrationEnquiry?.forEach((element) => {
      if (element.invoicePaidDate) {
        var temp = [
          element.clientUsername + "\n" + element.clientEmail,
          element.invoiceType,
          element.serviceProvider,
          "Currency: " +
          element.currency +
          "\n" +
          "Total Amt: " +
          element.totalAmountIncludingTax +
          "\n" +
          "Tax: " +
          element.totalTax,
          element.totalPaid +
          "\n" +
          moment(element.invoicePaidDate).format("DD/MM/YYYY hh:mm:ss a"),
          element.totalDue +
          "\n" +
          moment(element.invoiceDueDate).format("DD/MM/YYYY hh:mm:ss a"),
        ];
        rows.push(temp);
      } else {
        var temp = [
          element.clientUsername + "\n" + element.clientEmail,
          element.invoiceType,
          element.serviceProvider,
          "Currency: " +
          element.currency +
          "\n" +
          "Total Amt: " +
          element.totalAmountIncludingTax +
          "\n" +
          "Tax: " +
          element.totalTax,
          element.totalPaid,
          element.totalDue +
          "\n" +
          moment(element.invoiceDueDate).format("DD/MM/YYYY hh:mm:ss a"),
        ];
        rows.push(temp);
      }
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    doc.save("Migration_Invoice_Reports.pdf");
  };

  const exportPdfInsurance = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "InvoiceType",
      "ServiceProvider",
      "Amount",
      "Paid",
      "Due",
    ];
    var rows = [];
    insuranceEnquiry?.forEach((element) => {
      var temp = [
        element.clientUsername + "\n" + element.clientEmail,
        element.invoiceType,
        element.serviceProvider,
        "Currency: " +
        element.currency +
        "\n" +
        "Total Amt: " +
        element.totalAmountIncludingTax +
        "\n" +
        "Tax: " +
        element.totalTax,
        element.totalPaid +
        "\n" +
        moment(element.invoicePaidDate).format("DD/MM/YYYY hh:mm:ss a"),
        element.totalDue +
        "\n" +
        moment(element.invoiceDueDate).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    doc.save("Insurance_Invoice_Reports.pdf");
  };

  const exportPdfSkill = () => {
    const doc = new jsPDF();
    // autoTable(doc, { html: '#myTable' })
    var col = [
      "Client Details",
      "InvoiceType",
      "ServiceProvider",
      "Amount",
      "Paid",
      "Due",
    ];
    var rows = [];
    skillassementEnquiry?.forEach((element) => {
      var temp = [
        element.clientUsername + "\n" + element.clientEmail,
        element.invoiceType,
        element.serviceProvider,
        "Currency: " +
        element.currency +
        "\n" +
        "Total Amt: " +
        element.totalAmountIncludingTax +
        "\n" +
        "Tax: " +
        element.totalTax,
        element.totalPaid +
        "\n" +
        moment(element.invoicePaidDate).format("DD/MM/YYYY hh:mm:ss a"),
        element.totalDue +
        "\n" +
        moment(element.invoiceDueDate).format("DD/MM/YYYY hh:mm:ss a"),
      ];
      rows.push(temp);
    });
    doc.autoTable(col, rows, { pageBreak: "auto" });
    doc.save("Skill_Assessment_Invoice_Reports.pdf");
  };

  const viewinvoice = (p, e) => {
    localStorage.setItem("prev_path3", "/receivable-invoice-report");
    history.push({
      pathname: "/view-invoice-details/" + p.clientId + "/" + p.id,
    });
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Card
        className="commoncomponent_cardcss"
        style={{ background: "gainsboro" }}
      >
        <Card style={{ margin: "10px" }}>
          <CardBody style={{ background: "beige" }}>
            <CardSubtitle className="font-weight-bold"></CardSubtitle>
            <div>
              <Row>
                <Col md={4}>
                  <div
                    style={{ float: "left" }}
                    className="maindashboard_select"
                  >
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Invoice Status
                    </Typography>
                    <FormControl
                      // className={classes.formControl}
                      className="office_dropdown"
                    >
                      <br />
                      <Select
                        native
                        value={officeid}
                        id="demo-simple-select3"
                        onChange={(e) => handleInvoiceStatus(e)}
                      >
                        <option value="Active">Show All</option>
                        <option value="Paid">Paid</option>
                        <option value="PartiallyPaid">Partially Paid</option>
                        <option value="Unpaid">Unpaid</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Refund">Refund</option>
                      </Select>
                    </FormControl>
                  </div>
                </Col>
                <Col md={4}>
                  <div
                    style={{ float: "left" }}
                    className="maindashboard_select"
                  >
                    {currentWorkFlow == "migration" ? (
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Visa Office
                      </Typography>
                    ) : (
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Provider
                      </Typography>
                    )}
                    <FormControl
                      // className={classes.formControl}
                      className="office_dropdown"
                    >
                      <br />
                      <Select
                        native
                        // value={branchId}
                        id="demo-simple-select3"
                        onChange={(e) => handleProvider(e)}
                      >
                        <option value="">Show All</option>
                        {currentWorkFlow == "admission" ? (
                          <>
                            {availableBranch?.map((p, index) => (
                              <option key={index} value={p.branchId}>
                                {p.branchName}
                              </option>
                            ))}
                          </>
                        ) : null}

                        {currentWorkFlow == "migration" ? (
                          <>
                            {availableMigration?.map((p, index) => (
                              <option key={index} value={p.id}>
                                {p.name}
                              </option>
                            ))}
                          </>
                        ) : null}

                        {currentWorkFlow == "insurance" ? (
                          <>
                            {availableInsurance?.map((p, index) => (
                              <option key={index} value={p.id}>
                                {p.name}
                              </option>
                            ))}
                          </>
                        ) : null}

                        {currentWorkFlow == "skillassessment" ? (
                          <>
                            {availableBranch?.map((p, index) => (
                              <option key={index} value={p.branchId}>
                                {p.branchName}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </Select>
                    </FormControl>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="maindashboard_select">
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Intake
                    </Typography>
                    <FormControl
                      // className={classes.formControl}
                      className="office_dropdown"
                    >
                      <br />
                      <Select
                        native
                        id="demo-simple-select3"
                        onChange={(e) => handleIntake(e)}
                      >
                        <option value="">Show All</option>
                        {availableIntake?.map((p, index) => (
                          <option key={index} value={p.name}>
                            {p.createdIntake}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
        <ul className="nav nav-tabs ">
          <li className="nav-item ">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#admission"
              onClick={() => setCurrentWorkFlow("admission")}
            >
              Admission
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#migration"
              onClick={() => setCurrentWorkFlow("migration")}
            >
              Migration
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#insurance"
              onClick={() => setCurrentWorkFlow("insurance")}
            >
              Insurance
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#skillassessment"
              onClick={() => setCurrentWorkFlow("skillassessment")}
            >
              Skill Assessment
            </a>
          </li>
        </ul>
        <div className="tab-content" style={{ border: "1px solid  #dee2e6" }}>
          <div id="admission" className="tab-pane active">
            <Card style={{ margin: "10px" }}>
              <CardBody>
                <CardSubtitle className="font-weight-bold"></CardSubtitle>
                <div>
                  <Row>
                    <Col md={6}>
                      <CardText className={classes.headtext}>
                        <strong>Admission Reports</strong>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                          {permissionData?.includes(433) ? (
                            <button
                              className="btn listing_addbutton"
                              onClick={exportPdfAdmission}
                            >
                              Export PDF
                            </button>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          {admissionEnquiry != null ? (
                            <>
                              {permissionData?.includes(434) ? (
                                <CSVLink
                                  filename="Admission-Invoice-Reports.csv"
                                  data={admissionEnquiry}
                                  className="btn listing_addbutton"
                                // target="_blank"
                                >
                                  Export CSV
                                </CSVLink>
                              ) : null}
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {admissionEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {admissionEnquirycountno * rows + 1} to{" "}
                          {admissionEnquirycountno * rows +
                            admissionEnquirynumberofelements}{" "}
                          of {admissionEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div className="displaytablerow">
                    <Table responsive striped onScroll={scrolltable}>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            SN{" "}
                          </th>
                          <th style={{ minWidth: "160px" }}>Intake </th>
                          <th
                            style={{ minWidth: "110px", textAlign: "center" }}
                          >
                            Invoice No{" "}
                          </th>
                          <th style={{ minWidth: "160px" }}>Client </th>
                          <th style={{ minWidth: "160px" }}>Provider </th>
                          <th style={{ minWidth: "160px" }}>Super Agent </th>
                          <th style={{ minWidth: "160px" }}>Currency </th>
                          <th style={{ minWidth: "160px" }}>Total Amount </th>
                          <th style={{ minWidth: "160px" }}>Due date </th>
                          <th style={{ minWidth: "160px" }}>Paid Amount </th>
                          <th style={{ minWidth: "160px" }}>Paid date </th>
                          <th style={{ minWidth: "160px" }}>Invoice Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {admissionEnquiry != null
                          ? admissionEnquiry &&
                          admissionEnquiry.map((p, index) => (
                            <tr key={p.id}>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {admissionEnquirycountno * rows + 1 + index}
                              </td>
                              <td>{p.intake}</td>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {permissionData?.includes(63) ? (
                                  <a
                                    onClick={(e) => viewinvoice(p, e)}
                                    href={
                                      `#/view-invoice-details/` +
                                      p.clientId +
                                      `/` +
                                      p.id
                                    }
                                  >
                                    {p.id}
                                  </a>
                                ) : (
                                  <>{p.id}</>
                                )}
                              </td>
                              <td>
                                {p.clientUsername}
                                <br />
                                {p.clientEmail}
                              </td>
                              <td>{p.serviceProvider}</td>
                              <td>
                                {p.agentName}
                                <br />
                                {p.agentUsername}
                              </td>
                              <td>{p.currency}</td>
                              <td>{p.totalAmountIncludingTax}</td>
                              <td>
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td>{p.totalPaid}</td>
                              <td>
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td>{p.invoiceStatus}</td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    {admissionEnquiry?.length ? (
                      <p style={{ marginTop: "15px" }}>
                        {admissionEnquirycountno * rows + 1} to{" "}
                        {admissionEnquirycountno * rows +
                          admissionEnquirynumberofelements}{" "}
                        of {admissionEnquirytotaluser} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root}>
                      <Pagination
                        count={admissionEnquiryPageNo}
                        onChange={handlepageAdmission}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div id="migration" className="tab-pane fade">
            <Card style={{ margin: "10px" }}>
              <CardBody>
                <CardSubtitle className="font-weight-bold"></CardSubtitle>
                <div>
                  <Row>
                    <Col md={6}>
                      <CardText className={classes.headtext}>
                        <strong>Migration Reports</strong>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                          {permissionData?.includes(435) ? (
                            <button
                              className="btn listing_addbutton"
                              onClick={exportPdfMigration}
                            >
                              Export PDF
                            </button>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          {migrationEnquiry != null ? (
                            <>
                              {permissionData?.includes(436) ? (
                                <CSVLink
                                  filename="Migration-Invoice-Reports.csv"
                                  data={migrationEnquiry}
                                  className="btn listing_addbutton"
                                // target="_blank"
                                >
                                  Export CSV
                                </CSVLink>
                              ) : null}
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {migrationEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {migrationEnquirycountno * rows + 1} to{" "}
                          {migrationEnquirycountno * rows +
                            migrationEnquirynumberofelements}{" "}
                          of {migrationEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div className="displaytablerow">
                    <Table responsive striped onScroll={scrolltable}>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            SN{" "}
                          </th>
                          <th style={{ minWidth: "160px" }}>Intake </th>
                          <th
                            style={{ minWidth: "110px", textAlign: "center" }}
                          >
                            Invoice No{" "}
                          </th>
                          <th style={{ minWidth: "160px" }}>Client </th>
                          <th style={{ minWidth: "230px" }}>Visa Office </th>
                          <th style={{ minWidth: "90px" }}>Currency </th>
                          <th style={{ minWidth: "130px" }}>Total Amount </th>
                          <th style={{ minWidth: "160px" }}>Due Date </th>
                          <th style={{ minWidth: "120px" }}>Paid Amount </th>
                          <th style={{ minWidth: "160px" }}>Paid Date </th>
                          <th style={{ minWidth: "120px" }}>Invoice Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {migrationEnquiry != null
                          ? migrationEnquiry &&
                          migrationEnquiry.map((p, index) => (
                            <tr key={p.id}>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {migrationEnquirycountno * rows + 1 + index}
                              </td>
                              <td>{p.intake}</td>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {permissionData?.includes(63) ? (
                                  <a
                                    onClick={(e) => viewinvoice(p, e)}
                                    href={
                                      `#/view-invoice-details/` +
                                      p.clientId +
                                      `/` +
                                      p.id
                                    }
                                  >
                                    {p.id}
                                  </a>
                                ) : (
                                  <>{p.id}</>
                                )}
                              </td>
                              <td>
                                {p.clientUsername}
                                <br />
                                {p.clientEmail}
                              </td>
                              <td>{p.serviceProvider}</td>
                              <td>{p.currency}</td>
                              <td>{p.totalAmountIncludingTax}</td>
                              <td>
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td>{p.totalPaid}</td>
                              <td>
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td>{p.invoiceStatus}</td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    {migrationEnquiry?.length ? (
                      <p style={{ marginTop: "15px" }}>
                        {migrationEnquirycountno * rows + 1} to{" "}
                        {migrationEnquirycountno * rows +
                          migrationEnquirynumberofelements}{" "}
                        of {migrationEnquirytotaluser} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root}>
                      <Pagination
                        count={migrationEnquiryPageNo}
                        onChange={handlepageMigration}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div id="insurance" className="tab-pane fade">
            <Card style={{ margin: "10px" }}>
              <CardBody>
                <CardSubtitle className="font-weight-bold"></CardSubtitle>
                <div>
                  <Row>
                    <Col md={6}>
                      <CardText className={classes.headtext}>
                        <strong>Insurance Reports</strong>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                          {permissionData?.includes(437) ? (
                            <button
                              className="btn listing_addbutton"
                              onClick={exportPdfInsurance}
                            >
                              Export PDF
                            </button>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          {insuranceEnquiry != null ? (
                            <>
                              {permissionData?.includes(438) ? (
                                <CSVLink
                                  filename="Insurance-Invoice-Reports.csv"
                                  data={insuranceEnquiry}
                                  className="btn listing_addbutton"
                                // target="_blank"
                                >
                                  Export CSV
                                </CSVLink>
                              ) : null}
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {insuranceEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {insuranceEnquirycountno * rows + 1} to{" "}
                          {insuranceEnquirycountno * rows +
                            insuranceEnquirynumberofelements}{" "}
                          of {insuranceEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div className="displaytablerow">
                    <Table responsive striped onScroll={scrolltable}>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            SN{" "}
                          </th>
                          <th
                            style={{ minWidth: "110px", textAlign: "center" }}
                          >
                            Invoice No{" "}
                          </th>
                          <th style={{ minWidth: "140px" }}>Client </th>
                          <th style={{ minWidth: "140px" }}>Provider </th>
                          <th style={{ minWidth: "90px" }}>Currency </th>
                          <th style={{ minWidth: "120px" }}>Total Amount </th>
                          <th style={{ minWidth: "160px" }}>Due Date </th>
                          <th style={{ minWidth: "120px" }}>Paid Amount </th>
                          <th style={{ minWidth: "160px" }}>Paid Date </th>
                          <th style={{ minWidth: "160px" }}>Invoice Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {insuranceEnquiry != null
                          ? insuranceEnquiry &&
                          insuranceEnquiry.map((p, index) => (
                            <tr key={p.id}>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {insuranceEnquirycountno * rows + 1 + index}
                              </td>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {permissionData?.includes(63) ? (
                                  <a
                                    onClick={(e) => viewinvoice(p, e)}
                                    href={
                                      `#/view-invoice-details/` +
                                      p.clientId +
                                      `/` +
                                      p.id
                                    }
                                  >
                                    {p.id}
                                  </a>
                                ) : (
                                  <>{p.id}</>
                                )}
                              </td>
                              <td>
                                {p.clientUsername}
                                <br />
                                {p.clientEmail}
                              </td>
                              <td>{p.serviceProvider}</td>
                              <td>{p.currency}</td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalAmountIncludingTax}
                              </td>
                              <td>
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalPaid}
                              </td>
                              <td>
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td>{p.invoiceStatus}</td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    {insuranceEnquiry?.length ? (
                      <p style={{ marginTop: "15px" }}>
                        {insuranceEnquirycountno * rows + 1} to{" "}
                        {insuranceEnquirycountno * rows +
                          insuranceEnquirynumberofelements}{" "}
                        of {insuranceEnquirytotaluser} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root}>
                      <Pagination
                        count={insuranceEnquiryPageNo}
                        onChange={handlepageInsurance}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div id="skillassessment" className="tab-pane fade">
            <Card style={{ margin: "10px" }}>
              <CardBody>
                <CardSubtitle className="font-weight-bold"></CardSubtitle>
                <div>
                  <Row>
                    <Col md={6}>
                      <CardText className={classes.headtext}>
                        <strong>Skill Assessment Reports</strong>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={3}></Col>
                        <Col md={3}>
                          {permissionData?.includes(439) ? (
                            <button
                              className="btn listing_addbutton"
                              onClick={exportPdfSkill}
                            >
                              Export PDF
                            </button>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          {skillassementEnquiry != null ? (
                            <>
                              {permissionData?.includes(440) ? (
                                <CSVLink
                                  filename="SkillAssement-Invoice-Reports.csv"
                                  data={skillassementEnquiry}
                                  className="btn listing_addbutton"
                                // target="_blank"
                                >
                                  Export CSV
                                </CSVLink>
                              ) : null}
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {skillassementEnquiry?.length ? (
                        <p style={{ marginTop: "15px" }}>
                          {skillassessmentEnquirycountno * rows + 1} to{" "}
                          {skillassessmentEnquirycountno * rows +
                            skillassessmentEnquirynumberofelements}{" "}
                          of {skillassessmentEnquirytotaluser} records
                        </p>
                      ) : (
                        <p className="formfooterrecordstyle">No Record Found</p>
                      )}
                    </Col>
                  </Row>
                  <div className="above-scroller" onScroll={scrolldiv}>
                    <div className="scroller"></div>
                  </div>
                  <div className="displaytablerow">
                    <Table responsive striped onScroll={scrolltable}>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            SN{" "}
                          </th>
                          <th style={{ minWidth: "50px", textAlign: "center" }}>
                            IID{" "}
                          </th>
                          <th style={{ minWidth: "160px" }}>Client </th>
                          <th style={{ minWidth: "120px" }}>Invoice Type </th>
                          <th style={{ minWidth: "140px" }}>
                            Invoice SubType{" "}
                          </th>
                          <th style={{ minWidth: "120px" }}>A/C Type </th>
                          <th style={{ minWidth: "140px" }}>Provider </th>
                          <th style={{ minWidth: "90px" }}>Currency </th>
                          <th style={{ minWidth: "110px" }}>Net Amount </th>
                          <th style={{ minWidth: "110px" }}>Tax Amount </th>
                          <th style={{ minWidth: "170px" }}>Comm. Amount </th>
                          <th style={{ minWidth: "120px" }}>Total Amount </th>
                          <th style={{ minWidth: "120px" }}>Paid Amount </th>
                          <th style={{ minWidth: "160px" }}>Paid Date </th>
                          <th style={{ minWidth: "120px" }}>Due Amount </th>
                          <th style={{ minWidth: "160px" }}>Due Date </th>
                          <th style={{ minWidth: "160px" }}>Created </th>
                        </tr>
                      </thead>
                      <tbody>
                        {skillassementEnquiry != null
                          ? skillassementEnquiry &&
                          skillassementEnquiry.map((p, index) => (
                            <tr key={p.id}>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {skillassessmentEnquirycountno * rows +
                                  1 +
                                  index}
                              </td>
                              <td
                                style={{
                                  minWidth: "50px",
                                  textAlign: "center",
                                }}
                              >
                                {p.id}
                              </td>
                              <td>
                                {p.clientUsername}
                                <br />
                                {p.clientEmail}
                              </td>
                              <td>{p.invoiceType}</td>
                              <td>{p.invoiceSubType}</td>
                              <td>{p.incomeType}</td>
                              <td>{p.serviceProvider}</td>
                              <td>{p.currency}</td>
                              <td style={{ textAlign: "right" }}>
                                {p.netAmount}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalTax}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalCommission}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalAmountIncludingTax}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalPaid}
                              </td>
                              <td>
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoicePaidDate
                                  ? moment(
                                    p.invoicePaidDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {p.totalDue}
                              </td>
                              <td>
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoiceDueDate
                                  ? moment(
                                    p.invoiceDueDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                              <td>
                                {p.invoiceCreatedDate
                                  ? moment(
                                    p.invoiceCreatedDate.split(".")[0]
                                  ).format("DD-MMM-YY")
                                  : null}
                                <br />
                                {p.invoiceCreatedDate
                                  ? moment(
                                    p.invoiceCreatedDate.split(".")[0]
                                  ).format("hh.mm a")
                                  : null}
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    {skillassementEnquiry?.length ? (
                      <p style={{ marginTop: "15px" }}>
                        {skillassessmentEnquirycountno * rows + 1} to{" "}
                        {skillassessmentEnquirycountno * rows +
                          skillassessmentEnquirynumberofelements}{" "}
                        of {skillassessmentEnquirytotaluser} records
                      </p>
                    ) : (
                      <p className="formfooterrecordstyle">No Record Found</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className={classes.root}>
                      <Pagination
                        count={skillassementEnquiryPageNo}
                        onChange={handlepageSkillAssessment}
                        shape="rounded"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default withRouter(PayableInvoiceReports);
