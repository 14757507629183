import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input, Typography } from "@material-ui/core";
import { Button } from "reactstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import { selectdashboard } from "../features/dashboardSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "../../Utilities/CSSUtilities";
import { getquotationpermission,check4pointpermission,  check1pointpermission } from "../Permission/permission";

const EditQuotation = () => {
  const [backdropopen, setbackdropopen] = useState(false);
  let params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const [newline, setnewline] = useState([]);
  const [currency1, setcurrency] = useState();
  const [totalamountvalues, settotalamountvalues] = useState([]);
  const [totalamttax, settotalamttax] = useState();
  const { register, handleSubmit, errors } = useForm();
  const [stateCust, setstateCust] = useState();
  const user = useSelector(selectUser);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1200, 1204, permission_obj, resource_obj)) {
      getquotation();
      dispatch(setdashboardcheck(29));
    } else {
      history.push("/errorPage");
    }
  };



  const getquotation = () => {
    axios
      .get(`${base_url.api2}/getQuotationById`, {
        headers: {
          Authorization: jwtToken,
          quotationId: params.quotation_id,
        },
      })
      .then((res) => {
        console.log(res);
        let quotationDetails = [];
        for (let i = 0; i < res.data.data.quotationDetails.length; i++) {
          setnewline((prev) => [
            ...prev,
            {
              id: 0,
              description: res.data.data.quotationDetails[i].description,
              amount: res.data.data.quotationDetails[i].amount,
              taxPercentage: res.data.data.quotationDetails[i].taxPercentage,
              taxAmount: res.data.data.quotationDetails[i].taxAmount,
              totalAmount: res.data.data.quotationDetails[i].totalAmount,
            },
          ]);

          settotalamountvalues((prev) => [
            ...prev,
            res.data.data.quotationDetails[i].totalAmount,
          ]);
          quotationDetails.push({
            amount: res.data.data.quotationDetails[i].amount,
            description: res.data.data.quotationDetails[i].description,
            id: 0,
            taxAmount: res.data.data.quotationDetails[i].taxAmount,
            taxPercentage: res.data.data.quotationDetails[i].taxPercentage,
            totalAmount: res.data.data.quotationDetails[i].totalAmount,
          });
        }
        setstateCust({
          address: res.data.data.address,
          clientId: res.data.data.clientId,
          clientUsername: res.data.data.clientUsername,
          clientName: res.data.data.clientName,
          currency: res.data.data.currency,
          id: res.data.data.id,
          quotationDate: res.data.data.quotationDate,
          quotationDetails,
          quotationDueDate: res.data.data.quotationDueDate,
          totalAmount: res.data.data.totalAmount,
        });
        settotalamttax(res.data.data.totalAmount);
        setcurrency(res.data.data.currency);
        setbackdropopen(false);
      })
      .catch((err) => {
        setbackdropopen(false);
        handle403error(err);
      });
  };
  const selectcurrency = (e) => {
    setcurrency(e.target.value);
  };
  const addClick = () => {
    settotalamountvalues((prev) => [...prev, ""]);
  };
  const handlelinechange = (e, i) => {
    var lines = [...newline];
    lines[i].description = e.target.value;
    setnewline(lines);
  };
  const handletaxpercentchange = (e, i) => {
    var lines = [...newline];
    lines[i].taxPercentage = Number(e.target.value);
    lines[i].taxAmount = (
      (Number(lines[i].amount) * Number(lines[i].taxPercentage)) /
      100
    ).toFixed(2);
    lines[i].totalAmount = Number(lines[i].amount) + Number(lines[i].taxAmount);
    let totalamount = [...totalamountvalues];
    totalamount[i] = Number(lines[i].totalAmount);
    settotalamountvalues(totalamount);
    settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    setnewline(lines);
  };
  const handleamountChange = (e, i) => {
    var lines = [...newline];
    lines[i].amount = Number(e.target.value);
    lines[i].taxAmount = (
      (Number(lines[i].amount) * Number(lines[i].taxPercentage)) /
      100
    ).toFixed(2);
    lines[i].totalAmount = Number(lines[i].amount) + Number(lines[i].taxAmount);
    let totalamount = [...totalamountvalues];
    totalamount[i] = Number(lines[i].totalAmount);
    settotalamountvalues(totalamount);
    settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    setnewline(lines);
  };
  const onSubmit = (data2) => {
    stateCust.quotationDetails = newline;
    stateCust.totalAmount = totalamttax;
    stateCust.currency = currency1;
    axios
      .post(`${base_url.api2}/updateQuotationById`, stateCust, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Quotation Edited");
        history.push("/quotations");
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const goBack = () => {
    history.push("/quotations");
  };
  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };
  const dashboardvalue = useSelector(selectdashboard);
  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  return (
    <>
      <iframe
        id="receipt"
        src={`/admin/#/printquotation/${params.quotation_id}`}
        // src="/#/printinvoice"
        style={{ display: "none" }}
        title="Receipt"
      />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss addinvoice"> */}
      <div
        className="page-head d-flex justify-content-between p-4 "
        style={{ marginTop: "4.3rem" }}
      >
        <div className="breadcrums">
          <h3 className="page-title bold"> Client Quotation</h3>
          <a href="#/" className="b-link">
            Home
          </a>
          -<a className="b-link">Partner</a>-
          <a className="b-link active">Provider Edit</a>
        </div>
        <a onClick={goBack} className="sent-button">
          List
        </a>
      </div>
      <div style={{ padding: "1.5rem", border: "1.5rem solid #e9ebf2" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addinvoice_header">
            <Typography variant="h5" color="primary">
              {null}
            </Typography>
            <div className="d-flex justify-content-end">
              <button
                className="sent-button"
                style={{ marginRight: "5px" }}
                onClick={() => printIframe("receipt")}
              >
                Print
              </button>
              <button className="sent-button" type="submit">
                Save
              </button>
              {/* <Button className="btn listing_addbutton ml-1" onClick={goBack}>
                &#x2630; List
              </Button> */}
            </div>
          </div>
          <div className="addinvoice_details">
            <div style={{ display: "flex", flex: "0.7" }}>
              <div className="addinvoice_clientdetails">
                <div className="row quotation_margin_row">
                  <Typography
                    className="col-md-3"
                    style={{
                      color: "cornflowerblue",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    Name:
                  </Typography>
                  <Typography
                    variant="body2"
                    className="col-md-9"
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  >
                    {/* {clientDetail1?.firstName} {clientDetail1?.lastName} */}
                    {stateCust?.clientName}
                  </Typography>
                </div>
                <div className="row quotation_margin_row">
                  <Typography
                    className="col-md-3"
                    style={{
                      color: "cornflowerblue",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    Email:
                  </Typography>
                  <Typography
                    variant="body2"
                    className="col-md-9"
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  >
                    {/* {isw_user_name} */}
                    {stateCust?.clientUsername}
                  </Typography>
                </div>
                <div className="row quotation_margin_row">
                  <Typography
                    className="col-md-3"
                    style={{
                      color: "cornflowerblue",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    Address:
                  </Typography>
                  <Typography
                    variant="body2"
                    className="col-md-9"
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  >
                    {/* {isw_user_name} */}
                    {stateCust?.address}
                  </Typography>
                </div>
              </div>
            </div>
            <div style={{ flex: "0.3" }}>
              <div>
                <div className="addinvoice_datedetail">
                  <Typography
                    style={{
                      flex: "0.5",
                      color: "cornflowerblue",
                      fontWeight: "bold",
                    }}
                  >
                    Quotation Date
                  </Typography>
                  <DatePicker
                    className="form-control underline-input"
                    selected={
                      stateCust?.quotationDate
                        ? new Date(stateCust?.quotationDate)
                        : null
                    }
                    dateFormat="dd-MM-yyyy"
                    readOnly
                  />
                </div>
                <div>
                  <div className="addinvoice_datedetail">
                    <Typography
                      style={{
                        flex: "0.5",
                        color: "cornflowerblue",
                        fontWeight: "bold",
                        width: "200px",
                      }}
                    >
                      Quotation Due Date
                    </Typography>
                    <DatePicker
                      className="form-control underline-input"
                      selected={
                        stateCust?.quotationDueDate
                          ? new Date(stateCust?.quotationDueDate)
                          : null
                      }
                      dateFormat="dd-MM-yyyy"
                      readOnly
                    />
                  </div>
                </div>
                <div className="addinvoice_datedetail">
                  <Typography
                    style={{
                      flex: "0.5",
                      color: "cornflowerblue",
                      fontWeight: "bold",
                    }}
                  >
                    Currency
                  </Typography>

                  <input
                    style={{ flex: "0.5" }}
                    className="form-control quotation_input underline-input"
                    type="text"
                    readOnly
                    value={stateCust?.currency}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="invoice_adddetails quotation_table">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      SN
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Description
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Amount
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Tax(%)
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Tax Amount
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Total Amount
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: "bold" }}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="clientgeneralinvoicetables">
                  {newline.map((p, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <input
                          ref={register}
                          placeholder="Enter Description.."
                          value={p.description}
                          onChange={(e) => handlelinechange(e, index)}
                          className="form-control underline-input"
                          name={`description-${index}`}
                          type="text"
                          style={{ backgroundColor: "#f5f5f9" }}
                          required
                        />
                      </TableCell>
                      <TableCell align="right">
                        <input
                          ref={register}
                          placeholder="Enter Amount.."
                          value={p.amount}
                          name={`amount-${index}`}
                          className="form-control underline-input inputalign"
                          type="number"
                          style={{ backgroundColor: "#f5f5f9" }}
                          onWheel={(event) => event.currentTarget.blur()}
                          onChange={(e) => handleamountChange(e, index)}
                          required
                        />
                      </TableCell>
                      <TableCell align="right">
                        <input
                          ref={register}
                          placeholder="Enter Tax%.."
                          value={p.taxPercentage}
                          onChange={(e) => handletaxpercentchange(e, index)}
                          name={`taxPercentage-${index}`}
                          type="number"
                          style={{ backgroundColor: "#f5f5f9" }}
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control underline-input inputalign"
                          required
                        />
                      </TableCell>
                      <TableCell align="right">
                        <input
                          ref={register}
                          value={p.taxAmount}
                          className="form-control underline-input inputalign"
                          name={`taxAmount-${index}`}
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          readOnly
                        />
                      </TableCell>
                      <TableCell align="right">
                        <input
                          ref={register}
                          value={p.totalAmount}
                          className="form-control underline-input inputalign"
                          name={`totalAmount-${index}`}
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          readOnly
                        />
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          var lines = [...newline];
                          let totalamount = [...totalamountvalues];
                          lines.splice(index, 1);
                          totalamount.splice(index, 1);
                          settotalamountvalues(totalamount);
                          setnewline(lines);
                          settotalamttax(
                            totalamount?.reduce(
                              (amount, item) => item + amount,
                              0
                            )
                          );
                        }}
                      >
                        ❎
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="sent-button"
                // color="primary"
                // style={{ cursor: "pointer", marginTop: "20px" }}
                style={{ height: "3rem", marginTop: "10px" }}
                onClick={() => {
                  addClick();
                  setnewline((prev) => [
                    ...prev,

                    {
                      id: 0,
                      description: "",
                      amount: "",
                      taxPercentage: "",
                      taxAmount: "",
                      totalAmount: "",
                    },
                  ]);
                }}
              >
                Add Row
              </button>
              <div
                className="addinvoice_revenuedetailright"
                style={{ marginTop: "20px" }}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="addinvoice_revenuedetailrightfirst"
                >
                  Total Amount:
                </Typography>
                <div
                  className="addinvoice_detailinput"
                  style={{ marginRight: "30px" }}
                >
                  <Input
                    name="total_amount"
                    value={totalamttax}
                    className="form-control underline-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ marginTop: "40px" }}>
            
          </div> */}
        </form>
      </div>
      {/* </div> */}
    </>
  );
};

export default EditQuotation;
