import React, { useEffect, useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "./../../css/Responsive.css";
import "suneditor/dist/css/suneditor.min.css";
import {
  setdashboardcheck,
  logout,
  selectUser,
} from "./../../features/userSlice";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  useStyles,
  editorConfiguration,
} from "../../../Utilities/CSSUtilities";
import callsaveauditapi from "../../../services/auditservice";
import {
  check4pointpermission,
  checksettingpermission,
} from "../../Permission/permission";

function EditProforma(props) {
  let params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");

  const [stateCust, setstateCust] = useState({});
  const user = useSelector(selectUser);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1558, 1563, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(28));
      getProforma();

    } else {
      history.push("/errorPage");
    }
  };

  const getProforma = () => {
    axios
      .get(`${base_url.api2}/getProformaById`, {
        headers: {
          Authorization: jwtToken,
          proformaId: Number(params.proforma_id),
        },
      })
      .then((response) => {
        let coursedata = response.data.data;
        setstateCust({
          addedOn: coursedata.addedOn,
          id: coursedata.id,
          name: coursedata.name,
          status: coursedata.status,
          template: coursedata.template,
        });
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setstateCust((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [descriptionState, setdescriptionState] = useState(null);

  const putCustomer = (e) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    stateCust.template = descriptionState;
    console.log("Spread Operator", stateCust);
    axios
      .post(`${base_url.api2}/updateProformaById`, stateCust, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response);
          toast.success("Success");

          let log = isw_user_name + " Updated Proforma " + stateCust.name + ".";
          callsaveauditapi(log);
          history.push("/proforma");
        },
        (err) => {
          if (err) {
            if (err.response) {
              if (Number(err?.response?.status) === 403) {
                setTimeout(() => {
                  dispatch(logout());
                  localStorage.clear();
                  localStorage.setItem("from_403_status", "call_toast");
                  history.push("/");
                  toast.warn("Session Expired");
                }, 1000);
              }
            }
          }
        }
      );
  };

  const goback = () => {
    history.push("/proforma");
  };

  return (
    <div>
      <ToastContainer />
      {/* <Dashboard /> */}
      <Card className="commoncomponent_cardcss">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            putCustomer(e);
          }}
        >
          <div className="form-inner-admin">
            <Row>
              <Col md={6}>
                <CardText className={classes.headtext}>
                  <strong>Edit Proforma</strong>
                </CardText>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <Button className="btn listing_addbutton" onClick={goback}>
                  &#x2630; List
                </Button>
              </Col>
            </Row>
            <div className="form-group creatadmin">
              <Row>
                <Col md={6}>
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    onChange={inputEvent}
                    name="name"
                    value={stateCust?.name}
                    required
                  />
                </Col>
                <Col md={6}>
                  <label>Status:</label>
                  <select
                    onChange={inputEvent}
                    value={stateCust?.status}
                    className={classes.selectdrop}
                    name="status"
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                {/* <Col md={12}>
                    <label>Template:</label>
                    <SunEditor
                      ref={editorRef}
                      setContents={stateCust.template}
                      height="500"
                      onChange={descriptionChange}
                      required
                    />
                  </Col> */}
                <Col md={12}>
                  <label>Template:</label>
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    data={stateCust.template}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setdescriptionState(data);
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button type="submit" className="btn listing_addbutton mt-2">
              Update
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default withRouter(EditProforma);
