import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import "../css/Vertical.css";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Typography } from "@material-ui/core";
import "../css/ViewEnquiryWorkFlow.css";
import PropTypes from "prop-types";
import Badge from "@material-ui/core/Badge";
import { deepOrange } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EditIcon from "@material-ui/icons/Edit";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import EmailIcon from "@material-ui/icons/Email";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import { Avatar, Button } from "@material-ui/core";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  selectworkflowType,
  selecttabvalue,
  gettabvalue,
  selectUser,
  setdashboardcheck,
} from "../features/userSlice";
import WorkflowListing from "./WorkflowListing";
import InvoiceList from "../Invoice/InvoiceList";
import { selectdashboard } from "../features/dashboardSlice";
import WorkflowDashboard from "./WorkflowDashboard";
import Tooltip from "@material-ui/core/Tooltip";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import EnquiryNotesDisplay from "./EnquiryNotesDisplay";
import Mask from "./../images/Mask group.png";
import ClientImage from "./../images/client-dashboard/client-image.png";
import Rocket from "./../images/client-dashboard/rocket.svg";
import SnowFlake from "./../images/client-dashboard/snow-flake.svg";
import Coffee from "./../images/client-dashboard/coffee.svg";
import Sun from "./../images/client-dashboard/sun.svg";
import { check4pointpermission, check1pointpermission } from "../Permission/permission";
import Illu from "./../images/client-dashboard/Illustrationwork.png";
import Eoi from "./Eoi";
import Pte from "./Pte";
const drawerWidth = 300;
const drawerWidth2 = 60;
const selectheight = "35px";
const selectWidth = "100%";
const headtxt = 30;

function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && props.component}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: drawerWidth,
    marginRight: drawerWidth2,
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  headtext: {
    fontSize: headtxt,
  },
  viewheadtext: {
    fontSize: "10",
  },
  createBtn2: {
    color: "white",
    textDecoration: "none",
  },
  createBtn: {
    float: "right",
  },
  modalcss: {
    marginTop: "10%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dropdownbtn: {
    background: "blue",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  dropdownbtn2: {
    background: "white",
    color: "black",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes2 = useStylesBootstrap();
  return <Tooltip arrow classes={classes2} {...props} />;
}
const ViewEnquiryWorkflowList = () => {
  const user = useSelector(selectUser);
  const [backdropopen, setbackdropOpen] = React.useState(false);

  const jwttoken = "Bearer " + localStorage.getItem("isw_user_token");
  const params = useParams();
  const workflowtyperedux = useSelector(selectworkflowType);
  const classes = useStyles();
  const history = useHistory();
  const tabvalue = useSelector(selecttabvalue);
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(tabvalue);
  console.log(value);
  const handleChange = (event, newValue) => {
    console.log("Value Changed", newValue);
    dispatch(gettabvalue(newValue));
    setValue(newValue);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdropOpen(true);
    localStorage.setItem("refreshing", "dontrefresh");
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(306, permission_obj, resource_obj)) {
      getClientDetails();
    } else {
      history.push("/errorPage");
    }
  };

  // console.log(params.client_name);
  const [clientDetail1, setclientDetail1] = useState();
  const [visaDateDiff, setvisaDateDiff] = useState();
  const [clientDetail, setclientDetail] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);
  const [clientStatusText, setclientStatusText] = useState();
  const [clientstatus, setclientstatus] = useState();
  const [clientstatususername, setclientstatususername] = useState();
  const [changestatusmodal, setchangestatusmodal] = useState(false);
  const isw_user_name = localStorage.getItem("isw_user_name");
  const [pageno, setpageno] = useState(0);
  const [client_users, setClientDetails] = useState(null);

  const getClientDetails = () => {



    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwttoken,
          username: params.client_name,
        },
      })
      .then(
        (res) => {
          console.log("hello sangam");
          setclientDetail1(res.data.data.clientDetail);
          setclientDetail(res.data.data);
          setclientstatus(res.data.data.status);
          if (res.data.data.status === 1) setclientStatusText("Unarchive");
          else setclientStatusText("Archive");
          setbackdropOpen(false);
          let isoDateString = new Date().toISOString();
          let datenow = isoDateString.split(".")[0];
          if (res.data.data.clientDetail.visaExpiry) {
            let visa_date = moment(
              res.data.data.clientDetail.visaExpiry
            ).format("YYYY-MM-DD");
            let datetoday = moment(datenow).format("YYYY-MM-DD");
            setvisaDateDiff(moment(visa_date).diff(moment(datetoday), "day"));
            var sangam = (moment(visa_date).diff(moment(datetoday), "day"));
            console.log(sangam);

            var sangamyear = Math.trunc(sangam / 365);
            console.log("sangamyear------->", sangamyear);
            var sangamdayremaining = Math.trunc(sangam % 365);
            console.log("sangamdayremaining----------->", sangamdayremaining);
            var sangammonth = Math.trunc(sangamdayremaining / 30);
            console.log("sangammonth-------->", sangammonth);
            var sangamday = Math.trunc(sangamdayremaining % 30);
            console.log("sangamday ------->", sangamday);

            // let start=moment();
            // var years = (moment(visa_date).diff(moment(datetoday), "year"));
            // start.add(years, 'years');
            // console.log(years);
            // var months = (moment(visa_date).diff(moment(datetoday), "months"));
            // start.add(months, 'months');

            // var days = (moment(visa_date).diff(moment(datetoday), "days"));
            if (sangamyear < 0 || sangammonth < 0 || sangamday < 0) {
              setvisaDateDiff(`(` + "Visa Already Expired" + `)`);
            }
            else if (sangamyear >= 0 && sangamyear < 1) {
              setvisaDateDiff(`(` + sangammonth + ' months ' + sangamday + ' days' + ' remaining' + `)`);
            }
            else {
              setvisaDateDiff(`(` + sangamyear + ' years ' + sangammonth + ' months ' + sangamday + ' days' + ' remaining' + `)`);
            }
          }
        },
        (error) => {
          setbackdropOpen(false);
          toast.error(JSON.stringify(error.response.data.message));
          // console.log(error);
        }
      );
    if (workflowtyperedux == "Admission") {
      setValue(1);
    } else if (workflowtyperedux == "Migration") {
      setValue(2);
    } else if (workflowtyperedux == "Insurance") {
      setValue(3);
    } else if (workflowtyperedux == "SkillAssessment") {
      setValue(4);
    } else if (workflowtyperedux == "Accounts") {
      setValue(5);
    }
    else if (workflowtyperedux == null) {
      setValue(0);
    } else {
      setValue(0);
    }

  };

  const deleteClient = (clientDetail1) => {
    setclientstatus(clientDetail1.status);
    setclientstatususername(clientDetail1.username);
    setchangestatusmodal(true);
  };

  const dashboardvalue = useSelector(selectdashboard);
  const editClient = (elem) => {
    localStorage.setItem("comingfromwhere", "workflowenquirypage");
    history.push("/client-view/" + params.client_name);
  };
  const updateenquiryleadtype = (value) => {
    setbackdropOpen(true);
    const data = {
      username: params.client_name,
    };
    axios
      .post(`${base_url.api3}/updateClientByUsername`, data, {
        headers: {
          Authorization: jwttoken,
          enquiryLeadType: value,
        },
      })
      .then((res) => {
        toast.success("EnquiryLeadType Updated");
        getClientDetails();
      })
      .catch((err) => {
        setbackdropOpen(false);
      });
  };

  const viewClient = () => {
    localStorage.setItem("clientPageNo", pageno);
    var client_username = params.client_name;
    localStorage.setItem("comingfromwhere", "userspage");
    if (client_username == "") {
      // props.history.replace({ pathname: '/edit-Client/'+client_username })
      console.log("coming if");
      history.push("/client-view/" + params.client_name);
    } else {
      console.log("coming else");
      history.push("/client-view/" + client_username);
    }
  };

  const deleteUser = () => {
    const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
    let status1;
    // console.log("client status is : "+clientstatus)
    if (clientstatus === 0) {
      status1 = 1;
    } else {
      status1 = 0;
    }
    let data = {
      username: clientstatususername,
      status: status1,
    };
    axios
      .post(`${base_url.api3}/updateClientByUsername`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success("Status Changed");
          setclientstatus(response.data.data.status);
          if (response.data.data.status === 1) setclientStatusText("Unarchive");
          else setclientStatusText("Archive");
          setchangestatusmodal(false);
          let logs =
            isw_user_name +
            " Updated status of Client " +
            response.data.data.email +
            " to " +
            response.data.data.status +
            ".";
          // callsaveauditapi(logs);
          getClientDetails();
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          // console.log(error);
        }
      );
  };

  const goback = () => {
    var client_prev_path = localStorage.getItem("client_prev_path");
    history.push(client_prev_path);
  };

  return (
    <>
      <div class="sp lead-page">
        <div class="page-head d-flex justify-content-between p-4">
          <ul class="breadcrums">
            <h3 class="page-title bold">Manage Clients</h3>
            <a href="#/" class="b-link">
              Home
            </a>
            <span className="s_span ">-</span>
            <a class="b-link active">Client Dashboard</a>
          </ul>
          <a onClick={goback} className="back-btn">
            Back
          </a>
        </div>
        <div class="page-card_container p-4">
          <div class="content-wrapper">
            <div class="card client-profile" id="cardClient">
              <div class="minimize-profile">
                <span
                  onClick={() => {
                    let card = document.getElementById("cardClient");
                    card.style.display = "none";
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "0px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "visible";
                  }}
                >
                  {" "}
                  &#171;{" "}
                </span>
              </div>
              <div class="options">
                <input type="checkbox" id="option-checkbox" class="checkbox" />
                <label class="icon" for="option-checkbox">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="6" fill="white" />
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                      stroke="#B1B1CA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <div class="options-content">
                  <ul>
                    {/* {client_users != null ? (
                      client_users &&
                      client_users?.map((p, index) => ( */}
                    <>
                      {check1pointpermission(
                        303,
                        permissionData,
                        rolepermission
                      ) ? (
                        <li>
                          <a
                            // id={p.username}
                            onClick={viewClient}
                            href={`#/edit-client/` + params.client_name}
                          >
                            Edit Client
                          </a>
                        </li>
                      ) : null}
                    </>
                    {/* )))} */}
                    <li>
                      {clientstatus === 1 ? (
                        <a
                          onClick={() => {
                            // deleteUser(p.status, p.username)
                            setclientstatus(clientDetail.status);
                            setclientstatususername(clientDetail.username);
                            setchangestatusmodal(true);
                          }}
                        >
                          Archive
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // deleteUser(p.status, p.username)
                            setclientstatus(clientDetail.status);
                            setclientstatususername(clientDetail.username);
                            setchangestatusmodal(true);
                          }}
                        >
                          Unarchive
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="client-img">
                {/* <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar className={classes.orange}>{(clientDetail?.firstName)? clientDetail?.firstName.charAt(0).toUpperCase():"N"}</Avatar>
            </StyledBadge> */}
                <img src={ClientImage} alt="" />
                <h2 class="mt-2">
                  {clientDetail1?.firstName} {clientDetail1?.middleName}{" "}
                  {clientDetail1?.lastName}
                </h2>
                {/* <div className="dummy_contentlefticons margin">
                {clientDetail?.enquiryLeadType === "Converted" ? (
                  <BootstrapTooltip title="Converted">
                    <PriorityHighIcon
                      className="icon-margin"
                      color="secondary"
                    />
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="Converted">
                    <PriorityHighIcon
                      style={{ cursor: "pointer" }}
                      className="icon-margin"
                      onClick={() => updateenquiryleadtype("Converted")}
                    />
                  </BootstrapTooltip>
                )}
                {clientDetail?.enquiryLeadType === "ColdLead" ? (
                  <BootstrapTooltip title="ColdLead">
                    <AcUnitIcon className="icon-margin" color="secondary" />
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="ColdLead">
                    <AcUnitIcon
                      style={{ cursor: "pointer" }}
                      className="icon-margin"
                      onClick={() => updateenquiryleadtype("ColdLead")}
                    />
                  </BootstrapTooltip>
                )}
                {clientDetail?.enquiryLeadType === "WarmLead" ? (
                  <BootstrapTooltip title="WarmLead">
                    <LocalCafeIcon className="icon-margin" color="secondary" />
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="WarmLead">
                    <LocalCafeIcon
                      style={{ cursor: "pointer" }}
                      className="icon-margin"
                      onClick={() => updateenquiryleadtype("WarmLead")}
                    />
                  </BootstrapTooltip>
                )}
                {clientDetail?.enquiryLeadType === "HotLead" ? (
                  <BootstrapTooltip title="HotLead">
                    <WbSunnyIcon className="icon-margin" color="secondary" />
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="HotLead">
                    <WbSunnyIcon
                      style={{ cursor: "pointer" }}
                      className="icon-margin"
                      onClick={() => updateenquiryleadtype("HotLead")}
                    />
                  </BootstrapTooltip>
                )}
              </div> */}
                {clientstatus === 0 ? (
                  <>
                    <div className="dummy_contentleftpersonal archive_btn">
                      <span style={{ color: "red" }}>
                        {clientStatusText}{" "}
                      </span>
                      <button
                        className="outline-button"
                        style={{ marginLeft: "10px" }}
                        // class="account_sidebarelementcontainer activeaccount"
                        onClick={() => deleteClient(clientDetail)}
                      >
                        {/* <img
                          alt="sidebaricon"
                          src="./restore_backup_time.png"
                          class=""
                          aria-describedby="mui-25167"
                        /> */}
                        Restore
                      </button>
                    </div>
                  </>
                ) : null}

                <Modal
                  isOpen={changestatusmodal}
                  onRequestClose={() => setchangestatusmodal(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <h4 style={{ marginTop: "10px" }}>
                    Are you sure you want to{" "}
                    {clientstatus === 1 ? "Archive" : "Unarchive"} current
                    record ?{" "}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      className="btn listing_addbutton ml-1"
                      onClick={deleteUser}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-secondary cancel_margin"
                      onClick={() => setchangestatusmodal(false)}
                    >
                      No
                    </button>
                  </div>
                </Modal>
                <div class="status" style={{ marginTop: "10px" }}>
                  {clientDetail?.enquiryLeadType === "Converted" ? (
                    <a>
                      <img src={Rocket} alt="" />
                    </a>
                  ) : (
                    <a
                      className="s_cursor"
                      onClick={() => updateenquiryleadtype("Converted")}
                    >
                      <img src={Rocket} alt="" />
                    </a>
                  )}
                  {clientDetail?.enquiryLeadType === "ColdLead" ? (
                    <a className="s_cursor">
                      <img src={SnowFlake} alt="" />
                    </a>
                  ) : (
                    <a
                      className="s_cursor"
                      onClick={() => updateenquiryleadtype("ColdLead")}
                    >
                      <img src={SnowFlake} alt="" />
                    </a>
                  )}
                  {clientDetail?.enquiryLeadType === "WarmLead" ? (
                    <a className="s_cursor">
                      <img src={Coffee} alt="" />
                    </a>
                  ) : (
                    <a
                      className="s_cursor"
                      onClick={() => updateenquiryleadtype("WarmLead")}
                    >
                      <img src={Coffee} alt="" />
                    </a>
                  )}
                  {clientDetail?.enquiryLeadType === "HotLead" ? (
                    <a className="s_cursor">
                      <img src={Sun} alt="" />
                    </a>
                  ) : (
                    <a
                      className="s_cursor"
                      onClick={() => updateenquiryleadtype("HotLead")}
                    >
                      <img className="cursor" src={Sun} alt="" />
                    </a>
                  )}
                </div>
                <br />
              </div>
              <div class="personal-details">
                <h3>Personal Details</h3>
                <div class="email">
                  <p>Email</p>
                  <p>
                    <strong>
                      {clientDetail1?.username ? clientDetail1?.username : "-"}
                    </strong>
                  </p>
                </div>
                <div class="dob">
                  <p>Date of Birth</p>
                  <p>
                    <strong>
                      {clientDetail1?.dob
                        ? moment(clientDetail1?.dob).format("DD-MMM-YYYY")
                        : "-"}
                    </strong>
                  </p>
                </div>
                <div class="contact">
                  <p>Contact</p>
                  <p>
                    <strong>
                      {clientDetail?.phone ? clientDetail?.phone : "-"}
                    </strong>
                  </p>
                </div>
                <div class="address">
                  <p>Address</p>
                  <p>
                    <strong>
                      {clientDetail1?.address}
                      <br />
                      {clientDetail1?.city}
                      <br />
                      {clientDetail1?.state}
                      <br />
                      {clientDetail1?.country ? clientDetail1?.country : "-"}
                    </strong>
                  </p>
                </div>
                <div class="postal">
                  <p>Postal Code</p>
                  <p>
                    <strong>{clientDetail1?.pincode || "-"}</strong>
                  </p>
                </div>
                <div class="visa-expiry">
                  <p>Visa Expiry</p>
                  <p>
                    <strong>
                      {clientDetail1?.visaExpiry
                        ? moment(clientDetail1?.visaExpiry).format(
                          "DD-MMM-YYYY"
                        )
                        : "-"}
                      &nbsp;&nbsp;
                      <span style={{ color: "red" }}>
                        {/* {visaDateDiff ? (
                          <>
                            {Number(visaDateDiff) < 0
                              ?`(${-visaDateDiff} days delay)`
                              : `(${visaDateDiff} days remaining)`}
                          </>
                        ) : null} */}
                        {visaDateDiff}
                      </span>
                    </strong>
                  </p>
                </div>
                <div class="visa-type">
                  <p>Visa Type</p>
                  <p>
                    <strong>
                      {clientDetail1?.visaType ? clientDetail1?.visaType : "-"}
                    </strong>
                  </p>
                </div>
                <div class="passport">
                  <p>Country of Passport</p>
                  <p>
                    <strong>
                      {clientDetail1?.passport ? clientDetail1?.passport : "-"}
                    </strong>
                  </p>
                </div>
                <div class="passport-number">
                  <p>Passport Number</p>
                  <p>
                    <strong>
                      {clientDetail1?.passportNumber
                        ? clientDetail1?.passportNumber
                        : "-"}
                    </strong>
                  </p>
                </div>
                <div class="sub-agent">
                  <p>Sub Agent</p>
                  <p>
                    <strong>
                      {clientDetail1?.subAgentName
                        ? clientDetail1?.subAgentName
                        : "-"}
                    </strong>
                  </p>
                </div>
                <hr />
                <div class="assignee">
                  <p>Consultant</p>
                  <p>
                    <strong>
                      {clientDetail?.consultantName
                        ? clientDetail?.consultantName
                        : "-"}
                    </strong>
                  </p>
                </div>
                <div class="created-by">
                  <p>Created By</p>
                  <p>
                    <strong>
                      {clientDetail?.createdBy ? clientDetail?.createdBy : "-"}
                    </strong>
                  </p>
                </div>
                <div class="assignee">
                  <p>Created ON</p>
                  <p>
                    <strong>
                      {clientDetail1?.addedOn
                        ? moment(clientDetail1?.addedOn).format("DD-MMM-YY")
                        : "-"}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="card client-details" id="cardNumber" style={{ overflow: "hidden" }}>
              <div class="maximize-profile">
                <span
                  onClick={() => {
                    let cardNum = document.getElementById("cardNumber");
                    cardNum.style.marginLeft = "20px";
                    let right_sp = document.getElementById("right_span");
                    right_sp.style.visibility = "hidden";
                    let card = document.getElementById("cardClient");
                    card.style.display = "block";
                  }}
                  id="right_span"
                  style={{ visibility: "hidden" }}
                >
                  {" "}
                  &#187;{" "}
                </span>
              </div>
              {/* <AppBar position="static" color="default"> */}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                // aria-label="scrollable auto tabs example"
                aria-label="disabled tabs example"
              >
                <Tab className="tab-bold" label="Dashboard" {...a11yProps(0)} />
                {check1pointpermission(343, permissionData, rolepermission) ? (<Tab className="tab-bold" label="Admission" {...a11yProps(1)} />) : <Tab className="tab-bold tabdisplay" label="Admission" {...a11yProps(1)} disabled />}
                {check1pointpermission(344, permissionData, rolepermission) ? (<Tab className="tab-bold" label="Migration" {...a11yProps(2)} />) : <Tab className="tab-bold tabdisplay" label="Migration" {...a11yProps(2)} disabled />}
                {check1pointpermission(345, permissionData, rolepermission) ? (<Tab className="tab-bold" label="Insurance" {...a11yProps(3)} />) : <Tab className="tab-bold tabdisplay" label="Insurance" {...a11yProps(3)} disabled />}
                {check1pointpermission(346, permissionData, rolepermission) ? (<Tab className="tab-bold" label="Skill Assessment" {...a11yProps(4)} />) : <Tab className="tab-bold tabdisplay" label="Skill Assessment" {...a11yProps(4)} disabled />}
                {check1pointpermission(347, permissionData, rolepermission) ? (<Tab className="tab-bold" label="Accounts" {...a11yProps(5)} />) : <Tab className="tab-bold tabdisplay" label="Accounts" {...a11yProps(5)} disabled />}
                <Tab className="tab-bold" label="Notes" {...a11yProps(6)} />
                {check1pointpermission(348, permissionData, rolepermission) ? (<Tab className="tab-bold" label="EOI" {...a11yProps(7)} />) : <Tab className="tab-bold tabdisplay" label="EOI" {...a11yProps(7)} disabled />}
                {check1pointpermission(349, permissionData, rolepermission) ? (<Tab className="tab-bold" label="PTE/IELTS" {...a11yProps(8)} />) : <Tab className="tab-bold tabdisplay" label="PTE/IELTS" {...a11yProps(8)} disabled />}
              </Tabs>
              {/* </AppBar> */}
              <TabPanel
                value={value}
                index={0}
                component={<WorkflowDashboard />}
              />
              {check1pointpermission(
                343,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  className="tabpanel"
                  value={value}
                  index={1}
                  component={
                    <WorkflowListing
                      clientId={params.client_id}
                      workflowType="Admission"
                    // workflowType={workflowtyperedux}
                    />
                  }
                />
              ) : null}
              {check1pointpermission(
                344,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  value={value}
                  index={2}
                  component={
                    <WorkflowListing
                      clientId={params.client_id}
                      workflowType="Migration"
                    />
                  }
                />
              ) : null}
              {check1pointpermission(
                345,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  value={value}
                  index={3}
                  component={
                    <WorkflowListing
                      clientId={params.client_id}
                      workflowType="Insurance"
                    />
                  }
                />
              ) : null}
              {check1pointpermission(
                346,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  value={value}
                  index={4}
                  component={
                    <WorkflowListing
                      clientId={params.client_id}
                      workflowType="SkillAssessment"
                    />
                  }
                />
              ) : null}
              {check1pointpermission(
                347,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  value={value}
                  index={5}
                  component={
                    <InvoiceList
                      clientId={params.client_id}
                      workflowType="Accounts"
                    />
                  }
                />
              ) : null}
              <TabPanel
                value={value}
                index={6}
                component={<EnquiryNotesDisplay clientId={params.client_id} />}
              />
              {check1pointpermission(
                348,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  value={value}
                  index={7}
                  component={<Eoi clientId={params.client_id} />}
                />
              ) : null}
              {check1pointpermission(
                349,
                permissionData,
                rolepermission
              ) ? (
                <TabPanel
                  value={value}
                  index={8}
                  component={<Pte clientId={params.client_id} />}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEnquiryWorkflowList;
