import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import moment from "moment";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "./../features/userSlice";
import { removeAdmin, selectAdmin } from "./../features/adminSlice";
import Modal from "react-modal";
import Pagination from "@material-ui/lab/Pagination";
import "./../css/Responsive.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useStyles } from "../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import sort from "./../images/sort.svg";
import { check1pointpermission } from "../Permission/permission";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    transform: "translate(-50%, -50%)",
  },
};

function BrandAmbasador(props) {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const alladmin = useSelector(selectAdmin);
  const dispatch = useDispatch();
  const history = useHistory();
  const isw_user_role = localStorage.getItem("isw_user_role");
  const jwtToken = "Bearer " + user.isw_user_token;
  const [backdropopen, setbackdropopen] = useState(false);
  const [deleteuser, setDeleteuser] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [sortState, setsortState] = useState("addedOn");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);
  const [display1, setdisplay] = useState("inline");


  const datepast = moment().subtract(1, "M").format("YYYY-MM-DDThh:mm:ss");
  const [datefilter, setdatefilter] = useState();
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [allBrandAmbasador, setAllBrandAmbasador] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allBrandAmbasador]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    var available_resource = resource_obj.includes(31);
    if (check1pointpermission(1400, permission_obj, resource_obj)) {
      getAllBrandAmbasador(pageno);
      dispatch(setdashboardcheck(31));
    } else {
      history.push("/errorPage");
    }
  };

  const createadminpage = () => {
    history.push("/create-brand-ambassador");
  };

  const handlepage = (event, value) => {
    setpageno(value - 1);
    getAllBrandAmbasador(value - 1);
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };

  const getAllBrandAmbasador = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getUserByRoleIdWithPage`, {
        headers: {
          Authorization: jwtToken,
          roleId: 3,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setAllBrandAmbasador(response.data.data.content);
          setbackdropopen(false);
          setsearch(false);
          setdatefilter(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const sortClient = (e) => {
    setbackdropopen(true);
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    axios
      .get(`${base_url.api2}/getUserByRoleIdWithPage`, {
        headers: {
          Authorization: jwtToken,
          roleId: 3,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          setgetpageno(response.data.data.totalPages);
          var client_admin = response.data.data.content;
          setAllBrandAmbasador(response.data.data.content);
          setbackdropopen(false);
          setsearch(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const deleteCurrentUser = (elem) => {
    axios
      .delete(`${base_url.api2}/deleteUserByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: deleteuser,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.data.message);
          setIsOpen(false);
          dispatch(
            removeAdmin({
              id: deleteuser,
            })
          );
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const editUser = (elem) => {
    var admin_username = elem.target.id;
    history.push("/edit-brand-ambassador/" + admin_username);
  };

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openModal = (elem) => {
    console.log(elem.target.id);
    setDeleteuser(elem.target.id);
    setIsOpen(true);
  };

  const [selecteduser, setselecteduser] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const openModal2 = (elem) => {
    console.log(elem.target.id);
    setselecteduser(elem.target.id);
    setIsOpen2(true);
  };

  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const openModal3 = (elem, p) => {
    if (p.status === 1) {
      console.log("called inactive");
      let data = {
        email: elem.target.id,
        status: Number(0),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail`, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            toast.success(
              "Brand Ambasador " +
              p.firstName +
              " " +
              p.lastName +
              " Status Inactive"
            );
            // setIsOpen3(false);
            getAllBrandAmbasador(pageno);
          },
          (error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    } else {
      let data = {
        email: elem.target.id,
        status: Number(1),
      };
      axios
        .post(`${base_url.api2}/updateUserDataByEmail  `, data, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            // toast.success(response.data.message);
            toast.success(
              "Brand Ambasador " +
              p.firstName +
              " " +
              p.lastName +
              " Status Active"
            );
            // setIsOpen3(false);
            getAllBrandAmbasador(pageno);
          },
          (error) => {
            handle403error(error);
            toast.error(JSON.stringify(error.response.data.message));
          }
        );
    }
    setselecteduser(elem.target.id);
    // setIsOpen3(true);
  };

  const submit2 = (e) => {
    let email = e.target[0].value.trim();
    let password = e.target[1].value.trim();
    let data = {
      email,
      password,
    };
    axios
      .post(`${base_url.api2}/updatePasswordByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);
          setIsOpen2(false);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [searchdata, setsearch] = useState(false);
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    // setdisplay("display");
    setpageno(value - 1);
    console.log(value);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchUserByRoleIdAndDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            roleId: 3,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setAllBrandAmbasador(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            setdatefilter(true);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setAllBrandAmbasador(response.data.data.content);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    }
  };

  const submit = (e) => {
    setbackdropopen(true);
    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchUser`, {
        headers: {
          Authorization: jwtToken,
          searchQuery: srchdata,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          var client_admin = response.data.data.content;
          setAllBrandAmbasador(response.data.data.content);
          // dispatch(getAdmin(client_admin));
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setsearch(true);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const submitrows = (e) => {
    setbackdropopen(true);
    setpageno(0);
    if (searchinput != null) {
      axios
        .get(`${base_url.api2}/searchUser`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var client_admin = response.data.data.content;
            setAllBrandAmbasador(response.data.data.content);
            setgetpageno(response.data.data.totalPages);
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            handle403error(error);
          }
        );
    } else {
      getAllBrandAmbasador(0);
    }
  };
  const handleSelect = (event) => {
    console.log(event.target.value);
  };

  const submitstatus = (e) => {
    let email = e.target[0].value;
    let status = e.target[1].value;
    let data = {
      email,
      status,
    };
    axios
      .post(`${base_url.api2}/updateUserByEmail`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);
          setIsOpen3(false);
          getAllBrandAmbasador(pageno);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setbackdropopen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setpageno(0);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    console.log(data);
    axios
      .post(`${base_url.api2}/searchUserByRoleIdAndDate`, data, {
        headers: {
          Authorization: jwtToken,
          roleId: 3,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          var client_admin = response.data.data.content;
          setAllBrandAmbasador(response.data.data.content);
          setgetpageno(response.data.data.totalPages);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setpageno(0);
          setdatefilter(true);
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };
  const editambassador = (elem) => {
    var provider_id = elem.target.id;
    history.push("/edit-brand-ambassador/" + provider_id);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Brand Ambassador</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Other</a><span className="s_span ">-</span>
          <a href="#/brand-ambassador" class="b-link active">
            Brand Ambasador
          </a>
        </ul>
        {/* <a href="workload-dashboard.html" class="back-btn">Back</a> */}
        <a
          className="sent-button"
          onClick={createadminpage}
          style={{ float: "right" }}
        >
          Add
        </a>
      </div>

      <div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <Row style={{ marginTop: "10px", marginBottom: "-15px" }}>
            <Col md={2}>

              <div>
                <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                >
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <select
                    className="form-control"
                    name="branch"
                    value={rows}
                    onChange={handleRows}
                    style={{ backgroundColor: "#f5f5f9", width: "100%" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </form>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <label className="area_rowslable">Date</label>
                <div className="clearsearchclass" style={{ width: "100%", marginTop: "0px" }}>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="datepickerrangevaluetextone"
                        style={{ backgroundColor: "#f5f5f9", width: "100%" }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    {/* {datefilter ? (
                    <div style={{ float: "left",width:"95%" }} className="s_datefilter">
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdatefilter(false);
                          setdatevalue("Click to open");
                          setdirection("DESC");
                          setpageno(0);
                          getAllBrandAmbasador(0);
                        }}
                      >
                        Clear Search
                      </a>
                    </div>
                  ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "7px",
                        position: "absolute",
                        right: "5px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
            </Col>
            <Col md={5}>{null}</Col>
            <Col md={2}>
              <div className="clearsearchclass" style={{ marginTop: "20px", marginRight: "-90px" }}>
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                    style={{ marginLeft: "0px", width: "100%" }}
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i class="fa fa-search" type="submit" style={{ color: "#6d7290" }}></i>
                  </button>
                </form>
              </div>

            </Col>
          </Row>
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allBrandAmbasador?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              {/* <button
                className="btn btn-success"
                onClick={createadminpage}
                style={{ float: "right" }}
              >
                Add
              </button> */}
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              striped
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ minWidth: "100px" }}>IMAGE</th>
                  <th style={{ minWidth: "120px" }}>
                    NAME{" "}
                    <img
                      src={sort}
                      className="sorting"
                      // className={display}
                      id="firstName"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    CONTACT{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="email"
                      // className={display}
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    STATE{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      // className={display}

                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    COUNTRY{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      // className={display}

                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "160px" }}>
                    CREATED{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="addedOn"
                      // className={display}

                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "160px" }}>
                    LAST LOGIN{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="lastLogin"
                      // className={display}
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    STATUS{" "}
                    <img
                      src={sort}
                      className="sorting"
                      id="status"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}`, height: "10px" }}
                    />
                  </th>
                  {/* <th style={{ minWidth: "200px" }}>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {allBrandAmbasador != null ? (
                  allBrandAmbasador &&
                  allBrandAmbasador.map((p) => (
                    <tr key={p.id}>
                      <td>
                        <img src={p.photo} alt="" style={{ height: "50px" }} />
                      </td>
                      <td>
                        {p.firstName} {p.lastName}
                      </td>
                      <td>
                        {p.email}
                        <br />
                        {p.phone == 0 ? " " : p.phone}
                      </td>
                      <td>{p.state}</td>
                      <td>{p.country}</td>
                      <td>
                        {p.addedOn
                          ? moment(p.addedOn.split(".")[0]).format(
                            "DD-MMM-YY"
                          )
                          : null}
                      </td>
                      <td>
                        {p.lastLogin
                          ? moment(p.lastLogin.split(".")[0]).format(
                            "DD-MMM-YY hh.mm a"
                          )
                          : null}
                      </td>
                      <td>
                        <Link
                          id={p.username}
                          onClick={(e) => {
                            openModal3(e, p);
                          }}
                        >
                          {p.status == "1" ? "Active" : "Inactive"}
                        </Link>
                        <Modal
                          isOpen={modalIsOpen3}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitstatus(e);
                            }}
                          >
                            <div className="form-inner-admin">
                              <h3 style={{ color: "black" }}>Update Status</h3>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>User Name:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={selecteduser}
                                      readOnly
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col>
                                    <label>Status:</label>
                                    <select
                                      defaultValue={
                                        p.status == 1 ? "Active" : "InActive"
                                      }
                                      className={classes.selectdrop}
                                      name="status"
                                      // value={p.status}
                                      // onChange={inputEvent}
                                      required
                                    >
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </Col>
                                </Row>
                              </div>
                              <div className="form-group creatadmin">
                                <Row className="justify-content-end">
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn listing_addbutton mr-1"
                                    >
                                      Update
                                    </button>
                                  </div>
                                  <div>
                                    <Button
                                      color="secondary"
                                      onClick={closeModal}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </Modal>
                      </td>
                      {/* <td id={p.id}>
                          <Link id={p.email} onClick={editambassador}>
                            Edit
                          </Link>
                        </td> */}
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable" style={{ fontWeight: "bold" }}></label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                    {allBrandAmbasador?.length ? (
                      <div className="s_spanPage">
                        {countnumber * rows + 1} to{" "}
                        {countnumber * rows + numberofElements} of {totalUser} records
                      </div>
                    ) : (
                      <div className="s_spanPage">No Record Found</div>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata || datefilter ? (
                  <Pagination
                    count={getpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default withRouter(BrandAmbasador);
