import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import Modal from "react-modal";
import moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  getcollege,
  removecollege,
  selectcollege,
} from "../features/collegeSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import Pagination from "@material-ui/lab/Pagination";
import "../css/Responsive.css";
import base_url from "../../api/bootapi";
import callsaveauditapi from "../../services/auditservice";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { Avatar } from "@material-ui/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import AddIcon from "@material-ui/icons/Add";
import { getproviderpermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import sort from "./../images/sort.svg";
import ProviderArchiveModal from "../Modal/Masters/ProviderArchiveModal";
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from "jspdf";

function Colleges(props) {
  const attachmenturlone = "https://api.intstudentworld.com";
  const [backdropopen, setbackdropopen] = useState();
  const user = useSelector(selectUser);
  const allcollege = useSelector(selectcollege);
  const dispatch = useDispatch();
  const [institutefilter, setinstitutefilter] = useState(false);
  const jwtToken = "Bearer " + user.isw_user_token;
  const username = user.isw_user_email;
  const history = useHistory();
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const [deleteCollege, setDeleteCollege] = useState(null);
  const [display1, setdisplay] = useState("inline");
  const [rolevalue, setrolevalue] = useState();
  const ipaddress = localStorage.getItem("AdminIpAddress");
  const datepast = moment().subtract(1, "M").format("YYYY-MM-DDThh:mm:ss");
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [datefilter, setdatefilter] = useState();
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [remarkcontent, setremarkcontent] = useState();
  const [remarkmodelopen, setremarkmodelopen] = useState(false);
  const [statusstate, setstatusstate] = useState(1);
  const [deleteProviderStatus, setdeleteProviderStatus] = useState();
  const [deleteProvidername, setdeleteProviderName] = useState();
  const toggle = (elem, p) => {
    console.log(elem.target.id);
    setDeleteCollege(elem.target.id);
    if (p) {
      setdeleteProviderName(p.name);
      if (p.status == 1) {
        setdeleteProviderStatus(0);
      } else {
        setdeleteProviderStatus(1);
      }
    }
    setModal(!modal);
  };

  const [colleges_details, setCollegeDetails] = useState(null);
  const [pageno, setpageno] = useState();
  const [paginationpageno, setpaginationpageno] = useState(1);
  const [sortState, setsortState] = useState("institutionId");
  const [direction, setdirection] = useState("DESC");
  const [rows, setrows] = React.useState(50);


  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const [myrows, setmyrows] = React.useState(20000);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
  }, [rows]);

  useEffect(() => {
    let width = document.querySelector("table")?.offsetWidth;
    if (document.querySelector(".scroller")) {
      document.querySelector(".scroller").style.width = `${width}px`;
    }
  }, [allcollege]);

  let scrolling = false;
  const scrolldiv = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow .table-responsive");
    table.scrollTo(scroller.scrollLeft, 0);
  };

  const scrolltable = () => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let scroller = document.querySelector(".above-scroller");
    let table = document.querySelector(".displaytablerow  .table-responsive");
    scroller.scrollTo(table.scrollLeft, 0);
  };

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check1pointpermission(702, permission_obj, resource_obj)) {
      //if (available_permission) {
      dispatch(setdashboardcheck(22));
      if (localStorage.getItem("providerPageNo")) {
        let currentPage = localStorage.getItem("providerPageNo");
        setpageno(currentPage);
        setpaginationpageno(Number(currentPage) + 1);
        getAllCollege(currentPage, statusstate);
      } else {
        setpaginationpageno(1);
        setpageno(0);
        getAllCollege(0, statusstate);
      }
      // } else {
      //   history.push("/errorPage");
      // }
    } else {
      history.push("/errorPage");
    }
  };

  const handleRows = (event) => {
    setrows(event.target.value);
  };
  const createproviders = () => {
    history.push("/create-provider");
  };

  const [getpageno, setgetpageno] = useState();
  // const [getpageindex, setgetpageIndex] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    setpaginationpageno(value);
    getAllCollege(value - 1, statusstate);
    // setgetpageIndex(countnumber * rows + 1);
    // console.log(countnumber);
    // console.log(rows);
    // console.log(numberofElements);
  };

  const handleAction = (actionType) => {
    setbackdropopen(true);
    if (actionType === 'DOWNLOAD') {
      axios
        .get(`${base_url.api2}/getAllInstitution`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(0),
            size: Number(pdflength),
            status: 1,
          },
        })
        .then(
          (response) => {
            console.log(response.data.data.content);
            setDataForDownload(response.data.data.content);
            setDownloadReady(true);
            setbackdropopen(false);
          },
          (error) => {
            setDataForDownload();
            setDownloadReady(true);
            setbackdropopen(false);
          }
        );
    }
  };

  const [pdflength, setpdflength] = useState(50);
  const [pdfHeader, setPdfHeader] = useState();
  const [pdfRow, setPdfRow] = useState([]);

  const getAllCollege = (value1, statusstate) => {
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: sortState,
          direction: direction,
          page: Number(value1),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data.content);
          getPdfData();
          if (response.data.data.totalElements == 0) {
            setpdflength(50);
          } else {
            setpdflength(50);
          }
          var collegeList = response.data.data.content;
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   collegeList[i].snId = response.data.data.number * rows + i;
          // }
          dispatch(getcollege(collegeList));
          setCollegeDetails(collegeList);
          // setgetpageIndex(countnumber * rows + 1);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          setdatefilter(false);
          document.getElementById("myForm").reset();
          setbackdropopen(false);
        },
        (error) => {
          setbackdropopen(false);
          handle403error(error);
        }
      );
  };

  const getPdfData = () => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(pdflength),
        },
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const exportPdfProviderList = () => {
    let headData = {
      Authorization: jwtToken,
      columnName: sortState,
      direction: direction,
      page: Number(0),
      size: Number(pdflength),
      status: 1,
    };
    setbackdropopen(true);
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: headData,
      })
      .then((response) => {
        setPdfRow(response.data.data.content);
        const doc = new jsPDF();
        // autoTable(doc, { html: '#myTable' })
        var col = [
          "Institution Name",
          "Address",
          "Country",
          "University",
          "Provider CreatedBy",
          "Remark",
          "provider CreatedDate",
        ];
        var rows = [];
        response.data.data.content?.forEach((element) => {
          var temp = [
            element.name,
            "City: " + element.city + "\n" + "State: " + element.state + "\n" + "Zipcode: " + element.zipCode,
            element.country,
            element.university,
            element.providerCreatedBy,
            element.remarks,
            "SST: " +
            moment(element.providerCreatedDate).format("DD/MM/YYYY hh:mm:ss"),
          ];
          rows.push(temp);
        });
        doc.autoTable(col, rows, { pageBreak: "auto" });
        // doc.autoTable(col, rows, { pageBreak: 'auto' });
        doc.save("Provider_list.pdf");
        setbackdropopen(false);
      })
      .catch((error) => {
        setPdfRow();
        setbackdropopen(false);
        handle403error(error);
      });
  };

  const [searchdata, setsearch] = useState();
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value) => {
    setbackdropopen(true);
    setpaginationpageno(value);
    setrolevalue("all");
    setpageno(value - 1);
    console.log(value);
    if (datefilter) {
      axios
        .post(`${base_url.api2}/searchInstitutionByDate`, datesrange, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            setbackdropopen(false);

            var collegeList = response.data.data.content;
            dispatch(getcollege(collegeList));
          },
          (error) => {
            setbackdropopen(false);
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    } else {
      axios
        .get(`${base_url.api2}/searchInstitution`, {
          headers: {
            Authorization: jwtToken,
            searchQuery: searchinput,
            columnName: sortState,
            direction: direction,
            page: Number(value - 1),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var collegeList = response.data.data.content;
            dispatch(getcollege(collegeList));
            setbackdropopen(false);
          },
          (error) => {
            setbackdropopen(false);
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    }
  };
  const submit = (e) => {
    setrolevalue("all");
    setbackdropopen(true);

    setdisplay("none");
    let srchdata = e.target[0].value;
    setsearchinput(srchdata);
    setpageno(0);
    axios
      .get(`${base_url.api2}/searchInstitution`, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          searchQuery: srchdata,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response.data.data);
          var collegeList = response.data.data.content;
          dispatch(getcollege(collegeList));
          setgetpageno(response.data.data.totalPages);
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   collegeList[i].snId = response.data.data.number * rows + i;
          // }
          setsearch(true);
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const deleteCurrentCollege = (elem) => {
    let data = {
      institutionId: deleteCollege,
      status: deleteProviderStatus,
    };
    axios
      .put(`${base_url.api2}/updateInstitutionByIntitutionId`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let logs =
            isw_user_name +
            " Updated Status of Provider " +
            deleteProvidername +
            ".";
          callsaveauditapi(logs);
          dispatch(
            removecollege({
              id: deleteCollege,
            })
          );
          setModal(!modal);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };
  const editProvider = (elem) => {
    localStorage.setItem("providerPageNo", pageno);
    var provider_id = elem.target.id;
    history.push("/edit-provider/" + provider_id);
  };

  const classes = useStyles();

  const openUrl = (elem) => {
    console.log(elem.target.id);
    window.open(elem.target.id, "_blank");
  };
  const submitrows = (e) => {
    setrolevalue("all");
    setpageno(0);
    if (searchinput != null) {
      axios
        .get(`${base_url.api2}/searchInstitution`, {
          headers: {
            Authorization: jwtToken,
            columnName: sortState,
            searchQuery: searchinput,
            page: Number(0),
            size: Number(rows),
          },
        })
        .then(
          (response) => {
            console.log(response.data.data);
            var collegeList = response.data.data.content;
            dispatch(getcollege(collegeList));
            setgetpageno(response.data.data.totalPages);
          },
          (error) => {
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          }
        );
    } else {
      getAllCollege(0, statusstate);
    }
  };
  const sortClient = (e) => {
    setsortState(e.target.id);
    if (direction == "DESC") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("DESC");
    }
    // getallEnquiry(pageno);
    axios
      .get(`${base_url.api2}/getAllInstitution`, {
        headers: {
          Authorization: jwtToken,
          status: statusstate,
          columnName: e.target.id,
          direction: direction,
          page: Number(pageno),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          console.log(response);
          var collegeList = response.data.data.content;
          dispatch(getcollege(collegeList));
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   collegeList[i].snId = response.data.data.number * rows + i;
          // }
          setCollegeDetails(collegeList);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [datesrange, setdatesrange] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const handleApply = (event, picker) => {
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setrolevalue("all");
    setbackdropopen(true);

    // setdatesrange(null);
    setpageno(0);
    setpaginationpageno(1);
    console.log(event, picker);
    let data = {
      addedOnFromDate: picker.startDate.format("YYYY-MM-DDTHH:MM:ss"),
      addedOnToDate: picker.endDate.format("YYYY-MM-DDTHH:MM:ss"),
    };
    setdatesrange(data);
    console.log(data);
    axios
      .post(`${base_url.api2}/searchInstitutionByDate`, data, {
        headers: {
          Authorization: jwtToken,
          columnName: sortState,
          direction: direction,
          page: Number(0),
          size: Number(rows),
        },
      })
      .then(
        (response) => {
          var collegeList = response.data.data.content;
          dispatch(getcollege(collegeList));
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   collegeList[i].snId = response.data.data.number * rows + i;
          // }
          setCollegeDetails(collegeList);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          setdatefilter(true);
        },
        (error) => {
          setbackdropopen(false);
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const filteroninstitute = (e) => {
    setbackdropopen(true);
    setrolevalue(e.target.value);
    setinstitutefilter(true);
    if (e.target.value === "all") {
      getAllCollege(pageno, statusstate);
    } else {
      axios
        .get(`${base_url.api2}/getInstitutionByType`, {
          headers: {
            Authorization: jwtToken,
            institutionType: e.target.value,
            columnName: sortState,
            direction: direction,
            page: Number(pageno),
            size: Number(rows),
          },
        })
        .then((response) => {
          var collegeList = response.data.data.content;
          dispatch(getcollege(collegeList));
          // for (let i = 0; i < response.data.data.numberOfElements; i++) {
          //   collegeList[i].snId = response.data.data.number * rows + i;
          // }
          setCollegeDetails(collegeList);
          setbackdropopen(false);
          settotalUser(response.data.data.totalElements);
          setcountnumber(response.data.data.number);
          setnumberofElements(response.data.data.numberOfElements);
          setgetpageno(response.data.data.totalPages);
          setsearch(false);
          setdatefilter(false);
        })
        .catch((err) => {
          setbackdropopen(false);
          dispatch(getcollege(null));
          setCollegeDetails(null);
          handle403error(err);
        });
    }
  };

  const filterInstituteStatus = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setstatusstate(Number(optionElementName));
    // console.log(optionElementName);
    getAllCollege(pageno, optionElementName);
  };

  const viewProvider = (elem) => {
    var provider_id = elem.target.id;
    // props.history.replace({ pathname: '/edit-Client/'+client_username })
    localStorage.setItem("providerPageNo", pageno);
    history.push("/view-provider/" + provider_id);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };

  function calculatePathName(cricosId) {
    // Extract the numeric part of the alphanumeric ID, assuming it's at the start
    const numericPart = cricosId.match(/^\d+/);
    const numericId = numericPart ? parseInt(numericPart[0]) : 0;

    // Calculate the lower and upper bounds for each range
    const lowerBound = Math.floor((numericId - 1) / 500) * 500 + 1;
    const upperBound = Math.min(lowerBound + 499, 10000); // Adjust the upper limit as needed

    // Format the lower and upper bounds as strings with leading zeros
    const lowerBoundStr = String(lowerBound).padStart(5, '0');
    const upperBoundStr = String(upperBound).padStart(5, '0');

    // Construct the path name
    const pathName = `${lowerBoundStr}-${upperBoundStr}`;

    return pathName;
  }

  return (
    <div id="root">
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className="commoncomponent_cardcss"> */}
      <div
        class="page-head d-flex justify-content-between p-4"
        style={{ marginTop: "4.3rem", marginBottom: "-20px" }}
      >
        <ul class="breadcrums">
          <h3 class="page-title bold">Manage Provider</h3>
          <a href="#/" class="b-link">
            Home
          </a>
          <span className="s_span ">-</span><a class="b-link">Partner</a><span className="s_span ">-</span>
          <a href="#/provider" class="b-link active">
            Provider
          </a>
        </ul>
        {check1pointpermission(703, permissionData, rolepermission) ? (
          <a
            className="sent-button"
            onClick={createproviders}
            style={{ float: "right" }}
          >
            Add
          </a>
        ) : null}
      </div>
      <div>
        {/* <Row>
            <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <strong>Manage Provider</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionData?.includes(167) ? (
                <button
                  className="btn listing_addbutton"
                  onClick={createproviders}
                >
                  <AddIcon className="listing_addbutton_i" /> Add
                </button>
              ) : null}
            </Col>
          </Row> */}
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            borderRight: "1.5rem solid #e9ebf2",
            borderLeft: "1.5rem solid #e9ebf2",
            borderTop: "1.5rem solid #e9ebf2",
            marginBottom: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Row style={{ gap: "25px" }}>
              <div style={{ marginRight: "7px" }}>
                {/* <form
                  className="master"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitrows(e);
                  }}
                > */}
                {/* <div style={{ marginTop: "-10px", width: "fit-content" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                        style={{
                          border: "2px solid #ced4da ",
                          paddingTop: "5px",
                          backgroundColor: "#f5f5f9",
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form> */}
                <form className="master">
                  <div style={{ marginTop: "-10px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                      <select
                        className="s_client_limit"
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
              </div>
              <div class="s_submaster">
                <label className="area_rowslable">Date</label>
                <div className="clearsearchclass" style={{ width: "200px", marginTop: "0px" }}>
                  <form
                    id="myForm"
                    className="d-flex"
                    style={{ position: "relative" }}
                  >
                    <DateRangePicker
                      onEvent={handleEvent}
                      onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <p
                        className="s_client_datepickerrangevaluetextone"
                        style={{ backgroundColor: "#f5f5f9", fontSize: "14px", color: "#6d7290", padding: "10px", width: "100%" }}
                      >
                        {datevalue}
                      </p>
                    </DateRangePicker>
                    {/* {datefilter
                 ? (
                  <div className="s_datefilter">
                    <a
                      className={classes.clrsrch}
                      onClick={() => {
                        setbackdropopen(true);
                        setdisplay("inline");
                        setdatevalue("Click to open");
                        setdirection("DESC");
                        setpageno(0);
                        getAllCollege(0, statusstate);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null} */}
                    <span
                      className="listpagesearch_button"
                      style={{
                        top: "10px",
                        position: "absolute",
                        right: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <i className="fa fa-calendar"></i>
                    </span>
                  </form>
                </div>
              </div>
              <div class="s_submaster">
                <label className="area_rowslable">Branch</label>
                <br />
                <select
                  className="form-control"
                  class="s_client_form-control"
                  name="institutiontype"
                  value={rolevalue}
                  onChange={(e) => filteroninstitute(e)}
                >
                  <option value="all">- Select Type -</option>
                  <option value="">University</option>
                  <option value="higherEducation">Higher Education</option>
                  <option value="vet">VET</option>
                  <option value="elicos">Elicos</option>
                  <option value="shortCourse">Short Course</option>
                  <option value="pyCourse">PyCourse</option>
                </select>
                {institutefilter ? (
                  <div>
                    <a
                      style={{
                        textDecoration: "none",
                        margin: "10px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setbackdropopen(true);

                        setdisplay("inline");
                        setdirection("DESC");
                        setrolevalue("all");
                        setinstitutefilter(false);
                        getAllCollege(0, statusstate);
                      }}
                    >
                      Clear Filter
                    </a>
                  </div>
                ) : null}
              </div>
              <div class="s_submaster">
                <div className={classes.side}>
                  <label className="area_rowslable">Status</label>
                  <br />
                  <select
                    className="form-control"
                    style={{ backgroundColor: "#f5f5f9" }}
                    onChange={filterInstituteStatus}
                  >
                    <option value="1">Unarchive</option>
                    <option value="0">Archive</option>
                  </select>
                </div>
              </div>
            </Row>
            {/* </Col>
                <Col md={3} style={{ marginTop: "15px" }}> */}
            <div className="s_search">
              <div className="clearsearchclass">
                <form
                  id="myForm"
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(e);
                  }}
                >
                  <input
                    className="form-control"
                    placeholder="Search.."
                    name="search"
                  />
                  <button className="btn listpagesearch_button" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              {searchdata ? (
                <a
                  className={classes.clrsrch}
                  onClick={() => {
                    setdisplay("inline");
                    setrolevalue("all");
                    setinstitutefilter(false);
                    setbackdropopen(true);
                    setpageno(0);
                    getAllCollege(0, statusstate);
                  }}
                >
                  Clear Search
                </a>
              ) : null}
            </div>
          </div>
          {/* </Col>
              </Row> */}
        </div>
        <div
          className="newlisting_headertabdesign"
          style={{
            padding: "1.5rem",
            border: "1.5rem solid #e9ebf2",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          <Row className="s_pagi">
            <Col md={6}>
              {allcollege?.length ? (
                <p className="s_para">
                  {countnumber * rows + 1} to{" "}
                  {countnumber * rows + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p className="s_para">No Record Found</p>
              )}
            </Col>
            {/* <Col md={6}>
              {permissionData?.includes(61) ? (
                <a
                  className="btn btn-success"
                  onClick={createproviders}
                  style={{ float: "right" }}
                >
                  Add
                </a>
              ) : null}
            </Col> */}

            <Col md={6} style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                {check1pointpermission(
                  730,
                  permissionData,
                  rolepermission
                ) ? (
                  <>
                    <button
                      className="btn listing_addbutton"
                      onClick={exportPdfProviderList}
                    >
                      Export PDF
                    </button>
                  </>
                ) : null}
                {check1pointpermission(
                  730,
                  permissionData,
                  rolepermission
                ) ? (
                  <>
                    {dataForDownload != null ? (
                      <>
                        <button type="button" className="btn listing_addbutton" style={{ marginLeft: "25px" }} onClick={(e) => handleAction('DOWNLOAD')} >Export CSV</button>
                        <CSVLink
                          data={dataForDownload}
                          filename="Provider_list.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank" />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="above-scroller" onScroll={scrolldiv}>
            <div className="scroller"></div>
          </div>
          <div className="displaytablerow">
            {/* <Table responsive striped onScroll={scrolltable}> */}
            <Table
              className="workload card-table table-borderless"
              responsive
              onScroll={scrolltable}
            >
              <thead className="s_sticky">
                <tr>
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th style={{ minWidth: "70px" }}>LOGO</th>
                  <th style={{ minWidth: "500px" }}>
                    NAME {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="name"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "300px" }}>
                    ADDRESS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="state"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    COUNTRY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="country"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    UNIVERSITY {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="university"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "120px" }}>
                    REMARKS {/* <UnfoldMoreIcon */}
                    <img
                      src={sort}
                      className="sorting"
                      id="remarks"
                      onClick={sortClient}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "110px" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {allcollege != null ? (
                  allcollege &&
                  allcollege.map((p, index) => (
                    <>
                      {p.status === 1 ?
                        <tr key={p.institutionId} className="s_tr">
                          <td style={{ textAlign: "center", width: "50px" }}>
                            {/* {p.snId+1} */}
                            {countnumber * rows + 1 + index}
                          </td>
                          <td>
                            {/* {p.logo == null ? (
                              <Avatar className="providerlist_logo" />
                            ) : (
                              <img
                                alt=""
                                src={`${attachmenturlone}/attachments/providers/australia/${calculatePathName(
                                  p.cricosProviderCode
                                )}/${p.cricosProviderCode}/${p.logo}`}
                                style={{ width: "70px", height: "50px" }}
                              />
                            )} */}
                            {p.logo ? (
                              <img
                                src={p.logo}
                                alt=""
                                style={{ width: "70px", height: "50px" }}
                              />
                            ) : (
                              <Avatar className="providerlist_logo" />
                            )}
                          </td>
                          <td>
                            {check1pointpermission(
                              706,
                              permissionData,
                              rolepermission
                            ) ? (
                              <a
                                className="effects"
                                id={p.institutionId}
                                onClick={viewProvider}
                                href={`#/view-provider/` + p.institutionId}
                              >
                                {p.name}
                              </a>
                            ) : (
                              <>{p.name}</>
                            )}
                            <br />
                            Cricos Provider Code: {p.cricosProviderCode}
                          </td>
                          <td>
                            {p.city}
                            <br />
                            {p.state}
                            <br />
                            {p.zipCode}
                          </td>
                          <td>{p.country}</td>
                          <td>{p.university}</td>
                          <td className="leadellipsis">
                            {p.remarks}
                            {p.remarks != null ? (
                              // <span
                              //   className="leadellipsisspan"
                              //   onClick={() => {
                              //     setremarkmodelopen(true);
                              //     setremarkcontent(p.remarks);
                              //   }}
                              // >
                              //   View More
                              // </span>
                              <button
                                // className="leadellipsisspan"
                                className="role-deletebtn"
                                onClick={() => {
                                  setremarkmodelopen(true);
                                  setremarkcontent(p.remarks);
                                }}
                              >
                                View More
                              </button>
                            ) : null}
                          </td>
                          <Modal
                            isOpen={remarkmodelopen}
                            onRequestClose={() => setremarkmodelopen(false)}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h4>Remark</h4>
                            {remarkcontent ? (
                              <textarea
                                style={{ minWidth: "600px" }}
                                className="form-control"
                                value={remarkcontent}
                                rows={10}
                                readonly
                              />
                            ) : (
                              <p> No Record Available</p>
                            )}
                            <div
                              style={{
                                display: "flex",
                                margin: "10px 0",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                color="secondary"
                                onClick={() => setremarkmodelopen(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal>
                          <td id={p.institutionId}>
                            {check1pointpermission(
                              704,
                              permissionData,
                              rolepermission
                            ) ? (
                              <>
                                <a
                                  className="effects"
                                  id={p.institutionId}
                                  onClick={editProvider}
                                  href={`#/edit-provider/` + p.institutionId}
                                >
                                  Edit
                                </a>
                                &nbsp;|&nbsp;
                              </>
                            ) : null}
                            {check1pointpermission(
                              705,
                              permissionData,
                              rolepermission
                            ) ? (
                              // <Link
                              //   className="effects"
                              //   id={p.institutionId}
                              //   onClick={(e) => {
                              //     e.preventDefault();
                              //     toggle(e, p);
                              //   }}
                              // >
                              //   {p.status == 1 ? "Archive" : "Unarchive"}
                              // </Link>
                              <ProviderArchiveModal
                                header={p.status == 1 ? "Archive" : "Unarchive"}
                                status={p.status}
                                username={p.username}
                                jwtToken={jwtToken}
                                handle403error={handle403error}
                                statusstate={statusstate}
                                pageno={pageno}
                                p={p}
                                institutionId={p.institutionId}
                              />
                            ) : null}
                            {/* <Modal
                          isOpen={modal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        // overlayClassName="Overlay"
                        >
                          <h5>
                            Are you sure you want to{" "}
                            {p.status == 1 ? "Archive" : "Unarchive"} current
                            record?
                          </h5>
                          <div style={{ float: "right" }}>
                            <Button
                              color="danger"
                              onClick={deleteCurrentCollege}
                            >
                              {p.status == 1 ? "Archive" : "Unarchive"}
                            </Button>{" "}
                            <Button color="secondary" onClick={toggle}>
                              Cancel
                            </Button>
                          </div>
                        </Modal> */}
                          </td>
                        </tr>
                        :
                        <tr key={p.institutionId} className="s_inactive">
                          <td style={{ textAlign: "center", width: "50px" }}>
                            {/* {p.snId+1} */}
                            {countnumber * rows + 1 + index}
                          </td>
                          <td>
                            {p.logo ? (
                              <img
                                src={p.logo}
                                alt=""
                                style={{ width: "70px", height: "50px" }}
                              />
                            ) : (
                              <Avatar className="providerlist_logo" />
                            )}
                          </td>
                          <td>
                            {check1pointpermission(
                              706,
                              permissionData,
                              rolepermission
                            ) ? (
                              <a
                                className="effects"
                                id={p.institutionId}
                                onClick={viewProvider}
                                href={`#/view-provider/` + p.institutionId}
                              >
                                {p.name}
                              </a>
                            ) : (
                              <>{p.name}</>
                            )}
                            <br />
                            Cricos Provider Code: {p.cricosProviderCode}
                          </td>
                          <td>
                            {p.city}
                            <br />
                            {p.state}
                            <br />
                            {p.zipCode}
                          </td>
                          <td>{p.country}</td>
                          <td>{p.university}</td>
                          <td className="leadellipsis">
                            {p.remarks}
                            {p.remarks != null ? (
                              // <span
                              //   className="leadellipsisspan"
                              //   onClick={() => {
                              //     setremarkmodelopen(true);
                              //     setremarkcontent(p.remarks);
                              //   }}
                              // >
                              //   View More
                              // </span>
                              <button
                                // className="leadellipsisspan"
                                className="role-deletebtn"
                                onClick={() => {
                                  setremarkmodelopen(true);
                                  setremarkcontent(p.remarks);
                                }}
                              >
                                View More
                              </button>
                            ) : null}
                          </td>
                          <Modal
                            isOpen={remarkmodelopen}
                            onRequestClose={() => setremarkmodelopen(false)}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h4>Remark</h4>
                            {remarkcontent ? (
                              <textarea
                                style={{ minWidth: "600px" }}
                                className="form-control"
                                value={remarkcontent}
                                rows={10}
                                readonly
                              />
                            ) : (
                              <p> No Record Available</p>
                            )}
                            <div
                              style={{
                                display: "flex",
                                margin: "10px 0",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                color="secondary"
                                onClick={() => setremarkmodelopen(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal>
                          <td id={p.institutionId}>
                            {check1pointpermission(
                              704,
                              permissionData,
                              rolepermission
                            ) ? (
                              <>
                                <a
                                  className="effects"
                                  id={p.institutionId}
                                  onClick={editProvider}
                                  href={`#/edit-provider/` + p.institutionId}
                                >
                                  Edit
                                </a>
                                &nbsp;|&nbsp;
                              </>
                            ) : null}
                            {check1pointpermission(
                              705,
                              permissionData,
                              rolepermission
                            ) ? (
                              // <Link
                              //   className="effects"
                              //   id={p.institutionId}
                              //   onClick={(e) => {
                              //     e.preventDefault();
                              //     toggle(e, p);
                              //   }}
                              // >
                              //   {p.status == 1 ? "Archive" : "Unarchive"}
                              // </Link>
                              <ProviderArchiveModal
                                header={p.status == 1 ? "Archive" : "Unarchive"}
                                status={p.status}
                                username={p.username}
                                jwtToken={jwtToken}
                                handle403error={handle403error}
                                statusstate={statusstate}
                                pageno={pageno}
                                p={p}
                                institutionId={p.institutionId}
                              />
                            ) : null}
                            {/* <Modal
                          isOpen={modal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        // overlayClassName="Overlay"
                        >
                          <h5>
                            Are you sure you want to{" "}
                            {p.status == 1 ? "Archive" : "Unarchive"} current
                            record?
                          </h5>
                          <div style={{ float: "right" }}>
                            <Button
                              color="danger"
                              onClick={deleteCurrentCollege}
                            >
                              {p.status == 1 ? "Archive" : "Unarchive"}
                            </Button>{" "}
                            <Button color="secondary" onClick={toggle}>
                              Cancel
                            </Button>
                          </div>
                        </Modal> */}
                          </td>
                        </tr>
                      }
                    </>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable"></label>
                  <br />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 180, marginTop: 0, maxHeight: 180 }}>
                    <select
                      className={classes.selectlimit}
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                  {allcollege?.length ? (
                    <div
                      className="s_spanPage"
                    >
                      {countnumber * rows + 1} to{" "}
                      {countnumber * rows + numberofElements} of {totalUser} records
                    </div>
                  ) : (
                    <span className="s_spanPage">No Record Found
                    </span>
                  )}
                </div>
              </form>
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata || datefilter ? (
                  <Pagination
                    count={getpageno}
                    page={paginationpageno}
                    onChange={handlesearch}
                    shape="rounded"
                  />
                ) : (
                  <Pagination
                    count={getpageno}
                    page={paginationpageno}
                    onChange={handlepage}
                    shape="rounded"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Colleges;