import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";
import SelectCurrency from "react-select-currency";
import { useHistory, useParams } from "react-router";
import { Input, Typography } from "@material-ui/core";
import { Button } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setdashboardcheck, logout } from "../features/userSlice";
import { selectdashboard } from "../features/dashboardSlice";
import { useStyles, customStyles } from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import { getquotationpermission,check4pointpermission,  check1pointpermission } from "../Permission/permission";

const CreateQuotation = () => {
  const isoDateString = new Date().toISOString();
  const datenow2 = isoDateString.split(".")[0];
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const client = JSON.parse(localStorage.getItem("quotation"));
  const { register, handleSubmit, errors } = useForm();
  const date = moment();
  const datepast16 = date.add(14, "days").format("YYYY-MM-DD");
  const [duedate, setduedate] = useState(datepast16);
  // const datepast16 = moment().add(14, "D").format("YYYY-MM-DD");

  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const [newline, setnewline] = useState([
    {
      description: "",
      amount: "",
      taxpercent: "",
      taxamount: "",
      totalamount: "",
    },
  ]);
  const [totalamountvalues, settotalamountvalues] = useState([]);
  const [currency1, setcurrency] = useState("AUD");
  const [totalamttax, settotalamttax] = useState(0);
  const selectcurrency = (e) => {
    setcurrency(e.target.value);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1200, 1204, permission_obj, resource_obj)) {
      getclientdetails();
      dispatch(setdashboardcheck(29));
    } else {
      history.push("/errorPage");
    }
  };

 
  const [clientdetails, setclientdetails] = useState();
  const getclientdetails = () => {
    axios
      .get(`${base_url.api3}/getClientById`, {
        headers: {
          Authorization: jwtToken,
          clientId: client?.value,
        },
      })
      .then((res) => {
        setclientdetails(res.data.data);
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const addClick = () => {
    settotalamountvalues((prev) => [...prev, ""]);
  };
  const handlelinechange = (e, i) => {
    var lines = [...newline];
    lines[i].description = e.target.value;
    setnewline(lines);
  };
  const handletaxpercentchange = (e, i) => {
    var lines = [...newline];
    lines[i].taxpercent = Number(e.target.value);
    lines[i].taxamount = (
      (Number(lines[i].amount) * Number(lines[i].taxpercent)) /
      100
    ).toFixed(2);
    lines[i].totalamount = Number(lines[i].amount) + Number(lines[i].taxamount);
    let totalamount = [...totalamountvalues];
    totalamount[i] = Number(lines[i].totalamount);
    settotalamountvalues(totalamount);
    settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    setnewline(lines);
  };
  const handleamountChange = (e, i) => {
    var lines = [...newline];
    lines[i].amount = Number(e.target.value);
    lines[i].taxamount = (
      (Number(lines[i].amount) * Number(lines[i].taxpercent)) /
      100
    ).toFixed(2);
    lines[i].totalamount = Number(lines[i].amount) + Number(lines[i].taxamount);
    let totalamount = [...totalamountvalues];
    totalamount[i] = Number(lines[i].totalamount);
    settotalamountvalues(totalamount);
    settotalamttax(totalamount?.reduce((amount, item) => item + amount, 0));
    setnewline(lines);
  };
  const onSubmit = (data2) => {
    const isoDateString = new Date().toISOString();
    const datenow2 = isoDateString.split(".")[0];
    let address = `${clientdetails?.address}, ${clientdetails?.state}, ${clientdetails?.country}`;
    let clientId = client.value;
    let clientName = `${clientdetails?.firstName} ${clientdetails?.lastName}`;
    let clientUsername = clientdetails?.email;
    let currency = currency1;
    let id = 0;
    let quotationDate = datenow2;
    let quotationDetails = [];
    for (let i = 0; i < newline.length; i++) {
      let descriptiondata = `description-${i}`;
      let amountdata = `amount-${i}`;
      let taxamountdata = `taxAmount-${i}`;
      let taxPercentagedata = `taxPercentage-${i}`;
      let totalAmountdata = `totalAmount-${i}`;
      let amount = Number(data2[amountdata]);
      let description = data2[descriptiondata];
      let taxAmount = Number(data2[taxamountdata]);
      let taxPercentage = Number(data2[taxPercentagedata]);
      let totalAmount = Number(data2[totalAmountdata]);
      let id = 0;
      quotationDetails.push({
        amount,
        description,
        id,
        taxAmount,
        taxPercentage,
        totalAmount,
      });
    }
    let quotationDueDate = duedate
      ? moment(duedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let totalAmount = totalamttax;
    let data = {
      address,
      clientId,
      clientName,
      clientUsername,
      currency,
      id,
      quotationDate,
      quotationDetails,
      quotationDueDate,
      totalAmount,
    };
    console.log(data);
    axios
      .post(`${base_url.api2}/addQuotation`, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        toast.success("Quotation Created");
        history.push("/quotations");
      })
      .catch((err) => {
        handle403error(err);
      });
  };
  const goBack = () => {
    history.push("/quotations");
  };
  const dashboardvalue = useSelector(selectdashboard);

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div className="commoncomponent_cardcss addinvoice">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="addinvoice_header">
          <Typography variant="h5" color="primary">
            Client Quotation
          </Typography>
          <div className="d-flex justify-content-end">
            <button className="btn listing_addbutton" type="submit">
              Save
            </button>
            <button className="btn listing_addbutton ml-1" onClick={goBack}>
              &#x2630; List
            </button>
          </div>
        </div>
        <div className="addinvoice_details">
          <div style={{ display: "flex", flex: "0.7" }}>
            <div className="addinvoice_clientdetails">
              <div className="row quotation_margin_row">
                <Typography
                  className="col-md-3"
                  style={{
                    color: "cornflowerblue",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Name:
                </Typography>
                <Typography
                  variant="body2"
                  className="col-md-9"
                  style={{ marginTop: "2px", marginLeft: "5px" }}
                >
                  {/* {clientDetail1?.firstName} {clientDetail1?.lastName} */}
                  {`${clientdetails?.firstName} ${clientdetails?.lastName}`}
                </Typography>
              </div>
              <div className="row quotation_margin_row">
                <Typography
                  className="col-md-3"
                  style={{
                    color: "cornflowerblue",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Email:
                </Typography>
                <Typography
                  className="col-md-9"
                  variant="body2"
                  style={{ marginTop: "2px", marginLeft: "5px" }}
                >
                  {/* {isw_user_name} */}
                  {clientdetails?.email}
                </Typography>
              </div>
              <div className="row quotation_margin_row">
                <Typography
                  className="col-md-3"
                  style={{
                    color: "cornflowerblue",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  Address:
                </Typography>
                <Typography
                  variant="body2"
                  className="col-md-9"
                  style={{ marginTop: "2px", marginLeft: "5px" }}
                >
                  {/* {isw_user_name} */}
                  {`${clientdetails?.address}, ${clientdetails?.state}, ${clientdetails?.country}`}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ flex: "0.3" }}>
            <div>
              <div className="addinvoice_datedetail">
                <Typography
                  style={{
                    flex: "0.5",
                    color: "cornflowerblue",
                    fontWeight: "bold",
                  }}
                >
                  Quotation Date
                </Typography>
                <DatePicker
                  className="form-control underline-input"
                  selected={datenow2 ? new Date(datenow2) : null}
                  dateFormat="dd-MM-yyyy"
                  readOnly
                />
              </div>
              <div>
                <div className="addinvoice_datedetail">
                  <Typography
                    style={{
                      flex: "0.5",
                      color: "cornflowerblue",
                      fontWeight: "bold",
                    }}
                  >
                    Quotation Due Date
                  </Typography>
                  <DatePicker
                    className="form-control underline-input"
                    selected={duedate ? new Date(duedate) : null}
                    onChange={(date) => setduedate(date)}
                    minDate={new Date(datenow2)}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Date"
                    required
                  />
                </div>
              </div>
              <div className="addinvoice_datedetail">
                <Typography
                  style={{
                    flex: "0.5",
                    marginRight: "105px",
                    color: "cornflowerblue",
                    fontWeight: "bold",
                  }}
                >
                  Currency
                </Typography>

                <SelectCurrency
                  style={{ flex: "0.5", width: "198px" }}
                  value={currency1}
                  onChange={selectcurrency}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="invoice_adddetails quotation_table">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "cornflowerblue" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    SN
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Description
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    Amount
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    Tax(%)
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    Tax Amount
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    Total Amount
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    align="right"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="clientgeneralinvoicetables">
                {newline.map((p, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <input
                        ref={register}
                        placeholder="Enter Description.."
                        value={p.description}
                        onChange={(e) => handlelinechange(e, index)}
                        className="form-control underline-input"
                        name={`description-${index}`}
                        type="text"
                        required
                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        ref={register}
                        placeholder="Enter Amount.."
                        value={p.amount}
                        name={`amount-${index}`}
                        className="form-control underline-input inputalign"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={(e) => handleamountChange(e, index)}
                        required
                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        ref={register}
                        placeholder="Enter Tax%.."
                        value={p.taxpercent}
                        onChange={(e) => handletaxpercentchange(e, index)}
                        name={`taxPercentage-${index}`}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        className="form-control underline-input inputalign"
                        required
                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        ref={register}
                        value={p.taxamount}
                        className="form-control underline-input inputalign"
                        name={`taxAmount-${index}`}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        readOnly
                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        ref={register}
                        value={p.totalamount}
                        className="form-control underline-input inputalign"
                        name={`totalAmount-${index}`}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        readOnly
                      />
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        var lines = [...newline];
                        let totalamount = [...totalamountvalues];
                        lines.splice(index, 1);
                        totalamount.splice(index, 1);
                        settotalamountvalues(totalamount);
                        setnewline(lines);
                        settotalamttax(
                          totalamount?.reduce(
                            (amount, item) => item + amount,
                            0
                          )
                        );
                      }}
                    >
                      ❎
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="sent-button"
              // color="primary"
              // style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                addClick();
                setnewline((prev) => [
                  ...prev,

                  {
                    description: "",
                    amount: "",
                    taxpercent: "",
                    taxamount: "",
                    totalamount: "",
                  },
                ]);
              }}
            >
              Add Row
            </button>
            <div
              className="addinvoice_revenuedetailright"
              style={{ marginTop: "20px" }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="addinvoice_revenuedetailrightfirst"
              >
                Total Amount:
              </Typography>
              <div
                className="addinvoice_detailinput"
                style={{ marginRight: "30px" }}
              >
                <input
                  name="total_amount"
                  value={totalamttax}
                  className="form-control underline-input"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ marginTop: "40px" }}>
          
        </div> */}
      </form>
    </div>
  );
};

export default CreateQuotation;
