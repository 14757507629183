import React, { useState } from "react";
import Modal from "react-modal";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "react-select";
import callsaveauditapi from "../../../services/auditservice";
import { CountryDropdown } from "react-country-region-selector";
import {
    selectUser,
    setdashboardcheck,
    logout,
} from "./../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";


const EditRoleModal = ({
    setbackdropopen,
    toast,
    handle403error,
    pageno,
    isw_user_name,
    p,
    getallRoles,
    roleState,
}) => {
    const [editmodal, seteditmodal] = useState(false);
    const [editRoleId, seteditRoleId] = useState(p.id);
    const [editRoleCode, seteditRoleCode] = useState(p.roleCode);
    const [editRoleName, seteditRoleName] = useState(p.roleName);
    const [editRolestatus, seteditRolestatus] = useState(p.status);

    const user = useSelector(selectUser);
    const jwtToken = "Bearer " + user.isw_user_token;
    console.log(p);

    const classes = useStyles();
    const closeeditModal = () => {
        seteditmodal(false);
    };

    const openeditmodal = () => {
        seteditmodal(true);
    };


    const submitRole = (e) => {
        setbackdropopen(true);
        let roleName = e.target[0].value;
        let roleCode = e.target[1].value;
        let status = e.target[2].value;
        let id = editRoleId;
        let data = {
          roleName,
          roleCode,
          status,
          id,
        };
        console.log(data);
        axios
          .post(`${base_url.api3}/updateRoleById`, data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            console.log(response.data);
            toast.success(response.data.message);
    
            let log =
              isw_user_name +
              " Updated Role Status" +
              response.data.data.roleName +
              ".";
            callsaveauditapi(log);
            getallRoles(pageno);
            seteditmodal(false);
          })
          .catch((error) => {
            setbackdropopen(false);
            toast.error(JSON.stringify(error.response.data.message));
            handle403error(error);
          });
      };


    return (
        <>
            <button style={{ cursor: "pointer" }} onClick={openeditmodal} className="outline-button">
                Edit
            </button>
            <Modal
                isOpen={editmodal}
                onRequestClose={closeeditModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2>
                    <strong>Role</strong>
                </h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        submitRole(e);
                    }}
                >
                    <div>
                        <div className="form-group creatadmin">
                            <Row>
                                <Col md={12}>
                                    <label>
                                         Role Name
                                        <span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={editRoleName}
                                      name="roleName"
                                      onChange={(e) => {
                                        seteditRoleName(e.target.value);
                                      }}
                                      style={{ backgroundColor: "#f5f5f9" }}
                                      required
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={12}>
                                    <label>
                                        Role Code
                                        <span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={editRoleCode}
                                      name="roleCode"
                                      style={{ backgroundColor: "#f5f5f9" }}
                                      onChange={(e) => {
                                        seteditRoleCode(e.target.value);
                                      }}
                                      readOnly
                                    />
                                </Col>
                            </Row>
                            <br />
                            {roleState?.id === 1 ||
                              roleState?.id === 2 ||
                              roleState?.id === 3 ||
                              roleState?.id === 4 ||
                              roleState?.id === 5 ? (
                            <Row>
                                <Col md={12}>
                                    <label>
                                        Status
                                        <span style={{ color: "red" }}></span>:
                                    </label>
                                    <select
                                        className="form-control"
                                        style={{ backgroundColor: "#f5f5f9" }}
                                      >
                                        <option id="1" value="1">
                                          Active
                                        </option>
                                    </select>
                                </Col>
                            </Row>
                            ) : (
                            <Row>
                                <Col md={12}>
                                  <label>Status:</label>
                                  <select
                                    className="form-control"
                                    value={editRolestatus}
                                    name="status"
                                    style={{ backgroundColor: "#f5f5f9" }}
                                    onChange={(e) => {
                                        seteditRolestatus(e.target.value);
                                    }}
                                  >
                                    <option id="1" value="1">
                                      Active
                                    </option>
                                    <option id="0" value="0">
                                      InActive
                                    </option>
                                  </select>
                                </Col>
                              </Row>   
                               )} 
                            <Row>
                                <Col md={6}>
                                    <Row className="justify-content-end">
                                        <Button color="secondary" onClick={closeeditModal}>
                                            Cancel
                                        </Button>
                                        <button
                                            type="submit"
                                            className="btn listing_addbutton ml-1"
                                        >
                                            Submit
                                        </button>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default EditRoleModal;

