import React, { useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../Utilities/CSSUtilities";
import { Col, Row, Button } from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import axios from "axios";
import base_url from "../../api/bootapi";
import callsaveauditapi from "../../services/auditservice";

const ClientEmailModal = ({
  username,
  inputtype,
  chkPassState,
  setchkPassState,
  setinputtype,
  jwtToken,
  toast,
  isw_user_name,
  allclientdetails,
  statusstate,
  pageno
}) => {
  const [passwordmodal, setpasswordmodal] = useState(false);
  const [selecteduser, setselecteduser] = useState("");
  const closeModal = () => setpasswordmodal(false);
  const openmodal = (elem) => {
    setselecteduser(elem);
    setpasswordmodal(true);
  };

  const changeClientEmail = (e) => {
    let email = e.target[0].value;
    let change_email = e.target[1].value;
    // console.log(email+" new email is : "+change_email);
    // password = chkPassState.trim();
    let data = {};
    axios
      .post(`${base_url.api3}/OverWriteClientEmail`, data, {
        headers: {
          Authorization: jwtToken,
          ExistingEmail: email,
          UpdateEmail: change_email,
        },
      })
      .then(
        (response) => {
          toast.success(response.data.message);
          let logs =
            isw_user_name +
            " Client Email change for the user " +
            e.target[1].value +
            ".";
          callsaveauditapi(logs);
          allclientdetails(pageno, statusstate);
          closeModal();
        },
        (error) => {
          toast.error(JSON.stringify(error.data.message));
          // console.log(JSON.stringify(error.response));
        }
      );
    // } else {
    //   return false;
    // }
  };
  return (
    <>  
    <span style={{ cursor: "pointer" }} id={username} onClick={()=>openmodal(username)}>
      Change Email
      </span>       
      <Modal
        isOpen={passwordmodal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changeClientEmail(e);
          }}
        >
          <div>
            <h3 style={{ color: "black" }}>Change Client Email</h3>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                  <label>Existing Email:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    value={selecteduser}
                    name="existing_email"
                    readOnly
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <Row>
                <Col>
                <label>New Email:</label>
                  <input
                    type="text"
                    className="form-control underline-input"
                    name="new_email"
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group creatadmin">
              <div className="d-flex justify-content-end mt-2">
                <Button color="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <button className="btn listing_addbutton ml-1" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ClientEmailModal;
 