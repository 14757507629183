import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast } from "react-toastify";
import moment from "moment";
import { addtoClient, updateClient } from "./../features/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import "./../css/Responsive.css";
import {
  getworkflowType,
  setdashboardcheck,
  selectUser,
  gettabvalue,
  logout,
} from "../features/userSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { useStyles } from "../../Utilities/CSSUtilities";
import DatePicker from "react-datepicker";
import callsaveauditapi from "../../services/auditservice";
import { check1pointpermission } from "./../Permission/permission";
import Swal from 'sweetalert2'

const CreateClientLead = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const classes = useStyles();
  const [region, setregion] = useState();
  const params = useParams();
  const temp_client_id = params.temp_client_id;
  const client_username = params.client_username;
  // console.log(client_username);
  const [subagentid, setsubagentid] = useState();
  const [superagentid, setsuperagentid] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();
  const { register, handleSubmit, errors } = useForm();
  const [value, setValue] = React.useState("");
  const isw_user_name = localStorage.getItem("isw_user_name");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const isw_user_email = localStorage.getItem("isw_user_email");
  const [confirmpassword, setconfirmpassword] = useState();
  const [subagentname, setsubagentname] = useState();
  const [superagentname, setsuperagentname] = useState();
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split(".")[0];
  const [selectedOfficeid, setselectedOfficeid] = useState(null);
  const [selectedOfficename, setselectedOfficename] = useState(null);
  const [selectedOfficecode, setselectedOfficecode] = useState(null);
  const [inputtype, setinputtype] = useState("password");
  const [inputtype2, setinputtype2] = useState("password");
  const [dateofbirthdate, setdateofbirthdate] = useState(null);
  var dob = moment(dateofbirthdate);

  const [passportissuedate, setpassportissuedate] = useState(null);
  var pasissdate = moment(passportissuedate);

  const [passportexpirydate, setpassportexpirydate] = useState(null);
  var pasexpdate = moment(passportexpirydate);

  const [visaissuedate, setvisaissuedate] = useState(null);
  var visissdate = moment(visaissuedate);

  const [visaexpirydate, setvisaexpirydate] = useState(null);
  var visexpdate = moment(visaexpirydate);

  const [healthcoverdate, sethealthcoverdate] = useState(null);
  var heacovdate = moment(healthcoverdate);

  const [insuranceexpirydate, setinsuranceexpirydate] = useState(null);
  var insexpdate = moment(insuranceexpirydate);

  const [membershipexpirydate, setmembershipexpirydate] = useState(null);
  var memexpdate = moment(membershipexpirydate);

  const ipaddress = localStorage.getItem("AdminIpAddress");
  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    getPermissions();
  }, []);


  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);
    if (check1pointpermission(201, permission_obj, resource_obj)) {
      dispatch(setdashboardcheck(5));
      getClientTemp();
      getIntake();
      getOffice();
      getVisa();
      getHealthCover();
      getHealth();
      getsubagent();
      getsuperagent();
      getClientSource();
      getClientDetails();
    } else {
      history.push("/errorPage");
    }
  };

  const [tempClient, settempClient] = useState();
  const [getremarks, setremarks] = useState();
  const [tempClientEmail, settempClientEmail] = useState();
  const [tempClientSource, settempClientSource] = useState();
  const [availableconsultant, setConsultant] = useState();
  const [leadId, setLeadId] = useState();
  const [newClientId, setClientId] = useState();
  // console.log(leadId);
  // console.log(newClientId);
  const getClientTemp = () => {
    axios
      .get(`${base_url.api3}/getClientTempDetailsById`, {
        headers: {
          Authorization: jwtToken,
          clientTempId: temp_client_id,
        },
      })
      .then(
        (response) => {
          setLeadId(response.data.data.id);
          settempClientEmail(response.data.data.email);
          settempClientSource(response.data.data.clientSource);
          setLeadType(response.data.data.leadType);
          setremarks(response.data.data.remarks);
          settempClient({
            addedOn: response.data.data.addedOn,
            address: response.data.data.address,
            alias: response.data.data.alias,
            assigneeId: response.data.data.assigneeId,
            assigneeName: response.data.data.assigneeName,
            assigneeEmail: response.data.data.assigneeEmail,
            ban: response.data.data.ban,
            city: response.data.data.city,
            clientSource: response.data.data.clientSource,
            comments: response.data.data.comments,
            country: response.data.data.country,
            dateOfBirth: response.data.data.dateOfBirth,
            email: response.data.data.email,
            emailVerificationStatus: response.data.data.emailVerificationStatus,
            endTime: response.data.data.endTime,
            enquiryStatus: response.data.data.enquiryStatus,
            firstName: response.data.data.firstName,
            gender: response.data.data.gender,
            id: response.data.data.id,
            insuranceExpiryDate: response.data.data.insuranceExpiryDate,
            insuranceIssueDate: response.data.data.insuranceIssueDate,
            insuranceProvider: response.data.data.insuranceProvider,
            insuranceType: response.data.data.insuranceType,
            intake: response.data.data.intake,
            interestedServices: response.data.data.interestedServices,
            lastLogin: response.data.data.lastLogin,
            lastName: response.data.data.lastName,
            leadType: response.data.data.leadType,
            membershipExpiry: response.data.data.membershipExpiry,
            membershipNumber: response.data.data.membershipNumber,
            middleName: response.data.data.middleName,
            officeCode: response.data.data.officeCode,
            officeId: response.data.data.officeId,
            officeName: response.data.data.officeName,
            passportCountry: response.data.data.passportCountry,
            passportExpiryDate: response.data.data.passportExpiryDate,
            passportIssueDate: response.data.data.passportIssueDate,
            passportNumber: response.data.data.passportNumber,
            // password: response.data.data.address,
            phone: response.data.data.phone,
            pincode: response.data.data.pincode,
            policyNumber: response.data.data.policyNumber,
            secondaryEmail: response.data.data.secondaryEmail,
            startTime: response.data.data.startTime,
            state: response.data.data.state,
            status: response.data.data.status,
            totalTime: response.data.data.totalTime,
            updatedOn: response.data.data.updatedOn,
            username: response.data.data.username,
            usi: response.data.data.usi,
            visaExpiryDate: response.data.data.visaExpiryDate,
            visaIssueDate: response.data.data.visaIssueDate,
            visaType: response.data.data.visaType,
          });
          setregion(response.data.data.state);
          setdateofbirthdate(response.data.data.dateOfBirth);
          setpassportissuedate(response.data.data.passportIssueDate);
          setpassportexpirydate(response.data.data.passportExpiryDate);
          setvisaissuedate(response.data.data.visaIssueDate);
          setvisaexpirydate(response.data.data.visaExpiryDate);
          sethealthcoverdate(response.data.data.insuranceIssueDate);
          setinsuranceexpirydate(response.data.data.insuranceExpiryDate);
          setmembershipexpirydate(response.data.data.membershipExpiry);
          setcountry(response.data.data.country);
          setselectedConsultantid(response.data.data.assigneeId);
          setselectedConsultantName(response.data.data.assigneeName);
          setselectedConsultantemail(response.data.data.assigneeEmail);
          setselectedOfficeid(response.data.data.officeId);
          setselectedOfficename(response.data.data.officeName);
          setselectedOfficecode(response.data.data.officeCode);
          getOfcData(response.data.data.officeId);
          axios
            .get(`${base_url.api2}/getUsersSelectByOfficeId`, {
              headers: {
                Authorization: jwtToken,
                officeId: Number(response.data.data.officeId),
              },
            })
            .then(
              (response) => {
                setConsultant(response.data.data);
              },
              (error) => {
                toast.error(JSON.stringify(error.response.data.message));
              }
            );
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [allDetails, setclientDetail] = useState({});
  const getClientDetails = () => {
    axios
      .get(`${base_url.api3}/getClientByUsername`, {
        headers: {
          Authorization: jwtToken,
          username: params.client_username,
        },
      })
      .then((response) => {
        // console.log(response.data.data.lastLogin);
        if (response.data.status_code == "200") {
          let client = response.data.data.clientDetail;
          console.log(client);
          let client2 = response.data.data;
          console.log(client2);
          setclientDetail({
            addedOn: client.addedOn,
            address: client.address,
            alias: client.alias,
            ban: client.ban,
            city: client.city,
            clientSource: client.clientSource,
            comment: client.comment,
            company: client.company,
            contactPreference: client.contactPreference,
            country: client.country,
            currentlyStudyingInstitutionName:
              client.currentlyStudyingInstitutionName,
            dob: client.dob,
            email: client.email,
            firstName: client.firstName,
            gender: client.gender,
            id: client.id,
            insuranceExpiryDate: client.insuranceExpiryDate,
            insuranceIssueDate: client.insuranceIssueDate,
            insuranceProvider: client.insuranceProvider,
            insuranceType: client.insuranceType,
            intake: client.intake,
            lastName: client.lastName,
            membershipExpiry: client.membershipExpiry,
            membershipNumber: client.membershipNumber,
            middleName: client.middleName,
            nationality: client.nationality,
            passport: client.passport,
            passportExpiryDate: client.passportExpiryDate,
            passportIssueDate: client.passportIssueDate,
            passportNumber: client.passportNumber,
            phone: client2.phone,
            secondaryphone: client2.secondaryphone,
            photo: client.photo,
            pincode: client.pincode,
            policyNumber: client.policyNumber,
            secondaryEmail: client.secondaryEmail,
            state: client.state,
            status: client.status,
            street: client.street,
            subAgent: client.subAgent,
            superAgent: client.superAgent,
            username: client.username,
            usi: client.usi,
            visaExpiry: client.visaExpiry,
            visaIssueDate: client.visaIssueDate,
            visaType: client.visaType,
            subAgentName: client.subAgentName,
            superAgentName: client.superAgentName,
            subAgentId: client.subAgentId,
            superAgentId: client.superAgentId,
          });

          console.log(allDetails);
        }
      })
      .catch((err) => handle403error(err));
  };

  const [setOfficeCode, setsetOfficeCode] = useState();
  const getOfcData = (ofcId) => {
    axios
      .get(`${base_url.api1}/getOfficeById`, {
        headers: {
          Authorization: jwtToken,
          officeId: Number(ofcId),
        },
      })
      .then(
        (response) => {
          setsetOfficeCode(response.data.data.officeCode);
        },
        (error) => {
          toast.error(JSON.stringify(error.response.data.message));
          handle403error(error);
        }
      );
  };

  const [allIntake, setAllIntake] = useState();
  const getIntake = () => {
    axios
      .get(`${base_url.api1}/getAllIntake`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let intakeData = response.data.data;
          let intakeObj = [];
          for (let i = 0; i < intakeData.length; i++) {
            let data = {
              createdIntake: moment(intakeData[i].createdIntake).format(
                "YYYY-MM"
              ),
              id: intakeData[i].id,
              name: intakeData[i].name,
              status: intakeData[i].status,
            };
            intakeObj.push(data);
          }
          let newintakeObj = intakeObj.sort(function (a, b) {
            return b.val - a.val;
          });
          setAllIntake(newintakeObj);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allVisa, setallVisa] = useState();
  const getVisa = () => {
    axios
      .get(`${base_url.api1}/getAllVisaType`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setallVisa(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allClientSource, setClientSource] = useState();
  const getClientSource = () => {
    axios
      .get(`${base_url.api1}/getAllClientSourceUsingQuery`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setClientSource(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [allHealth, setallHealth] = useState();
  const getHealth = () => {
    axios
      .get(`${base_url.api1}/findAllInsurance`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setallHealth(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };
  const [allHealthCover, setallHealthCover] = useState();
  const getHealthCover = () => {
    axios
      .get(`${base_url.api1}/getAllHealthCoverType`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setallHealthCover(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };
  const [subagent, setsubagent] = useState();
  const getsubagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Sub Agent",
        },
      })
      .then(
        (response) => {
          setsubagent(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };
  const [superagent, setsuperagent] = useState();
  const getsuperagent = () => {
    axios
      .get(`${base_url.api3}/findAgentsForDropDownList`, {
        headers: {
          Authorization: jwtToken,
          agentType: "Super Agent",
        },
      })
      .then(
        (response) => {
          setsuperagent(response.data.data);
        },
        (error) => {
          handle403error(error);
        }
      );
  };

  const [availableoffice, setAvailableOffice] = useState(null);
  const getOffice = () => {
    axios
      .get(`${base_url.api1}/findAllOffice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setAvailableOffice(response.data.data);
      })
      .catch((error) => {
        handle403error(error);
      });
  };

  const handleChangeOffice = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    setselectedOfficeid(optionElementId);
    setselectedOfficename(optionElementName);
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedhealthname, setselectedhealthname] = useState(null);
  const handleChangeHealth = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    // console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    setselectedhealthname(optionElementName);
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedIntakename, setselectedIntakename] = useState(null);
  const handleChangeIntake = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    // console.log(optionElementName);
    // setselectedhealthid(optionElementId);
    setselectedIntakename(optionElementName);
  };

  // const [selectedhealthid, setselectedhealthid] = useState(null);
  const [selectedClientSource, setselectedClientSource] = useState(tempClient?.clientSource);
  const handleClientSource = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("value");
    // console.log(event.target.childNodes[index]);
    // console.log(optionElement.getAttribute("id"));
    // console.log(optionElement.getAttribute("value"));
    // setselectedhealthid(optionElementId);
    setselectedClientSource(optionElementName);
  };

  const [selectedConsultantid, setselectedConsultantid] = useState(null);
  const [selectedConsultantName, setselectedConsultantName] = useState(null);
  const [selectedConsultantemail, setselectedConsultantemail] = useState(null);
  const handleChangeConsultant = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementName = optionElement.getAttribute("consultantname");
    const optionElementconsultantemail = optionElement.getAttribute("value");
    setselectedConsultantid(optionElementId);
    setselectedConsultantName(optionElementName);
    setselectedConsultantemail(optionElementconsultantemail);
  };

  const goback = () => {
    var client_prev_path = localStorage.getItem("client_prev_path");
    // history.push("/start-client-session/" + temp_client_id);
    history.push(client_prev_path);
  };

  const gobacktoClientList = () => {
    history.push("/client-list");
  };

  const selectCountry = (val) => {
    setcountry(val);
    tempClient.country = val;
  };
  const selectCountryPassport = (val) => {
    setcountryPassport(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };
  const [errorsecondaryemail, seterrorsecondaryemail] = useState(false);

  const onSubmit = (data2) => {
    if (data2.secondaryemail === data2.email) {
      seterrorsecondaryemail(true);
      return false;
    }
    seterrorsecondaryemail(false);
    // console.log(chkPassState);
    // console.log(data2.confirmpassword);
    // if (data2.confirmpassword === chkPassState) {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let addedOn = datenow;
    let address = data2.street;
    let alias = data2.alias;
    let ban = 0;
    let city = data2.city;
    let clientSource;
    if (selectedClientSource == null || selectedClientSource == undefined) {
      clientSource = tempClient?.clientSource;
    } else {
      clientSource = selectedClientSource;
    }
    let comment = data2.comment;
    let company = "string";
    let contactPreference = value;
    let country = country1;
    let currentlyStudyingInstitutionName = data2.institutionname;
    let dob = dateofbirthdate
      ? moment(dateofbirthdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let email = data2.email.trim();
    let firstName = data2.firstName.trim();
    let gender = data2.gender;
    let id = 0;
    let insuranceExpiryDate = insuranceexpirydate
      ? moment(insuranceexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let insuranceIssueDate = healthcoverdate
      ? moment(healthcoverdate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let insuranceProvider = data2.insuranceProvider;
    let insuranceType = data2.insuranceType;
    let intake = selectedIntakename;
    let lastName = data2.lastName.trim();
    let membershipExpiry = membershipexpirydate
      ? moment(membershipexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let membershipNumber = Number(data2.membershipNumber);
    let middleName = data2.middleName.trim();
    let nationality = country1;
    let passport = country1Passport;
    let passportExpiryDate = passportexpirydate
      ? moment(passportexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let passportIssueDate = passportissuedate
      ? moment(passportissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let passportNumber = data2.passportNumber;
    let phone = Number(data2.phone);
    let secondaryphone = Number(data2.secondaryphone);
    let photo = "string";
    let pincode = data2.pincode;
    let policyNumber = data2.policyNumber;
    let secondaryEmail = data2.secondaryEmail.trim();
    let state = region;
    let status = Number(data2.status);
    let street = data2.street;
    let subAgent = data2.subagent;
    let superAgent = data2.superagent;
    let subAgentId = Number(subagentid);
    let superAgentId = Number(superagentid);
    let username = data2.email.trim();
    let usi = data2.usi;
    let visaExpiry = visaexpirydate
      ? moment(visaexpirydate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let visaIssueDate = visaissuedate
      ? moment(visaissuedate).format("YYYY-MM-DDThh:mm:ss")
      : null;
    let visaType = data2.visaType;
    let consultantId = Number(selectedConsultantid);
    let consultantName = selectedConsultantName;
    let consultantEmail = selectedConsultantemail;
    // let lastLogin = datenow;
    let officeId = Number(selectedOfficeid);
    let officeName = selectedOfficename;
    let officeCode = selectedOfficecode;
    let addedOfficeId = Number(selectedOfficeid);
    let addedOfficeName = selectedOfficename;
    let addedOfficeCode = selectedOfficecode;
    let createdBy = isw_user_name + " (" + isw_user_email + ")";
    let password;
    if (chkPassState == null) {
      password = null;
    } else {
      password = chkPassState.trim();
    }
    // if (chkPass == false) {
    //   password = chkPassState.trim();
    // }
    let passwordRecoveryCode = "String";
    let passwordRecoveryDate = datenow;
    let roleId = 8;
    let updatedOn = datenow;
    let clientRelation = data2.clientRelation;
    let clientRelativeName = data2.clientRelativeName;
    let clientRelativeEmail = data2.clientRelativeEmail;
    let clientRelativePhone = data2.clientRelativePhone;
    let enquiryLeadType = selectedLeadType;

    // if (insuranceExpiryDate === "Invalid date") {
    //   insuranceExpiryDate = null;
    // }
    // if (insuranceIssueDate === "Invalid date") {
    //   insuranceIssueDate = null;
    // }
    // if (membershipExpiry === "Invalid date") {
    //   membershipExpiry = null;
    // }
    // if (passportExpiryDate === "Invalid date") {
    //   passportExpiryDate = null;
    // }
    // if (passportIssueDate === "Invalid date") {
    //   passportIssueDate = null;
    // }
    // if (visaExpiry === "Invalid date") {
    //   visaExpiry = null;
    // }
    // if (visaIssueDate === "Invalid date") {
    //   visaIssueDate = null;
    // }

    let clientDetail = {
      addedOn,
      address,
      alias,
      ban,
      city,
      clientSource,
      comment,
      company,
      contactPreference,
      country,
      currentlyStudyingInstitutionName,
      dob,
      email,
      firstName,
      gender,
      id,
      insuranceExpiryDate,
      insuranceIssueDate,
      insuranceProvider,
      insuranceType,
      intake,
      lastName,
      membershipExpiry,
      membershipNumber,
      middleName,
      nationality,
      passport,
      passportExpiryDate,
      passportIssueDate,
      passportNumber,
      phone,
      secondaryphone,
      photo,
      pincode,
      policyNumber,
      secondaryEmail,
      state,
      status,
      street,
      subAgent,
      subAgentId,
      superAgentId,
      subAgentName: subagentname,
      superAgent,
      superAgentName: superagentname,
      username,
      usi,
      visaExpiry,
      visaIssueDate,
      visaType,
      enquiryLeadType,
    };

    let data = {
      addedOn,
      address,
      alias,
      ban,
      city,
      clientDetail,
      clientSource,
      consultantId,
      consultantName,
      consultantEmail,
      contactPreference,
      country,
      email,
      firstName,
      id,
      insuranceExpiryDate,
      insuranceIssueDate,
      insuranceProvider,
      insuranceType,
      phone,
      secondaryphone,
      lastName,
      middleName,
      officeId,
      officeName,
      officeCode,
      addedOfficeCode,
      addedOfficeId,
      addedOfficeName,
      createdBy,
      password,
      passwordRecoveryCode,
      passwordRecoveryDate,
      pincode,
      policyNumber,
      roleId,
      secondaryEmail,
      state,
      status,

      updatedOn,
      username,
      visaExpiry,
      visaIssueDate,
      visaType,
      clientRelation,
      clientRelativeName,
      clientRelativeEmail,
      clientRelativePhone,
      enquiryLeadType,
    };
    console.log(data);
    if (chkPassState != null) {
      if (data2.confirmpassword != chkPassState) {
        alert("Confirm Password is not equal to password");
      } else {
        postCustomer(data, selectedLeadType);
      }
    } else {
      postCustomer(data, selectedLeadType);
    }

    // } else {
    //  alert("Confirm Password is not equal to password");
    // }
  };

  const [showApplication, setshowApplication] = useState();
  const [respclientid, setrespclientid] = useState();
  const [getclientId, setclientId] = useState();
  const [getData, setData] = useState();
  const [respclientemail, setrespclientemail] = useState();
  const [noteworkflowtype, setnoteworkflowtype] = useState("Intial consultant");
  let client_Id;
  const postCustomer = (data, enquiryLeadType) => {
    axios
      .post(`${base_url.api3}/createClient`, data, {
        headers: {
          enquiryLeadType: enquiryLeadType,
          leadId: temp_client_id,
        },
      })
      .then((response) => {
        console.log(allDetails);
        // console.log(response.data.data.clientDetail);
        // console.log(response.data.data);
        // console.log(response.data.data.id);
        // console.log(response.data.data.ClientId);
        setData(response.data);
        let my_client_Id = response.data.data.ClientId;
        let clientDetailId = response.data.data.clientDetailId;
        setclientId(response.data.data.ClientId);
        // console.log(response.data.data.Message);
        // console.log(response.data.data.isDuplicate);
        if (response.data.data.isDuplicate == 1) {
          // console.log("coming In If");
          Swal.fire({
            title: 'Client Email Already exists ?',
            text: "Would you like to update information for this client !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'
          }).then((result) => {
            if (result.isConfirmed) {
              axios
                .post(`${base_url.api3}/updateClientByUsername`,
                  {

                    "address": data.address,
                    "city": data.city,
                    "clientDetail": {
                      "addedOn": allDetails.addedOn,
                      "alias": allDetails.alias,
                      "ban": allDetails.ban,
                      "clientSource": allDetails.clientSource,
                      "comment": allDetails.comment,
                      "company": allDetails.company,
                      "contactPreference": allDetails.contactPreference,
                      "country": allDetails.country,
                      "currentlyStudyingInstitutionName": allDetails.currentlyStudyingInstitutionName,
                      "dob": allDetails.dob,
                      "email": allDetails.email,
                      "firstName": allDetails.firstName,
                      "gender": allDetails.gender,
                      "insuranceExpiryDate": allDetails.insuranceExpiryDate,
                      "insuranceIssueDate": allDetails.insuranceIssueDate,
                      "insuranceProvider": allDetails.insuranceProvider,
                      "insuranceType": allDetails.insuranceType,
                      "intake": allDetails.intake,
                      "lastName": allDetails.lastName,
                      "membershipExpiry": allDetails.membershipExpiry,
                      "membershipNumber": allDetails.membershipNumber,
                      "middleName": allDetails.middleName,
                      "nationality": allDetails.nationality,
                      "passport": allDetails.passport,
                      "passportExpiryDate": allDetails.passportExpiryDate,
                      "passportIssueDate": allDetails.passportIssueDate,
                      "passportNumber": allDetails.passportNumber,
                      "phone": allDetails.phone,
                      "secondaryphone": allDetails.secondaryphone,
                      "photo": allDetails.photo,
                      "policyNumber": allDetails.policyNumber,
                      "secondaryEmail": allDetails.secondaryEmail,
                      "status": allDetails.status,
                      "subAgent": allDetails.subAgent,
                      "superAgent": allDetails.superAgent,
                      "username": allDetails.username,
                      "usi": allDetails.usi,
                      "visaExpiry": allDetails.visaExpiry,
                      "visaIssueDate": allDetails.visaIssueDate,
                      "visaType": allDetails.visaType,
                      "subAgentName": allDetails.subAgentName,
                      "superAgentName": allDetails.superAgentName,
                      "subAgentId": allDetails.subAgentId,
                      "superAgentId": allDetails.superAgentId,

                      "id": clientDetailId,
                      "address": data.clientDetail.address,
                      "city": data.clientDetail.city,
                      "country": data.clientDetail.country,
                      "pincode": data.clientDetail.pincode,
                      "state": data.clientDetail.state,
                      "street": data.clientDetail.street,
                      // "enquiryLeadType": data.clientDetail.enquiryLeadType,
                    },
                    "clientSource": data.clientSource,
                    "consultantId": data.consultantId,
                    "consultantName": data.consultantName,
                    "consultantEmail": data.consultantEmail,
                    "officeId": data.officeId,
                    "officeName": data.officeName,
                    "officeCode": data.officeCode,
                    "addedOfficeCode": data.addedOfficeCode,
                    "addedOfficeId": data.addedOfficeId,
                    "addedOfficeName": data.addedOfficeName,
                    "pincode": data.pincode,
                    "state": data.state,
                    "updatedOn": data.updatedOn,
                    "username": data.username,
                    // "enquiryLeadType": data.enquiryLeadType
                  }
                  , {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      Authorization: jwtToken,
                      enquiryLeadType: selectedLeadType,
                    },
                  })
                .then(
                  (response) => {
                    toast.success("Success");
                    dispatch(
                      updateClient({
                        id: tempClient.username,
                        data: response.data.data.clientDetail,
                      })
                    );
                    let logs =
                      isw_user_name +
                      " Updated Client " +
                      response.data.data.firstName +
                      " " +
                      response.data.data.lastName +
                      "(" +
                      response.data.data.email +
                      ").";
                    callsaveauditapi(logs);

                  },
                  (error) => {
                    handle403error(error);
                  }
                );

              const data5 = {
                addedBy: isw_user_name,
                clientId: my_client_Id,
                createdOn: datenow,
                id: 0,
                note: getremarks,
                workflowType: noteworkflowtype,
                lastUpdatedOn: datenow,
              };
              // console.log(data5);

              axios
                .post(`${base_url.api3}/saveClientNotes`, data5, {
                  headers: {
                    Authorization: jwtToken,
                  },
                })
                .then((res) => {
                  toast.success("Notes Added");
                  let auditdata = {
                    addedBy: isw_user_name,
                    clientId: my_client_Id,
                    createdOn: data5.createdOn,
                    id: 0,
                    //note: descriptionState2,
                    note: data5.note,
                    workflowType: noteworkflowtype,
                    lastUpdatedOn: data5.lastUpdatedOn,
                  };
                  console.log(auditdata);
                  axios
                    .post(`${base_url.api3}/saveAuditNotes`, auditdata, {
                      headers: {
                        Authorization: jwtToken,
                      },
                    })
                    .then((response) => {
                      console.log(response);

                      axios
                        .get(`${base_url.api3}/getAuditNotesByClientId`, {
                          headers: {
                            Authorization: jwtToken,
                            clientId: my_client_Id,
                            columnName: "last_updated_on",
                            direction: "DESC",
                          },
                        })
                        .then((response) => {
                          console.log(response);
                        })
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  axios
                    .delete(`${base_url.api3}/deleteClientTempById`, {
                      headers: {
                        Authorization: jwtToken,
                        clientTempId: params.temp_client_id,
                      },
                    })
                    .then(
                      (response) => {
                        // toast.success(response.data.data.message);   
                      },
                      (error) => {
                        // toast.error(JSON.stringify(error.response.data.message));
                        handle403error(error);
                      }
                    );
                })
                .catch((err) => {
                  console.log("No Data Available");
                });
            }
            gobacktoClientList();
          })
        }
        else {
          // console.log("coming In Else");
          toast.success("Success");
          client_Id = response.data.data.id;
          setrespclientid(response.data.data.id);
          setclientId(response.data.data.clientId);
          setrespclientemail(response.data.data.email);
          setshowApplication(true);
          dispatch(addtoClient(response.data.data.clientDetail));

          const data4 = {
            addedBy: isw_user_name,
            clientId: client_Id,
            createdOn: datenow,
            id: 0,
            note: getremarks,
            workflowType: noteworkflowtype,
            lastUpdatedOn: datenow,
          };
          // console.log(data4);

          axios
            .post(`${base_url.api3}/saveClientNotes`, data4, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then((res) => {
              toast.success("Notes Added");

              let auditdata = {
                addedBy: isw_user_name,
                clientId: client_Id,
                createdOn: data4.createdOn,
                id: 0,
                //note: descriptionState2,
                note: data4.note,
                workflowType: noteworkflowtype,
                lastUpdatedOn: data4.lastUpdatedOn,
              };
              console.log(auditdata);
              axios
                .post(`${base_url.api3}/saveAuditNotes`, auditdata, {
                  headers: {
                    Authorization: jwtToken,
                  },
                })
                .then((response) => {
                  console.log(response);

                  axios
                    .get(`${base_url.api3}/getAuditNotesByClientId`, {
                      headers: {
                        Authorization: jwtToken,
                        clientId: client_Id,
                        columnName: "last_updated_on",
                        direction: "DESC",
                      },
                    })
                    .then((response) => {
                      console.log(response);
                    })
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((err) => {
              console.log("No Data Available");
            });

          // history.push("/client-list");

          // let email_data = {
          //   firstName: response.data.data.firstName,
          //   lastName: response.data.data.lastName,
          //   username: response.data.data.username,
          //   email: response.data.data.email,
          //   password: data.password,
          // };

          // axios
          //   .post(`${base_url.api3}/convertToClientEmail`, email_data, {
          //     headers: {
          //       Authorization: jwtToken,
          //     },
          //   })
          //   .then((response) => {
          //     console.log(response);
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });

          let log =
            isw_user_name +
            " Created Client " +
            response.data.data.firstName +
            " " +
            response.data.data.lastName +
            "(" +
            response.data.data.email +
            ").";

          let temp_data = {
            id: params.temp_client_id,
            status: 0,
          };
          axios
            .post(`${base_url.api3}/updateClientTempById`, temp_data, {
              headers: {
                Authorization: jwtToken,
              },
            })
            .then((response) => {
              // console.log(response);
              axios
                .delete(`${base_url.api3}/deleteClientTempById`, {
                  headers: {
                    Authorization: jwtToken,
                    clientTempId: params.temp_client_id,
                  },
                })
                .then(
                  (response) => {
                    // toast.success(response.data.data.message);   
                  },
                  (error) => {
                    toast.error(JSON.stringify(error.response.data.message));
                    handle403error(error);
                  }
                );
              gobacktoClientList();
            })
            .catch((error) => {
              console.log(JSON.stringify(error.response));
            });

          let temp_data2 = {
            email: tempClientEmail,
            password: data.password,
          };

          if (chkPassState != null) {
            axios
              .post(
                `${base_url.api3}/updateClientTempPasswordByEmail`,
                temp_data2,
                {
                  headers: {
                    Authorization: jwtToken,
                  },
                }
              )
              .then((response) => {
                // console.log(response);
                axios
                  .delete(`${base_url.api3}/deleteClientTempById`, {
                    headers: {
                      Authorization: jwtToken,
                      clientTempId: params.temp_client_id,
                    },
                  })
                  .then(
                    (response) => {
                      // toast.success(response.data.data.message);
                    },
                    (error) => {
                      // toast.error(JSON.stringify(error.response.data.message));
                      handle403error(error);
                    }
                  );
                gobacktoClientList();
              })
              .catch((error) => {
                console.log(JSON.stringify(error.response));
              });
          }

          callsaveauditapi(log);
        }

      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        handle403error(error);
      });
  };

  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();
  const handlePassword = (e) => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    // console.log(e.target.value);
    if (e.target.value.match(decimal)) {
      setchkPass(false);
      setchkPassState(e.target.value);
      return true;
    } else {
      setchkPass(true);
      // setchkPassState(e.target.value);
      return false;
    }
  };

  const gotoApplication = () => {
    localStorage.setItem("prev_path", "/create-client");
    localStorage.setItem("selected_client_id", respclientid);
    dispatch(getworkflowType("Admission"));
    dispatch(gettabvalue(0));
    history.push(
      "/view-enquiry-workflowList/" + respclientid + "/" + respclientemail
    );
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    settempClient((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [selectedLeadType, setLeadType] = useState();
  const handleLeadType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedServiceName = optionElement.getAttribute("value");
    setLeadType(selectedServiceName);
    // console.log(selectedServiceName);
  };

  const handle403error = (err) => {
    if (err) {
      if (err.response) {
        if (Number(err?.response?.status) === 403) {
          setTimeout(() => {
            dispatch(logout());
            localStorage.clear();
            localStorage.setItem("from_403_status", "call_toast");
            history.push("/");
            toast.warn("Session Expired");
          }, 1000);
        }
      }
    }
  };
  return (
    <div>
      <Card className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText className={classes.headtext}>
                <strong>Convert Client</strong>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <button className="btn listing_addbutton" onClick={goback}>
                &#x2630; List
              </button>
            </Col>
          </Row>
          {/* <CardText className={classes.headtext}>Create Admin User</CardText> */}
          <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-inner-admin">
              <div className="form-group creatadmin2">
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <p className="provider_containertitle">Office Use</p>
                  <hr style={{ flex: "1", marginLeft: "20px" }} />
                </div>
                <Row>
                  <Col md={3}>
                    <label>ID:</label>
                    <input
                      className="form-control underline-input"
                      name="clientid"
                      type="text"
                      value={respclientid}
                      readOnly
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Branch<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      // onChange={handleChangeOffice}
                      id={tempClient?.officeId}
                      defaultValue={tempClient?.officeName}
                      value={tempClient?.officeName}
                      readOnly
                    >
                      <option
                        id={tempClient?.officeId}
                        value={tempClient?.officeName}
                      >
                        {tempClient?.officeName}
                      </option>
                    </select>
                  </Col>
                  <Col md={3}>
                    <label>
                      Consultant<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleChangeConsultant}
                      value={selectedConsultantemail}
                      required
                    >
                      {availableconsultant?.map((item, index) => (
                        <option
                          key={index + 1}
                          value={item.email}
                          consultantname={`${item.firstName} ${item.lastName}`}
                          id={item.id}
                        >
                          {item.firstName} {item.lastName}
                        </option>
                      ))}
                      {/* <option
                          id={tempClient?.assigneeId}
                          value={tempClient?.assigneeName}
                        >
                          {tempClient?.assigneeName}
                        </option> */}
                    </select>
                  </Col>
                  <Col md={3}>
                    <label>
                      Client Source<span style={{ color: "red" }}></span>:
                    </label>
                    <select
                      name="clientSource"
                      className={classes.selectdrop}
                      onChange={(e) => {
                        handleClientSource(e);
                        tempClient.clientSource = e.target.value;
                      }}
                      ref={register({ required: false })}
                      value={tempClient?.clientSource}
                      defaultValue={tempClient?.clientSource}
                    >
                      <option value="">- Select Source -</option>
                      {allClientSource?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Personal Details</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="firstName"
                      ref={register({ required: true })}
                      value={tempClient?.firstName}
                      onChange={inputEvent}
                    />
                    {errors.firstName && (
                      <p className="errormessage">First Name is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>Middle Name:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="middleName"
                      ref={register}
                      value={tempClient?.middleName}
                      onChange={inputEvent}
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="lastName"
                      ref={register({ required: true })}
                      value={tempClient?.lastName}
                      onChange={inputEvent}
                    />
                    {errors.lastName && (
                      <p className="errormessage">Last Name is Required</p>
                    )}
                  </Col>
                  {selectedClientSource === "Sub Agent" ?
                    <Col md={3}>
                      <label className="area_rowslable_skill">Sub Agent</label>
                      <select
                        style={{ backgroundColor: "#f5f5f9" }}
                        className="form-control"
                        name="subagent"
                        onChange={(event) => {
                          const index = event.target.selectedIndex;
                          const optionElement = event.target.childNodes[index];
                          const optionElementId = optionElement.getAttribute("id");
                          const optionElementrealId =
                            optionElement.getAttribute("realid");
                          setsubagentname(optionElementId);
                          setsubagentid(optionElementrealId);
                        }}
                        ref={register}
                      >
                        <option value="">- Select sub agent -</option>
                        {subagent?.map((cuntitem) => (
                          <option
                            key={cuntitem.id}
                            value={cuntitem.username}
                            id={cuntitem.name}
                            realid={cuntitem.id}
                          >
                            {cuntitem.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    : selectedClientSource === "Referer" ?
                      <Col md={3}>
                        <label className="area_rowslable_skill">Referer</label>
                        <select
                          style={{ backgroundColor: "#f5f5f9" }}
                          className="form-control"
                          name="subagent"
                          onChange={(event) => {
                            const index = event.target.selectedIndex;
                            const optionElement = event.target.childNodes[index];
                            const optionElementId = optionElement.getAttribute("id");
                            const optionElementrealId =
                              optionElement.getAttribute("realid");
                            setsubagentname(optionElementId);
                            setsubagentid(optionElementrealId);
                          }}
                          ref={register}
                        >
                          <option value="">- Select sub agent -</option>
                          {subagent?.map((cuntitem) => (
                            <option
                              key={cuntitem.id}
                              value={cuntitem.username}
                              id={cuntitem.name}
                              realid={cuntitem.id}
                            >
                              {cuntitem.name}
                            </option>
                          ))}
                        </select>
                      </Col>
                      :
                      <div>
                      </div>
                  }
                </Row>
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>
                      Gender<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      required
                      name="gender"
                      ref={register({ required: true })}
                      value={tempClient?.gender}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    {errors.gender && (
                      <p className="errormessage">Gender is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>Date Of Birth:</label>
                    <DatePicker
                      selected={
                        // dateofbirthdate ? new Date(dateofbirthdate) : null
                        dob.isValid() == false ? null : new Date(dateofbirthdate)
                      }
                      onChange={(date) => setdateofbirthdate(date)}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date("01-01-1950")}
                      maxDate={new Date("12-31-2010")}
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={3}>
                    <label>Alias:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="alias"
                      ref={register}
                      value={tempClient?.alias}
                      onChange={inputEvent}
                    />
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Contact Details</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>
                      Email<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control underline-input"
                      name="email"
                      ref={register({ required: true })}
                      value={tempClient?.email}
                      onChange={inputEvent}
                    />
                    {errors.email && (
                      <p className="errormessage">Email is Required </p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>Secondary Email:</label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control underline-input"
                      name="secondaryEmail"
                      ref={register}
                      value={tempClient?.secondaryEmail}
                      onChange={inputEvent}
                    />
                    {errorsecondaryemail && (
                      <p className="errormessage">
                        SecondaryEmail and Email should not be same{" "}
                      </p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>Phone (primary)</label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      name="phone"
                      ref={register}
                      value={tempClient?.phone}
                      onChange={inputEvent}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_skill">Phone (secondary)</label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      name="secondaryphone"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  {/* <Col md={3}>
                    <label>
                      Password:
                    </label>
                    {/* <input
                        type="password"
                        className="form-control underline-input"
                        name="password"
                        onChange={handlePassword}
                        required
                      />
                      {chkPass ? (
                        <p className="errormessage">
                          Password must have at least 8 characters with at least
                          one lowercase letter, one uppercase letter, one
                          numeric digit, and one special character
                        </p>
                      ) : null} */}
                  {/* <div style={{ position: "relative" }}>
                    <input
                      type={inputtype}
                      autoComplete="off"
                      className="form-control underline-input"
                      value={chkPassState}
                      onChange={(e) => setchkPassState(e.target.value)}
                    />
                    {inputtype === "password" ? (
                      <i
                        className="fa fa-eye-slash fonticonpositioning"
                        onClick={() => setinputtype("text")}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye fonticonpositioning"
                        onClick={() => setinputtype("password")}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <PasswordStrengthBar password={chkPassState} /> */}
                  {/* {errors.password && (
                        <p className="errormessage">
                          Password must have at least 8 characters
                        </p>
                      )} */}
                  {/* </Col>
                  <Col md={3}>
                    <label>
                      Confirm Password:
                      {/* <span style={{ color: "red" }}>*</span>: */}
                  {/* </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={inputtype2}
                        onChange={(e) => setconfirmpassword(e.target.value)}
                        className="form-control underline-input"
                        name="confirmpassword"
                        ref={register({ required: false })}
                      // required
                      />
                      {inputtype2 === "password" ? (
                        <i
                          className="fa fa-eye-slash fonticonpositioning"
                          onClick={() => setinputtype2("text")}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye fonticonpositioning"
                          onClick={() => setinputtype2("password")}
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    {confirmpassword && confirmpassword !== chkPassState && (
                      <p className="errormessage">Password not matching</p>
                    )} */}
                  {/* </Col> */}
                  <Col md={3}>
                    <label className="area_rowslable_skill">Contact Preference</label>
                    <br />
                    <div class="form-check checkbox-group" style={{ gap: "10px", marginTop: "-10px" }}>
                      <input
                        type="radio"
                        name="contact"
                        id="phone"
                        value="phone"
                        style={{ backgroundColor: "#f5f5f9" }}
                        onChange={(e) => setValue(e.target.value)}
                        checked={value === "phone"}
                      />
                      <label className="area_rowslable_skill" for="phone" style={{ marginTop: "7px" }}>Phone</label>
                    </div>
                    <div class="form-check checkbox-group" style={{ gap: "10px", marginTop: "-20px" }}>
                      <input
                        type="radio"
                        name="contact"
                        id="email"
                        onChange={(e) => setValue(e.target.value)}
                        value="email"
                        style={{ backgroundColor: "#f5f5f9" }}
                        checked={value === "email"}
                      />
                      <label className="area_rowslable_skill" for="email" style={{ marginTop: "7px" }}>Email</label>
                    </div>
                  </Col>
                  <Col md={3}></Col>

                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Address</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>
                      Street<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="street"
                      ref={register({ required: true })}
                      value={tempClient?.address}
                      onChange={inputEvent}
                    />
                    {errors.street && (
                      <p className="errormessage">Address is Required</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      City/Suburb<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      value={tempClient?.city}
                      name="city"
                      ref={register({ required: true })}
                      onChange={inputEvent}
                    />
                    {errors.city && (
                      <p className="errormessage">City is Required</p>
                    )}
                  </Col>

                  <Col md={3}>
                    <label>
                      Country<span style={{ color: "red" }}>*</span>:
                    </label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      // value={tempClient?.country}
                      value={country1}
                      onChange={(val) => selectCountry(val)}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      State<span style={{ color: "red" }}>*</span>:
                    </label>
                    {/* {tempClient?.country == "Nepal" ? ( */}
                    {country1 == "Nepal" ? (
                      <select
                        className={classes.selectdrop}
                        name="gender"
                        value={region}
                        required
                        onChange={(e) => selectRegion(e.target.value)}
                      >
                        <option value="">- Select Province -</option>
                        <option value="Province No. 1">Province No. 1</option>
                        <option value="Madhesh Province">Madhesh Province</option>
                        <option value="Bagmati Province">Bagmati Province</option>
                        <option value="Gandaki Province">Gandaki Province</option>
                        <option value="Lumbini Province">Lumbini Province</option>
                        <option value="Karnali Province">Karnali Province</option>
                        <option value="Sudurpashchim Province">
                          Sudurpashchim Province
                        </option>
                      </select>
                    ) : (
                      <RegionDropdown
                        defaultOptionLabel="- Select State -"
                        className="form-control underline-input countrydropdown2"
                        // country={tempClient?.country}
                        country={country1}
                        value={region}
                        onChange={(val) => selectRegion(val)}
                        required
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>
                      PostCode<span style={{ color: "red" }}>*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="pincode"
                      ref={register({ required: true })}
                      value={tempClient?.pincode}
                      onChange={inputEvent}
                    />
                    {errors.pincode && (
                      <p className="errormessage">PostCode is Required</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">
                  Passport/Visa Information
                </p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>Country of Passport:</label>
                    <CountryDropdown
                      defaultOptionLabel="- Select Country -"
                      className="form-control underline-input countrydropdown2"
                      value={country1Passport}
                      onChange={(val) => selectCountryPassport(val)}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Passport Number:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="passportNumber"
                      ref={register}
                      value={tempClient?.passportNumber}
                      onChange={inputEvent}
                    />
                  </Col>
                  <Col md={3} style={{ position: "relative" }}>
                    <label>Passport Issue Date:</label>
                    <DatePicker
                      selected={
                        // passportissuedate ? new Date(passportissuedate) : null
                        pasissdate.isValid() == false ? null : new Date(passportissuedate)
                      }
                      onChange={(date) => setpassportissuedate(date)}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={3}>
                    <label>Passport Expiry Date:</label>
                    <DatePicker
                      selected={
                        // passportexpirydate ? new Date(passportexpirydate) : null
                        pasexpdate.isValid() == false ? null : new Date(passportexpirydate)
                      }
                      onChange={(date) => setpassportexpirydate(date)}
                      minDate={new Date(passportissuedate)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>Visa Type:</label>
                    <select
                      className={classes.selectdrop}
                      ref={register}
                      name="visaType"
                      value={tempClient?.visaType}
                      onChange={inputEvent}
                    >
                      <option value="">- Select Visa Type -</option>
                      {allVisa?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={3}>
                    <label>Visa Issue Date:</label>
                    <DatePicker
                      selected={
                        // visaissuedate ? new Date(visaissuedate) : null
                        visissdate.isValid() == false ? null : new Date(visaissuedate)
                      }
                      onChange={(date) => setvisaissuedate(date)}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={3}>
                    <label>Visa Expiry:</label>
                    <DatePicker
                      selected={
                        // visaexpirydate ? new Date(visaexpirydate) : null
                        visexpdate.isValid() == false ? null : new Date(visaexpirydate)
                      }
                      onChange={(date) => setvisaexpirydate(date)}
                      minDate={new Date(visaissuedate)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Health Insurance</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>Insurance Provider:</label>
                    <select
                      className={classes.selectdrop}
                      onChange={handleChangeHealth}
                      ref={register}
                      name="insuranceProvider"
                      value={tempClient?.insuranceProvider}
                    >
                      <option value="">- Select Insurance Provider -</option>
                      {allHealth?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={3}>
                    <label>Policy Number:</label>
                    <input
                      type="string"
                      className="form-control underline-input"
                      name="policyNumber"
                      ref={register}
                      value={tempClient?.policyNumber}
                      onChange={inputEvent}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Health Cover Type:</label>
                    <select
                      className={classes.selectdrop}
                      ref={register}
                      name="insuranceType"
                      value={tempClient?.insuranceType}
                    >
                      <option value="">- Select Health Cover Type -</option>
                      {allHealthCover?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>Health Cover Date:</label>
                    <DatePicker
                      selected={
                        // healthcoverdate ? new Date(healthcoverdate) : null
                        heacovdate.isValid() == false ? null : new Date(healthcoverdate)
                      }
                      onChange={(date) => sethealthcoverdate(date)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={3}>
                    <label>Insurance Expiry Date:</label>
                    <DatePicker
                      selected={
                        // insuranceexpirydate ? new Date(insuranceexpirydate) : null
                        insexpdate.isValid() == false ? null : new Date(insuranceexpirydate)
                      }
                      onChange={(date) => setinsuranceexpirydate(date)}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date("01-01-2000")}
                      maxDate={new Date("12-31-2099")}
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Agent</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  {/* <Col md={4}>
                    <label>Sub Agent:</label>
                    <select
                      className={classes.selectdrop}
                      name="subagent"
                      ref={register}
                      onChange={(event) => {
                        const index = event.target.selectedIndex;
                        const optionElement = event.target.childNodes[index];
                        const optionElementId =
                          optionElement.getAttribute("id");
                        const optionElementrealId =
                          optionElement.getAttribute("realid");
                        setsubagentname(optionElementId);
                        setsubagentid(optionElementrealId);
                      }}
                    >
                      <option value="">- Select sub agent -</option>
                      {subagent?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.username}
                          id={cuntitem.name}
                          realid={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col> */}
                  <Col md={3}>
                    <label>Super Agent:</label>
                    <select
                      className={classes.selectdrop}
                      name="superagent"
                      ref={register}
                      onChange={(event) => {
                        const index = event.target.selectedIndex;
                        const optionElement = event.target.childNodes[index];
                        const optionElementId =
                          optionElement.getAttribute("id");
                        const optionElementrealId =
                          optionElement.getAttribute("realid");
                        setsuperagentname(optionElementId);
                        setsuperagentid(optionElementrealId);
                      }}
                    >
                      <option value="">- Select super agent -</option>
                      {superagent?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.username}
                          id={cuntitem.name}
                          realid={cuntitem.id}
                        >
                          {cuntitem.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Others</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>USI:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="usi"
                      ref={register}
                      value={tempClient?.usi}
                      onChange={inputEvent}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Membership Number:</label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      name="membershipNumber"
                      ref={register}
                      value={tempClient?.membershipNumber}
                      onChange={inputEvent}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Membership Expiry Date:</label>
                    <DatePicker
                      selected={
                        // membershipexpirydate ? new Date(membershipexpirydate) : null
                        memexpdate.isValid() == false ? null : new Date(membershipexpirydate)
                      }
                      onChange={(date) => setmembershipexpirydate(date)}
                      minDate={new Date(datenow)}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col md={3}>
                    <label>
                      Enquiry Lead Type<span style={{ color: "red" }}>*</span>:
                    </label>
                    <select
                      className={classes.selectdrop}
                      name="enquiryLeadType"
                      ref={register}
                      value={tempClient?.leadType}
                      defaultValue={tempClient?.leadType}
                      onChange={(e) => {
                        handleLeadType(e);
                        tempClient.leadType = e.target.value;
                      }}
                      required
                    >
                      <option value="">- Select Lead Type -</option>
                      <option value="ColdLead">Cold Lead</option>
                      <option value="WarmLead">Warm Lead</option>
                      <option value="HotLead">Hot Lead</option>
                      <option value="Converted">Converted Lead</option>
                    </select>
                    {errors.LeadType && (
                      <p className="errormessage">
                        Enquiry Lead Type is Required
                      </p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label>Comment:</label>
                    <input
                      type="textarea"
                      className="form-control"
                      name="comment"
                      ref={register}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Institution Name:</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="institutionname"
                      ref={register}
                    // required
                    />
                  </Col>
                  <Col md={3}>
                    <label>Intake:</label>
                    <select
                      name="intake"
                      className={classes.selectdrop}
                      onChange={handleChangeIntake}
                      ref={register}
                    // required
                    >
                      <option value="">- Select Intake -</option>
                      {allIntake?.map((cuntitem) => (
                        <option
                          key={cuntitem.id}
                          value={cuntitem.name}
                          id={cuntitem.id}
                        >
                          {cuntitem.createdIntake}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={3}>
                    <label>Status:</label>
                    <select
                      className={classes.selectdrop}
                      name="status"
                      ref={register}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">
                  Client Emergency Contact Details
                </p>
                {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
              </div>
              <div className="form-group creatadmin2">
                <Row>
                  <Col md={3}>
                    <label className="area_rowslable_skill">Name</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="clientRelativeName"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_skill">Relationship With Client</label>
                    <input
                      type="text"
                      className="form-control underline-input"
                      name="clientRelation"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register}
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_skill">Email</label>
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control underline-input"
                      name="clientRelativeEmail"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register}
                    // required
                    />
                  </Col>
                  <Col md={3}>
                    <label className="area_rowslable_skill">Phone</label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control underline-input"
                      name="clientRelativePhone"
                      style={{ backgroundColor: "#f5f5f9" }}
                      ref={register}
                    />
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md={6}>{null}</Col>
                <Col md={6} className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    onClick={() => {
                      document.getElementById("myForm").reset();
                    }}
                  >
                    Reset
                  </Button>
                  <button type="submit" className="btn listing_addbutton ml-1">
                    Submit
                  </button>
                </Col>
              </Row>
            </div>
          </form>
          <div className="form-inner-admin">
            {showApplication ? (
              <button
                className="btn listing_addbutton"
                onClick={gotoApplication}
              >
                Application
              </button>
            ) : null}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CreateClientLead;
