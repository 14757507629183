import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./../css/Responsive.css";
import "./../css/MainDashboard.css";
import base_url from "../../api/bootapi";
import MainDashboardBodytop from "./MainDashboardBodytop";
import MainDashboardBodymiddle from "./MainDashboardBodymiddle";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, logout, selectCompress, setdashboardcheck } from "../features/userSlice";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getagentpermission, check4pointpermission, check1pointpermission } from "../Permission/permission";
import { useHistory } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const MainDashboard = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const compress = useSelector(selectCompress);
  console.log(compress);
  const jwtToken = "Bearer " + localStorage.getItem("isw_user_token");
  const isw_adminId = localStorage.getItem("isw_adminId");
  const [officeid, setofficeid] = useState();
  const [backdropopen, setbackdropopen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [enquirycount, setEnquiryCount] = useState();
  const [topprovider, settopprovider] = useState();
  const [topcourse, settopcourse] = useState();
  const [enquirybyfollowdate, setenquirybyfollowdate] = useState();
  const [pageno, setpageno] = useState(0);
  const [clientcountbyuser, setclientcountbyuser] = useState();
  const [getpageno, setgetpageno] = useState();
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getenquirybyfollowupdate(officeid, value - 1);
  };

  const handleOffice = (event) => {
    setbackdropopen(true);

    setofficeid(event.target.value);
    getallclient(event.target.value);
    getalllead(event.target.value);
    getEnquiryCount(event.target.value);
    getenquirybyfollowupdate(event.target.value, pageno);
    getadmissionEnquiryWorkflowsteps(event.target.value);
    getmigrationEnquiryWorkflowsteps(event.target.value);
    getinsuranceEnquiryWorkflowsteps(event.target.value);
    getclientcountbyuser(event.target.value);
    getskillassessmentEnquiryWorkflowsteps(event.target.value);
  };

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        history.push("/");
      }
    });
    setbackdropopen(true);
    getPermissions();
    console.log(greetings);
  }, []);

  const [permissionData, setPermissionData] = useState([]);
  const [rolepermission, setrolepermission] = useState([]);

  const getPermissions = () => {
    const resource_obj = [];
    const permission_obj = [];
    for (let i = 0; i < user.isw_role_permissions.length; i++) {
      resource_obj.push(user.isw_role_permissions[i].resourceId);
    }
    for (let i = 0; i < user.isw_user_permissions.length; i++) {
      permission_obj.push(user.isw_user_permissions[i].permissionId);
    }
    setPermissionData(permission_obj);
    setrolepermission(resource_obj);

    if (check4pointpermission(1, 11, permission_obj, resource_obj)) {
      gettopprovider();
      gettopcourse();
      getallClientApplicationReminder();
      getallClientVisaReminder();
      getallClientdeadlineReminder();
      getallOverdueInvoice();
      if (user?.isw_user_role === 1 || user?.isw_user_role === 6) {
        setofficeid("all");
        getenquirybyfollowupdate("all", pageno);
        getallclient("all");
        getalllead("all");
        getallappointment("all");
        getEnquiryCount("all");
        getclientcountbyuser("all");
        getadmissionEnquiryWorkflowsteps("all");
        getmigrationEnquiryWorkflowsteps("all");
        getinsuranceEnquiryWorkflowsteps("all");
        getskillassessmentEnquiryWorkflowsteps("all");
      } else {
        getenquirybyfollowupdate(user?.isw_available_Office[0]?.officeId, pageno);
        setofficeid(user?.isw_available_Office[0]?.officeId);
        getallclient(user?.isw_available_Office[0]?.officeId);
        getalllead(user?.isw_available_Office[0]?.officeId);
        getEnquiryCount(user?.isw_available_Office[0]?.officeId);
        getclientcountbyuser(user?.isw_available_Office[0]?.officeId);
        getallappointment("counsellor");
        getadmissionEnquiryWorkflowsteps(user?.isw_available_Office[0]?.officeId);
        getmigrationEnquiryWorkflowsteps(user?.isw_available_Office[0]?.officeId);
        getinsuranceEnquiryWorkflowsteps(user?.isw_available_Office[0]?.officeId);
        getskillassessmentEnquiryWorkflowsteps(
          user?.isw_available_Office[0]?.officeId
        );
      }
    } else {
      history.push("/errorPage");
    }
  };

  const getclientcountbyuser = (val) => {
    if (val === "all") {
      axios
        .get(`${base_url.api2}/clientConsultantCount`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          setclientcountbyuser(res.data.data);
        });
    } else {
      axios
        .get(`${base_url.api2}/clientConsultantCount`, {
          headers: {
            Authorization: jwtToken,
            officeId: val,
          },
        })
        .then((res) => {
          setclientcountbyuser(res.data.data);
        });
    }
  };
  const getenquirybyfollowupdate = (value, value1) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    if (value === "all") {
      axios
        .get(`${base_url.api2}/getEnquiryByFollowUpDate`, {
          headers: {
            Authorization: jwtToken,
            followUpDate: moment(datenow).format("YYYY-MM-DD"),
            columnName: "created",
            direction: "DESC",
            page: value1,
            size: 20,
          },
        })
        .then((res) => {
          // console.log("Client", res);
          setenquirybyfollowdate(res.data.data.content);
          setgetpageno(res.data.data.totalPages);
        })
        .catch((err) => {
          setclienttotal();
          console.log(err);
        });
    } else {
      axios
        .get(`${base_url.api2}/getEnquiryByFollowUpDate`, {
          headers: {
            Authorization: jwtToken,
            followUpDate: moment(datenow).format("YYYY-MM-DD"),
            officeId: Number(value),
            columnName: "created",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          // console.log("Client", res);
          setenquirybyfollowdate(res.data.data.content);
        })
        .catch((err) => {
          setclienttotal();
          console.log(err);
        });
    }
  };
  const gettopprovider = () => {
    axios
      .get(`${base_url.api1}/getTop5InstitutionCount`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        settopprovider(res.data.data);
      });
  };
  const gettopcourse = () => {
    axios
      .get(`${base_url.api1}/getTop5CourseCount`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        settopcourse(res.data.data);
      });
  };
  const [clienttotal, setclienttotal] = useState();
  const getallclient = (value) => {
    if (value === "all") {
      axios
        .get(`${base_url.api3}/getAllClients`, {
          headers: {
            Authorization: jwtToken,
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 1,
          },
        })
        .then((res) => {
          // console.log("Client", res);
          setclienttotal(res.data.data.totalElements);
        })
        .catch((err) => {
          setclienttotal(0);
          console.log(err);
        });
    } else {
      axios
        .get(`${base_url.api3}/getClientByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(value),
            columnName: "addedOn",
            direction: "DESC",
            page: 0,
            size: 1,
          },
        })
        .then((res) => {
          // console.log("Client", res);
          setclienttotal(res.data.data.totalElements);
        })
        .catch((err) => {
          setclienttotal(0);
          console.log(err);
        });
    }
  };
  const getEnquiryCount = (value) => {
    if (value === "all") {
      axios
        .get(`${base_url.api2}/getCountBasedOnEnquiryStatus`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((res) => {
          console.log("Client", res);
          var result = res.data.data;
          console.log(res.data.data);
          var obj = {};
          for (let i = 0; i < result.length; i++) {
            obj[result[i]["enquiryStatus"]] = result[i]["enquiryCount"];
          }
          console.log(obj);
          setEnquiryCount(obj);
          console.log(enquirycount);
        })
        .catch((err) => {
          setEnquiryCount();
          console.log(err);
        });
    } else {
      axios
        .get(`${base_url.api2}/getCountBasedOnEnquiryStatus`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(value),
          },
        })
        .then((res) => {
          console.log("Client", res);
          var result = res.data.data;
          console.log(res.data.data);
          var obj = {};
          for (let i = 0; i < result.length; i++) {
            obj[result[i]["enquiryStatus"]] = result[i]["enquiryCount"];
          }
          console.log(obj);
          setEnquiryCount(obj);
          console.log(enquirycount);
        })
        .catch((err) => {
          setclienttotal();
          console.log(err);
        });
    }
  };
  const [appointment, setappointment] = useState();
  const getallappointment = (value) => {
    if (value === "all") {
      axios
        .get(`${base_url.api4}/getDashboardAppointments`, {
          headers: {
            Authorization: jwtToken,
            status: 1,
          },
        })
        .then((res) => {
          // console.log("Appointment", res);
          setappointment(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${base_url.api4}/getAppointmentsByCounsellingCreatedBy`, {
          headers: {
            Authorization: jwtToken,
            counsellingCreatedBy: isw_adminId,
            columnName: "appointmentDate",
            direction: "DESC",
            page: 0,
            size: 100,
          },
        })
        .then((res) => {
          // console.log("Appointment", res);
          setappointment(res.data.data.content);
        })
        .catch((err) => {
          // toast.error("No Appointments Scheduled");
          console.log(err);
        });
    }
  };
  const [clienttemptotal, setclienttemptotal] = useState();
  const getalllead = (value) => {
    if (value === "all") {
      axios
        .get(`${base_url.api3}/getAllClientTempWithPage`, {
          headers: {
            Authorization: jwtToken,
            page: 0,
            size: 1,
            columnName: "addedOn",
            direction: "DESC",
            status: 1,
          },
        })
        .then((res) => {
          // console.log("ClientTemp", res);
          setclienttemptotal(res.data.data.totalElements);
        })
        .catch((err) => {
          setclienttemptotal(0);
          console.log(err);
        });
    } else {
      axios
        .get(`${base_url.api3}/getClientTempByOfficeId`, {
          headers: {
            Authorization: jwtToken,
            officeId: Number(value),
            page: 0,
            size: 1,
            columnName: "addedOn",
            direction: "DESC",
          },
        })
        .then((res) => {
          // console.log("ClientTemp", res);
          setclienttemptotal(res.data.data.totalElements);
        })
        .catch((err) => {
          setclienttemptotal(0);
          console.log(err);
        });
    }
  };
  const [admissionenquiryworkflowsteps, setadmissionenquiryworkflowsteps] =
    useState();
  const getadmissionEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Admission",
            },
          }
        )
        .then((res) => {
          setadmissionenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "Admission",
            },
          }
        )
        .then((res) => {
          // console.log("admissionenquiryworkflowtype", res);
          setadmissionenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [migrationenquiryworkflowsteps, setmigrationenquiryworkflowsteps] =
    useState();
  const getmigrationEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Migration",
            },
          }
        )
        .then((res) => {
          setmigrationenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "Migration",
            },
          }
        )
        .then((res) => {
          console.log("migrationenquiryworkflowtype", res);
          setmigrationenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [insuranceenquiryworkflowsteps, setinsuranceenquiryworkflowsteps] =
    useState();
  const getinsuranceEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "Insurance",
            },
          }
        )
        .then((res) => {
          setinsuranceenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "Insurance",
            },
          }
        )
        .then((res) => {
          // console.log("insurncceenquiryworkflowtype", res);
          setinsuranceenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [
    skillassessmentenquiryworkflowsteps,
    setskillassessmentenquiryworkflowsteps,
  ] = useState();
  const getskillassessmentEnquiryWorkflowsteps = (value) => {
    if (value === "all") {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              workflowType: "SkillAssessment",
            },
          }
        )
        .then((res) => {
          setskillassessmentenquiryworkflowsteps(res.data.data);
          setbackdropopen(false);
        })
        .catch((err) => {
          setbackdropopen(false);
          console.log(err);
        });
    } else {
      axios
        .get(
          `${base_url.api1}/getEnquiryCountByWorkflowTypeAndOfficeIdBasedOnCurrentStage`,
          {
            headers: {
              Authorization: jwtToken,
              officeId: Number(value),
              workflowType: "SkillAssessmentre",
            },
          }
        )
        .then((res) => {
          console.log("skillenquiryworkflowtype", res);
          setbackdropopen(false);

          setskillassessmentenquiryworkflowsteps(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setbackdropopen(false);
        });
    }
  };

  const [applicationreminder, setapplicationreminder] = useState();
  const getallClientApplicationReminder = (value) => {
    axios
      .get(`${base_url.api3}/getClientApplicationReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setapplicationreminder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [overdueInvoice, setoverdueInvoice] = useState();
  const getallOverdueInvoice = (value) => {
    axios
      .get(`${base_url.api2}/OverdueInvoice`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        setoverdueInvoice(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const [visreminder, setvisareminder] = useState();
  const getallClientVisaReminder = (value) => {
    axios
      .get(`${base_url.api3}/getClientVisaExpiryReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setvisareminder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [deadlinereminder, setdeadlinereminder] = useState();
  const getallClientdeadlineReminder = (value) => {
    axios
      .get(`${base_url.api3}/getApplicationDeadlineReminder`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setdeadlinereminder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  let date = new Date();
  let hours = date.getHours();
  let greetings;

  if (hours >= 18) {
    greetings = "Good Evening";
  } else if (hours >= 12) {
    greetings = "Good Afternoon";
  } else if (hours >= 0) {
    greetings = "Good Morning";
  } else {
    greetings = "";
  }

  return (
    <div className="dashboardcomponent_cardcss">
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {check1pointpermission(
        1,
        permissionData,
        rolepermission
      ) ? (
        <div className="page-head p-4">


          <div className="d-flex justify-content-between align-items-center mb-4 "  >

            <h3 class="page-title" >
              Welcome,{greetings}
            </h3>
            <div className="d-flex" style={{ flexDirection: "column", textAlign: "right", paddingRight: "1.5rem" }}>
              {/* <Col md={6}>{null}</Col> */}
              {/* <Col md={6}> */}
              {/* <label style={{ color: "#071a52", fontSize: "14px", marginBottom: '0.5rem' }}>Overview and Stats</label> */}
              <select
                value={officeid}
                className="form-select select-active"
                style={{ backgroundColor: "#f5f5f9" }}
                onChange={(e) => handleOffice(e)}
              >
                {user?.isw_user_role === 1 || user?.isw_user_role === 6 || user?.isw_user_role === 10 || user?.isw_user_role === 4 ? (
                  <option value="all">Select All</option>
                ) : null}
                {user?.isw_available_Office?.map((p, index) => (
                  <option
                    key={index + 1}
                    value={p.officeId}
                  >{`${p.officeName}-${p.officeCode}`}</option>
                ))}
              </select>
              {/* </Col> */}
            </div>
          </div>
          <div className="maindash_body">
            <MainDashboardBodytop
              totalclient={clienttotal}
              leadtotal={clienttemptotal}
              enquiryCount={enquirycount}
            />

            {/* <MainDashboardBodybottom /> */}
          </div>
        </div>
      ) : null}
      <MainDashboardBodymiddle
        // workflowType={selectedworkflow}
        handlepage={handlepage}
        getpageno={getpageno}
        officeid={officeid}
        appointment={appointment}
        applicationreminder={applicationreminder}
        visreminder={visreminder}
        deadlinereminder={deadlinereminder}
        overdueInvoice={overdueInvoice}
        clientcountbyuser={clientcountbyuser}
        topprovider={topprovider}
        topcourse={topcourse}
        enquirybyfollowdate={enquirybyfollowdate}
        admissionenquiryworkflowsteps={admissionenquiryworkflowsteps}
        migrationenquiryworkflowsteps={migrationenquiryworkflowsteps}
        insuranceenquiryworkflowsteps={insuranceenquiryworkflowsteps}
        skillassessmentenquiryworkflowsteps={
          skillassessmentenquiryworkflowsteps
        }
      />

    </div>
  );
};

export default MainDashboard;