import { createSlice } from "@reduxjs/toolkit";

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    newline: [
      {
        amount: 0,
        commissionAmount: 0,
        commissionPercentage: 0,
        description: "",
        id: 0,
        quantity: 0,
        taxAmount: 0,
        taxPercentage: 0,
        totalAmount: 0,
      },
    ],
    incomesharing: [
      {
        id: 0,
        incomeShareHolderId: 0,
        incomeSharingAmount: 0,
        incomeSharingName: "",
      },
    ],
    amountvalues: [],
    taxamountvalues: [],
    commissionamountvalues: [],
    totalamountvalues: [],
    incomeAmountdeductionvalues: [],
    netincomedataentries: [],
    totalamt: 0,
    totalpaid: 0,
    totaltax: 0,
    totalamttax: 0,
    totalcommission: 0,
    netincomevalue: 0,
    discountAmount: 0,
    discountpercentamount: 0,
    originalnetincomevalue: 0,
    incomeAmountdeduction: 0,
  },
  reducers: {
    getnewline: (state, action) => {
      state.newline = action.newline;
    },
    gettotalamt: (state, action) => {
      state.totalamt = action.totalamt;
    },
    gettotalpaid: (state, action) => {
      state.totalpaid = action.totalpaid;
    },
    gettotaltax: (state, action) => {
      state.totaltax = action.totaltax;
    },
    gettotalamttax: (state, action) => {
      state.totalamttax = action.totalamttax;
    },
    gettotalcommission: (state, action) => {
      state.totalcommission = action.totalcommission;
    },
    getnetincomevalue: (state, action) => {
      state.netincomevalue = action.netincomevalue;
    },
    getdiscountamount: (state, action) => {
      state.discountAmount = action.discountAmount;
    },
    getdiscountpercentamount: (state, action) => {
      state.discountpercentamount = action.discountpercentamount;
    },
    getoriginalnetincomevalue: (state, action) => {
      state.originalnetincomevalue = action.originalnetincomevalue;
    },
    getincomeAmountdeduction: (state, action) => {
      state.incomeAmountdeduction = action.incomeAmountdeduction;
    },
    getamountvalues: (state, action) => {
      state.amountvalues = action.amountvalues;
    },
    gettaxamountvalues: (state, action) => {
      state.taxamountvalues = action.taxamountvalues;
    },
    getcommissionamountvalues: (state, action) => {
      state.commissionamountvalues = action.commissionamountvalues;
    },
    gettotalamountvalues: (state, action) => {
      state.totalamountvalues = action.totalamountvalues;
    },
    getincomeAmountdeductionvalues: (state, action) => {
      state.incomeAmountdeductionvalues = action.incomeAmountdeductionvalues;
    },
    getnetincomedataentries: (state, action) => {
      state.netincomedataentries = action.netincomedataentries;
    },
    addtonewline: (state, action) => {
      state.newline = [
        ...state.newline,
        {
          amount: 0,
          commissionAmount: 0,
          commissionPercentage: 0,
          description: "",
          id: 0,
          quantity: 0,
          taxAmount: 0,
          taxPercentage: 0,
          totalAmount: 0,
        },
      ];
    },
    addtoamountvalues: (state, action) => {
      state.amountvalues = [...state.amountvalues, ""];
    },
    addtotaxamountvalues: (state, action) => {
      state.taxamountvalues = [...state.taxamountvalues, ""];
    },
    addtocommissionamountvalues: (state, action) => {
      state.commissionamountvalues = [...state.commissionamountvalues, ""];
    },
    addtototalamountvalues: (state, action) => {
      state.totalamountvalues = [...state.totalamountvalues, ""];
    },
    addtoincomeAmountdeductionvalues: (state, action) => {
      state.incomeAmountdeductionvalues = [
        ...state.incomeAmountdeductionvalues,
        "",
      ];
    },
    addtonetincomedataentries: (state, action) => {
      state.netincomedataentries = [...state.netincomedataentries, ""];
    },
    removenewline: (state, action) => {
      let lines = [...state.newline];
      lines.splice(action.payload.id, 1);
      return {
        ...state,
        newline: lines,
      };
    },
  },
});

export const {
  getnewline,
  addtonewline,
  removenewline,
  gettotalamt,
  gettotalpaid,
  gettotaltax,
  gettotalamttax,
  gettotalcommission,
  getnetincomevalue,
  getdiscountamount,
  getdiscountpercentamount,
  getoriginalnetincomevalue,
  getincomeAmountdeduction,
  getamountvalues,
  gettaxamountvalues,
  getcommissionamountvalues,
  gettotalamountvalues,
  getincomeAmountdeductionvalues,
  getnetincomedataentries,
  addtoamountvalues,
  addtotaxamountvalues,
  addtocommissionamountvalues,
  addtototalamountvalues,
  addtoincomeAmountdeductionvalues,
  addtonetincomedataentries,
} = invoiceSlice.actions;
export const selectnewline = (state) => state.invoice.newline;
export const selectincomesharing = (state) => state.invoice.incomesharing;
export const selectamountvalues = (state) => state.invoice.amountvalues;
export const selecttaxamountvalues = (state) => state.invoice.taxamountvalues;
export const selectcommissionamountvalues = (state) =>
  state.invoice.commissionamountvalues;
export const selecttotalamountvalues = (state) =>
  state.invoice.totalamountvalues;
export const selectincomeAmountdeductionvalues = (state) =>
  state.invoice.incomeAmountdeductionvalues;
export const selectnetincomedataentries = (state) =>
  state.invoice.netincomedataentries;
export const selecttotalamt = (state) => state.invoice.totalamt;
export const selecttotalpaid = (state) => state.invoice.totalpaid;
export const selecttotaltax = (state) => state.invoice.totaltax;
export const selecttotalamttax = (state) => state.invoice.totalamttax;
export const selecttotalcommission = (state) => state.invoice.totalcommission;
export const selectnetincomevalue = (state) => state.invoice.netincomevalue;
export const selectdiscountAmount = (state) => state.invoice.discountAmount;
export const selectdiscountpercentamount = (state) =>
  state.invoice.discountpercentamount;
export const selectincomeAmountdeduction = (state) =>
  state.invoice.incomeAmountdeduction;
export default invoiceSlice.reducer;
